import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  getAllMessages,
  getMessagingLoading,
  getMessagingLoadingPagination,
  getSelectedGroup,
  getTopLevelDraft,
} from '../../../store/messages/messages.selectors';
import {
  addDiscussionMembersToBackend,
  deleteGroupMemberFromBackend,
  loadMessages,
  loadSearchedMessagingUsers,
  loadSingleProfilePicture,
  messagesActions,
  updateMessagingGroupReadStatus,
} from '../../../store/messages/messages.actions';
import { NotificationsService } from '../../../services/notifications.service';
import {
  IMessage,
  IMessagingGroup,
  IMessagingUser,
} from '../../../store/messages/messages.interfaces';
import { debounceTime, delay, filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { DeepCopyService } from '../../../services/deep-copy.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { CurrentUserService } from '../../../services/current-user.service';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { MessagesStateService } from '../../../services/messages-state.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { DiscussionViewCommonComponent } from '../discussion-view-common/discussion-view-common.component';
import { getBubbleType } from '../../constants/messages.constants';
import { RichTextEditorComponent } from '../../inputs/rich-text-editor/rich-text-editor.component';
import { PresignedFileUploadService } from '../../../services/presigned-file-upload.service';
import { IsMessageEmptyPipe } from '../../../pipes/framework/is-message-empty.pipe';
import { UploadDropDirective } from '../../../directives/upload-drop.directive';
import { MessageItemAnnouncementComponent } from '../message-item-announcement/message-item-announcement.component';
import { MessageItemComponent } from '../message-item/message-item.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ArrowButtonBoxComponent } from '../../dropdown-button-box/arrow-button-box.component';
import { MatInput } from '@angular/material/input';
import { MessagingUsersTableComponent } from '../messaging-users-table/messaging-users-table.component';
import { OptionsListGeneralComponent } from '../../overlays/options-list-general/options-list-general.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MessagingBubbleComponent } from '../discussions-list-item/messaging-bubble/messaging-bubble.component';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

export interface IFilesToUpload {
  file: File;
  url: SafeUrl;
}

@Component({
  selector: 'app-discussions-view',
  templateUrl: './discussions-view.component.html',
  styleUrls: ['./discussions-view.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MessagingBubbleComponent,
    MatFormField,
    CdkOverlayOrigin,
    OptionsListGeneralComponent,
    MessagingUsersTableComponent,
    NgClass,
    NgFor,
    MatInput,
    MatSuffix,
    ArrowButtonBoxComponent,
    MatProgressBar,
    NgScrollbar,
    MessageItemComponent,
    MessageItemAnnouncementComponent,
    UploadDropDirective,
    RichTextEditorComponent,
    AsyncPipe,
    IsMessageEmptyPipe,
  ],
})
export class DiscussionsViewComponent
  extends DiscussionViewCommonComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isTaskView = false;
  @Input() hideSentMessages = false;
  selectedGroup$ = this.store.select(getSelectedGroup);
  messageForm: UntypedFormGroup;
  isDropDownOpen = false;
  getAllMessages$ = this.store.select(getAllMessages);
  selectedGroup: IMessagingGroup;

  @ViewChild('editorComponent') editorComponent: RichTextEditorComponent; // it overwrites the base component's variable
  @ViewChild('fileUploadSubstitute') fileUploadSubstitute: ElementRef; // it overwrites the base component's variable
  @ViewChild('input') input: ElementRef;
  @ViewChild('scrollbar') scrollbarRef: NgScrollbar;
  allMessages: IMessage[];
  isLoading$ = this.store.select(getMessagingLoading);
  isPaginationLoading$ = this.store.select(getMessagingLoadingPagination);
  loadPaginatedMessages = new Subject(); // emits when there is a need for more messages to be loaded
  // the scrollbar needs to be at position between 0% and SCROLL_UPDATE_PERCENT to make a request to load more messages
  SCROLL_UPDATE_PERCENT = 0.4;
  SCROLL_MIN_DIF = 1; // needs to scroll at least SCROLL_MIN_DIF pixels
  lastScrollPosition: number;
  scrollMaxPosition: number;
  scrollHeight: number;
  getBubbleType = getBubbleType;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private notif: NotificationsService,
    private messageService: MessagesStateService,
    private projectApi: ProjectApiService,
    private interactionBarState: InteractionBarStateService,
    protected domSanitizer: DomSanitizer,
    protected userService: CurrentUserService,
    protected store: Store<AppState>,
    protected footerService: MessagingFooterService,
    protected overlayService: CustomOverlayService,
    protected presignedFileUploadService: PresignedFileUploadService,
  ) {
    super(
      overlayService,
      footerService,
      store,
      userService,
      domSanitizer,
      presignedFileUploadService,
    );
    this.messageForm = this.formBuilder.group({
      subject: [''],
      participants: [''],
      message: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.handleGroupChange();
    this.handleAllMessageChangeStore();
    this.loadMessagesIfNeeded();
    this.handleSaveEvent();
    this.handleAddMessages();
    this.handleUpload();
    this.handleMessageUpdates();
    this.handleDraft();
    this.handleChatMembers();
    this.updateReadStatus();

    if (!this.isTaskView) {
      this.handlePagination();
      this.handleSearchFilterChange();
      this.handleDisablingInteractionBarClose();
    }
  }

  ngAfterViewInit() {
    this.handleScrolledEvents();
  }

  trackByFn = (index: number, item: IMessage) => item.id;

  private updateReadStatus() {
    if (this.selectedGroup) {
      this.store.dispatch(
        updateMessagingGroupReadStatus({ group: this.selectedGroup, isRead: true }),
      );
    } else {
      console.warn('no selected group in discussions view (maybe because of onDestroy)');
    }
  }

  private handleGroupChange() {
    this.selectedGroup$.pipe(takeUntil(this.isDestroyed$)).subscribe((group) => {
      this.selectedGroup = group;
    });
  }

  /**
   * It should be called once on init. It loads ONE TIME the most recent messages from the store.
   * @private
   */
  private handleAllMessageChangeStore() {
    // Warning! don't use first() with takeUntil().
    // If unsure, visit: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    this.getAllMessages$
      .pipe(
        filter((data: IMessage[]) => !!data.length),
        takeUntil(this.isDestroyed$),
        take(1),
      )
      .subscribe((allMessages: IMessage[]) => {
        this.allMessages = DeepCopyService.deepCopy(allMessages);
        this.sortMessages();

        this.scrollToBottom();
      });
  }

  private loadMessagesIfNeeded() {
    if (!this.isTaskView && !this.allMessages) {
      this.store.dispatch(loadMessages());
    }
  }

  /**
   * Load draft message if exists once.
   * @private
   */
  private handleDraft() {
    this.store
      .select(getTopLevelDraft)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((data) => !!data),
        take(1),
      )
      .subscribe((draft) => {
        if (!!this.editorOutput) {
          // this is important: don't overwrite the user's message with a draft
          return;
        }
        this.editorInput = draft.body;
      });
  }

  /**
   * Get paginated messages when needed.
   * @private
   */
  private handlePagination() {
    this.loadPaginatedMessages
      .pipe(debounceTime(100), takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.store.dispatch(messagesActions.loadMessagesPaginated({}));
      });
  }

  /**
   * It subscribes to the updateMessage$ observable which emits when a message
   * arrives via socket and the local version should be updated.
   * @private
   */
  private handleMessageUpdates(): void {
    this.messageService.updateMessage$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((message: IMessage) => {
        const messageIndex = this.allMessages.findIndex(
          (mess) => mess.id === message.id || (mess.isLocalOnly && message.body === mess.body),
        );
        if (messageIndex !== -1) {
          this.allMessages[messageIndex] = message;
        }
      });
  }

  /**
   * it subscribes to the addMessage observable which emits when a message comes on websocket
   * OR a message is added locally only
   * @private
   */
  private handleAddMessages() {
    this.messageService.addMessage$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        ({
          messages,
          shouldScrollToBottom,
          messageFromOtherUser,
        }: {
          messages: IMessage[];
          shouldScrollToBottom: boolean;
          messageFromOtherUser: boolean;
        }) => {
          // these users were loaded in the past, and they have all the user data (with profile pic)
          const loadedUserIds = [...new Set(this.allMessages?.map((m) => m.user?.id))];
          console.log('discussion view socket message', messages);

          messages?.forEach((newMessage) => {
            this.addNewMessageToLocalVersion(newMessage);
          });

          if (this.lastScrollPosition < 1) {
            this.scrollbarRef.scrollTo({
              duration: 0,
              top: 1,
            });
          }

          // if got a new message on socket which is written by my own user
          if (shouldScrollToBottom && !messageFromOtherUser) {
            // this.shouldScrollToBottom = false;
            this.scrollToBottom();
          }

          // if got a message from an other user and the scrollbar is near to bottom, scroll all a way down
          if (
            shouldScrollToBottom &&
            messageFromOtherUser &&
            this.scrollMaxPosition - this.lastScrollPosition - this.scrollHeight < 100
          ) {
            this.scrollToBottom();
          }

          this.loadMissingProfilePictures(loadedUserIds);
          this.sortMessages();
        },
      );
  }

  /**
   * Subscribes to the event of filtered messages changed
   * which emits after the user searches for a message and got a response.
   * @private
   */
  private handleSearchFilterChange() {
    this.messageService.filteredMessages.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      // this.disableInfiniteScroll = !!data.filters.search;
      // this setTimout is needed in order to disableInfiniteScroll to take effect in the corresponding pipe.
      setTimeout(() => {
        this.allMessages = DeepCopyService.deepCopy(data.messages);
        this.sortMessages();
      });

      this.scrollToBottom();
    });
  }

  private handleDisablingInteractionBarClose() {
    this.overlayService
      .isOpened$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((isOpened) => {
        this.interactionBarState.setDisableClose(isOpened);
      });
  }

  /**
   * after loading the initial messages configure the scrollbar to call for an update when needed
   */
  handleScrolledEvents() {
    this.isLoading$
      .pipe(
        filter((isloading) => !isloading),
        delay(100), // needed to wait for the scrollbar to render
        take(1),
        mergeMap(() => {
          this.scrollToBottom();
          return this.scrollbarRef?.scrolled.pipe(takeUntil(this.isDestroyed$), debounceTime(10));
        }),
      )
      .subscribe((e: Event) => {
        const position = (e.target as HTMLElement).scrollTop;
        const maxPos = (e.target as HTMLElement).scrollHeight;
        if (maxPos - position < 50) {
          console.log('scroll to bottom from handleScrolledEvents');
          this.scrollToBottom();
        }
        // todo check for double loading
        if (position < this.lastScrollPosition - this.SCROLL_MIN_DIF) {
          // the logic of when to load more messages changes based on how many messages are loaded yet
          // if more messages are loaded user needs to scroll higher.
          const scrolledRatio = position / maxPos;
          if (
            (this.allMessages.length < 50 && scrolledRatio < this.SCROLL_UPDATE_PERCENT) ||
            (this.allMessages.length >= 50 && scrolledRatio * 2 < this.SCROLL_UPDATE_PERCENT)
          ) {
            this.loadPaginatedMessages.next(true);
          }
        }
        this.lastScrollPosition = position;
        this.scrollMaxPosition = maxPos;
        this.scrollHeight = (e.target as HTMLElement).clientHeight;
      });
  }

  /**
   * called when a user wants to add/remove a user to the group.
   * @param event
   */
  onMemberOptionSelected(event: any) {
    if (event.action === 'select') {
      if (!this.showRelatedUsers) {
        return;
      }

      this.notif
        .showPopup('Are you sure you want to add a new member to the discussion?')
        .then((shouldAdd) => {
          if (shouldAdd) {
            this.store.dispatch(
              addDiscussionMembersToBackend({ member: event.user, group: this.selectedGroup }),
            );
          }
        });
      return;
    }

    this.notif.showPopup('Are you sure you want to remove member?').then((shouldDelete) => {
      if (shouldDelete) {
        this.store.dispatch(
          deleteGroupMemberFromBackend({ member: event.user, group: this.selectedGroup }),
        );
      }
    });
  }

  private isAdminUser() {
    return !!this.usersData.discussionMembers.find(
      (selected) => selected.user_id === String(this.userService.data.id) && selected.is_admin,
    );
  }

  loadUsers(searchValue: string = '') {
    // this.showRelatedUsers = showRelatedUsers;
    this.store.dispatch(loadSearchedMessagingUsers({ search: searchValue }));
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }

  /**
   * It completes messages' user data which do not have it.
   * @param newMessage
   * @private
   */
  private addNewMessageToLocalVersion(newMessage: IMessage) {
    const message: IMessage = DeepCopyService.deepCopy(newMessage);
    if (!message.user?.name) {
      // fill in missing user info if message is added only locally
      const messageWithInfo = this.allMessages.find(
        (m) => m.user.id === message.user.id && !!m.user.company_name && !!m.user.name,
      );
      if (messageWithInfo) {
        message.user = messageWithInfo.user;
      }
    }
    // unshift is needed because it does not create a new array thus improves rendering performance
    // check for thread id
    if (!message.thread_id) {
      this.allMessages.unshift(message);
    } else {
      const threadHead = this.allMessages.find((m) => m.id === message.thread_id);
      if (threadHead) {
        threadHead.is_read = message.user.id === this.userService.data?.id; // if message from current user it is read
        threadHead.thread.unshift(message);
      }
    }
  }

  /**
   * Check if there are users which do not have a profile picture loaded and load if needed.
   * @param loadedUserIds
   * @private
   */
  private loadMissingProfilePictures(loadedUserIds: number[]) {
    // these users we have after adding more messages
    const updatedUserIds = [...new Set(this.allMessages.map((message) => message.user?.id))];
    if (loadedUserIds.length !== updatedUserIds.length) {
      // load profile pictures not loaded yet.
      const diff = updatedUserIds.filter(
        (updatedUserId) => !loadedUserIds.find((loadedUserId) => loadedUserId === updatedUserId),
      );
      // if diff is not empty that means there are new users (which were not included in this.allMessages)
      // so we dispatch an action to load missing profile pics
      diff.forEach((userId) => {
        const user = this.allMessages.find((m) => m.user?.id === userId)?.user;
        this.store.dispatch(loadSingleProfilePicture({ user: user as IMessagingUser }));
      });
    }
  }

  clearSearchInput() {
    this.messageForm.get('participants').setValue('');
  }

  getOpenPosition(dropDownOverlay: HTMLElement) {
    const pos = dropDownOverlay.getClientRects()[0];
    return { x: pos?.x, y: pos?.bottom };
  }

  private sortMessages() {
    this.allMessages.sort((a, b) => {
      return moment(a.updated_at).isBefore(b.updated_at) ? -1 : 1;
    });
  }

  private scrollToBottom(duration = 200) {
    const intervalId = setInterval(async () => {
      if (!this.scrollbarRef) {
        return;
      }
      clearInterval(intervalId);
      await this.scrollbarRef.scrollTo({
        duration,
        bottom: 0,
      });
      this.scrollbarRef?.update();
    }, 10);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.updateReadStatus();
  }
}
