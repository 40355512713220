import { Component, Input } from '@angular/core';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-formatted-money',
  templateUrl: './formatted-money.component.html',
  styleUrls: ['./formatted-money.component.scss'],
  standalone: true,
  imports: [NgClass, MatTooltip, MoneyPipe],
})
export class FormattedMoneyComponent {
  @Input() value: number;
}
