import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ITaskUsersListData } from '../../../../store/messages/messages.interfaces';
import {
  getAllCheckList,
  getAllTaskSidebarFiles,
  getAssignedUsers,
  getSpectatorUsers,
  getTaskAssignedUsersListData,
  getTaskSpectatorUsersListData,
} from '../../../../store/tasks/tasks.selectors';
import {
  IChecklist,
  IChecklistItem,
  IFile,
  ITaskMember,
  ITaskSideBar,
} from '../../../../store/tasks/tasks.interfaces';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  addFiles,
  addSpectatorUser,
  loadUsers,
  removeCheckList,
  removeFile,
  removeSpectatorUser,
  setAssignedUsers,
  updateCheckList,
} from '../../../../store/tasks/tasks.actions';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { emptyCheckList, NO_CHECKLIST, noCheckList } from '../../../../store/tasks/tasks.constants';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { TasksInteractionBarCommonComponent } from '../tasks-interaction-bar-common/tasks-interaction-bar-common.component';
import { DateCustomPipe } from '../../../../pipes/framework/date-custom.pipe';
import { NotificationsService } from '../../../../services/notifications.service';
import { dropdownOverlayPositions, SELECTED_OPTION } from '../../../overlays/option-list.constants';
import { iconDropdown } from '../../../constants/general.constants';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { ChecklistDropdownComponent } from '../../../inputs/checklist-dropdown/checklist-dropdown.component';
import { DefaultUploadUiComponent } from '../../../upload/default-upload-ui/default-upload-ui.component';
import { UploadHiddenInputComponent } from '../../../upload/upload-hidden-input/upload-hidden-input.component';
import { FadedTextComponent } from '../../../faded-text/faded-text.component';
import { CustomInputOverlayDropdownComponent } from '../../../custom-input-overlay-dropdown/custom-input-overlay-dropdown.component';
import { TaskUsersDropdownComponent } from '../../../task-users-dropdown/task-users-dropdown.component';
import { OptionsListGeneralComponent } from '../../../overlays/options-list-general/options-list-general.component';
import { NgFor, NgIf, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-tasks-interaction-bar-second-page',
  templateUrl: './tasks-interaction-bar-second-page.component.html',
  styleUrls: ['./tasks-interaction-bar-second-page.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CdkOverlayOrigin,
    NgFor,
    OptionsListGeneralComponent,
    TaskUsersDropdownComponent,
    CustomInputOverlayDropdownComponent,
    FadedTextComponent,
    NgIf,
    UploadHiddenInputComponent,
    DefaultUploadUiComponent,
    ChecklistDropdownComponent,
    FloatingInputComponent,
    NgClass,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class TasksInteractionBarSecondPageComponent
  extends TasksInteractionBarCommonComponent
  implements OnInit
{
  @Input() model: Partial<ITaskSideBar> = {
    checklist: {
      name: '',
      items: [],
    },
  };
  getTaskAssignedUsersListData$: Observable<ITaskUsersListData> = this.store.select(
    getTaskAssignedUsersListData,
  );
  getTaskSpectatorUsersListData$: Observable<ITaskUsersListData> = this.store.select(
    getTaskSpectatorUsersListData,
  );
  getAllTaskSidebarFiles$: Observable<IFile[]> = this.store.select(getAllTaskSidebarFiles);
  getAllCheckList$: Observable<IChecklist[]> = this.store.select(getAllCheckList);
  getAssignedUsers$: Observable<ITaskMember[]> = this.store.select(getAssignedUsers);
  getSpectatorUsers$: Observable<ITaskMember[]> = this.store.select(getSpectatorUsers);
  assignedUsersSearch = '';
  spectatorUsersSearch = '';
  noCheckList: IChecklist = noCheckList;
  emptyCheckList: IChecklist = emptyCheckList;
  isDropdownShown = {
    responsibilityMembers: false,
  };
  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;
  protected readonly iconDropdown = iconDropdown;

  constructor(
    protected store: Store<AppState>,
    protected interactionBarStateService: InteractionBarStateService,
    protected timeZonePipe: DateCustomPipe,
    private notif: NotificationsService,
  ) {
    super(store, interactionBarStateService, timeZonePipe);
  }

  get isNewChecklistInput(): boolean {
    return !this.model?.checklist?.id;
  }

  trackByIndex = (index: number) => index;

  ngOnInit(): void {}

  onAssignedMemberOptionSelected(event: { action: string; user: ITaskMember }) {
    this.store.dispatch(setAssignedUsers({ users: [event.user] }));
  }

  onSpectatorMemberOptionSelected(event: { action: string; user: ITaskMember }) {
    this.store.dispatch(addSpectatorUser({ user: event.user }));
    this.clearSearchInput();
  }

  clearSearchInput() {
    this.assignedUsersSearch = '';
    this.spectatorUsersSearch = '';
  }

  removeSpectator(spectator: ITaskMember) {
    this.store.dispatch(removeSpectatorUser({ user: spectator }));
  }

  registerFileAdded(files: IFile[]) {
    this.registerFileUpload(files);
  }

  loadUsers(search: string = '') {
    this.store.dispatch(loadUsers({ search }));
  }

  userSearchChange(search: string) {
    this.loadUsers(search);
  }

  registerFileUpload(event: IFile[]) {
    const files = Array.from(event);
    this.store.dispatch(
      addFiles({
        files,
      }),
    );
  }

  removeFile({ file, index }: { file: File | IFile; index: number }) {
    this.store.dispatch(removeFile({ index }));
  }

  addCheckListItem() {
    this.model = DeepCopyService.deepCopy(this.model);
    if (!this.model?.checklist?.items?.length) {
      this.model.checklist.items = [];
    }
    this.model.checklist.items.push({ name: '' });
    this.checkListChange();
  }

  checkListOptionSelected(event: {
    action: SELECTED_OPTION;
    checklist?: IChecklist;
    index?: number;
  }) {
    switch (event.action) {
      case SELECTED_OPTION.SELECT:
        this.model.checklist = event.checklist;
        this.store.dispatch(updateCheckList({ checklist: this.deepCopyModel.checklist }));
        break;
      case SELECTED_OPTION.ADD:
        this.model.checklist = DeepCopyService.deepCopy(this.emptyCheckList);
        this.store.dispatch(updateCheckList({ checklist: this.deepCopyModel.checklist }));
        break;
      case SELECTED_OPTION.DESELECT:
        this.model.checklist = this.noCheckList;
        this.store.dispatch(updateCheckList({ checklist: this.deepCopyModel.checklist }));
        break;
      case SELECTED_OPTION.REMOVE:
        this.store.dispatch(removeCheckList({ checklist: event.checklist, index: event.index }));
        if (this.model?.checklist?.id === event.checklist?.id) {
          this.checkListOptionSelected({ action: SELECTED_OPTION.DESELECT });
        }
        break;
    }
  }

  checkListChange() {
    this.store.dispatch(updateCheckList({ checklist: this.deepCopyModel.checklist }));
  }

  removeChecklistItem(item: IChecklistItem, index: number) {
    this.model = DeepCopyService.deepCopy(this.model);
    this.model.checklist.items = [...this.model.checklist.items.filter((_, i) => i !== index)];
    this.checkListChange();
  }

  isFormValid(): boolean {
    this.taskForm.form.markAllAsTouched();
    const validChecklistItems =
      !!this.model?.checklist?.items?.length || this.model.checklist.id === NO_CHECKLIST;
    if (!validChecklistItems) {
      this.notif.showError('Please add items to the checklist');
    }
    return this.taskForm.form.valid && validChecklistItems;
  }

  showDropdown() {
    this.loadUsers();
    this.isDropdownShown.responsibilityMembers = true;
  }

  handleDropdownShowChange(isShown: boolean) {
    if (!isShown) {
      this.isDropdownShown.responsibilityMembers = false;
      this.clearSearchInput();
    }
  }

  checkListNameChange($event: any) {
    this.model = DeepCopyService.deepCopy(this.model);
    this.model.checklist.name = $event;
    this.checkListChange();
  }

  checkListItemChange(name: string, index: number) {
    this.model = DeepCopyService.deepCopy(this.model);
    this.model.checklist.items[index].name = name;
    this.checkListChange();
  }
}
