import { createFeatureSelector, createSelector } from '@ngrx/store';
import { rollupFeatureKey, RollupState } from './rollups.reducer';
import {
  IRollupAccount,
  IRollupAccountsTableTotals,
  IRollupAccountTable,
  IRollupLineItems,
  IRollupLineItemTable,
  IRollupLineItemTableTotals,
  IRollupProject,
  IRollupProjectTable,
  IRollupProjectTableTotals,
  IRollupTag,
  IRollupTagTable,
  IRollupTagTotals,
} from './rollups.interface';
import { emptyTotals } from './rollups.constants';

export const getRollupState = createFeatureSelector<RollupState>(rollupFeatureKey);

export const getAllProjects = createSelector(getRollupState, (state) => state.projects_list);
export const getAllLineItems = createSelector(getRollupState, (state) => state.line_items_list);
export const getAllTags = createSelector(getRollupState, (state) => state.tags_list);

export const getRollupProjectsList = createSelector(getAllProjects, (projects) =>
  projects.map((project) => transformProjectTable(project)),
);

export const getRollupLineItemsList = createSelector(getAllLineItems, (lineItems) =>
  lineItems.map((bli) => transformRollupLineItemsTable(bli)),
);

// todo: remove accounts from rollup
// export const getRollupAccountsList = createSelector(getRollupState, (state) =>
//   state.accounts_list.map((acc) => transformRollupAccountsTable(acc))
// );

export const getRollupTagsList = createSelector(getAllTags, (tags) =>
  tags.map((acc) => transformRollupTagsTable(acc)),
);

export const getRollupSelectedData = createSelector(
  getRollupState,
  (state) => state.selectedRollupData,
);
export const getRollupViewType = createSelector(
  getRollupState,
  (state) => state.selectedRollupViewType,
);
export const isLoading = createSelector(getRollupState, (state) => state.isLoading);
export const isFetching = createSelector(getRollupState, (state) => state.isFetching);

const sumTotalProjects = (
  acc: IRollupProjectTableTotals,
  curr: IRollupProjectTableTotals,
): IRollupProjectTableTotals => {
  const data: IRollupProjectTableTotals = {
    current_budget: acc.current_budget + curr.current_budget,
    contracts: acc.contracts + curr.contracts,
    total_committed: acc.total_committed + curr.total_committed,
    actuals: acc.actuals + curr.actuals,
    forecasts_to_complete: acc.forecasts_to_complete + curr.forecasts_to_complete,
    total_committed_vs_current_budget:
      acc.total_committed_vs_current_budget + curr.total_committed_vs_current_budget,
    complete: acc?.complete ?? 0 + curr?.complete ?? 0,
  };
  data.complete = calculatePercentage(data);
  return data;
};

const sumTotalGeneral = (acc, curr) => {
  const data = {
    current_budget: acc.current_budget + curr.current_budget,
    total_committed: acc.total_committed + curr.total_committed,
    actuals: acc.actuals + curr.actuals,
    total_committed_vs_current_budget:
      acc.total_committed_vs_current_budget + curr.total_committed_vs_current_budget,
    complete: acc?.complete ?? 0 + curr?.complete ?? 0,
  };
  data.complete = calculatePercentage(data);
  return data;
};

export const getRollupProjectsListTotal = createSelector(getRollupState, (state) => {
  const totals = state.projects_list
    .map((project): IRollupProjectTableTotals => {
      return {
        current_budget: project.cash_flow.current_budget,
        contracts: project.cash_flow.contracts,
        total_committed: project.cash_flow.total_committed,
        actuals: project.cash_flow.actuals,
        forecasts_to_complete: project.cash_flow.forecasts_to_complete,
        total_committed_vs_current_budget:
          project.cash_flow.current_budget - project.cash_flow.total_committed,
        complete: calculatePercentage(project.cash_flow),
      };
    })
    .reduce(sumTotalProjects, emptyTotals);
  totals.title = 'Totals';
  totals.total_projects =
    state.projects_list.length === 1
      ? `${state.projects_list.length} Project`
      : `${state.projects_list.length} Projects`;
  return totals;
});

export const getRollupLineItemsListTotal = createSelector(getRollupState, (state) => {
  const totals = state.line_items_list
    .map((bli): IRollupLineItemTableTotals => {
      return {
        current_budget: bli.cash_flow.current_budget,
        total_committed: bli.cash_flow.total_committed,
        total_committed_vs_current_budget:
          bli.cash_flow.current_budget - bli.cash_flow.total_committed,
        actuals: bli.cash_flow.actuals,
        complete: calculatePercentage(bli.cash_flow),
      };
    })
    .reduce(sumTotalGeneral, emptyTotals);
  totals.title = 'Totals';
  return totals;
});

export const getRollupAccountsListTotal = createSelector(getRollupState, (state) => {
  const totals = state.accounts_list
    .map((account: IRollupAccount): IRollupAccountsTableTotals => {
      return {
        current_budget: account.cash_flow.current_budget,
        total_committed: account.cash_flow.total_committed,
        total_committed_vs_current_budget:
          account.cash_flow.current_budget - account.cash_flow.total_committed,
        actuals: account.cash_flow.actuals,
        complete: calculatePercentage(account.cash_flow),
      };
    })
    .reduce(sumTotalGeneral, emptyTotals);
  totals.title = 'Totals';
  return totals;
});

export const getRollupFilters = createSelector(getRollupState, (state) => state.filters);

const sumTagsTotal = (acc, curr): IRollupTagTotals => {
  const data: IRollupTagTotals = {
    total_committed: acc.total_committed + curr.total_committed,
    actuals: acc.actuals + curr.actuals,
    actuals_vs_total_committed: acc.actuals_vs_total_committed + curr.actuals_vs_total_committed,
    complete: acc?.complete ?? 0 + curr?.complete ?? 0,
  };
  data.complete = calculatePercentage(acc);
  return data;
};
export const getRollupTagsListTotal = createSelector(getRollupState, (state) => {
  const totals = state.tags_list
    .map((tag: IRollupTag): IRollupTagTotals => {
      return {
        total_committed: tag.cash_flow.total_committed,
        actuals: tag.cash_flow.actuals,
        actuals_vs_total_committed: tag.cash_flow.total_committed - tag.cash_flow.actuals,
        complete: calculatePercentage(tag.cash_flow),
      };
    })
    .reduce(sumTagsTotal, emptyTotals);
  totals.title = 'Totals';
  return totals;
});

export const getRollupShowZeroDollarLines = createSelector(getRollupState, (state) => {
  return state.showZeroDollarLines;
});

const transformProjectTable = (project: IRollupProject): IRollupProjectTable => {
  const { cash_flow, ...rest } = project;
  return {
    ...rest,
    current_budget: cash_flow.current_budget,
    contracts: cash_flow.contracts,
    total_committed: cash_flow.total_committed,
    actuals: cash_flow.actuals,
    forecasts_to_complete: cash_flow.forecasts_to_complete,
    total_committed_vs_current_budget: cash_flow.current_budget - cash_flow.total_committed,
    complete: calculatePercentage(cash_flow),
  };
};

const transformRollupLineItemsTable = (lineItems: IRollupLineItems): IRollupLineItemTable => {
  const { cash_flow, ...rest } = lineItems;
  return {
    ...rest,
    current_budget: cash_flow.current_budget,
    actuals: cash_flow.actuals,
    total_committed: cash_flow.total_committed,
    total_committed_vs_current_budget: cash_flow.current_budget - cash_flow.total_committed,
    complete: calculatePercentage(cash_flow),
  };
};

const transformRollupAccountsTable = (accounts: IRollupAccount): IRollupAccountTable => {
  const { cash_flow, ...rest } = accounts;
  return {
    ...rest,
    current_budget: cash_flow.current_budget,
    actuals: cash_flow.actuals,
    total_committed: cash_flow.total_committed,
    total_committed_vs_current_budget: cash_flow.current_budget - cash_flow.total_committed,
    complete: calculatePercentage(cash_flow),
  };
};

const transformRollupTagsTable = (tags: IRollupTag): IRollupTagTable => {
  const { cash_flow, ...rest } = tags;
  return {
    ...rest,
    actuals: cash_flow.actuals,
    total_committed: cash_flow.total_committed,
    actuals_vs_total_committed: cash_flow.total_committed - cash_flow.actuals,
    complete: calculatePercentage(cash_flow),
  };
};

const calculatePercentage = (data) => {
  if (data.actuals === 0 || data.total_committed === 0) {
    return 0;
  }
  const complete = data.actuals / data.total_committed;
  return Math.round(complete * 100);
};
