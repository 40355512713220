import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [FloatingInputComponent],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() placeholder = 'Search...';
  @Output() searchText = new EventEmitter<string>();
  model = '';
  inputChange = new Subject<string>();

  ngOnInit(): void {
    this.inputChange.pipe(debounceTime(300)).subscribe((value) => {
      this.searchText.next(value);
    });
  }

  onModelChange(text: string) {
    this.inputChange.next(text);
  }

  ngOnDestroy() {
    this.inputChange.complete();
  }
}
