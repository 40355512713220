import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { fadeIn } from '../../../../assets/styles/animations';
import { CommonModule } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [CommonModule, MatFormField, MatInput, ReactiveFormsModule],
})
export class SearchBarComponent implements OnInit {
  @ViewChild('inputField') field;
  @Input() placeholder = 'Search';
  @Input() alignRight = false;
  @Input() iconToRight = false;
  showSearchbarProj = false;
  input = new UntypedFormControl('');

  @Output() searchEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  /**
   * Show input field insted of placeholder text.
   */
  showInput() {
    this.showSearchbarProj = true;

    setTimeout(() => {
      this.field.nativeElement.focus();
    }, 200);
  }

  /**
   * In case of searched value is not null and change is detected
   * notify parent of user search intention.
   *
   * In case of no search data restores placeholder.
   */
  checkSearchValue() {
    if (this.input.value.length !== 0 || !this.input.value) {
      this.showSearchbarProj = false;
      console.log(this.input.value);
      this.searchEvent.emit(this.input.value);
    } else {
      this.searchEvent.emit(null);
      console.log('search');
    }
  }
}
