<div class="search-container">
  <mat-form-field [subscriptSizing]="'dynamic'" class="input placeholder-color_accept borderless">
    <input
      [tabIndex]="tabIndex"
      [value]="searchInput"
      (input)="searchTextChanged($event)"
      [placeholder]="placeholder"
      type="text"
      matInput
    />
  </mat-form-field>
  <span class="icon-search"></span>
</div>
