<div
  [ngClass]="{
    'task-daily': task.type === 'daily' && !task.is_spectating,
    'task-custom': task.type === 'custom' && !task.is_spectating,
    'task-spectating': task.is_spectating
  }"
  class="task-small-view-container"
>
  <div class="header">
    <span (click)="taskService.openDetails(task.id)" class="pointer-cursor task-title">{{ task.title }}</span>
    <span
      #optionsTrigger="cdkOverlayOrigin"
      (click)="showOptions = !showOptions"
      cdkOverlayOrigin
      class="icon-options pointer-cursor"
    ></span>
    <app-options-list-simple
      (optionSelected)="taskService.optionSelected(task, $event)"
      [(show)]="showOptions"
      [actions]="actions"
      [trigger]="optionsTrigger"
    ></app-options-list-simple>
  </div>
  <div class="content">
    <div class="info-row">
      <span class="info-title">Project </span>
      <ng-container *ngIf="task.project">
        <span [routerLink]="['/webapp', 'projects', task.project.id]" class="project-name"
          >{{ task.project.title }}
        </span>
      </ng-container>
      <ng-container *ngIf="!task.project">
        <span>None</span>
      </ng-container>
    </div>
    <div class="info-row">
      <span class="info-title">Assigned</span>
      <span>{{ task.assigned_members?.length > 0 ? task.assigned_members[0]?.name : 'None' }}</span>
    </div>
    <div class="info-row info-row-icons">
      <span class="info-title">Due</span>
      <span>{{ task.end_date ? (task.end_date | datepipe: DATE_FORMAT : NO_TIMEZONE) : 'None' }}</span>
      <span class="icons-container">
        <span *ngIf="task.requires_response" [matTooltip]="'Requires response'" matTooltipPosition="above">
          <span class="icon-warning"></span>
        </span>
        <span>
          <app-tasks-message-count [number]="task.messages_count"></app-tasks-message-count>
        </span>
        <span *ngIf="task.status === TASK_STATUS_VALUE.PENDING" [matTooltip]="'Pending'" matTooltipPosition="above">
          <span class="icon-lightning-filled text-shade_4"></span>
        </span>
        <span
          *ngIf="task.status === TASK_STATUS_VALUE.IN_PROGRESS"
          [matTooltip]="'In progress'"
          matTooltipPosition="above"
        >
          <span class="icon-lightning-filled text-shade_1"></span>
        </span>
        <span *ngIf="task.status === TASK_STATUS_VALUE.COMPLETED" [matTooltip]="'Completed'" matTooltipPosition="above">
          <span class="icon-approved text-color_accept"></span>
        </span>
      </span>
    </div>
    <div *ngIf="task.checklist" class="info-row checklist">
      <span class="info-title">Checklist </span>
      <span class="flex flex-1 justify-between">
        <span>{{ checkedItems }} of {{ task.checklist.items.length }}</span>

        <span
          #checklistIcon
          #trigger="cdkOverlayOrigin"
          (mouseenter)="showChecklistItem = true"
          (mouseleave)="showChecklistItem = false"
          [ngClass]="checkedItems === task.checklist.items.length ? 'text-color_accept' : 'text-shade_4'"
          cdkOverlayOrigin
          class="icon-info-filled text-size_icon leading-none relative"
        ></span>
        <app-checklist-popup
          [(show)]="showChecklistItem"
          [checklistItems]="task.checklist.items"
          [trigger]="trigger"
        ></app-checklist-popup>
      </span>
    </div>
  </div>
</div>
