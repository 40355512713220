<div class="task-category">
  <div class="category-title">{{ type }}</div>
  <!--  if the first page is loading and no tasks are in the store,
    show the spinning circle loading icon and hide anything else

    if there are tasks to show, but (the first page) is refreshing,
    show that tasks and the progress bar to indicate loading
    this can happen if the tasks were loaded before and the user navigates back to this page
    -->
  <ng-container *ngIf="!(isLoading$ | async) || (getTasks$ | async).length !== 0; else isLoading">
    <div *ngIf="isPaginationLoading$ | async" class="progress-bar-container">
      <mat-progress-bar class="custom-loading-progress-bar" mode="indeterminate"></mat-progress-bar>
    </div>
    <ng-scrollbar #scrollbar appearance="compact" class="std-scrollbar inner-scrollbar" track="vertical">
      <div class="cards-container">
        <ng-container *ngFor="let task of getTasks$ | async">
          <app-task-kanban-item [task]="task"></app-task-kanban-item>
        </ng-container>
        <ng-container *ngIf="!(getTasks$ | async)?.length">
          <div
            (click)="openTasksSidebar()"
            *ngIf="type === TASK_KANBAN_COLUMN_TYPES.DUE_THIS_WEEK"
            class="missing-tasks add-task"
          >
            Add Task
          </div>
          <div *ngIf="type === TASK_KANBAN_COLUMN_TYPES.UPCOMING" class="missing-tasks no-tasks">No Tasks</div>
          <div *ngIf="type === TASK_KANBAN_COLUMN_TYPES.SPECTATING" class="missing-tasks no-tasks">No Tasks</div>
        </ng-container>
      </div>
    </ng-scrollbar>
  </ng-container>

  <ng-template #isLoading>
    <div class="h-100 flex justify-center items-start mt-5">
      <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
    </div>
  </ng-template>
</div>
