export enum SPEND_TYPES {
  BUDGET = 'Budget',
  ACTUALS = 'Actuals',
  FORECAST = 'Forecast',
}

export enum SPEND_ACTIONS {
  REFRESH,
  SHOW_M,
  HIDE_M,
  OPEN,
  CLOSE,
  SAVE,
  UPDATE,
  RESET_YEAR,
  CLOSE_SHEETS,
  CHANGE_BUDGET_TYPE,
  LOCK_BUDGET,
}

// this is used in spend service
export const MONTH_IS_BEFORE_YEAR = -1;
export const MONTH_IS_AFTER_YEAR = 13;
