import { Component, ViewChild } from '@angular/core';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { NgClass, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { PROJECT_STATUS_KEY } from '../../constants/project.constants';
import { TagInputComponent } from '../../inputs/tag-input/tag-input.component';
import { Project, ProjectStatus } from '../../../pages/webapp/projects/projects.interface';
import { getStoreUpdates } from '../../../store/spend/spend.selectors';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../services/notifications.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-state';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { ILineItem } from '../../../store/spend/spend.interfaces';
import { TooltipModule } from 'primeng/tooltip';
import dayjs from 'dayjs';
import { propertiesActions } from '../../../store/properties/properties.actions';
import { propertiesSelectors } from '../../../store/properties/properties.selector';
import { projectActions } from '../../../store/projects/projects.actions';
import { getAllBackendStatuses } from '../../../store/projects/projects.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { ROUTE_WEBAPP } from '../../constants/route.webapp.constants';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { loadSpends } from '../../../store/spend/spend.actions';
import { IProperty } from '../../../store/properties/properties.interfaces';

@Component({
  selector: 'app-project-setup',
  standalone: true,
  imports: [
    DropdownComponent,
    FloatingInputComponent,
    FormsModule,
    InputCalendarComponent,
    NgIf,
    NgScrollbar,
    NgClass,
    TagInputComponent,
    TooltipModule,
  ],
  templateUrl: './project-setup.component.html',
  styleUrl: './project-setup.component.scss',
})
export class ProjectSetupComponent {
  @ViewChild('summaryScroll', { static: true }) summaryScroll: NgScrollbar;
  @ViewChild('setupForm', { static: true }) setupForm: NgForm;

  PROJECT_STATUS_KEY = PROJECT_STATUS_KEY;
  projectStatuses: ProjectStatus[] = [];
  properties: IProperty[] = [];
  earliestLineItemDate: string | null;
  oldSelectedProperty: number = null;

  properties$ = this.store.select(propertiesSelectors.getAllProperties);
  projectStatuses$ = this.store.select(getAllBackendStatuses);
  lastStoreUpdate$ = this.store.select(getStoreUpdates);
  isDestroyed$ = new Subject();

  hasCommitments = false;

  model: Partial<Project> = {
    status: null,
    title: '',
    start_date: null,
    property_id: null,
    tags: [],
    id: null,
    budget_template_id: null,
    budget_template: null,
  };

  constructor(
    private notif: NotificationsService,
    private router: Router,
    private store: Store<AppState>,
    private actions: Actions,
    private interactionBarService: InteractionBarStateService,
  ) {}

  ngOnInit() {
    this.loadData();
    this.properties$.pipe(takeUntil(this.isDestroyed$)).subscribe((properties) => {
      this.properties = properties;
    });
    this.projectStatuses$.pipe(takeUntil(this.isDestroyed$)).subscribe((statuses) => {
      this.setAvailableProjectStatuses(statuses);
    });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.projectCreatedSuccessfully),
        tap(() => {
          this.notif.showSuccess('Project successfully created!');
        }),
        delay(100),
      )
      .subscribe((action) => {
        this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS, action.projectId], {});
        this.interactionBarService.close();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.updateProjectSuccess),
        tap(() => {
          this.notif.showSuccess('Project successfully updated!');
        }),
        delay(100),
      )
      .subscribe((_) => {
        // this.store.dispatch(viewProjectActions.refreshNeeded({}));
        this.interactionBarService.close();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.editProjectDataLoaded),
        tap((action) => {
          this.store.dispatch(loadSpends({ projectId: action.project.id }));
        }),
        delay(100),
      )
      .subscribe((action) => {
        this.model = {
          id: action.project.id,
          status: action.project.status,
          title: action.project.title,
          start_date: action.project.start_date,
          property_id: action.project.property_id,
          tags: [...action.project.tags],
          budget_template_id: action.project.budget_template_id,
          budget_template: action.project.budget_template,
        };
        // can edit template if no commitments
        this.hasCommitments = !action.project.can_edit_template;

        this.changeProperty(this.model.property_id);
        this.determineEarliestLineItemDate();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.successfulProjectDeletion),
        tap(() => {
          this.notif.showSuccess('Project successfully deleted!');
        }),
        delay(100),
        tap(() => {
          this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS], {});
        }),
        delay(200),
      )
      .subscribe((_) => {
        this.interactionBarService.close();
      });
  }

  loadData() {
    this.store.dispatch(propertiesActions.loadProperties({}));
    this.store.dispatch(projectActions.loadBackendStatuses());
  }

  determineEarliestLineItemDate() {
    this.lastStoreUpdate$.pipe(takeUntil(this.isDestroyed$)).subscribe((storeUpdate) => {
      const { lineItems } = storeUpdate;

      if (!lineItems || lineItems.length === 0) {
        this.earliestLineItemDate = null;
        return;
      }

      const lineItemDates = lineItems.map((item) => this.lineItemMinDate(item));
      this.earliestLineItemDate = dayjs.min(lineItemDates).format('YYYY-MM-DD');
    });
  }

  lineItemMinDate(item: ILineItem) {
    return dayjs(item.start_date).isSameOrBefore(dayjs(item.forecast_start_date))
      ? dayjs(item.start_date)
      : dayjs(item.forecast_start_date);
  }

  setAvailableProjectStatuses(statuses: ProjectStatus[]) {
    const hiddenStatuses: PROJECT_STATUS_KEY[] = [PROJECT_STATUS_KEY.DELETED];

    const filteredStatuses: ProjectStatus[] = statuses.filter((status) => {
      return !hiddenStatuses.includes(status.key);
    });

    const sortOrderByKey = {
      // the order to display
      [PROJECT_STATUS_KEY.PLANNED]: 1,
      [PROJECT_STATUS_KEY.BIDDING]: 2,
      [PROJECT_STATUS_KEY.IN_PROGRESS]: 4,
      [PROJECT_STATUS_KEY.AWARDED]: 5,
      [PROJECT_STATUS_KEY.COMPLETED]: 6,
      [PROJECT_STATUS_KEY.CANCELED]: 7,
      [PROJECT_STATUS_KEY.DELETED]: 8,
      [PROJECT_STATUS_KEY.ARCHIVED]: 9,
    };
    filteredStatuses.sort((a, b) => {
      return sortOrderByKey[a.key] - sortOrderByKey[b.key];
    });

    this.projectStatuses = filteredStatuses;
  }

  /**
   * Validates and saves project data.
   */
  save() {
    this.setupForm.form.markAllAsTouched();

    const startDateControl = this.setupForm.form.controls.start_date;
    if (startDateControl?.hasError('matDatepickerMax')) {
      this.notif.showError(
        "Start date needs to be before or equal the line item's budget or forecast start date!",
      );
      return;
    }

    if (this.setupForm.invalid) {
      this.notif.showError('Please fill in all required fields.');
      return;
    }

    if (this.model.id) {
      this.store.dispatch(projectActions.updateProject({ project: this.model }));
      return;
    }

    this.store.dispatch(projectActions.createProject({ project: this.model }));
  }

  /**
   * If properties changed, set default project template and if needed ask the
   * user if he/she wants to reset the budget.
   */
  propertiesChanged(newPropertyId: number) {
    const selectedProperty = this.properties.find((property) => property.id === newPropertyId);

    if (this.hasCommitments && selectedProperty?.team_id !== this.model?.budget_template?.team_id) {
      this.notif.showError('Property change blocked due to commitments and team mismatch.');
      this.changeProperty(this.oldSelectedProperty);
      return;
    }

    if (
      selectedProperty?.team_id === this.model?.budget_template?.team_id ||
      !this.model?.budget_template
    ) {
      this.changeProperty(newPropertyId);
    } else {
      this.notif
        .showPopup(
          'Selected property does not have the same team as the selected budget template. Template will reset. Continue?',
        )
        .then((resp) => {
          if (resp) {
            this.changeProperty(newPropertyId);
            this.model.budget_template_id = null;
            this.model.budget_template = null;
          } else {
            this.changeProperty(this.oldSelectedProperty);
          }
        });
    }
  }

  /**
   * Changes the property and filters and resets the budget template.
   */
  changeProperty(propertyId: number) {
    this.oldSelectedProperty = propertyId;
    this.model.property_id = propertyId;
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  delete() {
    this.notif.showPopup('Are you sure you want to delete this project?').then((answer) => {
      if (answer) {
        this.store.dispatch(projectActions.deleteProject({ id: this.model.id }));
      }
    });
  }
}
