import { Injectable } from '@angular/core';
import { DocumentItem } from '../store/documents/documents.reducer';
import { DriveApiService } from './drive-api.service';
import { saveAs } from 'file-saver';
import { NotificationsService } from './notifications.service';

export interface IZipStructure {
  files: { id: string | number }[];
  children: IZipStructure[];
  id?: string | number;
  name?: string;
  size?: number;
  parentFolderId?: number | string;
}

export interface IMakeCopyStructure {
  parent_id: number | string;
  children: IZipStructure[];
  files: IId[];
}

export interface IId {
  id: number | string;
}

@Injectable({
  providedIn: 'root',
})
export class DriveService {
  folderPath: Array<{ name: string; id: number }> = [];

  constructor(
    private driveApi: DriveApiService,
    private notif: NotificationsService,
  ) {}

  isFolder(document): boolean {
    return document?.children !== undefined;
  }

  isAlreadyShared(document: DocumentItem): boolean {
    return (
      !!document?.direct_link || !!document?.shared_with?.length || !!document?.bundle_links?.length
    );
  }

  isOwner(value: DocumentItem) {
    return value.owner === 'Me';
  }

  move(item: DocumentItem, folderId: number) {
    const type = {
      moveType: this.isFolder(item) ? 'moveDriveFolder' : 'moveDriveFile',
      body: this.buildBody(item, folderId),
    };
    return this.driveApi[type.moveType](type.body);
  }

  copy(parentId: number | string, selectedDocuments: DocumentItem[]) {
    const structure = this.buildZipBodyStructure(selectedDocuments);
    const body: IMakeCopyStructure = {
      parent_id: parentId,
      children: structure.children,
      files: structure.files,
    };
    return this.driveApi.copyDriveDocument(body);
  }

  revokeSharing(item: DocumentItem, email: string) {
    const type = {
      funcType: this.isFolder(item) ? 'deleteFolderShare' : 'deleteFileShare',
      idType: this.isFolder(item) ? 'folder_id' : 'file_id',
    };
    return this.driveApi[type.funcType]({ [type.idType]: item.id, email });
  }

  buildBody(item, folderId) {
    return this.isFolder(item)
      ? {
          user_drive_folder_id: item.id,
          parent_id: folderId,
        }
      : {
          user_drive_folder_id: folderId,
          user_drive_file_id: item.id,
        };
  }

  copyClipboard(link: string) {
    return navigator.clipboard.writeText(link);
  }

  downloadZip(zipStructure: IZipStructure, zipName: string, uuid: string | null = null) {
    this.driveApi.downloadZip(zipStructure, uuid).then((data) => {
      const zip = new File([data], zipName + '.zip', {
        type: 'application/zip',
      });
      saveAs(zip);
      this.notif.close();
    });
  }

  // careful: will not work on localhost, unless you are in a disabled security environment
  // open -na Google\ Chrome --args --user-data-dir=/tmp/temporary-chrome-profile-dir --disable-web-security --disable-site-isolation-trials
  downloadSingleFileS3Url(file, uuid: string | null = null) {
    this.driveApi.getFileDownloadURL(file.id, uuid).then(
      (data) => {
        saveAs(data, file.name);
        // const link = document.createElement('a');
        // link.href = data;
        // link.setAttribute('download', file.name);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        this.notif.close();
      },
      (err) => {
        console.warn(err);
        this.notif.showError(err.message);
      },
    );
  }

  /**
   *  Used to build zip download structure
   *  This function will go through the provided folder's every child,
   *  and it will be called recursively for every child(folder) until there are no more children or files
   *  it will return every file id in a folder, the folder's name and it's children(inner folders)
   * @param folder is a data structure that has children, files and a name
   */
  mapChild(folder: IZipStructure): IZipStructure {
    const document: IZipStructure = { files: [], children: [], name: undefined };
    folder.name ? (document.name = folder.name) : delete document.name;
    folder.id ? (document.id = folder.id) : delete document.id;
    if (folder?.children?.length || folder?.files?.length) {
      document.files = folder.files.map(
        (file: { id: string; name: string }): { id: string; name: string } => {
          return { id: file.id, name: file.name };
        },
      );
      document.children = folder.children.map((ch) => this.mapChild(ch));
    }
    return document;
  }

  /**
   * Create a structure for zip download containing files, children(folders) and each folder's name
   * @param selectedDocuments will contain all the documents that will be needed to create a structure for zip download
   * @param currentFolderId only in drive (excluded commitments, tasks, etc.) contains current folder ID needed for backend to create a zip structure
   */
  buildZipBodyStructure(selectedDocuments, currentFolderId: number | string = null): IZipStructure {
    let size = 0;
    let body: IZipStructure = { files: [], children: [] };
    selectedDocuments.forEach((doc) => {
      size += Number.parseFloat(doc.size) ?? 0;
      this.isFolder(doc) ? body.children.push(doc) : body.files.push(doc);
    });
    body = this.mapChild(body);
    body.size = size;
    body.parentFolderId = currentFolderId;
    return body;
  }

  downloadSelectedDocuments(
    selectedFiles: any[],
    documentName = '',
    uuid: string | null = null,
    currentFolderId: number | string | null = null,
  ) {
    this.notif.showLoading();
    const document = selectedFiles[0];
    if (
      selectedFiles.length > 1 ||
      (selectedFiles.length === 1 && this.isFolder(selectedFiles[0]))
    ) {
      const zipStructure = this.buildZipBodyStructure(selectedFiles, currentFolderId);
      this.downloadZip(zipStructure, documentName, uuid);
      return;
    }
    this.downloadSingleFileS3Url(document, uuid);
  }

  deleteFile(fileId: number, uuid: string | null = null) {
    return this.driveApi.deleteFile(fileId, uuid);
  }
}
