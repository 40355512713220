import { Pipe, PipeTransform } from '@angular/core';

/**
 * removes HTML tags from string and returns if message is empty or not
 */
@Pipe({
  name: 'isMessageEmpty',
  standalone: true,
})
export class IsMessageEmptyPipe implements PipeTransform {
  transform(body: string): boolean {
    const checkBody = body
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/\s/g, '')
      .replace(/&nbsp;/g, '');
    return !body || body === '' || checkBody === '';
  }
}
