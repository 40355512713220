<div class="messaging-tag-project-wrapper">
  <ng-scrollbar *ngIf="!isLoading" class="std-scrollbar" track="vertical" appearance="standard">
    <div (click)="onProjectClick(project)" *ngFor="let project of projectsToShow" class="user">
      {{ project.title }}
    </div>
  </ng-scrollbar>
  <div class="h-100 flex justify-center items-center" *ngIf="isLoading">
    <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
  </div>
</div>
