<ng-scrollbar class="std-scrollbar login-scrollbar" appearance="compact">
  <div class="login-wrapper">
    <h1 class="title">Sign In</h1>
    <p class="text-color_reject mt-10 h-0">
      <ng-container *ngIf="showError">
        {{ error }}
      </ng-container>
    </p>
    <div class="form" [formGroup]="user" (keyup.enter)="login()">
      <div class="mt-4">
        <app-floating-input
          [disabled]="!!invitationToken"
          ngDefaultControl
          label="Email"
          placeholder="Email"
          formControlName="email"
        >
        </app-floating-input>
        <app-floating-input
          ngDefaultControl
          type="password"
          formControlName="password"
          label="Password"
          placeholder="Password"
        >
        </app-floating-input>
      </div>
      <a [routerLink]="'/auth/reset-password'" class="text-color_secondary">Forgot password?</a>
      <div class="mt-10">
        <button
          class="landing-btn btn-big w-100 yellow-btn cubic"
          [disabled]="user.invalid || loading"
          mat-button
          (click)="login()"
          [ngClass]="{ 'btn-disabled': loading || user.invalid }"
        >
          LOGIN
        </button>
        <button
          class="navigate-to-register mt-8 landing-btn btn-big w-100 outline-btn cubic"
          mat-button
          routerLink="/auth/register"
        >
          REGISTER INSTEAD
        </button>
      </div>
    </div>
  </div>
</ng-scrollbar>
