<div class="webapp-page-container">
  <div class="text-wrapper">
    <div class="title-page default-cursor">
      <span *ngIf="!isEditMode">Add Project</span>
      <span *ngIf="isEditMode && !isBidding">Edit Project</span>
      <span *ngIf="isBidding">Post to Bidding</span>
    </div>
  </div>

  <div class="content">
    <div class="tab-group">
      <mat-tab-group
        [mat-stretch-tabs]="false"
        class="add-project"
        [dynamicHeight]="true"
        (selectedTabChange)="onTabChange()"
        #tabGroup
      >
        <mat-tab class="visited" [class]="'visited'" [ngClass]="{ visited: selectedTabIndex > 0 }" disabled="true">
          <ng-template class="visited" mat-tab-label>
            <span class="icon-checkbox-round-u"></span>
            <span>General Information</span>
          </ng-template>

          <div class="tab-group-content">
            <app-general
              #generalComponent
              (isFormValid)="isGeneralFormValid = $event"
              (projectStatusChange)="registerProjectStatusChange($event)"
              #optionTab
            ></app-general>
          </div>
        </mat-tab>

        <mat-tab disabled="true">
          <ng-template mat-tab-label>
            <span class="icon-checkbox-round-u"></span>
            <span>Project Files</span>
          </ng-template>

          <div class="tab-group-content">
            <div *ngIf="!areDocsLoaded" class="flex w-full h-full items-center justify-center">
              <div class="notify-msg animate-pulsating-border">
                <div class="icon-logo-box-hex"></div>
              </div>
            </div>
            <app-drive [ngClass]="{ 'opacity-0': !areDocsLoaded }" #driveComponent #optionTab></app-drive>
          </div>
        </mat-tab>

        <mat-tab disabled="true">
          <ng-template mat-tab-label>
            <span class="icon-checkbox-round-u"></span>
            <span>Project Budget</span>
          </ng-template>
          <div class="tab-group-content spend-distribution">
            <app-spend-distribution #spendDistribution #optionTab></app-spend-distribution>
          </div>
        </mat-tab>

        <mat-tab disabled="true" *ngIf="isScheduleActive">
          <ng-template mat-tab-label>
            <span class="icon-checkbox-round-u"></span>
            <span>Sitewalk Schedule</span>
          </ng-template>

          <div class="tab-group-content">
            <app-add-schedule [setActive]="selectedTabIndex >= 3" #optionTab></app-add-schedule>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div
        class="buttons-container"
        *ngIf="driveComponent?.selectedView.value === DRIVE_VIEWS.DEFAULT || !driveComponent?.selectedView?.value"
      >
        <button *ngIf="selectedTabIndex !== 0" class="btn back-btn" mat-button (click)="backButtonClicked()">
          Back
        </button>
        <button class="btn discard-btn" mat-button (click)="discard()">
          <span>{{ isEditMode ? 'Close' : 'Discard' }}</span>
        </button>
        <button *ngIf="isBudgetSheetClosed$ | async" class="btn discard-btn" mat-button (click)="closeSpreadsheet()">
          <span>Exit Spreadsheet View</span>
        </button>

        @if ((spendDistribution.spendState$ | async) !== SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
          <div class="btn-group">
            <div
              (click)="changeBudgetLock()"
              *ngIf="selectedTabIndex === this.budgetTabIndex"
              class="rectangle"
              [ngClass]="{ 'locked-appearance': !!isBudgetLocked }"
            >
              <div class="padlock-circle">
                <span
                  class="padlock"
                  [ngClass]="{ 'icon-padlock-unlocked': !isBudgetLocked, 'icon-padlock-locked': !!isBudgetLocked }"
                >
                </span>
              </div>
            </div>
            <button [disabled]="isValidating" class="btn btn-next" mat-button (click)="nextButtonClicked()">
              <span *ngIf="selectedTabIndex + 1 !== tabsCount"> {{ isEditMode ? 'Save & Continue' : 'Next' }} </span>
              <span *ngIf="selectedTabIndex + 1 === tabsCount">{{ isEditMode ? 'Save' : 'Post' }}</span>
              <span *ngIf="isValidating" class="icon-refresh animate-spin ml-2"></span>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</div>
