<app-empty-splash>
  <div class="flex flex-col gap-5 leading-none text-center">
    <div class="empty-text font-light text-shade_1">{{ title }}</div>
    <div *ngIf="subTitle" class="text-size_title_m font-light text-shade_1">
      {{ subTitle }}
    </div>
    <div class="m-auto flex items-center justify-center">
      <ng-content></ng-content>
    </div>
  </div>
</app-empty-splash>
