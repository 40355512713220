import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectDocumentsState, selectAll } from './projectDocuments.reducer';

export const projDocsFeatureSelector =
  createFeatureSelector<ProjectDocumentsState>('projectDocuments');

export const getAllProjDocs = createSelector(projDocsFeatureSelector, selectAll);

export const getRetrieveDisabled = createSelector(
  projDocsFeatureSelector,
  (state) => state.retrieveDisabled,
);

export const getProjDocsById = (id) =>
  createSelector(getAllProjDocs, (state) => {
    if (state) {
      return state.map((o) => o.children.find((f) => f.id === id)).filter((o) => o !== undefined);
    } else {
      return {};
    }
  });

export const areProjDocsLoaded = createSelector(projDocsFeatureSelector, (state) => state.isLoaded);

export const getSelectedProjectId = createSelector(
  projDocsFeatureSelector,
  (state) => state.projectId,
);

export const getProjectDocsSelectedFolder = createSelector(
  projDocsFeatureSelector,
  getAllProjDocs,
  (state, entities) => {
    const folder = entities.find((entity) => entity.id === state.selectedFolderId);
    return folder;
  },
);
