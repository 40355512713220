import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-sort-arrow',
  templateUrl: './sort-arrow.component.html',
  styleUrls: ['./sort-arrow.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class SortArrowComponent implements OnInit {
  @Input() hide = true;
  @Input() isDesc = true;

  constructor() {}

  ngOnInit(): void {}
}
