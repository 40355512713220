<app-editable-input
  class="flex flex-1"
  (textChange)="updateTemplateName($event)"
  [text]="template.name"
  [showInput]="showInput"
></app-editable-input>

<app-un-styled-options-list
  [listItems]="template.permissions | optionPermissions"
  (selectedOption)="registerOption($event, template)"
  #unStyledOptionsList
>
  <ng-template #projectedContent>
    <span
      *ngIf="hasPermissions"
      (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
      class="icon-options text-base pr-7 cursor-pointer text-color_secondary flex items-center"
    ></span>
  </ng-template>
</app-un-styled-options-list>
