import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationsService } from '../../services/notifications.service';
import { UsersDataSource } from './users.datasource';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchBarComponent } from '../../framework/inputs/search-bar/search-bar.component';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-admin-pannel',
  templateUrl: './admin-pannel.component.html',
  styleUrls: ['./admin-pannel.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormField,
    MatButton,
    MatTooltip,
    SearchBarComponent,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    ReactiveFormsModule,
    CdkFixedSizeVirtualScroll,
    MatInput,
  ],
})
export class AdminPannelComponent implements OnInit {
  users: any = [];
  statusMessage = 'No new users';
  editId = null;
  moment = moment;

  userForm = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    first_name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    last_name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', [Validators.required, Validators.minLength(10)]),
    password: new UntypedFormControl('', [Validators.minLength(8)]),
  });
  searchText = '';

  constructor(
    private user: CurrentUserService,
    private notif: NotificationsService,
  ) {
    // this.users = new UsersDataSource(user, notif);
  }

  ngOnInit(): void {
    this.statusMessage = 'Loading...';
    this.users = new UsersDataSource(this.user, this.notif);

    // this.users = [];
    //
    // this.user.getAllUsers().then(
    //   (data: any) => {
    //     this.users = data.data; // data.filter(u => u.status < 2).reverse();
    //     this.statusMessage = 'No new users';
    //   },
    //   err => {
    //     this.statusMessage = err;
    //   }
    // );
  }

  test(ms): Promise<any> {
    return new Promise<any>((res) => {
      setTimeout(() => {
        res(true);
      }, ms);
    });
  }

  acceptUser(item: any) {
    this.user.acceptUser(item).then(
      (data) => {
        this.notif.showSuccess('User accepted!');
        this.registerSearch(this.searchText);
      },
      (err) => {
        this.statusMessage = err;
        this.users = [];
      },
    );
  }

  deleteUser(item: any) {
    this.user.deleteUser(item).then(
      (data) => {
        this.ngOnInit();
      },
      (err) => {
        this.statusMessage = err;
        this.users = [];
      },
    );
  }

  editUser(item: any) {
    this.editId = item.id;
    this.userForm.get('company_name').setValue(item.company_name);
    this.userForm.get('last_name').setValue(item.last_name);
    this.userForm.get('first_name').setValue(item.first_name);
    this.userForm.get('phone').setValue(item.phone);
    this.userForm.get('email').setValue(item.email);
  }

  saveUser(item: any) {
    const body = this.userForm.getRawValue();

    if (body.password === '' || body.password === null) {
      delete body.password;
    } else {
      body.password_confirmation = body.password;
    }

    this.user.updateUserById(item.id, body).then(
      (data) => {
        this.users.updateUser(this.editId, body);
        this.editId = null;
        this.notif.showSuccess('User updated!');
      },
      (err) => {
        this.notif.showError(err.message);
      },
    );
  }

  registerSearch(event: any) {
    this.searchText = event;
    if (event == null) {
      this.users.searchPage('');
    } else {
      this.users.searchPage(this.searchText);
    }
  }

  verifyEmail(item: any) {
    const body = { verify_email: true };

    this.user.updateUserById(item.id, body).then(
      (_) => {
        this.notif.showSuccess('Email verified!');
        this.registerSearch(this.searchText);
      },
      (err) => {
        this.notif.showError(err.message);
      },
    );
  }
}
