import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-arrow-button-box',
  template: `
    <span
      (click)="clicked.next()"
      class="arrow-down cursor-pointer"
      [ngClass]="[thickness, direction, isFilled ? 'hovered' : '']"
    ></span>
  `,
  styleUrls: ['arrow-button-box.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class ArrowButtonBoxComponent implements OnInit {
  // activates hover state styles if set to true.
  // useful when there is text next to it and you want to change styles on text hover.
  @Input() isFilled = false;
  @Output() clicked = new EventEmitter<void>();
  @Input() thickness: 'light' | 'thicker' = 'thicker';
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'down';
  constructor() {}

  ngOnInit(): void {}
}
