<mat-expansion-panel
  [expanded]="expanded"
  (expandedChange)="onExpandChange($event)"
  class="pure-expansion-panel mat-elevation-z flex-1"
  hideToggle
>
  <mat-expansion-panel-header>
    <ng-content select="[header-row]"></ng-content>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <ng-content select="[content-row]"></ng-content>
  </ng-template>
</mat-expansion-panel>
