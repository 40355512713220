import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-arrow-button',
  standalone: true,
  imports: [NgClass],
  styleUrl: './arrow-button.component.scss',
  template: `
    <span
      [ngClass]="{ filled: filled, hovered: hovered }"
      class="outer-container flex gap-2.5 items-center cursor-pointer"
    >
      <span class="icon-container">
        <span class="icon-rounded-triangle icon"></span>
      </span>
      <span><ng-content></ng-content></span>
    </span>
  `,
})
export class ArrowButtonComponent {
  @Input() filled = false;
  @Input() hovered: boolean;
}
