import { Component, Input, OnInit } from '@angular/core';
import { BudgetAdjustmentViewComponent } from '../../budget-adjustment-view/budget-adjustment-view.component';

/**
 * @deprecated delete
 */
@Component({
  selector: 'app-budget-adjustment',
  templateUrl: './budget-adjustment.component.html',
  styleUrls: ['./budget-adjustment.component.scss'],
  standalone: true,
  imports: [BudgetAdjustmentViewComponent],
})
export class BudgetAdjustmentComponent implements OnInit {
  lineItems;
  projectId: number;
  hasBudgetTemplate = false;
  @Input() set data(value) {
    if (value) {
      this.lineItems = value.lineItems.items;
      this.projectId = value.projectId;
      this.hasBudgetTemplate = value.lineItems?.budget_template_id;
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
