import { createAction, props } from '@ngrx/store';
import {
  IRollupAccount,
  IRollupAccountTable,
  IRollupFilters,
  IRollupLineItems,
  IRollupLineItemTable,
  IRollupProject,
  IRollupTag,
  IRollupTagTable,
  ROLLUP_VIEW_TYPES,
} from './rollups.interface';

const cancel = createAction('[Rollup] cancel - do nothing');

const loadRollupByView = createAction(
  '[Rollup] load all rollup by view',
  props<{
    viewType: ROLLUP_VIEW_TYPES;
    searchText?: string;
    propertyIds?: number[];
    projectTags?: string[];
    projectStatusIds?: number[];
    projectIds?: number[];
  }>(),
);

const rollupByViewSuccessfullyLoaded = createAction(
  '[Rollup] rollup by view successfully loaded',
  props<{
    data: IRollupProject[] | IRollupLineItems[] | IRollupAccount[] | IRollupTag[];
    view: ROLLUP_VIEW_TYPES;
  }>(),
);
const rollupByViewLoadCancelled = createAction('[Rollup] rollup by view load cancelled');
const isLoading = createAction('[Rollup] rollup view is loading', props<{ isLoading: boolean }>());
const setSelectedRollup = createAction(
  '[Rollup] set selected rollup by type',
  props<{
    viewType: ROLLUP_VIEW_TYPES;
    data: IRollupTagTable | IRollupAccountTable | IRollupLineItemTable;
  }>(),
);

const clearRollups = createAction('[Rollup] clear rollups');

// currently used only for project status on rollup page
const setRollupFilters = createAction(
  '[Rollup] set rollup filters',
  props<{ filters: IRollupFilters }>(),
);

const toggleShowZeroDollarLines = createAction(
  '[Rollup] toggle show zero dollar lines',
  props<{ showZeroDollarLines: boolean }>(),
);
export const rollupActions = {
  cancel,
  loadRollupByView,
  rollupByViewSuccessfullyLoaded,
  rollupByViewLoadCancelled,
  isLoading,
  setSelectedRollup,
  clearRollups,
  setRollupFilters,
  toggleShowZeroDollarLines,
};
