import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ROLLUP_VIEW_TYPES } from '../../store/rollups/rollups.interface';
import { SearchBarMinimalistComponent } from '../search-bar-minimalist/search-bar-minimalist.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';

/**
 * @deprecated use the new GeneralHeaderComponent which corresponds to the new design.
 */
@Component({
  selector: 'app-general-header-with-search',
  templateUrl: './general-header-with-search.component.html',
  styleUrls: ['./general-header-with-search.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatFormField, MatSelect, MatOption, SearchBarMinimalistComponent],
})
export class GeneralHeaderWithSearchComponent {
  @Input() title = '';
  @Input() showBorder = true;
  @Input() searchPlaceholder = 'Search';
  @Input() showViewSelector = false;
  @Input() showPropertySelector = true;
  @Input() showPreSearchBorder = true;
  @Output() searchText = new EventEmitter<string>();
  @Output() viewSelected = new EventEmitter<number>();
  ROLLUP_VIEW_TYPES = ROLLUP_VIEW_TYPES;
  viewTitleMap = {
    [ROLLUP_VIEW_TYPES.PROJECTS]: 'Projects',
    [ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS]: 'Budget Lines',
    [ROLLUP_VIEW_TYPES.ACCOUNTS]: 'Accounts',
    [ROLLUP_VIEW_TYPES.TAGS]: 'Budget Tags',
  };
  constructor() {}

  searchTextChanged(event) {
    this.searchText.next(event);
  }

  tableViewTypeChanged(selectedView) {
    this.viewSelected.next(selectedView);
    if (selectedView && this.viewTitleMap?.[selectedView]) {
      this.title = this.viewTitleMap[selectedView];
    }
  }
}
