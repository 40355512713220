import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { getMessagingView } from '../../../store/messages/messages.selectors';
import { MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  createGroup,
  createOrUpdateMessage,
  discardMessagingGroupCache,
  setMessagingView,
} from '../../../store/messages/messages.actions';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { Subject } from 'rxjs';
import { MessagesFooterComponent } from '../messages-footer/messages-footer.component';
import { DiscussionThreadViewComponent } from '../discussion-thread-view/discussion-thread-view.component';
import { DiscussionsViewComponent } from '../discussions-view/discussions-view.component';
import { DiscussionsCreateComponent } from '../discussions-create/discussions-create.component';
import { DiscussionsListComponent } from '../discussions-list/discussions-list.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { MessagesHeaderComponent } from '../messages-header/messages-header.component';

@Component({
  selector: 'app-messages-wrapper',
  templateUrl: './messages-wrapper.component.html',
  styleUrls: ['./messages-wrapper.component.scss'],
  standalone: true,
  imports: [
    MessagesHeaderComponent,
    NgIf,
    DiscussionsListComponent,
    DiscussionsCreateComponent,
    DiscussionsViewComponent,
    DiscussionThreadViewComponent,
    MessagesFooterComponent,
    AsyncPipe,
  ],
})
export class MessagesWrapperComponent implements OnInit, OnDestroy {
  @Input() data;
  @Input() drawerState;
  MESSAGING_VIEWS = MESSAGING_VIEWS;
  selectedView$ = this.store.select(getMessagingView);
  isDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private footerService: MessagingFooterService,
  ) {}

  ngOnInit(): void {
    this.handleSaveAction();
    this.handleDiscardAction();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
  //
  private handleSaveAction() {
    this.footerService.save
      .pipe(takeUntil(this.isDestroyed$), withLatestFrom(this.selectedView$))
      .subscribe(([_, view]) => {
        switch (view) {
          case MESSAGING_VIEWS.DISCUSSION_CREATE: {
            this.store.dispatch(createGroup());
            break;
          }
          case MESSAGING_VIEWS.DISCUSSION_VIEW: {
            this.store.dispatch(createOrUpdateMessage({}));
            break;
          }

          case MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW: {
            this.store.dispatch(createOrUpdateMessage({}));
            break;
          }
          default:
            break;
        }
      });
  }

  private handleDiscardAction() {
    this.footerService.discard.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      this.discard();
    });
  }

  private discard() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.store.dispatch(discardMessagingGroupCache());
  }
}
