import { Component, Input } from '@angular/core';
import { fadeInGeneral } from '../../../../../assets/styles/animations';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-commitments-sidebar-help',
  templateUrl: './commitments-sidebar-help.component.html',
  styleUrls: ['./commitments-sidebar-help.component.scss'],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [NgIf],
})
export class CommitmentsSidebarHelpComponent {
  @Input() type: COMMITMENTS_INTERACTION_BAR_TYPE;

  COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;
}
