import { Component, Input, OnInit } from '@angular/core';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../../../../../framework/constants/interaction-bar.constants';
import {
  setMessagingView,
  updateNewGroupData,
} from '../../../../../store/messages/messages.actions';
import {
  IMessagingMember,
  MESSAGING_VIEWS,
} from '../../../../../store/messages/messages.interfaces';
import { AppState } from '../../../../../store/app-state';
import { Store } from '@ngrx/store';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { TasksService } from '../../../../../services/tasks.service';
import { take } from 'rxjs/operators';
import { PhonePipe } from '../../../../../pipes/framework/phone.pipe';
import { MatButton } from '@angular/material/button';

// todo: delete this component
/**
 * @deprecated delete this component
 */
@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  standalone: true,
  imports: [MatButton, PhonePipe],
})
export class NoteComponent implements OnInit {
  private _project;
  phoneNumber = '';

  constructor(
    private interactionBarState: InteractionBarStateService,
    private store: Store<AppState>,
    private currentUser: CurrentUserService,
    private taskService: TasksService,
  ) {}

  @Input() set project(proj) {
    this._project = proj;
    this.phoneNumber = this?.project?.user?.phone;
  }

  get project() {
    return this._project;
  }

  ngOnInit() {}

  openMessages() {
    this.interactionBarState.announceState.next({ action: INTERACTION_BAR_STATES.MESSAGES });
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_CREATE }));
    this.addDiscussionMember();
  }

  addDiscussionMember(): void {
    this.taskService
      .getUsers({ search: '', getWithUser: 0, project_id: this.project.id })
      .pipe(take(1))
      .subscribe((users: IMessagingMember[]) => {
        const user = users.find((usr) => usr.user_id === this.project.user.id);
        const member = {
          ...user,
          added_by: {
            user_id: String(this.currentUser.data.id),
            name: `${this.currentUser.data.first_name} ${this.currentUser.data.last_name}`,
            company_name: this.currentUser.data.company_name,
          },
        };
        this.store.dispatch(updateNewGroupData({ member }));
      });
  }
}
