export const richTextItemsConfig = [
  'Undo',
  'Redo',
  '|',
  'Bold',
  'Italic',
  'Underline',
  'StrikeThrough',
  'CreateLink',
  '|',
  'FontName',
  'FontSize',
  'FontColor',
  'BackgroundColor',
  '|',
  'Formats',
  'Alignments',
  '|',
  'OrderedList',
  'UnorderedList',
  '|',
  'Indent',
  'Outdent',
  '|',
  'ClearFormat',
];
