<div class="project-summary-wrapper">
  <div class="title">Project Summary</div>
  <ng-scrollbar class="std-scrollbar">
    <app-rich-text-editor
      [editorSettings]="settings"
      [editorValue]="project?.project_description?.description"
      class="summary"
    ></app-rich-text-editor>
  </ng-scrollbar>
</div>
