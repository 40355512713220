import { Component, Input, OnInit } from '@angular/core';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import * as moment from 'moment';
import { GeneralSidebarHeaderComponent } from '../../general-sidebar-header/general-sidebar-header.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass, NgIf } from '@angular/common';

import { DayjsFormatterPipe } from '../../../pipes/framework/dayjs-formatter.pipe';
import { SimpleButtonComponent } from '../../buttons/simple-medium-button/simple-button.component';
import { ProjectSpend } from '../../../services/project-spend.service';
import { Log, LogByDate } from './budget-adjustment-log.interfaces';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';

@Component({
  selector: 'app-budget-adjustment-log',
  templateUrl: './budget-adjustment-log.component.html',
  styleUrls: ['./budget-adjustment-log.component.scss'],
  standalone: true,
  imports: [
    GeneralSidebarHeaderComponent,
    NgScrollbar,
    NgClass,
    NgIf,
    DayjsFormatterPipe,
    SimpleButtonComponent,
    MoneyPipe,
  ],
})
export class BudgetAdjustmentLogComponent implements OnInit {
  private _projectSpends: ProjectSpend[] = [];
  @Input() set projectSpends(value: ProjectSpend[]) {
    this._projectSpends = value;
    this.logs = this.getAdjustmentLogs();
    this.groupByDate();
    this.sortGroupedLogs();
  }

  get projectSpends() {
    return this._projectSpends;
  }

  groupLogDate: LogByDate[] = [];
  logs: Log[] = [];

  constructor(private interactionBar: InteractionBarStateService) {}

  ngOnInit(): void {}

  /**
   * Extract unique budget adjustment logs from project spend data.
   * Each spend data contains a list of budget adjustments.
   * Budget adjustments may contain duplicates across different line items.
   * Filters out duplicates by log ID.
   */
  getAdjustmentLogs() {
    const seenIds: Set<number> = new Set();

    return this.projectSpends.flatMap((lineItem) =>
      lineItem.budget_adjustments
        .filter((log) => {
          if (!seenIds.has(log.id)) {
            seenIds.add(log.id);
            return true;
          }
          return false;
        })
        .map((log) => ({
          ...log,
          from_name: this.getLineItemName(log.from_item_id),
          to_name: this.getLineItemName(log.to_item_id),
        })),
    );
  }

  getLineItemName(id: number) {
    return this.projectSpends.find((lineItem) => lineItem.id === id)?.name;
  }

  /**
   * <p>this function groups each budget adjustment log by the date it is created </p>
   * <p>note: it takes into consideration the current timezone. </p>
   * <p>Ex: an adjustment created on 15 Jun, 02:00 in UTC will be 14 Jun in California (PDT)</p>
   *
   */
  private groupByDate() {
    this.groupLogDate = this.logs.reduce((groupedLogs, log) => {
      const logDate = moment.utc(log.created_at).local().format('YYYY-MM-DD');
      const dateGroup = groupedLogs.find((groupLog) => groupLog.date === logDate);

      if (dateGroup) {
        // push log to existing date group
        dateGroup.logs.push(log);
      } else {
        // create new date group entry
        groupedLogs.push({
          date: logDate,
          logs: [log],
        });
      }

      return groupedLogs;
    }, []);
  }

  /**
   * sort by date in descending order the grouped logs
   *
   */
  private sortGroupedLogs() {
    this.groupLogDate.sort((a, b) => {
      return moment(a.date).isAfter(b.date) ? -1 : +1;
    });
  }

  close() {
    this.interactionBar.close();
  }
}
