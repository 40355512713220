import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProjectApiService } from '../../services/project-api.service';
import { NotificationsService } from '../../services/notifications.service';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../constants/interaction-bar.constants';
import { propertiesActions } from '../../store/properties/properties.actions';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { states } from '../../store/properties/properties.types';
import { IPropertyBase } from '../../store/properties/properties.interfaces';
import { take } from 'rxjs/operators';
import { SimpleSmallButtonComponent } from '../buttons/simple-small-button/simple-small-button.component';
import { DropdownComponent } from '../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';

export enum PROPERTY_TYPES {
  MULTIFAMILY = 1,
  SENIOR_LIVING,
  HEALTHCARE,
  OFFICE,
}

@Component({
  selector: 'app-add-property-view',
  templateUrl: './add-property-view.component.html',
  styleUrls: ['./add-property-view.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FloatingInputComponent,
    DropdownComponent,
    SimpleSmallButtonComponent,
  ],
})
export class AddPropertyViewComponent implements OnInit, OnDestroy {
  property: IPropertyBase;
  isLoading = false;
  stateSubscriber;

  propertyForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    state: new UntypedFormControl('', [Validators.required]),
    street: new UntypedFormControl('', [Validators.required]),
    zip: new UntypedFormControl('', [Validators.required]),
    type_id: new UntypedFormControl('', [Validators.required]),
    type_unit_value: new UntypedFormControl('', [Validators.required]),
  });
  propertyTypes = [];
  protected readonly states = states;

  constructor(
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private interactionBar: InteractionBarStateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.stateSubscriber = this.interactionBar.announceState.pipe(take(1)).subscribe((state) => {
      switch (state.action) {
        case INTERACTION_BAR_STATES.ADD_PROPERTY: {
          this.propertyForm.reset();
          break;
        }
      }
    });
    this.projectApi.getPropertyTypes().then(
      (data) => {
        this.propertyTypes = data;
      },
      (err) => console.warn(err),
    );
  }

  ngOnDestroy(): void {
    if (this.stateSubscriber) {
      this.stateSubscriber.unsubscribe();
    }
  }

  @Input() set editedProperty(value) {
    if (value) {
      this.property = value;
      this.init();
    } else {
    }
  }

  getErrorMessage(param: AbstractControl) {
    return param.hasError('required')
      ? 'Required'
      : param.hasError('email')
        ? 'Invalid email'
        : param.hasError('minlength')
          ? 'Too short'
          : '';
  }

  save() {
    if (this.propertyForm.valid) {
      this.notif.showLoading();
      this.propertyForm.get('zip').setValue(this.propertyForm.value.zip.toString());
      this.isLoading = true;
      if (this.property) {
        this.updateProperty(this.property.id, this.propertyForm.getRawValue());
        this.store.dispatch(propertiesActions.reloadProperties({ search: '' }));
      } else {
        this.createProperty(this.propertyForm.getRawValue());
      }
      this.clear();
    } else {
      this.propertyForm.markAllAsTouched();
    }
  }

  private clear() {
    this.property = null;
  }

  private init() {
    if (this.property) {
      this.propertyForm.patchValue({
        name: this.property.name,
        city: this.property.city,
        state: this.property.state,
        street: this.property.street,
        type_id: this.property.type_id,
        type_unit_value: this.property.type_unit_value,
        zip: this.property.zip,
      });
    }
  }

  private updateProperty(id, body) {
    this.projectApi
      .updateProperty(id, body)
      .then(
        (resp) => {
          this.notif.showSuccess('Updated property!');
          this.store.dispatch(propertiesActions.reloadPropertiesByType({}));
          this.interactionBar.close();
        },
        (err) => {
          this.notif.showError(err);
          this.isLoading = false;
        },
      )
      .then();
  }

  private createProperty(body) {
    this.projectApi.createProperty(body).then(
      (resp) => {
        this.store.dispatch(propertiesActions.reloadPropertiesByType({}));
        this.notif.showSuccess('Property added!');
        this.interactionBar.close();
      },
      (err) => {
        this.notif.showError(err);
        this.isLoading = false;
      },
    );
  }
}
