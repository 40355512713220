import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state/index';
import { NotificationsService } from '../../services/notifications.service';
import { cashflowActions } from './cashflow.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { cashflowSelectors } from './cashflow.selectors';
import { of } from 'rxjs';
import { CashFlowService } from '../../services/cash-flow.service';
import { ALL_ID, CashflowFilters } from '../../framework/constants/cashflow.constants';

@Injectable()
export class CashflowEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private notif: NotificationsService,
    private cashflowService: CashFlowService,
  ) {}

  loadCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(cashflowActions.filtersChanged),
      withLatestFrom(this.store.select(cashflowSelectors.getCashflowState)),
      map(([action, state]) => {
        const filters: CashflowFilters = { ...state.filters, ...action.filters };
        if (filters.property_ids.includes(ALL_ID)) {
          filters.property_ids = [];
        }
        if (filters.project_ids.includes(ALL_ID)) {
          filters.project_ids = [];
        }
        return { filters, state };
      }),
      switchMap(({ filters, state }) => {
        this.store.dispatch(cashflowActions.startedLoading());
        return this.cashflowService
          .getCashFlowData(filters)
          .pipe(map((data) => cashflowActions.cashflowLoaded({ cashflow: data })));
      }),
      catchError((error) => {
        console.error('Error loading cashflow', error);
        this.notif.showError(error?.message || 'An error occurred while loading the project');
        return of(cashflowActions.cancel());
      }),
    ),
  );
}
