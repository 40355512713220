import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NotificationsService } from '../../services/notifications.service';
import { ProjectSpendService } from '../../services/project-spend.service';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { Store } from '@ngrx/store';
import { viewProjectSelectors } from '../../store/view-project/view-project.selectors';
import { MatButton } from '@angular/material/button';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

/**
 * @deprecated delete this
 */
@Component({
  selector: 'app-forecast-adjustments-view',
  templateUrl: './forecast-adjustments-view.component.html',
  styleUrls: ['./forecast-adjustments-view.component.scss'],
  standalone: true,
  imports: [
    NgScrollbar,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    FloatingInputComponent,
    NgClass,
    MatButton,
    NgIf,
  ],
})
export class ForecastAdjustmentsViewComponent implements OnInit {
  private _data;
  set data(value) {
    if (value) {
      console.log('ForecastAdjustmentsViewComponent', value);
      this._data = value;
      this._data.map((item) => {
        this.addFormGroupLine(item);
      });
    }
  }
  forecastModifications: {
    name: string;
    item_id: number;
    forecastForms: FormGroup<any>[];
  }[] = [];
  isLoading = false;
  projectSpends$ = this.store.select(viewProjectSelectors.getSpends);

  constructor(
    private notif: NotificationsService,
    private projectSpendService: ProjectSpendService,
    private interactionBar: InteractionBarStateService,
    private store: Store,
  ) {
    this.projectSpends$.subscribe((project) => {
      this.data = project;
    });
  }

  ngOnInit(): void {}

  removeModification(lineNr: number, formIndex) {
    const selectedForecastModificationForms = this.forecastModifications[lineNr].forecastForms;
    this.notif.showPopup('Are you sure you want to delete forecast modification?').then((data) => {
      if (data) {
        selectedForecastModificationForms.splice(formIndex, 1);
        this.notif.showSuccess('Modification deleted!');
      }
    });
  }

  getErrorMessage(param: AbstractControl) {
    return param.hasError('required')
      ? 'Required'
      : param.hasError('email')
        ? 'Invalid email'
        : param.hasError('minlength')
          ? 'Too short'
          : '';
  }

  cloneForm(data?: { note; value; item_id; id? }) {
    const form = this.createForm(data);
    this.patchForm(form, data);
    return form;
  }

  createForm(data: any) {
    const form = new FormGroup({
      note: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      item_id: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.patchForm(form, data);
    return form;
  }

  patchForm(form, data) {
    if (data) {
      form.patchValue({
        note: data.note,
        value: data.value,
        item_id: data.item_id,
        id: data?.id,
      });
    }
  }

  addLine(lineNr, modification) {
    const data = {
      item_id: modification.item_id,
      value: undefined,
      note: undefined,
    };
    const item = this.cloneForm(data);
    this.forecastModifications[lineNr].forecastForms.push(item);
  }

  addFormGroupLine(data) {
    const group = [];
    data.forecast_modifications.forEach((elem) => {
      const item = this.cloneForm(elem);
      group.push(item);
    });

    this.forecastModifications.push({
      name: data.name,
      item_id: data.id,
      forecastForms: [...group],
    });
  }

  save() {
    if (this.allRequiredFieldsFilled()) {
      this.notif.showLoading();
      this.isLoading = true;
      const form = this.buildForecastForm();
      this.modifyForecast(form);
    }
  }

  modifyForecast(form) {
    const body = {
      forecast_modifications: form,
    };
    this.projectSpendService.modifyForecast(body).then(
      (data) => {
        console.log(data);
        this.notif.showSuccess('Modifications saved!');
        this.isLoading = false;
        setTimeout(() => {
          this.interactionBar.close();
        }, 500);
      },
      (err) => {
        console.warn('Forecast error', err);
        this.notif.showError(err);
        this.isLoading = false;
      },
    );
  }

  buildForecastForm() {
    const body = [];
    this.forecastModifications.forEach((item) => {
      const group = {
        item_id: item.item_id,
        modifications: [],
      };
      item.forecastForms.forEach((formGroup) => {
        const rawGroup = formGroup.getRawValue();
        group.modifications.push({
          note: rawGroup.note,
          value: this.projectSpendService.formatString(rawGroup.value),
        });
      });
      body.push(group);
    });
    return body;
  }

  getAmount(formGroupIndex: number) {
    return 1 + formGroupIndex + '. Amount($)';
  }

  private allRequiredFieldsFilled() {
    let isValid = true;
    this.forecastModifications.forEach((item) => {
      item.forecastForms.forEach((formGroup) => {
        if (!formGroup.valid) {
          formGroup.markAllAsTouched();
          isValid = false;
        }
      });
    });
    return isValid;
  }
}
