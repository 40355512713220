import { Injectable, OnDestroy } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_ACCESSIBLE_USERS,
  REST_BASE,
  REST_CURRENT_USER,
  REST_LOGIN,
  REST_LOGOUT,
  REST_PASS_CONFIRM,
  REST_PASS_RESET,
  REST_PROPERTY,
  REST_REGISTER,
  REST_SCHEDULE_VISITS,
  REST_TEAM_MANAGEMENT,
  REST_TOKEN_RFRESH,
  REST_USER,
} from '../restApi/RestRoutes';
import { StorageService } from './storage.service';
import { User, USER_MANAGER } from '../framework/constants/user.constants';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from '../store/app-state';
import { Store } from '@ngrx/store';
import { clearAll } from '../store/documents/documents.actions';
import { AccessibleUser } from '../framework/interfaces/User.interface';
import { discardMessagingGroupCache, setMessagingView } from '../store/messages/messages.actions';
import { MESSAGING_VIEWS } from '../store/messages/messages.interfaces';
import { MessagesStateService } from './messages-state.service';
import { tasksActions } from '../store/tasks/tasks.actions';
import { commitmentsActions } from '../store/commitments/commitments.actions';
import { rollupActions } from '../store/rollups/rollups.actions';
import { teamManagementActions } from '../store/team-management/team-management.actions';
import { projectActions } from '../store/projects/projects.actions';
import { settingsActions } from '../store/settings/settings.actions';
import { Intercom } from '@supy-io/ngx-intercom';
import { hasActiveSubscription } from '../framework/constants/subscription.constants';
import { activitiesActions } from '../store/activities/activities.actions';
import { IPropertyBase, IPropertyType } from '../store/properties/properties.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService implements OnDestroy {
  isManager = true;
  data: User = null;
  private _data$ = new BehaviorSubject<User>(null);

  constructor(
    protected rest: RestRequestService,
    private storage: StorageService,
    protected router: Router,
    private store: Store<AppState>,
    private intercom: Intercom,
  ) {
    this.isManager = storage.getUserType() === USER_MANAGER;
    this.data = storage.getUserData();
    if (this.data) {
      void this.refreshUser();
    }
  }

  get data$() {
    return this._data$.asObservable().pipe(filter((value) => !!value));
  }

  updateCurrentUserData(userData: User) {
    userData.isManager = userData.type.name === USER_MANAGER;
    this.storage.setUserData(userData);
    this.data = userData;
    this._data$.next(userData);
    this.isManager = userData.type.name === USER_MANAGER;
  }

  refreshUser(): Promise<any> {
    return new Promise((res, rej) => {
      this.getUserData().then(
        (userData) => {
          this.updateCurrentUserData(userData);
          if (!hasActiveSubscription(userData)) {
            this.router.navigate(['webapp', 'subscribe']);
          }
          res(userData);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  register(userData): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.post(REST_REGISTER, userData).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(error);
        },
      );
    });
  }

  acceptInvitation(token: string) {
    return this.rest.postWithObservable(`${REST_TEAM_MANAGEMENT}/invitations/${token}`, { token });
  }

  getAllUsers(page = 1, search = '') {
    return new Promise((res, rej) => {
      let query = 'users?page=' + page;

      if (search && search !== '') {
        query += '&search=' + encodeURI(search);
      }

      this.rest.get(REST_BASE + query).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(this.handleError(error));
        },
      );
    });
  }

  requestReset(email) {
    return new Promise((res, rej) => {
      this.rest.post(REST_PASS_RESET, { email }).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(this.handleError(error));
        },
      );
    });
  }

  confirmReset(token) {
    return new Promise((res, rej) => {
      this.rest.post(REST_PASS_CONFIRM, { reset_token: token }).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(this.handleError(error));
        },
      );
    });
  }

  isAdmin() {
    console.log(this.storage.getUserData()?.is_admin);
    return this.storage.getUserData()?.is_admin;
  }

  isApproved() {
    return this.storage.getUserData()?.is_approved;
  }

  login(userData): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.post(REST_LOGIN, userData).then(
        (result) => {
          this.storage.setRefreshToken(result.refresh_token);

          this.storage.setToken(result.token, result.expires_in).then((userId) => {
            this.storage.setTokenAquireTime(new Date());

            this.getUserData().then(
              (currentUser) => {
                this.updateCurrentUserData(currentUser);
                MessagesStateService.connectUser$.next(true);
                res(currentUser);
              },
              (err) => {
                rej(err);
              },
            );
          });
        },
        (error) => {
          rej(this.handleError(error, true));
        },
      );
    });
  }

  isManagerF(): Promise<any> {
    return new Promise<any>((res) => {
      const userType = this.storage.getUserType();
      if (userType == null) {
        setTimeout(() => {
          res(this.isManagerF());
        });
      } else {
        res(userType === USER_MANAGER);
      }
    });
  }

  getUserData(): Promise<User> {
    return new Promise((res, rej) => {
      this.rest.get(REST_CURRENT_USER).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(this.handleError(error));
        },
      );
    });
  }

  updateUser(data): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.put(`${REST_USER}${this.data.id}`, data).then(
        (result) => {
          this.updateCurrentUserData(result);
          res(result);
        },
        (error) => {
          rej(error);
        },
      );
    });
  }

  updateUserById(id, data): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.put(REST_USER + id, data).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(error);
        },
      );
    });
  }

  acceptUser(data): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.put(REST_USER + 'accept/' + data.id, {}).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(error);
        },
      );
    });
  }

  deleteUser(data, permanent = true) {
    return new Promise((res, rej) => {
      this.rest.del(REST_USER + data.id + (permanent ? '?permanent=1' : ''), {}).then(
        (result) => {
          res(result);
        },
        (error) => {
          rej(error);
        },
      );
    });
  }

  retrieveNewToken(): Promise<any> {
    return new Promise((res, rej) => {
      this.rest.get(REST_TOKEN_RFRESH + '/' + this.storage.getRefreshToken()).then(
        (result) => {
          if (result.auth === 'Token is valid') {
            // this.storage.setTokenAquireTime(new Date());
            res(true);
          } else {
            this.storage.setRefreshToken(result.refresh_token);
            this.storage.setToken(result.token, result.expires_in).then((data) => {
              res(true);
            });
          }
        },
        (error) => {
          rej('You are logged out, please log in!');
        },
      );
    });
  }

  isLoggedIn() {
    return this.storage.getUserType() !== null;
  }

  logout() {
    this.rest.del(REST_LOGOUT).then(
      (data) => {
        this.store.dispatch(clearAll());
        console.log('logged Out');
      },
      (err) => {
        console.warn('failed to log out correctly');
      },
    );
    this.storage.setUserId(null);
    this.storage.setToken(null, 0);
    this.storage.setRefreshToken(null);
    this.storage.setUserData(null);
    this.storage.setInviteToken(null);
    this.storage.setShowZeroLineItems(null);
    this.intercom.shutdown();
    this.data = null;
    this._data$.next(null);
    this.store.dispatch(discardMessagingGroupCache());
    this.store.dispatch(commitmentsActions.clearCommitments());
    StorageService.setDefaultRollupFilters();
    this.store.dispatch(rollupActions.clearRollups());
    this.store.dispatch(projectActions.clearProjects());
    this.store.dispatch(teamManagementActions.clearTeamManagement());
    this.store.dispatch(tasksActions.emptyTasksState());
    this.store.dispatch(settingsActions.clearAll());
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.store.dispatch(activitiesActions.clearActivities());
    this.isManager = false;
  }

  /**
   * Returns true if user went trough the full register process
   */
  isPropertyOwnerOrTeamMember() {
    if (this.isLoggedIn()) {
      return (
        this.storage.getUserData().is_team_member || this.storage.getUserData().is_property_owner
      );
    } else {
      return null;
    }
  }

  getVisits(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_SCHEDULE_VISITS + '?user_id=' + this.data.id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getProperties(searchText = ''): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROPERTY, {}).then(
        (dataRes) => {
          if (searchText) {
            dataRes = dataRes.filter((el) => el.name.includes(searchText));
            res(dataRes);
          } else {
            res(dataRes);
          }
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getPropertiesByType(view: IPropertyType): Observable<IPropertyBase[]> {
    return this.rest.getWithObservable(
      `${REST_PROPERTY}`,
      {},
      {
        view: view,
      },
    );
  }

  getAccessibleUsers(): Promise<AccessibleUser[]> {
    return new Promise<AccessibleUser[]>((res, rej) => {
      this.rest.get(REST_ACCESSIBLE_USERS).then(
        (response) => res(response),
        (error) => rej(this.handleError(error)),
      );
    });
  }

  checkEmailInUsers(email: string): Promise<{ message: 'Found' | 'Not found' }> {
    return new Promise<{ message: 'Found' | 'Not found' }>((res, rej) => {
      this.rest.get(`${REST_USER}${email}/check`).then(
        (response) => res(response),
        (error) => rej(this.handleError(error)),
      );
    });
  }

  getUserById(userId: number): Observable<User> {
    return this.rest.getWithObservable(`${REST_USER}${userId}`);
  }

  handleError(error, disableRefresh = false) {
    console.log(error, disableRefresh);

    if (error.status === 500) {
      return error?.error?.errors?.detail ?? 'An error occurred. Please try again later.';
    } else if (error.status === 0) {
      return 'Network unreachable. Please try again later.';
    } else if (error.status === 401 && !disableRefresh) {
      return error.error[Object.keys(error.error)[0]];
    } else {
      if (error.error) {
        return error.error[Object.keys(error.error)[0]];
      } else {
        return 'Unhandled Exception';
      }
    }
  }

  ngOnDestroy(): void {
    this._data$.complete();
  }
}
