import {
  ILineItem,
  ILineItemsTotal,
  IMonthlyDisable,
  IMonthlySpendData,
} from '../../store/spend/spend.interfaces';
import { SPEND_TYPES } from './budget.constants';

export const defaultMonthlyData: IMonthlySpendData = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
};

export const defaultMonthlyDisable: IMonthlyDisable = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: false,
  12: false,
};

export enum DISTRIBUTION_TYPES {
  BELL_CURVE = 'bell_curve',
  SPLIT_BELL_CURVE = 'split_bell_curve',
  S_CURVE = 's_curve',
  REVERSE_S_CURVE = 'reverse_s_curve',
  STRAIGHT_LINE = 'straight_line',
  STRAIGHT_INCLINE = 'straight_incline',
  STRAIGHT_DECLINE = 'straight_decline',
  MANUAL = 'manual',
}

export const defaultLineItem: ILineItem = {
  name: 'Project Cost',
  id: undefined,
  project_id: undefined,
  budget_distribution: DISTRIBUTION_TYPES.BELL_CURVE,
  forecast_distribution: DISTRIBUTION_TYPES.BELL_CURVE,
  duration: 1,
  forecast_duration: 1,
  budget: [],
  start_date: undefined,
  forecast_start_date: undefined,
  commitment_start_date: null,
  row_number: 1,
  // monthly_data: { ...defaultMonthlyData }
};

export const defaultLineItemsTotal: ILineItemsTotal = {
  project_total: 0,
  year_total: 0,
  monthly_data: { ...defaultMonthlyData },
};

export const distributionTypes = [
  {
    key: 'bell_curve',
    name: 'Bell Curve',
  },
  {
    key: 'split_bell_curve',
    name: 'Split Bell Curve',
  },
  // THESE NEED TO BE REMOVED ONLY FROM FRONTEND
  // {
  //   key: 's_curve',
  //   name: 'S-Curve',
  // },
  // {
  //   key: 'reverse_s_curve',
  //   name: 'Reverse S-Curve',
  // },
  {
    key: 'straight_line',
    name: 'Straight Line',
  },
  {
    key: 'straight_incline',
    name: 'Straight Incline',
  },
  {
    key: 'straight_decline',
    name: 'Straight Decline',
  },
  {
    key: 'manual',
    name: 'Manual',
  },
];

export enum SPEND_DISTRIBUTION_STATE {
  LOADING,
  SHOW_SPREADSHEET,
  ADD_PROJECT,
  EDIT_PROJECT,
  DEFAULT,
}

export const typeToProp = {
  [SPEND_TYPES.BUDGET]: 'monthly_budget',
  [SPEND_TYPES.FORECAST]: 'monthly_forecast',
  [SPEND_TYPES.ACTUALS]: 'monthly_actuals',
};

export const typeToDistProp = {
  [SPEND_TYPES.BUDGET]: 'budget_distribution',
  [SPEND_TYPES.FORECAST]: 'forecast_distribution',
};

export const initialProjectTotalProp = {
  [SPEND_TYPES.BUDGET]: 'initial_budget_line_item_total',
  [SPEND_TYPES.FORECAST]: 'initial_forecast_line_item_total',
};

export const typeToDurationProp = {
  [SPEND_TYPES.BUDGET]: 'duration',
  [SPEND_TYPES.FORECAST]: 'forecast_duration',
};

export const typeToStartDateProp = {
  [SPEND_TYPES.BUDGET]: 'start_date',
  [SPEND_TYPES.FORECAST]: 'forecast_start_date',
};

export const spendTypeKeys = ['monthly_budget', 'monthly_forecast', 'monthly_actuals'];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const MONTHS_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const specialCharReplaceRegexp = /[^a-zA-Z0-9 ]/g;
