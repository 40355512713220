import { Directive, ViewChild } from '@angular/core';
import { NotificationsHandler } from './NotificationsHandler';
import { NotificationsService } from '../../services/notifications.service';
import { CalendarComponent } from '../inputs/calendar/calendar.component';

@Directive()
export class CalendarHandler extends NotificationsHandler {
  @ViewChild('schedule') schedule: CalendarComponent;
  calendarEvents: any = [];
  calendarDate: any;

  constructor(protected notif: NotificationsService) {
    super(notif);
  }

  refreshCalendar() {
    this.schedule.refresh();
  }

  registerNewDate(event: any) {
    this.calendarDate = event;
  }

  registerEvents(event: any) {
    const oldEvents = this.calendarEvents;
    this.calendarEvents = event;

    if (this.schedule && oldEvents.length !== event.length) {
      // todo check not to reload 2x
      // this.schedule.refresh();
    }
  }
}
