import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectsFeatureKey, ProjectsState, selectAll } from './projects.reducer';

export const getProjectsState = createFeatureSelector<ProjectsState>(projectsFeatureKey);
export const getAllProjects = createSelector(getProjectsState, selectAll);

export const getNonDeletedProjects = createSelector(getAllProjects, (projects) => {
  return projects.filter((project) => {
    const status = project?.project_status?.key ?? project.status;
    return status !== 'deleted' && status !== 'archived';
  });
});

export const getSimpleProjects = createSelector(getProjectsState, (state) => state.simpleProjects);
export const areSimpleProjectsLoading = createSelector(
  getProjectsState,
  (state) => state.areSimpleProjectsLoading,
);

export const getAllBackendStatuses = createSelector(getProjectsState, (state) => state.statuses);
