import { Component, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { NgClass, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { cashflowSelectors } from '../../../store/cashflow/cashflow.selectors';
import { TooltipModule } from 'primeng/tooltip';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';

@Component({
  selector: 'app-cash-flow-stats',
  templateUrl: './cash-flow-stats.component.html',
  styleUrls: ['./cash-flow-stats.component.scss'],
  standalone: true,
  imports: [MatTooltip, NgIf, NgClass, TooltipModule, MoneyPipe],
})
export class CashFlowStatsComponent implements OnInit {
  budget = 0;
  actuals = 0;
  forecast = 0;
  fcstVsBud = 0;
  actVsForecast = 0;
  isDestroyed$ = new Subject<boolean>();
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(cashflowSelectors.getTotals)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((v) => !!v),
      )
      .subscribe((totals) => {
        this.budget = totals.budget;
        this.actuals = totals.actuals;
        this.forecast = totals.forecast;
        this.fcstVsBud = this.budget - this.forecast;
        this.actVsForecast = this.forecast - this.actuals;
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
