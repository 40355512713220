import { createReducer, on } from '@ngrx/store';
import { messagesActions } from './messages.actions';
import {
  IMessage,
  IMessageFilters,
  IMessagingGroup,
  IMessagingGroupFilters,
  IMessagingMember,
  IMessagingProfilePictureCache,
  MESSAGING_VIEWS,
} from './messages.interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  defaultGroupFilters,
  defaultMessageFilters,
  MESSAGES_NOT_FIRST_PAGE_LENGTH,
} from '../../framework/constants/messages.constants';
import { DeepCopyService } from '../../services/deep-copy.service';
import { IFilesToUpload } from '../../framework/messages/discussions-view/discussions-view.component';

export const messagingFeatureKey = 'messages';

export const messagingAdapter: EntityAdapter<IMessagingGroup> =
  createEntityAdapter<IMessagingGroup>();

export const { selectAll } = messagingAdapter.getSelectors();

export interface MessagingState extends EntityState<IMessagingGroup> {
  selectedView: MESSAGING_VIEWS;
  selectedGroupId: number;
  searchedMembers: Set<IMessagingMember>;
  discussionMembers: Set<IMessagingMember>;
  message: string; // the message which the user is currently typing
  newGroupData: {
    // when creating a new group this is populated
    message: string;
    name: string;
    discussionMembers: Set<IMessagingMember>;
  };
  // filters to be applied during group or discussion filtering
  // filters: IMessagingStoreFilters;
  groupFilters: IMessagingGroupFilters;
  messageFilters: IMessageFilters;
  drafts: IMessage[];
  thread_id: number; // corresponds to the main_message_id - if null then no thread is selected
  profile_pic_cache: IMessagingProfilePictureCache;
  isLoading: boolean;
  isSearchedUsersLoading: boolean;
  isPaginationLoading: boolean;
  lastNonDraftSentMessageId: number;
  files: IFilesToUpload[];
  areAllGroupsLoaded: boolean; // if the last pagination request returns [] it is set to true otherwise false
  hasUnreadMessage: boolean;
}

export const messagingGroupInitialState: MessagingState = messagingAdapter.getInitialState({
  searchedMembers: new Set<IMessagingMember>(),
  discussionMembers: new Set<IMessagingMember>(),
  selectedView: MESSAGING_VIEWS.DISCUSSION_LIST,
  selectedGroupId: null,
  messageFilters: defaultMessageFilters,
  groupFilters: defaultGroupFilters,
  drafts: [],
  message: '',
  thread_id: null,
  newGroupData: {
    message: null,
    name: null,
    discussionMembers: new Set<IMessagingMember>(),
  },
  profile_pic_cache: {},
  isLoading: false,
  isPaginationLoading: false,
  isSearchedUsersLoading: true,
  lastNonDraftSentMessageId: null,
  files: [],
  areAllGroupsLoaded: false,
  hasUnreadMessage: false,
});

export const messagesReducer = createReducer(
  messagingGroupInitialState,
  on(messagesActions.setGroups, (state, action) => {
    return messagingAdapter.setAll([...action.groups], { ...state, isLoading: false });
  }),
  on(messagesActions.paginatedGroupsLoaded, (state, action) => {
    const page = action.filters.page;
    const newState = {
      ...state,
      isLoading: false,
      groupFilters: {
        ...state.groupFilters,
        page,
        perPage: MESSAGES_NOT_FIRST_PAGE_LENGTH,
      },
      areAllGroupsLoaded: action.groups?.length === 0,
    };
    if (page === 1) {
      return messagingAdapter.setAll([...action.groups], newState);
    }
    return messagingAdapter.addMany([...action.groups], newState);
  }),
  on(messagesActions.setMessageFilters, (state, action) => {
    return {
      ...state,
      messageFilters: {
        ...state.messageFilters,
        ...action.filters,
      },
    };
  }),
  on(messagesActions.setGroupFilters, (state, action) => {
    return {
      ...state,
      groupFilters: {
        ...state.groupFilters,
        ...action.filters,
      },
      areAllGroupsLoaded: false,
    };
  }),
  on(messagesActions.addFiles, (state, action) => {
    return {
      ...state,
      files: [...state.files, ...action.files],
    };
  }),
  on(messagesActions.removeFile, (state, action) => {
    const files = DeepCopyService.deepCopy(state.files);
    files.splice(action.index, 1);
    return {
      ...state,
      files,
    };
  }),
  on(messagesActions.cleanFiles, (state, action) => {
    return {
      ...state,
      files: [],
    };
  }),
  on(messagesActions.setMessagingView, (state, action) => {
    return {
      ...state,
      thread_id: action.view !== MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW ? null : state.thread_id,
      selectedView: action.view,
      newGroupData: {
        message: null,
        name: null,
        discussionMembers: new Set<IMessagingMember>(),
      },
      groupFilters: defaultGroupFilters,
      messageFilters: defaultMessageFilters,
      hasUnreadMessage:
        action.view === MESSAGING_VIEWS.DISCUSSION_LIST ? false : state.hasUnreadMessage,
    };
  }),
  on(messagesActions.setSearchedMessagingMembers, (state, action) => {
    return {
      ...state,
      isSearchedUsersLoading: false,
      searchedMembers: new Set<IMessagingMember>([...action.members]),
    };
  }),
  on(messagesActions.setDiscussionMembers, (state, action) => {
    return {
      ...state,
      discussionMembers: new Set<IMessagingMember>([...action.members]),
    };
  }),
  on(messagesActions.addDiscussionMembers, (state, action) => {
    const discussionMembers = [...state.discussionMembers];
    if (discussionMembers.findIndex((member) => member.user_id === action.member.user_id) === -1) {
      discussionMembers.push(action.member);
    }
    return {
      ...state,
      discussionMembers: new Set<IMessagingMember>([...discussionMembers]),
    };
  }),

  on(messagesActions.removeNewGroupDiscussionMember, (state, action) => {
    const discussionMembers = [...state.newGroupData.discussionMembers];
    const index = discussionMembers.findIndex(
      (discussionMember) => discussionMember.user_id === action.member.user_id,
    );
    if (index !== -1) {
      discussionMembers.splice(index, 1);
    }

    return {
      ...state,
      newGroupData: {
        ...state.newGroupData,
        discussionMembers: new Set<IMessagingMember>(discussionMembers),
      },
    };
  }),
  on(messagesActions.removeDiscussionMembers, (state, action) => {
    const discussionMembers = [...state.discussionMembers];
    const index = discussionMembers.findIndex(
      (discussionMember) => discussionMember.user_id === action.member.user_id,
    );
    if (index !== -1) {
      discussionMembers.splice(index, 1);
    }
    return {
      ...state,
      discussionMembers: new Set<IMessagingMember>(discussionMembers),
    };
  }),
  on(messagesActions.updateMessagingGroup, (state, action) => {
    return messagingAdapter.updateOne(
      { id: action.group.id, changes: action.group },
      {
        ...state,
      },
    );
  }),
  on(messagesActions.updateNewGroupData, (state, action): MessagingState => {
    return {
      ...state,
      newGroupData: {
        discussionMembers: action?.member
          ? state.newGroupData.discussionMembers.add(action.member)
          : state.newGroupData.discussionMembers,
        message:
          action?.message || action?.message === '' ? action.message : state.newGroupData.message,
        name: action?.name || action.name === '' ? action.name : state.newGroupData.name,
      },
    };
  }),
  on(messagesActions.discardMessagingGroupCache, (state, action) => {
    state.discussionMembers.clear();
    state.searchedMembers.clear();
    return {
      ...state,
      message: '',
      name: '',
      lastNonDraftSentMessageId: null,
      newGroupData: {
        message: null,
        name: null,
        discussionMembers: new Set<IMessagingMember>(),
      },
    };
    // return messagingAdapter.removeAll(state);
  }),
  on(messagesActions.setSelectedGroupId, (state, action) => {
    const group = state.entities[action.groupId];
    return {
      ...state,
      discussionMembers: new Set<IMessagingMember>([...(group ? group.members : [])]),
      selectedGroupId: action.groupId,
    };
  }),

  on(messagesActions.setMessage, (state, action) => {
    return {
      ...state,
      message: action.message,
    };
  }),
  on(messagesActions.setMessages, (state, action): MessagingState => {
    const draft = action.groupMessagesAndDrafts.drafts.find(
      (d) => !d.thread_id && d.group_id === state.selectedGroupId,
    );

    return messagingAdapter.updateOne(
      {
        id: state.selectedGroupId,
        changes: {
          messages: action.groupMessagesAndDrafts.messages,
        },
      },
      {
        ...state,
        entities: state.entities,
        drafts: action.groupMessagesAndDrafts.drafts,
        message: draft?.body || '',
        isLoading: false,
      },
    );
  }),
  on(messagesActions.appendToMessages, (state, action) => {
    if (!action.messages) {
      return { ...state, isPaginationLoading: false };
    }
    const group = state.entities[state.selectedGroupId];
    try {
      return messagingAdapter.updateOne(
        {
          id: state.selectedGroupId,
          changes: {
            messages: [...group.messages, ...action.messages],
            loadedAllPastMessages: action.messages.length === 0,
          },
        },
        { ...state, isPaginationLoading: false },
      );
    } catch (e) {
      // probably group.messages is not iterable because of slow internet
      // groups might have been deleted on back button but a request might still live in the background
      console.warn('handle appendToMessages err', e);
      return state;
    }
  }),
  on(messagesActions.setThread, (state, action): MessagingState => {
    return {
      ...state,
      isLoading: false,
      message: state.drafts?.find(
        (draft) =>
          draft.thread_id === action.thread.mainMessage.id &&
          draft.group_id === state.selectedGroupId,
      )?.body,
      thread_id: action.thread.mainMessage.id,
    };
  }),
  on(messagesActions.setDrafts, (state, action): MessagingState => {
    return {
      ...state,
      drafts: action.drafts.length ? action.drafts : [],
    };
  }),
  on(messagesActions.addGroupMessage, (state: MessagingState, action): MessagingState => {
    const message: Partial<IMessage> = DeepCopyService.deepCopy(action.message);
    const drafts = state.drafts.filter((draft) => draft.id !== message.id);
    const group = state.entities[message.group_id];
    const newState = {
      ...state,
      message: '',
      drafts,
    };

    if (message.isLocalOnly) {
      message.uuid = getRandomString();
      // if the message is present only locally then find the current user info in other messages:
      const messageWithInfo = group.messages.find(
        (m) => m.user.id === message.user.id && !!m.user.company_name && !!m.user.name,
      );
      if (messageWithInfo) {
        message.user = messageWithInfo.user;
      }
    }

    // will be overwritten if user is not in one of the discussion views
    let changes: Partial<IMessagingGroup> = {
      is_read: false,
    };
    const isInDiscussionView =
      state.selectedView === MESSAGING_VIEWS.DISCUSSION_VIEW ||
      state.selectedView === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW;
    if (isInDiscussionView) {
      if (!message.thread_id) {
        // if the message does not belong to any thread, simply add the message to the group
        const containsMessage = group.messages.find(
          (groupMessage) => groupMessage.id === message.id,
        );
        changes = {
          messages: containsMessage ? [...group.messages] : [message, ...group.messages],
        };
      } else {
        // if the message is part of a thread, add it to the main message's thread array
        const isMessageRead = state.thread_id === message.thread_id;
        let updatedMessages: IMessage[] = DeepCopyService.deepCopy(group.messages);
        updatedMessages = updatedMessages.map((m) => {
          if (m.id === message.thread_id) {
            // if the message belongs to the selected thread than is read, otherwise unread
            m.is_read = isMessageRead;
            m.thread = [...m.thread, message as IMessage];
          }
          return m;
        });
        changes = {
          messages: updatedMessages,
          // if the group is read set its read status to the read status of the message, otherwise keep original
          is_read: group.is_read ? isMessageRead : false,
        };
        console.log('changes', changes);
      }
    }
    return messagingAdapter.updateOne(
      {
        id: action.message.group_id,
        changes,
      },
      newState,
    );
  }),
  // update group message is used after a save request
  on(messagesActions.gotMessageOnSocketUpdateIt, (state, action) => {
    // delete draft locally if message is sent
    const messages: Partial<IMessage>[] = DeepCopyService.deepCopy(
      state.entities[action.message.group_id]?.messages,
    );
    const drafts: Partial<IMessage>[] = state.drafts.filter(
      (draft) => draft.id !== action.message.id,
    );

    if (!messages) {
      console.warn('messages to update not found');
      return state;
    }

    // replace one existing message:
    // todo : destructure this - refactor me please
    if (!action.message.thread_id) {
      const indexToUpdate = messages.findIndex(
        (m) => (m.isLocalOnly && m.body === action.message.body) || m.id === action.message.id,
      );
      if (indexToUpdate >= 0) {
        messages[indexToUpdate] = action.message;
      }
    } else {
      const message = messages.find((m) => m.id === action.message.thread_id);
      if (message) {
        const threadMessageIndex = message.thread.findIndex(
          (m) => (m.isLocalOnly && m.body === action.message.body) || m.id === action.message.id,
        );
        if (threadMessageIndex >= 0) {
          message.thread[threadMessageIndex] = action.message as IMessage;
        }
      }
    }

    return messagingAdapter.updateOne(
      {
        id: action.message.group_id,
        changes: {
          messages,
        },
      },
      {
        ...state,
        drafts: drafts as IMessage[],
        lastNonDraftSentMessageId: action.isDraft
          ? state.lastNonDraftSentMessageId
          : action.message.id,
      },
    );
  }),
  on(messagesActions.updateDraft, (state, action) => {
    let drafts;
    const draftIndex = state.drafts.findIndex((draft) => draft.id === action.draft.id);
    if (draftIndex >= 0) {
      drafts = DeepCopyService.deepCopy(state.drafts);
      drafts[draftIndex] = action.draft;
    } else {
      drafts = [action.draft, ...state.drafts];
    }

    return {
      ...state,
      drafts,
    };
  }),
  on(messagesActions.addImageToCache, (state, action) => {
    // the imageSrc is created with URL.createObjectURL(imageBlob);
    const newCache = { ...state.profile_pic_cache, [action.user_id]: action.imageSrc };
    return {
      ...state,
      profile_pic_cache: newCache,
    };
  }),
  on(messagesActions.setIsLoading, (state, action) => {
    return {
      ...state,
      isLoading: action.isLoading ?? state.isLoading,
      isPaginationLoading: action.isPaginationLoading ?? state.isPaginationLoading,
    };
  }),
  on(messagesActions.setIsSearchedUsersLoading, (state, action) => {
    return {
      ...state,
      setIsSearchedUsersLoading: action.isSearchedUsersLoading ?? state.isSearchedUsersLoading,
    };
  }),
  on(messagesActions.setLastNonDraftMessage, (state, action) => {
    return {
      ...state,
      lastNonDraftSentMessageId: action.lastNonDraftMessageId,
    };
  }),
  on(messagesActions.singleGroupLoaded, (state, action) => {
    const selectedGroup = action.groups[0];
    const newState = {
      ...state,
      selectedGroupId: selectedGroup.id,
      discussionMembers: new Set<IMessagingMember>(selectedGroup.members),
    };
    return messagingAdapter.setAll([...action.groups], { ...newState });
  }),
  on(messagesActions.newMessageGotOnSocket, (state, action) => {
    const hasUnreadMessage =
      action.message.group_id !== state.selectedGroupId ||
      (state.selectedView !== MESSAGING_VIEWS.DISCUSSION_VIEW &&
        state.selectedView !== MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW);
    return {
      ...state,
      hasUnreadMessage,
    };
  }),
  on(messagesActions.setOverallMessagingUnreadStatus, (state, action) => {
    return {
      ...state,
      hasUnreadMessage: action.isUnread,
    };
  }),
  on(
    messagesActions.messageStatusUpdatedInEffect,
    (state: MessagingState, action): MessagingState => {
      const selectedGroup: IMessagingGroup = DeepCopyService.deepCopy(
        state.entities[state.selectedGroupId],
      );
      if (!selectedGroup?.messages) {
        console.warn('no selected group in reducer messageStatusUpdatedInEffect');
        return {
          ...state,
        };
      }
      const message = selectedGroup.messages.find((m) => m.id === action.messageId);
      message.is_read = action.isRead;
      return messagingAdapter.updateOne(
        { id: selectedGroup.id, changes: selectedGroup },
        { ...state },
      );
    },
  ),
);

function getRandomString() {
  return (Math.random() + 1).toString(36).substring(7);
}
