import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeamManagementService } from '../../../services/team-management.service';
import { TeamMemberSimple } from '../../../store/team-management/team-management.interfaces';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { getInitials } from '../../../store/activities/activities.constants';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-assign-teammate-table',
  standalone: true,
  imports: [FloatingInputComponent, NgScrollbar, AsyncPipe, NgIf],
  templateUrl: './assign-teammate-table.component.html',
  styleUrl: './assign-teammate-table.component.scss',
})
export class AssignTeammateTableComponent implements OnInit {
  @Input() projectId: number | undefined;
  @Input() set teamMembers(value: TeamMemberSimple[]) {
    this.fetchTeamMates = false;
    this.isLoading = false;
    this.teamMates = value;
  }

  @Output() teamMateSelected = new EventEmitter<TeamMemberSimple>();

  _displayedTeamMates: (TeamMemberSimple & { initials?: string })[] = [];
  set displayedTeamMates(value: TeamMemberSimple[]) {
    this._displayedTeamMates = value.map((teamMate) => {
      return {
        ...teamMate,
        initials: getInitials(teamMate),
      };
    });
  }
  get displayedTeamMates(): (TeamMemberSimple & { initials?: string })[] {
    return this._displayedTeamMates;
  }

  private _teamMates: TeamMemberSimple[] = [];
  get teamMates(): TeamMemberSimple[] {
    return this._teamMates;
  }
  set teamMates(value: TeamMemberSimple[]) {
    this._teamMates = value;
    this.displayedTeamMates = [...value];
  }

  isLoading = true;
  errorFetchingMembers = false;
  fetchTeamMates = true;
  constructor(private teamService: TeamManagementService) {}

  ngOnInit(): void {
    if (this.fetchTeamMates) {
      this.isLoading = true;
      this.teamService.getTeamMembers([this.projectId]).subscribe({
        next: (res) => {
          this.teamMates = res;
          this.isLoading = false;
          this.errorFetchingMembers = false;
        },
        error: (err) => {
          console.log('error', err);
          this.errorFetchingMembers = true;
          this.isLoading = false;
        },
      });
    }
  }

  searchTeamMates(searchText: string) {
    if (!searchText) {
      this.displayedTeamMates = this.teamMates;
      return;
    }

    this.displayedTeamMates = this.teamMates.filter((teamMate) => {
      const name = `${teamMate.first_name} ${teamMate.last_name}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  onTeamMateSelect(teamMate: TeamMemberSimple) {
    this.teamMateSelected.emit(teamMate);
  }
}
