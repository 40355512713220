import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

export const NO_TIMEZONE = 'noTimezone';
export const UTC_DATE = 'utcDate';
export const NO_TIMEZONE_DATE = 'noTimezoneDate';
export const NO_TIMEZONE_TIME = 'noTimezoneTime';
export const DMMM = 'DMMM';
export const MMMMDO = 'monthDay';
export const YYYY = 'year';
export const DOT_SEPARATED = 'dotSeparated';
export const UTC_TO_LOCAL = 'utcToLocal';
export const MMMMDOYYYY = 'MMMMDOYYYY';
export const CALENDAR = 'calendar';
export const YYYY_MM_DD = 'YYYY_MM_DD';
export const LT = 'LT';
export const MMMDD = 'MMM DD';
export const MMMDYYYY = 'MMMDYYYY';
export const MMMDDYYYY = 'MMMDDYYYY';

@Pipe({
  name: 'datepipe',
  standalone: true,
})
export class DateCustomPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (args[0]) {
      case NO_TIMEZONE:
        return this.transformWithoutTimezone(value, args);

      case NO_TIMEZONE_DATE:
        return this.transformWithoutTimezone(value).split(' ')[0];

      case NO_TIMEZONE_TIME:
        return this.transformWithoutTimezone(value).split(' ')[1];

      case UTC_DATE:
        if (!value) {
          console.log('invalid date', value);
          return 'Invalid Date.';
        }
        return moment.utc(value).format('MM/DD/YYYY');

      case UTC_TO_LOCAL:
        return moment.utc(value).local().format('MM/DD/YYYY');

      case DOT_SEPARATED:
        return moment.utc(value).format('MM.DD.YYYY');

      case DMMM:
        return moment.utc(value).local().format('D MMM');

      case MMMMDO:
        if (args[1] === NO_TIMEZONE) {
          return moment(value).format('MMMM Do ');
        }
        return moment.utc(value).local().format('MMMM Do ');

      case MMMMDOYYYY:
        if (args[1] === NO_TIMEZONE) {
          return moment(value).format('MMMM Do, YYYY');
        }
        return moment.utc(value).local().format('MMMM Do, YYYY');

      case YYYY:
        if (args[1] === NO_TIMEZONE) {
          return moment(value).format('YYYY');
        }
        return moment.utc(value).local().format('YYYY');

      case CALENDAR: {
        return moment(value).utc().local().toISOString();
      }

      case YYYY_MM_DD: {
        return moment(value).format('YYYY-MM-DD');
      }

      case LT: {
        return moment(value).format('LT');
      }

      case MMMDD: {
        return moment(value).format('MMM DD');
      }

      case MMMDYYYY: {
        return moment(value).format('MMM D, YYYY');
      }

      case MMMDDYYYY: {
        return moment(value).format('MMM DD, YYYY');
      }

      default:
        return this.transformUTC(value);
    }
  }

  transformUTC(value) {
    try {
      const date = new Date(value);

      if (isNaN(date.getTime())) {
        return value;
      } else {
        return moment.utc(value).local().toDate().toLocaleDateString();
      }
    } catch (e) {
      return value;
    }
  }

  transformWithoutTimezone(value: string, ...args: unknown[]): string {
    try {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return value;
      } else {
        return moment(value).toDate().toLocaleDateString();
      }
    } catch (e) {
      return value;
    }
  }
}
