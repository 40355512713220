import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ContractorsApiService } from '../services/contractors-api.service';
import { NotificationsService } from '../services/notifications.service';
import { ProjectApiService } from '../services/project-api.service';
import { EventEmitter } from '@angular/core';
import { CONTRACTOR_SELECTOR } from '../framework/constants/view.constants';
import { uniqBy } from 'lodash';
export enum SORT_DIR {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
  DISABLED = 'disabled',
}

export class ContractorDataSource extends DataSource<any | undefined> {
  contractors = [];
  currentPage = 0;
  currentSearch = '';
  currentSortDir = '';
  currentSortColumn = '';

  private cachedData: Array<any> = [];
  private PAGE_SIZE = 25;
  private LOADING_ROW = {
    id: -1,
    message: 'Loading...',
  };
  private NO_DATA_ROW = {
    GENERAL: {
      id: -1,
      message: 'No items to list',
    },
    INVITES: {
      id: -1,
      message: 'No invites',
    },
    SCHEDULED: {
      id: -1,
      message: 'No visits scheduled',
    },
  };
  private dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedData);
  private subscription = new Subscription();
  private currentType = CONTRACTOR_SELECTOR.ALL;

  constructor(
    private contractorApi: ContractorsApiService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private SortByProjectEvent: EventEmitter<boolean>,
  ) {
    super();
    this._fetchPage();

    // this.SortByProjectEvent.subscribe(event => {
    //   if (this.isSortByProject !== event) {
    //     this.isSortByProject = event;
    //     this.fetchedPages = new Set<number>();
    //     this.cachedData = [];
    //     this.dataStream.next(this.cachedData);
    //     if (this.isSortByProject) {
    //       this._fetchPageByProject(0);
    //       // this._fetchPage(0);
    //     } else {
    //       this._fetchPageByProject(0);
    //       // this._fetchPage(0);
    //     }
    //     console.log('in data source', event);
    //   }
    // });
  }

  connect(
    collectionViewer: CollectionViewer,
  ): Observable<(any | undefined)[] | ReadonlyArray<any | undefined>> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const startPage = 0; // this._getPageForIndex(range.start);
        console.log(range);
        if (this.PAGE_SIZE * this.currentPage - 10 <= range.end) {
          this._fetchPage();
        }
      }),
    );
    return this.dataStream;
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  cleanCache() {
    this.currentPage = 0;
    this.cachedData = [this.LOADING_ROW];
    this.dataStream.next(this.cachedData);
  }

  changeFilter(type: CONTRACTOR_SELECTOR) {
    this.currentType = type;
    this.currentSearch = '';
    this.cleanSort();
    this.cleanCache();
    this._fetchPage();
  }

  cleanSort() {
    this.currentSortDir = '';
    this.currentSortColumn = '';
  }

  //TODO: seems like this isn't used, should be removed
  changeSort(column: string, dir: string) {
    if (dir === SORT_DIR.ASC || dir === SORT_DIR.DESC) {
      this.cleanCache();

      this.currentSortDir = dir;
      switch (column) {
        case 'company':
          this.currentSortColumn = 'licenses.company_name';
          break;

        case 'addr':
          this.currentSortColumn = 'licenses.city';
          break;

        case 'contact':
          this.currentSortColumn = 'users.email';
          break;

        case 'license':
          this.currentSortColumn = 'licenses.license_number';
          break;

        case 'meeting':
          this.currentSortColumn = 'visits.day';
          break;
      }

      this._fetchPage();
    } else {
      this.cleanSort();
      this.cleanCache();
      this._fetchPage();
    }
  }

  // TODO: seems like this isn't used, should be removed
  searchPage(searchText) {
    return new Promise((res) => {
      this.currentSearch = searchText;
      this.cleanCache();
      this._fetchPage();
      res(true);
    });
  }

  isDataEmpty() {
    return this.cachedData.length === 0;
  }

  private _fetchPage() {
    const LAST_STATE = this.currentType;

    const findMeassageIndex = () => this.cachedData.findIndex((o) => o.id === this.LOADING_ROW.id);

    const removeMessages = () => {
      let loadingIndex = findMeassageIndex();

      while (loadingIndex >= 0) {
        this.cachedData.splice(loadingIndex, 1);
        loadingIndex = findMeassageIndex();
      }
    };

    const checkEmptyState = () => {
      switch (this.currentType) {
        case CONTRACTOR_SELECTOR.INVITED:
          return this.NO_DATA_ROW.INVITES;
        case CONTRACTOR_SELECTOR.SCHEDULED:
          return this.NO_DATA_ROW.SCHEDULED;
        default:
          return this.NO_DATA_ROW.GENERAL;
      }
    };

    const checkEmptyArray = () => {
      if (this.cachedData.length === 0) {
        this.cachedData.push(checkEmptyState());
        this.dataStream.next(this.cachedData);
      }
    };

    this.currentPage++;

    if (findMeassageIndex() < 0) {
      this.cachedData.push(this.LOADING_ROW);
      this.dataStream.next(this.cachedData);
    }

    this.contractorApi
      .getContractors(
        this.currentPage,
        this.currentType,
        this.currentSearch,
        this.currentSortColumn,
        this.currentSortDir,
      )
      .then(
        (data) => {
          removeMessages();

          if (LAST_STATE === this.currentType) {
            if (data.data) {
              this.cachedData.push(...data.data);
            } else {
              this.cachedData.push(...data);
            }
            this.cachedData = uniqBy(this.cachedData, 'id');

            checkEmptyArray();

            this.dataStream.next(this.cachedData);
          }
        },
        (err) => {
          removeMessages();
          checkEmptyArray();
          console.log(this.cachedData);

          if (err !== 'No data.') {
            this.notif.showError(err);
          }
        },
      );
  }
}
