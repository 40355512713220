import { AfterViewInit, Directive, ElementRef, Host, Input, Renderer2 } from '@angular/core';
import moment from 'moment';
import { CalendarComponent } from '../framework/inputs/calendar/calendar.component';

const EVENT_DAY_CLASS = 'event-day';
const SCHEDULE_DAY_CLASS = 'schedule-day';
const NO_VISIT_DAY = 'none';

@Directive({
  selector: '[appCalendarEvents]',
  standalone: true,
})
export class CalendarEventsDirective implements AfterViewInit {
  days: Array<any> = [];
  events = [];
  eventsDates = {};

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Host() private calendar: CalendarComponent,
  ) {
    // console.log('asdasdasd', calendar);
  }

  @Input('events') set setEvents(events) {
    this.refreshEvents(events);
  }

  async refreshEvents(events) {
    const countValuesByKey = (arr, key) => {
      return arr.reduce((r, c) => {
        if (r[c[key]] === 'none') {
          r[c[key]] = 0;
        }
        if (c.status === 'visit') {
          r[c[key]] = (r[c[key]] || 0) + 1;
        } else if (c.status === 'none' && !(r[c[key]] > 0)) {
          r[c[key]] = NO_VISIT_DAY;
        }
        return r;
      }, {});
    };

    this.events = events;
    this.eventsDates = await countValuesByKey(events, 'day');

    // console.log(this.events, this.eventsDates);

    this.loadEvents();
  }

  ngAfterViewInit(): void {
    const buttons = this.el.nativeElement.querySelectorAll(
      '.mat-calendar .calendar-header-previous-button,' +
        '.mat-calendar .calendar-header-next-button',
    );

    if (buttons) {
      Array.from(buttons).forEach((button) => {
        this.renderer.listen(button, 'click', () => {
          // console.log(this.calendar.calendar.monthView._monthLabel);
          // let asd = new DatePipe('en-US');
          // let asdw = asd.transform(new Date(2014, 3), 'MMM');
          // console.log(asdw);
          this.loadEvents();
        });
      });
    }

    this.loadEvents();
  }

  loadEvents() {
    this.clearEvents();
    this.days = this.el.nativeElement.querySelectorAll('.mat-calendar-body-cell-content');

    Object.keys(this.eventsDates).forEach((date) => {
      const current = moment.utc(date);

      if (
        current.format('MMMM').toUpperCase().includes(this.calendar.calendar.monthView._monthLabel)
      ) {
        // if (this.eventsDates = 'visit') {
        // console.log(
        //   current,
        //   current.toDate().getUTCDate(),
        //   this.eventsDates[date]
        // );
        this.addEvent(current.toDate().getUTCDate(), this.eventsDates[date]);
        // }
      }
    });
  }

  addEventNumber(nrEvents): string {
    return `<div class="day-event-nr">` + nrEvents + `</div>`;
  }

  clearEvents() {
    this.clearCalendarEventNumbers();

    let dayElements = this.el.nativeElement.querySelectorAll(
      '.' + SCHEDULE_DAY_CLASS,
    ) as Array<HTMLElement>;

    for (const element of dayElements) {
      element.classList.remove(SCHEDULE_DAY_CLASS);
    }

    dayElements = this.el.nativeElement.querySelectorAll(
      '.' + EVENT_DAY_CLASS,
    ) as Array<HTMLElement>;

    for (const element of dayElements) {
      element.classList.remove(EVENT_DAY_CLASS);
    }
  }

  addEvent(day, nr) {
    if (nr === NO_VISIT_DAY) {
      if (!this.days[day - 1].classList.contains(SCHEDULE_DAY_CLASS)) {
        this.days[day - 1].className += ' ' + SCHEDULE_DAY_CLASS;
      }
    } else {
      if (!this.days[day - 1].classList.contains(EVENT_DAY_CLASS)) {
        this.days[day - 1].className += ' ' + EVENT_DAY_CLASS;
      }
      this.days[day - 1].innerHTML += this.addEventNumber(nr);
    }
  }

  private clearCalendarEventNumbers() {
    const elements = this.el.nativeElement.getElementsByClassName('day-event-nr') as Array<any>;
    while (elements.length > 0) {
      elements[0].remove();
    }
  }
}
