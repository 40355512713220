<div (click)="downloadFile()" class="flex flex-col gap-2 items-center cursor-pointer">
  <div class="thumbnail" [ngClass]="{ 'cover-image': type === 'image' }">
    <img *ngIf="type === 'general'" src="assets/icons/files/file_general.png" alt="" />
    <img *ngIf="type === 'image'" [src]="url" alt="" />
    <img *ngIf="type === 'pdf'" src="assets/icons/files/file_pdf.png" alt="" />
    <img *ngIf="type === 'audio'" src="assets/icons/files/file_audio.png" alt="" />
    <img *ngIf="type === 'ppt'" src="assets/icons/files/file_ppt.png" alt="" />
    <img *ngIf="type === 'txt'" src="assets/icons/files/file_txt.png" alt="" />
    <img *ngIf="type === 'video'" src="assets/icons/files/file_video.png" alt="" />
    <img *ngIf="type === 'xls'" src="assets/icons/files/file_xls.png" alt="" />
  </div>

  <span
    #optionsTrigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    (mouseenter)="showFullName = true"
    (mouseleave)="showFullName = false"
    class="file text-color_secondary"
  >
    {{ file?.name | shortenFileName: maxFileNameLength }}
  </span>
  <app-options-list-general
    *ngIf="file?.name?.length >= maxFileNameLength"
    [cdkConnectedOverlayHasBackdrop]="false"
    [(show)]="showFullName"
    [trigger]="optionsTrigger"
    [overlayPositions]="overlayPositions"
    [withAnimation]="false"
  >
    <div class="options-overlay-white py-0.5 px-2 rounded-sm">{{ file.name }}</div>
    <!--    [overlayPositions]="dropdownOverlayPositions"-->
  </app-options-list-general>
</div>
