import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class CdkOverlayContainer extends OverlayContainer {
  /**
   * Create overlay container and append to ElementRef from directive
   * Used from cdkOverlayContainer directive
   */
  public myCreateContainer(element: HTMLElement): void {
    const container = document.createElement('div');
    // TODO RENAME FROM GLOBAL SCSS AND OVERLAY CONTAINER TOO
    container.classList.add('skillhop-overlay-container');
    element.appendChild(container);
    this._containerElement = container;
  }
  /**
   * Prevent creation of the HTML element, use custom method above
   */
  protected _createContainer(): void {
    return;
  }
}
