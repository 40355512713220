<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="show"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="toggleShow()"
>
  <div class="options-overlay-white py-1.5 px-2">
    <div
      (click)="toggleShow(); onOptionSelected(item)"
      [ngClass]="{ 'disabled text-color_disabled': item?.disabled, 'text-shade_1': !item?.disabled }"
      class="py-1 px-1 pointer-cursor item text-size_m"
      *ngFor="let item of actions"
    >
      <span>{{ item.value }}</span>
    </div>
  </div>
</ng-template>
