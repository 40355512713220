import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseTransform',
  standalone: true,
})
export class CaseTransformPipe implements PipeTransform {
  transform(value: string, type: string, ...args: unknown[]): string {
    if (type === 'capitalize') {
      return value[0].toUpperCase() + value.substr(1).toLowerCase();
    }
    return '';
  }
}
