import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { NotificationsService } from './services/notifications.service';
import { interval } from 'rxjs';
import { IntercomService } from './services/intercom.service';
import { CurrentUserService } from './services/current-user.service';
import { RouterOutlet } from '@angular/router';
import { NotificationComponent } from './framework/notification/notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NotificationComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Skillhop';
  constructor(
    public el: ElementRef,
    private user: CurrentUserService,
    private swUpdate: SwUpdate,
    private notif: NotificationsService,
    private intercomService: IntercomService,
  ) {}

  ngOnInit() {
    this.intercomService.bootIntercom();
    this.loadScript();

    if (this.swUpdate.isEnabled) {
      const second = 1000; // in ms
      const minute = 60; // in s
      // ex. 1000 * 60 => 60000ms (1 minute)
      const minutes = second * minute; // in ms
      interval(0.5 * minutes).subscribe(() => this.swUpdate.checkForUpdate());
    }

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
      )
      .subscribe(async (evt) => {
        console.warn('New version update available!', evt);
        this.notif
          .showPopup('A new Skillhop version is available. Do you want to update now?')
          .then((res) => {
            if (res) {
              this.swUpdate.activateUpdate().then(() => document.location.reload());
            }
          });
      });
  }

  private loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid: ${environment.hotjar_key},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(document.createComment('Hotjar Tracking Code'));
      document.head.appendChild(script);
    }
  }
}
