<div class="interaction-bar-wrapper re-schedule-component">
  <div class="interaction-bar-title">
    <!--    <span *ngIf="type == INTERACTION_BAR_STATE.SCHEDULE">-->
    <!--      {{type}}</span>-->
    <!--    <span *ngIf="type != INTERACTION_BAR_STATE.SCHEDULE">-->
    Schedule
    <!--    </span>-->
  </div>

  <div class="interaction-bar-content">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="date">{{ visitDate }}</div>
    </div>
    <app-calendar #schedule (dateChange)="registerNewDate($event)" (events)="registerEvents($event)"> </app-calendar>

    <ng-scrollbar class="std-scrollbar">
      <app-selection-list
        (changes)="refreshCalendar()"
        [barState]="{ type: type, data: data }"
        [date]="calendarDate"
        [events]="calendarEvents"
        [intervalMin]="30"
      ></app-selection-list>
    </ng-scrollbar>
    <button (click)="close()" class="btn-save" mat-button>Save</button>
  </div>
</div>
