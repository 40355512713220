import dayjs from 'dayjs';
import { ISimpleProject } from '../../pages/webapp/projects/projects.interface';

export const activitiesFeatureKey = 'activities';

export type ActivityViewTypes = 'notStarted' | 'inProgress' | 'completed';
export enum ACTIVITY_VIEW_TYPES {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}
export type ActivityBackendFilter = 'not-started' | 'in-progress' | 'completed';
export type ActivityFilterKeys =
  | 'types[]'
  | 'search'
  | 'assigneeIds[]'
  | 'projectIds[]'
  | 'unassigned'
  | 'startDateLT'
  | 'startDateGTE';
// | 'endDateLT'  // it is implemented on backend, yet not used on frontend
// | 'endDateGTE'; // it is implemented on backend, yet not used on frontend

export const defaultActivitiesFilters = {
  projectIds: [],
};

export type ActivityFilters = {
  'types[]'?: string[];
  search?: string;
  'assigneeIds[]'?: number[];
  unassigned?: 0 | 1;
  startDateLT?: string;
  startDateGTE?: string;
  // endDateLT?: string;
  // endDateGTE?: string;
};
export const UNASSIGNED_ID = -1;
export const unassignedUser = { id: UNASSIGNED_ID, name: 'Unassigned' };

export const transformViewTypeToBackendFilter: { [k: string]: ActivityBackendFilter } = {
  notStarted: 'not-started',
  inProgress: 'in-progress',
  completed: 'completed',
};

export type ActivityType =
  | 'contract'
  | 'change'
  | 'invoice'
  | 'misc'
  | 'tracking'
  | 'checklist'
  | 'daily';

export const NO_PROJECT_ID = -1;

export const NO_SIMPLE_PROJECT: ISimpleProject = {
  id: NO_PROJECT_ID,
  title: 'No Related Project',
  start_date: '',
};
export type Activity = {
  id: number;
  author: Author;
  assignee: ActivityAssignee;
  title: string;
  start_date: string;
  end_date: string;
  completed_at: string | null;
  completed?: boolean;
  type: ActivityType;
  project: Project;
  progress_item: ProgressItem;
  commitment: Commitment;
  percent: number; // calculated on the frontend
  dayCount: number; // calculated on the frontend
  checklist_activities?: Activity[];
  description?: string;
};

export type ISidebarActivity = {
  id?: number;
  assignee_id?: number;
  completed?: boolean;
  title: string;
  start_date: string;
  end_date: string;
  project_id: number;
  description?: string;
};

export type ISidebarActivityPayload = {
  id?: number;
  assignee_id?: number;
  completed?: boolean;
  title: string;
  start_date: string;
  end_date: string;
  project_id: number;
};

interface ProgressItem {
  id: number;
  title: string;
  work_percentage: number;
}

interface Author {
  id: number;
  name: string;
}

export interface ActivityAssignee {
  id: number;
  first_name: string;
  last_name: string;
  type_id?: number;
}

interface Project {
  id: number;
  title: string;
  start_date: string;
}

interface Commitment {
  id: number;
  title: string;
  company_name?: string;
  invoice_number: null;
  value?: number;
}

export enum ACTIVITY_VIEW {
  ACTIVITIES = 'Activities',
  CALENDAR = 'Calendar',
  ACTIVITY_LOG = 'Activity Log',
}

export const ACTIVITY_VIEWS = [
  { title: ACTIVITY_VIEW.ACTIVITIES, value: ACTIVITY_VIEW.ACTIVITIES },
  { title: ACTIVITY_VIEW.CALENDAR, value: ACTIVITY_VIEW.CALENDAR },
  { title: ACTIVITY_VIEW.ACTIVITY_LOG, value: ACTIVITY_VIEW.ACTIVITY_LOG },
];

export type ActivityAssignOptions = 'Reassign' | 'Message' | 'Remove';
export const ACTIVITY_ASSIGN_OPTIONS: ActivityAssignOptions[] = ['Reassign', 'Message', 'Remove'];

export type ActivityCalendarDateWindow = {
  startDateGTE: string;
  startDateLT: string;
};

export const calculateDifferenceBetweenDates = (
  startDateString: string,
  endDateString: string,
  completed?: boolean,
) => {
  if (completed) {
    return {
      percent: 100,
      dayCount: 0,
    };
  }

  // color is supposed to be the default because it will be completed only when it's 100%
  let percent = 100;
  let dayCount = null;
  const today = dayjs();
  const startDate = dayjs(startDateString);
  const endDate = dayjs(endDateString);
  if (today.isAfter(startDate)) {
    const totalDays = endDate.diff(startDate, 'day');
    const daysPassed = today.diff(startDate, 'day');
    percent = 100 - Math.round((daysPassed / totalDays) * 100 * 100) / 100;
    dayCount = totalDays - daysPassed;

    // here color should be red
    if (dayCount < 0) {
      percent = 100;
    }

    // here shouldn't be any color
    if (dayCount === 0) {
      percent = 0;
    }
  }

  return {
    percent,
    dayCount,
  };
};

export const getInitials = (assignee: ActivityAssignee) => {
  if (!assignee) {
    return null;
  }
  if (assignee.first_name && assignee.last_name) {
    return `${assignee.first_name.charAt(0)}${assignee.last_name.charAt(0)}`;
  }

  if (assignee.first_name) {
    return assignee.first_name.charAt(0);
  }
  if (assignee.last_name) {
    return assignee.last_name.charAt(0);
  }

  return null;
};

export const ACTIVITIES_CALENDAR_SCROLL_MONTHS = 3;
