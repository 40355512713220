import { Component, Input, OnInit } from '@angular/core';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../../../../../framework/constants/interaction-bar.constants';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { CommitmentsService } from '../../../../../services/commitments.service';
import { NgClass, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-add-panel-view',
  templateUrl: './add-panel-view.component.html',
  styleUrls: ['./add-panel-view.component.scss'],
  standalone: true,
  imports: [MatButton, NgClass, NgIf],
})
export class AddPanelViewComponent implements OnInit {
  constructor(
    private interactionBarState: InteractionBarStateService,
    public user: CurrentUserService,
    private commitmentsService: CommitmentsService,
  ) {}

  availableContracts;

  private _project;
  @Input() set project(value) {
    if (value) {
      this._project = value;
      // todo: this method will be deprecated, use new endpoint
      this.commitmentsService.getAllCommitments(this.project.id).then((data) => {
        this.commitments = data;
      });
    }
  }

  get project() {
    return this._project;
  }

  private _commitments;
  set commitments(value) {
    if (value) {
      this._commitments = value;
      this.availableContracts = this.checkApprovedContracts();
    }
  }

  get commitments() {
    return this._commitments;
  }

  private checkApprovedContracts(): boolean {
    let foundApprovedContract = false;
    this.commitments.contractors.forEach((contractor) => {
      const isApproved = contractor.commitments.contracts.find(
        (contract) => contract.approval_status === 'approved',
      );
      if (isApproved) {
        foundApprovedContract = true;
      }
    });
    return foundApprovedContract;
  }

  ngOnInit(): void {}

  openAddContractsAgreement() {
    this.interactionBarState.openCommitments(INTERACTION_BAR_STATES.SIDEBAR_ADD_CONTRACT);
  }

  openAddChangeOrder() {
    this.interactionBarState.openCommitments(INTERACTION_BAR_STATES.SIDEBAR_CHANGE_ORDER);
  }

  openAddInvoices() {
    this.interactionBarState.openCommitments(INTERACTION_BAR_STATES.SIDEBAR_INVOICE);
  }
  openAddDirectCosts() {
    this.interactionBarState.openCommitments(INTERACTION_BAR_STATES.SIDEBAR_MISC_COST);
  }
}
