import { ITaskMember, TASK_TYPE } from '../tasks/tasks.interfaces';
import {
  DISCUSSION_FILTER_TYPE,
  DISCUSSION_TYPES,
} from '../../framework/constants/messages.constants';

export interface IMessagingUser {
  id: number;
  name: string;
  profile_picture_id: number;
  company_name: string;
  is_admin: boolean;
  was_deleted?: boolean;
}

export interface IMessage {
  id: number;
  uuid?: string;
  body: string;
  user: Partial<IMessagingUser>;
  thread: IMessage[];
  thread_id: number;
  group_id: number;
  created_at: string;
  updated_at: string;
  attachments: any[];
  is_read?: boolean; // refers to the thread which belongs to the message
  // isLocalOnly is true when the message isn't saved yet to the backend; otherwise it's undefined
  isLocalOnly?: boolean;
  is_announcement?: boolean;
}

export interface IGroupMessagesAndDrafts {
  drafts: IMessage[];
  messages: IMessage[];
}

export interface IMessageThread {
  mainMessage: IMessage;
  replies: IMessage[];
}

export interface IMessagingUserListData {
  usersRelated: IMessagingMember[];
  discussionMembers: IMessagingMember[];
}

export interface ITaskUsersListData {
  usersRelated: ITaskMember[];
  selectedMembers: ITaskMember[];
}

export interface ICreateMessageData {
  id?: number;
  group_id: number;
  body: string;
  is_draft: boolean;
  thread_id: number;
}

export interface IMessagingMember {
  user_id: string;
  name: string;
  company_name: string;
  is_team_member?: boolean;
  email?: string | number;
  is_admin?: boolean;
  added_by?: { user_id: string; name: string; company_name: string };
}

export interface IMessagingGroup {
  id: number;
  name: string;
  type:
    | DISCUSSION_TYPES.DIRECT_TYPE
    | DISCUSSION_TYPES.GROUP_TYPE
    | DISCUSSION_TYPES.TASK_TYPE
    | DISCUSSION_TYPES.ARCHIVED
    | null;
  task_type: TASK_TYPE.CUSTOM | TASK_TYPE.DAILY;
  status: null | 'archived';
  members: IMessagingMember[];
  last_comment_date: string;
  message: string;
  is_read: boolean;
  messages: Partial<IMessage>[];
  loadedAllPastMessages: boolean;
  task_id?: number;
}

export enum MESSAGING_VIEWS {
  DISCUSSION_LIST,
  DISCUSSION_CREATE,
  DISCUSSION_VIEW,
  DISCUSSION_THREAD_VIEW,
}

export type groupFilterType =
  | DISCUSSION_TYPES.DIRECT_TYPE
  | DISCUSSION_TYPES.GROUP_TYPE
  | DISCUSSION_TYPES.TASK_TYPE;

export interface IMessagingGroupFilters {
  search?: string;
  discussionType?: DISCUSSION_FILTER_TYPE;
  status?: DISCUSSION_TYPES.ARCHIVED;
  page?: number;
  perPage?: number;
  group_id?: number;
}

export interface IMessagingGroupApiFilters {
  search?: string;
  type?: groupFilterType;
  status?: DISCUSSION_TYPES.ARCHIVED;
  page?: number;
  perPage?: number;
  group_id?: number;
}

export interface IMessageFilters {
  search?: string;
  offset?: number; // the oldest loaded message id - replaces page parameter
  perPage?: number;
}

export interface IMessagingProfilePictureCache {
  [key: number]: string; // user_id: imageSrc
  // - the imageSrc points to a local image file created with URL.createObjectURL(imageBlob);
}

export type withUser = 1 | 0;
