import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../constants/interaction-bar.constants';
import { Store } from '@ngrx/store';
import { commitmentsSelectors } from '../../store/commitments/commitments.selectors';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ICommitmentBudgetLineItem } from '../../store/commitments/commitments.types';
import { CommonModule } from '@angular/common';
import { MatButton } from '@angular/material/button';

import { ContainsValuePipe } from '../../pipes/framework/contains-value.pipe';
import { NgScrollbar } from 'ngx-scrollbar';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';

export type SelectedOption = {
  action: 'select' | 'delete';
  item?: ICommitmentBudgetLineItem; // todo check type
  name?: string;
  index?: number;
};

@Component({
  selector: 'app-budget-line-item-view-table-dropdown',
  templateUrl: './budget-line-item-view-table-dropdown.component.html',
  styleUrls: ['./budget-line-item-view-table-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButton, ContainsValuePipe, NgScrollbar, MoneyPipe],
})
export class BudgetLineItemViewTableDropdownComponent implements OnInit, OnDestroy {
  @Input() disabledLineItems: any[];
  @Input() commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE;
  @Output() optionSelected = new EventEmitter<SelectedOption>();

  budgetLineItems: ICommitmentBudgetLineItem[];
  COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;

  sidebarContract$ = this.store.select(commitmentsSelectors.getSidebarSelectedContractSummary);
  budgetLineItems$ = this.store
    .select(commitmentsSelectors.getBudgetLineItems)
    .pipe(filter((value) => !!value));
  isDestroyed$ = new Subject();

  constructor(
    public user: CurrentUserService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.subscribeToChanges();
  }

  subscribeToChanges() {
    combineLatest([this.sidebarContract$, this.budgetLineItems$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([contract, lineItems]) => {
        if (!contract || this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT) {
          this.budgetLineItems = lineItems;
          return;
        }

        this.budgetLineItems = lineItems.map((lineItem) => {
          const foundItem = contract.contract.commitment_items.find(
            (ci) => ci.item?.id === lineItem?.id,
          );
          return {
            ...lineItem,
            contractApproved: !!foundItem,
          };
        });
      });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
