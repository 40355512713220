import { createFeatureSelector, createSelector } from '@ngrx/store';
import { teamManagementFeatureKey, TeamManagementState } from './team-management.reducer';
import { DeepCopyService } from '../../services/deep-copy.service';
import { TEAM_VIEWS } from '../../framework/constants/team.constants';

export const teamManagementFeatureSelector =
  createFeatureSelector<TeamManagementState>(teamManagementFeatureKey);

export const getTeamMembersByView = createSelector(teamManagementFeatureSelector, (state) =>
  state.view === TEAM_VIEWS.MY_TEAMS ? state.myTeams?.[0]?.team_members : state.peerTeams,
);

export const getSelectedTeamsView = createSelector(
  teamManagementFeatureSelector,
  (state) => state.view,
);

export const teamManagementSidebarViewType = createSelector(
  teamManagementFeatureSelector,
  (state: TeamManagementState) => state.viewType,
);

export const isOwnerEdited = createSelector(teamManagementFeatureSelector, (state) => {
  return state?.selectedTeamMember?.role === 'owner';
});

export const getTeamManagementRoles = createSelector(
  teamManagementFeatureSelector,
  isOwnerEdited,
  (state: TeamManagementState, isOwner) => (isOwner ? [...state.roles, 'owner'] : state.roles),
);
export const getTeamManagementProperties = createSelector(
  teamManagementFeatureSelector,
  (state: TeamManagementState) => state.projectProperties,
);

/**
 * Returns all properties filtered by search query
 */
export const getTeamManagementFilteredProperties = createSelector(
  teamManagementFeatureSelector,
  (state: TeamManagementState) =>
    state.projectProperties.filter(
      (property) =>
        property.team_id === state.selectedTeamId &&
        property.name.toLowerCase().includes(state.propertiesSearchQuery.toLowerCase()),
    ),
);

export const getAreProfilePicturesLoading = createSelector(
  teamManagementFeatureSelector,
  (state) => state.areProfilePicturesLoading,
);
export const areTeamsFetching = createSelector(
  teamManagementFeatureSelector,
  (state) => state.areTeamsFetching,
);

export const isSelectedMemberFetching = createSelector(
  teamManagementFeatureSelector,
  (state) => state.isSelectedMemberFetching,
);

export const getIsPropertyChecked = (id: number) =>
  createSelector(teamManagementFeatureSelector, (state) => {
    return state.checkedProperties.includes(id);
  });

export const getIsProjectChecked = (id: number) =>
  createSelector(teamManagementFeatureSelector, (state) => {
    return state?.checkedProjects?.includes(id);
  });

export const getProfilePicture = (id: number) =>
  createSelector(teamManagementFeatureSelector, (state) => {
    return state.picturesCache[id];
  });
export const getCheckedProjects = createSelector(teamManagementFeatureSelector, (state) => {
  return state?.checkedProjects ?? [];
});

export const getCheckedProperties = createSelector(teamManagementFeatureSelector, (state) => {
  return state.checkedProperties;
});

/**
 * Returns properties that are part of the checked properties with projects filtered by search query
 */
export const getFilteredSelectedProperties = createSelector(
  teamManagementFeatureSelector,
  (state) => {
    const filteredProperties = DeepCopyService.deepCopy(
      state.projectProperties.filter((property) => state.checkedProperties.includes(property.id)),
    );

    return filteredProperties.map((property) => {
      property.projects = property.projects.filter((project) =>
        project.title.toLowerCase().includes(state.projectsSearchQuery.toLowerCase()),
      );
      return property;
    });
  },
);

export const areAllAvailablePropertiesCheckboxesSelected = createSelector(
  teamManagementFeatureSelector,
  getTeamManagementFilteredProperties,
  (state, filteredProperties) => {
    // filter all properties that are checked based on the checked properties, see if they match
    const containsOnlyCheckedProperties =
      filteredProperties.filter((property) => state.checkedProperties.includes(property.id))
        .length === state.checkedProperties.length;
    // return if all properties are checked and if their ids match
    return (
      state.checkedProperties.length === filteredProperties.length && containsOnlyCheckedProperties
    );
  },
);

export const areAllProjectCheckboxesSelected = createSelector(
  teamManagementFeatureSelector,
  getFilteredSelectedProperties,
  (state, selectedProperties) => {
    // get available projects from the selected properties
    const totalAvailableProjects = selectedProperties.map((property) => property.projects).flat();
    // filter only projects that are checked, see if there are only checked projects
    const containsOnlyCheckedProjects =
      totalAvailableProjects.filter((project) => state.checkedProjects.includes(project.id))
        .length === state.checkedProjects.length;
    return (
      state.checkedProjects.length === totalAvailableProjects.length && containsOnlyCheckedProjects
    );
  },
);
export const getSelectedMember = createSelector(teamManagementFeatureSelector, (state) => {
  return state.selectedTeamMember;
});
