import { SPEND_TYPES } from '../../../../../framework/constants/budget.constants';
import { ILineItem } from '../../../../../store/spend/spend.interfaces';
import { defaultMonthlyData } from '../../../../../framework/constants/spend.constants';

interface MonthItem<T> {
  1: T;
  2: T;
  3: T;
  4: T;
  5: T;
  6: T;
  7: T;
  8: T;
  9: T;
  10: T;
  11: T;
  12: T;
}

interface YearItem {
  monthly_actuals: MonthItem<number>;
  monthly_budget: MonthItem<number>;
  monthly_forecast: MonthItem<number>;
  year: number;
}

interface BudgetItem {
  budget: Array<YearItem>;
  id: number;
  name: string;
}

type BudgetItems = Array<BudgetItem>;

export const ColMap = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
};

export const MonthIndexMap = {
  1: 'D',
  2: 'E',
  3: 'F',
  4: 'G',
  5: 'H',
  6: 'I',
  7: 'J',
  8: 'K',
  9: 'L',
  10: 'M',
  11: 'N',
  12: 'O',
};

export function getCellIndex(cell: string) {
  return Object.values(ColMap).findIndex((o) => o === cell) + 1;
}

export interface SpreadsheetModel {
  name: string;
  id: string;
  data: Array<{ cell: string; value: number }>;
}

export function mapItemBudgetKey(
  budgetType: SPEND_TYPES,
): 'monthly_actuals' | 'monthly_budget' | 'monthly_forecast' {
  switch (budgetType) {
    case SPEND_TYPES.BUDGET:
      return 'monthly_budget';
    case SPEND_TYPES.ACTUALS:
      return 'monthly_actuals';
    case SPEND_TYPES.FORECAST:
      return 'monthly_forecast';
  }
}

function extendMonths(val) {
  for (let i = 1; i < 13; i++) {
    if (!val[i]) {
      val[i] = 0;
    }
  }

  return val;
}

/**
 * This function will rebuild the object that needs to be sent to the spreadsheet component
 * It will add missing years to all line items with default 0 monthly data
 * @param items - line items
 * @param allYears - all years from budget
 */
export function getSpreadSheetData(items, allYears) {
  items.forEach((item) => {
    allYears.forEach((year) => {
      const budIndex = item.budget.findIndex((bud) => bud.year === year);
      if (budIndex === -1) {
        item.budget.push({
          year,
          monthly_budget: { ...defaultMonthlyData },
          monthly_forecast: { ...defaultMonthlyData },
          monthly_actuals: { ...defaultMonthlyData },
        });
      }
    });
  });
  return items;
}

export function toSpreadshet(
  lineItems: ILineItem[],
  budgetType: SPEND_TYPES,
  orderedMonths: Array<string>,
): Array<SpreadsheetModel> {
  const budgetKey = mapItemBudgetKey(budgetType);

  const formatted = lineItems.map((line, lineIndex) => {
    return line.budget.map((yearBudget) => {
      let yearTotal = 0;
      const mappedLineNr = lineIndex + 2;
      const months = {
        [yearBudget.year]: Object.entries(yearBudget[budgetKey]).map(([key, value]) => {
          yearTotal += value;

          return {
            cell: ColMap[Number(key) + 3] + mappedLineNr,
            value,
          };
        }),
      };

      months[yearBudget.year].push({
        cell: ColMap[3] + mappedLineNr,
        value: yearTotal,
      });
      months[yearBudget.year].push({
        cell: ColMap[1] + mappedLineNr,
        value: line.name,
      });

      return months;
    });
  });

  console.log(formatted);

  const result: Record<string, any> = {};

  formatted.forEach((line, lineIndex) => {
    line.forEach((yearArray) => {
      Object.keys(yearArray).forEach((year) => {
        if (result[year]) {
          result[year] = [...result[year], ...yearArray[year]];
        } else {
          result[year] = yearArray[year];

          // ADD first row aka. header
          result[year].push(
            ...Object.values(ColMap).map((col, idx) => {
              if (idx === 0) {
                return { cell: `${col}1`, value: 'LINE ITEM' };
              }

              // if (idx === 1) {
              //   return { cell: `${col}1`, value: 'YEAR TOTAL' };
              // }

              if (idx === 2) {
                return { cell: `${col}1`, value: 'YEAR TOTAL' };
              }

              return {
                cell: `${col}1`,
                value: orderedMonths[idx - 3].toUpperCase(),
              };
            }),
          );
        }
      });
    });
  });

  return Object.entries(result).map(([year, value]) => ({
    id: year,
    name: `${year}`,
    data: value,
  }));
}

export function toBudget(
  spreadsheetItems: Array<SpreadsheetModel>,
  oldItems: BudgetItems,
  budgetType: SPEND_TYPES,
  maxLine: number,
) {
  const budgetKey = mapItemBudgetKey(budgetType);

  const formated = {};
  spreadsheetItems.forEach((year) => {
    year.data.forEach((month) => {
      const line = Number(month.cell.replace(/[a-zA-Z]+/g, '')) - 2;
      const monthIndex = getCellIndex(month.cell[0]) - 3;

      // console.log(month.cell.replace(/[a-zA-Z]+/g, ''), line, monthIndex);

      if (monthIndex > 0) {
        if (formated[line]) {
          if (formated[line][year.id]) {
            formated[line][year.id][monthIndex] = month.value;
          } else {
            formated[line][year.id] = { [monthIndex]: month.value };
          }
        } else {
          formated[line] = { [year.id]: { [monthIndex]: month.value } };
        }
      } else if (monthIndex === -2) {
        if (!formated[line]) {
          formated[line] = { name: month.value, [year.id]: {} };
        } else {
          formated[line].name = month.value;
        }
      }
    });
  });

  console.log({ ...formated });

  delete formated['-1'];

  const updatedItems = oldItems.map((line, lineIndex) => {
    line.budget = line.budget.map((year) => {
      if (formated[lineIndex]) {
        if (formated[lineIndex][year.year]) {
          year[budgetKey] = { ...formated[lineIndex][year.year] };
        }

        delete formated[lineIndex][year.year];
      }

      return year;
    });

    if (formated[lineIndex]) {
      line.name = formated[lineIndex].name;
      delete formated[lineIndex].name;
    }

    return line;
  });

  Object.keys(formated).forEach((lineIndex) => {
    if (Number(lineIndex) < maxLine) {
      Object.entries(formated[lineIndex]).forEach(([year, value]) => {
        if (oldItems[lineIndex]) {
          oldItems[lineIndex].budget.push({
            [budgetKey]: extendMonths(value),
            year: Number(year),
          });

          delete formated[lineIndex][year];
        }
      });

      if (
        Object.keys(formated[lineIndex]).length === 0
        // formated[lineIndex].name === 'Total' ||
        // formated[lineIndex].name === 0
      ) {
        delete formated[lineIndex];
      }
    } else {
      // omit Total and undefined rows
      delete formated[lineIndex];
    }
  });

  const newItems = Object.keys(formated).map((lineNr, lineIndex) => {
    const line = {
      budget: [],
      name: '',
    };

    Object.keys(formated[lineNr]).map((year) => {
      if (year !== 'name') {
        line.budget.push({
          [budgetKey]: formated[lineNr][year] || {},
          year: Number(year),
        });
      }
    });

    console.log(formated, lineNr);

    line.name = formated[lineNr].name;

    return line;
  });

  return [updatedItems, newItems];
}
