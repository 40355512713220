import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { EmptySplashComponent } from '../empty-splash.component';

@Component({
  selector: 'app-general-empty-splash',
  standalone: true,
  imports: [NgIf, EmptySplashComponent],
  templateUrl: './general-empty-splash.component.html',
  styleUrl: './general-empty-splash.component.scss',
})
export class GeneralEmptySplashComponent {
  @Input() subTitle = '';
  @Input() title = '';
}
