import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  PAGE_TASKS_CALENDAR,
  PAGE_TASKS_KANBAN,
  PAGE_TASKS_TABLE,
} from '../../constants/view.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TASK_STATUS_VALUE, TASK_TYPE } from '../../../store/tasks/tasks.interfaces';
import { Store } from '@ngrx/store';
import { projectActions } from '../../../store/projects/projects.actions';
import { getNonDeletedProjects } from '../../../store/projects/projects.selectors';
import { taskFiltersChangedInComponent } from '../../../store/tasks/tasks.actions';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { NgClass, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tasks-header',
  templateUrl: './tasks-header.component.html',
  styleUrls: ['./tasks-header.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, MatFormField, MatSelect, MatOption, NgFor, AsyncPipe],
})
export class TasksHeaderComponent implements OnInit, OnDestroy {
  page: string;
  KANBAN = PAGE_TASKS_KANBAN;
  TABLE = PAGE_TASKS_TABLE;
  CALENDAR = PAGE_TASKS_CALENDAR;
  isDestroyed$ = new Subject();
  OPTION_ALL = -1;
  TASK_STATUS_VALUE = TASK_STATUS_VALUE;
  TASK_TYPE = TASK_TYPE;
  projects$ = this.store.select(getNonDeletedProjects);
  constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.page = data?.page;
    });
    this.store.dispatch(projectActions.loadProjectsTasksKanban());
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  statusSelected($event: MatSelectChange) {
    let value = $event.value;
    if ($event.value.includes(this.OPTION_ALL)) {
      value = null;
    }
    this.store.dispatch(taskFiltersChangedInComponent({ filters: { status_filter: value } }));
  }

  taskTypeSelected($event: MatSelectChange) {
    let value = $event.value;
    const selected: any[] = $event.value;
    if (
      selected.includes(this.OPTION_ALL) ||
      (selected.includes(TASK_TYPE.DAILY) && selected.includes(TASK_TYPE.CUSTOM))
    ) {
      value = null;
    }
    this.store.dispatch(taskFiltersChangedInComponent({ filters: { type_filter: value } }));
  }

  projectSelected($event: MatSelectChange) {
    let value = $event.value;
    if ($event.value.includes(this.OPTION_ALL)) {
      value = null;
    }
    this.store.dispatch(taskFiltersChangedInComponent({ filters: { project_filter: value } }));
  }
}
