<div class="complete-container">
  <div
    class="progress-bar"
    [matTooltip]="percent > 100 ? 'Over Committed' : percent < 0 && totalPercent < 0 ? 'Contract Credit' : '% Complete'"
    [matTooltipClass]="{ 'color-red-negative': percent > 100 || (percent < 0 && totalPercent < 0) }"
  >
    <div
      *ngIf="width !== 0"
      [ngClass]="{ overcommitment: percent > 100 }"
      [ngStyle]="{ width: width + '%' }"
      class="fill"
    ></div>
  </div>
  <div [ngClass]="{ negative: percent < 0, overcommitment: !Number.isFinite(percent) }" class="complete-percent-text">
    <ng-container *ngIf="percent < 0">(</ng-container>
    {{ finitePercent }}%
    <ng-container *ngIf="percent < 0">)</ng-container>
  </div>
</div>
