import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { JsonPipe, NgClass, NgForOf, NgStyle } from '@angular/common';
import { IStyledOption } from '../../constants/documents.constants';
import { OPTIONS } from '../../constants/options-list.constants';
import { ContainsValuePipe } from '../../../pipes/framework/contains-value.pipe';

@Component({
  selector: 'app-options-list-styled',
  templateUrl: './options-list-styled.component.html',
  styleUrls: ['./options-list-styled.component.scss'],
  standalone: true,
  imports: [NgStyle, NgForOf, JsonPipe, NgClass, ContainsValuePipe],
})
export class OptionsListStyledComponent {
  @Input() listItems: Array<IStyledOption> = [];
  @Input() disabledList: Array<OPTIONS> = [];
  @Output() selectedOption = new EventEmitter();

  @ViewChild('wrapper') wrapper: HTMLElement;

  constructor() {}

  optionSelected(value: OPTIONS) {
    this.selectedOption.emit(value);
  }

  isDisabled(item: IStyledOption) {
    return this.disabledList?.includes(item?.value);
  }
}
