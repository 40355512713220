import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CurrentUserService } from '../../../../services/current-user.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { SpreadsheetComponent, SpreadsheetModule } from '@syncfusion/ej2-angular-spreadsheet';
import { Subject } from 'rxjs';
import {
  SHEET_OPEN_URL,
  SHEET_SAVE_URL,
} from '../../../../framework/constants/syncfusion.constants';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
  standalone: true,
  imports: [SpreadsheetModule],
})
export class SheetComponent implements OnDestroy {
  @ViewChild('spreadsheet') public spreadsheetObj: SpreadsheetComponent;
  private _excelFile;
  fileObservable = new Subject<Blob>();
  // save button and File -> save both use same onComplete to emit file
  // without setting a variable it's impossible to realise which save is used
  downloadOnSave = true; // set from parent component to false if our save button is used
  @Input() set excelFile(value) {
    if (value) {
      this._excelFile = value;
    }
  }
  get excelFile() {
    return this._excelFile;
  }

  SHEET_OPEN_URL = SHEET_OPEN_URL;
  SHEET_SAVE_URL = SHEET_SAVE_URL;

  constructor(
    public user: CurrentUserService,
    protected notif: NotificationsService,
  ) {}

  sheetCreated() {
    if (this.excelFile) {
      const fileBlob = new Blob([this.excelFile]);
      const file = new File([fileBlob], 'Sample.xlsx'); // convert the blob into file
      this.spreadsheetObj.open({ file });
    }
  }

  /**
   * returns an observable of the Excel file blob
   */
  getFile() {
    // calling the save method will trigger the sheetBeforeSave() which triggers the sheetSaveComplete()
    this.spreadsheetObj.save({});
    return this.fileObservable.asObservable();
  }

  sheetBeforeSave(args) {
    args.needBlobData = true; // To trigger saveComplete event
    args.isFullPost = false; // To get blob data of Spreadsheet
  }

  sheetSaveComplete(args) {
    if (args.status === 'Failure') {
      this.notif.showError('Failed to save.');
      return;
    }

    this.fileObservable.next(args.blobData);
    if (args.blobData && this.downloadOnSave) {
      saveAs(args.blobData, `${args.fileName}.${args.saveType.toLowerCase()}`);
    }
  }

  ngOnDestroy() {
    this.fileObservable.complete();
  }
}
