import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  selectEntities,
  submitCondition,
  tasksFeatureKey,
  TasksState,
} from './tasks.reducer';
import { ITaskUsersListData } from '../messages/messages.interfaces';
import { ITaskMember, TASK_KANBAN_COLUMN_TYPES } from './tasks.interfaces';
import { columnTypeToVisualization, NO_PROJECT, tasksApiDateFormat } from './tasks.constants';
import moment, { Moment } from 'moment';

export const tasksFeatureSelector = createFeatureSelector<TasksState>(tasksFeatureKey);

export const getAllTasks = createSelector(tasksFeatureSelector, selectAll);
export const getAllTaskEntities = createSelector(tasksFeatureSelector, selectEntities);
export const getTasksFilteredByVisualization = createSelector(
  tasksFeatureSelector,
  (state) => state.tasksFiltered,
);
export const getTasksByColumnType = (columnType: TASK_KANBAN_COLUMN_TYPES) =>
  createSelector(getTasksFilteredByVisualization, (tasksFiltered) => {
    const visualization = columnTypeToVisualization[columnType];
    return tasksFiltered[visualization];
  });

export const getSelectedTaskId = createSelector(
  tasksFeatureSelector,
  (state) => state.selectedTask?.id,
);

export const getSelectedTask = createSelector(tasksFeatureSelector, (state) => state.selectedTask);
export const getTaskSidebarProjectId = createSelector(
  tasksFeatureSelector,
  (state) => state.sidebarTask.project_id,
);

export const getAssignedUsers = createSelector(
  tasksFeatureSelector,
  (state): ITaskMember[] => state.sidebarTask.assigned_members,
);
export const getSpectatorUsers = createSelector(tasksFeatureSelector, (state) =>
  state.sidebarTask.spectator_members.filter((spec) => !spec.deleted),
);

export const getUsers = createSelector(
  tasksFeatureSelector,
  getAssignedUsers,
  getSpectatorUsers,
  (state, assignedUsers, spectators) => {
    return state.users.filter(
      (user) =>
        !spectators.find((spec) => spec.user_id === user.user_id) &&
        !assignedUsers.find((assigned) => assigned.user_id === user.user_id),
    );
  },
);

export const getAllTaskSidebarFiles = createSelector(
  tasksFeatureSelector,
  (state) => state.sidebarTask.files,
);

export const getTaskSidebarFilesToDelete = createSelector(tasksFeatureSelector, (state) =>
  state.sidebarTask.deletedFiles.filter((file) => file?.id),
);

export const getTaskSidebarFilesToUpload = createSelector(tasksFeatureSelector, (state) =>
  state.sidebarTask.files.filter((file) => !file?.id),
);

export const getTaskAssignedUsersListData = createSelector(
  getUsers,
  getAssignedUsers,
  (users, assignedUsers): ITaskUsersListData => {
    return {
      usersRelated: users,
      selectedMembers: assignedUsers,
    };
  },
);

export const getTaskSpectatorUsersListData = createSelector(
  getUsers,
  getSpectatorUsers,
  (users, spectatorUsers): ITaskUsersListData => {
    return {
      usersRelated: users,
      selectedMembers: spectatorUsers,
    };
  },
);

export const getAllCheckList = createSelector(tasksFeatureSelector, (state) =>
  state.allChecklist.filter((list) => !list?.deleted),
);

export const getCurrentPage = createSelector(
  tasksFeatureSelector,
  (state) => state.sidebarTask.currentPage,
);

export const getIsSubmit = createSelector(tasksFeatureSelector, (state) => {
  return submitCondition(state.sidebarTask.currentPage, state.sidebarTask.type);
});

export const getUpdateTaskID = createSelector(tasksFeatureSelector, (state) => {
  return state.sidebarTask.updateTaskId;
});

export const getSidebarModel = createSelector(tasksFeatureSelector, (state) => {
  if (state.sidebarTask.project_id === null) {
    return {
      ...state.sidebarTask,
      project_id: NO_PROJECT,
    };
  }
  return state.sidebarTask;
});

export const getTaskFilters = createSelector(tasksFeatureSelector, (state) => state.filters);

export const getTasksMainLoading = createSelector(tasksFeatureSelector, (state) => state.isLoading);

export const getTasksLoadingAllViews = createSelector(
  tasksFeatureSelector,
  (state) => state.loading,
);

// first page loading is different because there might be no tasks at all in the store
// so nothing should be shown at that time
export const getPaginationFirstPageLoading = createSelector(
  getTasksLoadingAllViews,
  (loading) => loading.firstPage,
);

export const getPaginationLoading = createSelector(
  getTasksLoadingAllViews,
  (loading) => loading.pagination,
);

export const getSortingTableLoading = createSelector(
  getTasksLoadingAllViews,
  (loading) => loading.tableSorting,
);

export const isTableProgressBarLoading = createSelector(
  getPaginationLoading,
  getSortingTableLoading,
  (paginationLoading, tableSortLoading) => paginationLoading || tableSortLoading,
);

export const areAllTaskPagesLoaded = createSelector(
  tasksFeatureSelector,
  (state) => state.areAllPagesLoaded,
);

export const isTaskSidebarEdit = createSelector(
  tasksFeatureSelector,
  (state) => !!state.sidebarTask.id,
);

export const deletedChecklists = createSelector(tasksFeatureSelector, (state) =>
  state.allChecklist.filter((checklist) => checklist.deleted),
);

// used only locally here
const getTaskPaginationInfo = createSelector(
  tasksFeatureSelector,
  (state) => state.paginationInfoKanban,
);

/**
 * return the pagination info associated with the column type
 * each column has its own pagination and infinite scroll
 */
export const getTaskPaginationInfoByView = (columnType: TASK_KANBAN_COLUMN_TYPES) =>
  createSelector(getTaskPaginationInfo, (paginationFilteredTasks) => {
    const visualization = columnTypeToVisualization[columnType];
    return paginationFilteredTasks[visualization];
  });
export const isKanbanColumnPaginationLoading = (columnType: TASK_KANBAN_COLUMN_TYPES) =>
  createSelector(getTaskPaginationInfoByView(columnType), (columnInfo) => {
    return columnInfo.isLoading;
  });
export const isKanbanColumnFirstPageLoading = (columnType: TASK_KANBAN_COLUMN_TYPES) =>
  createSelector(
    getTaskPaginationInfoByView(columnType),
    (columnInfo) => columnInfo.isFirstPageLoading,
  );

export const getSelectedTaskView = createSelector(
  tasksFeatureSelector,
  (state) => state.selectedView,
);

export const getCalendarTasksByMonth = (year, month) =>
  createSelector(tasksFeatureSelector, (state) =>
    state.tasksCalendar.find((record) => record.year === year && record.month === month),
  );

// todo: extend this
export const getCalendarTasksByDay = (date: Moment) =>
  createSelector(getCalendarTasksByMonth(date.year(), date.month()), (allMonthlyData) => {
    if (allMonthlyData) {
      const tasks = allMonthlyData.tasks.filter(
        (task) => moment(task.end_date, tasksApiDateFormat).date() === date.date(),
      );
      return tasks.sort((a, b) => a.title.localeCompare(b.title));
    }
    return [];
  });

export const isCalendarLoading = createSelector(
  tasksFeatureSelector,
  (state) => state.calendar.isCalendarLoading,
);

export const getSelectedMonth = createSelector(tasksFeatureSelector, (state) => {
  return {
    month: state.calendar.selectedMonth,
    year: state.calendar.selectedYear,
  };
});
