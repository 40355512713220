<div class="cash-flow-page-wrapper">
  <app-general-header *ngIf="!isViewProject" title="Cashflow" [hideBorder]="true">
    <div class="header-inputs">
      <app-dropdown
        [multiple]="true"
        class="input dropdown-specific-empty-message cashflow-projects"
        name="projects"
        [(model)]="filters.project_ids"
        (modelChange)="onProjectChange($event)"
        [options]="projectsFiltered"
        optionLabel="title"
        optionValue="id"
        [selectedItemsLabel]="
          filters.project_ids.length === projectsFiltered.length
            ? 'All Projects'
            : filters.project_ids.length + ' Projects'
        "
      >
      </app-dropdown>

      <app-dropdown
        [multiple]="true"
        class="input dropdown-specific-empty-message cashflow-properties"
        name="properties"
        [(model)]="filters.property_ids"
        (modelChange)="onPropertyChange($event)"
        [options]="allProperties"
        optionLabel="name"
        optionValue="id"
        [selectedItemsLabel]="
          filters.property_ids.length === allProperties.length
            ? 'All Properties'
            : filters.property_ids.length + ' Properties'
        "
      ></app-dropdown>
      <app-search-input
        (searchText)="onSearchTextChange($event)"
        class="input w-[300px]"
        placeholder="Search Projects..."
      ></app-search-input>
    </div>
  </app-general-header>

  <app-page-loading *ngIf="(isLoading$ | async) && !hasLoadedOnce"></app-page-loading>
  <div
    class="cashflow-scrollbar-wrapper"
    [ngClass]="{
      'is-view-project': isViewProject,
      'visibility-hidden': (isLoading$ | async) && !hasLoadedOnce,
      'page-content': !isViewProject
    }"
  >
    <ng-scrollbar appearance="standard" track="horizontal" class="std-scrollbar">
      <div class="cashflow-content">
        <div class="graph">
          <app-cash-flow-graph></app-cash-flow-graph>
          <app-dropdown
            class="dropdown"
            name="fiscalYear"
            [model]="filters.year"
            (modelChange)="onFiscalYearChange($event)"
            [options]="fiscalYears$ | async"
            optionLabel="label"
            optionValue="value"
            [dropdownClass]="['options-centered']"
          >
          </app-dropdown>
        </div>
        <div class="cashflow-table">
          <app-cashflow-table [isViewProject]="isViewProject"></app-cashflow-table>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
