import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
  CommitmentItemBackendExtended,
  CostBackendExtended,
  CostBackendInvoiceExtended,
} from '../../../store/commitments/commitments.types';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../constants/interaction-bar.constants';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { commitmentsSelectors } from '../../../store/commitments/commitments.selectors';
import { takeUntil } from 'rxjs/operators';
import { commitmentsActions } from '../../../store/commitments/commitments.actions';
import { CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

import { MatButton } from '@angular/material/button';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';

@Component({
  selector: 'app-cost-view-table-dropdown',
  templateUrl: './cost-view-table-dropdown.component.html',
  styleUrls: ['./cost-view-table-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbar, MatButton, FadedTextComponent, MoneyPipe],
})
export class CostViewTableDropdownComponent implements AfterViewInit, OnDestroy {
  costs: CostBackendInvoiceExtended[] = [];
  commitmentItems: CommitmentItemBackendExtended[] = [];
  COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;

  @Input() commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE;
  @Input() costIndex: number;
  @Input() spendEntryIndex: number;
  @Input() disableDelete = false;
  @Input() disableAdd = false;

  @Output() switchToCustomInput = new EventEmitter();
  @Output() closeMe = new EventEmitter();
  @Output() deleteMe = new EventEmitter();

  isDestroyed$ = new Subject();

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    if (this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES) {
      this.store
        .select(commitmentsSelectors.getSelectableInvoiceCosts)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((results) => {
          this.costs = results;
        });
    }

    this.store
      .select(commitmentsSelectors.getSelectableLineItemsForChangeOrder(this.spendEntryIndex))
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((results) => {
        this.commitmentItems = results;
      });
  }

  deleteCostDescription() {
    this.deleteMe.emit(true);
    this.closeMe.emit(true);
  }

  switchToCustomDescription() {
    // reset data
    this.selectCost({ id: null, name: '' }, false);
    this.switchToCustomInput.emit(true);
  }

  selectInvoiceCost(cost: CostBackendInvoiceExtended) {
    if (this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES) {
      this.store.dispatch(
        commitmentsActions.selectInvoiceCost({
          costIndex: this.costIndex,
          cost: { ...cost },
        }),
      );

      this.closeMe.emit(true);
    }
  }

  selectCost(cost: Partial<CostBackendExtended>, is_dropdown = true) {
    if (!cost) {
      console.warn('No cost selected');
      return;
    }
    this.store.dispatch(
      commitmentsActions.selectChangeOrderSpendEntryCost({
        spendEntryIndex: this.spendEntryIndex,
        costIndex: this.costIndex,
        cost: { ...cost, is_dropdown, value: null },
      }),
    );

    this.closeMe.emit(true);
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
