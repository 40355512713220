import { ProjectsInterface } from './projects.interface';

export class ProjectsManager implements ProjectsInterface {
  tabOneLabel = 'Budget';
  tabTwoLabel = 'Bidding';
  tabThreeLabel = 'Planned';
  moneyIndicatorTwoTitle = 'Budget ($)';
  moneyIndicatorOneTitle = 'Contractors Invited';
  tableFields = [
    'title',
    'graph',
    'planned',
    'budget',
    'forecast',
    'fcvsbud',
    'spend',
    'spvsfc',
    'capex',
    'opex',
    'priority',
    'approved',
    'status',
  ];
}
