import { Pipe, PipeTransform } from '@angular/core';

export enum SHOW_DECIMALS {
  ALWAYS,
  ABOVE_MILLION,
  NEVER,
}

export type MoneyFormatType =
  | 'coma'
  | 'comaSign'
  | 'positiveComa'
  | 'short'
  | 'shortSimple'
  | 'shortSimpleSpace'
  | 'shortSign'
  | 'shortSignEmpty'
  | 'shortSignSimple'
  | 'shortSignSimpleSpace'
  | 'comaNoZero'
  | 'shortWithParentheses';

@Pipe({
  name: 'money',
  standalone: true,
})
/**
 * Converts number to formated string money
 */
export class MoneyPipe implements PipeTransform {
  unitsProjects = ['', 'K', 'Mil', 'Bil'];
  unitsShort = ['', 'K', 'M', 'B'];

  transform(value: any, type: MoneyFormatType = 'coma', ...args: any[]): any {
    if (
      ((value === '-' || !value) && type !== 'shortSignEmpty' && type !== 'shortSignSimpleSpace') ||
      value === null
    ) {
      return '-';
    }

    if (type === 'coma') {
      return this.transformToComa(value);
    } else if (type === 'comaSign') {
      return '$' + this.transformToComa(value);
    } else if (type === 'positiveComa') {
      return this.transformToPositiveComa(value);
    } else if (type === 'short') {
      return this.transformToShort(value);
    } else if (type === 'shortSimple') {
      return this.transformToShortSimplified(value);
    } else if (type === 'shortSimpleSpace') {
      if (value < 0) {
        value = -1 * value;
      }
      const res = this.transformToShortSimplified(value);
      if (res === '0') {
        return '-';
      } else {
        return '$ ' + res;
      }
    } else if (type === 'shortSign') {
      const res = this.transformToShort(value);
      if (res !== '-') {
        return '$' + res;
      } else {
        return res;
      }
    } else if (type === 'shortSignEmpty') {
      const res = this.transformToShort(value, ' ');
      if (res !== '') {
        return '$' + res;
      } else {
        return res;
      }
    } else if (type === 'shortSignSimple') {
      const res = this.transformToShortSimplified(value);
      if (res !== '-') {
        return '$' + res;
      } else {
        return res;
      }
    } else if (type === 'shortSignSimpleSpace') {
      if (value < 0) {
        value = -1 * value;
      }
      const res = this.transformToShortSimplified(value);
      if (res === '0') {
        return '$ -';
      } else {
        return '$ ' + res;
      }
    } else if (type === 'comaNoZero') {
      const num = this.transformToComa(value);
      if (Number.parseInt(num, 10) === 0) {
        return '-';
      } else {
        return num;
      }
    } else if (type === 'shortWithParentheses') {
      return this.transformToShortWithParentheses(value);
    }
  }

  transformToComa(value: any) {
    value = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const decimals = value.split('.')?.[1];
    if (decimals === '00') {
      value = value.split('.')[0];
    }

    return value;
  }

  transformToShort(value: any, emptyValue = '-') {
    let nr = Number.parseFloat(value);
    let currentUnit = 0;
    let prefix = '';

    if (nr < 0) {
      nr = nr * -1;
      prefix = emptyValue;
    }

    while (Math.trunc(nr / 1000) > 0) {
      nr = nr / 1000;
      currentUnit += 1;
    }

    nr = nr * 10;
    nr = Math.round(nr) / 10;

    return prefix + nr + ' ' + this.unitsProjects[currentUnit];
  }

  transformToShortSimplified(value: any, showDecimal: SHOW_DECIMALS = SHOW_DECIMALS.ALWAYS) {
    let nr = Number.parseFloat(value);
    let currentUnit = 0;
    let prefix = '';

    if (nr < 0) {
      nr = nr * -1;
      prefix = '-';
    }

    while (Math.trunc(nr / 1000) > 0) {
      nr = nr / 1000;
      currentUnit += 1;
    }

    if (
      showDecimal === SHOW_DECIMALS.ALWAYS ||
      (showDecimal === SHOW_DECIMALS.ABOVE_MILLION && currentUnit >= 2)
    ) {
      nr = nr * 10;
      nr = Math.round(nr) / 10;
    } else {
      nr = Math.round(nr);
    }

    return prefix + nr + this.unitsShort[currentUnit];
  }

  transformToShortWithParentheses(value) {
    const shortForm = this.transformToShortSimplified(value);
    if (shortForm.startsWith('-')) {
      return '($' + shortForm.slice(1) + ')';
    }
    return '$' + shortForm;
  }

  transformToPositiveComa(value: any) {
    value = Number(value);
    value = value >= 0 ? value : value * -1;
    return '$ ' + this.transformToComa(value);
  }
}
