import { createAction, props } from '@ngrx/store';
import {
  DISCUSSION_FILTER_TYPE,
  IMessagingHeaderFilters,
} from '../../framework/constants/messages.constants';
import {
  IGroupMessagesAndDrafts,
  IMessage,
  IMessageThread,
  IMessagingGroupFilters,
  IMessagingUser,
  MESSAGING_VIEWS,
  IMessagingGroup,
  IMessagingMember,
  IMessageFilters,
} from './messages.interfaces';
import { IFilesToUpload } from '../../framework/messages/discussions-view/discussions-view.component';

export const loadMessagingGroupsNextPage = createAction(
  '[Messages] load message groups next page',
  props<{ filters?: IMessagingGroupFilters }>(),
);
export const setMessageFilters = createAction(
  '[Messages] set message Filters',
  props<{ filters: IMessageFilters }>(),
);
export const setGroupFilters = createAction(
  '[Messages] set group Filters',
  props<{ filters: IMessagingGroupFilters }>(),
);

export const setDiscussionMembers = createAction(
  '[Messages] set discussion members',
  props<{ members: IMessagingMember[] }>(),
);

export const addDiscussionMembers = createAction(
  '[Messages] add discussion members',
  props<{ member: IMessagingMember }>(),
);

export const addDiscussionMembersToBackend = createAction(
  '[Messages] add discussion members to backend',
  props<{ member: IMessagingMember; group: IMessagingGroup }>(),
);

export const removeDiscussionMembers = createAction(
  '[Messages] remove selected discussion members',
  props<{ member: IMessagingMember }>(),
);

export const removeNewGroupDiscussionMember = createAction(
  '[Messages] remove new group selected discussion members',
  props<{ member: IMessagingMember }>(),
);

export const deleteGroupMemberFromBackend = createAction(
  '[Messages] delete group member from backend',
  props<{ member: IMessagingMember; group: IMessagingGroup }>(),
);

export const loadSearchedMessagingUsers = createAction(
  '[Messages] load searched messaging users',
  props<{ search: string }>(),
);
export const setGroups = createAction(
  '[Messages] set groups',
  props<{ groups: IMessagingGroup[] }>(),
);
export const paginatedGroupsLoaded = createAction(
  '[Messages] paginated groups loaded',
  props<{ groups: IMessagingGroup[]; filters: IMessagingGroupFilters }>(),
);
export const setSelectedGroupId = createAction(
  '[Messages] set selected Group',
  props<{ groupId: number }>(),
);
export const setSearchedMessagingMembers = createAction(
  '[Messages] set searched messaging users',
  props<{ members: IMessagingMember[] }>(),
);

export const setMessagingView = createAction(
  '[Messages] set messaging view',
  props<{ view: MESSAGING_VIEWS }>(),
);

export const createGroup = createAction('[Messages] create group');
export const updateNewGroupData = createAction(
  '[Messages] update new group data',
  props<{ member?: IMessagingMember; message?: string; name?: string }>(),
);

export const cancel = createAction('[Messages] cancel');
export const discardMessagingGroupCache = createAction('[Messages] Clear Up Data');

// will update only locally
export const updateMessagingGroup = createAction(
  '[Messages] update messaging group',
  props<{ group: Partial<IMessagingGroup> }>(),
);

export const updateGroupStatus = createAction(
  '[Messages] update group status',
  props<{ group: IMessagingGroup }>(),
);

export const updateMessagingGroupReadStatus = createAction(
  '[Messages] update messaging group read status',
  props<{ group: IMessagingGroup; isRead: boolean }>(),
);

export const updateMessageReadStatus = createAction(
  '[Messages] update one message`s read status',
  props<{ messageId: number; isRead: boolean }>(),
);

export const filterMessages = createAction(
  '[Messages] filter messages',
  props<{ filters: IMessagingHeaderFilters }>(),
);

export const createOrUpdateMessage = createAction(
  '[Messages] Create or update message',
  props<{ message?: string }>(),
);
export const createOrUpdateDraft = createAction(
  '[Messages] Create or update draft',
  props<{ message?: string }>(),
);

export const createThreadMessage = createAction('[Messages] Create new thread message');
export const loadMessages = createAction('[Messages] load all message');
export const loadMessagesPaginated = createAction(
  '[Messages] load all message paginated',
  props<{ lastMessageId?: number; perPage?: number }>(),
);
export const setMessage = createAction(
  '[Messages] set current message',
  props<{ message: string }>(),
);

export const setThread = createAction('[Messages] set thread', props<{ thread: IMessageThread }>());

export const addGroupMessage = createAction(
  '[Messages] add one message',
  props<{ message: Partial<IMessage>; isDraft?: boolean }>(),
);
export const gotMessageOnSocketUpdateIt = createAction(
  '[Messages] update one message got on socket',
  props<{ message: Partial<IMessage>; isDraft?: boolean }>(),
);

export const addFiles = createAction(
  '[Messages] add one file to upload',
  props<{ files: IFilesToUpload[] }>(),
);

export const removeFile = createAction(
  '[Messages] remove one file to upload',
  props<{ file: IFilesToUpload; index: number }>(),
);

export const cleanFiles = createAction('[Messages] clean all file to upload');

export const setMessages = createAction(
  '[Messages] set all message',
  props<{ groupMessagesAndDrafts: IGroupMessagesAndDrafts }>(),
);
export const cancelMessages = createAction('[Messages] cancel messages');
export const reloadDrafts = createAction('[Messages] reload all draft messages');
export const setDrafts = createAction(
  '[Messages] set all draft messages',
  props<{ drafts: IMessage[] }>(),
);

export const updateDraft = createAction('[Messages] update draft', props<{ draft: IMessage }>());

export const loadGroupFromMention = createAction(
  '[Messages] load group from mention',
  props<{ groupId: number }>(),
);

export const singleGroupLoaded = createAction(
  '[Messages] single group loaded (opened from tasks or notifications)',
  props<{ groups: IMessagingGroup[] }>(),
);

export const addImageToCache = createAction(
  '[Messages] add image to cache',
  props<{ imageSrc: string; user_id: number }>(),
);

export const loadSingleProfilePicture = createAction(
  '[Messages] single profile pictures and add to cache',
  props<{ user: IMessagingUser }>(),
);

export const setIsLoading = createAction(
  '[Messages] set is loading',
  props<{ isLoading?: boolean; isPaginationLoading?: boolean }>(),
);

export const setIsSearchedUsersLoading = createAction(
  '[Messages] set is searched members loading',
  props<{ isSearchedUsersLoading?: boolean }>(),
);

export const setLastNonDraftMessage = createAction(
  '[Messages] set last non draft message',
  props<{ lastNonDraftMessageId?: number }>(),
);

export const appendToMessages = createAction(
  '[Messages] append to messages',
  props<{ messages: IMessage[] }>(),
);

export const loadFirstPageOfGroups = createAction(
  '[Messages] load first page of groups',
  props<{ clearOldGroups?: boolean }>(),
);

const loadGroupFromTasks = createAction(
  '[Messages] load one group from tasks',
  props<{ groupId: number }>(),
);

const createGroupTasks = createAction('[Messages] create messaging group for tasks');

const newMessageGotOnSocket = createAction(
  '[Messages] got a new message on socket',
  props<{ message: IMessage }>(),
);
const setOverallMessagingUnreadStatus = createAction(
  '[Messages] set messaging unread status',
  props<{ isUnread: boolean }>(),
);

const messageStatusUpdatedInEffect = createAction(
  '[Messages][effects] message status updated in effect',
  props<{ messageId: number; isRead: boolean }>(),
);

const openThreadByMessageId = createAction(
  '[Messages][notification] open message thread by main message id',
  props<{ messageId: number }>(),
);
// const openThreadByMessageId = createAction('[Messages][notification] open message thread by main message id', props<{messageId: number}>());

export const messagesActions = {
  loadMessagingGroupsNextPage,
  loadSearchedMessagingUsers,
  setGroupFilters,
  setMessageFilters,
  setGroups,
  paginatedGroupsLoaded,
  setSearchedMessagingMembers,
  setMessagingView,
  setDiscussionMembers,
  addDiscussionMembers,
  removeDiscussionMembers,
  removeNewGroupDiscussionMember,
  createGroup,
  cancel,
  updateMessagingGroup,
  discardMessagingGroupCache,
  setSelectedGroupId,
  updateGroupStatus,
  updateMessagingGroupReadStatus,
  updateMessageReadStatus,
  deleteGroupMemberFromBackend,
  addDiscussionMembersToBackend,
  filterMessages,
  updateNewGroupData,
  // from messages.actions:
  createOrUpdateMessage,
  createOrUpdateDraft,
  updateDraft,
  createThreadMessage,
  loadMessages,
  loadMessagesPaginated,
  appendToMessages,
  loadSingleProfilePicture,
  setMessage,
  cancelMessages,
  setMessages,
  addGroupMessage,
  gotMessageOnSocketUpdateIt,
  setThread,
  reloadDrafts,
  setDrafts,
  addImageToCache,
  setIsLoading,
  setIsSearchedUsersLoading,
  addFiles,
  removeFile,
  cleanFiles,
  setLastNonDraftMessage,
  loadFirstPageOfGroups,
  loadGroupFromMention,
  singleGroupLoaded,
  loadGroupFromTasks,
  createGroupTasks,
  newMessageGotOnSocket,
  setOverallMessagingUnreadStatus,
  messageStatusUpdatedInEffect,
  openThreadByMessageId,
};
