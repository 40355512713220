import { Component, OnInit } from '@angular/core';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../../../../../framework/constants/interaction-bar.constants';
import { ProjectStateService } from '../../../../../services/project-state.service';
import { ProjectApiService } from '../../../../../services/project-api.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { NO_TIMEZONE, DateCustomPipe } from '../../../../../pipes/framework/date-custom.pipe';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

/**
 * @deprecated - schedule not used anymore.
 */
@Component({
  selector: 'app-schedule-contractor',
  templateUrl: './schedule-contractor.component.html',
  styleUrls: ['./schedule-contractor.component.scss'],
  standalone: true,
  imports: [NgIf, MatButton, NgFor, DateCustomPipe],
})
export class ScheduleContractorComponent implements OnInit {
  visits = [];
  bidsCount = 0;
  loadingVisits = false;
  NO_TIMEZONE = NO_TIMEZONE;

  constructor(
    private barState: InteractionBarStateService,
    private projectState: ProjectStateService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
  ) {}

  ngOnInit() {
    this.barState.announceState.subscribe((state) => {
      if (state.action === INTERACTION_BAR_STATES.SUCCESS_CLOSED) {
        this.refresh();
      }
    });
    this.refresh();
  }

  scheduleVisit() {
    this.barState.openSchedule();
  }

  refresh() {
    this.visits = [];
    this.loadingVisits = true;
    this.projectState.getScheduleVisits().then(
      (schedule) => {
        if (schedule.length !== 0) {
          this.projectState.getVisit().then(
            (visit) => {
              this.createScheduleVisitObj(visit, schedule);
              this.loadingVisits = false;
            },
            (err) => {
              this.loadingVisits = false;
            },
          );
        } else {
          this.loadingVisits = false;
        }
      },
      (err) => {
        this.loadingVisits = false;
      },
    );

    console.log(this.projectState.id);

    // this.projectState.getProjectBids().then(
    //   (data) => {
    //     this.bidsCount = data.length;
    //   },
    //   (err) => {},
    // );
  }

  createScheduleVisitObj(visits, schedules) {
    const newVisits = [];

    for (const schedule of schedules) {
      const assignedVisit = visits.filter((visit) => visit.id === schedule.visit_id);

      if (assignedVisit.length > 0) {
        assignedVisit[0].status = 'visit';
        assignedVisit[0].visitId = schedule.id;
        assignedVisit[0].contractorId = schedule.contractor_id;
        newVisits.push(assignedVisit[0]);
        console.log(schedule.id);
      }
    }

    this.visits = newVisits;
  }

  removeScheduleVisit(visit: any) {
    this.notif.showPopup('Cancel visit?').then((resp) => {
      if (resp === true) {
        this.notif.showLoading();
        this.projectApi.deleteScheduleVisit(visit.visitId).then(
          (data) => {
            this.refresh();
            this.notif.close();
          },
          (err) => {
            this.notif.showError(err);
          },
        );
      }
    });
  }

  reScheduleVisit(visit) {
    this.barState.openReSchedule(visit);
  }
}
