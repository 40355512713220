import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pricing-enterprise-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pricing-enterprise-card.component.html',
  styleUrls: ['./pricing-enterprise-card.component.scss', '../pricing-cards.scss'],
})
export class PricingEnterpriseCardComponent {
  @Output() onButtonClick = new EventEmitter<void>();
  @Input() isLoading = false;
}
