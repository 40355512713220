import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tasks-message-count',
  templateUrl: './tasks-message-count.component.html',
  styleUrls: ['./tasks-message-count.component.scss'],
  standalone: true,
})
export class TasksMessageCountComponent implements OnInit {
  textToDisplay: string;
  @Input() set number(data: number) {
    if (data === undefined || data === null) {
      this.textToDisplay = '0';
      return;
    }
    if (data > 99) {
      this.textToDisplay = '99+';
      return;
    }
    this.textToDisplay = data.toString(10);
  }
  constructor() {}

  ngOnInit(): void {}
}
