import { Injectable } from '@angular/core';
import {
  INotification,
  IUnseenStatus,
  NOTIFICATION_CATEGORIES,
} from '../framework/constants/notification.constants';
import { ErrorHandlerRestSimple } from '../restApi/errorHandler-rest';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_NOTIFICATIONS,
  REST_NOTIFICATIONS_SEEN,
  REST_NOTIFICATIONS_UNSEEN,
  REST_UNSEEN_STATS,
} from '../restApi/RestRoutes';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends ErrorHandlerRestSimple {
  constructor(private rest: RestRequestService) {
    super();
  }

  getPaginated(page: number, perPage = 20, category?: NOTIFICATION_CATEGORIES) {
    const params: any = { page, perPage };
    if (category) {
      params.category = category;
    }
    return this.rest.get(REST_NOTIFICATIONS, {}, params);
  }

  getUnseen(page: number, perPage = 20) {
    return new Promise<INotification[]>((res, rej) => {
      this.rest.get(REST_NOTIFICATIONS_UNSEEN, {}, { page, perPage }).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getSeen(page: number, perPage = 20) {
    return new Promise<INotification[]>((res, rej) => {
      this.rest.get(REST_NOTIFICATIONS_SEEN, {}, { page, perPage }).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateStatus(id: any) {
    const body = { is_seen: 1 };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_NOTIFICATIONS + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  markAllAsRead() {
    return this.rest
      .post(REST_NOTIFICATIONS + '/mark-all-seen', {})
      .catch((err) => this.handleError(err));
  }

  getUnseenStatus(): Promise<IUnseenStatus> {
    return this.rest.get(REST_UNSEEN_STATS).catch(this.handleError);
  }
}
