import { Component, EventEmitter, Input, Output } from '@angular/core';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import { fillIn } from '../../../../../assets/styles/animations';

import { CommitmentContractor } from '../../../../store/commitments/commitments.types';
import { TemporaryContractorIconComponent } from '../../../temporary-contractor-icon/temporary-contractor-icon.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

export enum COMPANY_DROPDOWN_SELECT_TYPES {
  CHANGE_DROPDOWN,
  SELECT,
}

export interface ICompanyDropdownSelectEvent {
  type: COMPANY_DROPDOWN_SELECT_TYPES;
  data?: CommitmentContractor;
  isTemporaryContractor?: boolean;
}
@Component({
  selector: 'app-company-dropdown',
  templateUrl: './company-dropdown.component.html',
  styleUrls: ['./company-dropdown.component.scss'],
  animations: [fillIn],
  standalone: true,
  imports: [TemporaryContractorIconComponent, AsyncPipe, NgScrollbar, CommonModule],
})
export class CompanyDropdownComponent {
  @Output() optionSelected = new EventEmitter<ICompanyDropdownSelectEvent>();
  @Input() hideAddCompany = false;

  allContractors$ = this.store.select(commitmentsSelectors.allContractors);
  constructor(private store: Store<AppState>) {}

  selectOption(contractor: CommitmentContractor, isTemporaryContractor: boolean) {
    this.optionSelected.emit({
      type: COMPANY_DROPDOWN_SELECT_TYPES.SELECT,
      data: contractor,
      isTemporaryContractor,
    });
  }

  switchDropdown() {
    this.optionSelected.emit({ type: COMPANY_DROPDOWN_SELECT_TYPES.CHANGE_DROPDOWN });
  }
}
