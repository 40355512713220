import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../../projects.interface';
import { AddProjectService } from '../../../../../services/add-project.service';
import { RichTextEditorComponent } from '../../../../../framework/inputs/rich-text-editor/rich-text-editor.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
  providers: [AddProjectService],
  standalone: true,
  imports: [NgScrollbar, RichTextEditorComponent],
})
export class ProjectSummaryComponent implements OnInit {
  @Input() project: Project;

  settings = {
    enableToolbar: false,
    isReadOnly: true,
    height: '100%',
  };

  constructor() {}

  ngOnInit(): void {}
}
