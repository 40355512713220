import { createAction, props } from '@ngrx/store';
import { IProperty, IPropertyBase, IPropertyType } from './properties.interfaces';

const loadProperties = createAction(
  '[Properties-listing] load all properties',
  props<{ search?: string }>(),
);

const loadPropertiesByType = createAction(
  '[Properties-listing] load properties by type',
  props<{ view?: IPropertyType }>(),
);

const reloadPropertiesByType = createAction(
  '[Properties-listing] reload properties by type',
  props<{ view?: IPropertyType }>(),
);

const setSelectedView = createAction(
  '[Properties-listing] property view changed',
  props<{ view: IPropertyType }>(),
);

const setPropertiesSearch = createAction(
  '[Properties-listing] set properties search',
  props<{ search: string }>(),
);

const reloadProperties = createAction(
  '[Properties-listing] reload all properties',
  props<{ search?: string }>(),
);

const successfullyLoadedProperties = createAction(
  '[Properties-listing] properties successfully loaded',
  props<{ properties: IProperty[] }>(),
);

const successfullyLoadedPropertiesByType = createAction(
  '[Properties-listing] properties by type successfully loaded',
  props<{ propertyType: IPropertyType; properties: IPropertyBase[] }>(),
);

const cancelLoad = createAction('[Properties-listing] properties load cancelled');
const clearProperties = createAction('[Properties-listing] clear properties');
const showFetching = createAction('[Properties-listing] fetching');
const deleteProperty = createAction(
  '[Properties-listing] delete property',
  props<{ id: number }>(),
);

export const propertiesActions = {
  loadProperties,
  reloadProperties,
  showFetching,
  successfullyLoadedProperties,
  cancelLoad,
  clearProperties,
  loadPropertiesByType,
  reloadPropertiesByType,
  successfullyLoadedPropertiesByType,
  setPropertiesSearch,
  setSelectedView,
  deleteProperty,
};
