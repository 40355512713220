import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommitmentsService } from '../../../services/commitments.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { Store } from '@ngrx/store';
import { commitmentsSelectors } from '../../../store/commitments/commitments.selectors';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { NotificationsService } from '../../../services/notifications.service';
import { ActivatedRoute } from '@angular/router';

import {
  AllCommitmentsSummaryContracts,
  AllCommitmentsSummaryDirectCosts,
  AllCommitmentsSummaryTotals,
  CommittedContractor,
  SORT_OPTIONS,
} from '../../../store/commitments/commitments.types';
import { fadeInGeneral } from '../../../../assets/styles/animations';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { SortArrowComponent } from '../../sort-arrow/sort-arrow.component';
import { TooltipModule } from 'primeng/tooltip';

import { ProgressBarProjectableComponent } from '../../progress-bar-projectable/progress-bar-projectable.component';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { TemporaryContractorIconComponent } from '../../temporary-contractor-icon/temporary-contractor-icon.component';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';
import { CaseTransformPipe } from '../../../pipes/framework/case-transform.pipe';

@Component({
  selector: 'app-commitments-summary',
  templateUrl: './commitments-summary.component.html',
  styleUrls: ['./commitments-summary.component.scss'],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [
    NgScrollbar,
    CommonModule,
    SortArrowComponent,
    TooltipModule,
    ProgressBarProjectableComponent,
    FadedTextComponent,
    TemporaryContractorIconComponent,
    MoneyPipe,
    CaseTransformPipe,
  ],
})
export class CommitmentsSummaryComponent implements OnInit, OnDestroy {
  @Output() selectedContract = new EventEmitter();
  @Output() goToDirectCosts = new EventEmitter();

  contractors: CommittedContractor[] = [];
  contracts: AllCommitmentsSummaryContracts[] = [];
  totals: AllCommitmentsSummaryTotals = {
    complete: 0,
    total_paid: 0,
    total_committed: 0,
    total_invoiced: 0,
    change_order_value: 0,
    contract_value: 0,
  };
  directCostTotals: AllCommitmentsSummaryDirectCosts = {
    total_committed: 0,
    total_paid: 0,
    total_invoiced: 0,
    complete: 0,
  };

  SORT = SORT_OPTIONS;
  sortedBy: SORT_OPTIONS;
  lastSortOrder = -1;
  isDestroyed$ = new Subject();

  constructor(
    private commitmentsService: CommitmentsService,
    public user: CurrentUserService,
    private store: Store,
    private notif: NotificationsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.notif.showLoading();
    this.store
      .select(commitmentsSelectors.getCommitmentsSummary)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((summary) => !!summary),
      )
      .subscribe((summary) => {
        this.notif.close();
        this.totals = summary.total;
        this.directCostTotals = summary.direct_costs;
        this.contracts = cloneDeep(summary.contracts);
      });
  }

  // needs extending for other types than string, number
  sortFunc = (property: string): ((a, b) => number) => {
    if (!property) {
      return (a, b) => 0;
    }
    this.lastSortOrder *= -1;
    return (a, b) => {
      if (typeof a[property] === 'string') {
        return this.lastSortOrder * a[property].localeCompare(b[property]);
      } else if (typeof a[property] === 'number') {
        return this.lastSortOrder * (a[property] - b[property]);
      }
      return 0;
    };
  };

  sortBy(field: SORT_OPTIONS) {
    this.sortedBy = field;
    this.contracts.sort(this.sortFunc(field));
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    // todo: maybe empty store here for another project
  }
}
