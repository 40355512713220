import { Component, OnInit } from '@angular/core';
import { TasksInteractionBarCommonComponent } from '../tasks-interaction-bar-common/tasks-interaction-bar-common.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app-state';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { DateCustomPipe } from '../../../../pipes/framework/date-custom.pipe';
import { ISimpleProject } from '../../../../pages/webapp/projects/projects.interface';
import moment from 'moment';
import {
  areSimpleProjectsLoading,
  getSimpleProjects,
} from '../../../../store/projects/projects.selectors';
import { Observable } from 'rxjs';
import { projectActions } from '../../../../store/projects/projects.actions';
import { CurrentUserService } from '../../../../services/current-user.service';
import { takeUntil } from 'rxjs/operators';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { NO_PROJECT } from '../../../../store/tasks/tasks.constants';
import { TitleCasePipe } from '@angular/common';
import { FloatingTextareaComponent } from '../../../inputs/floating-textarea/floating-textarea.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-tasks-interaction-bar-first-page',
  templateUrl: './tasks-interaction-bar-first-page.component.html',
  styleUrls: ['./tasks-interaction-bar-first-page.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    DropdownComponent,
    FloatingInputComponent,
    InputCalendarComponent,
    FloatingTextareaComponent,
    TitleCasePipe,
  ],
})
export class TasksInteractionBarFirstPageComponent
  extends TasksInteractionBarCommonComponent
  implements OnInit
{
  noneProject: ISimpleProject = {
    id: NO_PROJECT,
    title: 'None',
  };
  possibleProjects$: Observable<ISimpleProject[]> = this.store.select(getSimpleProjects);
  possibleProjects: ISimpleProject[] = [this.noneProject];
  areSimpleProjectsLoading$: Observable<boolean> = this.store.select(areSimpleProjectsLoading);

  constructor(
    protected store: Store<AppState>,
    protected interactionBarStateService: InteractionBarStateService,
    protected timeZonePipe: DateCustomPipe,
    protected currentUser: CurrentUserService,
  ) {
    super(store, interactionBarStateService, timeZonePipe);
  }

  ngOnInit(): void {
    this.handleSimpleProjectLoad();
    this.possibleProjects$.pipe(takeUntil(this.isDestroyed$)).subscribe((projects) => {
      this.possibleProjects = DeepCopyService.deepCopy(projects);
      this.possibleProjects.unshift(this.noneProject);
      if (this.model.project) {
        const projectExists = this.possibleProjects.find((p) => p.id === this.model.project.id);
        if (!projectExists) {
          this.possibleProjects.push(this.model.project);
        }
      }
    });
  }

  configureDates() {
    const startDate = moment(this.model.start_date);
    const endDate = moment(this.model.end_date);
    if (startDate.isAfter(endDate)) {
      this.resetDueDate();
    }
  }

  resetDueDate() {
    this.model.end_date = null;
    this.updateSidebarTask();
  }

  private handleSimpleProjectLoad() {
    this.store.dispatch(projectActions.loadProjectsSimpleTaskSidebar({}));
  }
}
