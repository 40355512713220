<div class="schedule-contractor-container">
  <div *ngIf="visits.length == 0 && !loadingVisits" class="no-visits">
    <div class="title default-cursor">There are no scheduled visits for this project</div>
    <button (click)="scheduleVisit()" class="btn-schedule" mat-button>Schedule Visit</button>
  </div>

  <div *ngIf="visits.length == 0 && loadingVisits" class="no-visits">
    <div class="title">
      <span class="icon-refresh animate-spin"></span>
    </div>
  </div>

  <div *ngFor="let visit of visits" class="scheduled">
    <div class="schedule-item">
      <div class="row">
        <span class="title">Visit Scheduled</span>
        <span class="date">{{ visit.day | datepipe: NO_TIMEZONE }}</span>
      </div>
      <div class="row">
        <div class="status">Bid <span *ngIf="bidsCount == 0"> Not </span> Submitted</div>
        <div class="hour">{{ visit.interval }}</div>
      </div>
    </div>
    <div class="row">
      <button (click)="reScheduleVisit(visit)" class="btn-reschedule" mat-button>Reschedule Visit</button>
      <button (click)="removeScheduleVisit(visit)" class="btn-cancel" mat-button>Cancel Visit</button>
    </div>
  </div>
</div>
