<div class="tab-container">
  <div class="tab-title">
    <!--    Sitewalk Schedule-->
  </div>
  <div class="tab-content-wrapper">
    <div class="column">
      <div class="calendar-content">
        <div class="calendar-wrapper">
          <app-calendar #schedule (events)="registerEvents($event)" (dateChange)="registerNewDate($event)">
          </app-calendar>
        </div>
      </div>
      <div class="separator"></div>
      <div class="selection-content">
        <div class="text">Please select a day from the calendar and then the timeframes for that day.</div>
        <div class="selection-wrapper">
          <ng-scrollbar class="std-scrollbar" #selectionScrollbar>
            <app-selection-list
              (changes)="refreshCalendar()"
              [date]="calendarDate"
              [events]="calendarEvents"
            ></app-selection-list>
          </ng-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>
