<div [ngClass]="{ negative: value < 0 }">
  <span
    [matTooltipClass]="{
      'negative-tooltip': value < 0
    }"
    [matTooltip]="value | money: 'positiveComa'"
  >
    {{ value | money: 'shortSignSimpleSpace' }}
  </span>
</div>
