<div class="options-list-container">
  <div class="options-list-animation-wrapper custom-options-list-animation-wrapper">
    <div class="options-list">
      <ng-container *ngFor="let item of listItems; index as i">
        <div
          [matTooltip]="
            (item | containsValue: disabledActionList) && item === 'Dismiss'
              ? 'Remove commitments before dismissing the Service Provider!'
              : ''
          "
        >
          <div
            (click)="optionSelected(item)"
            [ngClass]="{
              'p-disabled': item | containsValue: disabledActionList
            }"
            class="btn-option btn-small"
          >
            {{ item }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
