import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'switchFields',
  standalone: true,
})
export class SwitchFieldsPipe implements PipeTransform {
  transform(value: any, item: { name: string; id: string }[]): unknown {
    const foundItem = item.find((it) => it.id === value);
    console.log(foundItem);
    if (!foundItem) {
      return '';
    }
    return foundItem.name;
  }
}
