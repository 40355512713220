import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { OptionsListGeneralComponent } from '../overlays/options-list-general/options-list-general.component';
import { ControlContainer, NgForm } from '@angular/forms';
import { dropdownOverlayPositions } from '../overlays/option-list.constants';

@Component({
  selector: 'app-custom-input-overlay-dropdown',
  template: ` <app-floating-input
      #optionsTrigger="cdkOverlayOrigin"
      #floatingInputComponent
      (click)="showDropdown()"
      (modelChange)="modelChange.emit($event)"
      [(model)]="model"
      [autoComplete]="autoComplete"
      [iconClass]="iconClass"
      [label]="label"
      [name]="name"
      [placeholder]="placeholder"
      [required]="required"
      [readonly]="readonly"
      cdkOverlayOrigin
    ></app-floating-input>

    <app-options-list-general
      (optionSelected)="optionSelected.emit($event)"
      [(show)]="isDropdownShown"
      [overlayPositions]="dropdownOverlayPositions"
      [trigger]="optionsTrigger"
      [withAnimation]="withAnimation"
    >
      <ng-content></ng-content>
    </app-options-list-general>`,
  standalone: true,
  imports: [AsyncPipe, CdkOverlayOrigin, FloatingInputComponent, OptionsListGeneralComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomInputOverlayDropdownComponent implements OnInit {
  @ViewChild('floatingInputComponent') floatingInputComponent: FloatingInputComponent;

  @Input() model: any;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;
  @Input() readonly: boolean;
  @Input() autoComplete: boolean;
  @Input() required: boolean;
  @Input() withAnimation = true;
  @Input() iconClass: {
    position: 'p-input-icon-left' | 'p-input-icon-right';
    icon: string;
  } = {
    position: null,
    icon: null,
  };
  isDropdownShown = false;

  @Output() modelChange = new EventEmitter();
  @Output() optionSelected = new EventEmitter();

  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;

  showDropdown() {
    this.isDropdownShown = true;
  }

  hideDropdown() {
    this.isDropdownShown = false;
  }

  ngOnInit(): void {}
}
