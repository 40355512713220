import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { BorderedDropdownIconComponent } from '../bordered-dropdown-icon/bordered-dropdown-icon.component';

@Component({
  selector: 'app-general-sidebar-header',
  standalone: true,
  imports: [NgIf, NgClass, BorderedDropdownIconComponent],
  templateUrl: './general-sidebar-header.component.html',
  styleUrl: './general-sidebar-header.component.scss',
})
export class GeneralSidebarHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() section: boolean = false;
  @Input() showBackButton: boolean = false;

  @Output() goBack = new EventEmitter<void>();

  onBack() {
    this.goBack.emit();
  }
}
