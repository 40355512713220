<span class="p-float-label flex flex-col mb-2 mt-6">
  <textarea
    class="resize-y"
    id="float-input"
    [rows]="rows"
    [cols]="cols"
    pInputTextarea
    #input="ngModel"
    (ngModelChange)="onModelChange($event)"
    [(ngModel)]="model"
    [name]="name"
    [ngClass]="{ 'ng-invalid ng-dirty': !control?.valid && control?.touched }"
    [ngModelOptions]="ngModelOptions"
    [pattern]="pattern"
    [placeholder]="placeholder"
    [required]="required"
    [value]="formattedModel"
    [disabled]="disabled"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [autoResize]="autoResize"
    [readOnly]="!autoComplete && readonly"
  ></textarea>

  <label>{{ label }}</label>
  <small [ngClass]="{ hidden: control?.valid || control?.untouched }" class="p-error text-uppercase">
    <ng-container *ngIf="control">
      <!--      todo there might be a situation with multiple errors, this should be handled-->
      <ng-container *ngIf="control.hasError('required')"> Required </ng-container>
      <ng-container *ngIf="control.hasError('pattern')"> Invalid </ng-container>
      <ng-container *ngIf="control.hasError('notMatched')"> Does not match </ng-container>
    </ng-container>
  </small>
</span>
