<ng-scrollbar appearance="compact" class="std-scrollbar outer-scrollbar" track="horizontal">
  <div class="tasks-view">
    <app-tasks-kanban-column
      class="task-column"
      [type]="TASK_KANBAN_COLUMN_TYPES.DUE_THIS_WEEK"
    ></app-tasks-kanban-column>
    <app-tasks-kanban-column class="task-column" [type]="TASK_KANBAN_COLUMN_TYPES.UPCOMING"></app-tasks-kanban-column>
    <app-tasks-kanban-column class="task-column" [type]="TASK_KANBAN_COLUMN_TYPES.SPECTATING"></app-tasks-kanban-column>
  </div>
</ng-scrollbar>
