import { EventEmitter, Injectable } from '@angular/core';
import { NOTIFICATION_TYPE } from '../framework/constants/notification.constants';
import { Router } from '@angular/router';

const HASH_LENGTH = 5;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  static showEvent = new EventEmitter<any>();
  notificationsStack = [];

  constructor(private router: Router) {}

  showLoading(msg = 'Loading..'): string {
    NotificationsService.showEvent.emit({
      type: NOTIFICATION_TYPE.LOADING,
      msg,
    });
    return this.openLoading();
  }

  showSuccess(msg) {
    NotificationsService.showEvent.emit({
      type: NOTIFICATION_TYPE.SUCCESS,
      msg,
    });
    this.notificationsStack = [];
  }

  showError(msg) {
    NotificationsService.showEvent.emit({ type: NOTIFICATION_TYPE.ERROR, msg });
    this.notificationsStack = [];
  }

  showPopup(msg): Promise<any> {
    return new Promise<any>((res) => {
      NotificationsService.showEvent.emit({
        type: NOTIFICATION_TYPE.POPUP,
        msg,
      });

      const subscription = NotificationsService.showEvent.subscribe((event) => {
        if (event.type === NOTIFICATION_TYPE.POPUP_RESPONSE) {
          // console.log('Response', event.data);
          subscription.unsubscribe();
          this.close();
          res(event.data);
        }
      });
    });
  }

  close(hash = null) {
    if (hash === null) {
      this.closeEvent();
    } else {
      this.closeLoading(hash);
    }
  }

  private closeEvent() {
    NotificationsService.showEvent.emit({ type: NOTIFICATION_TYPE.CLOSE });
  }

  private closeLoading(hash) {
    // console.log(this.notificationsStack);
    const notificationIndex = this.notificationsStack.indexOf(hash);
    if (notificationIndex === this.notificationsStack.length - 1) {
      this.notificationsStack.splice(notificationIndex, 1);
      this.closeEvent();
    }
  }

  private genHash(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private openLoading() {
    const newHash = this.genHash(HASH_LENGTH);
    this.notificationsStack.push(newHash);
    return newHash;
  }
}
