<div class="graph-stats-container">
  <div class="stats">
    <div class="wealth-info budget">
      <div tooltipPosition="bottom" pTooltip="Dollars budgeted for this project" class="default-cursor title">
        BUDGET
      </div>
      <div
        [ngClass]="{ negative: budget < 0 }"
        [tooltipStyleClass]="budget < 0 ? 'negative' : ''"
        [pTooltip]="budget | money: 'positiveComa'"
      >
        {{ budget | money: 'shortSimpleSpace' }}
      </div>
    </div>
    <div class="wealth-info committed">
      <div tooltipPosition="bottom" pTooltip="Dollars committed to be spent" class="default-cursor title">
        COMMITTED
      </div>
      <div
        [ngClass]="{ negative: forecast < 0 }"
        [tooltipStyleClass]="forecast < 0 ? 'negative' : ''"
        [pTooltip]="forecast | money: 'positiveComa'"
      >
        {{ forecast | money: 'shortSimpleSpace' }}
      </div>
    </div>
    <div class="wealth-info remaining">
      <div tooltipPosition="bottom" pTooltip="Project is over budgeted if negative" class="title default-cursor">
        REMAINING
      </div>
      <div
        [ngClass]="{ negative: fcstVsBud < 0 }"
        [tooltipStyleClass]="fcstVsBud < 0 ? 'negative' : ''"
        [pTooltip]="fcstVsBud | money: 'positiveComa'"
      >
        {{ fcstVsBud | money: 'shortSimpleSpace' }}
      </div>
    </div>
    <div class="wealth-info paid">
      <div tooltipPosition="bottom" pTooltip="Dollars spent to date" class="default-cursor title">PAID</div>
      <div
        [ngClass]="{ negative: actuals < 0 }"
        [tooltipStyleClass]="actuals < 0 ? 'negative' : ''"
        [pTooltip]="actuals | money: 'positiveComa'"
      >
        {{ actuals | money: 'shortSimpleSpace' }}
      </div>
    </div>
    <div class="wealth-info left-to-pay">
      <div
        tooltipPosition="bottom"
        pTooltip="Remaining spend. Project is over committed if negative"
        class="default-cursor title accent"
      >
        LEFT TO PAY
      </div>
      <div
        [ngClass]="{ negative: actVsForecast < 0 }"
        [tooltipStyleClass]="actVsForecast < 0 ? 'negative' : ''"
        [pTooltip]="actVsForecast | money: 'positiveComa'"
      >
        {{ actVsForecast | money: 'shortSimpleSpace' }}
      </div>
    </div>
  </div>
</div>
