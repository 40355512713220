<div class="msg-table-wrapper">
  <ng-scrollbar *ngIf="usersRelated.length" class="std-scrollbar min-h-[12.5rem]" appearance="standard">
    <ng-container *ngFor="let user of usersRelated">
      <span (click)="optionSelected({ action: 'select', user: user })" class="msg-table-row p-1">
        <span [ngClass]="{ 'font-bold': user?.is_admin }" class="text-shade_1 font-semibold text-sm cursor-pointer">
          {{ user.name }}
        </span>
        <span class="text-shade_2 font-normal text-sm cursor-pointer">
          {{ user.company_name }}
        </span>
        <span class="text-shade_3 font-normal italic text-xs cursor-pointer">{{
          user.is_team_member ? 'Team Member' : ''
        }}</span>
        <span
          (click)="optionSelected({ action: 'remove', user: user }); $event.stopPropagation()"
          *ngIf="
            isSelected(user) &&
            (isUserAdmin() ||
              isAddedByCurrentUser(user) ||
              (selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_CREATE) &&
            !isSelfUser(user)
          "
          class="text-color_secondary text-sm font-semibold text-center cursor-pointer"
        >
          remove
        </span>
      </span>
    </ng-container>
  </ng-scrollbar>
  <ng-container *ngIf="!usersRelated.length">
    <span class="text-shade_2 font-normal text-sm"> No users found </span>
  </ng-container>
</div>
