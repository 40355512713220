import { Component } from '@angular/core';
import { LoadingPulseComponent } from '../loading-pulse/loading-pulse.component';

@Component({
  selector: 'app-page-loading',
  standalone: true,
  imports: [LoadingPulseComponent],
  template: `
    <div class="flex w-full h-full items-center justify-center absolute">
      <app-loading-pulse></app-loading-pulse>
    </div>
  `,
  styles: `
    :host {
      flex: 1;
    }
  `,
})
export class PageLoadingComponent {}
