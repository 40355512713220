<div class="custom-task-dropdown-wrapper" [@fillIn]="'in'">
  <ng-scrollbar class="std-scrollbar min-h-[9rem]" appearance="standard">
    <ng-container *ngFor="let taskType of allCustomTaskFieldTypes; let index = index">
      <span class="row p-1">
        <span
          (click)="selectOption(SELECTED_OPTION.SELECT, taskType)"
          class="text-shade_1 font-semibold text-sm cursor-pointer"
        >
          {{ CustomTaskTypeToDisplay[taskType] | titlecase }}
        </span>
      </span>
    </ng-container>
  </ng-scrollbar>
  <span
    (click)="selectOption(SELECTED_OPTION.REMOVE)"
    class="text-color_secondary text-sm font-semibold text-center cursor-pointer"
  >
    Delete Custom Field
  </span>
</div>
