import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_MESSAGES,
  REST_MESSAGING_GROUPS,
  REST_MESSAGES_RECEIVED,
  REST_MESSAGES_SENT,
  REST_MESSAGING,
  REST_USER_BASE,
  REST_MESSAGING_USERS,
  REST_MESSAGING_MESSAGES,
} from '../restApi/RestRoutes';
import { ErrorHandlerRestSimple } from '../restApi/errorHandler-rest';
import { Observable } from 'rxjs';
import {
  IMessage,
  IGroupMessagesAndDrafts,
  ICreateMessageData,
  IMessagingGroup,
  IMessagingGroupFilters,
  IMessagingMember,
  IMessageFilters,
  withUser,
  IMessagingGroupApiFilters,
} from '../store/messages/messages.interfaces';
import { MessagesGroupEffects } from '../store/messages/messagesGroup.effects';
import { messagesActions } from '../store/messages/messages.actions';

@Injectable({
  providedIn: 'root',
})
export class MessagesApiService extends ErrorHandlerRestSimple {
  constructor(private rest: RestRequestService) {
    super();
  }

  getMessagesSent() {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_MESSAGES_SENT).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getMessagesReceived() {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_MESSAGES_RECEIVED).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createMessage(userId, message) {
    const body = {
      to_uid: userId,
      message,
    };
    return new Promise<any>((res, rej) => {
      this.rest.post(REST_MESSAGES, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createMessageObs(
    message: ICreateMessageData = {
      group_id: null,
      body: '',
      is_draft: false,
      thread_id: null,
    },
  ) {
    return this.rest.postWithObservable(REST_MESSAGING_MESSAGES, message);
  }

  // currently not used but leave it here just in case patch is needed
  patchMessageObs(
    message: ICreateMessageData = {
      id: null,
      group_id: null,
      body: '',
      is_draft: false,
      thread_id: null,
    },
  ): Observable<IMessage> {
    return this.rest.postWithObservable(`${REST_MESSAGING_MESSAGES}/${message.id}`, message);
  }

  createMessageThread(message: { body: string; thread_id: number }) {
    return this.rest.postWithObservable(REST_MESSAGING_MESSAGES, message);
  }

  updateMessage(id: any, params) {
    let body = {
      project_id: id,
    };

    body = { ...body, ...params };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_MESSAGES + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteMessage(id: any) {
    return new Promise<any>((res, rej) => {
      this.rest.del(REST_MESSAGES + '/' + id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getMessagingGroups(filter?: IMessagingGroupApiFilters) {
    const params = {
      ...filter,
    };
    return this.rest.getWithObservable(REST_MESSAGING_GROUPS, {}, params);
  }

  getGroupMessages(groupId: number, filter?: IMessageFilters): Observable<IGroupMessagesAndDrafts> {
    const params = {
      ...filter,
    };
    return this.rest.getWithObservable(`${REST_MESSAGING_GROUPS}/${groupId}/messages`, {}, params);
  }

  getMessagingUsers(search: string = '', getWithUser: withUser = 1) {
    const endpoint = `${REST_MESSAGING_USERS}`;
    return this.rest.getWithObservable(endpoint, {}, { search, with_current_user: getWithUser });
  }

  createGroup(body: {
    name: string;
    members: Partial<IMessagingMember[]>;
    message: { body: string };
  }): Observable<IMessagingGroup> {
    return this.rest.postWithObservable(REST_MESSAGING_GROUPS, body);
  }

  createGroupForTasks(groupData: { task_id: number; message: { body: string } }) {
    return this.rest.postWithObservable(REST_MESSAGING_GROUPS, groupData);
  }

  changeGroupStatus(group: IMessagingGroup) {
    const body = {
      status: group.status,
    };
    return this.rest.patchWithObservable(`${REST_MESSAGING_GROUPS}/${group.id}/status`, body);
  }

  updateGroupReadStatus(group: IMessagingGroup, is_read: boolean) {
    const body = {
      is_read,
    };
    return this.rest.patchWithObservable(`${REST_MESSAGING_GROUPS}/${group.id}/status`, body);
  }

  removeGroupMember(group: IMessagingGroup, member: IMessagingMember) {
    const body = {
      name: group.name,
      members: [
        {
          user_id: member.user_id,
          deleted: true,
        },
      ],
    };
    return this.rest.patchWithObservable(`${REST_MESSAGING_GROUPS}/${group.id}`, body);
  }

  addGroupMember(group: IMessagingGroup, members: IMessagingMember[]) {
    const body = {
      name: group.name,
      members,
    };
    return this.rest.patchWithObservable(`${REST_MESSAGING_GROUPS}/${group.id}`, body);
  }

  updateMessageReadStatus(messageId: number, is_read: boolean) {
    const body = {
      is_read,
    };
    return this.rest.patchWithObservable(`${REST_MESSAGING_MESSAGES}/${messageId}/status`, body);
  }

  getMessageById(messageId: number) {
    return this.rest.getWithObservable(`${REST_MESSAGING_MESSAGES}/${messageId}`);
  }
}
