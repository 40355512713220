import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { settingsActions } from './settings.actions';
import {
  catchError,
  exhaustMap,
  map,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import { IBudgetTagTemplate, IBudgetTemplate, IGlTemplate } from './settings.interface';
import { NotificationsService } from '../../services/notifications.service';
import { settingsFeatureSelector } from './settings.selectors';
import * as uuid from 'uuid';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private notif: NotificationsService,
  ) {}

  loadBudgetTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadBudgetTemplate, settingsActions.reloadBudgetTemplates),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      exhaustMap((): Observable<IBudgetTemplate[]> => this.settingsService.getBudgetTemplates()),
      map((templates: IBudgetTemplate[]) => {
        return settingsActions.setBudgetTemplates({ budgetTemplates: templates });
      }),
    ),
  );

  loadGlTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadGlTemplateViewInit, settingsActions.reloadGlAccounts),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      switchMap(() => this.settingsService.getGlTemplates()),
      map((templates: IGlTemplate[]) => {
        return settingsActions.setGlTemplates({ glTemplates: templates });
      }),
    ),
  );

  loadBudgetTagTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadBudgetTagTemplates, settingsActions.reloadBudgetTagTemplates),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      switchMap(() => this.settingsService.getBudgetTagTemplates()),
      map((templates: IBudgetTagTemplate[]) => {
        return settingsActions.setBudgetTagTemplates({ budgetTagTemplates: templates });
      }),
    ),
  );

  loadBudgetTagTemplateCommitments = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadBudgetTagTemplatesFromCommitments),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      switchMap((action) => this.settingsService.getBudgetTagTemplates(action.projectId)),
      map((templates: IBudgetTagTemplate[]) => {
        return settingsActions.setBudgetTagTemplates({ budgetTagTemplates: templates });
      }),
    ),
  );

  saveGlTemplate = createEffect(() => {
    let selectedIndex = -1;
    return this.actions$.pipe(
      ofType(settingsActions.saveGlTemplate),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      withLatestFrom(this.store.select(settingsFeatureSelector)),
      switchMap(([action, state]) => {
        const requests: Observable<any>[] = [];
        const allGlTemplates = state.glTemplates.filter((template) => !template?.is_deleted);
        allGlTemplates.forEach((template) => {
          // if new account it has an uuid, not default id from backend
          if (uuid.validate(template.id)) {
            requests.push(this.settingsService.addGlTemplate(template));

            if (state.selectedGlTemplateId === template.id) {
              selectedIndex = requests.length - 1;
            }
          } else {
            if (template.permissions?.can_edit) {
              requests.push(this.settingsService.modifyGlTemplate(template));

              template.labels.forEach((label) => {
                if (label.is_deleted && !uuid.validate(label.id)) {
                  requests.push(this.settingsService.removeGlTemplateLabel(label));
                }
              });
            }
          }
        });

        const deletedGlTemplates = state.glTemplates.filter(
          (template) =>
            template?.is_deleted && !uuid.validate(template.id) && template.permissions?.can_delete,
        );
        deletedGlTemplates.forEach((template) => {
          requests.push(this.settingsService.removeGlTemplate(template));
        });

        return forkJoin(...requests);
      }),
      map((requests) => {
        this.notif.showSuccess('Saved!');
        if (selectedIndex >= 0 && requests?.[selectedIndex]?.id) {
          this.store.dispatch(
            settingsActions.setSelectedGlTemplate({ templateId: requests[selectedIndex].id }),
          );
        }
        return settingsActions.reloadGlAccounts();
      }),
      catchError((err) => {
        console.error('Save gl account error', err);
        this.notif.showError('Error saving templates');
        return of(settingsActions.cancel());
      }),
      repeat(),
    );
  });

  saveBudgetTemplates = createEffect(() => {
    let selectedIndex = -1;
    return this.actions$.pipe(
      ofType(settingsActions.saveBudgetTemplates),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      withLatestFrom(this.store.select(settingsFeatureSelector)),
      switchMap(([action, state]) => {
        const requests = [];
        const allBudgetTemplates = state.budgetTemplates.filter(
          (template) => !template?.is_deleted,
        );

        allBudgetTemplates.forEach((template) => {
          // if new account it has an uuid, not default id from backend
          if (uuid.validate(template.id)) {
            requests.push(this.settingsService.addBudgetTemplate(template));

            if (state.selectedBudgetTemplateId === template.id) {
              selectedIndex = requests.length - 1;
            }
          } else {
            if (template.permissions?.can_edit) {
              requests.push(this.settingsService.modifyBudgetTemplate(template));
            }
          }
        });

        const deletedBudgetTemplates = state.budgetTemplates.filter(
          (template) =>
            template?.is_deleted && !uuid.validate(template.id) && template.permissions?.can_delete,
        );
        deletedBudgetTemplates.forEach((template) => {
          requests.push(this.settingsService.removeBudgetTemplate(template));
        });

        return forkJoin(...requests);
      }),
      map((requests) => {
        this.notif.showSuccess('Saved!');
        if (selectedIndex >= 0 && (requests?.[selectedIndex] as any)?.id) {
          this.store.dispatch(
            settingsActions.setSelectedBudgetTemplate({
              templateId: (requests[selectedIndex] as any).id,
            }),
          );
        }
        return settingsActions.reloadBudgetTemplates();
      }),
      catchError((err) => {
        console.error('Save budget templates error', err);
        this.notif.showError('Error saving templates');
        return of(settingsActions.cancel());
      }),
      repeat(),
    );
  });

  saveBudgetTagTemplate = createEffect(() => {
    let selectedIndex = -1;
    return this.actions$.pipe(
      ofType(settingsActions.saveBudgetTagTemplate),
      tap(() => this.store.dispatch(settingsActions.setIsLoading({ isLoading: true }))),
      withLatestFrom(this.store.select(settingsFeatureSelector)),
      switchMap(([action, state]) => {
        const requests = [];
        const allTagTemplates = state.budgetTagTemplates.filter(
          (template) => !template?.is_deleted,
        );

        allTagTemplates.forEach((template) => {
          // if new account it has an uuid, not default id from backend
          if (uuid.validate(template.id)) {
            requests.push(this.settingsService.addBudgetTagTemplate(template));

            if (state.selectedBudgetTagTemplateId === template.id) {
              // selected index is needed to replace uuid after requests have finished
              selectedIndex = requests.length - 1;
            }
          } else {
            if (template.permissions?.can_edit) {
              requests.push(this.settingsService.modifyBudgetTagTemplate(template));
              template.tags.forEach((tag) => {
                if (tag.is_deleted && !uuid.validate(tag.id)) {
                  requests.push(this.settingsService.removeTagTemplateItem(tag));
                }
              });
            }
          }
        });

        const deletedTagTemplates = state.budgetTagTemplates.filter(
          (template) =>
            template?.is_deleted && !uuid.validate(template.id) && template.permissions?.can_delete,
        );
        deletedTagTemplates.forEach((template) => {
          requests.push(this.settingsService.removeTagTemplate(template));
        });

        return forkJoin(...requests);
      }),
      map((requests) => {
        if (selectedIndex >= 0 && (requests?.[selectedIndex] as any)?.id) {
          this.store.dispatch(
            settingsActions.setSelectedBudgetTagTemplate({
              templateId: (requests[selectedIndex] as any).id,
            }),
          );
        }
        this.notif.showSuccess('Saved!');
        return settingsActions.reloadBudgetTagTemplates();
      }),
      catchError((err) => {
        console.error('Save gl budget tag error', err);
        this.notif.showError('Error saving templates');
        return of(settingsActions.cancel());
      }),
      repeat(),
    );
  });
}
