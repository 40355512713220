import { Pipe, PipeTransform } from '@angular/core';
import { OVERLAY_TYPES, OverlayOptionsPipe } from './overlay-options.pipe';
import { DocumentItem } from '../../store/documents/documents.reducer';
import { IStyledOption } from '../../framework/constants/documents.constants';

@Pipe({
  name: 'commonPermissions',
  standalone: true,
})
export class CommonPermissionsPipe implements PipeTransform {
  constructor(private optionsPipe: OverlayOptionsPipe) {}

  transform(
    items: DocumentItem[],
    isPublicLinkUsed: boolean,
    currentFolder: DocumentItem,
  ): IStyledOption[] {
    if (!items.length) {
      return [];
    }

    // array that contain an array of permission for each item separately
    const itemsPermissions = items.map((item) =>
      this.optionsPipe.transform(item, OVERLAY_TYPES.DRIVE, isPublicLinkUsed, currentFolder),
    );

    const commonPermissions = itemsPermissions.reduce((common, current) =>
      common.filter((permission) => current.some((p) => p.value === permission.value)),
    );

    return commonPermissions;
  }
}
