import { createFeatureSelector, createSelector } from '@ngrx/store';
import { viewProjectFeatureKey, ViewProjectState } from './view-project.reducer';

const getViewProjectState = createFeatureSelector<ViewProjectState>(viewProjectFeatureKey);

const getProjectId = createSelector(getViewProjectState, (state) => state.currentProjectId);

const getSelectedView = createSelector(getViewProjectState, (state) => state.selectedView);

const getSelectedProject = createSelector(getViewProjectState, getProjectId, (state, projectId) =>
  state.projects.get(Number(projectId)),
);

const isLoading = createSelector(getViewProjectState, (state) => state.isLoading);
const isLoadingGeneral = createSelector(isLoading, (loading) => loading.general);
const isLoadingSpends = createSelector(isLoading, (loading) => loading.spends);
const isLoadingLineItems = createSelector(isLoading, (loading) => loading.lineItems);

const getSpends = createSelector(getViewProjectState, getProjectId, (state, projectId) =>
  state.spends.get(projectId),
);

const getLineItems = createSelector(
  getViewProjectState,
  getProjectId,
  (state, projectId) => state.lineItems.get(projectId) ?? [],
);

const hasTemplate = createSelector(getSelectedProject, (project) => !!project?.budget_template_id);

const selectAllLoadingStatesFinished = createSelector(
  isLoadingGeneral,
  isLoadingSpends,
  (isLoadingGeneral, isLoadingSpends) => {
    return !isLoadingGeneral && !isLoadingSpends;
  },
);

const allLoadingStatesFinished = createSelector(selectAllLoadingStatesFinished, (allFinished) => {
  return allFinished;
});

const isDescriptionsLoading = createSelector(
  getViewProjectState,
  (state) => state.isLoading.descriptions,
);

const areDescriptionsReloading = createSelector(
  getViewProjectState,
  (state) => state.isLoading.reloadDescriptions,
);

const projectDescriptions = createSelector(getViewProjectState, getProjectId, (state, projectId) =>
  state.descriptions.get(projectId),
);

const getProjectTemplates = createSelector(getViewProjectState, (state) => state.projectTemplates);

export const viewProjectSelectors = {
  getViewProjectState,
  getProjectId,
  getSelectedView,
  getSelectedProject,
  isLoadingGeneral,
  isLoadingSpends,
  isLoadingLineItems,
  getSpends,
  getLineItems,
  hasTemplate,
  allLoadingStatesFinished,
  projectDescriptions,
  isDescriptionsLoading,
  areDescriptionsReloading,
  getProjectTemplates,
};
