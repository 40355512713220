<div class="messages-container">
  <button (click)="openMessages()" mat-button disableRipple class="message-btn btn-small">
    <span class="icon-add-round"></span>
    <span class="btn-text">New Message</span>
  </button>
  <button mat-button disableRipple class="message-btn default-cursor btn-small">
    <span class="icon-checkbox-round-u"></span>
    <span class="btn-text">
      {{ phoneNumber | phone }}
    </span>
  </button>
</div>

<div class="spacer"></div>
