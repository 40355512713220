import { Component, Input, OnInit } from '@angular/core';
import { InteractionBarStateService } from 'src/app/services/interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../../constants/interaction-bar.constants';
import { AddPropertyViewComponent } from '../../add-property-view/add-property-view.component';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
  standalone: true,
  imports: [NgIf, AddPropertyViewComponent],
})
export class AddPropertyComponent implements OnInit {
  _data = null;

  editView = false;

  @Input() set data(value) {
    if (value) {
      if (InteractionBarStateService.state === INTERACTION_BAR_STATES.EDIT_PROPERTY) {
        this._data = value.property;
        this.editView = true;
      } else {
        this._data = null;
      }
    }
  }

  get data() {
    return this._data;
  }

  constructor() {}

  ngOnInit(): void {}
}
