import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OPTIONS } from '../constants/options-list.constants';
import { ContainsValuePipe } from '../../pipes/framework/contains-value.pipe';

@Component({
  selector: 'app-default-options-list',
  templateUrl: './default-options-list.component.html',
  styleUrls: ['./default-options-list.component.scss'],
  standalone: true,
  imports: [NgClass, MatTooltipModule, NgForOf, ContainsValuePipe],
})
export class DefaultOptionsListComponent implements OnInit {
  @Input() listItems: Array<string> = [];
  @Input() disabledActionList: Array<OPTIONS> = [];
  @Output() selectedOption = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  // todo type
  optionSelected(item) {
    this.selectedOption.emit(item);
  }
}
