<div class="message-item-wrapper">
  <!--  <div *ngIf="message.isLocalOnly" class="text-color_accent waiting-message">Waiting for network...</div>-->
  <div [ngClass]="{ 'is-local-only': message?.isLocalOnly }" class="inner-wrapper flex gap-3">
    <div class="profile-pic-container">
      <div class="rounded-profile-pic">
        <ng-container [ngSwitch]="profilePicUrl">
          <span *ngSwitchCase="DEFAULT_IMAGE" class="icon-logo-box-hex text-color_secondary"></span>
          <span *ngSwitchCase="undefined" class="icon-refresh animate-spin pic-loading"></span>
          <img *ngSwitchDefault [src]="profilePicUrl" />
        </ng-container>
      </div>
    </div>
    <div class="flex-1 message-item-content pr-1">
      <div class="message-item-header flex justify-between items-baseline">
        <div class="flex gap-1.5 items-baseline">
          <span class="text-shade_1 font-semibold text-size_xxl">{{ message?.user?.name }}</span>
          <span class="text-shade_2 font-bold text-size_l">{{ message?.user?.company_name }}</span>
          <span *ngIf="message?.user?.was_deleted" class="text-color_reject font-bold text-size_l"
            >User no longer exists.</span
          >
        </div>
        <div class="flex text-size_l text-shade_2 gap-4">
          <span> {{ message?.updated_at | datepipe: 'LT' | uppercase }} </span>
          <span> {{ message?.updated_at | datepipe: 'MMM DD' | uppercase }} </span>
        </div>
      </div>
      <div class="message" *ngIf="message?.body !== '<p><br></p>'" [innerHTML]="message?.body"></div>
      <ng-container *ngFor="let attachment of message?.attachments">
        <div (click)="downloadAttachment(attachment)" class="text-color_secondary pointer-cursor">
          {{ attachment.name }}
        </div>
      </ng-container>
      <div *ngIf="!hideReplies" class="message-item-footer text-size_m flex gap-4 items-baseline w-fit">
        <div
          (click)="message?.thread?.length ? openThread(message) : $event.stopPropagation()"
          [ngClass]="{ 'pointer-cursor': message?.thread?.length, 'cursor-default': !message?.thread?.length }"
          class="text-shade_1"
        >
          {{ message?.thread ? message?.thread?.length : 0 }} Replies
        </div>
        <div
          (click)="openThread(message)"
          class="text-color_secondary cursor-pointer font-semibold hover:text-shade_1 p-1"
        >
          Reply
        </div>
        <div *ngIf="message?.is_read === false" class="text-color_accent font-semibold p-1">Unread messages</div>
      </div>
    </div>
  </div>
</div>
