import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class GeneralGuard {
  constructor(
    private router: Router,
    private user: CurrentUserService,
  ) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn = this.user.isLoggedIn();
    const isAdmin = this.user.isAdmin();
    if (isLoggedIn && isAdmin) {
      return true;
    } else {
      this.router.navigate(['/webapp']);
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return undefined;
  }
}
