export const PAGE_DASHBOARD = 'dashboard';
export const PAGE_PROJECTS = 'projects';
// export const PAGE_PROJECTS_CONTRACTORS = 'contractor/projects';
// export const PAGE_PROJECTS_MANAGERS = 'manager/projects';
export const PAGE_BUDGET = 'budget';
export const PAGE_DOCUMENTS = 'documents';
export const PAGE_INVOICES = 'invoices';
export const PAGE_CONTRACTORS = 'contractors';
export const PAGE_SETTINGS = 'settings';
export const PRINT = 'print';
export const PRICING = 'pricing';
export const CASHFLOW = 'cashflow';
export const PAGE_TASKS = 'tasks';
export const PAGE_TASKS_KANBAN = 'tasks/kanban';
export const PAGE_TASKS_TABLE = 'tasks/table';
export const PAGE_TASKS_CALENDAR = 'tasks/calendar';

export enum MENU_PAGES {
  ADD = 'projects/add',
  TASKS = 'tasks',
  PROJECTS = 'projects',
  // BUDGET = 'budget',
  DOCUMENTS = 'documents',
  // INVOICES = 'invoices',
  CONTRACTORS = 'contractors',
  PRINT = 'reporting',
  CASHFLOW = 'cashflow',
  SETTINGS = 'settings',
  ACTIVITIES = 'activities',
}

export enum CONTRACTOR_SELECTOR {
  ALL = 'all',
  PREFERRED = 'preferred',
  INVITED = 'invited',
  SCHEDULED = 'scheduled',
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
