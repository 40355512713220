import { createReducer, on } from '@ngrx/store';
import { viewProjectActions } from './view-project.actions';
import { Project, ProjectDescription } from '../../pages/webapp/projects/projects.interface';
import { ProjectSpend } from '../../services/project-spend.service';
import { ILineItem } from '../spend/spend.interfaces';
import { PROJECT_VIEWS } from '../../framework/constants/view-project.constants';
import { IBudgetTemplate } from '../settings/settings.interface';

export const viewProjectFeatureKey = 'view-project';

export interface ViewProjectState {
  isLoading: {
    lineItems: boolean;
    spends: boolean;
    general: boolean;
    descriptions: boolean;
    reloadDescriptions: boolean;
  };
  currentProjectId?: number;
  projects: Map<number, Project>;
  spends: Map<number, ProjectSpend[]>;
  descriptions: Map<number, ProjectDescription[]>;
  lineItems: Map<number, ILineItem[]>;
  selectedView: PROJECT_VIEWS | null;
  projectTemplates: IBudgetTemplate[];
}

export const initialState: ViewProjectState = {
  isLoading: {
    lineItems: false,
    spends: false,
    general: false,
    descriptions: false,
    reloadDescriptions: false,
  },
  currentProjectId: null,
  projects: new Map<number, Project>(),
  spends: new Map<number, ProjectSpend[]>(),
  descriptions: new Map<number, ProjectDescription[]>(),
  lineItems: new Map<number, ILineItem[]>(),
  selectedView: null,
  projectTemplates: [],
};

export const viewProjectReducer = createReducer(
  initialState,
  on(viewProjectActions.selectedProjectChanged, (state, action) => {
    return {
      ...state,
      currentProjectId: action.projectId,
    };
  }),
  on(viewProjectActions.projectLoaded, (state, action) => {
    const projects = state.projects;
    projects.set(action.project.id, action.project);
    return {
      ...state,
      projects,
      isLoading: {
        ...state.isLoading,
        general: false,
      },
    };
  }),

  on(viewProjectActions.startedLoading, (state, action) => {
    let target = action.target;
    if (!target) {
      target = 'general';
    }
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [target]: true,
      },
    };
  }),

  on(viewProjectActions.loadingEnded, (state, action) => {
    let target = action.target;
    if (!target) {
      target = 'general';
    }
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [target]: false,
      },
    };
  }),

  on(viewProjectActions.spendsLoaded, (state, action) => {
    const spends: Map<number, ProjectSpend[]> = state.spends;
    spends.set(action.projectId, action.spends);
    return {
      ...state,
      spends,
      isLoading: {
        ...state.isLoading,
        spends: false,
      },
    };
  }),

  on(
    viewProjectActions.updateProjectDescriptions,
    viewProjectActions.createProjectDescriptions,
    viewProjectActions.deleteProjectDescriptions,
    viewProjectActions.reloadProjectDescriptions,
    (state, action) => {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reloadDescriptions: true,
        },
      };
    },
  ),

  on(viewProjectActions.loadProjectDescriptions, (state, action) => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        descriptions: true,
      },
    };
  }),

  on(viewProjectActions.cancel, viewProjectActions.successfullySaved, (state, action) => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        descriptions: false,
        reloadDescriptions: false,
        general: false,
      },
    };
  }),

  on(viewProjectActions.deleteProjectDescriptionFromStore, (state, action) => {
    const descriptions: Map<number, ProjectDescription[]> = state.descriptions;
    descriptions.set(
      state.currentProjectId,
      descriptions.get(state.currentProjectId).filter((d) => d.id !== action.id),
    );
    return {
      ...state,
      descriptions,
      isLoading: {
        ...state.isLoading,
        descriptions: false,
        reloadDescriptions: false,
        general: false,
      },
    };
  }),

  on(viewProjectActions.projectDescriptionsLoaded, (state, action) => {
    const descriptions: Map<number, ProjectDescription[]> = state.descriptions;

    descriptions.set(action.projectId, action.projectDescriptions);

    return {
      ...state,
      descriptions,
      isLoading: {
        ...state.isLoading,
        descriptions: false,
        reloadDescriptions: false,
      },
    };
  }),

  on(viewProjectActions.lineItemsLoaded, (state, action) => {
    const lineItems: Map<number, ILineItem[]> = state.lineItems;
    lineItems.set(action.projectId, action.lineItems);
    return {
      ...state,
      lineItems,
      isLoading: {
        ...state.isLoading,
        line_items: false,
      },
    };
  }),

  on(viewProjectActions.changeView, (state, action) => {
    return {
      ...state,
      selectedView: action.view,
    };
  }),

  on(viewProjectActions.onDestroy, (state, action) => {
    return {
      ...state,
      selectedView: PROJECT_VIEWS.PROJECT_SPEND,
      currentProjectId: null,
      projectTemplates: [],
    };
  }),

  on(viewProjectActions.projectTemplatesLoaded, (state, action) => {
    return {
      ...state,
      projectTemplates: action.templates,
    };
  }),
);
