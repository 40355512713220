import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { GeneralSidebarHeaderComponent } from '../../general-sidebar-header/general-sidebar-header.component';
import { AsyncPipe, DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, NgForm } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgScrollbar } from 'ngx-scrollbar';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { CustomInputOverlayDropdownComponent } from '../../custom-input-overlay-dropdown/custom-input-overlay-dropdown.component';
import { ChecklistDropdownComponent } from '../../inputs/checklist-dropdown/checklist-dropdown.component';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { ChecklistInputComponent } from '../../inputs/checklist-input/checklist-input.component';
import { NotificationsService } from '../../../services/notifications.service';
import { IItemTrackingSidebar } from '../../../store/item-tracking/item-tracking.reducer';
import { itemTrackingActions } from '../../../store/item-tracking/item-tracking.actions';
import { Actions, ofType } from '@ngrx/effects';
import { delay, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { DeepCopyService } from '../../../services/deep-copy.service';
import { defaultItemTrackingSidebar } from '../../../store/item-tracking/item-tracking.constants';
import { viewProjectSelectors } from '../../../store/view-project/view-project.selectors';

@Component({
  selector: 'app-track-item-sidebar',
  standalone: true,
  imports: [
    GeneralSidebarHeaderComponent,
    AsyncPipe,
    CdkOverlayOrigin,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    NgScrollbar,
    NgClass,
    InputCalendarComponent,
    FloatingInputComponent,
    CustomInputOverlayDropdownComponent,
    ChecklistDropdownComponent,
    NgIf,
    DecimalPipe,
    NgForOf,
    ChecklistInputComponent,
  ],
  templateUrl: './track-item-sidebar.component.html',
  styleUrl: './track-item-sidebar.component.scss',
})
export class TrackItemSidebarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('form') trackForm: NgForm;

  model: IItemTrackingSidebar = {
    ...(defaultItemTrackingSidebar as IItemTrackingSidebar),
  };

  project_start_date: string = '';

  $currentProject = this.store.select(viewProjectSelectors.getSelectedProject);
  private destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
    private interactionBar: InteractionBarStateService,
    private actions: Actions,
  ) {}

  ngAfterViewInit() {
    this.actions
      .pipe(
        ofType(itemTrackingActions.successfullySavedTrackingItem),
        takeUntil(this.destroyed$),
        tap(() => this.notif.showSuccess('Tracking item saved')),
        delay(500),
      )
      .subscribe(() => {
        this.interactionBar.close();
      });

    this.actions
      .pipe(ofType(itemTrackingActions.selectTrackingItemToEdit), takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.model = DeepCopyService.deepCopy(data.item);
      });

    this.$currentProject.pipe(take(1)).subscribe((project) => {
      this.project_start_date = project.start_date;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  saveTrackItem() {
    this.trackForm.form.markAllAsTouched();
    this.trackForm.form.updateValueAndValidity();
    if (this.trackForm.form.invalid) {
      this.notif.showError(
        'Please fill out all required fields and make sure the dates are valid.',
      );
      return;
    }

    this.saveTrackItemToBackend(this.model);
  }

  private saveTrackItemToBackend(model: IItemTrackingSidebar) {
    this.store.dispatch(
      itemTrackingActions.saveTrackingItem({ trackingItem: DeepCopyService.deepCopy(model) }),
    );
  }
}
