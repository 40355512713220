import { Component, Input, OnInit } from '@angular/core';
import { ConnectedPosition, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { IChecklistItem } from '../../../store/tasks/tasks.interfaces';
import { OptionsListGeneralComponent } from '../../overlays/options-list-general/options-list-general.component';
import { NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-checklist-popup',
  templateUrl: './checklist-popup.component.html',
  styleUrls: ['./checklist-popup.component.scss'],
  standalone: true,
  imports: [CdkConnectedOverlay, NgFor, NgClass],
})
export class ChecklistPopupComponent extends OptionsListGeneralComponent implements OnInit {
  @Input() checklistItems: IChecklistItem[];
  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      overlayX: 'center',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: -5,
    },
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
