<div class="budget-tags-row-wrapper">
  <div class="budget-tags-wrapper">
    <div class="templates">
      <app-template-selector (unselectTemplate)="unselectTemplate()" [type]="'budget-tag'"></app-template-selector>
    </div>
    <div class="flex">
      <div class="item-container-wrapper" *ngIf="!!selectedTemplate">
        <div class="items-container header-container">
          <span class="item header-item uppercase col-span-2">Budget Tag</span>
        </div>
        <div *ngIf="!showHelp" class="items-container header-container">
          <span (click)="showHelp = true" class="item help-item capitalize box-border">Need help with tags?</span>
        </div>
        <app-templates-help
          [showHelp]="showHelp"
          (closeHelpGuideLine)="showHelp = false"
          type="budget-tag"
          class="h-full"
          *ngIf="showHelp"
        ></app-templates-help>
        <ng-scrollbar *ngIf="!showHelp" class="std-scrollbar" appearance="compact" track="vertical">
          <div class="items-container">
            <ng-container *ngFor="let item of selectedTemplate?.tags; let index = index">
              <span [ngClass]="{ 'edit-disabled': !item?.permissions?.can_edit }" class="item index">{{
                index + 1
              }}</span>
              <span [ngClass]="{ 'edit-disabled': !item?.permissions?.can_edit }" class="item">
                <input
                  [readOnly]="!item?.permissions?.can_edit"
                  (change)="onInputChange(item, index, $event)"
                  (paste)="onPasteEvent(index, $event)"
                  class="template-input"
                  [value]="item.name"
                />
              </span>
              <span class="item justify-center">
                <!-- it removes an item, the add icon is rotated to be an X-->
                <span *ngIf="item?.permissions?.can_delete" (click)="removeItem(item)" class="icon-add"></span>
              </span>
            </ng-container>

            <ng-container *ngIf="selectedTemplate?.permissions?.can_edit">
              <span class="item index"></span>
              <span class="item col-span-2">
                <input
                  [(ngModel)]="newItemValue"
                  (change)="onNewItemChange()"
                  (paste)="onPasteEvent(null, $event)"
                  class="template-input"
                />
              </span>
            </ng-container>
          </div>
        </ng-scrollbar>
      </div>

      <!--      <div class="item-container-wrapper w-7/12">-->
      <!--        <div class="items-container header-container">-->
      <!--          <span class="item header-item uppercase col-span-2"></span>-->
      <!--        </div>-->
      <!--        <div class="items-container"></div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="budget-tags-wrapper -ml-px">
    <div></div>
    <div class="buttons-wrapper">
      <span class="discard-btn btn flex flex-1 justify-center p-1.5 pointer-cursor" (click)="onDiscard()">Discard</span>
      <span class="send-btn btn flex flex-1 justify-center p-1.5 pointer-cursor" (click)="onSave()">Save</span>
    </div>
  </div>
</div>
