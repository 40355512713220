<div class="flex gap-2.5 items-center mt-3.5">
  <div
    [ngClass]="{
      disabled: disabledRemove,
      'p-disabled': disabledRemove
    }"
    (click)="deleteItem()"
    class="text-color_red_light icon-minus-box cursor-pointer"
  ></div>
  <div
    [ngClass]="{
      disabled: disabledAdd,
      'p-disabled': disabledAdd
    }"
    (click)="insertItem()"
    class="text-color_secondary icon-plus-box cursor-pointer"
  ></div>
</div>
