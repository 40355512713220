import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Activity,
  ACTIVITY_ASSIGN_OPTIONS,
  ActivityAssignOptions,
  getInitials,
} from '../../../../store/activities/activities.constants';
import { activitiesActions } from '../../../../store/activities/activities.actions';
import { Store } from '@ngrx/store';
import { CurrentUserService } from '../../../../services/current-user.service';
import { MessagesStateService } from '../../../../services/messages-state.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { assignTeammateOverlayPositions } from '../../../../framework/overlays/option-list.constants';
import { CommonModule } from '@angular/common';
import { OptionsListGeneralComponent } from '../../../../framework/overlays/options-list-general/options-list-general.component';
import { AssignTeammateTableComponent } from '../../../../framework/teams/assign-teammate-table/assign-teammate-table.component';
import { DefaultOptionsListComponent } from '../../../../framework/default-options-list/default-options-list.component';
import { TooltipModule } from 'primeng/tooltip';
import { ITeamMember } from '../../../../store/team-management/team-management.interfaces';

@Component({
  selector: 'app-activity-card-assign-button',
  standalone: true,
  imports: [
    CdkOverlayOrigin,
    CommonModule,
    OptionsListGeneralComponent,
    AssignTeammateTableComponent,
    DefaultOptionsListComponent,
    TooltipModule,
  ],
  templateUrl: './activity-card-assign-button.component.html',
  styleUrl: './activity-card-assign-button.component.scss',
})
export class ActivityCardAssignButtonComponent {
  private _activity: Partial<Activity>;
  @Input({ required: true }) set activity(data: Partial<Activity>) {
    this._activity = data;
    this.initials = getInitials(data?.assignee);
  }
  get activity(): Partial<Activity> {
    return this._activity;
  }

  @Input() size: 'small' | 'medium' | 'medium-wide' | 'big' = 'medium';
  @Output() isOverlayOpen = new EventEmitter<boolean>();

  initials: string | null;
  overlayPosition: ConnectedPosition[] = assignTeammateOverlayPositions;
  assignOptions: Array<string> = ACTIVITY_ASSIGN_OPTIONS;
  private _isOverlayShown = false;
  set isOverlayShown(value: boolean) {
    this._isOverlayShown = value;
    this.isOverlayOpen.emit(value);
  }
  get isOverlayShown() {
    return this._isOverlayShown;
  }

  // true if the options can be shown (default)
  // false if we want to reassign the activity - so we have to show the assign teammate overlay
  private canShowAssigneeOptions = true;
  // the options should be shown if there is an assignee, and we don't want to reassign the activity
  get showAssigneeOptions() {
    return !!this.activity?.assignee && this.canShowAssigneeOptions;
  }

  constructor(
    private store: Store,
    private userService: CurrentUserService,
    private messagesStateService: MessagesStateService,
    private notif: NotificationsService,
  ) {}

  onTeamMateSelected(teamMember: ITeamMember, activity: Partial<Activity>) {
    this.onOverlayShowChange(false);
    this.notif.showLoading();
    this.store.dispatch(
      activitiesActions.assignTeammate({ activityId: activity?.id, teammateId: teamMember?.id }),
    );
  }

  onAssignOptionSelected(option: ActivityAssignOptions) {
    console.log('onAssignOptionSelected', option);
    this.onOverlayShowChange(false);
    switch (option) {
      case 'Reassign':
        // setTimeout is needed because we set the isOverlayShown to false in order to hide the options, but
        // we will set it to true after a short time to show the assignee table
        // otherwise the assignee's table wouldn't recalculate its position and sometimes would appear off-screen
        setTimeout(() => {
          this.onOverlayShowChange(true);
          this.canShowAssigneeOptions = false;
        }, 150); // setTimeout here needs to take longer than the one in onOverlayShowChange
        break;
      case 'Message':
        this.notif.showLoading();
        this.userService.getUserById(this.activity?.assignee?.id).subscribe({
          next: (user) => {
            this.notif.close();
            this.messagesStateService.newMessageTo(user);
          },
          error: () => {
            this.notif.showError('Error loading user');
          },
        });
        break;
      case 'Remove':
        this.store.dispatch(
          activitiesActions.assignTeammate({ activityId: this.activity?.id, teammateId: null }),
        );
        break;
    }
  }

  /**
   * Should be called anytime when the overlay's shown status changes. <br/>
   * If the overlay will be hidden, it resets the canShowAssigneeOptions to true - so the option menu can be shown.
   * @param isShown
   */
  onOverlayShowChange(isShown: boolean) {
    this.isOverlayShown = isShown;

    if (!isShown) {
      // setTimeout is needed because without it the assign table would disappear immediately
      // and the options menu would pop up for a fraction of time
      setTimeout(() => {
        this.canShowAssigneeOptions = true;
      }, 100);
    }
  }

  onInsideClick(event: Event) {
    this.onOverlayShowChange(!this.isOverlayShown);
    event.stopPropagation();
  }
}
