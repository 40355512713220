import { Component, Input, OnInit } from '@angular/core';
import {
  DRIVE_ITEM_TYPES,
  DRIVE_VIEW_OR_DOWNLOAD_EXTERNALLY_SHARED_FILE,
  SHARE_PERMISSIONS,
} from '../../constants/documents.constants';
import { DriveService } from '../../../services/drive.service';
import { DocumentItem } from '../../../store/documents/documents.reducer';
import { NotificationsService } from '../../../services/notifications.service';
import { DriveApiService } from '../../../services/drive-api.service';
import { MatButton } from '@angular/material/button';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-share-document-link',
  templateUrl: './share-document-link.component.html',
  styleUrls: ['../share-document/share-document.component.scss'],
  standalone: true,
  imports: [NgScrollbar, DropdownComponent, MatButton],
})
export class ShareDocumentLinkComponent implements OnInit {
  private items: DocumentItem[];
  itemType: DRIVE_ITEM_TYPES;
  selectedRight: SHARE_PERMISSIONS = SHARE_PERMISSIONS.READ_ONLY;
  selectableRights: { value: SHARE_PERMISSIONS; label: string }[] = [
    { value: SHARE_PERMISSIONS.READ_ONLY, label: 'Read only' },
  ];
  @Input() set data(value) {
    this.items = value.items;

    if (this?.items?.length > 1) {
      this.itemType = DRIVE_ITEM_TYPES.DOCUMENTS;
    } else {
      this.itemType = this.driveService.isFolder(this.items[0])
        ? DRIVE_ITEM_TYPES.FOLDER
        : DRIVE_ITEM_TYPES.FILE;
    }

    if (this.itemType === DRIVE_ITEM_TYPES.FOLDER || this.itemType === DRIVE_ITEM_TYPES.DOCUMENTS) {
      this.selectableRights.push({
        value: SHARE_PERMISSIONS.READ_UPLOAD,
        label: 'Read & Upload Access',
      });
    }
  }

  constructor(
    private driveService: DriveService,
    private notif: NotificationsService,
    private driveApi: DriveApiService,
  ) {}

  ngOnInit(): void {}

  async copyLink() {
    this.notif.showLoading('Generating link... ');
    if (this.itemType === DRIVE_ITEM_TYPES.DOCUMENTS) {
      await this.copyMultipleDocumentsLink();
      return;
    }
    await this.copySingleDocumentLink(this.items[0]);
  }

  private async copySingleDocumentLink(item: DocumentItem) {
    const successMsg = 'Link copied to the clipboard.';
    const link = `${location.origin}/${DRIVE_VIEW_OR_DOWNLOAD_EXTERNALLY_SHARED_FILE}/`;
    if (item.direct_link) {
      await this.driveService.copyClipboard(link + item.direct_link);
      this.notif.showSuccess(successMsg);
      return;
    }

    let result;
    try {
      this.notif.showLoading();
      result = await this.driveApi.directShare(
        item.id,
        this.driveService.isFolder(item),
        this.selectedRight,
      );
    } catch (e) {
      this.notif.showError('Could not share the file.');
    }

    if (result?.id) {
      try {
        await this.driveService.copyClipboard(link + result.id);
        this.notif.showSuccess(successMsg);
      } catch (e) {
        this.notif.showError('Could not copy to the clipboard.');
      }
    }
  }

  private async copyMultipleDocumentsLink() {
    const fileIds = [];
    const folderIds = [];
    const link = `${location.origin}/${DRIVE_VIEW_OR_DOWNLOAD_EXTERNALLY_SHARED_FILE}/`;
    this.items.forEach((selected) => {
      this.driveService.isFolder(selected)
        ? folderIds.push(selected.id)
        : fileIds.push(selected.id);
    });
    try {
      const data = await this.driveApi.directShareMultiple({
        user_drive_folder_ids: folderIds,
        user_drive_file_ids: fileIds,
        permission: this.selectedRight,
      });
      await this.driveService.copyClipboard(link + data.id);
      this.notif.showSuccess('Successfully copied.');
    } catch (e) {
      this.notif.showError('Could not copy to the clipboard.');
    }
  }
}
