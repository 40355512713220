import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CurrentUserService } from '../../../services/current-user.service';
import { fadeIn } from '../../../../assets/styles/animations';
import { ROUTE_WEBAPP } from '../../../framework/constants/route.webapp.constants';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FloatingInputComponent } from '../../../framework/inputs/floating-input/floating-input.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeIn],
  standalone: true,
  providers: [NgForm],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
    FloatingInputComponent,
    NgScrollbarModule,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  loading = false;
  error = undefined;
  showError = false;

  user = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });
  invitationToken = undefined;

  isDestroyed$ = new Subject();

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.user.valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe((value) => {
      if (value.password !== null) {
        this.showError = false;
      }
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.isDestroyed$)).subscribe((params) => {
      if (params.email) {
        this.user.get('email').setValue(params.email);
      }

      if (params.invitation_token) {
        this.invitationToken = params.invitation_token;
      }
    });
  }

  async login() {
    this.loading = true;
    const userData: { email: string; password: string; invitation_token?: string } = {
      ...this.user.getRawValue(),
    };

    if (this.invitationToken) {
      userData.invitation_token = this.invitationToken;
    }

    try {
      await this.currentUserService.login(userData);
      await this.router.navigate([ROUTE_WEBAPP.BASE]);
    } catch (error) {
      this.error = error;
      this.showError = true;
      this.user.get('password').reset();
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
