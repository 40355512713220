<div class="messages-threads-view-wrapper h-100 flex flex-col">
  <div class="flex-1 min-h-0">
    <ng-scrollbar *ngIf="!(isLoading$ | async)" #scrollbar class="std-scrollbar" track="vertical" appearance="standard">
      <div class="main-message-body pt-4 pb-3">
        <app-message-item [hideReplies]="true" [message]="threadMessages?.mainMessage"></app-message-item>
      </div>
      <div
        *ngFor="let threadMessage of threadMessages?.replies; trackBy: trackByFn"
        class="py-5 pl-10 border-b border-solid border-shade_5 first-child:py-0"
      >
        <app-message-item [hideReplies]="true" [message]="threadMessage"></app-message-item>
      </div>
    </ng-scrollbar>

    <div class="h-100 flex justify-center items-center" *ngIf="isLoading$ | async">
      <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
    </div>
  </div>
  <div class="upload-container" *ngIf="filesToUpload?.length">
    <ng-scrollbar [autoHeightDisabled]="false" class="std-scrollbar" track="vertical" appearance="standard">
      <ng-container *ngFor="let fileStructure of filesToUpload; let index = index">
        <span class="flex">
          <div class="text-shade_1 flex-1">
            {{ fileStructure.file.name }}
            <!--            <img [src]="fileStructure.url" />-->
          </div>
          <span
            (click)="removeFile(fileStructure.file, index)"
            class="mr-2 cursor-pointer text-color_secondary icon-remove-circle-x"
          ></span>
        </span>
      </ng-container>
    </ng-scrollbar>
  </div>
  <input
    (click)="$event.stopPropagation()"
    #fileUploadSubstitute
    (change)="registerFileAdded($event)"
    class="disp-none"
    type="file"
    multiple
  />
  <div
    class="py-2 options rich-text-edit-upload upload-drop-area"
    appUploadDrop
    (fileDropped)="registerFileUpload($event)"
  >
    <app-rich-text-editor
      #richTextEditorComponent
      [specialKeys]="editorSpecialKeys"
      (specialKeyPressed)="specialKeyPressed($event)"
      [editorValue]="editorInput"
      (editorChanged)="onEditorValueChanged($event)"
      [placeholder]="'Type message here'"
      [editorSettings]="editorSetting"
      [imageInsertSettings]="imageModel"
      customClass="messages"
      [editorView]="RTE_VIEWS.MESSAGING"
    ></app-rich-text-editor>
  </div>
</div>
