<div [formGroup]="messageForm" class="messageForm create h-100 flex">
  <div #list class="form hide-underline">
    <mat-form-field
      #userTableTrigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      (click)="optionsListGeneralComponent.toggleShow()"
      class="borderless w-full"
      subscriptSizing="dynamic"
    >
      <app-options-list-general
        #optionsListGeneralComponent
        (showChange)="!!$event ? loadUsers() : ''"
        [trigger]="userTableTrigger"
        [withAnimation]="false"
      >
        <app-messaging-users-table
          [listItems]="itemOptions"
          [usersRelated]="usersData.usersRelated"
          [discussionMembers]="usersData.discussionMembers"
          (selectedOption)="registerOption($event); optionsListGeneralComponent.toggleShow()"
        ></app-messaging-users-table>
      </app-options-list-general>

      <span prefix class="icon-at-sign text-shade_2 font-normal font-sm"></span>
      <ng-container *ngFor="let user of usersData.discussionMembers">
        <span class="pr-5 text-shade_2 font-normal font-sm"> {{ user.name }} </span>
      </ng-container>
      <input
        tabindex="-1"
        class="participants"
        autocomplete="off"
        (click)="optionsListGeneralComponent.toggleShow(); $event.stopPropagation()"
        (ngModelChange)="loadUsers()"
        formControlName="participants"
        matInput
      />
      <mat-error *ngIf="messageForm.get('participants').invalid">
        {{ getErrorMessage(messageForm.get('participants')) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="border-separator"></div>

  <div class="form hide-underline subject-input">
    <mat-form-field class="borderless w-full" subscriptSizing="dynamic">
      <input
        #subjectInput
        name="subject"
        formControlName="subject"
        placeholder="Group Name / Subject"
        matInput
        (ngModelChange)="updateMessagingGroup($event, 'name')"
      />
      <mat-error *ngIf="messageForm.get('subject').invalid">
        {{ getErrorMessage(messageForm.get('subject')) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="upload-container" *ngIf="filesToUpload?.length">
    <ng-scrollbar [autoHeightDisabled]="false" class="std-scrollbar" track="vertical" appearance="standard">
      <ng-container *ngFor="let fileStructure of filesToUpload; let index = index">
        <span class="flex">
          <div class="text-shade_1 flex-1">
            {{ fileStructure.file.name }}
            <!--            <img [src]="fileStructure.url" />-->
          </div>
          <span
            (click)="removeFile(fileStructure.file, index)"
            class="mr-2 cursor-pointer text-color_secondary icon-remove-circle-x"
          ></span>
        </span>
      </ng-container>
    </ng-scrollbar>
  </div>
  <input
    (click)="$event.stopPropagation()"
    #fileUploadSubstitute
    (change)="registerFileAdded($event)"
    class="disp-none"
    type="file"
    multiple
  />
  <div
    class="flex-1 pb-6 min-h-0 options rich-text-edit-upload upload-drop-area"
    appUploadDrop
    (fileDropped)="registerFileUpload($event)"
  >
    <!--      [editorValue]="messageForm.get('message').value"-->
    <app-rich-text-editor
      #richTextEditorComponent
      [quickToolbarSettings]="quickToolbarSettings"
      (editorChanged)="onEditorValueChanged($event)"
      [placeholder]="'Type message here'"
      [editorSettings]="editorSetting"
      customClass="messages"
      [editorView]="RTE_VIEWS.MESSAGING"
      [editorValue]="editorInput"
      [specialKeys]="editorSpecialKeys"
      (specialKeyPressed)="specialKeyPressed($event)"
    ></app-rich-text-editor>
  </div>
</div>
