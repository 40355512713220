<div
  [ngClass]="{ negative: dayCount < 0, 'gap-0.5': showText, 'min-w-11': size === 'big', 'min-w-6': size === 'default' }"
  class="flex no-leading text-[8px] font-bold text-shade_1"
>
  @if (dayCount) {
    {{ Math.abs(dayCount) }}
  } @else {
    {{ '-' }}
  }
  <div class="font-normal">
    @if (showText) {
      {{ dayCount ? 'DAYS' : '' }}
    }
  </div>
</div>
