import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  allCustomTaskFieldTypes,
  CustomTaskFieldType,
  CustomTaskTypeToDisplay,
  TaskDropdownOptionSelected,
} from '../../store/tasks/tasks.constants';
import { fillIn } from '../../../assets/styles/animations';
import { SELECTED_OPTION } from '../overlays/option-list.constants';
import { NgFor, TitleCasePipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-custom-tasks-group-dropdown',
  templateUrl: './custom-tasks-group-dropdown.component.html',
  styleUrls: ['./custom-tasks-group-dropdown.component.scss'],
  animations: [fillIn],
  standalone: true,
  imports: [NgScrollbar, NgFor, TitleCasePipe],
})
export class CustomTasksGroupDropdownComponent implements OnInit {
  allCustomTaskFieldTypes = allCustomTaskFieldTypes;
  CustomTaskTypeToDisplay = CustomTaskTypeToDisplay;
  SELECTED_OPTION = SELECTED_OPTION;

  @Output() optionSelected: EventEmitter<TaskDropdownOptionSelected> =
    new EventEmitter<TaskDropdownOptionSelected>();

  constructor() {}

  ngOnInit(): void {}

  selectOption(optionType: SELECTED_OPTION, taskType?: CustomTaskFieldType) {
    this.optionSelected.emit({ type: optionType, taskType });
  }
}
