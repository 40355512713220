<header [ngClass]="{ 'hide-border': hideBorder }">
  <div class="flex items-center gap-3">
    <ng-content select="[custom-title]"></ng-content>
    <h1 *ngIf="title" class="page-title">{{ title }}</h1>
  </div>

  <div class="filters">
    <ng-content></ng-content>
  </div>
</header>
