import {
  animate,
  animateChild,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0 }), animate(600)]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(0, style({ opacity: 0 }))),
]);

export const fadeInCustom = (duration: number) =>
  trigger('fadeInCustom', [
    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade in when created. this could also be written as transition('void => *')
    transition(':enter', [style({ opacity: 0 }), animate(duration)]),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave', animate(0, style({ opacity: 0 }))),
  ]);

export const fadeInGeneral = trigger('fadeInGeneral', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(0, style({ opacity: 0 }))),
]);

export const fillIn = trigger('fillIn', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ 'max-height': '25rem', overflow: 'hidden' })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [
    style({
      'max-height': 0,
      overflow: 'hidden',
    }),
    animate(200),
  ]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(200, style({ 'max-height': 0, overflow: 'hidden' }))),
]);

export const fillInMaxH = trigger('fillInMaxH', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ 'max-height': '50vh' })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [
    style({
      'max-height': 0,
      overflow: 'hidden',
      'box-shadow': 'none',
    }),
    animate(400),
  ]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(200, style({ 'max-height': 0, overflow: 'hidden' }))),
]);

export const widthAnimation = trigger('widthAnimation', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ top: 0 })),
  state('out', style({ top: '7rem' })),

  // fade in when created. this could also be written as transition('void => *')
  transition('in => out', [
    query(':self', [
      stagger(0, [
        animate(
          0,
          style({
            top: 0,
          }),
        ),
        animate(
          200,
          style({
            width: '1%',
          }),
        ),
      ]),
      stagger(200, [
        animate(
          200,
          style({
            top: '7rem',
          }),
        ),
      ]),
      stagger(200, [
        animate(
          200,
          style({
            width: '100%',
          }),
        ),
      ]),
    ]),
  ]),

  transition('out => in', [
    query(':self', [
      stagger(0, [
        animate(
          0,
          style({
            top: '7rem',
          }),
        ),
        animate(
          200,
          style({
            width: '1%',
          }),
        ),
      ]),
      stagger(200, [
        animate(
          200,
          style({
            top: 0,
          }),
        ),
      ]),
      stagger(200, [
        animate(
          200,
          style({
            width: '100%',
          }),
        ),
      ]),
    ]),
  ]),
]);

export const list = trigger('list', [
  state('in', style({ height: '0px' })),
  state('out', style({ flex: 1 })),

  transition(':enter', [query('@items', stagger(200, animateChild()))]),
]);

export const item = trigger('items', [
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }), // initial
    animate('0.4s ease-in-out', style({ transform: 'scale(1)', opacity: 1 })), // final
  ]),
]);

export const dropdownAnimation = trigger('dropdownAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-50px)',
    }),
    animate(120, style({ transform: 'none', opacity: 1 })),
  ]),
  transition(':leave', [
    animate(
      120,
      style({
        transform: 'translateY(-50px)',
        opacity: 0,
      }),
    ),
  ]),
]);
