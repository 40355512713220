import { EventEmitter, Injectable } from '@angular/core';
import { SPEND_ACTIONS, SPEND_TYPES } from '../framework/constants/budget.constants';
import { BehaviorSubject } from 'rxjs';
import { SPEND_DISTRIBUTION_STATE } from '../framework/constants/spend.constants';

@Injectable({
  providedIn: 'root',
})
export class SpendViewProjectStateService {
  static isModified;
  static isBudgetLocked = true;
  static isOpened = new BehaviorSubject<boolean>(false);
  static isSheetOpen = false;
  budgetType = SPEND_TYPES.BUDGET;
  stateEvents = new EventEmitter<SPEND_ACTIONS>();
  currentState = new BehaviorSubject<SPEND_DISTRIBUTION_STATE>(SPEND_DISTRIBUTION_STATE.DEFAULT);

  constructor() {}

  close() {
    if (this.currentState.value === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
      this.closeSheet();
    } else {
      this.closeOverlay();
    }
  }

  closeSheet(spendDistributionState: SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE.DEFAULT) {
    this.stateEvents.next(SPEND_ACTIONS.CLOSE_SHEETS);
    this.currentState.next(spendDistributionState);
  }

  update() {
    this.stateEvents.next(SPEND_ACTIONS.UPDATE);
  }

  showMonths() {
    this.stateEvents.next(SPEND_ACTIONS.SHOW_M);
  }

  hideMonths() {
    this.stateEvents.next(SPEND_ACTIONS.HIDE_M);
  }

  openOverlay() {
    this.stateEvents.next(SPEND_ACTIONS.OPEN);
  }

  changeBudgetType() {
    this.stateEvents.next(SPEND_ACTIONS.CHANGE_BUDGET_TYPE);
  }

  closeOverlay() {
    this.stateEvents.next(SPEND_ACTIONS.CLOSE);
  }

  save() {
    this.stateEvents.next(SPEND_ACTIONS.SAVE);
  }

  refresh() {
    this.stateEvents.next(SPEND_ACTIONS.REFRESH);
  }

  resetYear() {
    this.stateEvents.next(SPEND_ACTIONS.RESET_YEAR);
  }

  lockBudget() {
    this.stateEvents.next(SPEND_ACTIONS.LOCK_BUDGET);
  }
}
