<div class="task-details-header pt-1 border-separator">
  <div class="flex">
    <span
      class="flex items-center mr-6"
      *ngIf="
        (selectedMessagingView | async) === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW ||
        taskService.isTaskDetailsOpenedFromMessages
      "
    >
      <app-back-button-box class="text-shade_2 font-semibold" (clicked)="backButtonClicked()">Back</app-back-button-box>
    </span>
    <div class="title-container">
      <span
        ><app-messaging-bubble [type]="taskTypeToBubbleType[task?.type]" [isFilled]="false"></app-messaging-bubble
      ></span>
      <span class="font-semibold text-size_xxl text-color_secondary">{{ task?.title }}</span>
    </div>
  </div>
  <div class="info-container">
    <span
      (click)="onUpdateStatus()"
      class="btn-status font-semibold text-size_l text-uppercase text-color_secondary cursor-pointer"
      >MARK AS {{ taskStatusToText[nextTaskStatus[task.status]] }}</span
    >
    <span
      cdk-overlay-origin
      #trigger="cdkOverlayOrigin"
      (click)="showOptionsList = !showOptionsList"
      class="icon-options pointer-cursor text-color_secondary text-size_icon"
    ></span>
    <app-options-list-simple
      [actions]="actions"
      [(show)]="showOptionsList"
      [trigger]="trigger"
      (optionSelected)="taskService.optionSelected(task, $event)"
    ></app-options-list-simple>
    <span *ngIf="task.requires_response" [matTooltip]="'Requires response'" matTooltipPosition="above">
      <span class="icon-warning text-size_icon"></span>
    </span>
  </div>
</div>
