import { Injectable } from '@angular/core';
import { IRollupProjectTable } from '../store/rollups/rollups.interface';
import { INTERCOM_ID } from '../pages/webapp/webapp.component';
import { CurrentUserService } from './current-user.service';
import { Intercom } from '@supy-io/ngx-intercom';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private user: CurrentUserService,
    private intercom: Intercom,
  ) {}

  updateIntercom(projects?: IRollupProjectTable[]) {
    const userData: any = {
      app_id: INTERCOM_ID,
      widget: {
        activator: '#intercom',
      },
    };

    if (this.user.isLoggedIn() && this.user.data) {
      userData.name = this.user.data.first_name + ' ' + this.user.data.last_name;
      userData.email = this.user.data.email;
      userData.created_at = this.user.data.created_at; // Signup date as a Unix timestamp
      userData.numberOfProjects = projects?.length ?? null; // num of projects
      userData.isManager = !!this?.user?.isManager; // is manager
      userData.isServiceProvider = !this?.user?.isManager; // is service provider
    }
    this.intercom.update(userData);
  }

  bootIntercom() {
    const userData: any = {
      app_id: INTERCOM_ID,
      widget: {
        activator: '#intercom',
      },
    };

    if (this.user.isLoggedIn() && this.user.data) {
      userData.name = this.user.data.first_name + ' ' + this.user.data.last_name; // full name
      userData.email = this.user.data.email; // email address
      userData.created_at = this.user.data.created_at; // Signup date as a Unix timestamp
      userData.numberOfProjects = null; // num of projects
      userData.isManager = !!this?.user?.isManager; // is manager
      userData.isServiceProvider = !this?.user?.isManager; // is service provider
    }
    this.intercom.boot(userData);
  }
}
