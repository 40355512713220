import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { rollupActions } from './rollups.actions';
import {
  IRollupAccount,
  IRollupAccountTable,
  IRollupFilters,
  IRollupLineItems,
  IRollupLineItemTable,
  IRollupProject,
  IRollupTag,
  IRollupTagTable,
  ROLLUP_VIEW_TYPES,
} from './rollups.interface';
import { StorageService } from '../../services/storage.service';

export const rollupFeatureKey = 'rollups';

export interface RollupState {
  isLoading: boolean;
  isFetching: boolean;
  projects_list: IRollupProject[];
  line_items_list: IRollupLineItems[];
  tags_list: IRollupTag[];
  accounts_list: IRollupAccount[];
  selectedRollupData: IRollupLineItemTable | IRollupTagTable | IRollupAccountTable;
  selectedRollupViewType: ROLLUP_VIEW_TYPES;
  filters: IRollupFilters;
  showZeroDollarLines: boolean;
}

export const rollupState: EntityAdapter<RollupState> = createEntityAdapter<RollupState>();

export const initialState = rollupState.getInitialState<RollupState>({
  isLoading: true, // general loading for loading screen
  isFetching: true,
  projects_list: [],
  line_items_list: [],
  tags_list: [],
  accounts_list: [],
  selectedRollupViewType: null,
  selectedRollupData: null,
  filters: StorageService.getRollupFilters(),
  showZeroDollarLines: false,
});

export const rollupsReducer = createReducer(
  initialState,
  on(rollupActions.rollupByViewSuccessfullyLoaded, (state, action) => {
    const newState = { ...state, isLoading: false, isFetching: false };
    switch (action.view) {
      case ROLLUP_VIEW_TYPES.PROJECTS:
        return { ...newState, projects_list: action.data as IRollupProject[] };
      case ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS:
        return { ...newState, line_items_list: action.data as IRollupLineItems[] };
      case ROLLUP_VIEW_TYPES.TAGS:
        return { ...newState, tags_list: action.data as IRollupTag[] };
      case ROLLUP_VIEW_TYPES.ACCOUNTS:
        return { ...newState, accounts_list: action.data as IRollupAccount[] };
      default:
        console.warn('rollupByViewSuccessfullyLoaded: unhandled view type');
        return newState;
    }
  }),
  on(rollupActions.isLoading, (state, action) => {
    return { ...state, isFetching: action.isLoading };
  }),
  on(rollupActions.rollupByViewLoadCancelled, (state) => {
    return { ...state, isFetching: false, isLoading: false };
  }),
  on(rollupActions.setSelectedRollup, (state, action) => {
    return { ...state, selectedRollupViewType: action.viewType, selectedRollupData: action.data };
  }),
  on(rollupActions.clearRollups, (_) => {
    StorageService.setDefaultRollupFilters();
    return { ...initialState, filters: StorageService.getRollupFilters() };
  }),
  on(rollupActions.setRollupFilters, (state, action) => {
    return { ...state, filters: { ...state.filters, ...action.filters } };
  }),
  on(rollupActions.toggleShowZeroDollarLines, (state, action) => {
    return { ...state, showZeroDollarLines: action.showZeroDollarLines };
  }),
);
