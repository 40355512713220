<div class="taskForm">
  <form #taskForm="ngForm" class="form w-100 flex flex-col" name="taskForm" role="form">
    <div class="mb-2">
      <label class="text-xs text-shade_3 font-semibold uppercase">Assigned To</label>
      <div
        #optionsTrigger="cdkOverlayOrigin"
        (click)="showDropdown(); input.focus()"
        cdkOverlayOrigin
        class="prime-border relative"
      >
        <ng-container *ngFor="let assignedUser of getAssignedUsers$ | async">
          <span (click)="showDropdown()" class="pr-5 text-shade_2 font-normal font-sm">
            {{ assignedUser.name }}
          </span>
        </ng-container>
        <label
          [hidden]="assignedUsersSearch || (getAssignedUsers$ | async).length"
          class="absolute text-shade_3 text-base top-2.5 font-medium"
          >&#64;name
        </label>
        <input
          #input
          #responsibilityMembers="ngModel"
          (click)="showDropdown()"
          (ngModelChange)="userSearchChange($event)"
          [(ngModel)]="assignedUsersSearch"
          autocomplete="off"
          id="responsibilityMembers"
          name="responsibilityMembers"
          type="text"
        />
      </div>
      <app-options-list-general
        #optionsList
        (showChange)="handleDropdownShowChange($event)"
        [(show)]="isDropdownShown.responsibilityMembers"
        [overlayPositions]="dropdownOverlayPositions"
        [trigger]="optionsTrigger"
      >
        <app-task-users-dropdown
          (selectedOption)="onAssignedMemberOptionSelected($event); optionsList.toggleShow()"
          [selectedMembers]="(getTaskAssignedUsersListData$ | async)?.selectedMembers"
          [usersRelated]="(getTaskAssignedUsersListData$ | async)?.usersRelated"
        ></app-task-users-dropdown>
      </app-options-list-general>
    </div>

    <app-custom-input-overlay-dropdown
      #spectatorOverlay
      (click)="loadUsers()"
      (modelChange)="loadUsers($event)"
      [(model)]="spectatorUsersSearch"
      label="Spectators"
      name="spectators"
      placeholder="@name"
    >
      <app-task-users-dropdown
        (selectedOption)="onSpectatorMemberOptionSelected($event); spectatorOverlay.hideDropdown()"
        [selectedMembers]="(getTaskSpectatorUsersListData$ | async)?.selectedMembers"
        [usersRelated]="(getTaskSpectatorUsersListData$ | async)?.usersRelated"
      ></app-task-users-dropdown>
    </app-custom-input-overlay-dropdown>

    <ng-container *ngFor="let spectator of getSpectatorUsers$ | async">
      <div class="grid mt-2 py-1 gap-2.5 grid-cols-[minmax(100px,_0.5fr)_minmax(90px,_1fr)_auto]">
        <span class="font-semibold text-shade_1">
          <app-faded-text>
            {{ spectator.name }}
          </app-faded-text>
        </span>
        <span class="font-normal text-shade_2">
          <app-faded-text>
            {{ spectator.company_name }}
          </app-faded-text>
        </span>
        <span (click)="removeSpectator(spectator)" class="pointer-cursor text-color_secondary"> remove </span>
      </div>
    </ng-container>

    <div *ngIf="(getSpectatorUsers$ | async).length" class="text-shade_5 w-full border-t mt-6 mb-4"></div>

    <app-hidden-upload-input
      #hiddenFileInput
      (onFilesChange)="registerFileAdded($event)"
      [allowMultipleFiles]="true"
    ></app-hidden-upload-input>

    <app-default-upload-ui
      (openWindow)="hiddenFileInput.openFileUploadWindow()"
      (removeFile)="removeFile($event)"
      (fileDropped)="registerFileAdded($event)"
      [files]="getAllTaskSidebarFiles$ | async"
      class="mb-2"
    ></app-default-upload-ui>

    <div class="mb-2">
      <app-custom-input-overlay-dropdown
        #checkListOverlay
        *ngIf="!isNewChecklistInput"
        [(model)]="model.checklist.name"
        [autoComplete]="false"
        [iconClass]="iconDropdown(checkListOverlay.isDropdownShown)"
        [readonly]="true"
        class="cursor-pointer"
        label="Checklist"
        name="checklist"
        placeholder="Checklist"
      >
        <app-checklist-dropdown
          (selectedOption)="checkListOptionSelected($event); checkListOverlay.hideDropdown()"
          [checkLists]="getAllCheckList$ | async"
        >
        </app-checklist-dropdown>
      </app-custom-input-overlay-dropdown>

      <app-floating-input
        (iconClick)="checkListOptionSelected({ action: SELECTED_OPTION.DESELECT })"
        (modelChange)="checkListNameChange($event)"
        *ngIf="isNewChecklistInput"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: 'icon-add rotate-45 text-color_secondary hover:text-color_reject'
        }"
        [model]="model.checklist.name"
        label="New Checklist Name"
        name="checklistInput"
        placeholder="New Checklist Name"
      ></app-floating-input>
    </div>

    <ng-container *ngFor="let item of model?.checklist?.items; let index = index; trackBy: trackByIndex">
      <app-floating-input
        (iconClick)="removeChecklistItem(item, index)"
        (modelChange)="checkListItemChange($event, index)"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: 'icon-add rotate-45 text-color_secondary hover:text-color_reject'
        }"
        [label]="'Checklist Item ' + (index + 1 | number)"
        [model]="item.name"
        [name]="'checklistItem_' + index"
        [placeholder]="'Checklist Item ' + (index + 1 | number)"
      ></app-floating-input>
    </ng-container>
  </form>

  <span
    (click)="addCheckListItem()"
    [ngClass]="{
      'disabled text-color_disabled p-disabled pointer-events-none': model?.checklist?.id === noCheckList.id
    }"
    class="pointer-cursor text-color_secondary"
    >Add Item</span
  >
</div>
