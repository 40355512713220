import { spendActionTypes } from './spend.actions';
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SPEND_TYPES } from '../../framework/constants/budget.constants';
import {
  ILineItem,
  ISpendLineItemSummary,
  ISpendStoreUpdate,
  SPEND_ERROR_TYPES,
  SpendStoreUpdateTypes,
} from './spend.interfaces';
import { DeepCopyService } from '../../services/deep-copy.service';
import { getAllPossibleYearsFunction, getLineItemPossibleYearsFunction } from './spend.selectors';
import moment from 'moment';
import {
  DISTRIBUTION_TYPES,
  SPEND_DISTRIBUTION_STATE,
  typeToDistProp,
  typeToDurationProp,
  typeToProp,
  typeToStartDateProp,
} from '../../framework/constants/spend.constants';
import lodash from 'lodash';
import { FiscalService } from '../../services/fiscal.service';
import { defaultSpendLineSummary } from './spend.constants';

export const spendFeatureKey = 'spend';

export interface SpendState extends EntityState<ILineItem> {
  selectedYear: number;
  projectStartDate: string;
  selectedSpendType: SPEND_TYPES;
  spendDistributionState: SPEND_DISTRIBUTION_STATE;
  selectedProjectId: number;
  newLineItems: Set<number>; // these exist only locally and should be saved to backend
  modifiedLineItems: Set<number>; // these exist only locally and should be saved to backend
  deletedLineItems: Set<number>; // these exist only locally and should be saved to backend
  lastStoreUpdate: ISpendStoreUpdate; // it is used in Spend component to update only one item at a time
  isLoading: boolean; // shows the loading notification if true
  // the user is able to add more years to the selection list.
  // These are the newly added years which are not present in lineItem budget data.
  newYears: number[];
  // set for add projects, if project has templates then user can't modify budget line item name, and can't add/delete line items
  hasTemplates: boolean;
  spendLineItemSummary: ISpendLineItemSummary;
}

export const spendAdapter: EntityAdapter<ILineItem> = createEntityAdapter<ILineItem>();

function getSelectedYearFunc(lineItems: ILineItem[], currentlySelectedYear: number): number {
  const possibleYears = getLineItemPossibleYearsFunction(lineItems);
  if (possibleYears[0] === undefined) {
    return currentlySelectedYear;
  }
  let selectedYear;
  if (!possibleYears.includes(currentlySelectedYear)) {
    if (possibleYears.includes(FiscalService.fiscalYear)) {
      selectedYear = FiscalService.fiscalYear;
    } else {
      selectedYear = possibleYears[0];
    }
  } else {
    selectedYear = currentlySelectedYear;
  }
  return selectedYear;
}

export const spendInitialState: SpendState = spendAdapter.getInitialState({
  selectedYear: new Date().getFullYear(), // todo: it should be current fiscal year
  projectStartDate: undefined,
  selectedSpendType: SPEND_TYPES.BUDGET,
  selectedProjectId: null,
  spendDistributionState: SPEND_DISTRIBUTION_STATE.DEFAULT,
  newLineItems: new Set(),
  modifiedLineItems: new Set(),
  deletedLineItems: new Set(),
  lastStoreUpdate: { type: SpendStoreUpdateTypes.NONE, lineId: null },
  isLoading: false,
  newYears: [],
  hasTemplates: false,
  spendLineItemSummary: { ...defaultSpendLineSummary },
});

export const spendReducer = createReducer(
  spendInitialState,
  on(spendActionTypes.setLineItems, (state, action) => {
    const selectedYear = getSelectedYearFunc(Object.values(action.lineItems), state.selectedYear);
    return spendAdapter.setAll(action.lineItems, {
      ...state,
      selectedYear,
      newLineItems: new Set<number>(),
      modifiedLineItems: new Set<number>(),
      deletedLineItems: new Set<number>(),
      selectedProjectId: action.projectId,
      lastStoreUpdate: { type: SpendStoreUpdateTypes.SET_ALL, lineId: null },
      isLoading: false,
      spendDistributionState: SPEND_DISTRIBUTION_STATE.DEFAULT,
      newYears: [],
    });
  }),

  on(spendActionTypes.addLineItem, (state, action) => {
    let newSelectedYear;
    const lineItem = { ...action.lineItem };
    if (Object.keys(state.entities).length === 0) {
      newSelectedYear = getSelectedYearFunc([lineItem], state.selectedYear);
    }
    const id = Object.values(state.entities).reduce((acc, curr) => {
      if (curr.id > acc) {
        return curr.id;
      }
      return acc;
    }, 0);
    // the new id will be bigger than the biggest so order is kept
    lineItem.id = id + 1; // it will be removed when saved to backend - only for local use
    const newLineItems = new Set([...state.newLineItems]);
    newLineItems.add(lineItem.id);
    const newState: SpendState = {
      ...state,
      newLineItems,
      selectedYear: newSelectedYear ? newSelectedYear : state.selectedYear,
      lastStoreUpdate: { type: SpendStoreUpdateTypes.ADD, lineId: lineItem.id },
      isLoading: false,
    };
    return spendAdapter.addOne(lineItem, newState);
  }),

  on(spendActionTypes.updateLineItem, (state, action) => {
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    if (!state.newLineItems.has(action.lineItem.id)) {
      modifiedLineItems.add(action.lineItem.id);
    }
    const newState: SpendState = {
      ...state,
      modifiedLineItems,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.UPDATE,
        lineId: action.lineItem.id,
      },
      isLoading: false,
    };
    const lineItem = { ...action.lineItem };
    const lineDistributionType = lineItem[typeToDistProp[state.selectedSpendType]];
    if (lineDistributionType === DISTRIBUTION_TYPES.MANUAL) {
      lineItem[typeToDurationProp[state.selectedSpendType]] = getItemDuration(action, state);
    }

    const stateAfterUpdate = spendAdapter.updateOne(
      { id: lineItem.id, changes: lineItem },
      newState,
    );

    const selectedYear = getSelectedYearFunc(
      Object.values(stateAfterUpdate.entities),
      stateAfterUpdate.selectedYear,
    );
    return {
      ...stateAfterUpdate,
      selectedYear,
    };
  }),

  on(spendActionTypes.deleteLineItem, (state, action) => {
    // if an item is going to be deleted, it's useless to create/update it
    const newLineItems = new Set([...state.newLineItems]);
    const modifiedLineItems = new Set([...state.modifiedLineItems]);
    const deletedLineItems = new Set([...state.deletedLineItems]);

    if (newLineItems.has(action.id)) {
      newLineItems.delete(action.id);
    } else {
      deletedLineItems.add(action.id);
    }

    if (modifiedLineItems.has(action.id)) {
      modifiedLineItems.delete(action.id);
    }

    const selectedYear = getSelectedYearFunc(Object.values(state.entities), state.selectedYear);

    const newState: SpendState = {
      ...state,
      deletedLineItems,
      newLineItems,
      modifiedLineItems,
      selectedYear,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.DELETE,
        lineId: action.id,
      },
      isLoading: false,
    };
    return spendAdapter.removeOne(action.id, newState);
  }),

  on(spendActionTypes.setSelectedYear, (state, action) => {
    return {
      ...state,
      selectedYear: action.year,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.FILTER_CHANGE,
        lineId: null,
      },
    };
  }),

  on(spendActionTypes.setSelectedSpendType, (state, action) => {
    return {
      ...state,
      selectedSpendType: action.spendType,
      lastStoreUpdate: {
        type: SpendStoreUpdateTypes.FILTER_CHANGE,
        lineId: null,
      },
    };
  }),
  on(spendActionTypes.setIsLoading, (state, action) => {
    const isLoading = action.isLoading;
    if (isLoading !== !!isLoading) {
      // when isLoading is null/undefined
      return state;
    }
    return { ...state, isLoading };
  }),

  on(spendActionTypes.setProjectStartDate, (state, action) => {
    return {
      ...state,
      projectStartDate: action.projectStartDate,
    };
  }),

  on(spendActionTypes.setSelectedProjectIdFromAddEditProject, (state, action) => {
    return {
      ...state,
      selectedProjectId: action.id,
    };
  }),

  on(spendActionTypes.setSpendDistributionState, (state, action) => {
    return {
      ...state,
      spendDistributionState: action.spendDistributionState,
    };
  }),
  on(spendActionTypes.clearMonths, (state, action) => {
    // sets all values to 0 in the current selected year and spend type
    if (state.selectedSpendType === SPEND_TYPES.ACTUALS) {
      // cannot change actuals
      return state;
    }
    const entities: { [index: string]: ILineItem } = DeepCopyService.deepCopy(state.entities);
    const type = typeToProp[state.selectedSpendType];
    Object.values(entities).forEach((item) => {
      const budgetToChange = item.budget.find((budget) => budget.year === state.selectedYear);
      if (!budgetToChange) {
        // means the line item has no data for that year
        return;
      }
      Object.keys(budgetToChange[type]).forEach((monthIndex) => {
        budgetToChange[type][monthIndex] = 0;
      });
      const distType = typeToDistProp[state.selectedSpendType];
      item[distType] = DISTRIBUTION_TYPES.MANUAL;
    });
    return {
      ...state,
      entities,
      lastStoreUpdate: { type: SpendStoreUpdateTypes.SET_ALL, lineId: null },
    };
  }),
  on(spendActionTypes.clearAfterSave, (state, action) => {
    const newState = { ...state };
    newState.isLoading = false;
    newState.newLineItems.clear();
    newState.modifiedLineItems.clear();
    newState.deletedLineItems.clear();
    // add id's to store again with http errors
    action.errors.forEach((err) => {
      switch (err.errorType) {
        case SPEND_ERROR_TYPES.NEW:
          newState.newLineItems.add(err.id);
          break;
        case SPEND_ERROR_TYPES.MODIFIED:
          newState.modifiedLineItems.add(err.id);
          break;
        case SPEND_ERROR_TYPES.DELETED:
          newState.deletedLineItems.add(err.id);
          break;
      }
    });

    newState.lastStoreUpdate = {
      type: SpendStoreUpdateTypes.SAVE_BACKEND,
      lineId: null,
    };
    return newState;
  }),

  on(spendActionTypes.addNewYear, (state, action) => {
    // adds a year to the selectable list
    const allYears = getAllPossibleYearsFunction(Object.values(state.entities), state.newYears);
    const newYears = [...state.newYears];
    newYears.push(allYears[allYears.length - 1] + 1);
    return {
      ...state,
      selectedYear: newYears ? newYears[newYears.length - 1] : state.selectedYear,
      newYears,
    };
  }),

  on(
    spendActionTypes.setHasProjectTemplateFromAddEditProject,
    spendActionTypes.setHasProjectTemplateFromViewProject,
    (state, action) => {
      return {
        ...state,
        hasTemplates: action.hasTemplates,
      };
    },
  ),
  on(spendActionTypes.loadSpendLineItemSummary, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...defaultSpendLineSummary, isLoaded: false },
    };
  }),
  on(spendActionTypes.spendLineItemSummaryLoaded, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...action.summary, isLoaded: true },
    };
  }),
  on(spendActionTypes.clearSpendLineItemSummary, (state, action) => {
    return {
      ...state,
      spendLineItemSummary: { ...defaultSpendLineSummary },
    };
  }),
);

function getItemDuration(action, state: SpendState): number {
  const startMonth = moment(action.lineItem[typeToStartDateProp[state.selectedSpendType]]);

  const itemLimits = {
    startMonth: -1,
    endMonth: -1,
    startYear: -1,
    endYear: -1,
  };

  action.lineItem.budget.forEach((budget) => {
    const budgetData = Object.values(budget[typeToProp[state.selectedSpendType]]);
    const isSameOrAfterLineStart = (month) =>
      budget.year > startMonth.year() ||
      (budget.year === startMonth.year() && startMonth.month() <= month);
    if (itemLimits.startMonth < 0) {
      itemLimits.startMonth = lodash.findIndex(
        budgetData,
        (bud, monthIndex) => bud !== 0 && isSameOrAfterLineStart(monthIndex),
      );
      itemLimits.startYear = budget.year;
    }
    const foundLastMonthIndex = lodash.findLastIndex(
      budgetData,
      (bud, monthIndex) => bud !== 0 && isSameOrAfterLineStart(monthIndex),
    );

    if (foundLastMonthIndex >= 0) {
      itemLimits.endMonth = foundLastMonthIndex;
      itemLimits.endYear = budget.year;
    }
  });

  if (itemLimits.startMonth < 0 && itemLimits.endMonth < 0) {
    return 1;
  }

  if (itemLimits.endMonth < 0) {
    itemLimits.endMonth = itemLimits.startMonth;
  }

  // add one because index 0
  itemLimits.startMonth += 1;
  itemLimits.endMonth += 1;
  const duration =
    moment(`${itemLimits.endYear}-${itemLimits.endMonth}-01`, 'YYYY-MM-DD').diff(
      moment(`${itemLimits.startYear}-${itemLimits.startMonth}-01`, 'YYYY-MM-DD'),
      'month',
    ) + 1;
  return Math.abs(duration);
}

export const { selectAll, selectIds, selectEntities } = spendAdapter.getSelectors();
