import { Pipe, PipeTransform } from '@angular/core';
import { IPermissions } from '../../store/documents/documents.reducer';
import { PERMISSION_LITERALS } from '../../framework/constants/documents.constants';

@Pipe({
  name: 'permissionToText',
  standalone: true,
})
export class PermissionToTextPipe implements PipeTransform {
  transform(permissions: IPermissions | undefined): string {
    if (!permissions) {
      return '';
    }

    if (
      permissions[PERMISSION_LITERALS.CAN_MOVE] === 0 &&
      permissions[PERMISSION_LITERALS.CAN_RENAME] === 0 &&
      permissions[PERMISSION_LITERALS.CAN_DELETE] === 0
    ) {
      return `Cannot Be Edited or Deleted`;
    }
    return '';
  }
}
