<div class="bid-container">
  <div class="stats" [ngClass]="{ 'contractor-view': !user.isManager }">
    <div class="wealth-info report">
      <div class="left">
        <div class="title">SCHEDULED VISITS</div>
        <div class="stat-number">{{ biddingVisitsNr }}</div>
      </div>
      <div class="right icon-container">
        <span class="icon-scheduled-visits"></span>
      </div>
    </div>

    <div class="wealth-info report">
      <div class="left">
        <div class="title">PROJECTS BIDDING</div>
        <div class="stat-number">{{ biddingNr }}</div>
      </div>
      <div class="right icon-container">
        <span class="icon-projects"></span>
      </div>
    </div>

    <div class="wealth-info report">
      <div class="left">
        <div class="title">PROPOSALS SUBMITTED</div>
        <div class="stat-number">{{ bidSubmitedNr }}</div>
      </div>
      <div class="right icon-container">
        <span class="icon-gavel"></span>
      </div>
    </div>

    <div class="wealth-info report">
      <div class="left">
        <div class="title">PENDING PROPOSALS</div>
        <div class="stat-number">{{ biddingPending | money: 'positiveComa' }}</div>
      </div>
      <div class="right icon-container">
        <span class="icon-pending-proposals"></span>
      </div>
    </div>

    <div class="wealth-info report">
      <div class="left">
        <div class="title">AWARDED PROJECTS</div>
        <div class="stat-number">{{ biddingAwarded | money: 'positiveComa' }}</div>
      </div>
      <div class="right icon-container">
        <span class="icon-signature"></span>
      </div>
    </div>
  </div>
</div>
