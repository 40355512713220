import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DriveApiService } from '../../../services/drive-api.service';
import { NotificationsService } from '../../../services/notifications.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { DRIVE_ITEM_TYPES, SHARE_PERMISSIONS } from '../../constants/documents.constants';
import { DriveService } from '../../../services/drive.service';
import { DocumentItem } from '../../../store/documents/documents.reducer';
import { CurrentUserService } from '../../../services/current-user.service';
import { AccessibleUser, SharedWithUser } from '../../interfaces/User.interface';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { documentActionTypes } from '../../../store/documents/documents.actions';
import { projectDocumentActionTypes } from '../../../store/projectDocuments/projectDocuments.actions';
import { forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgScrollbar,
    DropdownComponent,
    FloatingInputComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    FadedTextComponent,
    MatButton,
  ],
})
export class ShareDocumentComponent implements OnInit {
  @ViewChild('emailInput') emailInput;

  @Input() set data(value) {
    if (!value.item) {
      return;
    }
    this.projectId = value.projectId;
    this.item = value.item;
    this.currentAccessors = [...this.item.shared_with];
    this.currentAccessors.push(
      ...this.item.bundle_links.map((bundle) => ({
        ...bundle,
        id: this.currentAccessEmailIndex--,
        name: bundle.email,
        permissions: 'read',
        is_bundle: true,
      })),
    );
    this.itemType = this.driveService.isFolder(this.item)
      ? DRIVE_ITEM_TYPES.FOLDER
      : DRIVE_ITEM_TYPES.FILE;

    if (this.itemType === DRIVE_ITEM_TYPES.FOLDER) {
      this.selectableRights.push({
        value: SHARE_PERMISSIONS.READ_UPLOAD,
        label: 'Read & Upload Access',
      });
    }
  }

  item: DocumentItem;
  itemType: DRIVE_ITEM_TYPES;

  isLoading = false;

  participantsControlModel = [];
  showEmailInput = false;
  emailParticipantIndex = -1; // it is lowered by 1 on every email added
  currentAccessEmailIndex = -1; // it is lowered by 1 on every currently shared with email
  emailEntered = false;
  emailControl = new UntypedFormControl('', [Validators.email]);

  accessibleUsers: AccessibleUser[] = [];

  selectedRight = SHARE_PERMISSIONS.READ_ONLY;
  SHARE_PERMISSIONS = SHARE_PERMISSIONS;
  DRIVE_ITEM_TYPES = DRIVE_ITEM_TYPES;

  currentAccessors: SharedWithUser[] = [];

  projectId: number;

  constructor(
    private driveApi: DriveApiService,
    private notif: NotificationsService,
    private barState: InteractionBarStateService,
    private driveService: DriveService,
    private cdr: ChangeDetectorRef,
    private userService: CurrentUserService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.loadParticipants();
  }

  async loadParticipants() {
    try {
      this.isLoading = true;
      this.accessibleUsers = await this.userService.getAccessibleUsers();
      // show only the users who currently don't have access (file/folder isn't shared with it)
      this.accessibleUsers = this.accessibleUsers.filter(
        (user) => !this.currentAccessors.map((a) => a.id).includes(user.id),
      );
      // if drive is accessed from view project then show only the project participants
      if (this.projectId) {
        this.accessibleUsers = this.accessibleUsers.filter((user) =>
          user.project_ids.includes(this.projectId),
        );
      }

      const manualEmail: AccessibleUser = {
        id: null,
        email: 'Enter email manually',
        name: 'Enter email manually',
        project_ids: [],
      };

      this.accessibleUsers = [manualEmail, ...this.accessibleUsers];
    } catch (ex) {
      this.notif.showError('Error while getting participants.');
    } finally {
      this.isLoading = false;
    }
  }

  // todo please refactor me I can't make it more nice in this short time :(
  /**
   * this function will check if provided emails are SK app registered users or not
   */
  checkInternEmails(selected): Observable<any> {
    const promises = [];
    const emails = selected
      .filter((id) => id < 0)
      .map((id) => this.accessibleUsers.find((u) => u.id === id)?.email);

    if (!emails.length) {
      return of(true);
    }

    emails.forEach((email) => {
      promises.push(this.userService.checkEmailInUsers(email));
    });

    return forkJoin(promises);
  }

  async share() {
    const selected: number[] = [...this.participantsControlModel];

    if (!selected || selected?.length === 0) {
      this.notif.showError('Please select at least one member to share with.');
      return;
    }

    const result = this.checkInternEmails(selected);
    result.pipe(take(1)).subscribe(
      () => {
        this.save(selected);
      },
      () => {
        this.saveWithPopup(selected);
      },
    );
  }

  async save(selected) {
    this.notif.showLoading();

    try {
      if (this.itemType === DRIVE_ITEM_TYPES.FILE) {
        await this.driveApi.shareFile({
          emails: selected
            .filter((id) => id < 0)
            .map((id) => this.accessibleUsers.find((u) => u.id === id)?.email),
          user_drive_file_id: this.item.id,
          permission: this.selectedRight,
          user_ids: selected.filter((id) => id > 0),
        });
      } else {
        await this.driveApi.shareFolder({
          emails: selected
            .filter((id) => id < 0)
            .map((id) => this.accessibleUsers.find((u) => u.id === id)?.email),
          user_drive_folder_id: this.item.id,
          permission: this.selectedRight,
          user_ids: selected.filter((id) => id > 0),
        });
      }
      const documentType = this.itemType.charAt(0).toUpperCase() + this.itemType.slice(1);
      this.notif.showSuccess(documentType + ' has been shared.');
      this.refresh();
    } catch (e) {
      if (e?.error?.message) {
        setTimeout(() => {
          this.notif.showError(e.error.message);
        }, 1000);
      } else {
        this.notif.showError('Could not share!');
      }
    } finally {
      this.barState.close();
    }
  }

  // add email address to selected participants
  async addEmailToSelectOptions() {
    if (this.emailControl.invalid || this.emailControl.value === '' || this.emailEntered) {
      this.notif.showError('Please enter a valid email address.');
      return;
    }
    this.emailEntered = true;
    const email = this.emailControl.value;
    this.showEmailInput = false;
    this.emailControl.setValue('');
    this.emailEntered = false;

    this.accessibleUsers.push({
      id: this.emailParticipantIndex,
      email,
      name: email,
      project_ids: [],
    });
    this.participantsControlModel = [...this.participantsControlModel, this.emailParticipantIndex];
    this.emailParticipantIndex--;

    this.showEmailInput = false;
  }

  addManualEmailInput() {
    this.showEmailInput = !this.showEmailInput;
    // change detection is needed to access the element right after the show variable changes
    this.cdr.detectChanges();
    this.emailInput.nativeElement.focus();
  }

  async removeAccessor(accessor: SharedWithUser) {
    const doRemove = await this.notif.showPopup('Do you want to remove access?');
    if (!doRemove) {
      return;
    }
    this.notif.showLoading();
    try {
      if (accessor?.file?.id) {
        await this.driveApi.deleteFileShare({ file_id: accessor.file.id, email: accessor.email });
      } else {
        await this.driveApi.deleteFolderShare({
          folder_id: accessor.folder.id,
          email: accessor.email,
        });
      }

      // if (this.itemType === DRIVE_ITEM_TYPES.FILE) {
      //   await this.driveApi.deleteFileShare({ file_id: this.item.id, email: accessor.email });
      // } else {
      //   await this.driveApi.deleteFolderShare({ folder_id: this.item.id, email: accessor.email });
      // }
      this.notif.showSuccess('Share revoked.');
      if (this.projectId) {
        this.store.dispatch(projectDocumentActionTypes.reloadProjDocs());
      } else {
        this.store.dispatch(documentActionTypes.reload());
      }

      this.currentAccessors = this.currentAccessors.filter((a) => a.id !== accessor.id);
      this.accessibleUsers.push(accessor);
    } catch (e) {
      this.notif.showError('Error while revoking share.');
    }
  }

  async copyEmailLink(accessor) {
    await navigator.clipboard.writeText(`${location.origin}/webapp/documents/${accessor.link}`);
    this.notif.showSuccess('Link copied to clipboard');
  }

  private saveWithPopup(selected) {
    this.notif
      .showPopup(
        'THE EMAIL ENTERED IS NOT ASSOCIATED WITH ANY SKILLHOP ACCOUNT. READ ONLY LINK WILL BE SENT. \n' +
          ' PLEASE NOTE, RECIPIENT WILL BE ABLE TO SHARE LINK WITH ANYONE. SEND ANYWAY?',
      )
      .then((res) => {
        if (res) {
          this.save(selected);
        }
      });
  }

  // note setTimeout is needed to show the message
  // otherwise notif component should be refactored to handle these situations...
  private refresh(): void {
    setTimeout(() => {
      if (this.projectId) {
        this.store.dispatch(projectDocumentActionTypes.reloadProjDocs());
      } else {
        this.store.dispatch(documentActionTypes.reload());
      }
    }, 1000);
  }

  selectableRights: { value: SHARE_PERMISSIONS; label: string }[] = [
    { value: SHARE_PERMISSIONS.READ_ONLY, label: 'Read only' },
  ];
}
