import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DocumentEditorContainerComponent,
  DocumentEditorContainerModule,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { NotificationsService } from '../../../../services/notifications.service';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  DOCUMENT_OPEN_URL,
  DOCUMENT_SERVICE_URL,
} from '../../../../framework/constants/syncfusion.constants';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [ToolbarService],
  standalone: true,
  imports: [DocumentEditorContainerModule],
})
export class DocumentComponent implements OnInit {
  @ViewChild('wordDoc') wordObj: DocumentEditorContainerComponent;
  private _wordFile: ArrayBuffer;
  fileObservable = new Subject<Blob>();
  DOCUMENT_SERVICE_URL = DOCUMENT_SERVICE_URL;
  @Input() set wordFile(value) {
    if (value) {
      this._wordFile = value;
    }
  }

  get wordFile() {
    return this._wordFile;
  }

  constructor(
    private http: HttpClient,
    private notif: NotificationsService,
  ) {}
  ngOnInit() {}

  documentCreated(event) {
    if (!this.wordFile) {
      return;
    }
    this.notif.showLoading();
    const blob = new Blob([this.wordFile]);
    const file = new File([blob], 'sample.docx');
    const formData = new FormData();
    formData.append('files', file);
    this.http
      .post(DOCUMENT_OPEN_URL, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        catchError((err) => {
          this.notif.showError('An error occurred during file processing.');
          return throwError(err);
        }),
      )
      .subscribe(
        (sfdtJsonEvent: HttpEvent<any>) => {
          console.log(sfdtJsonEvent);

          this.notif.showLoading(`We're getting your file ready.`);

          if (sfdtJsonEvent.type === HttpEventType.Response) {
            this.wordObj.documentEditor?.open(JSON.stringify(sfdtJsonEvent.body));
            this.notif.close();
          }
        },
        (err) => {
          console.warn('Processing error:', err);
        },
      );
  }

  /**
   * returns an observable of the Word file blob
   */
  getFile() {
    this.wordObj.documentEditor.saveAsBlob('Docx').then((blob) => {
      this.fileObservable.next(blob);
    });
    return this.fileObservable.asObservable();
  }
}
