import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MatCalendar } from '@angular/material/datepicker';
import moment from 'moment';
import { ProjectStateService } from '../../../services/project-state.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { ContractorsApiService } from '../../../services/contractors-api.service';
import { NotificationsService } from '../../../services/notifications.service';
import { NgClass, NgIf } from '@angular/common';
import { CalendarEventsDirective } from '../../../directives/calendar-events.directive';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [MatCalendar, CalendarEventsDirective, NgClass, NgIf],
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendar', { static: true }) calendar: MatCalendar<any>;

  @Input() eventType = 'by-project';
  // set eventType(eventType) {
  //   if (eventType) {
  //     this._eventType = eventType;
  //     this.refresh();
  //   }
  // };

  @Output() dateChange = new EventEmitter();
  @Output('events') eventsEmitter = new EventEmitter();
  selectedDate = null;
  header = HeaderComponent;
  events = [];
  isLoading = true;

  constructor(
    private projectState: ProjectStateService,
    private projectApi: ProjectApiService,
    private userService: CurrentUserService,
    private contractorApi: ContractorsApiService,
    private notif: NotificationsService,
    private el: ElementRef,
  ) {
    this.setCalendarToday();
  }

  ngOnInit() {
    this.refresh();
  }

  clean() {
    this.events = [];
  }

  refresh(emitPreChange = false) {
    this.isLoading = this.events.length === 0;

    // console.log(this.eventType);
    switch (this.eventType) {
      case 'by-project':
        this.projectState.getVisit().then(
          (schedule) => {
            // setTimeout(() => {
            this.projectState.getScheduleVisits().then(
              (visits) => {
                this.createVisitObj(schedule, visits);
              },
              (err) => {
                console.warn('this.projectState.getProjectVisits() err: ', err);
                this.isLoading = false;
              },
            );
            // }, 200);
          },
          (err) => {
            this.isLoading = false;
            console.warn('this.projectState.getSchedule() err: ', err);
          },
        );
        break;
      case 'by-user':
        this.projectApi.getScheduleVisitsUser(this.userService.data.id).then(
          (visit) => {
            const schedules = [];
            visit.forEach((schedule) => {
              if (schedule.visit) {
                schedules.push(schedule.visit);
              }
            });

            this.createVisitObj(schedules, visit);
            // console.log(visit);
          },
          (err) => {
            this.isLoading = false;
          },
        );
        break;
      case 'by-schedule':
        this.contractorApi.getVisits().then(
          (visit) => {
            const schedules = [];
            visit.forEach((schedule) => {
              if (schedule.visit) {
                schedules.push(schedule.visit);
              }
            });

            this.createVisitObj(schedules, visit);
            // console.log(visit);
          },
          (err) => {
            this.isLoading = false;
          },
        );
        break;
    }
  }

  createVisitObj(schedule, visits) {
    for (const sch of schedule) {
      sch.status = 'none';
    }

    for (const visit of visits) {
      let assignedVisit = schedule.filter((sch) => sch.id === visit.visit_id);

      if (assignedVisit.length > 0) {
        assignedVisit[0].status = 'visit';
        assignedVisit[0].visitId = visit.id;
        assignedVisit[0].contractorId = visit.contractor_id;
        // console.log(visit.id);
      } else {
      }
    }

    this.events = schedule;
    this.eventsEmitter.emit(schedule);
    if (this.selectedDate != null) {
      this.dateChange.emit(new Date(this.selectedDate));
    }

    this.isLoading = false;
  }

  onSelect(e) {
    // let offset = e.getTimezoneOffset();
    // if (offset < 0) {
    //   offset = 60;
    // }
    // e.setTime(e.getTime() + 60 * 1000);
    this.selectedDate = e;
    this.dateChange.emit(e);
  }

  private setCalendarToday() {
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    this.onSelect(today.toISOString());
  }
}
