<div class="webapp-page-container">
  <div class="text-wrapper">
    <div class="title-page">Admin Panel</div>
  </div>

  <div class="search-bar-admin">
    <div class="search-container">
      <app-search-bar (searchEvent)="registerSearch($event)"></app-search-bar>
    </div>
  </div>

  <div [ngClass]="{ 'disp-none': users.length == 0 }" class="content">
    <cdk-virtual-scroll-viewport class="table-wrapper" itemSize="50">
      <div
        [ngClass]="{ 'with-verify-btn': !item?.email_verified_at }"
        *cdkVirtualFor="let item of users"
        class="user-row"
      >
        <div>
          <!--          <span>{{item.id}}</span>-->
          {{ moment.utc(item.last_login).local().format('MM/DD/YYYY h:mm a') }}
        </div>
        <div>{{ item.username }}</div>
        <div>
          <span *ngIf="item.type">
            <span *ngIf="item.type.id == 1">Contractor</span>
            <span *ngIf="item.type.id != 1"> Manager </span>
          </span>
        </div>
        <div *ngIf="item.id != editId" class="user-editable">
          <div>{{ item.last_name }}</div>
          <div>{{ item.first_name }}</div>
          <div>{{ item.company_name }}</div>
          <div>{{ item.phone }}</div>
          <div>{{ item.email }}</div>
          <!--          <div>-->
          <div *ngIf="!item?.is_approved">Not Accepted</div>
          <div *ngIf="!!item?.is_approved">Accepted</div>

          <!--          <div>-->
        </div>

        <div *ngIf="item.id == editId" [formGroup]="userForm" class="user-editable">
          <mat-form-field>
            <input formControlName="last_name" matInput />
          </mat-form-field>
          <mat-form-field>
            <input formControlName="first_name" matInput />
          </mat-form-field>
          <mat-form-field>
            <input formControlName="company_name" matInput />
          </mat-form-field>
          <mat-form-field>
            <input formControlName="phone" matInput />
          </mat-form-field>
          <mat-form-field>
            <input formControlName="email" matInput />
          </mat-form-field>
          <mat-form-field>
            <input formControlName="password" placeholder="New Password" matInput />
          </mat-form-field>
        </div>

        <button
          matTooltip="approve account"
          (click)="acceptUser(item)"
          *ngIf="!item.is_approved"
          class="btn-small bg-accept"
          mat-button
        >
          Accept
        </button>
        <button
          matTooltip="verify email address manually"
          (click)="verifyEmail(item)"
          *ngIf="!item?.email_verified_at"
          class="btn-small bg-accent"
          mat-button
        >
          Verify
        </button>
        <button (click)="deleteUser(item)" class="btn-small bg-reject" mat-button>Delete</button>
        <button (click)="saveUser(item)" *ngIf="item.id == editId" class="btn-small bg-primary" mat-button>Save</button>
        <button (click)="editUser(item)" *ngIf="item.id != editId" class="btn-small bg-primary" mat-button>Edit</button>
        <!--        <div>{{item.username}}</div>-->
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div *ngIf="users.length == 0" class="content">
    {{ statusMessage }}
  </div>
</div>
