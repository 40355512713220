import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NotificationsService } from '../../services/notifications.service';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { ProjectSpendService } from '../../services/project-spend.service';
import { MatButton } from '@angular/material/button';
import { FloatingTextareaComponent } from '../inputs/floating-textarea/floating-textarea.component';
import { MatRipple } from '@angular/material/core';
import { DropdownComponent } from '../inputs/dropdown/dropdown.component';
import { NgClass, NgIf } from '@angular/common';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';

interface Items {
  budget: any;
  id: number | string;
  name: string;
}

/**
 * @deprecated delete
 */
@Component({
  selector: 'app-budget-adjustment-view',
  templateUrl: './budget-adjustment-view.component.html',
  styleUrls: ['./budget-adjustment-view.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FloatingInputComponent,
    NgClass,
    DropdownComponent,
    NgIf,
    MatRipple,
    FloatingTextareaComponent,
    MatButton,
  ],
})
export class BudgetAdjustmentViewComponent implements OnInit {
  EXTERNAL_ADJUSTMENT = 'External Adjustment';
  budgetAdjustmentForm = new UntypedFormGroup({
    value: new FormControl('', [Validators.required]),
    from_item_id: new FormControl('', [Validators.required]),
    to_item_id: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
  });
  isLoading: any;
  toItems: Items[];
  fromItems: Items[];
  isNewItemInput = false;

  @Input() projectId;
  @Input() hasBudgetTemplate;

  ADD_NEW_ITEM_ID = null;

  @Input() set lineItems(originalItems: Items[]) {
    if (originalItems) {
      this.fromItems = [
        { id: this.EXTERNAL_ADJUSTMENT, name: this.EXTERNAL_ADJUSTMENT, budget: null },
        ...originalItems,
      ];

      const toItems = [...originalItems];
      toItems.push({ id: this.ADD_NEW_ITEM_ID, name: 'Add new', budget: null });
      this.toItems = toItems;
    }
  }

  constructor(
    private projectSpendService: ProjectSpendService,
    private notif: NotificationsService,
    private interactionBar: InteractionBarStateService,
  ) {}

  ngOnInit(): void {}

  save() {
    if (!this.validAdjustmentNumber()) {
      this.notif.showError('Negative numbers are allowed only from External Adjustment!');
      return;
    }
    this.budgetAdjustmentForm.valid
      ? this.createBudgetAdjustment()
      : this.budgetAdjustmentForm.markAllAsTouched();
  }

  private createBudgetAdjustment() {
    this.notif.showLoading();
    const rawData = this.budgetAdjustmentForm.getRawValue();
    rawData.project_id = this.projectId;
    rawData.value = this.projectSpendService.formatString(rawData.value);
    // "from_item_id" has "External Adjustment" value in some cases
    rawData.is_external_adjustment = rawData.from_item_id === this.EXTERNAL_ADJUSTMENT;
    rawData.from_item_id = rawData.is_external_adjustment
      ? rawData.to_item_id
      : rawData.from_item_id;
    if (this.isNewItemInput) {
      rawData.to_item_name = rawData.to_item_id;
      delete rawData.to_item_id;
    }

    if (this.isNewItemInput && rawData.is_external_adjustment) {
      delete rawData.from_item_id;
    }

    this.projectSpendService.adjustBudget(rawData).then(
      (data) => {
        console.log(data);
        this.notif.showSuccess('Budget adjusted!');
        setTimeout(() => {
          this.interactionBar.close();
        }, 500);
      },
      (err) => this.notif.showError(err),
    );
  }

  private validAdjustmentNumber() {
    const form = this.budgetAdjustmentForm.getRawValue();
    if (form.value > 0) {
      return true;
    }
    return form.value < 0 && form.from_item_id === this.EXTERNAL_ADJUSTMENT;
  }

  switchInput() {
    this.isNewItemInput = !this.isNewItemInput;
    this.budgetAdjustmentForm.get('to_item_id').reset();
  }

  onToItemChange(selectedId: number) {
    if (selectedId === null) {
      this.switchInput();
    }
  }
}
