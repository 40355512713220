import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export enum COMMITMENT_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  REVIEW = 'review',
  DRAFT = 'draft',
}

/**
 * Represents 1 gigabyte (GB) when measured in binary
 * In decimal notation, 1 GB is equivalent to 1,073,741,824 bytes
 * In some contexts, powers of 2 (such as 1024) are used, leading to the rounder number 1,048,576,000
 */
const ONE_GB = 1048576000; // 1Gb in bytes
export const MAX_FILE_UPLOAD_LIMIT = ONE_GB * 4; // 4Gb in bytes

export function getErrorMessage(param: AbstractControl) {
  return param.hasError('required')
    ? 'Required'
    : param.hasError('email')
      ? 'Invalid email'
      : param.hasError('minlength')
        ? 'Too short'
        : param.hasError('matDatepickerMin')
          ? `Date must be on or after ${moment(param.errors.matDatepickerMin.min).format('MM/DD/YYYY')}`
          : param.hasError('matDatepickerFilter')
            ? 'Invalid date'
            : '';
}
