import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '../../../../services/notifications.service';
import { ProjectApiService } from '../../../../services/project-api.service';
import { PROJECTS_TABLE_VIEW, ProjectTableDataContractor } from '../projects.interface';
import { NotificationsHandler } from '../../../../framework/interfaces/NotificationsHandler';
import { IntercomService } from '../../../../services/intercom.service';
import { ProjectsTableComponent } from '../../../../framework/projects-table/projects-table.component';
import { ProjectInfoContractorComponent } from '../bidding/project-info-contractor.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-projects-contractor',
  templateUrl: './projects-contractor.component.html',
  styleUrls: ['./projects-contractor.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ProjectInfoContractorComponent, ProjectsTableComponent],
})
export class ProjectsContractorComponent extends NotificationsHandler implements OnInit, OnDestroy {
  projects: ProjectTableDataContractor[] = [];
  // todo: extend and move to project constants
  dataKeys = [
    'title',
    'company_name',
    'start_date',
    'approved',
    'proposal',
    'total_committed',
    'actuals',
    'complete',
    'statusId',
  ];
  VIEWS = PROJECTS_TABLE_VIEW;
  isLoading = false;
  constructor(
    protected notif: NotificationsService,
    private projectApi: ProjectApiService,
    private intercomService: IntercomService,
  ) {
    super(notif);
  }

  async ngOnInit() {
    this.openLoading();
    const projects = await this.projectApi.getProjects();
    this.projects = projects;
    this.intercomService.updateIntercom(projects);
    this.notif.close();
  }

  ngOnDestroy() {}
}
