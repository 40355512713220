import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

@Pipe({
  name: 'dayjsFormatter',
  standalone: true,
})
export class DayjsFormatterPipe implements PipeTransform {
  transform(value: string, template: string = 'MM/DD/YYYY'): string {
    if (!value) {
      return null;
    }

    dayjs.extend(localizedFormat);

    return dayjs(value).format(template);
  }
}
