import { Component, Input, OnInit } from '@angular/core';
import { taskTypeToBubbleType } from '../../constants/messages.constants';
import { ITask } from '../../../store/tasks/tasks.interfaces';
import { Store } from '@ngrx/store';
import { getMessagingView } from '../../../store/messages/messages.selectors';
import { MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { setMessagingView } from '../../../store/messages/messages.actions';
import { tasksActions } from '../../../store/tasks/tasks.actions';
import { OPTIONS } from '../../constants/options-list.constants';
import { OptionsActionList } from '../../overlays/option-list.constants';
import { TasksService } from '../../../services/tasks.service';
import { nextTaskStatus, taskStatusToText } from '../../../store/tasks/tasks.constants';
import { first } from 'rxjs/operators';
import { CurrentUserService } from '../../../services/current-user.service';
import { MatTooltip } from '@angular/material/tooltip';
import { OptionsListSimpleComponent } from '../../overlays/options-list-simple/options-list-simple.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MessagingBubbleComponent } from '../../messages/discussions-list-item/messaging-bubble/messaging-bubble.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-task-details-header',
  templateUrl: './task-details-header.component.html',
  styleUrls: ['./task-details-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BackButtonBoxComponent,
    MessagingBubbleComponent,
    CdkOverlayOrigin,
    OptionsListSimpleComponent,
    MatTooltip,
    AsyncPipe,
  ],
})
export class TaskDetailsHeaderComponent implements OnInit {
  _task: ITask;
  @Input() set task(data: ITask) {
    this._task = data;
    this.actions = this.taskService.disableTaskActions(this.actions, data);
  }
  get task() {
    return this._task;
  }
  OPTIONS = OPTIONS;
  taskTypeToBubbleType = taskTypeToBubbleType;
  selectedMessagingView = this.store.select(getMessagingView);
  MESSAGING_VIEWS = MESSAGING_VIEWS;
  actions: OptionsActionList = [
    {
      value: OPTIONS.EDIT,
    },
    {
      value: OPTIONS.DELETE,
    },
    {
      value: OPTIONS.DOWNLOAD,
    },
  ];
  nextTaskStatus = nextTaskStatus;
  taskStatusToText = taskStatusToText;
  showOptionsList = false;
  disabledActions: OptionsActionList;

  constructor(
    private store: Store,
    public taskService: TasksService,
    private userService: CurrentUserService,
  ) {}

  ngOnInit(): void {}

  backButtonClicked() {
    this.selectedMessagingView.pipe(first()).subscribe((view) => {
      if (
        this.taskService.isTaskDetailsOpenedFromMessages &&
        view !== MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW
      ) {
        this.taskService.goBackToMessagesFromTaskDetails();
        return;
      }
      this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_VIEW }));
    });
  }

  onUpdateStatus() {
    this.store.dispatch(tasksActions.statusUpdateOnTaskDetails());
  }
}
