<div class="header-container">
  <!--  <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">-->
  <!--            <mat-icon>keyboard_arrow_left</mat-icon>-->
  <!--            <mat-icon>keyboard_arrow_left</mat-icon>-->
  <!--  </button>-->
  <button mat-icon-button (click)="previousClicked('month')" class="calendar-header-previous-button">
    <div class="icon-arrow-right"></div>
  </button>
  <span class="label">{{ periodLabel }}</span>
  <button mat-icon-button (click)="nextClicked('month')" class="calendar-header-next-button">
    <div class="icon-arrow-right"></div>
  </button>
  <!--  <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">-->
  <!--            <mat-icon>keyboard_arrow_right</mat-icon>-->
  <!--            <mat-icon>keyboard_arrow_right</mat-icon>-->
  <!--  </button>-->
</div>
