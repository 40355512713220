<div class="pricing-card enterprise" [ngClass]="{ 'is-loading': isLoading }">
  <div class="bg-box-1"></div>
  <div class="bg-box-2"></div>
  <h3 class="text-white z-10">Enterprise</h3>
  <div class="description text-white">Best for property management companies with integrations.</div>
  <div [ngClass]="{ 'opacity-0': isLoading }" class="my-5 flex-1 flex flex-col items-center gap-y-1 font-semibold">
    <div class="text-color_secondary text-size_l lg:text-size_xxl">Includes</div>

    <div class="text-size_xxl lg:text-size_3xl">Integrations</div>
    <div class="text-size_xxl lg:text-size_3xl">Training</div>
    <div class="text-size_xxl lg:text-size_3xl">Knowledge Base</div>
    <div class="text-size_xxl lg:text-size_3xl">Seminars</div>
    <div class="text-size_xxl lg:text-size_3xl">Consulting</div>
  </div>
  <button
    [ngClass]="{ 'opacity-0': isLoading }"
    (click)="onButtonClick.emit()"
    class="landing-btn btn-big-full outline-shade-1-btn uppercase transition-opacity"
  >
    schedule call
  </button>
</div>
