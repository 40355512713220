<image-cropper
  [imageFile]="data"
  [maintainAspectRatio]="true"
  [aspectRatio]="1 / 1"
  [resizeToWidth]="IMG_RESIZE_TO"
  [resizeToHeight]="IMG_RESIZE_TO"
  format="png"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
></image-cropper>

<div class="btn-wrapper gap-2">
  <div class="btn mat-button btn-cancel py-2 px-3" (click)="cancel()">Cancel</div>
  <div class="btn mat-button py-2 px-3" (click)="saveImage()">Save</div>
</div>
