import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpDownloadProgressEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { catchError, delay, delayWhen, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { concat, from, Observable, of, Subscription, throwError } from 'rxjs';
import { REST_TOKEN_RFRESH, REST_TOKEN_VALIDATE } from './RestRoutes';
import { NotificationsService } from '../services/notifications.service';
import { MessagesStateService } from '../services/messages-state.service';

export function delayedRetryWRefresh(delayMs: number, retryNr = 2) {
  let retryes = retryNr;

  return (src: Observable<any>) => {
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) =>
            retryes-- > 0
              ? of(error)
              : // tap(data => {console.log(data);})
                throwError('Yo failed'),
          ),
        ),
      ),
    );
  };
}

@Injectable({
  providedIn: 'root',
})
/**
 * Basic request types wrapper
 */
export class RestRequestService {
  isAlreadyRefreshing = false;
  refreshingSignal = new EventEmitter();
  retryCount = 0;
  downloadSubscription: Subscription = new Subscription();

  pipeRetry =
    (take(1),
    // in for some shady stuff when error is not a request error with status 401
    tap((response) => {
      console.log('Token expired');
    }),
    retryWhen((result) =>
      result.pipe(
        tap((reqRes) => {
          if (reqRes.status !== 401) {
            throw reqRes;
          }
          console.log('auth retry: ' + ++this.retryCount);
        }),
        delayWhen((req) => from(this.handleRefresh(req))),
        // delay(200),
        take(2),
        // error message after we exceed number of attempts
        (o) =>
          concat(
            o,
            throwError({
              status: 410,
              error: { msg: 'Unauthorized' },
              msg: 'Sorry, there was an auth error after ' + this.retryCount + ' attempts',
            }),
          ),
      ),
    ));

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService,
    private notif: NotificationsService,
  ) {
    // this.storage.setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxMiIsImp0aSI6Ijc2N2ZkYTk3N2RmZjdhOTkwZTIxNjU3MjE5NDUzMjYwY2EyMTAwYzJiMzEyZTMyY2VlZjk3ZWY2MmM1OWI5MmMxMTU4ODZmNGQ2MTQ2ZGMyIiwiaWF0IjoxNjA3Njk5OTY4LCJuYmYiOjE2MDc2OTk5NjgsImV4cCI6MTYzOTIzNTk2OCwic3ViIjoiMzc0MyIsInNjb3BlcyI6W119.RjZ5ZXkOrSEaOwKv_g0Rq0gi8wr4LxbUXpgTLv2mRJoqsaoBHZQJQTPiXmCoDyfqYzNbHiyCFQbVGstdLqmDSIbKta5oHfHJrzcpnPsRWgoqdwLNC3KrdRos12bhuPBzDAxklrbHkZcdg9J2KGlbHKiJx7_CRalzuWlvgaSUbUG2YrW46494MMo687pU7sT7za0hN6hQ0TqgkpNe72iQqRb_uRx2nSk7Kf4gfrgCYwViv3HgeCaKckwLhfzPTYE92cDTPSEsFLZyKVqumfQuT0BMgmdsJxuEyG-gU5iBwqq_0QM6BkkT3LZwOs2bYEWvyfP9wQ97jZhDnAATHHNsPq1BXxGJIs5QW1CrzzDRHI43tqhq9X52noASJ5zwA7KRm67JyzrQu5xV5GywberLXyDiicQzze8djfdC1uIUPelNCqubo_WFXPLHZPVT2fcme8CmbYUQyWAgPCMi8_-8fKcGUNk-F_Gavwp69Wzmg-Wt8d21mzhYcmk4HdqeIh7AkMg940mgltX-ET45BioJHwCQamjZcCujFBumamd2-iIaMYWQKiVm7QmBR-V7kNKVkY9-APNvgbEIKqlb4To698tpj1NT6gI0iKkY0bvIksM0mEik3pHTghX0BTtIqKPLcIy_U5BvMGQ4ynedrA9Jp5VwUD9gJq8KrlWLiZBOfpt', 0);
    // this.storage.setRefreshToken('asd');
  }

  headersBuild(options = {}, setContentType = true) {
    let header = new HttpHeaders();
    if (setContentType) {
      header = header.set('Content-Type', 'application/json');
    }
    header = header.set('Access-Control-Allow-Origin', '*');
    header = header.set(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept',
    );

    Object.keys(options).forEach((key) => {
      header = header.set(key, options[key]);
    });

    return header;
  }

  handleRefresh(reqRes): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild();
      let isAlreadyRefreshing = false;

      const callValidate = () => {
        console.log('aaaaaaaaaaaaaaaaaaaa');

        this.httpClient.get(REST_TOKEN_VALIDATE, { headers: header }).subscribe(
          (validResp) => {
            this.refreshingSignal.next(true);
            res(true);
          },
          (err) => {
            callRefresh();
          },
        );
      };

      const callRefresh = () => {
        this.httpClient
          .get(REST_TOKEN_RFRESH + '/' + this.storage.getRefreshToken(), {
            headers: header,
          })
          .subscribe(
            (refreshRes: any) => {
              if (refreshRes.auth === 'Token is valid') {
                console.log('isValid');

                this.refreshingSignal.next(true);
                res(true);
              } else {
                this.storage.setRefreshToken(refreshRes.refresh_token);
                this.storage.setToken(refreshRes.token, refreshRes.expires_in).then((data) => {
                  console.log('set');
                  MessagesStateService.connectUser$.next(true);
                  this.refreshingSignal.next(true);
                  res(true);
                });
              }
            },
            (error) => {
              if (error.status === 401) {
                this.refreshingSignal.next(true);

                res(true);
                // throw new Error('You are logged out, please log in!');
              } else {
                // if even this request fails why try again?
                this.refreshingSignal.next(false);

                rej(error);
              }
            },
          );
      };

      const checkToken = () => {
        if (reqRes.status === 401) {
          if (this.storage.getToken() != null && this.storage.getRefreshToken() != null) {
            callValidate();
          } else {
            this.refreshingSignal.next(false);
            rej(reqRes);
          }
        } else {
          this.refreshingSignal.next(false);
          rej(reqRes);
        }
      };

      if (isAlreadyRefreshing === false) {
        isAlreadyRefreshing = true;
        this.refreshingSignal.pipe(take(1)).subscribe((ev) => {
          console.log('got it');
          isAlreadyRefreshing = false;
          res(true);
        });
        checkToken();
      }
    });
  }

  post(url, data, options = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;

      this.httpClient
        .post(url, data, { headers: header })
        .pipe(this.pipeRetry)
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  postWithObservable(url, data, options = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    return this.httpClient.post(url, data, { headers: header }).pipe(this.pipeRetry);
  }

  get(url, options = {}, params = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;

      this.httpClient
        .get(url, { headers: header, params })
        .pipe(this.pipeRetry)
        .subscribe(
          (done) => {
            res(done);
          },
          (err) => {
            rej(this.handleError(err));
          },
        );
    });
  }

  getWithObservable(url, options = {}, params = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    return this.httpClient.get(url, { headers: header, params }).pipe(this.pipeRetry);
  }

  getNoToken(url, options = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      let header = new HttpHeaders().set('Content-Type', 'application/json');
      header = header.set('Access-Control-Allow-Origin', '*');
      header = header.set(
        'Access-Control-Allow-Headers',
        'Origin, X-Requested-With, Content-Type, Accept',
      );
      header = header.set('Authorization', 'none');

      Object.keys(options).forEach((key) => {
        header = header.set(key, options[key]);
      });

      this.retryCount = 0;

      // keep in mind interceptor may add headers

      this.httpClient
        .get(url, { headers: header })
        .pipe(this.pipeRetry)
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  getBlob(url, options = {}, params = {}, showErrorMessage = true): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options, false);

      this.retryCount = 0;
      this.httpClient
        .get(url, {
          headers: header,
          responseType: 'blob',
          reportProgress: true,
          observe: 'events',
          params,
        })
        .pipe(this.pipeRetry)
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.type === HttpEventType.Response) {
              res(response.body);
            }
          },
          (error) => {
            if (showErrorMessage) {
              this.notif.showError('Error occurred during download.');
            }
            rej(this.handleError(error));
          },
        );
    });
  }

  /**
   * This function is used for syncfusion to download files, if called again first download be cancelled
   * Accepts an AWS S3 url download link.
   * @param url - AWS S3 url
   * @param options
   * @param params
   */
  getArrayBuffer(url, options = {}, params = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;
      if (!this?.downloadSubscription?.closed) {
        this.downloadSubscription?.unsubscribe();
      }

      this.downloadSubscription = this.httpClient
        .get(url, {
          headers: {
            Authorization: 'none',
          },
          responseType: 'arraybuffer',
          reportProgress: true,
          observe: 'events',
          params,
        })
        .pipe(this.pipeRetry)
        .subscribe(
          (response: HttpResponse<any>) => {
            this.notif.showLoading();
            if (response.type === HttpEventType.Response) {
              res(response.body);
            }
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  getZip(url, body, options = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;
      this.httpClient
        .post(url, body, {
          headers: header,
          responseType: 'blob',
          reportProgress: true,
          observe: 'events',
        })
        .pipe(this.pipeRetry)
        .subscribe(
          (response: any) => {
            const progress = (response as HttpDownloadProgressEvent)?.loaded / body.size;
            if (progress && Number.isFinite(progress)) {
              this.notif.showLoading(
                'Downloading: ' +
                  Number(((response as HttpProgressEvent)?.loaded / body.size) * 100).toFixed(0) +
                  ' %',
              );
            }
            if (response.type === HttpEventType.Response) {
              this.notif.showSuccess('Download completed.');
              setTimeout(() => res(response.body), 1000);
            }
          },
          async (error) => {
            const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json';
            const responseData = isJsonBlob(error.error)
              ? await error?.error?.text()
              : error?.error;
            const responseJson =
              typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
            const errorMessage = responseJson?.message
              ? responseJson.message
              : 'Error occurred during download.';
            this.notif.showError(errorMessage);
            rej(this.handleError(error));
          },
        );
    });
  }

  put(url, data, options = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;

      this.httpClient
        .put(url, data, { headers: header })
        .pipe(this.pipeRetry)
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  putWithObservable(url, data, options = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    return this.httpClient.put(url, data, { headers: header }).pipe(
      this.pipeRetry,
      catchError((err) => throwError(err)),
    );
  }

  putWithObservableReportProgress(url, data, options = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    options = {
      ...options,
      reportProgress: true,
      observe: 'events',
    };
    return this.httpClient.put(url, data, { headers: header, ...options }).pipe(
      this.pipeRetry,
      catchError((err) => throwError(err)),
    );
  }

  patchWithObservable(url, data, options = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    return this.httpClient.patch(url, data, { headers: header }).pipe(
      this.pipeRetry,
      catchError((err) => throwError(err)),
    );
  }

  patch(url, data, options = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;

      this.httpClient
        .patch(url, data, { headers: header })
        .pipe(this.pipeRetry)
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  del(url, options = {}, body = {}): Promise<any> {
    return new Promise<any>((res, rej) => {
      const header = this.headersBuild(options);

      this.retryCount = 0;

      this.httpClient
        .delete(url, { headers: header, params: body })
        .pipe(this.pipeRetry)
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            console.error(error);
            rej(this.handleError(error));
          },
        );
    });
  }

  delWithObservable(url, options = {}, body = {}): Observable<any> {
    const header = this.headersBuild(options);
    this.retryCount = 0;
    return this.httpClient.delete(url, { headers: header, params: body }).pipe(this.pipeRetry);
  }

  handleError(error) {
    return error;
    // if (error.statusText) {
    //   return error.statusText;
    // } else {
    //   return 'Please reload page..';
    // }
  }
}
