import { Injectable } from '@angular/core';
import { IRollupFilters } from '../store/rollups/rollups.interface';
import { defaultRollupFilters } from '../pages/webapp/rollups/rollup.constants';
import { User } from '../framework/constants/user.constants';
import {
  ActivityFilterKeys,
  defaultActivitiesFilters,
} from '../store/activities/activities.constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  SHOW_ZERO_LINES_KEY = 'showZeroLines';
  constructor() {}

  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  static getRollupFilters(): IRollupFilters {
    try {
      return JSON.parse(localStorage.getItem('rollup_filters')) ?? defaultRollupFilters;
    } catch (err) {
      return defaultRollupFilters;
    }
  }

  static setDefaultRollupFilters() {
    localStorage.setItem('rollup_filters', JSON.stringify(defaultRollupFilters));
  }

  static setRollupFilters(filters: IRollupFilters) {
    localStorage.setItem('rollup_filters', JSON.stringify(filters));
  }

  static getActivityFilters() {
    try {
      return (
        JSON.parse(localStorage.getItem('activity_filters')) ?? { ...defaultActivitiesFilters }
      );
    } catch (err) {
      return {
        ...defaultActivitiesFilters,
      };
    }
  }

  static setActivityFilters(filters: ActivityFilterKeys) {
    localStorage.setItem('activity_filters', JSON.stringify(filters));
  }

  static setDefaultActivityFilters() {
    localStorage.setItem('activity_filters', JSON.stringify(defaultActivitiesFilters));
  }

  setTokenAquireTime(time) {
    this.setItem('token_aquire', time);
  }

  getTokenAquireTime(): string {
    return this.getItem('token_aquire');
    // return this.storage.get('token_aquire');
  }

  setTokenDurration(time) {
    this.setItem('token_durration', JSON.stringify(time));
    // this.storage.set('token_durration', time);
  }

  getTokenDurration(): number {
    return this.getItem('token_durration');
    // return Number.parseInt(localStorage.getItem('token_durration'), 10);
    // return Number.parseInt(this.storage.get('token_durration'), 10);
  }

  getToken(): string {
    return this.getItem('user_token');
    // return this.storage.get('user_token');
  }

  setToken(token, expire): Promise<any> {
    return new Promise<any>((res) => {
      this.decodeToken(token).then(
        (result) => {
          this.setUserId(result.sub);
          this.setTokenDurration(expire);
          this.setItem('user_token', token);
          // this.storage.set('user_token', token);
          res(result.sub);
        },
        (err) => {
          throw new Error(err);
        },
      );
    });
  }

  getInviteToken(): string {
    return this.getItem('invitation_token');
    // return this.storage.get('invitation_token');
  }
  setInviteToken(invite: string): void {
    this.setItem('invitation_token', invite);
    // this.storage.set('invitation_token', invite);
  }

  setRefreshToken(token) {
    this.setItem('refresh_token', token);
    // this.storage.set('refresh_token', token);
  }

  getRefreshToken() {
    this.getItem('refresh_token');
    // return this.storage.get('refresh_token');
  }

  setUserId(id) {
    this.setItem('user_id', id);
    // this.storage.set('user_id', id);
  }

  getUserId() {
    this.getItem('user_id');
    // return this.storage.get('user_id');
  }

  setUserData(data: User) {
    this.setItem('user_data', data);
    // this.storage.set('user_data', JSON.stringify(data));
  }

  getUserData(): User {
    return this.getItem('user_data');
    // return JSON.parse(this.storage.get('user_data'));
  }

  getUserType() {
    const userData = this.getItem<User>('user_data');
    if (userData !== null) {
      return userData.type.name;
    } else {
      return null;
    }
  }

  getShowZeroLineItems(): boolean {
    return this.getItem<boolean>(this.SHOW_ZERO_LINES_KEY);
  }
  setShowZeroLineItems(show: boolean) {
    this.setItem(this.SHOW_ZERO_LINES_KEY, show);
  }

  public decodeToken(token: string = ''): Promise<any> {
    return new Promise<any>((res, rej) => {
      if (token === null || token === '') {
        res({ upn: '' });
        return;
      }
      const parts = token.split('.');
      if (parts.length !== 3) {
        rej('JWT must have 3 parts');
      }
      this.urlBase64Decode(parts[1]).then((decoded) => {
        if (!decoded) {
          rej('Cannot decode the token');
        }
        res(JSON.parse(decoded));
      });
    });
  }

  private urlBase64Decode(str: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      let output = str.replace(/-/g, '+').replace(/_/g, '/');
      switch (output.length % 4) {
        case 0:
          break;
        case 2:
          output += '==';
          break;
        case 3:
          output += '=';
          break;
        default:
          // tslint:disable-next-line:no-string-throw
          rej('Illegal base64url string!');
      }
      res(decodeURIComponent((window as any).escape(window.atob(output))));
    });
  }
}
