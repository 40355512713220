import { Component, Input, ViewChild } from '@angular/core';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { FormsModule, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { NotificationsService } from '../../../../services/notifications.service';
import { ISidebarCost, ISidebarInvoiceCost } from '../../../../store/commitments/commitments.types';
import { dropdownAlignRight } from '../../../overlays/option-list.constants';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ArrowButtonBoxComponent } from '../../../dropdown-button-box/arrow-button-box.component';
import { OptionsListGeneralComponent } from '../../../overlays/options-list-general/options-list-general.component';
import { FormatInputMoneyDirective } from '../../../../directives/format-input-money.directive';
import { CostViewTableDropdownComponent } from '../../cost-view-table-dropdown/cost-view-table-dropdown.component';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { BorderedDropdownIconComponent } from '../../../bordered-dropdown-icon/bordered-dropdown-icon.component';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';

@Component({
  selector: 'app-cost-description',
  templateUrl: './cost-description.component.html',
  styleUrls: ['./cost-description.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    CdkOverlayOrigin,
    ArrowButtonBoxComponent,
    OptionsListGeneralComponent,
    FormatInputMoneyDirective,
    CostViewTableDropdownComponent,
    AddRemoveButtonsComponent,
    FloatingInputComponent,
    BorderedDropdownIconComponent,
  ],
})
export class CostDescriptionComponent {
  protected readonly COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;
  protected readonly dropdownAlignRight = dropdownAlignRight;

  _model: Partial<ISidebarCost | ISidebarInvoiceCost>;
  @ViewChild('costDescriptionForm') costDescriptionForm: NgForm;
  @Input() commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE;
  @Input() set model(value: Partial<ISidebarCost | ISidebarInvoiceCost>) {
    if (value) {
      this._model = value;
    }
  }

  get model(): Partial<ISidebarCost | ISidebarInvoiceCost> {
    return this._model;
  }
  @Input() costIndex: number;
  @Input() spendEntryIndex: number;

  isDropdownShown = {
    name: [],
  };
  @Input() totalCostCount: number;
  canAddInvoiceCosts$ = this.store.select(commitmentsSelectors.canAddInvoiceCosts);

  constructor(
    private store: Store,
    private notif: NotificationsService,
  ) {}

  async askRemoveSubItem() {
    const answer = await this.notif.showPopup('Do you want to remove this description?');
    if (answer) {
      this.deleteCost();
    }
  }

  updateModel() {
    let action;
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT: {
        action = commitmentsActions.updateContractSpendEntryCost;

        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER: {
        action = commitmentsActions.updateChangeOrderSpendEntryCost;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES: {
        action = commitmentsActions.updateInvoiceCostValue;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST: {
        action = commitmentsActions.updateDirectCostSpendEntryDescription;
        break;
      }
    }

    if (!action) {
      console.warn('unhandled action');
      return;
    }

    console.log(this.model);
    if (this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES) {
      this.store.dispatch(
        action({
          costIndex: this.costIndex,
          cost: this.model,
        }),
      );
    } else {
      this.store.dispatch(
        action({
          costIndex: this.costIndex,
          spendEntryIndex: this.spendEntryIndex,
          cost: this.model,
        }),
      );
    }
  }

  addCost() {
    // if (!this.model?.item?.id) {
    //   return;
    // }
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT:
        this.store.dispatch(
          commitmentsActions.addCostToContractCommitmentItem({
            spendEntryIndex: this.spendEntryIndex,
            newCostIndex: this.costIndex + 1,
          }),
        );
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER:
        this.store.dispatch(
          commitmentsActions.addCostToChangeOrderCommitmentItem({
            spendEntryIndex: this.spendEntryIndex,
            newCostIndex: this.costIndex + 1,
          }),
        );
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST:
        this.store.dispatch(
          commitmentsActions.addCostToDirectCostCommitmentItem({
            spendEntryIndex: this.spendEntryIndex,
            newCostIndex: this.costIndex + 1,
          }),
        );
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES:
        this.store.dispatch(commitmentsActions.addCostToInvoice());
        break;
      default:
        console.warn('Action not handled.');
        break;
    }
  }

  deleteCost() {
    let action;
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT: {
        action = commitmentsActions.removeContractSpendEntryCost;

        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER: {
        action = commitmentsActions.removeChangeOrderSpendEntryCost;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES: {
        action = commitmentsActions.removeInvoiceCost;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST: {
        action = commitmentsActions.removeDirectCostEntryDescription;
        break;
      }
    }

    if (!action) {
      console.warn('unhandled action');
      return;
    }

    this.store.dispatch(
      action({
        spendEntryIndex: this.spendEntryIndex,
        costIndex: this.costIndex,
      }),
    );
  }
}
