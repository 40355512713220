import { createAction, props } from '@ngrx/store';

export const loadProjectDocuments = createAction(
  '[Project Documents Service] Load for Projects',
  props<{ projectId: number }>(),
);

export const updateAll = createAction(
  '[Project Documents Service] Update All',
  props<{ data: any; projectId?: number; setFolderPath?: boolean }>(),
);

export const projectFolderClicked = createAction(
  '[Project Documents Service] Project Documents Folder Clicked',
  props<{ folderId: number }>(),
);

export const reloadProjectFolder = createAction(
  '[Project Documents Service] Reload Documents Folder By Id',
  props<{ id: number }>(),
);

export const loadProjectFolder = createAction(
  '[Project Documents Service] Load Project Drive Folder',
  props<{ folder: any }>(),
);

export const updateProjectDriveFolder = createAction(
  '[Project Documents Service] Update Project Drive Folder',
  props<{ folder: any }>(),
);

export const backProjDoc = createAction('[Project Documents Service] Back');
export const navigateBack = createAction('[Project Documents Service] Navigate Back');

export const reset = createAction('[Project Documents Service] Reset');

export const setRetrieveDisabled = createAction(
  '[Project Documents Service] setRetrieveDisabled',
  props<{ retrieveDisabled: boolean }>(),
);

export const cancel = createAction('[Project Documents Service] Cancel');

export const reloadProjDocs = createAction('[Project Documents Service] Reload Proj Docs');
export const clearCacheForNewDrive = createAction(
  '[Project Documents Service] Clear cache for new drive from add/edit project',
);

export const clearCache = createAction('[Project Documents Service] Clear cache');

export const projectDocumentActionTypes = {
  loadProjectDocuments,
  projectFolderClicked,
  reloadProjDocs,
  updateAll,
  backProjDoc,
  navigateBack,
  reset,
  cancel,
  clearCacheForNewDrive,
  clearCache,
  setRetrieveDisabled,
  loadProjectFolder,
  updateProjectDriveFolder,
  reloadProjectFolder,
};
