import { IItemTrackingSidebar } from './item-tracking.reducer';

export const defaultItemTrackingSidebar: Partial<IItemTrackingSidebar> = {
  title: '',
  start_date: '',
  end_date: '',
  checklist: {
    name: '',
    items: [],
    completed: false,
  },
};
