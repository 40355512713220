import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-pricing-basic-card',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './pricing-basic-card.component.html',
  styleUrls: ['./pricing-basic-card.component.scss', '../pricing-cards.scss'],
})
export class PricingBasicCardComponent {
  private _price = 69;
  @Input() get price(): number {
    return this._price;
  }

  set price(value: number) {
    if (value) {
      this._price = value;
    }
  }

  @Input() buttonText = 'Free 30 day trial';
  @Input() buttonTextBackside = 'Register Now';
  @Input() backSideButtonText = 'Register Now';
  @Input() isYearly: boolean;
  @Output() onButtonClick = new EventEmitter<void>();
  @Input() isLoading = false;
  showBasicFeatures = false;
}
