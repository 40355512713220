import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-splash',
  standalone: true,
  imports: [],
  templateUrl: './empty-splash.component.html',
  styleUrl: './empty-splash.component.scss',
})
export class EmptySplashComponent {}
