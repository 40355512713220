import { Component, OnDestroy, OnInit } from '@angular/core';
import moment, { Moment } from 'moment';
import { ITaskCalendarItem } from '../../../../store/tasks/tasks.interfaces';
import { tasksActions } from '../../../../store/tasks/tasks.actions';
import { Store } from '@ngrx/store';
import { tasksApiDateFormat } from '../../../../store/tasks/tasks.constants';
import { getSelectedMonth, isCalendarLoading } from '../../../../store/tasks/tasks.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TasksCalendarItemComponent } from '../../../../framework/tasks/tasks-calendar-item/tasks-calendar-item.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tasks-calendar',
  templateUrl: './tasks-calendar.component.html',
  styleUrls: ['./tasks-calendar.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressBar, NgClass, NgFor, TasksCalendarItemComponent, AsyncPipe],
})
export class TasksCalendarComponent implements OnInit, OnDestroy {
  monthName = moment().format('MMMM');
  month = moment().month();
  year = moment().year();
  calendarItems: ITaskCalendarItem[] = [];
  isLoading$ = this.store.select(isCalendarLoading);
  isDestroyed$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.refreshItems();
    this.handleMonthChange();
  }

  private refreshItems() {
    this.fillCalendarItems();
    this.store.dispatch(tasksActions.loadTasks({}));
  }

  private handleMonthChange() {
    this.store
      .select(getSelectedMonth)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(({ year, month }) => {
        this.setSelectedDate(moment().year(year).month(month));
      });
  }

  /**
   * this function adds the day items to the calendarItems array based on the selected month and year.
   */
  private fillCalendarItems() {
    this.calendarItems = [];
    const selectedMonth = moment().year(this.year).month(this.month);
    const firstDayOfMonth = selectedMonth.clone().startOf('month');
    const firstDayIndex = firstDayOfMonth.isoWeekday() - 1;

    // first add the days of the previous month
    // which are in the same week as the first day of the selected month
    const prevMonthDay = firstDayOfMonth.clone().subtract(firstDayIndex, 'day');
    for (let i = 0; i < firstDayIndex; i++) {
      this.calendarItems.push({
        isSelectedMonth: false,
        dayNumber: prevMonthDay.date(),
        dayName: prevMonthDay.format('ddd'),
        dateFormatted: prevMonthDay.format(tasksApiDateFormat),
      });
      prevMonthDay.add(1, 'day');
    }

    // then add the selected month's days
    const currentMonthDay = firstDayOfMonth.clone();
    for (let i = 0; i < selectedMonth.clone().daysInMonth(); i++) {
      this.calendarItems.push({
        isSelectedMonth: true,
        dayNumber: currentMonthDay.date(),
        dayName: currentMonthDay.format('ddd'),
        dateFormatted: currentMonthDay.format(tasksApiDateFormat),
      });
      currentMonthDay.add(1, 'day');
    }

    // finally add the next month's days
    // which are in the same week as the last day of the month
    const nextMonthDay = selectedMonth.clone().add(1, 'month').startOf('month');
    // if the month ends on last day of week (Sunday) don't add the next month's first week
    if (nextMonthDay.isoWeekday() !== 1) {
      for (let i = nextMonthDay.isoWeekday(); i <= 7; i++) {
        this.calendarItems.push({
          isSelectedMonth: false,
          dayNumber: nextMonthDay.date(),
          dayName: nextMonthDay.format('ddd'),
          dateFormatted: nextMonthDay.format(tasksApiDateFormat),
        });
        nextMonthDay.add(1, 'day');
      }
    }

    this.calendarItems = this.calendarItems.map((item, index) => {
      return index < 7 ? { ...item, isFirstRow: true } : item;
    });
    console.log(this.calendarItems);
  }

  goToPreviousMonth() {
    const date = moment().year(this.year).month(this.month);
    const previousMonth = date.subtract(1, 'month');
    this.store.dispatch(
      tasksActions.selectedMonthChangedCalendar({
        year: previousMonth.year(),
        month: previousMonth.month(),
      }),
    );
  }

  goToNextMonth() {
    const date = moment().year(this.year).month(this.month);
    const nextMonth = date.add(1, 'month');
    this.store.dispatch(
      tasksActions.selectedMonthChangedCalendar({
        year: nextMonth.year(),
        month: nextMonth.month(),
      }),
    );
  }

  setSelectedDate(date: Moment) {
    this.year = date.year();
    this.month = date.month();
    this.monthName = date.format('MMMM');
    this.fillCalendarItems();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
