import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormatInputMoneyContentEditableDirective } from '../../../directives/format-input-money-content-editable.directive';

@Component({
  selector: 'app-spend-custom-input',
  templateUrl: './spend-custom-input.component.html',
  styleUrls: ['./spend-custom-input.component.scss'],
  standalone: true,
  imports: [NgClass, FormatInputMoneyContentEditableDirective],
})
export class SpendCustomInputComponent implements OnInit {
  numberValue: number;

  // todo: this disable does not work
  @Input() disabled = false;
  @Input() set value(val: number) {
    this.numberValue = val;
  }
  @Input() allowNegatives = false;

  @Output() valueChange = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}

  onValueChange(event: number) {
    this.valueChange.emit(event);
  }
}
