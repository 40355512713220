import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { SELECTED_OPTION } from '../../overlays/option-list.constants';
import { CustomInputOverlayDropdownComponent } from '../../custom-input-overlay-dropdown/custom-input-overlay-dropdown.component';
import { AsyncPipe, DecimalPipe, NgForOf, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { IChecklist } from '../../../store/tasks/tasks.interfaces';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { ChecklistDropdownComponent } from '../checklist-dropdown/checklist-dropdown.component';
import { FloatingInputComponent } from '../floating-input/floating-input.component';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { InputCalendarComponent } from '../input-calendar/input-calendar.component';
import { DeepCopyService } from '../../../services/deep-copy.service';
import {
  IItemTrackingChecklist,
  IItemTrackingChecklistItem,
} from '../../../store/item-tracking/item-tracking.reducer';
import { itemTrackingActions } from '../../../store/item-tracking/item-tracking.actions';
import { itemTrackingSelectors } from '../../../store/item-tracking/item-tracking.selectors';

const emptyDateChecklistItem: IItemTrackingChecklistItem[] = [
  { name: '', end_date: '', note: '', files: [], completed: false, order: 0 },
];

const emptyDateChecklist: IItemTrackingChecklist = {
  name: '',
  items: DeepCopyService.deepCopy([...emptyDateChecklistItem]),
};

// TODO(2024-03-19): change IChecklist to something else for the model, it's not supposed to be from the tasks..

@Component({
  selector: 'app-checklist-input',
  standalone: true,
  imports: [
    CustomInputOverlayDropdownComponent,
    NgIf,
    ChecklistDropdownComponent,
    FloatingInputComponent,
    DecimalPipe,
    AsyncPipe,
    NgForOf,
    InputCalendarComponent,
  ],
  templateUrl: './checklist-input.component.html',
  styleUrl: './checklist-input.component.scss',
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ChecklistInputComponent),
    },
  ],
})
export class ChecklistInputComponent implements AfterViewInit, OnDestroy {
  protected readonly SELECTED_OPTION = SELECTED_OPTION;
  isNewChecklistInput = false;
  getAllCheckList$: Observable<IItemTrackingChecklist[]> = this.store.select(
    itemTrackingSelectors.getAllCheckList,
  );

  constructor(private store: Store<AppState>) {}

  ngAfterViewInit() {
    this.store.dispatch(itemTrackingActions.loadAllChecklist({ withTrash: false }));
  }

  ngOnDestroy() {
    this.store.dispatch(itemTrackingActions.clearDeletedChecklist());
  }

  @Input() model: IItemTrackingChecklist = DeepCopyService.deepCopy({ ...emptyDateChecklist });
  @Input() required = false;

  @Input() minStartDate: string | Date = '';
  @Input() maxDate: string | Date = '';
  @Input() defaultDate: string | Date = '';

  @Output() modelChange = new EventEmitter<IChecklist>();
  trackByIndex = (index: number) => index;

  checkListOptionSelected(event: {
    action: SELECTED_OPTION;
    checklist?: IChecklist;
    index?: number;
  }) {
    switch (event.action) {
      case SELECTED_OPTION.ADD: {
        this.isNewChecklistInput = true;
        this.model = DeepCopyService.deepCopy({ ...emptyDateChecklist });
        this.modelChange.emit(this.model);
        break;
      }
      case SELECTED_OPTION.SELECT: {
        this.model = DeepCopyService.deepCopy({
          ...event.checklist,
          id: this.model.id,
          template_id: event.checklist.id,
          items: event.checklist.items.map((item) => ({ ...item, end_date: this.defaultDate })),
        });
        this.modelChange.emit(this.model);
        break;
      }
      case SELECTED_OPTION.DESELECT: {
        this.model = { name: '', items: [] };
        this.modelChange.emit(this.model);
        this.isNewChecklistInput = false;
        break;
      }
      case SELECTED_OPTION.REMOVE: {
        this.store.dispatch(itemTrackingActions.deleteChecklist({ id: event.checklist.id }));
        if (this.model?.template_id === event.checklist?.id) {
          this.checkListOptionSelected({ action: SELECTED_OPTION.DESELECT });
        }
        break;
      }
      default: {
        console.log('Default case not handled');
        break;
      }
    }
  }

  updateModelDate(date: string, index: number) {
    this.model.items[index].end_date = date;
    this.modelChange.emit(this.model);
  }

  deleteItem(index: number) {
    this.model.items = this.model.items.filter((_, i) => i !== index);
    this.reorder();
    this.modelChange.emit(this.model);
  }

  insertItem(index: number) {
    this.model.items.splice(index + 1, 0, {
      name: '',
      end_date: '',
      note: '',
      files: [],
      completed: false,
      order: index + 1,
    });
    this.reorder();

    this.modelChange.emit(this.model);
  }

  private reorder() {
    this.model.items = this.model.items.map((item, index) => ({ ...item, order: index }));
  }
}
