import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadTasks,
  removedMarkedAsDeleteChecklist,
  statusUpdateInEffects,
  tasksActions,
} from './tasks.actions';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TasksService } from '../../services/tasks.service';
import { forkJoin, of } from 'rxjs';
import {
  IChecklist,
  ITask,
  ITaskFilters,
  ITaskMember,
  TASK_KANBAN_COLUMN_TYPES,
  TASK_VIEWS,
} from './tasks.interfaces';
import {
  areAllTaskPagesLoaded,
  deletedChecklists,
  getAllCheckList,
  getCalendarTasksByMonth,
  getSelectedMonth,
  getSelectedTask,
  getSelectedTaskId,
  getSelectedTaskView,
  getTaskFilters,
  getTaskPaginationInfoByView,
  getTaskSidebarProjectId,
  isTaskSidebarEdit,
  tasksFeatureSelector,
} from './tasks.selectors';
import { DateCustomPipe, YYYY_MM_DD } from '../../pipes/framework/date-custom.pipe';
import { DeepCopyService } from '../../services/deep-copy.service';
import { NotificationsService } from '../../services/notifications.service';
import {
  getVisualizationByColumnType,
  nextTaskStatus,
  NO_CHECKLIST,
  tasksApiDateFormat,
  tasksDefaultPageLength,
} from './tasks.constants';
import moment from 'moment';

@Injectable()
export class TasksEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private tasksService: TasksService,
    private timeZonePipe: DateCustomPipe,
    private notif: NotificationsService,
  ) {}

  loadTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadTasks),
      withLatestFrom(this.store.select(getSelectedTaskView)),
      tap(([action, selectedView]) => {
        if (selectedView === TASK_VIEWS.KANBAN) {
          this.store.dispatch(
            tasksActions.loadNextPageFromKanban({
              firstPage: true,
              columnType: TASK_KANBAN_COLUMN_TYPES.DUE_THIS_WEEK,
            }),
          );
          this.store.dispatch(
            tasksActions.loadNextPageFromKanban({
              firstPage: true,
              columnType: TASK_KANBAN_COLUMN_TYPES.UPCOMING,
            }),
          );
          this.store.dispatch(
            tasksActions.loadNextPageFromKanban({
              firstPage: true,
              columnType: TASK_KANBAN_COLUMN_TYPES.SPECTATING,
            }),
          );
        } else if (selectedView === TASK_VIEWS.CALENDAR) {
          this.store.dispatch(tasksActions.loadMonthFromCalendar({ hardReload: true }));
        } else {
          this.store.dispatch(tasksActions.loadNextPageFromTable({ firstPage: true }));
        }
        // this.tasksService.getAllTasks$(action.filters ? action.filters : undefined)
      }),
      map((tasks) => {
        return tasksActions.cancel();
        // return tasksActions.setTasks({ tasks });
      }),
    ),
  );

  loadOneTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.taskSelected),
      tap(() => {
        this.store.dispatch(tasksActions.taskViewLoadStarted());
      }),
      switchMap((action) => this.tasksService.getOneTask$(action.taskId)),
      map((task) => {
        return tasksActions.taskViewLoadedSuccessfully({ task });
      }),
    ),
  );

  refreshSelectedTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.refreshSelectedTask),
      tap(() => {
        this.store.dispatch(tasksActions.taskViewLoadStarted());
      }),
      withLatestFrom(this.store.select(getSelectedTaskId)),
      switchMap(([action, taskId]) => this.tasksService.getOneTask$(taskId)),
      map((task) => {
        return tasksActions.taskViewLoadedSuccessfully({ task });
      }),
    ),
  );

  loadTasksNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadNextPageFromTable),
      debounceTime(100),
      withLatestFrom(this.store.select(getTaskFilters), this.store.select(areAllTaskPagesLoaded)),
      // if an empty array was returned then don't load any more pages
      filter(([action, taskFilters, allPagesLoaded]) => !!action.firstPage || !allPagesLoaded),
      tap(([action]) => {
        this.store.dispatch(tasksActions.nextPageLoadingStarted({ isFirstPage: action.firstPage }));
      }),
      mergeMap(([action, filters]) => {
        const newFilters: ITaskFilters = {
          ...filters,
          with_pagination: 1,
          page: action.firstPage ? 1 : filters.page + 1,
        };
        this.store.dispatch(tasksActions.taskFiltersChangedInEffect({ filters: newFilters }));
        const apiFilters = this.convertToApiFilters(newFilters, true);
        return this.tasksService
          .getAllTasks$(apiFilters)
          .pipe(withLatestFrom(of(action.firstPage)));
      }),
      map(([tasks, isFirstPage]) => {
        return tasksActions.nextPageLoaded({ tasks, isFirstPage });
      }),
    ),
  );

  loadNextPageFromKanban$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadNextPageFromKanban),
      mergeMap((action) => {
        return of(action).pipe(
          withLatestFrom(
            this.store.select(getTaskFilters),
            this.store.select(getTaskPaginationInfoByView(action.columnType)),
          ),
        );
      }),
      // if an empty array was returned then don't load any more pages
      filter(
        ([action, taskFilters, paginationInfo]) =>
          !!action.firstPage || !paginationInfo.isAllLoaded,
      ),
      tap(([action, taskFilters, paginationInfo]) => {
        this.store.dispatch(
          tasksActions.nextPageLoadingStartedOnKanban({
            visualization: getVisualizationByColumnType(action.columnType),
            isFirstPage: action.firstPage,
          }),
        );
      }),
      mergeMap(([action, filters, paginationInfo]) => {
        const visualization = getVisualizationByColumnType(action.columnType);
        const newFilters: ITaskFilters = {
          ...filters,
          with_pagination: 1,
          page: action.firstPage ? 1 : paginationInfo.page + 1,
          visualization,
        };
        // this.store.dispatch(tasksActions.taskFiltersChangedInEffect({ filters: newFilters }));
        const apiFilters = this.convertToApiFilters(newFilters);
        return this.tasksService
          .getAllTasks$(apiFilters)
          .pipe(withLatestFrom(of(visualization), of(action.firstPage), of(newFilters.page)));
      }),
      map(([tasks, visualization, isFirstPage, pageLoaded]) => {
        return tasksActions.nextPageFromKanbanLoaded({
          tasks,
          visualization,
          isFirstPage,
          pageLoaded,
        });
      }),
    ),
  );

  /**
   * sets the selected month to the current month
   */
  setDefaultMonth$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.setDefaultMonth),
      map((action) => {
        const year = moment().year();
        const month = moment().month();
        this.store.dispatch(
          tasksActions.loadMonthFromCalendar({ year, month, hardReload: action.hardReload }),
        );
        return tasksActions.selectedMonthChangedEffect({ year, month });
      }),
    ),
  );

  selectedMonthChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.selectedMonthChangedCalendar),
      map((action) => {
        this.store.dispatch(
          tasksActions.loadMonthFromCalendar({ year: action.year, month: action.month }),
        );
        return tasksActions.selectedMonthChangedEffect({ year: action.year, month: action.month });
      }),
    ),
  );

  loadMonthFromCalendar$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadMonthFromCalendar),
      switchMap((action) => {
        return of(action).pipe(withLatestFrom(this.store.select(getSelectedMonth)));
      }),
      switchMap(([action, selectedMonth]) => {
        const year = action.year ?? selectedMonth.year;
        const month = action.month ?? selectedMonth.month;
        return of(action).pipe(
          withLatestFrom(
            of({ year, month }),
            this.store.select(getTaskFilters),
            this.store.select(getCalendarTasksByMonth(year, month)),
          ),
        );
      }),
      switchMap(([action, selectedMonthAndYear, filters, monthlyTasks]) => {
        if (!action.hardReload && monthlyTasks && monthlyTasks.tasks?.length > 0) {
          console.log('not loading tasks, they are already loaded');
          return of(null);
        }
        this.store.dispatch(tasksActions.monthLoadingStartedOnCalendar());

        const year = selectedMonthAndYear.year;
        const month = selectedMonthAndYear.month;
        const selectedDate = moment().year(year).month(month);

        const start_date = selectedDate.clone().startOf('month').format(tasksApiDateFormat);
        const end_date = selectedDate.clone().endOf('month').format(tasksApiDateFormat);

        const newFilters: ITaskFilters = {
          ...filters,
          with_pagination: 0,
          start_date,
          end_date,
          visualization: 'all',
        };
        const apiFilters = this.convertToApiFilters(newFilters);
        return this.tasksService.getAllTasks$(apiFilters).pipe(
          withLatestFrom(of({ month, year, hardReload: !!action.hardReload })),
          catchError((error) => {
            this.notif.showError('An error happened during loading.');
            return of(null);
          }),
        );
      }),
      map((returnedInfo) => {
        if (returnedInfo) {
          const [response, otherInfo] = returnedInfo;
          return tasksActions.monthFromCalendarLoaded({
            tasks: response,
            year: otherInfo.year,
            month: otherInfo.month,
            hardReload: otherInfo.hardReload,
          });
        }
        return tasksActions.cancel();
      }),
    ),
  );

  sortTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.sortTasksFromTaskTable),
      debounceTime(100),
      withLatestFrom(this.store.select(getTaskFilters), this.store.select(areAllTaskPagesLoaded)),
      tap(() => {
        this.store.dispatch(tasksActions.sortingTasksLoadingFromEffect({ isLoading: true }));
      }),
      mergeMap(([action, filters]) => {
        const newFilters: ITaskFilters = {
          ...filters,
          with_pagination: 1,
          page: 1,
          order_direction: action.order_direction,
          order_by: action.order_by,
        };
        this.store.dispatch(tasksActions.taskFiltersChangedInEffect({ filters: newFilters }));
        const apiFilters = this.convertToApiFilters(newFilters);
        return this.tasksService.getAllTasks$(apiFilters);
      }),
      map((tasks) => {
        return tasksActions.setTasksAfterSort({ tasks });
      }),
    ),
  );

  /**
   * will return users from messaging endpoint that are reachable for current user in function of search
   * if a project in tasks sidebar is selected users related to that project will be filtered on backend
   */
  loadUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadUsers),
      withLatestFrom(this.store.select(getTaskSidebarProjectId)),
      tap(() => {
        this.store.dispatch(tasksActions.setUsersLoading({ isLoading: true }));
      }),
      mergeMap(([action, projectId]) =>
        this.tasksService
          .getUsers({ search: action.search, getWithUser: 1, project_id: projectId })
          .pipe(
            map((users: ITaskMember[]) => {
              return tasksActions.setUsers({ users });
            }),
            catchError((err) => {
              console.warn('loadUsers$ err handled', err);
              return of(tasksActions.cancel());
            }),
          ),
      ),
    ),
  );

  /**
   * will load all available checklists for current user
   * returns action to set checklist from backend
   */
  loadAllCheckList$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.loadAllCheckList),
      mergeMap((action) =>
        this.tasksService.getChecklist().pipe(
          map((checklist: IChecklist[]) => {
            return tasksActions.setAllCheckList({ checklist });
          }),
          catchError((err) => {
            console.warn('loadAllChecklist$ err handled', err);
            return of(tasksActions.cancel());
          }),
        ),
      ),
    ),
  );

  /**
   * remove checklists from all checklists on the backend
   * all checklists that have been removed from the task sidebar have "deleted: true"
   * get all checklists with deleted flag and send request for backend
   */
  removedMarkedAsDeleteChecklist$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.removedMarkedAsDeleteChecklist),
      withLatestFrom(this.store.select(deletedChecklists)),
      filter(([action, checklist]) => checklist.length >= 1),
      map(([action, checklists]) => checklists.map((checklist) => this.removeChecklist(checklist))),
      switchMap((observables) => forkJoin([...observables])),
      map((responses: any) => {
        const errors = responses.filter((response) => response?.error);
        if (errors.length) {
          this.notif.showError('Error occurred during deletion of checklist.');
        }

        return tasksActions.cancel();
      }),
    ),
  );

  /**
   * !! CURRENTLY NOT USED !!
   * creates a POST request that adds another checklist to the user's checklist
   * will not create checklist if sidebar checklist is default, NO_CHECKLIST or it exists already
   * name of the checklist will be indexed with a number if one with same name already exists it already has that name
   */
  createChecklist = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.createChecklist),
      withLatestFrom(this.store.select(getAllCheckList)),
      mergeMap(([action, allChecklist]) => {
        const foundChecklist = this.findChecklist(action.checklist, allChecklist);
        if (action.checklist?.id === NO_CHECKLIST || foundChecklist) {
          return of(tasksActions.cancel());
        }

        const newChecklistName = this.getChecklistName(
          allChecklist.map((list) => list.name),
          action.checklist.name,
        );
        const newChecklist = {
          ...action.checklist,
          name: newChecklistName,
        };
        return this.tasksService.addChecklist(newChecklist).pipe(
          map(tasksActions.loadAllCheckList),
          catchError((err) => {
            console.warn('createChecklist$ err handled', err);
            return of(tasksActions.cancel());
          }),
        );
      }),
    ),
  );

  /**
   * get all values saved on task sidebar from store and send it to the backend as a POST/PATCH in function of id
   * convert start and end date to UTC before sending
   * after successful request reload tasks and delete flagged checklists
   * return action to set lat updated task id for file upload purposes
   */
  saveTaskToBackend$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.saveTaskToBackend),
      withLatestFrom(this.store.select(tasksFeatureSelector), this.store.select(isTaskSidebarEdit)),
      tap(() => {
        this.store.dispatch(tasksActions.setIsLoading({ isLoading: true }));
      }),
      mergeMap(([action, state, isTaskEdit]) => {
        const sidebarTask = DeepCopyService.deepCopy(state.sidebarTask);
        sidebarTask.start_date = this.timeZonePipe.transform(sidebarTask.start_date, YYYY_MM_DD);
        if (sidebarTask.end_date !== null) {
          sidebarTask.end_date = this.timeZonePipe.transform(sidebarTask.end_date, YYYY_MM_DD);
        }
        const request = isTaskEdit
          ? this.tasksService.editTask(sidebarTask)
          : this.tasksService.createTask(sidebarTask);
        return request.pipe(
          map((task: ITask) => {
            this.store.dispatch(loadTasks({}));
            // this.store.dispatch(createChecklist({ checklist: sidebarTask.checklist }));
            this.store.dispatch(removedMarkedAsDeleteChecklist());
            return tasksActions.setUpdateTaskID({ id: task.id });
          }),
          catchError((err) => {
            if (err?.error?.message) {
              this.notif.showError(err.error.message);
            } else {
              this.notif.showError('An error occurred during save!');
            }
            console.warn('loadAllChecklist$ err handled', err);
            return of(tasksActions.cancel());
          }),
        );
      }),
    ),
  );

  filtersChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.taskFiltersChangedInComponent),
      withLatestFrom(this.store.select(getTaskFilters)),
      map(([action, currentFilters]) => {
        const newFilters = {
          ...currentFilters,
          ...action.filters,
        };
        const apiFilters = this.convertToApiFilters(newFilters);
        this.store.dispatch(loadTasks({ filters: apiFilters }));
        return tasksActions.taskFiltersChangedInEffect({ filters: newFilters });
      }),
    ),
  );

  /**
   * load one task from the backend and return action to set sidebar in store
   */
  editSidebarTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.editSideBarTaskClicked),
      tap(() => this.store.dispatch(tasksActions.editTaskSidebarLoadStarted())),
      exhaustMap((action) => {
        return this.tasksService.getOneTask$(action.taskId).pipe(
          map((task) => {
            return tasksActions.loadSidebarTaskEdit({ task });
          }),
        );
      }),
    ),
  );

  /**
   * the status update is circular
   *  pending -> in progress -> completed -> pending -> ...
   *  this effect increments the tasks' status by one
   */
  statusUpdateInEffects$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.statusUpdateInEffects),
      mergeMap((action) => {
        const selectedTask = action.task;
        if (!selectedTask) {
          return of(null);
        }
        const newStatus = nextTaskStatus[action.task.status];
        const taskUpdates = { status: newStatus };
        return this.tasksService.updateTask$(selectedTask.id, taskUpdates).pipe(
          catchError((response) => {
            this.notif.showError(`Status update failed: ${response.error.message}`);
            return of(null);
          }),
        );
      }),
      map((response) => {
        if (response && !response.error) {
          this.store.dispatch(loadTasks({}));
          return tasksActions.taskUpdatedSuccessfully({ task: response });
        }
        return tasksActions.cancel();
      }),
    ),
  );

  statusUpdateOnTaskDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.statusUpdateOnTaskDetails),
      withLatestFrom(this.store.select(getSelectedTask)),
      map(([action, task]) => {
        console.log('statusUpdateOnTaskDetails$');
        this.store.dispatch(statusUpdateInEffects({ task: { ...task } }));
        return tasksActions.cancel();
      }),
    ),
  );

  statusUpdateFromOptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.statusUpdateFromOptions),
      map((action) => tasksActions.statusUpdateInEffects({ task: action.task })),
    ),
  );

  deleteOne$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.deleteFromOptions, tasksActions.deleteOnDetails),
      switchMap((action) =>
        this.tasksService.deleteTask(action.task).pipe(
          catchError((resp) => {
            this.notif.showError(`An error occurred: ${resp?.error?.message}`);
            return of(null);
          }),
          map((response) => {
            if (response) {
              return tasksActions.loadTasks({});
            }
            return tasksActions.cancel();
          }),
        ),
      ),
    ),
  );

  checklistItemToggledOnDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(tasksActions.checklistItemToggledOnDetails),
      withLatestFrom(this.store.select(getSelectedTask)),
      switchMap(([action, task]) => {
        const checklist: IChecklist = DeepCopyService.deepCopy(task.checklist);
        const itemToUpdate = checklist.items.find(
          (item) => item.name === action.checklistItem.name,
        );
        if (!itemToUpdate) {
          this.notif.showError('Could not update checklist.');
          return of(null);
        }
        itemToUpdate.value = !itemToUpdate.value;
        return this.tasksService.updateTask$(task.id, { checklist }).pipe(
          catchError((resp) => {
            this.notif.showError(`An error occurred: ${resp?.error?.message}`);
            return of(null);
          }),
        );
      }),
      map((resp) => {
        if (!resp) {
          return tasksActions.cancel();
        }
        this.store.dispatch(loadTasks({}));
        return tasksActions.taskUpdatedSuccessfully({ task: resp });
      }),
    ),
  );

  convertToApiFilters(filters: ITaskFilters, mightNeedLongerPages = true) {
    // convert local filters to API filters
    const newFilters: Partial<ITaskFilters> = { ...filters };
    Object.entries(filters).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        delete newFilters[key];
      }
    });
    if (!newFilters.with_pagination) {
      delete newFilters.page;
      delete newFilters.per_page;
    } else {
      // if pagination is needed determine how many tasks to be loaded based on window height.
      // todo: check, it might break things if window is resized
      let perPage = tasksDefaultPageLength;
      if (mightNeedLongerPages) {
        if (window.innerHeight > 1000 && window.innerHeight <= 1500) {
          perPage = 40;
        }

        if (window.innerHeight > 1500) {
          perPage = 60;
        }

        if (window.innerHeight > 2000) {
          perPage = 80;
        }

        if (window.innerHeight > 3000) {
          perPage = 100;
        }
      }
      newFilters.per_page = perPage;
    }
    if (newFilters.visualization === 'all') {
      delete newFilters.visualization;
    }
    return newFilters;
  }

  /**
   * Will check if selected is same as received from backend
   * if anything is different will return false otherwise true
   * @param checklist selected checklist
   * @param allChecklist all checklist from backend
   * @private
   */
  private findChecklist(checklist: IChecklist, allChecklist: IChecklist[]) {
    if (!checklist?.id) {
      return false;
    }
    const foundChecklistById = allChecklist.find((all) => all.id === checklist.id);
    return JSON.stringify(foundChecklistById) === JSON.stringify(checklist);
  }

  /**
   * get unique name that does not exist in allNames
   * increment name with one if it is found in the list
   * once the new name is not found the name with increment is returned
   * @param allNames string list to be checked if name exists in it
   * @param name string that is checked and incremented if exists in allNames
   * @private
   */
  private getChecklistName(allNames: string[], name: string) {
    if (!allNames.includes(name)) {
      return name;
    }

    const regexp = /\d+$/g; // number at end of string regex
    const trailingNumber = name.match(regexp)?.[0];
    const newNumber = Number.parseInt(trailingNumber, 10) + 1;
    const newName = trailingNumber ? name.replace(regexp, newNumber.toString()) : name + ' 1';
    return this.getChecklistName(allNames, newName);
  }

  /**
   * send request to remove one single checklist from backend
   * @param checklist
   * @private
   */
  private removeChecklist(checklist) {
    return this.tasksService.removeChecklist(checklist).pipe(
      catchError((err) => {
        return of(err);
      }),
    );
  }
}
