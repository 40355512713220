import { Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { propertiesActions } from './properties.actions';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { CurrentUserService } from '../../services/current-user.service';
import { IProperty } from './properties.interfaces';
import { ProjectApiService } from '../../services/project-api.service';
import { propertiesSelectors } from './properties.selector';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class PropertiesEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private userService: CurrentUserService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
  ) {}

  loadProperties$ = createEffect(() =>
    this.actions.pipe(
      ofType(propertiesActions.loadProperties, propertiesActions.reloadProperties),
      tap((action) => {
        if (action.type === propertiesActions.reloadProperties.type) {
          this.store.dispatch(propertiesActions.showFetching());
        }
      }),
      switchMap(async (action) => {
        const properties: IProperty[] = await this.userService.getProperties(action.search);
        return propertiesActions.successfullyLoadedProperties({ properties });
      }),
      catchError((errorData) => {
        console.warn(errorData);
        return of(propertiesActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  loadPropertiesByView$ = createEffect(() =>
    this.actions.pipe(
      ofType(propertiesActions.loadPropertiesByType, propertiesActions.reloadPropertiesByType),
      withLatestFrom(this.store.select(propertiesSelectors.propertiesFeatureSelector)),
      tap((_) => {
        this.store.dispatch(propertiesActions.showFetching());
      }),
      debounceTime(500),
      switchMap(([action, state]) => {
        return this.userService
          .getPropertiesByType(action?.view ?? state.selectedPropertyType)
          .pipe(
            map((properties) => {
              return propertiesActions.successfullyLoadedPropertiesByType({
                properties,
                propertyType: action.view ?? state.selectedPropertyType,
              });
            }),
            catchError((err) => {
              console.warn(err);
              this.notif.showError(err?.error?.message ?? 'Could not load properties');
              return of(propertiesActions.cancelLoad());
            }),
          );
      }),
      repeat(),
    ),
  );

  deleteProperty$ = createEffect(() =>
    this.actions.pipe(
      ofType(propertiesActions.deleteProperty),
      withLatestFrom(this.store.select(propertiesSelectors.propertiesFeatureSelector)),
      tap((_) => {
        this.store.dispatch(propertiesActions.showFetching());
      }),
      exhaustMap(([action, state]) => {
        return this.projectApi.deleteProperty$(action.id).pipe(
          map((properties) => {
            return propertiesActions.loadPropertiesByType({
              view: state.selectedPropertyType,
            });
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? 'Could not delete property.');
            return of(propertiesActions.cancelLoad());
          }),
        );
      }),
      repeat(),
    ),
  );
}
