import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProjectStateService } from '../../../services/project-state.service';
import { CalendarHandler } from '../../interfaces/CalendarHandler';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { INTERACTION_BAR_STATES } from '../../constants/interaction-bar.constants';
import { ContractorsApiService } from '../../../services/contractors-api.service';
import { NotificationsService } from '../../../services/notifications.service';
import { DateCustomPipe } from '../../../pipes/framework/date-custom.pipe';
import { CalendarComponent } from '../../inputs/calendar/calendar.component';
import { MatButton } from '@angular/material/button';
import { SelectionListComponent } from '../../selection-list/selection-list.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-re-schedule',
  templateUrl: './re-schedule.component.html',
  styleUrls: ['./re-schedule.component.scss'],
  standalone: true,
  imports: [CalendarComponent, NgScrollbar, SelectionListComponent, MatButton],
})
export class ReScheduleComponent extends CalendarHandler implements OnInit {
  @ViewChild('schedule') schedule: CalendarComponent;
  visit;
  calendarEvents: any = [];
  visitDate = '';
  title = '';
  inited = false;
  INTERACTION_BAR_STATE = INTERACTION_BAR_STATES;

  constructor(
    private projectState: ProjectStateService,
    private interactionBar: InteractionBarStateService,
    private user: CurrentUserService,
    private barState: InteractionBarStateService,
    private contractorApi: ContractorsApiService,
    private datePipe: DateCustomPipe,
    protected notif: NotificationsService,
  ) {
    super(notif);
  }

  _data;

  get data() {
    return this._data;
  }

  @Input() set data(value) {
    this._data = value;
    if (value && this.inited) {
      this.ngOnInit();
    }
  }

  _type;

  get type() {
    return this._type;
  }

  @Input() set type(value) {
    this._type = value;
    if (value && this.inited) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    if (this.user.isManager) {
    } else {
      if (this.projectState.general) {
        this.title = this.projectState.general.title;
      }
    }

    if (this.type === INTERACTION_BAR_STATES.RE_SCHEDULE) {
      if (this.data) {
        if (this.data.day && this.data.interval) {
          this.visitDate = this.datePipe.transform(this.data.day) + ' ' + this.data.interval;
        }
      }
    }

    if (this.type === INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR) {
      this.contractorApi.getContractor(this.data).then((data) => {
        this.title = data.user.company_name;
      });
    }

    if (this.type === INTERACTION_BAR_STATES.RE_SCHEDULE_BY_PROJECT) {
      if (this.user.isManager) {
        this._type = INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR;
        this.contractorApi.getContractor(this.data.contractorId).then((data) => {
          this.title = data.user.company_name;
        });
      }

      this.projectState.id = this.data.projectId;
      this.projectState.fetchAndSetProject().then((data) => {
        // this.title = data.user.company_name;
      });
    }

    if (this.schedule) {
      this.schedule.refresh();
    }

    this.projectState.getScheduleVisits().then(
      (data) => {
        this.visit = data;
        this.inited = true;
      },
      (err) => {},
    );
  }

  close() {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.NONE;
    this.interactionBar.close();
  }
}
