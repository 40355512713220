import { Router } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';

export const enum ERROR_MESSAGES {
  NETWORK_CONNECTION_ERROR = 'Network Connection Error',
  SPECTATOR_ERROR = 'You cannot edit this resource. You are a spectator!',
  ACCESS_ERROR = 'You have not been granted edit access to this project',
  UNHANDLED_EXCEPTION = 'Unhandled Exception',
}

export abstract class ErrorHandlerRest {
  constructor(
    protected user: CurrentUserService,
    protected router: Router,
  ) {}

  handleError(error) {
    console.log('ERROR (handled): ', error);

    if (error.status === 500) {
      if (error.error.errors) {
        return error.error.errors.detail;
      } else {
        return error.error.message;
      }
    } else if (error.status === 0) {
      return ERROR_MESSAGES.NETWORK_CONNECTION_ERROR;
    } else if (error.status === 401) {
      return error.error[Object.keys(error.error)[0]];
    } else if (error.status === 403) {
      return error.error.message;
    } else {
      if (error.error) {
        return error.error[Object.keys(error.error)[0]];
      } else {
        return ERROR_MESSAGES.UNHANDLED_EXCEPTION;
      }
    }
  }
}

export class ErrorHandlerRestClass extends ErrorHandlerRest {
  constructor(
    protected user: CurrentUserService,
    protected router: Router,
  ) {
    super(user, router);
  }
}

export class ErrorHandlerRestSimple {
  /**
   * Aim is to handle rest errors without token verification
   * @param error RestRequest error to handle
   */
  handleError(error) {
    if (error.status === 500) {
      if (error.error.detail) {
        return error.error.errors.detail;
      } else {
        return error.error.message;
      }
    } else {
      if (error.error) {
        return error.error[Object.keys(error.error)[0]];
      } else {
        return error;
      }
    }
  }
}
