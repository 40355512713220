import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TasksInteractionBarCommonComponent } from '../tasks-interaction-bar-common/tasks-interaction-bar-common.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app-state';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { DateCustomPipe } from '../../../../pipes/framework/date-custom.pipe';
import { CUSTOM_TASK_FIELD_TYPES } from '../../../../store/tasks/tasks.constants';
import { updateSidebarTask } from 'src/app/store/tasks/tasks.actions';
import { CustomTaskGroupComponent } from '../../../tasks/custom-task-group/custom-task-group.component';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-tasks-interaction-bar-third-page',
  templateUrl: './tasks-interaction-bar-third-page.component.html',
  standalone: true,
  imports: [NgFor, CustomTaskGroupComponent],
})
export class TasksInteractionBarThirdPageComponent
  extends TasksInteractionBarCommonComponent
  implements OnInit
{
  @ViewChildren('customTaskGroupComponent')
  customTaskGroupComponent: QueryList<CustomTaskGroupComponent>;

  constructor(
    protected store: Store<AppState>,
    protected interactionBarStateService: InteractionBarStateService,
    protected timeZonePipe: DateCustomPipe,
  ) {
    super(store, interactionBarStateService, timeZonePipe);
  }

  addCustomFieldGroup() {
    this.model = this.deepCopyModel;
    this.model.custom_fields.push({
      type: CUSTOM_TASK_FIELD_TYPES.TEXT,
      label: '',
      value: '',
    });
    this.onModelChange();
  }

  ngOnInit(): void {}

  onModelChange() {
    this.store.dispatch(updateSidebarTask({ task: this.deepCopyModel }));
  }

  removeGroup(event: { index: number }) {
    this.model.custom_fields.splice(event.index, 1);
    this.onModelChange();
  }

  isFormValid() {
    let areComponentsValid = true;
    this.customTaskGroupComponent.forEach((customFromComponent) => {
      areComponentsValid = areComponentsValid && customFromComponent.isFormValid();
    });
    return areComponentsValid;
  }
}
