import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormatInputMoneyDirective } from '../../../directives/format-input-money.directive';

@Component({
  selector: 'app-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    FormatInputMoneyDirective,
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FloatingInputComponent),
    },
  ],
})
export class FloatingInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') input: NgModel;
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() classes: {
    wrapper: string;
    input: string;
  } = {
    wrapper: '',
    input: '',
  };
  @Input() model: any = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() name = 'input';
  @Input() type: 'tel' | 'text' | 'password' | 'number' | 'email' = 'text';
  @Input() label: string;
  @Input() placeholder = '';
  @Input() ngModelOptions: { standalone: boolean } = { standalone: false };
  @Input() isEmail = false;
  @Input() disabled = false;
  @Input() maxlength: string | number = null;
  @Input() minlength: string | number = null;
  @Input() autoComplete = true;
  @Input() verticalSpacing = true;
  /**
   * validate input against a given pattern
   */
  @Input() pattern = null;
  @Input() patternErrorMessage: string = null;
  @Input() formatBy: 'tel' = null;
  @Input() iconClass: {
    position: 'p-input-icon-left' | 'p-input-icon-right';
    icon: string;
  } = {
    position: null,
    icon: null,
  };
  @Input() formatMoney = false;
  @Input() set formControlName(value: string) {
    // just set the input's name
    if (value) {
      this.name = value;
    }
  }

  get control(): AbstractControl {
    if (this.ngControl) {
      return this.ngControl.control;
    }
    return this.input?.control;
  }
  @Output() modelChange = new EventEmitter<any>();
  @Output() iconClick = new EventEmitter<any>();
  formattedModel: any = '';
  isDestroyed$ = new Subject();
  ngControl: NgControl;

  innerReadOnly = true; // workaround against autocomplete
  get isInputDisabled() {
    // control.disabled is used only with formControl and not ngModel
    return this.disabled || (this.control && this.control.disabled && this.ngControl);
  }

  constructor(private injector: Injector) {}

  ngOnInit() {
    try {
      // no ngControl if used with ngModel so injector is needed,
      // otherwise we get a nullInjection error
      this.ngControl = this.injector.get(NgControl);
      setTimeout(() => {
        // otherwise it won't display the control's value
        this.model = this.ngControl?.control?.value ?? '';
        console.log('this.model', this.model);
        this.ngControl?.control?.valueChanges
          ?.pipe(takeUntil(this.isDestroyed$))
          .subscribe((value) => {
            this.model = value;
          });
      });
    } catch (error) {
      // this is NOT a real problem, we can just use ngModel's control
    }
  }

  ngAfterViewInit() {
    if (this.type === 'tel') {
      this.formattedModel = this.formatPhoneNumber(this.model);
    }
  }

  onModelChange(value: string) {
    if (this.type === 'tel') {
      this.formattedModel = this.formatPhoneNumber(value);
    } else {
      this.formattedModel = value;
    }

    this.modelChange.emit(value);
  }

  /**
   * <p>this function formats the phone number input to the <code>(yyy) - yyy - yyyy</code> format
   * - or whatever length the user inputs.
   * The function sets the formattedPhoneNumber variable. </p>
   * <p>it gets executed on every keyup event on phone number input.</p>
   * <p>automatically shows or hides the extra characters.</p>
   */
  formatPhoneNumber(inputValue: string) {
    if (!inputValue) {
      return '';
    }
    const numbers = inputValue.replace(/[^0-9]/g, '');
    if (numbers.length === 0) {
      return '';
    }
    let formattedPhoneNumber = '';
    const part1 = numbers.slice(0, 3);
    if (numbers.length < 4) {
      return part1;
    }
    formattedPhoneNumber = `(${part1})`;

    const groupedNumbers = [];
    groupedNumbers.push(numbers.slice(3, 6));
    if (numbers.slice(10, 14).length <= 1) {
      groupedNumbers.push(numbers.slice(6, 11));
    } else {
      groupedNumbers.push(numbers.slice(6, 10));
      groupedNumbers.push(numbers.slice(10, 14));
    }

    for (const group of groupedNumbers) {
      if (!group) {
        break;
      }
      formattedPhoneNumber += ` - ${group}`;
    }
    return formattedPhoneNumber;
  }

  onBlur() {
    this.control.markAsTouched();
  }

  onFocus() {
    // workaround against autocomplete
    // https://stackoverflow.com/a/32578659/6146963
    this.innerReadOnly = false;
  }

  onIconClick() {
    this.iconClick.emit();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
