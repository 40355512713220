import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-pulse',
  standalone: true,
  imports: [],
  template: `
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  `,
  styles: ``,
})
export class LoadingPulseComponent {}
