import { AfterViewInit, Directive, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Directive({
  selector: '[appCalendarFormatter]',
  providers: [DatePipe],
  standalone: true,
})
export class CalendarFormatterDirective implements AfterViewInit, DoCheck {
  constructor(
    private model: NgControl,
    private datePipe: DatePipe,
  ) {}

  ngDoCheck() {
    this.formatDate();
  }

  ngAfterViewInit() {
    this.formatDate();
  }

  formatDate() {
    try {
      const displayValue = this.datePipe.transform(this.model.value, 'MM/dd/yyyy');
      // this.model.control.setValue(this.model.value); // actual value
      this.model.valueAccessor.writeValue(displayValue); // display value
    } catch (e) {
      console.log(e);
    }
  }
}
