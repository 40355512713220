import { PROJECT_STATUS_ID } from '../constants/project.constants';

export enum REPORT_TYPE {
  PORTFOLIO = 1,
  COST,
  PROPERTY_DETAIL,
  PROPERTY_CASHFLOW,
  PROJECT_DETAIL,
  PROJECT_CASHFLOW,
}

export const MAX_TABLE_ROWS = 7;
export enum PRINT_PAGE_TYPES {
  ROLLUP_PROJECTS,
  ROLLUP_BUDGET_LINES,
  ROLLUP_BUDGET_TAGS,
  CASHFLOW,
  PROJECT_VIEW_SPEND_DETAIL,
  PROJECT_VIEW_CASHFLOW,
  PROJECT_VIEW_COMMITMENTS,
}

export const POSSIBLE_PRINT_PAGES = [
  PRINT_PAGE_TYPES.ROLLUP_PROJECTS,
  PRINT_PAGE_TYPES.ROLLUP_BUDGET_LINES,
  PRINT_PAGE_TYPES.ROLLUP_BUDGET_TAGS,
  PRINT_PAGE_TYPES.CASHFLOW,
  PRINT_PAGE_TYPES.PROJECT_VIEW_SPEND_DETAIL,
  PRINT_PAGE_TYPES.PROJECT_VIEW_CASHFLOW,
  PRINT_PAGE_TYPES.PROJECT_VIEW_COMMITMENTS,
];

export interface ReportingQueryParams {
  start_year: number;
  end_year: number;
  project_ids: number | number[] | 'all' | 'none';
  property_ids: number | number[] | 'all' | 'none';
  property_detail: boolean;
  property_cashflow: boolean;
  project_detail: boolean;
  project_cashflow: boolean;
  schedule: boolean;
}

export type IReportingData = {
  start_year?: number;
  end_year?: number;
  invoice_log_start_date?: string;
  invoice_log_end_date?: string;
  project_status_ids: number[];
  project_ids: number[] | 'all';
  property_ids: number[] | 'all';
  include_property_summary: boolean;
  include_property_projects: boolean;
  include_property_budget_lines: boolean;
  include_property_budget_tags: boolean;
  include_property_cash_flow: boolean;
  include_project_spend_detail: boolean;
  include_project_cash_flow: boolean;
  include_project_schedule: boolean;
  include_project_commitments: boolean;
  include_contract_detail: boolean;
  include_change_order_detail: boolean;
  include_invoice_detail: boolean;
  include_direct_cost_detail: boolean;
  include_project_updates: boolean;
  include_invoice_log?: boolean;
};

export const REPORTING_ALL_STATUSES = [
  PROJECT_STATUS_ID.COMPLETED + '',
  PROJECT_STATUS_ID.IN_PROGRESS + '',
  PROJECT_STATUS_ID.BIDDING + '',
  PROJECT_STATUS_ID.PLANNED + '',
  PROJECT_STATUS_ID.CANCELED + '',
];
