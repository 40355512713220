import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { IMessage, MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { setMessagingView, setThread } from '../../../store/messages/messages.actions';
import { RestRequestService } from '../../../restApi/rest-request.service';
import { getProfilePicUrl } from '../../../store/messages/messages.selectors';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { filter, take, takeUntil } from 'rxjs/operators';
import { DEFAULT_IMAGE } from '../../constants/messages.constants';
import { Observable } from 'rxjs';
import { DriveService } from '../../../services/drive.service';
import { DateCustomPipe } from '../../../pipes/framework/date-custom.pipe';
import {
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgIf,
  NgFor,
  UpperCasePipe,
} from '@angular/common';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgIf,
    NgFor,
    UpperCasePipe,
    DateCustomPipe,
  ],
})
export class MessageItemComponent implements OnInit, OnDestroy {
  @Input() message: IMessage;
  @Input() hideReplies = false;
  DEFAULT_IMAGE = DEFAULT_IMAGE;
  getProfilePicUrl$: Observable<string>;
  profilePicUrl: SafeUrl;
  isDestroyed$ = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private rest: RestRequestService,
    private domSanitizer: DomSanitizer,
    private driveService: DriveService,
  ) {}

  ngOnInit(): void {
    this.getProfilePicUrl$ = this.store.select(getProfilePicUrl(this.message?.user?.id));
    this.getProfilePicUrl$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((data) => !!data),
        take(1),
      )
      .subscribe((url) => {
        if (url) {
          // note: sanitized SafeUrl is stored inside variable as getProfilePicUrl$ selector triggers too many times
          this.profilePicUrl = this.sanitize(url);
        }
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  sanitize(url) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  openThread(message: IMessage) {
    if (message.isLocalOnly) {
      return;
    }
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW }));
    this.store.dispatch(setThread({ thread: { mainMessage: message, replies: message.thread } }));
  }

  downloadAttachment(attachment: File) {
    this.driveService.downloadSelectedDocuments([attachment]);
  }
}
