<mat-calendar
  appCalendarEvents
  [ngClass]="{ 'opacity-blur': isLoading }"
  [events]="events"
  [selected]="selectedDate"
  [headerComponent]="header"
  (selectedChange)="onSelect($event)"
  class="default-cursor"
  #calendar
>
</mat-calendar>

<div class="calendar-loading" *ngIf="this.isLoading">
  <div class="text-container">
    Loading
    <span class="spin-container"><span class="icon-refresh animate-spin"></span></span>
  </div>
</div>
