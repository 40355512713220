import { Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { projectActions } from './projects.actions';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectApiService } from '../../services/project-api.service';
import { getAllProjects, getSimpleProjects } from './projects.selectors';
import { from, of } from 'rxjs';
import { CurrentUserService } from '../../services/current-user.service';
import {
  IProjectFilters,
  Project,
  ProjectStatusKey,
} from '../../pages/webapp/projects/projects.interface';
import {
  PROJECT_STATUSES,
  PROJECT_STATUSES_CONTRACTOR,
} from '../../framework/constants/project.constants';
import { IProjectStatus } from '../rollups/rollups.interface';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class ProjectsEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private projectsApi: ProjectApiService,
    private currentUser: CurrentUserService,
    private notif: NotificationsService,
  ) {}

  // it doesn't load if there are projects
  loadProjectsTasksKanban$ = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadProjectsTasksKanban),
      withLatestFrom(this.store.select(getAllProjects)),
      switchMap(([action, projects]) => {
        if (!projects || projects.length === 0) {
          // it doesn't load if there are projects
          return this.projectsApi.getProjects();
        }
        return of(null);
      }),
      map((projects) => {
        if (projects) {
          return projectActions.allProjectsLoadedSuccessfully({ projects });
        }
        return projectActions.allProjectLoadCancelled();
      }),
    ),
  );

  // it doesn't load if there are projects
  loadProjectsSimpleTaskSidebar$ = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadProjectsSimpleTaskSidebar),
      withLatestFrom(this.store.select(getSimpleProjects)),
      switchMap(([action, simpleProjects]) => {
        if (simpleProjects?.length > 0) {
          // it doesn't load if there are projects
          return of(null);
        }
        if (action.filters) {
          return this.projectsApi.getSimpleProjects(action.filters);
        }
        return from(this.getDefaultFilters()).pipe(
          switchMap((filters: IProjectFilters) => {
            return this.projectsApi.getSimpleProjects(filters);
          }),
        );
      }),
      catchError((err) => {
        console.warn('loadProjectsSimpleTaskSidebar$ err on load', err);
        return of(null);
      }),
      map((projects) => {
        if (!projects) {
          return projectActions.simpleProjectLoadCancelled();
        }
        return projectActions.simpleProjectsLoadedSuccessfully({ projects });
      }),
    ),
  );

  loadBackendStatuses = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadBackendStatuses),
      switchMap((_) => {
        return this.projectsApi.getPossibleStatuses$();
      }),
      catchError((err) => {
        console.warn('err on load', err);
        return of(null);
      }),
      map((statuses: IProjectStatus[] | null) => {
        if (!statuses) {
          return projectActions.cancelStatusLoading();
        }
        return projectActions.statusesLoadedSuccessfully({ statuses });
      }),
    ),
  );

  createProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.createProject),
      switchMap((action) => {
        return this.projectsApi.createProject$(action.project);
      }),
      catchError((err) => {
        console.warn('err on load', err);
        return of(null);
      }),
      map((project: Project) => {
        if (!project?.id) {
          return projectActions.projectCreationCancelled();
        }
        return projectActions.projectCreatedSuccessfully({ projectId: project.id });
      }),
    ),
  );

  editProjectInitiated = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.editProjectInitiated),
      switchMap((action) => {
        return this.projectsApi.getProjectById$(action.id);
      }),
      map((project: Project) => {
        if (!project?.id) {
          return projectActions.failedToInitiateProjectEdit();
        }
        return projectActions.editProjectDataLoaded({ project });
      }),
    ),
  );

  deleteProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.deleteProject),
      exhaustMap((action) => {
        return this.projectsApi.deleteProject$(action.id);
      }),
      catchError((err) => {
        this.notif.showError(err?.error?.message || 'Failed to delete project');
        console.warn('err on load', err);
        return of(null);
      }),
      map((data) => {
        if (!data) {
          this.notif.showError('Failed to delete project');
          return projectActions.failedToDeleteProject();
        }
        return projectActions.successfulProjectDeletion();
      }),
    ),
  );

  updateProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.updateProject),
      switchMap((action) => {
        return this.projectsApi.updateProject$(action.project);
      }),
      catchError((err) => {
        console.warn('err on patch', err);
        return of(null);
      }),
      map((data) => {
        if (!data) {
          return projectActions.updateProjectFailure();
        }
        return projectActions.updateProjectSuccess();
      }),
    ),
  );

  getDefaultFilters(): Promise<IProjectFilters> {
    let statuses: ProjectStatusKey[];
    return this.currentUser.isManagerF().then((isManager): IProjectFilters => {
      if (isManager) {
        statuses = PROJECT_STATUSES.filter(
          (status) =>
            status.key !== 'archived' && status.key !== 'deleted' && status.key !== 'cancelled',
        ).map((status) => status.key as ProjectStatusKey);
      } else {
        statuses = PROJECT_STATUSES_CONTRACTOR.filter(
          (status) => status.id !== 'archived' && status.id !== 'deleted',
        ).map((status) => status.id as ProjectStatusKey);
      }
      return { status_filter: statuses };
    });
  }
}
