<div class="flex items-center gap-3">
  <!--  <span [ngClass]="{ checked: checked, disabled: disabled }" class="checkbox"></span>-->
  <p-checkbox class="small" [ngModel]="checked" [binary]="true"></p-checkbox>
  <div class="flex-1 min-w-0">
    <div
      [ngClass]="{
        'text-color_primary': !disabled,
        'text-shade_3': disabled
      }"
      class="text-size_l font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden"
    >
      {{ title }}
    </div>
    <div
      [ngClass]="{
        'text-shade_1': !disabled,
        'text-shade_3': disabled
      }"
      class="text-size_m font-normal"
      *ngIf="subtitle"
    >
      {{ subtitle }}
    </div>
  </div>
</div>
