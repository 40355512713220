import { createAction, props } from '@ngrx/store';
import { Cashflow, CashflowFilters } from '../../framework/constants/cashflow.constants';

const cancel = createAction('[Cashflow] Cancel');

const filtersChanged = createAction(
  '[Cashflow] Filters Changed',
  props<{ filters: Partial<CashflowFilters> }>(),
);

const startedLoading = createAction('[Cashflow] Started Loading');

const cashflowLoaded = createAction('[Cashflow] Cashflow Loaded', props<{ cashflow: Cashflow }>());

export const cashflowActions = {
  cancel,
  filtersChanged,
  startedLoading,
  cashflowLoaded,
};
