import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { NotificationsService } from '../services/notifications.service';

@Injectable()
export class IsApproved {
  constructor(
    private router: Router,
    private user: CurrentUserService,
    private notif: NotificationsService,
  ) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.user.isApproved()) {
      return true;
    } else {
      this.notif.showError('Your account is not Approved');
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.user.isApproved()) {
      return true;
    } else {
      this.notif.showError('Your account is not Approved!');
      return false;
    }
  }
}
