import { ElementRef, TemplateRef, TrackByFunction } from '@angular/core';

export type DynamicTableType =
  | 'any'
  | 'date'
  | 'date-MMM_DD_YYYY'
  | 'money'
  | 'name_with_options'
  | 'formatted_address'
  | 'type_unit_value'
  | 'template_1'
  | 'template_2'
  | 'empty_field';

export type DynamicTableDataType = {
  [key: string]: DynamicTableType;
};

export type DynamicTableDataKeys = string[];

export type DynamicTableRowStyle =
  | {
      'grid-template-columns': string;
    }
  | {
      [klass: string]: any;
    };
export type DynamicTableTemplate = {
  template: TemplateRef<ElementRef>;
  context: {
    $implicit: any;
  };
};

export type DynamicTableTemplateKeys = 'template_1' | 'template_2';

export type DynamicTableRowData = {
  [key: string]: any;
} & {
  [key in DynamicTableTemplateKeys]?: DynamicTableTemplate;
};

export type DynamicTableHeaderData = {
  [key: string]: any;
};

export type DynamicTableData = DynamicTableRowData[];

export type DynamicTableHeaders = DynamicTableHeaderData[];

export type TableScrollEvent = { scrollEvent: Event; scrolledToBottom: boolean };

export const trackById: TrackByFunction<any> = (index, item) => item.id;
