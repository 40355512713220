import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ProjectStateService } from '../../services/project-state.service';
import { ProjectApiService } from '../../services/project-api.service';
import { ContractorsApiService } from '../../services/contractors-api.service';
import { CurrentUserService } from '../../services/current-user.service';
import { NotificationsService } from '../../services/notifications.service';
import { INTERACTION_BAR_STATES } from '../constants/interaction-bar.constants';
import moment from 'moment';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, MatSlideToggle],
})
/**
 * Provides selection list of time intervals.
 */
export class SelectionListComponent implements OnInit {
  /**
   * Range interval to display.
   */
  @Input() intervalMin = 30;
  /**
   * Possible selections range.
   */
  availabilityHours = [];
  @Output() changes = new EventEmitter();
  curentDate = null;
  INTERACTION_BAR_STATES = INTERACTION_BAR_STATES;

  constructor(
    private projectStatus: ProjectStateService,
    public projectApi: ProjectApiService,
    public userApi: CurrentUserService,
    private changeRef: ChangeDetectorRef,
    private contractorApi: ContractorsApiService,
    private notif: NotificationsService,
    private el: ElementRef,
  ) {
    this.constructAvailability(this.timeArray('6:00', '18:00'));
  }

  _events = [];

  get events() {
    return this._events;
  }

  @Input() set events(value) {
    this._events = value;
    this.availabilityHours = [];
    this.constructAvailability(this.timeArray('6:00', '18:00'));
    this.checkAvailability(this.curentDate);
  }

  @Input() set date(curentDate) {
    // console.log('aaaaaaaaaaaaaaaaaaaaa');
    this.curentDate = curentDate;
    this.checkAvailability(curentDate);
  }

  _barState = { type: 'none', data: '' };

  get barState() {
    return this._barState;
  }

  @Input() set barState(value) {
    if (value) {
      this._barState = value;
      console.log('BAR STATE');
      console.log('BAR STATE', value);
    }
  }

  ngOnInit() {}

  checkAvailability(date) {
    // console.log(this.availabilityHours);
    // console.log(this.events);
    // console.log(date);

    if (date && !Array.isArray(date)) {
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      // console.log(date.toISOString());

      this.availabilityHours.forEach((available) => {
        available.name = '';
        available.available = true;
        available.status = undefined;
      });

      // console.log(this.events, this.availabilityHours);

      this.events.forEach((event) => {
        this.availabilityHours.forEach((available) => {
          const hourOne = event.interval.split('-')[0];
          const hourTwo = available.start;
          // console.log(hourOne, hourTwo, hourOne === hourTwo);
          // console.log(date.toISOString().split('T')[0], event.day);
          if (hourOne === hourTwo && date.toISOString().split('T')[0] === event.day) {
            available.day = event.day;
            if (event.status !== 'visit') {
              available.available = false;
              available.name = '';
              available.contractor_id = null;
            } else {
              if (event.contractorId) {
                if (this.userApi.isManager) {
                  this.contractorApi.getContractor(event.contractorId).then(
                    (contractor) => {
                      // console.log(contractor);
                      available.name = contractor.license.company_name;
                      available.contractor_id = event.contractorId;
                    },
                    (err) => {
                      console.log('unhandled selection list GET');
                    },
                  );
                }
                // console.log(
                //   this.barState.data,
                //   event.contractorId,
                //   ((this.barState.data as any).contractorId ||
                //     this.barState.data) === event.contractorId
                // );
                // if (
                //   this.barState.type ===
                //   INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACOTR &&
                //   ((this.barState.data as any).contractorId ||
                //     this.barState.data) !== event.contractorId
                // ) {
                //   // available.disable = true;
                // }
              }
              available.available = true;
            }
            available.visitId = event.visitId;
            available.status = event.status;
            available.id = event.id;
          }
        });
      });
      this.changeRef.detectChanges();
    }
  }

  constructAvailability(array: Array<any>) {
    for (let i = 0; i < array.length; i += 1) {
      this.availabilityHours.push({
        start: array[i],
        end: array[i + 1],
        available: true,
      });
    }
  }

  deleteVisit(hour) {
    hour.status = 'loading';
    this.projectApi.deleteVisit(hour.id).then(
      (data) => {
        this.changes.emit(true);
        // this.checkAvailability(this.curentDate);
      },
      (err) => {
        this.changes.emit(true);
      },
    );
  }

  registerCheckbox(hour: any, event, askConfirm = false) {
    console.log(hour, event);

    if (event.checked) {
      if (
        this.userApi.isManager &&
        this.barState.type !== INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR
      ) {
        this.registerManagerCheckbox(hour, event);
      } else {
        this.registerContractorCheckbox(hour, event);
      }
    } else {
      if (
        this.userApi.isManager &&
        this.barState.type !== INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR
      ) {
        if (askConfirm) {
          // this.notif.showPopup('Delete Scheduled visit?').then(resp => {
          //   if (resp === true) {
          this.deleteVisit(hour);
          // }
          // });
        } else {
          this.deleteVisit(hour);
        }
      } else {
        // this.notif.showPopup('Delete Scheduled visit?').then(resp => {
        //   if (resp === true) {
        hour.status = 'loading';
        // this.deleteSchedule(hour);
        this.deleteScheduleVisit(hour, event);
      }
      // this.projectStatus.deleteVisitSchedule(hour.id).then(data => {
      //   this.changes.emit(true);
      // });
    }
  }

  isChecked(hour): boolean {
    return hour.available && !(!hour.status && hour.status !== 0);
  }

  isBeforeNow = (day, time) => {
    time = moment(time, 'hh:mma');
    const schedule = moment(day).set({
      hour: time.hour,
      minute: time.minute,
    });
    return schedule.isBefore(moment(), 'minute');
  };

  isOneChecked(contractorId, hour): boolean {
    if (!this.userApi.isManager) {
      return (
        this.availabilityHours.findIndex(
          (o) =>
            o.available === true &&
            !(!o.status && o.status !== 0) &&
            o.day === moment.utc(this.curentDate).local().format('YYYY-MM-DD') &&
            !this.isBeforeNow(o.day, o.end),
        ) >= 0
      );
    }
    return this.unavailableSlots(contractorId).length > 0;
  }

  unavailableSlots(contractorId): Array<any> {
    console.warn('this.availabilityHours', this.availabilityHours);
    const checkedContractors = this.availabilityHours.filter(
      (o) => o.available === true && !(!o.status && o.status !== 0),
    );

    return checkedContractors.filter(
      (o) =>
        (o.contractor_id === contractorId || !this.userApi.isManager) &&
        o.day === moment.utc(this.curentDate).local().format('YYYY-MM-DD') &&
        !this.isBeforeNow(o.day, o.end),
    );
  }

  restoreInitialCheckedState(hour, event, state = false) {
    hour.available = false;

    setTimeout(() => {
      event.checked = state;
      event.source.checked = state;

      event.source._elementRef.nativeElement.classList.remove('mat-checked');
      this.changeRef.detectChanges();
      this.changes.emit(true);
    }, 500);
  }

  registerContractorCheckbox(hour: any, event = null) {
    // TODO: may be needed contractor id
    console.log('hour', hour, event, hour.available);
    hour.status = 'loading';
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    if (new Date(this.curentDate) >= todayDate) {
      let contractorId;
      if (this.barState.type === INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR) {
        contractorId = this.barState.data;
      } else {
        contractorId = this.userApi.data.contractor.id;
      }

      if (contractorId.contractorId) {
        contractorId = contractorId.contractorId;
      }

      !this.isOneChecked(contractorId, hour)
        ? this.addSchedule(hour, contractorId, event)
        : this.handleReschedule(contractorId, hour, event);
    } else {
      this.notif.showError('Date in past!');
      this.restoreInitialCheckedState(hour, event);
    }
  }

  registerManagerCheckbox(hour, event) {
    if (hour.status === 'visit') {
      // hour.status = 'loading';
      // this.projectApi.deleteVisit(hour.visitId).then(data => {
      //   this.changes.emit(true);
      //
      //   // this.checkAvailability(this.curentDate);
      // }, err => {
      //
      // });
    } else {
      hour.status = 'loading';
    }
    console.log(hour, event);

    this.projectStatus.createVisit(hour, this.curentDate).then(
      (data) => {
        // setTimeout(() => {
        this.changes.emit(true);

        // this.checkAvailability(this.curentDate);
        // }, 1000);
      },
      (err) => {
        console.warn(err);
        this.notif.showError(err);
        this.restoreInitialCheckedState(hour, event);
      },
    );
  }

  // }

  // hoverAction(event: MouseEvent, i) {
  //   const elements = this.el.nativeElement.getElementsByClassName('selection-item-schedule') as Array<HTMLElement>;
  //
  //   for (let index = 0; index < elements.length; index++) {
  //     elements[index].classList.remove('selection-item-schedule-hover');
  //     elements[index].classList.remove('selection-item-schedule-hover-neighbor');
  //   }
  //
  //   if (elements[i - 1]) {
  //     elements[i - 1].classList.add('selection-item-schedule-hover-neighbor');
  //   }
  //
  //   if (elements[i + 1]) {
  //     elements[i + 1].classList.add('selection-item-schedule-hover-neighbor');
  //   }
  //
  //   elements[i].classList.add('selection-item-schedule-hover');
  // }

  /**
   * Converts numeric value in to string time.
   * @param time Numeric value
   */
  private timeString(time) {
    let hours: any = Math.floor(time / 60);
    let minutes: any = time % 60;
    let zone = 'AM';

    if (hours > 12) {
      zone = 'PM';
      hours = hours % 12;
    }

    if (hours === 12) {
      zone = 'PM';
    }

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return hours + ':' + minutes + zone;
  }

  // setPreviousHover(event) {
  //   console.log(event);
  //   console.log(event.relatedTarget.previousElementSibling);

  /**
   * Creates list items array.
   * @param start start of interval
   * @param end end of interval
   */
  private timeArray(start, end) {
    start = start.split(':');
    end = end.split(':');

    start = parseInt(start[0], 10) * 60 + parseInt(start[1], 10);
    end = parseInt(end[0], 10) * 60 + parseInt(end[1], 10);

    const result = [];

    for (let time = start; time <= end; time += this.intervalMin) {
      result.push(this.timeString(time));
    }

    return result;
  }

  private createScheduleVisit(hour, contractorId, event = null) {
    this.projectStatus.createScheduleVisit(hour.id, contractorId).then(
      (data) => {
        this.changes.emit(true);
      },
      (err) => {
        this.notif.showError(err);
        this.restoreInitialCheckedState(hour, event);
      },
    );
  }

  private showError(err: any) {
    console.warn(err);
    this.notif.showError(err);
  }

  private addVisit(hour, contractorId, event) {
    this.projectApi.getVisits(this.projectStatus.id).then(
      (allProjVisits) => {
        const currentVisit = allProjVisits.find(
          (el) =>
            el.day === moment.utc(this.curentDate).local().format('YYYY-MM-DD') &&
            hour.start === moment.utc(el.start_date_time).local().format('hh:mmA'),
        );
        hour.id = currentVisit.id;
        this.createScheduleVisit(hour, contractorId, event);
      },
      (err) => {
        this.showError(err);
        this.restoreInitialCheckedState(hour, event);
      },
    );
  }

  private addSchedule(hour, contractorId, event) {
    if (!hour.available && hour.id) {
      this.createScheduleVisit(hour, contractorId, event);
    } else {
      this.projectStatus.createVisit(hour, this.curentDate).then(
        () => {
          this.addVisit(hour, contractorId, event);
        },
        (err) => {
          this.showError(err);
          this.restoreInitialCheckedState(hour, event);
        },
      );
    }
  }

  private deleteScheduleVisit(hour, event) {
    this.projectApi.deleteScheduleVisit(hour.visitId).then(
      (data) => {
        setTimeout(() => {
          this.changes.emit(true);
        }, 100);
        // this.checkAvailability(this.curentDate);
      },
      (err) => {
        this.changes.emit(true);
        this.restoreInitialCheckedState(hour, event, true);
        // });
        // } else {
        //   this.restoreInitialCheckedState(hour, event, true);
        // }
      },
    );
  }

  isIntervalPast(hour, time = 'start') {
    const isIntervalBeforeNow = this.isIntervalBefore(hour, time);
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const currentDate = moment(this.curentDate);
    const daysDiff = Number(currentDate.diff(today, 'days', true).toFixed(2));
    if (daysDiff === 0) {
      return isIntervalBeforeNow;
    }
    return daysDiff < 0;
  }

  isIntervalBefore(hour, time) {
    const interval = time === 'end' ? moment(hour.end, 'hh:mma') : moment(hour.start, 'hh:mma');
    const hourNow = moment(new Date(), 'hh:mma');
    return interval.isBefore(hourNow);
  }

  private handleReschedule(contractorId, hour, event) {
    const schedule = this.unavailableSlots(contractorId);
    const body = {
      visit_id: hour.id,
      contractor_id: contractorId,
      project_id: this.projectStatus.id,
    };
    if (schedule[0].id && hour.id) {
      this.rescheduleServiceProvider(schedule[0].visitId, body);
      return;
    } else {
      this.projectStatus.createVisit(hour, this.curentDate).then(
        () => {
          this.getScheduleId(hour).then((id) => {
            body.visit_id = id;
            this.rescheduleServiceProvider(schedule[0].visitId, body);
          });
        },
        (err) => {
          this.restoreInitialCheckedState(hour, event);
          console.warn(err);
        },
      );
    }
  }

  private getScheduleId(hour) {
    return new Promise((resolve) => {
      this.projectApi.getVisits(this.projectStatus.id).then((allProjSchedules) => {
        const currentSchedule = allProjSchedules.find(
          (el) =>
            el.day === moment.utc(this.curentDate).local().format('YYYY-MM-DD') &&
            hour.start === moment.utc(el.start_date_time).local().format('hh:mmA'),
        );
        resolve(currentSchedule.id);
      });
    });
  }

  private rescheduleServiceProvider(visitID, body) {
    this.projectStatus.reviseScheduleVisit(visitID, body).then(
      (data) => {
        this.changes.emit(true);
      },
      (err) => {
        this.notif.showError(err);
      },
    );
  }
}
