import { createAction, props } from '@ngrx/store';
import {
  IProject,
  IProjectProperty,
  ITeamMember,
  ROLE_TYPE,
  TEAM_MANAGEMENT_SIDEBAR_VIEW,
} from './team-management.interfaces';
import { SafeUrl } from '@angular/platform-browser';
import { TEAM_VIEWS } from '../../framework/constants/team.constants';

const loadTeamMembersFromBackend = createAction(
  '[Team-Management] load all team member from backend',
  props<{ view?: TEAM_VIEWS }>(),
);

const teamLoaded = createAction(
  '[Team-Management] load all team members',
  props<{ view: TEAM_VIEWS; teams: ITeamMember[] }>(),
);

const setViewType = createAction(
  '[Team-Management] set view type',
  props<{ viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW }>(),
);

const loadTeamRolesFromBackend = createAction(
  '[Team-Management] get all team roles',
  props<{ team_id: number }>(),
);

const loadTeamRolesFromPropertyChange = createAction(
  '[Team-Management] get all team roles from property change',
  props<{ property_id: number }>(),
);

const reloadTeamFromBackend = createAction(
  '[Team-Management] reload team from backend',
  props<{ team_id: number }>(),
);

const setTeamRoles = createAction(
  '[Team-Management] set team roles',
  props<{ roles: ROLE_TYPE[] }>(),
);
const setTeamRolesAndTeamId = createAction(
  '[Team-Management] set team roles and team id',
  props<{ roles: ROLE_TYPE[]; team_id: number }>(),
);

const filterPropertiesBySearch = createAction(
  '[Team-Management] filter properties by search',
  props<{ searchText: string }>(),
);

const filterProjectsBySearch = createAction(
  '[Team-Management] filter projects by search',
  props<{ searchText: string }>(),
);

const loadProjectPropertiesFromBackend = createAction('[Team-Management] get project properties');

const setAreTeamsFetching = createAction(
  '[Team-Management] set are teams fetching',
  props<{ isLoading: boolean }>(),
);

const isSelectedMemberFetching = createAction(
  '[Team-Management] is selected member fetching',
  props<{ isLoading: boolean }>(),
);

const setProjectProperties = createAction(
  '[Team-Management] set project properties',
  props<{ properties: IProjectProperty[] }>(),
);

const editTeamMemberOptionClicked = createAction(
  '[Team-Management] edit team member option clicked',
  props<{ member: ITeamMember }>(),
);

const checkProperty = createAction(
  '[Team-Management] check property',
  props<{ property: IProjectProperty; checked: boolean }>(),
);

const checkProject = createAction(
  '[Team-Management] check project',
  props<{ project: IProject; checked: boolean }>(),
);

const checkAllProperties = createAction(
  '[Team-Management] check  all properties',
  props<{ checked: boolean }>(),
);

const checkAllProjects = createAction(
  '[Team-Management] check  all projects',
  props<{ checked: boolean }>(),
);

const clearSelectedTeamMember = createAction('[Team-Management] clear selected team member');
const addMemberToBackend = createAction(
  '[Team-Management] add member to backend',
  props<{ member: Partial<ITeamMember> }>(),
);

const modifyMemberOnBackend = createAction(
  '[Team-Management] modify member on backend',
  props<{ member: Partial<ITeamMember> }>(),
);

const loadProfilePictures = createAction(
  '[Team-Management] load profile pictures',
  props<{ teams: ITeamMember[] }>(),
);

// todo check
const loadOneTeamMemberFromBackend = createAction(
  '[Team-Management] load one team member from backend',
  props<{ member: Partial<ITeamMember> }>(),
);

// todo check
const setLoadedTeamMember = createAction(
  '[Team-Management] set loaded team member',
  props<{ member: Partial<ITeamMember> }>(),
);

const setMemberProjects = createAction(
  '[Team-Management] set member projects',
  props<{ projectIds: number[] }>(),
);

const addProfilePictureToCache = createAction(
  '[Team-Management] set member projects',
  props<{ profilePictureId: number; url: SafeUrl }>(),
);

const setProfilePicturesLoading = createAction(
  '[Team-Management] set profile pictures loading',
  props<{ isLoading: boolean }>(),
);

const setTeamSearchText = createAction(
  '[Team-Management] set team search query',
  props<{ searchQuery: string }>(),
);

const setMemberProperties = createAction(
  '[Team-Management] set member properties',
  props<{ propertyIds: number[] }>(),
);

const cancelLoad = createAction('[Team-Management] cancel loading');
const cancel = createAction('[Team-Management] cancel');
const searchTeamTextChanged = createAction('[Team-Management] search team text changed');
const clearTeamManagement = createAction('[Team-Management] clear team management');

const viewChanged = createAction('[Team-Management] view changed', props<{ view: TEAM_VIEWS }>());

export const teamManagementActions = {
  loadTeamMembersFromBackend,
  reloadTeamFromBackend,
  teamLoaded,
  setViewType,
  loadTeamRolesFromBackend,
  loadTeamRolesFromPropertyChange,
  setTeamRoles,
  loadProjectPropertiesFromBackend,
  setProjectProperties,
  checkProperty,
  checkAllProperties,
  checkProject,
  checkAllProjects,
  addMemberToBackend,
  modifyMemberOnBackend,
  editTeamMemberOptionClicked,
  cancel,
  cancelLoad,
  clearSelectedTeamMember,
  loadOneTeamMemberFromBackend,
  setLoadedTeamMember,
  setMemberProperties,
  setMemberProjects,
  setTeamRolesAndTeamId,
  filterPropertiesBySearch,
  filterProjectsBySearch,
  loadProfilePictures,
  addProfilePictureToCache,
  setProfilePicturesLoading,
  setAreTeamsFetching,
  isSelectedMemberFetching,
  setTeamSearchText,
  searchTeamTextChanged,
  clearTeamManagement,
  viewChanged,
};
