import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISimpleProject, Project } from '../../../pages/webapp/projects/projects.interface';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { Store } from '@ngrx/store';
import { getSimpleProjects } from '../../../store/projects/projects.selectors';
import { projectActions } from '../../../store/projects/projects.actions';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-messaging-tag-project-overlay',
  templateUrl: './messaging-tag-project-overlay.component.html',
  styleUrls: ['./messaging-tag-project-overlay.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbar],
})
export class MessagingTagProjectOverlayComponent implements OnInit, OnDestroy {
  projects: ISimpleProject[] = [];
  isLoading = false;
  projectsToShow: ISimpleProject[] = [];
  isDestroyed$ = new Subject();
  private _filterText: string;

  @Input() set data(inputData) {
    this.filterText = inputData.filterText;
    // this.projects = inputData.projects;
  }

  get filterText(): string {
    return this._filterText;
  }

  set filterText(value: string) {
    this._filterText = value;
    if (value) {
      this.projectsToShow = this.projects.filter((project) =>
        project.title.toLowerCase().includes(value),
      );
    } else {
      this.projectsToShow = this.projects;
    }
  }

  constructor(
    private overlayService: CustomOverlayService,
    private projectApi: ProjectApiService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.loadProjects();
  }

  loadProjects() {
    this.store.dispatch(projectActions.loadProjectsSimpleTaskSidebar({}));
    this.isLoading = true;
    this.store
      .select(getSimpleProjects)
      .pipe(
        filter((projects) => projects?.length > 0),
        first(),
      )
      .subscribe((projects) => {
        this.projects = projects;
        this.projectsToShow = this.projects;
        this.isLoading = false;
      });
  }

  onProjectClick(project: Project) {
    this.overlayService.closeOverlay();
    this.overlayService.emitOutputData(project);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
