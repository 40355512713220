<div class="pricing-card premium" [ngClass]="{ 'is-loading': isLoading }">
  <div class="bg-box-1"></div>
  <div class="bg-box-2"></div>
  <h3 class="text-white z-10">Premium</h3>
  <div class="text-white description">Best for mid-large property management companies.</div>
  <div class="flex-1 flex flex-col">
    <div [ngClass]="{ 'opacity-0': isLoading }" class="flex items-baseline justify-center">
      <h2 class="price">${{ price }}/</h2>
      <span class="text-white text-size_title_s lg:text-size_title_m font-semibold">property</span>
    </div>
    <div [ngClass]="{ 'opacity-0': isLoading }" class="billing-info text-white">
      <ng-container *ngIf="!isYearly">billed each month</ng-container>
      <ng-container *ngIf="isYearly">billed each year</ng-container>
    </div>
  </div>
  <div class="my-5">
    <div class="text-center text-size_xxl lg:text-size_3xl font-semibold">Full Access to All Features</div>
    <div class="text-center text-size_l lg:text-size_xl">Minimum portfolio size of 5 Properties</div>
  </div>
  <button
    [ngClass]="{ 'opacity-0': isLoading }"
    (click)="onButtonClick.emit()"
    class="landing-btn btn-big-full outline-shade-1-btn uppercase transition-opacity"
  >
    {{ buttonText }}
  </button>
</div>
