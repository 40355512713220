import { Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, repeat, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectApiService } from '../../services/project-api.service';
import { rollupActions } from './rollups.actions';
import { of } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { ROLLUP_VIEW_TYPES } from './rollups.interface';
import { getRollupShowZeroDollarLines } from './rollups.selectors';

@Injectable()
export class RollupEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private projectsApi: ProjectApiService,
  ) {}

  loadRollup$ = createEffect(() =>
    this.actions.pipe(
      ofType(rollupActions.loadRollupByView),
      withLatestFrom(this.store.select(getRollupShowZeroDollarLines)),
      tap((_) => {
        this.store.dispatch(rollupActions.isLoading({ isLoading: true }));
      }),
      switchMap(([action, showZeroDollarLines]) => {
        // set show zero dollar lines for projects view filter, for the budget line items the components handles it
        let localShowZeroDollarLines = showZeroDollarLines;
        if (action.viewType === ROLLUP_VIEW_TYPES.PROJECTS) {
          localShowZeroDollarLines = true;
        }

        if (action.viewType === ROLLUP_VIEW_TYPES.TAGS) {
          localShowZeroDollarLines = true;
        }

        return of(action).pipe(
          switchMap(() =>
            this.projectsApi.getRollup(
              action.viewType,
              action.searchText ?? '',
              action.propertyIds,
              action.projectTags,
              action.projectStatusIds,
              action.projectIds,
              localShowZeroDollarLines,
            ),
          ),
          withLatestFrom(of(action)),
        );
      }),
      map(([dataByView, action]) => {
        return rollupActions.rollupByViewSuccessfullyLoaded({
          data: dataByView,
          view: action.viewType,
        });
      }),
      catchError((errorData) => {
        console.warn(errorData);
        return of(rollupActions.rollupByViewLoadCancelled());
      }),
      repeat(),
    ),
  );

  setRollupFilters$ = createEffect(() =>
    this.actions.pipe(
      ofType(rollupActions.setRollupFilters),
      tap(({ filters }) => {
        StorageService.setRollupFilters(filters);
      }),
      map(() => rollupActions.cancel()),
    ),
  );
}
