import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PAGE_TASKS_CALENDAR,
  PAGE_TASKS_KANBAN,
  PAGE_TASKS_TABLE,
} from '../../constants/view.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { tasksActions } from '../../../store/tasks/tasks.actions';
import { Store } from '@ngrx/store';
import { taskPageUrlToView } from '../../../store/tasks/tasks.interfaces';
import { TasksCalendarComponent } from '../../../pages/webapp/tasks/tasks-calendar/tasks-calendar.component';
import { TasksTableComponent } from '../../../pages/webapp/tasks/tasks-table/tasks-table.component';
import { TasksKanbanComponent } from '../../../pages/webapp/tasks/tasks-kanban/tasks-kanban.component';
import { NgIf } from '@angular/common';
import { TasksHeaderComponent } from '../tasks-header/tasks-header.component';

@Component({
  selector: 'app-tasks-wrapper',
  templateUrl: './tasks-wrapper.component.html',
  styleUrls: ['./tasks-wrapper.component.scss'],
  standalone: true,
  imports: [
    TasksHeaderComponent,
    NgIf,
    TasksKanbanComponent,
    TasksTableComponent,
    TasksCalendarComponent,
  ],
})
export class TasksWrapperComponent implements OnInit, OnDestroy {
  page: string;
  KANBAN = PAGE_TASKS_KANBAN;
  TABLE = PAGE_TASKS_TABLE;
  CALENDAR = PAGE_TASKS_CALENDAR;
  isDestroyed$ = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit(): void {
    // on route change the page changes too which alters the component shown
    // the header stays the same, but the subcomponents change by route
    // task loading is called by the individual components
    this.activatedRoute.data.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.page = data.page;
      this.store.dispatch(tasksActions.taskViewChanged({ view: taskPageUrlToView[this.page] }));
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
