import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_GENERAL_TASKS,
  REST_MESSAGING_USERS,
  REST_TASK_CHECKLIST,
} from '../restApi/RestRoutes';
import { Observable } from 'rxjs';
import {
  IChecklist,
  ITask,
  ITaskFilters,
  ITaskSideBar,
  TASK_TYPE,
} from '../store/tasks/tasks.interfaces';
import { NO_CHECKLIST, NO_PROJECT, userSearchData } from '../store/tasks/tasks.constants';
import { DeepCopyService } from './deep-copy.service';
import { DriveService, IZipStructure } from './drive.service';
import { OPTIONS } from '../framework/constants/options-list.constants';
import { editSideBarTaskClicked, tasksActions, taskSelected } from '../store/tasks/tasks.actions';
import { InteractionBarStateService } from './interaction-bar-state.service';
import { Store } from '@ngrx/store';
import { NotificationsService } from './notifications.service';
import { setMessagingView } from '../store/messages/messages.actions';
import { MESSAGING_VIEWS } from '../store/messages/messages.interfaces';
import { MessagesStateService } from './messages-state.service';
import { CurrentUserService } from './current-user.service';
import { OptionsAction, OptionsActionList } from '../framework/overlays/option-list.constants';
import { INTERACTION_BAR_STATES } from '../framework/constants/interaction-bar.constants';

/**
 * @deprecated - tasks are not used anymore, should be deleted
 */
@Injectable({
  providedIn: 'root',
})
export class TasksService {
  isTaskDetailsOpenedFromMessages: boolean;

  constructor(
    private rest: RestRequestService,
    private driveService: DriveService,
    private barState: InteractionBarStateService,
    private store: Store,
    private notif: NotificationsService,
    private messageService: MessagesStateService,
    private userService: CurrentUserService,
  ) {}

  getAllTasks$(filters?: Partial<ITaskFilters>): Observable<ITask[]> {
    const convertedFilters = this.convertArrayParams(filters);
    convertedFilters.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.rest.getWithObservable(REST_GENERAL_TASKS, {}, convertedFilters);
  }

  getOneTask$(taskId: number): Observable<ITask> {
    return this.rest.getWithObservable(`${REST_GENERAL_TASKS}/${taskId}`);
  }

  createTask(task: Partial<ITaskSideBar>) {
    task = DeepCopyService.deepCopy(task);
    if (task.project_id === NO_PROJECT) {
      task.project_id = null;
    }
    if (task?.checklist?.id === NO_CHECKLIST) {
      task.checklist = null;
    }

    if (task.type === TASK_TYPE.DAILY) {
      delete task.custom_fields;
    }
    const endpoint = `${REST_GENERAL_TASKS}`;
    return this.rest.postWithObservable(endpoint, task);
  }

  editTask(task: Partial<ITaskSideBar>) {
    task = DeepCopyService.deepCopy(task);
    if (task.project_id === NO_PROJECT) {
      task.project_id = null;
    }
    if (task?.checklist?.id === NO_CHECKLIST) {
      task.checklist = null;
    }

    if (task.type === TASK_TYPE.DAILY) {
      delete task.custom_fields;
    }

    const endpoint = `${REST_GENERAL_TASKS}/${task.id}`;
    return this.rest.patchWithObservable(endpoint, task);
  }

  updateTask$(taskId: number, task: Partial<ITask>) {
    return this.rest.patchWithObservable(`${REST_GENERAL_TASKS}/${taskId}`, task);
  }

  getUsers(data: userSearchData) {
    const endpoint = `${REST_MESSAGING_USERS}`;
    const payload = {
      search: data.search,
      with_current_user: data.getWithUser,
      project_filter: data.project_id,
    };
    if (payload.project_filter === NO_PROJECT) {
      delete payload.project_filter;
    }
    return this.rest.getWithObservable(endpoint, {}, payload);
  }

  getChecklist(search: string = '') {
    const endpoint = `${REST_TASK_CHECKLIST}`;
    return this.rest.getWithObservable(endpoint, {});
  }

  addChecklist(checklist: IChecklist) {
    const endpoint = `${REST_TASK_CHECKLIST}`;
    return this.rest.postWithObservable(endpoint, checklist);
  }

  removeChecklist(checklist: IChecklist) {
    const endpoint = `${REST_TASK_CHECKLIST}/${checklist.id}`;
    return this.rest.delWithObservable(endpoint);
  }

  // should not be used, but it exists
  patchChecklist(checklist: IChecklist) {
    const endpoint = `${REST_TASK_CHECKLIST}/${checklist.id}`;
    return this.rest.patchWithObservable(endpoint, checklist);
  }

  downloadFilesZipped(task: ITask) {
    const zipStructure = this.buildZipStructure(task);
    this.driveService.downloadZip(zipStructure, `${task.title}_files`);
  }

  deleteTask(task: ITask) {
    return this.rest.delWithObservable(`${REST_GENERAL_TASKS}/${task.id}`);
  }

  optionSelected(task: ITask, option: OPTIONS) {
    switch (option) {
      case OPTIONS.VIEW: {
        this.openDetails(task.id);
        break;
      }
      case OPTIONS.EDIT: {
        this.store.dispatch(editSideBarTaskClicked({ taskId: task.id }));
        this.barState.announceState.next({
          action: INTERACTION_BAR_STATES.TASKS_EDIT_CREATE_SIDEBAR,
        });
        break;
      }
      // the switch case fallback here is intentional
      // the statusUpdateOnTaskDetails() increments the current status
      case OPTIONS.TASK_PENDING:
      case OPTIONS.TASK_IN_PROGRESS:
      case OPTIONS.TASK_COMPLETE: {
        this.store.dispatch(tasksActions.statusUpdateFromOptions({ task }));
        break;
      }
      case OPTIONS.DOWNLOAD: {
        if (task.files.length > 1) {
          this.downloadFilesZipped(task);
          break;
        }
        this.driveService.downloadSelectedDocuments(task.files);
        break;
      }
      case OPTIONS.DELETE: {
        this.notif
          .showPopup(`Are you sure you want to delete task '${task.title}'`)
          .then((answer) => {
            if (answer) {
              this.store.dispatch(tasksActions.deleteFromOptions({ task }));
              this.barState.close();
            }
          });
        break;
      }
    }
  }

  openCreateTask() {
    this.barState.announceState.next({ action: INTERACTION_BAR_STATES.TASKS_EDIT_CREATE_SIDEBAR });
    // todo change this wrong method
    this.store.dispatch(tasksActions.setSidebarLoadingFromMenuComp({ isLoading: false }));
  }

  // if it is opened from discussion view than the task view component's discard button acts like a back button
  openDetails(taskId: number, isTaskDetailsOpenedFromMessages?: boolean) {
    this.isTaskDetailsOpenedFromMessages = isTaskDetailsOpenedFromMessages;
    this.barState.announceState.next({ action: INTERACTION_BAR_STATES.TASK_DETAILS });
    this.store.dispatch(taskSelected({ taskId }));
  }

  goBackToMessagesFromTaskDetails() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.messageService.openMessagesInteractionBar(true);
  }

  disableTaskActions(actions: OptionsActionList, task: ITask) {
    if (!task?.files?.length) {
      const action: OptionsAction = actions.find((a) => a.value === OPTIONS.DOWNLOAD);
      if (action) {
        action.disabled = true;
      }
    }
    if (task?.author?.user_id !== this.userService.data?.id) {
      const action: OptionsAction = actions.find((a) => a.value === OPTIONS.DELETE);
      if (action) {
        action.disabled = true;
      }
    }
    return actions;
  }

  private convertArrayParams(filters: Partial<ITaskFilters>) {
    const convertedFilters = { ...filters };
    if (filters?.project_filter) {
      convertedFilters['project_filter[]'] = filters.project_filter;
      delete convertedFilters.project_filter;
    }
    if (filters?.status_filter) {
      convertedFilters['status_filter[]'] = filters.status_filter;
      delete convertedFilters.status_filter;
    }
    return convertedFilters;
  }

  /**
   * similar to the DriveService.buildZipBodyStructure but this is simpler, does not care of folders
   * @private
   */
  private buildZipStructure(task: ITask): IZipStructure {
    let size = 0;
    const body: IZipStructure = { files: [], children: [] };
    task.files.forEach((file) => {
      size += file.size ?? 0;
      body.files.push(file);
    });
    body.size = size;
    console.warn('download zip body', body);
    body.parentFolderId = `task_${task.id}`;
    return body;
  }
}
