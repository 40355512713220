import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-back-button-box',
  template: `
    <span (click)="clicked.next()" class="outer-container flex gap-4 items-center cursor-pointer">
      <span class="arrow-left"></span>
      <span><ng-content></ng-content></span>
    </span>
  `,
  styleUrls: ['back-button-box.component.scss'],
  standalone: true,
})
export class BackButtonBoxComponent implements OnInit {
  // activates hover state styles if set to true.
  // useful when there is text next to it and you want to change styles on text hover.
  @Output() clicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
}
