<ng-scrollbar class="std-scrollbar login-scrollbar" appearance="compact">
  <div class="login-wrapper">
    <p class="title">Password Reset</p>
    <p class="text mb-1">Enter your email address below and we will contact you.</p>
    <p class="text mt-0 mb-1 text-color_reject" [ngClass]="{ 'opacity-0': !message }">
      {{ message ? message : 'hi there!' }}
    </p>
    <div class="form" (keyup.enter)="send()">
      <app-floating-input [formControl]="email" label="Email" placeholder="Email" ngDefaultControl>
      </app-floating-input>
      <button class="mt-2 landing-btn btn-big yellow-btn cubic" [disabled]="!email.valid" mat-button (click)="send()">
        SEND REQUEST
      </button>
      <button class="mt-8 landing-btn btn-big w-100 outline-btn cubic" mat-button routerLink="/auth/login">
        LOGIN INSTEAD
      </button>
    </div>
  </div>
</ng-scrollbar>
