<div class="taskForm">
  <form name="taskForm" role="form" class="flex flex-col w-100" #taskForm="ngForm">
    <app-dropdown
      [optionLabel]="null"
      [optionValue]="null"
      name="type"
      label="Task Type"
      placeholder="Task Type"
      [options]="taskTypes"
      [(model)]="model.type"
      (modelChange)="updateSidebarTask()"
      required
    >
      <ng-template #selectedContent #optionContent let-option>
        {{ option | titlecase }}
      </ng-template>
    </app-dropdown>

    <app-floating-input
      (focusout)="updateSidebarTask()"
      name="title"
      [(model)]="model.title"
      required
      label="Title"
      placeholder="Title"
    ></app-floating-input>

    <app-input-calendar
      name="start_date"
      #start_date
      [required]="true"
      [isDisabled]="false"
      [date]="model.start_date"
      (dateChanged)="this.model.start_date = $event; updateSidebarTask(); configureDates()"
      [minStartDate]="null"
      placeholder="Start Date"
    ></app-input-calendar>

    <app-input-calendar
      name="end_date"
      #end_calendar
      [isDisabled]="!model.start_date"
      [iconClass]="{
        iconPosition: 'p-input-icon-right',
        icon: 'icon icon-add rotate-45 text-color_secondary hover:text-color_reject pointer-cursor',
        hideIcon: !model?.end_date
      }"
      [date]="model.end_date"
      [minStartDate]="model?.start_date"
      placeholder="Due Date"
      (dateChanged)="this.model.end_date = $event; updateSidebarTask()"
      (iconClicked)="resetDueDate()"
    ></app-input-calendar>

    <app-dropdown
      optionLabel="name"
      optionValue="value"
      name="status"
      label="Status"
      placeholder="Status"
      [options]="taskStatuses"
      [(model)]="model.status"
      (focusout)="updateSidebarTask($event)"
      required
    >
      <ng-template #selectedContent #optionContent let-option>
        {{ option.name | titlecase }}
      </ng-template>
    </app-dropdown>

    <app-dropdown
      optionLabel="title"
      optionValue="id"
      name="project"
      label="Projects"
      placeholder="Projects"
      [options]="possibleProjects"
      [(model)]="model.project_id"
      (modelChange)="model.project_id = $event; updateSidebarTask()"
      required
    >
      <ng-template #selectedContent #optionContent let-option>
        {{ option.title | titlecase }}
      </ng-template>
    </app-dropdown>

    <app-floating-textarea
      class="pr-0.5 pl-0.5"
      label="Task Summary"
      placeholder="Task Summary"
      name="summary"
      [(model)]="model.summary"
      (focusout)="updateSidebarTask()"
    ></app-floating-textarea>
  </form>
</div>
