import { COMMITMENTS_TYPE } from '../../framework/constants/interaction-bar.constants';
import {
  ISidebarChangeOrder,
  ISidebarCommitmentItem,
  ISidebarContract,
  ISidebarCost,
  ISidebarDirectCost,
  ISidebarInvoice,
  ISidebarInvoiceCost,
} from './commitments.types';

export const defaultCost: Partial<ISidebarCost> = {
  name: '',
  value: null,
  parent_id: null,
  is_dropdown: false, // not stored on backend
  is_disabled: false,
  deleted: false,
};

export const defaultInvoiceCost: ISidebarInvoiceCost = {
  ...(defaultCost as ISidebarCost),
  is_dropdown: true,
  budget_tag: { id: null, name: '' },
  item: { name: '', id: null },
};

export const defaultCommitmentItem: ISidebarCommitmentItem = {
  duration: null,
  start_date: null,
  costs: [],
  deleted_costs: [],
  item: {
    id: null,
    name: '',
  },
  budget_tag: {
    id: null,
    name: '',
  },
};

export const defaultCommitmentItemWithCost: ISidebarCommitmentItem = {
  duration: null,
  start_date: null,
  costs: [{ ...defaultCost, is_dropdown: true }],
  deleted_costs: [],
  item: {
    id: null,
    name: '',
  },
  budget_tag: {
    id: null,
    name: '',
  },
};

export const defaultDirectCostCommitmentItem: Partial<ISidebarCommitmentItem> = {
  costs: [],
  deleted_costs: [],
  item: {
    id: null,
    name: '',
  },
  budget_tag: {
    id: null,
    name: '',
  },
};

export const defaultContract: ISidebarContract = {
  project_id: null,
  type: COMMITMENTS_TYPE.CONTRACTS,
  added_at: null,
  title: null,
  description: null,
  approval_status: null,
  files: [],
  removeFileIds: [],
  commitment_items: [{ ...defaultCommitmentItem }],
  deleted_commitment_items: [],
  service_provider: {
    name: '',
    service_provider_user_id: null,
    temporary_service_provider_id: null,
  },
};

export const defaultDirectCost: ISidebarDirectCost = {
  project_id: null,
  type: COMMITMENTS_TYPE.DIRECT_COST,
  vendor: null,
  added_at: null,
  title: null,
  description: null,
  paid_status: null,
  paid_date: null,
  files: [],
  removeFileIds: [],
  commitment_items: [{ ...defaultDirectCostCommitmentItem }],
  deleted_commitment_items: [],
};

export const defaultChangeOrder: ISidebarChangeOrder = {
  project_id: null,
  type: COMMITMENTS_TYPE.CHANGE_ORDER,
  added_at: null,
  contract_id: null,
  title: null,
  description: null,
  approval_status: null,
  files: [],
  removeFileIds: [],
  commitment_items: [{ ...defaultCommitmentItem }],
  deleted_commitment_items: [],
  service_provider: {
    name: '',
    service_provider_user_id: null,
    temporary_service_provider_id: null,
  },
};

export const defaultInvoice: ISidebarInvoice = {
  project_id: null,
  type: COMMITMENTS_TYPE.INVOICES,
  added_at: null,
  paid_date: null,
  contract_id: null,
  title: null,
  description: null,
  paid_status: null,
  files: [],
  removeFileIds: [],
  costs: [{ ...defaultInvoiceCost }],
  deleted_costs: [],
  budget_tag: {
    name: '',
    id: null,
  },
  service_provider: {
    name: '',
    service_provider_user_id: null,
    temporary_service_provider_id: null,
  },
};
