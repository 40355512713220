// TODO: use all options and refactor options-list

import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';

export type OptionsAction =
  | { class: string; value: string; icon: string; disabled?: boolean }
  | string
  | any;

export type OptionsActionList = Array<OptionsAction>;

// todo: remove options view if not used
export enum OPTIONS_VIEW {
  DEFAULT_OPTIONS_LIST,
  BUDGET_LINE_ITEM_VIEW,
  BUDGET_LINE_ITEM_TABLE_VIEW,
  MESSAGES_USERS_TABLE,
  MESSAGES_TAG_USERS,
}

export enum SELECTED_OPTION {
  SELECT,
  DESELECT,
  REMOVE,
  ADD,
}

export const optionOverlayPositions: ConnectedPosition[] = [
  {
    originX: 'end',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 0,
  },
  {
    originX: 'start',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 0,
  },
  {
    originX: 'start',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -0,
  },
];

export const dropdownOverlayPositions: ConnectedPosition[] = [
  {
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 9,
    offsetX: 0,
  },
];

export const defaultDropdownOverlayPositions: ConnectedPosition[] = [
  {
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 10,
    offsetX: -5,
  },
  {
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -10,
    offsetX: 5,
  },
];

export const dropdownAlignRight: ConnectedPosition[] = [
  {
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 9,
  },

  {
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -9,
  },
];

export const oldOptionOverlayPositions: ConnectedPosition[] = [
  { originX: 'start', originY: 'bottom', overlayX: 'center', overlayY: 'bottom' },
  { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
  { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
];

export const driveOptionOverlayPositions = [
  new ConnectionPositionPair(
    { originX: 'center', originY: 'bottom' },
    { overlayX: 'center', overlayY: 'top' },
    60,
  ),
  new ConnectionPositionPair(
    { originX: 'center', originY: 'top' },
    { overlayX: 'center', overlayY: 'bottom' },
    60,
  ),
];

export const driveHeaderOptionOverlayPositions = [
  new ConnectionPositionPair(
    { originX: 'center', originY: 'bottom' },
    { overlayX: 'center', overlayY: 'top' },
    40,
    10,
  ),
  new ConnectionPositionPair(
    { originX: 'center', originY: 'top' },
    { overlayX: 'center', overlayY: 'bottom' },
    40,
    10,
  ),
];

export const assignTeammateOverlayPositions: ConnectedPosition[] = [
  // try to open the overlay in this order - the first one that fits will be used
  {
    // open towards down and right
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 5,
    offsetX: 0,
  },
  {
    // open towards down and left
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetY: 5,
    offsetX: -0,
  },
  {
    // open towards up and left
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -5,
    offsetX: 0,
  },
  {
    // open towards up and right
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -5,
    offsetX: 0,
  },
];
