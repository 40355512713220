import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SpendViewProjectStateService } from '../../../../../services/spend-view-project-state.service';
import { ProjectStateService } from '../../../../../services/project-state.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { SpreadsheetComponent } from '../../../../../framework/spreadsheet/spreadsheet.component';
import { SPEND_TYPES } from '../../../../../framework/constants/budget.constants';
import { AppState } from '../../../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  getDisableBasedOnTemplate,
  getFilteredLineItems,
  getIsLoading,
  getLineItemTotals,
  getModifiedLineItems,
  getProjectStartDate,
  getSelectableYears,
  getSelectedSpendType,
  getSelectedYear,
  getStoreUpdates,
  hasCommitments,
} from '../../../../../store/spend/spend.selectors';
import {
  DistributionFieldType,
  IFilteredLineItem,
  ILineItem,
  ILineItemsTotal,
  ISpendDistribution,
  ISpreadSheetChange,
  SpendStoreUpdateTypes,
  SpreadSheetInputData,
} from '../../../../../store/spend/spend.interfaces';
import {
  addDefaultLineItem,
  addNewYear,
  clearMonths,
  deleteLineItem,
  saveToBackend,
  setSelectedSpendType,
  setSelectedYear,
  updateDistribution,
  updateLineItem,
} from '../../../../../store/spend/spend.actions';
import {
  defaultLineItemsTotal,
  defaultMonthlyData,
  DISTRIBUTION_TYPES,
  distributionTypes,
  initialProjectTotalProp,
  MONTHS,
  MONTHS_KEYS,
  SPEND_DISTRIBUTION_STATE,
  typeToDistProp,
  typeToDurationProp,
  typeToProp,
  typeToStartDateProp,
} from '../../../../../framework/constants/spend.constants';
import { debounceTime, delay, filter, take, takeUntil, tap } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { NgScrollbar, ScrollViewport } from 'ngx-scrollbar';
import { getSpreadSheetData } from '../spend/spend.converter';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { SpendState } from '../../../../../store/spend/spend.reducer';
import { AddProjectBase } from '../AddProjectBase';
import { NgForm } from '@angular/forms';
import { AsyncPipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { DropdownComponent } from '../../../../../framework/inputs/dropdown/dropdown.component';
import { SpendCustomInputComponent } from '../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { InputCalendarComponent } from '../../../../../framework/inputs/input-calendar/input-calendar.component';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import { TooltipModule } from 'primeng/tooltip';
import { getIsLoading as areTemplatesLoading } from '../../../../../store/settings/settings.selectors';
import { viewProjectSelectors } from '../../../../../store/view-project/view-project.selectors';
import { IBudgetTemplate } from '../../../../../store/settings/settings.interface';
import { ProjectApiService } from '../../../../../services/project-api.service';
import { Project } from '../../projects.interface';
import { viewProjectActions } from '../../../../../store/view-project/view-project.actions';
import { VIEW_PROJECT_REFRESH_CAUSE } from '../../../../../framework/constants/view-project.constants';
import { SimpleButtonComponent } from '../../../../../framework/buttons/simple-medium-button/simple-button.component';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DeepCopyService } from '../../../../../services/deep-copy.service';

@Component({
  selector: 'app-spend-distribution',
  templateUrl: './spend-distribution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./spend-distribution.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    SpreadsheetComponent,
    DropdownComponent,
    NgClass,
    NgScrollbar,
    SpendCustomInputComponent,
    InputCalendarComponent,
    NgForOf,
    FloatingInputComponent,
    TooltipModule,
    JsonPipe,
    SimpleButtonComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkScrollable,
    ScrollViewport,
  ],
})
export class SpendDistributionComponent
  extends AddProjectBase
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('spreadsheet') spreadsheet: SpreadsheetComponent;
  @ViewChild('scrollbar') ngScrollbar: NgScrollbar;
  @ViewChild('leftSide') leftSide: ElementRef;

  spreadsheetData: SpreadSheetInputData;

  SPEND_TYPES = SPEND_TYPES;
  SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE;
  selectedBudgetType: SPEND_TYPES = SPEND_TYPES.BUDGET;
  selectedYear = 0;

  selectedYear$ = this.store.select(getSelectedYear);
  selectedBudgetType$ = this.store.select(getSelectedSpendType);
  possibleYears$ = this.store.select(getSelectableYears);
  lastStoreUpdate$ = this.store.select(getStoreUpdates);
  isLoading$ = this.store.select(getIsLoading);
  projectStartDate$ = this.store.select(getProjectStartDate);
  modifiedLineItems$ = this.store.select(getModifiedLineItems);
  totalLineItems$ = this.store.select(getLineItemTotals);
  spendState$ = this.state.currentState;
  disableOnTemplate$ = this.store.select(getDisableBasedOnTemplate); // mainly from add project
  templates$ = this.store.select(viewProjectSelectors.getProjectTemplates);
  areTemplatesLoading$ = this.store.select(areTemplatesLoading);
  selectedProject$ = this.store.select(viewProjectSelectors.getSelectedProject);
  hasCommitments$ = this.store.select(hasCommitments);
  currentProject: Project;
  templateId: number = null;
  oldTemplateId: number = null;
  isDestroyed$ = new Subject<void>();
  subs = new Subscription();
  possibleYearsOptions = [];
  isDragging = false;

  templates: IBudgetTemplate[] = [];
  DEFAULT_TEMPLATE_NAME = 'Default';
  DEFAULT_TEMPLATE_ID = null; // 0 is not valid as dropdown does not detect if value is 0, please leave it at -1

  items: IFilteredLineItem[] = [];
  distributionTypes = distributionTypes;
  DISTRIBUTION_TYPES = DISTRIBUTION_TYPES;
  totals: ILineItemsTotal = defaultLineItemsTotal;
  MONTHS = cloneDeep(MONTHS);
  MONTHS_KEYS = MONTHS_KEYS;
  DATE_FORMAT = 'YYYY-MM-DD';
  projectStartDate: string;

  durationMonths = Array(72)
    .fill(0)
    .map((_, i) => i + 1)
    .map((i) => {
      return {
        label: i === 1 ? `${i} month` : `${i} months`,
        value: i,
      };
    });
  spendBudgetTypes = [
    { name: SPEND_TYPES.BUDGET },
    { name: SPEND_TYPES.FORECAST },
    { name: SPEND_TYPES.ACTUALS },
  ];
  loadingHash;
  typeToDistProp = typeToDistProp;
  initialProjectTotalProp = initialProjectTotalProp;
  typeToStartDateProp = typeToStartDateProp;
  typeToDurationProp = typeToDurationProp;
  typeToProp = typeToProp;
  minDates: Array<Date | moment.Moment | string> = [];
  // maxDates: Array<Date | moment.Moment | string> = []; -- not used, but may be used later
  ADD_YEAR = -1;

  protected readonly Number = Number;

  constructor(
    private projectState: ProjectStateService,
    private notif: NotificationsService,
    private interactionBarState: InteractionBarStateService,
    private state: SpendViewProjectStateService,
    private userService: CurrentUserService,
    public store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private projectApi: ProjectApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.setMonths();
    this.createStoreSubscriptions();

    this.getInitialLineItems();
    this.handlePossibleYearsOptions();

    // this.store.dispatch(settingsActions.loadBudgetTemplate());
  }

  ngAfterViewInit() {
    if (this.projectState.isEdit) {
      this.state.currentState.next(SPEND_DISTRIBUTION_STATE.EDIT_PROJECT);
    }
    if (this.projectState.isAddProject) {
      this.state.currentState.next(SPEND_DISTRIBUTION_STATE.ADD_PROJECT);
    }

    if (!this.projectState.isEdit && !this.projectState.isAddProject) {
      this.state.currentState.next(SPEND_DISTRIBUTION_STATE.DEFAULT);
    }
    this.setScrollPosition();
  }

  createStoreSubscriptions() {
    this.subs.add(this.lastStoreUpdate$.pipe(debounceTime(300)).subscribe(this.onStoreUpdate));
    this.subs.add(
      this.isLoading$.subscribe((isLoading) => {
        isLoading ? (this.loadingHash = this.notif.showLoading()) : this.notif.close();
      }),
    );
    this.subs.add(
      this.projectStartDate$.subscribe((date) => {
        this.projectStartDate = date;
        this.buildDatesLimits();
      }),
    );
    this.subs.add(
      this.selectedYear$.subscribe((year) => {
        this.selectedYear = year;
        this.setAllDisabledMonths();
      }),
    );
    this.subs.add(
      this.selectedBudgetType$.subscribe((type) => {
        this.selectedBudgetType = type;
      }),
    );
    this.subs.add(
      this.spendState$
        .pipe(
          filter((value) => value === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET),
          tap((_) => (this.spreadsheetData = undefined)),
          delay(100),
        )
        .subscribe(this.onOpenSpreadSheet),
    );
    this.subs.add(
      this.totalLineItems$.subscribe((totals) => {
        this.totals = totals;
        this.changeDetectorRef.detectChanges();
      }),
    );

    combineLatest([this.templates$, this.selectedProject$])
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(([_, project]) => !!project),
      )
      .subscribe(([templates, project]) => {
        // Handle templates
        const defaultTemplate: IBudgetTemplate = {
          name: this.DEFAULT_TEMPLATE_NAME,
          id: this.DEFAULT_TEMPLATE_ID,
          template_items: [],
        };

        this.templates = [defaultTemplate, ...templates];

        // Handle selected project
        this.currentProject = project;
        this.templateId = project?.budget_template_id ?? this.DEFAULT_TEMPLATE_ID;
        this.oldTemplateId = project.budget_template_id;
        this.changeDetectorRef.detectChanges();
      });
  }

  onStoreUpdate = (storeUpdate) => {
    switch (storeUpdate.lastStoreUpdate.type) {
      case SpendStoreUpdateTypes.SET_ALL: {
        this.setAllLineItems(storeUpdate.lineItems);
        break;
      }
      case SpendStoreUpdateTypes.ADD: {
        const localLine = this.getLineWithDisabledMonths(storeUpdate.lineItems[0]);
        this.items.push(localLine);
        this.buildDatesLimits();
        break;
      }
      case SpendStoreUpdateTypes.UPDATE: {
        this.updateLineLocally(storeUpdate.lineItems[0]);
        break;
      }
      case SpendStoreUpdateTypes.DELETE: {
        this.deleteLineLocally(storeUpdate.lastStoreUpdate.lineId);
        break;
      }
      case SpendStoreUpdateTypes.FILTER_CHANGE: {
        storeUpdate.lineItems.forEach((item) => {
          this.updateLineLocally(item);
        });
        break;
      }
    }
    this.changeDetectorRef.detectChanges();
  };

  setAllLineItems(lineItems: IFilteredLineItem[]) {
    const localLinesAccumulator: IFilteredLineItem[] = [];
    lineItems.forEach((item) => {
      const localLine = this.getLineWithDisabledMonths(item);
      localLinesAccumulator.push(localLine);
    });

    // UI does not freeze this way
    setTimeout(() => {
      this.items = localLinesAccumulator;
      this.buildDatesLimits();
      this.changeDetectorRef.detectChanges();
      this.ngScrollbar?.update();
    }, 10);
  }

  getInitialLineItems() {
    // this component is recreated every time it is opened
    // but the SET_ALL update type is called once,
    // so we need to set the line items on every component creation
    this.store
      .select(getFilteredLineItems)
      .pipe(
        filter((value) => !!value),
        debounceTime(300),
        take(1),
      )
      .subscribe((lineItems) => {
        this.setAllLineItems(lineItems);
      });
  }

  addLineToStore() {
    this.store.dispatch(addDefaultLineItem());
  }

  deleteLineFromStore(id: number) {
    this.store.dispatch(deleteLineItem({ id }));
  }

  updateLineLocally(item: IFilteredLineItem) {
    const newItem = cloneDeep(item);
    const ind = this.items.findIndex((it) => it.id === newItem.id);
    if (ind < 0) {
      return;
    }
    this.setModifiedItem(ind, newItem);
    this.setDisabledMonth(this.items[ind]);
  }

  getLineWithDisabledMonths(item: IFilteredLineItem) {
    item = cloneDeep(item);
    this.setDisabledMonth(item);
    return item;
  }

  deleteLineLocally(id: number) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  setModifiedItem(index: number, newItem: IFilteredLineItem) {
    this.items[index].id = newItem.id;
    this.items[index].name = newItem.name;
    this.items[index].project_total = newItem.project_total;
    this.items[index].year_total = newItem.year_total;
    this.items[index].start_date = newItem.start_date;
    this.items[index].forecast_start_date = newItem.forecast_start_date;
    this.items[index].commitment_start_date = newItem.commitment_start_date;
    this.items[index].duration = newItem.duration;
    this.items[index].forecast_duration = newItem.forecast_duration;
    this.items[index].budget_distribution = newItem.budget_distribution;
    this.items[index].forecast_distribution = newItem.forecast_distribution;
    this.items[index].budget = newItem.budget;
    this.items[index].row_number = newItem.row_number;
    Object.entries(this.items[index].monthly_data).forEach(([key, value]) => {
      if (value !== newItem.monthly_data[key]) {
        this.items[index].monthly_data[key] = cloneDeep(newItem.monthly_data[key]);
      }
    });
  }

  setBudgetLock(val: boolean) {
    SpendViewProjectStateService.isBudgetLocked = val;
  }

  onOpenSpreadSheet = (_) => {
    this.possibleYears$.pipe(take(1)).subscribe((years) => {
      const items = [...getSpreadSheetData(this.items, years)];
      const isEditable = [];
      this.items.forEach((item) => {
        const isEditableItem = {};
        item.budget.forEach((budget) => {
          isEditableItem[budget.year] = this.getDisabledMonths(budget.year, item);
        });
        isEditable.push(isEditableItem);
      });

      this.spreadsheetData = {
        items,
        isEditable,
        selectedYear: this.selectedYear,
      };
      console.log('spreadsheet data', this.spreadsheetData);
      this.changeDetectorRef.detectChanges(); // otherwise spreadsheet won't open

      // const spreadSheetData = toSpreadshet(itemsToShow, this.selectedBudgetType, {
      //   '-2': this.selectedBudgetType,
      //   ...this.MONTHS,
      // });

      // if (this.spreadsheet) {
      //   this.spreadsheet.loading = true;
      // }
    });
  };

  getIsBudgetLocked() {
    return SpendViewProjectStateService.isBudgetLocked;
  }

  openSpreadsheet() {
    this.state.currentState.next(SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET);
  }

  setScrollPosition(count: number = 0) {
    count++;
    if (count > 50) {
      return;
    }
    const scrollBarX = this?.ngScrollbar?.nativeElement?.getElementsByTagName('scrollbar-x');
    if ((scrollBarX?.[0] as HTMLElement) && this.leftSide.nativeElement.clientWidth) {
      (scrollBarX[0] as HTMLElement).style.left = this.leftSide.nativeElement.clientWidth + 'px';
      this.changeDetectorRef.detectChanges();
      this.ngScrollbar.update();
    } else {
      of([])
        .pipe(delay(100))
        .subscribe(() => this.setScrollPosition(count));
    }
  }

  registerSheetChange(change: ISpreadSheetChange) {
    console.log('sheet change', change);
    const key = typeToProp[this.selectedBudgetType];
    const lineItem = cloneDeep(this.items[change.lineIndex]);
    if (!lineItem) {
      return;
    }

    const budgetByYear = lineItem.budget.find((bud) => bud.year === change.sheetYear);
    budgetByYear[key][change.monthIndex] = change.newValue;
    this.store.dispatch(updateLineItem({ lineItem }));
  }

  setBudgetType(value: SPEND_TYPES) {
    this.selectedBudgetType = value;
    this.store.dispatch(setSelectedSpendType({ spendType: value }));
  }

  setSelectedYear(value: number) {
    if (value !== this.ADD_YEAR) {
      this.store.dispatch(setSelectedYear({ year: value }));
      return;
    }
    this.store.dispatch(addNewYear());
  }

  trackByIndex = (index: number) => index;
  trackByItemId = (_: number, item: IFilteredLineItem) => item.id;

  clearMonths() {
    // currently feature is not used
    this.store.dispatch(clearMonths());
  }

  clearName(item: IFilteredLineItem) {
    if (item.name === 'Project Cost') {
      item.name = '';
    }
  }

  showName(item: IFilteredLineItem) {
    if (!item.name) {
      item.name = 'Project Cost';
      this.registerChangeName(item.name, item);
    }
  }

  registerChangeName(name: string, item: IFilteredLineItem) {
    const lineItem: Partial<ILineItem> = cloneDeep(item);
    lineItem.name = name;
    this.store.dispatch(updateLineItem({ lineItem }));
  }

  registerChange(inputValue: number, item: IFilteredLineItem, month: string) {
    const newValue = inputValue;
    const lineItem: Partial<ILineItem> = cloneDeep(item);
    let budget = lineItem.budget.find((el) => el.year === this.selectedYear);
    const spendKey = typeToProp[this.selectedBudgetType];
    if (!budget) {
      budget = {
        year: this.selectedYear,
        monthly_budget: { ...defaultMonthlyData },
        monthly_forecast: { ...defaultMonthlyData },
        monthly_actuals: { ...defaultMonthlyData },
      };
      lineItem.budget.push(budget);
    }

    if (!isNaN(newValue)) {
      budget[spendKey][month] = newValue;
    }

    lineItem[typeToDistProp[this.selectedBudgetType]] = DISTRIBUTION_TYPES.MANUAL;

    this.store.dispatch(updateLineItem({ lineItem }));
  }

  setItemTotal(total: string, item: IFilteredLineItem) {
    const value = parseFloat(total);
    item.project_total = isNaN(value) ? 0 : value;
  }

  setStartDate(date: string, item: IFilteredLineItem) {
    item[typeToStartDateProp[this.selectedBudgetType]] = date;
  }

  setItemDuration(duration: number, item: IFilteredLineItem) {
    item[typeToDurationProp[this.selectedBudgetType]] = duration;
  }

  setItemDistribution(distribution: DISTRIBUTION_TYPES, item: IFilteredLineItem) {
    item[typeToDistProp[this.selectedBudgetType]] = distribution;
  }

  calcDistribution(item: IFilteredLineItem) {
    if (this.selectedBudgetType === SPEND_TYPES.ACTUALS) {
      return;
    }
    const field = typeToProp[this.selectedBudgetType] as DistributionFieldType;

    const distribution: ISpendDistribution = {
      distribution: item[typeToDistProp[this.selectedBudgetType]],
      start_date: moment(item[typeToStartDateProp[this.selectedBudgetType]]).format(
        this.DATE_FORMAT,
      ),
      field,
      duration: item[typeToDurationProp[this.selectedBudgetType]],
      budget: item.project_total,
    };
    this.store.dispatch(updateDistribution({ lineId: item.id, distribution }));
  }

  setMonths() {
    let fyStart = this.userService.data.fiscal_year_start;

    if (fyStart) {
      fyStart = fyStart - 1;
      for (let i = 0; i < fyStart; i++) {
        const aux = this.MONTHS[0];
        this.MONTHS.shift();
        this.MONTHS.push(aux);
      }
    }
  }

  update() {
    this.setScrollPosition();
    this.setAllDisabledMonths();
  }

  canGoBack(): Promise<boolean> {
    return new Promise<boolean>((res) => {
      this.modifiedLineItems$.pipe(take(1)).subscribe(async (data: Partial<SpendState>) => {
        if (data.modifiedLineItems.size || data.deletedLineItems.size || data.newLineItems.size) {
          const shouldSave = await this.notif.showPopup(
            'Do you want to save budget before going back? Unsaved line items might be lost.',
          );
          if (shouldSave) {
            this.store.dispatch(saveToBackend());
          }
        }
        res(true);
      });
    });
  }

  minDateFilter() {
    if (!this.projectStartDate) {
      return;
    }
    return this.projectStartDate;
  }

  // maxDateFilter(item: ILineItem) {
  //   if (!item?.commitment_start_date || this.selectedBudgetType === SPEND_TYPES.BUDGET) {
  //     return null;
  //   }
  //
  //   if (item.commitment_start_date) {
  //     return item.commitment_start_date;
  //   }
  // }

  buildDatesLimits() {
    this.minDates = [];
    // this.maxDates = []; -- not used, but may be used later
    this.items.forEach((item) => {
      this.minDates.push(this.minDateFilter());
      // this.maxDates.push(this.maxDateFilter(item));
    });
  }

  setAllDisabledMonths() {
    this.items.forEach((item) => {
      this.setDisabledMonth(item);
    });
  }

  setDisabledMonth(item: IFilteredLineItem) {
    const availableMonths = this.getDisabledMonths(this.selectedYear, item);

    // disable all months that should be non-editable
    Object.keys(item.monthly_data).forEach((monthKey, index) => {
      item.monthly_disable[monthKey] = !availableMonths[index]; // key starts with 1
    });

    // Object.keys(item.monthly_data).forEach((monthKey, index) => {
    //   item.monthly_disable[monthKey] = !availableMonths[index]; // key starts with 1
    // });
  }

  getDisabledMonths(year: number, item: ILineItem) {
    const availableMonths = Array(12).fill(false);
    if (this.selectedBudgetType === SPEND_TYPES.ACTUALS || year === 0) {
      return availableMonths;
    }

    const fyStart = this.userService.data.fiscal_year_start - 1;
    const startDate = item[typeToStartDateProp[this.selectedBudgetType]];
    const duration = item[typeToDurationProp[this.selectedBudgetType]];
    const itemStart = moment(startDate);
    const itemEnd = moment(startDate).add(duration, 'month');

    // set to fiscal year start, just as in the header
    const currentMonth = moment(`${year}-01-01`, this.DATE_FORMAT);
    if (fyStart) {
      currentMonth.subtract(12 - fyStart, 'month');
    }

    // set all months that should be available, months by default are unavailable
    availableMonths.forEach((month, index) => {
      const budget = item.budget.find((bud) => bud.year === this.selectedYear);
      const hasFieldValue =
        budget?.[this.typeToProp[this.selectedBudgetType]][index + 1] !== 0 &&
        budget?.[this.typeToProp[this.selectedBudgetType]][index + 1] !== undefined;
      const isManualDistribution =
        item[typeToDistProp[this.selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL;
      const isSameOrAfterStart = currentMonth.isSameOrAfter(itemStart, 'month');
      const isInsideLineDuration = isSameOrAfterStart && currentMonth.isBefore(itemEnd, 'month');
      if (
        isInsideLineDuration ||
        (hasFieldValue && isSameOrAfterStart) ||
        (isManualDistribution && isSameOrAfterStart)
      ) {
        availableMonths[index] = true;
      }
      currentMonth.add(1, 'month');
    });

    return availableMonths;
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
    this.subs.unsubscribe();
    SpendViewProjectStateService.isBudgetLocked = true;
    console.log('spend distribution ON DESTROY');
  }

  private handlePossibleYearsOptions() {
    this.possibleYears$.pipe(takeUntil(this.isDestroyed$)).subscribe((years) => {
      this.possibleYearsOptions = years.map((year) => {
        return {
          key: year,
          value: year,
        };
      });
      this.possibleYearsOptions.push({
        key: 'Add Year',
        value: this.ADD_YEAR,
      });
    });
  }

  /**
   * Check if the project total is equal to the initial line item total
   * for manual distributions
   * only if the commitment start date is set
   */
  manualDistributionTotalsMatch() {
    return this.items.some(
      (item) =>
        item.project_total !== item[initialProjectTotalProp[this.selectedBudgetType]] &&
        !!item.commitment_start_date &&
        item[typeToDistProp[this.selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL,
    );
  }

  setTemplate(templateId: number) {
    if (SpendViewProjectStateService.isBudgetLocked) {
      this.notif.showError('Please make sure budget is unlocked before changing templates.');
      this.changeDetectorRef.detectChanges();
      this.templateId = this.oldTemplateId;
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.notif
      .showPopup(
        'You are about to reset project templates. This change is irreversible. Are you sure you want to continue?',
      )
      .then((resetBudget) => {
        if (!resetBudget) {
          this.changeDetectorRef.detectChanges();
          this.templateId = this.oldTemplateId;
          this.changeDetectorRef.detectChanges();
          return;
        }
        this.projectApi
          .updateProject({
            id: this.currentProject.id,
            start_date: this.currentProject.start_date,
            status: this.currentProject.status,
            title: this.currentProject.title,
            property_id: this.currentProject.property_id,
            budget_template_id: templateId,
          })
          .then(
            (data) => {
              // todo: reload only project's budget template selector and line items
              this.store.dispatch(
                viewProjectActions.refreshNeeded({
                  cause: VIEW_PROJECT_REFRESH_CAUSE.SPEND_SAVE,
                }),
              );
              this.changeDetectorRef.detectChanges();
              this.templateId = templateId;
              this.oldTemplateId = templateId;
              this.changeDetectorRef.detectChanges();
            },
            (err) => {
              this.notif.showError('Error setting template');
              this.changeDetectorRef.detectChanges();
              this.templateId = this.oldTemplateId;
              this.changeDetectorRef.detectChanges();
            },
          );
      });
  }

  drop(event: CdkDragDrop<ILineItem, any>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    const index =
      event.currentIndex > event.previousIndex ? event.currentIndex : event.previousIndex;
    this.setRowNumberUntilIndex(index);
  }

  setRowNumberUntilIndex(index: number) {
    this.items.forEach((item, lineIndex) => {
      if (lineIndex <= index) {
        item.row_number = lineIndex;
        this.store.dispatch(updateLineItem({ lineItem: DeepCopyService.deepCopy(item) }));
      }
    });
  }
}
