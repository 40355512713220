import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import {
  AbstractControl,
  ControlContainer,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';

/**
 * A wrapper around primeng's dropdown component. <br/>
 * You can give it an <code><ng-template #optionContent let-option></ng-template></code> if you want custom options.
 * The <code>#optionContent</code> part is mandatory.<br/>
 * Or you can use it with the default options presentation. <br/>
 * @example
 *   <app-dropdown
 *    label="Project Status"
 *    [options]="projectStatuses"
 *    optionValue="key"
 *    optionLabel="name"
 *    [(model)]="model.status"
 *   >
 *    <ng-template #optionContent let-option>
 *      <div class="text-color_reject">{{ option?.name }}</div>
 *    </ng-template>
 *    <ng-template #selectedContent let-option>
 *      <div class="text-color_accent">{{ option?.name }}</div>
 *    </ng-template>
 *   </app-dropdown>
 *
 */
@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule, ReactiveFormsModule, MultiSelectModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
})
export class DropdownComponent implements OnInit, OnDestroy {
  private _model: any;
  // see: https://stackoverflow.com/questions/50201167/primeng-dropdown-nested-ng-template
  @ContentChild('optionContent') optionContent: TemplateRef<ElementRef>;
  @ContentChild('selectedContent') selectedContent: TemplateRef<ElementRef>;
  @ViewChild('dropdown', { read: NgModel }) dropdown: NgModel;

  @Input() showLabel = true;
  @Input() name = 'input';
  @Input() required = false;
  @Input() placeholder: string;
  @Input() optionValue = 'value';
  @Input() optionLabel = 'label';
  @Input() autoDisplayFirst = false;
  @Input() disabled = false;
  @Input() appendTo = null;
  @Input() showError = true;
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() ngModelOptions: { standalone: boolean } = { standalone: false };
  @Input() dropdownClass: ('options-centered' | 'borderless' | 'transparent' | 'view-selector')[] =
    [];
  @Output() modelChange = new EventEmitter<any>();

  @Input() multiple = false;
  @Input() selectedItemsLabel: string | undefined;

  get model(): any {
    return this._model;
  }

  @Input() set model(value: any) {
    this._model = value;
  }

  @Input() set formControlName(value: string) {
    // just set the input's name
    if (value) {
      this.name = value;
    }
  }

  get control(): AbstractControl {
    if (this.ngControl) {
      return this.ngControl.control;
    }
    return this.dropdown?.control;
  }

  isDropdownShown = false;
  ngControl: NgControl;
  isDestroyed$ = new Subject();
  constructor(private injector: Injector) {}

  ngOnInit() {
    try {
      // no ngControl if used with ngModel so injector is needed,
      // otherwise we get a nullInjection error
      this.ngControl = this.injector.get(NgControl);
      setTimeout(() => {
        // otherwise it won't display the control's value
        this.model = this.ngControl?.control?.value ?? '';
        this.ngControl?.control?.valueChanges
          ?.pipe(takeUntil(this.isDestroyed$))
          .subscribe((value) => {
            this.model = value;
          });
      });
    } catch (error) {
      // this is not a real problem, we can just use ngModel's control
    }
  }

  onModelChange($event: any) {
    if (this.ngControl) {
      // this is not the cleanest way to do it, but it works
      this.control?.setValue($event);
    }
    this.modelChange.emit($event);
  }

  onBlur() {
    this.control.markAsTouched();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  /**
   * Not the cleanest way to do it, but it works.
   * Sets the overlay's width to the dropdown's width.
   * Otherwise, the overlay may be too wide because of overflowing text.
   */
  setOverlayWidth(component: Dropdown | MultiSelect) {
    if (!component) {
      // if no dropdown or multiselect, then we can't do anything
      return;
    }

    // @ts-ignore
    const dropdownWidth = component?.el?.nativeElement?.offsetWidth;
    // @ts-ignore
    const overlayContainer: HTMLElement = component?.overlayViewChild?.el?.nativeElement;
    const overlay: HTMLElement = overlayContainer?.querySelector('.p-overlay');

    if (!dropdownWidth || !overlayContainer || !overlay) {
      return;
    }

    overlay.style.left = null;
    overlay.style.maxWidth = `${dropdownWidth}px`;
  }
}
