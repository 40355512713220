import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { projectDocumentActionTypes } from './projectDocuments.actions';
import { of } from 'rxjs';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { getRetrieveDisabled, projDocsFeatureSelector } from './projectDocuments.selectors';
import { DriveApiService } from '../../services/drive-api.service';
import { DriveService } from '../../services/drive.service';
import { documentActionTypes } from '../documents/documents.actions';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class ProjectDocumentsEffects {
  loadProjectDocuments = createEffect(() => {
    return this.actions.pipe(
      ofType(projectDocumentActionTypes.loadProjectDocuments),
      withLatestFrom(this.store.select(getRetrieveDisabled)),
      exhaustMap(([action, retrieveDisabled]) => {
        return this.driveApiService.getProjectDriveAsync(action.projectId, retrieveDisabled).pipe(
          map((data) => {
            return projectDocumentActionTypes.updateAll({
              data,
              projectId: action.projectId,
            });
          }),
        );
      }),
    );
  });

  backProjDoc = createEffect(() =>
    this.actions.pipe(
      ofType(projectDocumentActionTypes.backProjDoc),
      map((action) => {
        return projectDocumentActionTypes.navigateBack();
      }),
      catchError(() => of(projectDocumentActionTypes.cancel())),
    ),
  );

  projectFolderClicked = createEffect(() => {
    return this.actions.pipe(
      ofType(
        projectDocumentActionTypes.projectFolderClicked,
        projectDocumentActionTypes.backProjDoc,
      ),
      switchMap((action) => {
        if (action.type === projectDocumentActionTypes.backProjDoc.type) {
          return of(documentActionTypes.cancel());
        }
        return this.driveApiService.getDriveFolder(action.folderId).pipe(
          map((folder) => {
            return projectDocumentActionTypes.loadProjectFolder({ folder });
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? err?.message);
            return of(documentActionTypes.cancel());
          }),
        );
      }),
    );
  });

  reset = createEffect(() => {
    return this.actions.pipe(
      ofType(projectDocumentActionTypes.reset),
      map(() => {
        return projectDocumentActionTypes.cancel();
      }),
    );
  });

  clearCache = createEffect(
    () => {
      return this.actions.pipe(
        ofType(projectDocumentActionTypes.clearCache),
        tap(() => {
          this.driveService.folderPath = [];
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  clearCacheForNewDrive = createEffect(() => {
    return this.actions.pipe(
      ofType(projectDocumentActionTypes.clearCacheForNewDrive),
      map(() => {
        this.driveService.folderPath = [];
        return projectDocumentActionTypes.cancel();
      }),
    );
  });

  reloadProjDocs = createEffect(() => {
    return this.actions.pipe(
      ofType(projectDocumentActionTypes.reloadProjDocs),
      withLatestFrom(
        this.store.select(getRetrieveDisabled),
        this.store.select(projDocsFeatureSelector),
      ),
      exhaustMap(([_, retrieveDisabled, state]) => {
        if (!state.selectedFolderId) {
          return of(projectDocumentActionTypes.cancel());
        }
        return this.driveApiService.getDriveFolder(state.selectedFolderId, retrieveDisabled).pipe(
          map((data) => {
            return projectDocumentActionTypes.updateProjectDriveFolder({ folder: data });
          }),
          catchError((error) => {
            console.warn(error);
            return of(projectDocumentActionTypes.updateAll({ data: [] }));
          }),
        );
      }),
    );
  });

  reloadProjectFolder = createEffect(() => {
    return this.actions.pipe(
      ofType(projectDocumentActionTypes.reloadProjectFolder),
      withLatestFrom(this.store.select(getRetrieveDisabled)),
      exhaustMap(([action, retrieveDisabled]) => {
        return this.driveApiService.getDriveFolder(action.id, retrieveDisabled).pipe(
          map((data) => {
            return projectDocumentActionTypes.updateProjectDriveFolder({ folder: data });
          }),
          catchError((error) => {
            console.warn(error);
            return of(projectDocumentActionTypes.updateAll({ data: [] }));
          }),
        );
      }),
    );
  });

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private driveApiService: DriveApiService,
    private driveService: DriveService,
    private notif: NotificationsService,
  ) {}
}
