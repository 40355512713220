import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'long' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // parse the date from input component as it only expect dates in
  // mm-dd-yyyy format

  parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  getFirstDayOfWeek(): number {
    return 7;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const dayOfWeekNames = super.getDayOfWeekNames('short');
    for (let i = 0; i < dayOfWeekNames.length; i++) {
      dayOfWeekNames[i] = dayOfWeekNames[i].toUpperCase();
    }
    return dayOfWeekNames;
  }
}
