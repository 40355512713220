import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectApiService } from '../../../../../services/project-api.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { FormsModule, NgForm } from '@angular/forms';
import { BID_STATUS } from '../../../../../framework/constants/project.constants';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import { InputUploadComponent } from '../../../../../framework/input-upload/input-upload.component';

import { Store } from '@ngrx/store';
import { viewProjectSelectors } from '../../../../../store/view-project/view-project.selectors';
import { PageLoadingComponent } from '../../../../../framework/page-loading/page-loading.component';
import { SimpleSmallButtonComponent } from '../../../../../framework/buttons/simple-small-button/simple-small-button.component';
import { SimpleButtonComponent } from '../../../../../framework/buttons/simple-medium-button/simple-button.component';
import { ProjectBid } from '../../projects.interface';
import { UserDriveFile } from '../../../../../framework/constants/documents.constants';
import { DriveService } from '../../../../../services/drive.service';
import { MoneyPipe } from '../../../../../pipes/framework/money-short.pipe';

interface IBid {
  description: string;
  value: number;
  message: string;
  id?: number;
  files?: UserDriveFile[];
}

@Component({
  selector: 'app-bid-proposal',
  templateUrl: './bid-proposal.component.html',
  styleUrls: ['./bid-proposal.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    CommonModule,
    FloatingInputComponent,
    InputUploadComponent,
    FormsModule,
    PageLoadingComponent,
    SimpleSmallButtonComponent,
    SimpleButtonComponent,
    MoneyPipe,
  ],
})
export class BidProposalComponent implements OnInit, OnDestroy {
  uploadFiles: Subject<void> = new Subject();

  model: IBid = {
    description: '',
    value: null,
    message: '',
    files: [],
  };

  isAwarded = false;
  showSummary = false;
  uploadMetaData = {};

  projectId: number;
  isDestroyed$ = new Subject<boolean>();
  isLoading = true;
  private bid: ProjectBid;

  constructor(
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private store: Store,
    private driveService: DriveService,
  ) {}

  ngOnInit() {
    this.store.select(viewProjectSelectors.getProjectId).subscribe((id) => {
      this.projectId = id;
      this.setupForm();
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  submit(bidForm: NgForm) {
    bidForm.form.markAllAsTouched();
    if (!bidForm.valid) {
      return;
    }

    if (!this.model.files.length) {
      this.notif.showError('Please add a file.');
      return;
    }
    this.notif.showLoading();
    this.model.id ? this.updateBid() : this.createBid();
  }

  setupForm() {
    this.isLoading = true;
    this.projectApi
      .getBidByProject(this.projectId)
      .then((bids) => {
        this.isLoading = false;
        const bid = bids?.pop();
        if (!bid?.id) {
          return;
        }

        this.bid = bid;

        this.model = {
          description: bid?.description,
          value: bid?.value,
          message: bid?.message,
          id: bid.id,
          files: bid?.user_drive_file ? [bid?.user_drive_file] : [],
        };

        if (bid.id && this.model.files.length) {
          this.showSummary = true;
        }

        this.isAwarded = bid.status.id === BID_STATUS.ACCEPTED;
      })
      .catch((err) => {
        this.isLoading = false;
        this.notif.showError(err);
      });
  }

  createBid() {
    this.projectApi
      .createBid(this.projectId, this.model)
      .then((data) => {
        this.setupMetadata(data.id);
        if (this.localFiles()?.length) {
          this.uploadFiles.next();
        } else {
          this.notif.showSuccess('Bid created!');
          this.setupForm();
        }
      })
      .catch((err) => {
        console.log('error submitting bid', err);
        this.notif.showError(err);
      });
  }

  updateBid() {
    this.projectApi
      .updateBid(this.model.id, this.model)
      .then((data) => {
        this.setupMetadata(data.id);
        if (this.localFiles()?.length) {
          this.uploadFiles.next();
        } else {
          this.notif.showSuccess('Bid updated!');
          this.setupForm();
        }
      })
      .catch((err) => {
        console.log('error updating bid', err);
        this.notif.showError(err);
      });
  }

  reviseProposal() {
    this.showSummary = !this.showSummary;
  }

  setupMetadata(id: number) {
    this.uploadMetaData = {
      bid_id: id,
      project_id: this.projectId,
    };
  }

  onFilesChange(files: any[]) {
    this.model.files = [...files];
  }

  localFiles() {
    return this.model.files.filter((f) => !f?.id);
  }

  downloadProposal() {
    if (!this.bid?.user_drive_file?.id) {
      this.notif.showError('No file to download');
      return;
    }

    this.driveService.downloadSingleFileS3Url(this.bid.user_drive_file);
  }
}
