import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { DynamicTableRowStyle } from '../constants/dynamic-table.constants';

@Component({
  selector: 'app-expansion-panel',
  standalone: true,
  imports: [CommonModule, MatExpansionModule],
  templateUrl: './expansion-panel.component.html',
  styleUrl: './expansion-panel.component.scss',
})
export class ExpansionPanelComponent {
  showContent = false;

  @Input() rowStyle: DynamicTableRowStyle;
  @Input() expanded = false;
  @Output() expandedChange = new EventEmitter<boolean>();

  onExpandChange(isExpanded: boolean) {
    this.showContent = isExpanded;
    this.expandedChange.emit(isExpanded);
  }
}
