import { createReducer, on } from '@ngrx/store';
import {
  IProjectProperty,
  ITeamMember,
  ITeamMemberExtended,
  ITeamProfilePicture,
  ROLE_TYPE,
  TEAM_MANAGEMENT_SIDEBAR_VIEW,
  TEAM_PROP_PROJ_SIDEBAR_PAGES,
} from './team-management.interfaces';
import { teamManagementActions } from './team-management.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import { TEAM_VIEWS } from '../../framework/constants/team.constants';

export const teamManagementFeatureKey = 'team-management';

export interface TeamManagementState {
  myTeams: ITeamMember[];
  peerTeams: ITeamMember[];
  areTeamsFetching: boolean;
  areProfilePicturesLoading: boolean;
  isSelectedMemberFetching: boolean;
  sharedPropProjPage: number;
  viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW;
  roles: ROLE_TYPE[];
  projectProperties: IProjectProperty[];
  checkedProperties: number[];
  checkedProjects: number[];
  selectedTeamMember?: Partial<ITeamMemberExtended>;
  selectedTeamId: number;
  propertiesSearchQuery: string;
  projectsSearchQuery: string;
  teamSearchQuery: string;
  picturesCache: ITeamProfilePicture;
  view: TEAM_VIEWS;
}

export const teamManagementInitialState: TeamManagementState = {
  myTeams: [],
  peerTeams: [],
  areTeamsFetching: false,
  areProfilePicturesLoading: false,
  isSelectedMemberFetching: false,
  sharedPropProjPage: TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES,
  viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER,
  roles: [],
  projectProperties: [], // contains only ids
  checkedProperties: [], // contains only ids
  checkedProjects: [],
  selectedTeamId: null,
  projectsSearchQuery: '',
  propertiesSearchQuery: '',
  teamSearchQuery: '',
  picturesCache: {},
  view: TEAM_VIEWS.MY_TEAMS,
};

export const teamManagementReducer = createReducer(
  teamManagementInitialState,
  on(teamManagementActions.teamLoaded, (state, action): TeamManagementState => {
    if (action.view === TEAM_VIEWS.MY_TEAMS) {
      return { ...state, myTeams: action.teams, areTeamsFetching: false };
    }
    return { ...state, peerTeams: action.teams, areTeamsFetching: false };
  }),
  on(teamManagementActions.reloadTeamFromBackend, (state, action): TeamManagementState => {
    return { ...state, areTeamsFetching: false };
  }),
  on(teamManagementActions.setTeamRoles, (state, action): TeamManagementState => {
    return { ...state, roles: action.roles, areTeamsFetching: false };
  }),
  on(teamManagementActions.setAreTeamsFetching, (state, action): TeamManagementState => {
    return { ...state, areTeamsFetching: action.isLoading };
  }),
  on(teamManagementActions.setTeamRolesAndTeamId, (state, action): TeamManagementState => {
    return {
      ...state,
      roles: action.roles,
      selectedTeamId: action.team_id,
      areTeamsFetching: false,
    };
  }),
  on(teamManagementActions.setViewType, (state, action): TeamManagementState => {
    return { ...state, viewType: action.viewType, areTeamsFetching: false };
  }),
  on(teamManagementActions.setProjectProperties, (state, action): TeamManagementState => {
    return { ...state, projectProperties: action.properties, areTeamsFetching: false };
  }),
  on(teamManagementActions.setProfilePicturesLoading, (state, action): TeamManagementState => {
    return { ...state, areProfilePicturesLoading: action.isLoading };
  }),
  on(teamManagementActions.clearSelectedTeamMember, (state, _): TeamManagementState => {
    return {
      ...state,
      selectedTeamMember: null,
      checkedProjects: [],
      checkedProperties: [],
      projectsSearchQuery: '',
      propertiesSearchQuery: '',
    };
  }),
  on(teamManagementActions.setLoadedTeamMember, (state, action): TeamManagementState => {
    return { ...state, selectedTeamMember: action.member, isSelectedMemberFetching: false };
  }),
  on(teamManagementActions.checkProperty, (state, action): TeamManagementState => {
    const newState = DeepCopyService.deepCopy(state);

    if (action.checked) {
      newState.checkedProperties.push(action.property.id);
    } else {
      // remove property from checkedProperties
      newState.checkedProperties.splice(newState.checkedProperties.indexOf(action.property.id), 1);
      // collect all ids that need to be filtered out
      const removedProjectIds = newState.projectProperties
        .find((prop) => prop.id === action.property.id)
        .projects.map((project) => project.id);
      // remove all project ids that are related to the removed property
      newState.checkedProjects = newState.checkedProjects.filter(
        (projectId) => !removedProjectIds.includes(projectId),
      );
    }

    return { ...newState };
  }),
  on(teamManagementActions.checkProject, (state, action): TeamManagementState => {
    const newState = DeepCopyService.deepCopy(state);

    if (action.checked) {
      newState.checkedProjects.push(action.project.id);
    } else {
      newState.checkedProjects.splice(newState.checkedProjects.indexOf(action.project.id), 1);
    }

    return { ...newState };
  }),
  on(teamManagementActions.checkAllProperties, (state, action): TeamManagementState => {
    if (action.checked) {
      return {
        ...state,
        checkedProperties: state.projectProperties
          .filter(
            (property) =>
              property.team_id === state.selectedTeamId &&
              property.name.toLowerCase().includes(state.propertiesSearchQuery.toLowerCase()),
          )
          .map((property) => {
            if (property.team_id === state.selectedTeamId) {
              return property.id;
            }
          }),
      };
    }
    return { ...state, checkedProperties: [] };
  }),
  on(teamManagementActions.setMemberProperties, (state, action): TeamManagementState => {
    return { ...state, checkedProperties: action.propertyIds ?? [] };
  }),
  on(teamManagementActions.setMemberProjects, (state, action): TeamManagementState => {
    return { ...state, checkedProjects: action.projectIds ?? [] };
  }),
  on(teamManagementActions.filterPropertiesBySearch, (state, action): TeamManagementState => {
    return { ...state, propertiesSearchQuery: action.searchText };
  }),
  on(teamManagementActions.filterProjectsBySearch, (state, action): TeamManagementState => {
    return { ...state, projectsSearchQuery: action.searchText };
  }),
  on(teamManagementActions.isSelectedMemberFetching, (state, action): TeamManagementState => {
    return { ...state, isSelectedMemberFetching: action.isLoading };
  }),
  on(teamManagementActions.setTeamSearchText, (state, action): TeamManagementState => {
    return { ...state, teamSearchQuery: action.searchQuery };
  }),
  on(teamManagementActions.addProfilePictureToCache, (state, action): TeamManagementState => {
    return {
      ...state,
      picturesCache: { ...state.picturesCache, [action.profilePictureId]: action.url },
    };
  }),
  on(
    teamManagementActions.cancelLoad,
    teamManagementActions.cancel,
    (state, _): TeamManagementState => {
      return {
        ...state,
        areTeamsFetching: false,
      };
    },
  ),
  on(teamManagementActions.loadTeamRolesFromBackend, (state, action): TeamManagementState => {
    // todo: think if it is ok to set this here or it would be better to dispatch it in the effect with another name
    return { ...state, selectedTeamId: action.team_id };
  }),
  on(teamManagementActions.checkAllProjects, (state, action): TeamManagementState => {
    // todo: this logic might have been better to be done in the component and despatch the ids to the store
    const filteredPropertiesByCheckedProperties = DeepCopyService.deepCopy(
      state.projectProperties.filter((property) => state.checkedProperties.includes(property.id)),
    );

    filteredPropertiesByCheckedProperties.forEach((property) => {
      // filter projects to contain only the ones that match the search query
      property.projects = property.projects.filter((project) =>
        project.title.toLowerCase().includes(state.projectsSearchQuery.toLowerCase()),
      );
    });

    if (action.checked) {
      return {
        ...state,
        checkedProjects: filteredPropertiesByCheckedProperties
          .map((property) => {
            if (state.checkedProperties.includes(property.id)) {
              return property.projects.map((project) => project.id);
            }
            return [];
          })
          .flat(),
      };
    }
    return { ...state, checkedProjects: [] };
  }),
  on(teamManagementActions.clearTeamManagement, (_): TeamManagementState => {
    return { ...teamManagementInitialState };
  }),
  on(teamManagementActions.viewChanged, (state, action): TeamManagementState => {
    return { ...state, view: action.view };
  }),
);
