import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MMMMDOYYYY, NO_TIMEZONE, DateCustomPipe } from '../../../pipes/framework/date-custom.pipe';
import { OPTIONS } from '../../constants/options-list.constants';
import { ITask, TASK_STATUS_VALUE } from '../../../store/tasks/tasks.interfaces';
import { OptionsActionList } from '../../overlays/option-list.constants';
import { TasksService } from '../../../services/tasks.service';
import { Store } from '@ngrx/store';
import { ChecklistPopupComponent } from '../checklist-popup/checklist-popup.component';
import { TasksMessageCountComponent } from '../tasks-message-count/tasks-message-count.component';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { OptionsListSimpleComponent } from '../../overlays/options-list-simple/options-list-simple.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-task-kanban-item',
  templateUrl: './task-kanban-item.component.html',
  styleUrls: ['./task-kanban-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    CdkOverlayOrigin,
    OptionsListSimpleComponent,
    NgIf,
    RouterLink,
    MatTooltip,
    TasksMessageCountComponent,
    ChecklistPopupComponent,
    DateCustomPipe,
  ],
})
export class TaskKanbanItemComponent implements OnInit {
  @ViewChild('checklistIcon') checklistIcon;
  DATE_FORMAT = MMMMDOYYYY;
  TASK_STATUS_VALUE = TASK_STATUS_VALUE;
  OPTIONS = OPTIONS;
  actions: OptionsActionList = [
    {
      value: OPTIONS.VIEW,
    },
    {
      value: OPTIONS.EDIT,
    },
    {
      value: OPTIONS.DELETE,
    },
    {
      value: OPTIONS.DOWNLOAD,
    },
  ];
  checkedItems: number;
  showChecklistItem: boolean;
  showOptions = false;
  protected readonly NO_TIMEZONE = NO_TIMEZONE;

  constructor(
    public taskService: TasksService,
    private store: Store,
  ) {}

  private _task: ITask;

  get task() {
    return this._task;
  }

  @Input() set task(data) {
    this._task = data;
    this.actions = this.taskService.disableTaskActions(this.actions, data);
  }

  ngOnInit(): void {
    if (this.task.status === TASK_STATUS_VALUE.PENDING) {
      this.actions.push({ value: OPTIONS.TASK_IN_PROGRESS, class: 'border-top' });
    } else if (this.task.status === TASK_STATUS_VALUE.IN_PROGRESS) {
      this.actions.push({ value: OPTIONS.TASK_COMPLETE, class: 'border-top' });
    } else if (this.task.status === TASK_STATUS_VALUE.COMPLETED) {
      this.actions.push({ value: OPTIONS.TASK_PENDING, class: 'border-top' });
    }
    this.checkedItems = !this.task.checklist
      ? 0
      : this.task.checklist.items.reduce((acc, curr) => (curr.value ? acc + 1 : acc), 0);
  }
}
