<div class="projected-table-wrapper" [ngClass]="{ hidden: isEmpty && isLoading && !wasDataLoadedOnce }">
  <!--    projected header here with it's own style ex: -->
  <!--  <div [ngStyle]="rowStyle" class="grid-row header-row"></div>-->
  <ng-scrollbar class="std-scrollbar horizontal-scrollbar" track="horizontal" appearance="compact">
    <div [ngClass]="horizontalContainerClass" class="horizontal-container flex flex-col">
      <ng-content select="[tableHeader]"></ng-content>

      <p-progressBar [ngClass]="{ 'visibility-hidden': !isLoading }" mode="indeterminate"></p-progressBar>

      <div *ngIf="isEmpty && !isLoading" class="error-msg">{{ emptyTableMessage }}</div>

      <ng-scrollbar
        track="vertical"
        #ngScrollbar
        [ngClass]="{ 'hide-footer': hideFooter }"
        appearance="compact"
        class="std-scrollbar vertical-scrollbar"
      >
        <!--        project table with defined rows here       -->
        <ng-content select="[projectedTable]"></ng-content>

        <!--        project separator here ex: -->
        <!--        <div *ngIf="!last || !totalsKeys?.length" [ngClass]="['grid border-bottom h-1 w-full', separatorClass]"></div>-->
        <ng-content select="[rowSeparator]"></ng-content>
      </ng-scrollbar>
      @if (!isEmpty) {
        <!--    projected footer here with it's own style like the header -->
        <ng-content select="[tableFooter]"></ng-content>
      }
    </div>
  </ng-scrollbar>
</div>

<app-page-loading *ngIf="isEmpty && isLoading && !wasDataLoadedOnce"></app-page-loading>
