import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { REST_DRIVE_FILES } from 'src/app/restApi/RestRoutes';
import { IFilesToUpload } from '../discussions-view/discussions-view.component';
import {
  CUSTOM_OVERLAY_VIEWS,
  CustomOverlayService,
} from '../../../services/custom-overlay.service';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { first, takeUntil } from 'rxjs/operators';
import {
  IMessagingMember,
  IMessagingUserListData,
  MESSAGING_VIEWS,
} from '../../../store/messages/messages.interfaces';
import { Project } from '../../../pages/webapp/projects/projects.interface';
import {
  addFiles,
  cleanFiles,
  createOrUpdateDraft,
  removeFile,
  setMessage,
  updateNewGroupData,
} from '../../../store/messages/messages.actions';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { CurrentUserService } from '../../../services/current-user.service';
import { ImageSettingsModel } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/models';
import { combineLatest, Subject } from 'rxjs';
import {
  getDiscussionMembers,
  getFilesToUpload,
  getLastNonDraftSentMessageId,
  getSearchedMessagingMembers,
} from '../../../store/messages/messages.selectors';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IToolbarItems } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/base/interface';
import { QuickToolbarSettingsModel } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/toolbar-settings-model';
import {
  RichTextEditorComponent,
  RTE_VIEWS,
  RTEConfig,
} from '../../inputs/rich-text-editor/rich-text-editor.component';
import { PresignedFileUploadService } from '../../../services/presigned-file-upload.service';

@Component({
  selector: 'app-discussion-view-common',
  templateUrl: './discussion-view-common.component.html',
  styleUrls: ['./discussion-view-common.component.scss'],
  standalone: true,
})
/**
 * This component is not meant to be used alone.
 * Instead, it should be extended by custom messaging components.
 * It contains the common variables and functions of the child components.
 */
export class DiscussionViewCommonComponent implements OnInit, OnDestroy {
  fileUploadSubstitute: ElementRef;
  editorComponent: RichTextEditorComponent;
  uploadBtn: IToolbarItems = {
    template: `<span class="icon-upload"></span>`,
    click: this.uploadFile.bind(this),
    tooltipText: 'Upload files',
    undo: false,
  };
  tagBtn: IToolbarItems = {
    template: `<span class="custom-hashtag-icon">#</span>`,
    click: this.tagProject.bind(this),
    tooltipText: 'Tag Project',
    undo: false,
  };
  // another version of default 'createLink' toolbar item
  linkBtn: IToolbarItems = {
    template: `<span class="icon-attachments-chain"></span>`,
    click: this.createLink.bind(this),
    tooltipText: 'Create Link',
    undo: false,
  };

  editLinkBtn: IToolbarItems = {
    template: `<span class="e-edit-link e-icons m-auto"></span>`,
    click: this.editLink.bind(this),
    tooltipText: 'Edit Link',
    undo: false,
  };
  editorSetting: RTEConfig = {
    richTextConfigItems: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      this.linkBtn,
      '|',
      this.tagBtn,
      '|',
      this.uploadBtn,
    ],
    enableToolbar: true,
    isReady: false,
    height: '200px',
  };
  editorSpecialKeys = ['Enter', '@', '#', 'Escape']; // the editor emits a different event if one of these is pressed
  editorInput = '';
  editorOutput: string;
  RTE_VIEWS = RTE_VIEWS;
  imageModel: ImageSettingsModel = {
    allowedTypes: [],
  };

  usersData: IMessagingUserListData = {
    usersRelated: [],
    discussionMembers: [],
  };
  itemOptions: string[];
  showRelatedUsers = false;

  REST_DRIVE_FILES = REST_DRIVE_FILES;
  uploadMetaData: { message_id: number };
  filesToUpload: IFilesToUpload[] = [];
  shouldScrollToBottom = false;

  searchedMessagingMembers$ = this.store.select(getSearchedMessagingMembers);
  discussionMembers$ = this.store.select(getDiscussionMembers);
  lastNonDraftSentMessageId$ = this.store.select(getLastNonDraftSentMessageId);
  filesToUpload$ = this.store.select(getFilesToUpload);
  isDestroyed$: Subject<boolean> = new Subject<boolean>();
  quickToolbarSettings: QuickToolbarSettingsModel = {
    link: ['Open', this.editLinkBtn, 'UnLink'],
  };
  selectedView: MESSAGING_VIEWS; // set by child component

  constructor(
    protected overlayService: CustomOverlayService,
    protected footerService: MessagingFooterService,
    protected store: Store<AppState>,
    protected userService: CurrentUserService,
    protected domSanitizer: DomSanitizer,
    protected presignedFileUploadService: PresignedFileUploadService,
  ) {}

  ngOnInit(): void {
    // this is empty as you see. If you extended this component then
    // call the needed `handle` methods from the child component's ngOnInit() function.
    // Different children might need different base functions to be called.
    /*
    source: https://stackoverflow.com/questions/36475626/how-to-extend-inherit-components >
    Component lifecycle is not inherited
      This part is the one that is not so obvious especially to people who have not extensively worked with OOP principles.
       For example, say you have ComponentA which implements one of Angular’s many lifecycle hooks like OnInit.
       If you create ComponentB and inherit ComponentA, the OnInit lifecycle from ComponentA won't fire
        until you explicitly call it even if you do have this OnInit lifecycle for ComponentB.
     */
  }

  uploadFile() {
    // todo find out a better solution than this mess
    console.log(this.fileUploadSubstitute);
    this.fileUploadSubstitute.nativeElement.click();
  }

  tagProject() {
    this.editorComponent.insertHashtag();
  }

  createLink() {
    this.editorComponent.createLink();
  }

  editLink() {
    this.editorComponent.editLink();
  }

  onEditorValueChanged(value: string) {
    this.editorOutput = value;
    this.setOverlayFilters(value);
    this.saveDraft(value);
  }

  saveDraft(message) {
    if (this.selectedView === MESSAGING_VIEWS.DISCUSSION_CREATE) {
      this.store.dispatch(updateNewGroupData({ message }));
    } else {
      this.store.dispatch(setMessage({ message }));
      this.store.dispatch(createOrUpdateDraft({ message }));
    }
  }

  protected handleSaveEvent() {
    this.footerService.save.pipe(takeUntil(this.isDestroyed$)).subscribe((savePressed) => {
      if (savePressed) {
        console.log('set should scroll to bottom to true');
        this.shouldScrollToBottom = true;
        this.clearEditor();
      }
    });
  }

  clearEditor() {
    this.editorInput = '';
    this.editorComponent.clearEditor();
  }

  /**
   * When the user presses a key in RTE which has a special meaning, this function is called.
   */
  specialKeyPressed({ key, html }: { key: string; html: string }) {
    switch (key) {
      case 'Enter': {
        if (!this.overlayService.isOpened()) {
          // todo: this send blocking not works
          this.footerService.emitSave();
        }
        break;
      }
      case 'Escape': {
        this.overlayService.closeOverlay();
        break;
      }
      case '@': {
        this.handleAtPressed(html);
        break;
      }
      case '#': {
        this.handleHashtagPressed(html);
        break;
      }
    }
  }

  /**
   * When the user types a `@` this function is called.
   * It displays the overlay to mention another user.
   * It subscribes for the overlay response and inserts the new <a></a> element with the user.
   */
  handleAtPressed(html) {
    // a span with class .span-at is inserted in the RTE component on a `@` press.
    const regexForAt = /<span\s*class="span-at"\s*>(\s|&nbsp;|<br>)*@<\/span>/;
    if (!html || !html.match(regexForAt)) {
      console.warn('nothing found @', html);
      return;
    }
    // setTimeout is needed to wait for the rendering process
    setTimeout(() => {
      // getting the position of the span to determine the overlay global position.
      const atPosition = document
        .querySelector('.e-richtexteditor .span-at')
        ?.getBoundingClientRect();
      if (!atPosition) {
        console.warn('no position', html);
        return;
      }

      const discussionMembers = this.getOtherDiscussionMembers();
      this.overlayService.openOverlay(
        { position: 'global', left: `${atPosition.left}px`, top: `${atPosition.top + 20}px` },
        { discussionMembers },
        CUSTOM_OVERLAY_VIEWS.MESSAGING_TAG_USERS,
      );
      this.overlayService.outputData$.pipe(first()).subscribe((selectedUser: IMessagingMember) => {
        // replace the span with the selected user. The href is important, the backend sends a notification based on it.
        this.editorInput = html.replace(
          regexForAt,
          ` <a style="pointer-events: none;" href="user/${selectedUser.user_id}">@${selectedUser.name}</a> `,
        );
        this.saveDraft(this.editorInput);
      });
    }, 50);
  }

  /**
   * When the user types a `#` this function is called.
   * It displays the overlay to tag a project.
   * It subscribes for the overlay response and inserts the new <a></a> element with the project.
   */
  handleHashtagPressed(html) {
    // a span with class .span-hashtag is inserted in the RTE component on a `#` press.
    const regexForHashtag = /<span\s*class="span-hashtag"\s*>(\s|&nbsp;|<br>)*#<\/span>/;
    if (!html || !html.match(regexForHashtag)) {
      console.warn('nothing found #', html);
      return;
    }

    // setTimeout is needed to wait for the render process
    setTimeout(() => {
      // determining overlay position
      const hashtagPosition = document
        .querySelector('.e-richtexteditor .span-hashtag')
        ?.getBoundingClientRect();
      if (!hashtagPosition) {
        console.warn('no position', html);
        return;
      }

      this.overlayService.openOverlay(
        {
          position: 'global',
          left: `${hashtagPosition.left}px`,
          top: `${hashtagPosition.top + 20}px`,
        },
        {},
        CUSTOM_OVERLAY_VIEWS.MESSAGING_TAG_PROJECTS,
      );
      this.overlayService.outputData$.pipe(first()).subscribe((selectedProject: Project) => {
        // when the user selects a project, insert an <a> element with the href pointing to the project.
        this.editorInput = html.replace(
          regexForHashtag,
          ` <a style="pointer-events: none;" href="/webapp/projects/${selectedProject.id}">#${selectedProject.title}</a> `,
        );
        this.saveDraft(this.editorInput);
      });
    }, 50);
  }

  /**
   * It sets the text filter for the user tag or project tag overlays.
   * It searches for a pattern of kind: `#<project_name>` or `@<user_name>`
   */
  setOverlayFilters(editorOutput) {
    if (
      !editorOutput.match(
        /<span\s*class="span-(at|hashtag)"\s*>(\s|&nbsp;|<br>)*[@#]\w*<\/span>/,
      ) &&
      this.overlayService.isOpened()
    ) {
      // if `@` or `#` is deleted then close the overlay.
      this.overlayService.closeOverlay();
      return;
    }
    let filterText;
    const atFilter = editorOutput.match(
      /<span\s*class="span-at"\s*>(\s|&nbsp;|<br>)*@(?<searched>\w*)<\/span>/,
    );

    if (!atFilter) {
      // if a match with @ pressed cannot be found search for a #
      filterText = editorOutput.match(
        /<span\s*class="span-hashtag"\s*>(\s|&nbsp;|<br>)*#(?<searched>\w*)<\/span>/,
      )?.groups?.searched;
      this.overlayService.setInputData({ filterText });
      return;
    }
    filterText = atFilter?.groups?.searched;
    const discussionMembers = this.getOtherDiscussionMembers();
    this.overlayService.setInputData({ discussionMembers, filterText });
  }

  protected handleChatMembers() {
    // todo make searchedMessagingMembers$ and discussionMembers$ an array in the store
    // this way the selector always creates a new array every time a keystroke is pressed and this func is called
    combineLatest([this.searchedMessagingMembers$, this.discussionMembers$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([usersRelated, discussionMembers]) => {
        this.itemOptions = usersRelated.map((user) => user.name);
        const filteredUsersRelated = usersRelated.filter(
          (user) => !discussionMembers.find((discMember) => discMember.user_id === user.user_id),
        );
        // console.log('chat members changed', discussionMembers);
        this.usersData = {
          usersRelated: this.showRelatedUsers ? filteredUsersRelated : discussionMembers,
          discussionMembers,
        };
      });
  }

  protected getOtherDiscussionMembers() {
    const ownUserId = String(this.userService.data.id);
    return this.usersData.discussionMembers.filter((u) => u.user_id !== ownUserId);
  }

  protected handleUpload() {
    this.lastNonDraftSentMessageId$.pipe(takeUntil(this.isDestroyed$)).subscribe((id) => {
      this.uploadMetaData = { message_id: id };
      if (this.filesToUpload.length) {
        const files = this.filesToUpload.map((f) => f.file);
        setTimeout(() => {
          this.presignedFileUploadService
            .uploadMultipleFilesToS3(
              files,
              files.map((_) => this.uploadMetaData),
            )
            .subscribe(
              (data) => {
                console.log('upload success', data);
              },
              (error) => {
                console.error('upload error', error);
              },
            );
          this.store.dispatch(cleanFiles());
        }, 0);
      }
    });

    this.filesToUpload$.pipe(takeUntil(this.isDestroyed$)).subscribe((files) => {
      this.filesToUpload = files;
    });
  }

  registerFileAdded(event: any) {
    this.registerFileUpload(event.target.files);
  }

  registerFileUpload(event: File[]) {
    const files = Array.from(event);
    this.store.dispatch(
      addFiles({
        files: [
          ...files.map((file) => {
            return { file, url: this.getFileURL(file) };
          }),
        ],
      }),
    );
  }

  removeFile(file: IFilesToUpload, index: number) {
    this.store.dispatch(removeFile({ file, index }));
  }

  // in case images have to be displayed
  getFileURL(file): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
  }
}
