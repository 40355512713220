import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateType } from '../template-selector/template-selector.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-templates-help',
  templateUrl: './templates-help.component.html',
  styleUrls: ['./templates-help.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class TemplatesHelpComponent {
  @Input() showHelp = false;
  @Input() type: TemplateType | 'account-relations';
  @Output() closeHelpGuideLine: EventEmitter<boolean> = new EventEmitter<boolean>();

  hideGuideline(value: boolean) {
    this.showHelp = value;
    this.closeHelpGuideLine.emit(value);
  }
}
