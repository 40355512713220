import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationsStateService } from '../../../services/notifications-state.service';
import { NotificationsApiService } from '../../../services/notifications-api.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { Router } from '@angular/router';
import { ROUTE_WEBAPP } from '../../constants/route.webapp.constants';
import { CurrentUserService } from '../../../services/current-user.service';
import { NotificationsService } from '../../../services/notifications.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { NotificationsHandler } from '../../interfaces/NotificationsHandler';
import { PROJECT_STATUS_ID } from '../../constants/project.constants';
import {
  INotification,
  NOTIFICATION_CATEGORIES,
  NotificationsGrouped,
  NotificationsMap,
} from '../../constants/notification.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { messagesActions, setMessagingView } from '../../../store/messages/messages.actions';
import { MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { TeamManagementService } from '../../../services/team-management.service';
import { DateCustomPipe, DMMM, UTC_TO_LOCAL } from '../../../pipes/framework/date-custom.pipe';
import moment from 'moment';
import { KeyValue, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { teamManagementActions } from '../../../store/team-management/team-management.actions';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatProgressBar } from '@angular/material/progress-bar';
import { activitiesActions } from '../../../store/activities/activities.actions';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, MatProgressBar, NgScrollbar, KeyValuePipe, DateCustomPipe],
})
export class NotificationsComponent
  extends NotificationsHandler
  implements OnInit, AfterViewInit, OnDestroy
{
  private _notificationsGrouped: NotificationsGrouped = { seen: new Map(), unseen: new Map() };
  get notificationsGrouped(): NotificationsGrouped {
    return this._notificationsGrouped;
  }
  set notificationsGrouped(value: NotificationsGrouped) {
    this._notificationsGrouped = value;
    this.notificationGroups = [value.unseen, value.seen];
  }
  notificationGroups: NotificationsMap[] = []; // used for rendering

  isLoading = false;
  notificationCount = 0;
  unseenCount = 0;

  selectedCategoryIndex = 0;
  selectedCategory = NOTIFICATION_CATEGORIES.ALL;
  NOTIFICATION_CATEGORIES = NOTIFICATION_CATEGORIES;
  notificationCategories: NOTIFICATION_CATEGORIES[] = [
    NOTIFICATION_CATEGORIES.ALL,
    NOTIFICATION_CATEGORIES.SCHEDULE,
    NOTIFICATION_CATEGORIES.MESSAGES,
    NOTIFICATION_CATEGORIES.PROPOSALS,
    NOTIFICATION_CATEGORIES.INVOICES,
    NOTIFICATION_CATEGORIES.TEAM,
  ];

  readonly DMMM = DMMM;
  readonly UTC_TO_LOCAL = UTC_TO_LOCAL;
  readonly today = moment().format('YYYY-MM-DD');
  private isDestroyed$ = new Subject();

  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  constructor(
    private el: ElementRef,
    private notificationsState: NotificationsStateService,
    private notificationsApi: NotificationsApiService,
    private interractionBarState: InteractionBarStateService,
    private router: Router,
    private user: CurrentUserService,
    protected notif: NotificationsService,
    private projectApi: ProjectApiService,
    private store: Store,
    private teamsService: TeamManagementService,
  ) {
    super(notif);

    if (!this.user.isManager) {
      this.notificationCategories = [
        NOTIFICATION_CATEGORIES.ALL,
        NOTIFICATION_CATEGORIES.INVITES,
        NOTIFICATION_CATEGORIES.SCHEDULE,
        NOTIFICATION_CATEGORIES.MESSAGES,
        NOTIFICATION_CATEGORIES.PROPOSALS,
      ];
    }
  }

  ngOnInit() {
    this.loadFirstPage();
    this.notificationsState.loadedState.pipe(takeUntil(this.isDestroyed$)).subscribe((isLoaded) => {
      this.isLoading = !isLoaded;
      if (isLoaded) {
        this.updateNotifications();
      }
    });
  }

  /**
   * Handle infinite scrolling.
   */
  ngAfterViewInit(): void {
    let lastScrollTop = 0;
    if (!this.scrollbarRef) {
      console.error(
        "Couldn't find scrollbarRef, could not init infinite scrolling for notifications.",
      );
      return;
    }

    this.scrollbarRef.scrolled.pipe(takeUntil(this.isDestroyed$)).subscribe((event) => {
      const target = event.target as HTMLElement;
      // scrollBottom: how much did the user scroll (scrollBottom === scrollHeight) if scrolled completely to bottom
      const scrollBottom = target.offsetHeight + target.scrollTop;
      const scrollHeight = target.scrollHeight; // total height of the scroll

      // should retrieve if scrolled in bottom direction
      // and scrolled to the 15% of the bottom
      // and there is less than 400px to the bottom
      //    (this is needed when there are too many notifications and the scroll is always in the last 15%)
      if (
        lastScrollTop < target.scrollTop &&
        scrollHeight / scrollBottom < 1.15 &&
        scrollHeight - scrollBottom < 400
      ) {
        // it can be called multiple times at once, but it will be throttled
        this.notificationsState.retrieveThrottled();
      }
      lastScrollTop = target.scrollTop;
    });
  }

  private async loadFirstPage() {
    try {
      await this.notificationsState.retrieveNextPage(true);
    } catch (err) {
      this.notif.showError('Could not load notifications.');
      console.warn(err);
    }
  }

  /**
   * Gets data from state service. Should be called when notifications are fetched / updated.
   * @private
   */
  private updateNotifications() {
    this.notificationsGrouped = this.notificationsState.notificationsGrouped;
    this.unseenCount = this.notificationsState.unseenNotifications;
    this.notificationCount = this.notificationsState.notifications.length;
  }

  /**
   * used by ngFor to track changes in the notifications map
   */
  mapCompareFn(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    // sort notification groups by date descending
    return moment(a.key).isAfter(b.key) ? -1 : 1;
  }
  isThisYear(key: string) {
    return moment(key).isSame(this.today, 'year');
  }

  prevTab() {
    if (this.selectedCategoryIndex > 0) {
      this.setSelectedCategory(this.notificationCategories[this.selectedCategoryIndex - 1]);
    }
  }

  nextTab() {
    if (this.selectedCategoryIndex < this.notificationCategories.length - 1) {
      this.setSelectedCategory(this.notificationCategories[this.selectedCategoryIndex + 1]);
    }
  }

  /**
   * Sets notification type, e.g. notification type TASKS to be active and selected.
   * @param category Notification category to be active.
   */
  setSelectedCategory(category: NOTIFICATION_CATEGORIES) {
    this.selectedCategory = category;
    this.selectedCategoryIndex = this.notificationCategories.indexOf(category);
    this.notificationsState.setCategory(category);
  }

  /**
   * Handles the action for the notification. (e.g. opens the project, task, etc.)
   * @param notification
   */
  async openNotification(notification: INotification) {
    this.notificationsState.setNotificationsAsSeen(notification);

    switch (notification?.notification_type) {
      case 'message_tag': {
        if (notification.extra_data.thread_id) {
          this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW }));
          this.store.dispatch(
            messagesActions.openThreadByMessageId({ messageId: notification.extra_data.thread_id }),
          );
        } else {
          this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_VIEW }));
        }
        this.interractionBarState.openMessageGroupFromNotification(notification);
        break;
      }
      case 'message':
        this.interractionBarState.openMessageGroupFromNotification(notification);
        break;
      case 'schedule_visit':
      case 'scheduled_visit':
      case 'reschedule_visit':
      case 'rescheduled_visit':
      case 'cancel_visit':
      case 'bid_placed':
      case 'bid_accepted':
      case 'bid_declined':
      case 'bid_revised':
      case 'invoice_uploaded':
      case 'invoice_updated':
      case 'invoice_deleted':
      case 'project_invite':
      case 'project_invite_rejected':
        this.openLoading();
        try {
          const data = await this.projectApi.getProject(notification.trigger_object_id);
          if (data.status !== PROJECT_STATUS_ID.DELETED) {
            this.router.navigate([
              ROUTE_WEBAPP.BASE,
              ROUTE_WEBAPP.PROJECTS,
              notification.trigger_object_id,
            ]);
            this.interractionBarState.close();
          } else {
            this.notif.showError('Project no longer available!');
          }
        } catch (err) {
          this.notif.showError('Project no longer available!');
        } finally {
          this.closeLoading();
        }
        break;
      case 'task_updated':
      case 'new_task':
      case 'task_file_uploaded':
      case 'task_daily_reminder':
      case 'task_weekly_reminder':
      case 'task_spectating_weekly_reminder': {
        this.notif.showError('Tasks no longer available!');
        break;
      }
      case 'team_invite': {
        if (notification.extra_data?.is_accepted) {
          this.store.dispatch(teamManagementActions.loadTeamMembersFromBackend({}));
          await this.router.navigate(['webapp', 'teams']);
          this.interractionBarState.close();
          break;
        }

        await this.teamsService.acceptInvite(notification.extra_data);
        this.loadFirstPage();
        break;
      }
      case 'report_generated': {
        this.router.navigate(['webapp', 'documents']);
        this.interractionBarState.close();
        break;
      }
      case 'report_failed': {
        this.notif.showError('Report generation failed, please try again later!');
        break;
      }
      case 'activity_assigned':
      case 'activity_unassigned': {
        this.store.dispatch(
          activitiesActions.activitySelectedFromNotification({
            activityId: notification?.extra_data?.id,
          }),
        );
        this.interractionBarState.close();
        break;
      }
      default:
        console.warn('unhandled action', notification);
        this.notif.showError('It looks like this action is not available!');
    }
  }

  async markAllAsRead() {
    await this.notificationsApi.markAllAsRead();
    this.notif.showSuccess('Notifications marked as read.');
    this.loadFirstPage();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
