import { Component, OnInit } from '@angular/core';
import { TASK_KANBAN_COLUMN_TYPES } from '../../../../store/tasks/tasks.interfaces';
import { Store } from '@ngrx/store';
import { TasksKanbanColumnComponent } from '../../../../framework/tasks/tasks-kanban-column/tasks-kanban-column.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-tasks-kanban',
  templateUrl: './tasks-kanban.component.html',
  styleUrls: ['./tasks-kanban.component.scss'],
  standalone: true,
  imports: [NgScrollbar, TasksKanbanColumnComponent],
})
/**
 * Just a wrapper component for the kanban view. It displays the 3 columns.
 * Each column has the responsibility to load its tasks.
 */
export class TasksKanbanComponent implements OnInit {
  TASK_KANBAN_COLUMN_TYPES = TASK_KANBAN_COLUMN_TYPES;
  constructor(private store: Store) {}

  ngOnInit(): void {}
}
