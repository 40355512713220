import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { propertiesActions } from './properties.actions';
import { IProperty, IPropertyBase, IPropertyType } from './properties.interfaces';

export const propertiesFeatureKey = 'properties';

export const propertiesAdapter: EntityAdapter<IProperty> = createEntityAdapter<IProperty>();

export interface PropertiesState extends EntityState<IProperty> {
  isLoading: boolean;
  isFetching: boolean;
  peerProperties: IPropertyBase[];
  ownProperties: IPropertyBase[];
  selectedPropertyType: IPropertyType;
  searchedProperty?: string;
}

export const initialState: PropertiesState = propertiesAdapter.getInitialState({
  isLoading: true, // general loading for loading screen
  isFetching: true, // fetching effect loading bar
  peerProperties: [],
  ownProperties: [],
  selectedPropertyType: 'own',
  searchedProperty: '',
});

export const propertiesReducer = createReducer(
  initialState,
  on(propertiesActions.successfullyLoadedProperties, (state, action) => {
    return propertiesAdapter.setAll(action.properties, {
      ...state,
      isFetching: false,
      isLoading: false,
    });
  }),
  on(propertiesActions.showFetching, (state) => {
    return { ...state, isFetching: true };
  }),
  on(propertiesActions.cancelLoad, (state) => {
    return { ...state, isFetching: false, isLoading: false };
  }),
  on(propertiesActions.clearProperties, (state) => {
    return { ...initialState };
  }),
  on(propertiesActions.setSelectedView, (state, action) => {
    return { ...state, selectedPropertyType: action.view };
  }),
  on(propertiesActions.setPropertiesSearch, (state, action) => {
    return { ...state, searchedProperty: action.search };
  }),
  on(propertiesActions.successfullyLoadedPropertiesByType, (state, action) => {
    const newState = {
      ...state,
    };

    if (action.propertyType === 'peer') {
      newState.peerProperties = action.properties;
    } else {
      newState.ownProperties = action.properties;
    }
    return {
      ...newState,
      isFetching: false,
      isLoading: false,
    };
  }),
);
