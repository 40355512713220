<div class="contractor-wrapper">
  <div class="head">
    <span class="title">
      <app-faded-text>
        {{ contractor.license.company_name }}
      </app-faded-text>
    </span>

    <app-un-styled-options-list
      #unStyledOptionsList
      (selectedOption)="registerOption($event, contractor)"
      [listItems]="optionsShortFormat"
      [disabledActionList]="disabledActionList"
    >
      <ng-template #projectedContent>
        <div class="options">
          <span
            (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
            class="btn-small icon-options"
          ></span>
          <span class="btn-small" (click)="toggleFavorite(contractor)" *ngIf="user.isManager">
            <span class="icon-star" *ngIf="contractor.is_favorite"></span>
            <span class="icon-star-u" *ngIf="!contractor.is_favorite"></span>
          </span>
        </div>
      </ng-template>
    </app-un-styled-options-list>
  </div>

  <div class="type" *ngIf="user.isManager && isShort">
    <span class="title">{{ contractor.user.type.name }}</span>
    <div *ngIf="isShort" class="default-cursor short-status"><span>Invited</span></div>
  </div>

  <div class="status" *ngIf="!isShort">
    <span
      [ngClass]="{
        scheduled: contractor.internStatus == 'scheduled' || contractor.internStatus == 'invited',
        received: contractor.internStatus == BID_STATUS.PENDING || contractor.internStatus == BID_STATUS.ACCEPTED,
        declined: contractor.internStatus == BID_STATUS.DECLINED
      }"
    >
      <span *ngIf="contractor.internStatus == 'scheduled'">{{ viewModel.statusScheduled }}</span>
      <span *ngIf="contractor.internStatus == BID_STATUS.PENDING">{{ viewModel.statusPendingTxt }}</span>
      <span *ngIf="contractor.internStatus == BID_STATUS.ACCEPTED">{{ viewModel.statusAcceptedTxt }}</span>
      <span *ngIf="contractor.internStatus == BID_STATUS.DECLINED">{{ viewModel.statusDeclinedTxt }}</span>
      <span *ngIf="contractor.internStatus == 'invited'">{{ viewModel.statusNotScheduled }}</span>
    </span>
    <!--    <span class="received" *ngIf="contractor.status == 'bid'">Bid Received</span>-->
    <!--    <span class="declined" *ngIf="contractor.status == 'bid-declined'">Bid Declined</span>-->

    <div class="date" *ngIf="contractor.internStatus === 'scheduled'">
      <span>{{ date }}</span>
    </div>
  </div>

  <div class="row" *ngIf="!isShort">
    <span class="no-bid" *ngIf="contractor.internStatus == 'scheduled' || !contractor.bid">Bid Not Submited</span>
    <span
      class="amount"
      [ngClass]="{ reject: contractor.internStatus == BID_STATUS.DECLINED }"
      *ngIf="contractor.internStatus != 'scheduled' && contractor.bid"
      >${{ contractor.bid.value | money }}</span
    >

    <div class="hour" *ngIf="contractor.internStatus === 'scheduled'">
      <span>
        {{ hour }}
      </span>
    </div>
  </div>

  <div class="row" *ngIf="showInfo">
    <span class="mail">
      {{ contractor.user.email }}
    </span>
    <div class="hour">
      {{ contractor.user.phone | phone }}
    </div>
  </div>
</div>
