import { createReducer, on } from '@ngrx/store';
import { commitmentsActions } from './commitments.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import { COMMITMENTS_TYPE } from '../../framework/constants/interaction-bar.constants';
import {
  defaultChangeOrder,
  defaultCommitmentItem,
  defaultContract,
  defaultCost,
  defaultDirectCost,
  defaultDirectCostCommitmentItem,
  defaultInvoice,
  defaultInvoiceCost,
} from './commitments.constants';
import {
  COMMITMENT_SIDEBAR_PAGE,
  CommitmentsState,
  ISidebarChangeOrder,
  ISidebarCommitmentItem,
  ISidebarContract,
  ISidebarCost,
  ISidebarDirectCost,
  ISidebarInvoice,
  ISidebarInvoiceCost,
} from './commitments.types';

export const commitmentsFeatureKey = 'commitments';

export const initialState: CommitmentsState = {
  allCommitments: null,
  allCommitmentsSummary: null,
  directCostsSummary: null,
  selectedCommitment: null,
  selectedContract: null,
  sidebarPage: 1,
  projectData: null,
  sideBarContract: { ...defaultContract },
  sideBarChangeOrder: { ...defaultChangeOrder },
  sideBarInvoice: { ...defaultInvoice },
  sideBarDirectCost: { ...defaultDirectCost },
  sideBarChanged: false,
  budgetLineItems: [],
  sidebarSelectedContractSummary: null,
  isSidebarLoading: false,
  isSelectedCommitmentViewSummaryLoading: true,
};

export const commitmentsReducer = createReducer<CommitmentsState>(
  initialState,
  on(commitmentsActions.allCommitmentsLoaded, (state, action) => {
    return {
      ...state,
      allCommitments: action.commitments,
      sidebarPage: 1,
    };
  }),
  on(commitmentsActions.setIsSelectedCommitmentViewSummaryLoading, (state, action) => {
    return {
      ...state,
      isSelectedCommitmentViewSummaryLoading: action.isLoading,
    };
  }),
  on(commitmentsActions.cancel, (state) => {
    return {
      ...state,
      isSidebarLoading: false,
      isSelectedCommitmentViewSummaryLoading: false,
    };
  }),
  on(commitmentsActions.allCommitmentsSummaryLoaded, (state, action) => {
    return {
      ...state,
      allCommitmentsSummary: action.commitmentsSummary,
    };
  }),
  on(commitmentsActions.directCostsSummaryLoaded, (state, action) => {
    return {
      ...state,
      directCostsSummary: action.directCostsSummary,
      isSelectedCommitmentViewSummaryLoading: false,
    };
  }),
  on(commitmentsActions.commitmentByIdLoaded, (state, action) => {
    return {
      ...state,
      selectedCommitment: action.commitment,
    };
  }),
  on(commitmentsActions.sidebarCommitmentLoading, (state, action) => {
    return {
      ...state,
      isSidebarLoading: action.isLoading,
    };
  }),
  on(commitmentsActions.sidebarCommitmentLoaded, (state, action) => {
    switch (action.sidebarCommitment.type) {
      case COMMITMENTS_TYPE.CONTRACTS:
        const sidebarContract: Partial<ISidebarContract> = {
          id: action.sidebarCommitment.id,
          has_commitments: action.sidebarCommitment?.has_commitments,
          project_id: action.sidebarCommitment.project_id,
          type: action.sidebarCommitment.type,
          added_at: action.sidebarCommitment.added_at,
          title: action.sidebarCommitment.title,
          description: action.sidebarCommitment.description,
          approval_status: action.sidebarCommitment.approval_status,
          files: action.sidebarCommitment.user_drive_files,
          commitment_items: action.sidebarCommitment.commitment_items.map((commitmentItem) => {
            return {
              id: commitmentItem.id,
              has_commitments: commitmentItem.has_commitments,
              item: commitmentItem.item,
              start_date: commitmentItem.start_date,
              duration: commitmentItem.duration,
              budget_tag: commitmentItem.budget_tag ?? {
                id: null,
                name: '',
              },
              costs: commitmentItem.costs,
            } as ISidebarCommitmentItem;
          }),
          service_provider: action.sidebarCommitment.service_provider
            ? {
                name: action.sidebarCommitment.service_provider.company_name,
                service_provider_user_id: action.sidebarCommitment.service_provider.id,
              }
            : {
                name: action.sidebarCommitment.temporary_service_provider.name,
                temporary_service_provider_id:
                  action.sidebarCommitment.temporary_service_provider.id,
              },
        };

        return {
          ...state,
          isSidebarLoading: false,
          sideBarChanged: false,
          sideBarContract: sidebarContract as ISidebarContract,
        };
      case COMMITMENTS_TYPE.CHANGE_ORDER:
        const sidebarChangeOrder: Partial<ISidebarChangeOrder> = {
          id: action.sidebarCommitment.id,
          has_commitments: action.sidebarCommitment?.has_commitments,
          project_id: action.sidebarCommitment.project_id,
          contract_id: action.sidebarCommitment.contract_id,
          type: action.sidebarCommitment.type,
          added_at: action.sidebarCommitment.added_at,
          title: action.sidebarCommitment.title,
          description: action.sidebarCommitment.description,
          approval_status: action.sidebarCommitment.approval_status,
          files: action.sidebarCommitment.user_drive_files,
          commitment_items: action.sidebarCommitment.commitment_items.map((commitmentItem) => {
            return {
              id: commitmentItem.id,
              item: commitmentItem.item,
              start_date: commitmentItem.start_date,
              duration: commitmentItem.duration,
              has_commitments: commitmentItem.has_commitments,
              budget_tag: commitmentItem.budget_tag ?? {
                id: null,
                name: '',
              },
              costs: commitmentItem.costs.map((cost: ISidebarCost) => {
                return {
                  ...cost,
                  is_dropdown: !!cost.parent_id,
                };
              }),
            } as ISidebarCommitmentItem;
          }),
          service_provider: action.sidebarCommitment.service_provider
            ? {
                name: action.sidebarCommitment.service_provider.company_name,
                service_provider_user_id: action.sidebarCommitment.service_provider.id,
              }
            : {
                name: action.sidebarCommitment.temporary_service_provider.name,
                temporary_service_provider_id:
                  action.sidebarCommitment.temporary_service_provider.id,
              },
        };

        return {
          ...state,
          sideBarChanged: false,
          isSidebarLoading: false,
          sideBarChangeOrder: sidebarChangeOrder as ISidebarChangeOrder,
        };
      case COMMITMENTS_TYPE.INVOICES:
        const sideBarInvoice: Partial<ISidebarInvoice> = {
          id: action.sidebarCommitment.id,
          project_id: action.sidebarCommitment.project_id,
          has_commitments: action.sidebarCommitment?.has_commitments,
          contract_id: action.sidebarCommitment.contract_id,
          type: action.sidebarCommitment.type,
          added_at: action.sidebarCommitment.added_at,
          title: action.sidebarCommitment.title ?? action.sidebarCommitment.invoice_number,
          description: action.sidebarCommitment.description,
          paid_status: action.sidebarCommitment.paid_status,
          files: action.sidebarCommitment.user_drive_files,
          costs: action.sidebarCommitment.commitment_items.flatMap(
            (commitmentItem: ISidebarCommitmentItem): ISidebarInvoiceCost[] => {
              return commitmentItem.costs.flatMap((cost: ISidebarCost): ISidebarInvoiceCost => {
                return {
                  ...cost,
                  is_dropdown: true, // is_dropdown should always be true for invoices
                  budget_tag: commitmentItem.budget_tag,
                  item: { ...commitmentItem.item },
                };
              });
            },
          ),
          service_provider: action.sidebarCommitment.service_provider
            ? {
                name: action.sidebarCommitment.service_provider.company_name,
                service_provider_user_id: action.sidebarCommitment.service_provider.id,
              }
            : {
                name: action.sidebarCommitment.temporary_service_provider.name,
                temporary_service_provider_id:
                  action.sidebarCommitment.temporary_service_provider.id,
              },
        };
        if (!!action?.sidebarCommitment?.paid_status) {
          sideBarInvoice.paid_date = action.sidebarCommitment.paid_date;
        }
        return {
          ...state,
          isSidebarLoading: false,
          sideBarChanged: false,
          sideBarInvoice: sideBarInvoice as ISidebarInvoice,
        };

      case COMMITMENTS_TYPE.DIRECT_COST:
        const sideBarDirectCost: Partial<ISidebarDirectCost> = {
          id: action.sidebarCommitment.id,
          project_id: action.sidebarCommitment.project_id,
          vendor: action.sidebarCommitment.vendor,
          has_commitments: action.sidebarCommitment?.has_commitments,
          type: action.sidebarCommitment.type,
          added_at: action.sidebarCommitment.added_at,
          title: action.sidebarCommitment.title,
          description: action.sidebarCommitment.description,
          paid_date: action.sidebarCommitment.paid_date,
          paid_status: action.sidebarCommitment.paid_status,
          files: action.sidebarCommitment.user_drive_files,
          commitment_items: action.sidebarCommitment.commitment_items.map((commitmentItem) => {
            return {
              id: commitmentItem.id,
              item: commitmentItem.item,
              budget_tag: commitmentItem.budget_tag ?? {
                id: null,
                name: '',
              },
              has_commitments: commitmentItem.has_commitments,
              costs: commitmentItem.costs,
            };
          }),
        };
        return {
          ...state,
          isSidebarLoading: false,
          sideBarChanged: false,
          sideBarDirectCost: sideBarDirectCost as ISidebarDirectCost,
        };
      default:
        console.warn('unhandled commitment type');
        return {
          ...state,
          sideBarChanged: false,
          isSidebarLoading: false,
        };
    }
  }),
  on(commitmentsActions.contractByIdLoaded, (state, action) => {
    return {
      ...state,
      selectedContract: action.contract,
      isSelectedCommitmentViewSummaryLoading: false,
    };
  }),
  on(commitmentsActions.selectedContractClosed, (state, action) => {
    return {
      ...state,
      selectedContract: null,
    };
  }),
  on(commitmentsActions.incrementPage, (state) => {
    return {
      ...state,
      sidebarPage: state.sidebarPage + 1,
    };
  }),
  on(commitmentsActions.decrementPage, (state) => {
    return {
      ...state,
      sidebarPage: state.sidebarPage - 1,
    };
  }),
  on(commitmentsActions.updateSelectedContract, (state, action) => {
    console.log('updateSelectedContract', action.contract);
    const updates: ISidebarContract = {
      ...(action.contract as ISidebarContract),
    };

    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: { ...state.sideBarContract, ...updates },
    };
  }),
  on(commitmentsActions.updateSelectedChangeOrder, (state, action) => {
    const updates = {
      ...(action.change_order as ISidebarChangeOrder),
    };

    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...state.sideBarChangeOrder,
        ...updates,
      },
    };
  }),
  on(commitmentsActions.updateSelectedInvoice, (state, action) => {
    const updates = {
      ...(action.invoice as ISidebarInvoice),
    };

    return {
      ...state,
      sideBarChanged: true,
      sideBarInvoice: {
        ...state.sideBarInvoice,
        ...updates,
      },
    };
  }),
  on(commitmentsActions.updateSelectedDirectCost, (state, action) => {
    const updates = {
      ...(action.directCost as ISidebarDirectCost),
    };

    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: {
        ...state.sideBarDirectCost,
        ...updates,
      },
    };
  }),
  on(commitmentsActions.addContractSpendEntry, (state, action) => {
    const modifiedContract: ISidebarContract = {
      ...state.sideBarContract,
      commitment_items: [...state.sideBarContract.commitment_items, defaultCommitmentItem],
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: {
        ...modifiedContract,
      },
    };
  }),
  on(commitmentsActions.addChangeOrderSpendEntry, (state, action) => {
    const modifiedChangeOrder: ISidebarChangeOrder = {
      ...state.sideBarChangeOrder,
      commitment_items: [...state.sideBarChangeOrder.commitment_items, defaultCommitmentItem],
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...modifiedChangeOrder,
      },
    };
  }),
  on(commitmentsActions.addDirectCostSpendEntry, (state, action) => {
    const modifiedDirectCost: ISidebarDirectCost = {
      ...state.sideBarDirectCost,
      commitment_items: [
        ...state.sideBarDirectCost.commitment_items,
        defaultDirectCostCommitmentItem,
      ],
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: {
        ...modifiedDirectCost,
      },
    };
  }),
  on(commitmentsActions.updateContractSpendEntry, (state, action) => {
    const modifiedContract: ISidebarContract = DeepCopyService.deepCopy(state.sideBarContract);
    modifiedContract.commitment_items[action.index] = {
      ...modifiedContract.commitment_items[action.index],
      ...action.commitmentItem,
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: modifiedContract,
    };
  }),
  on(commitmentsActions.updateDirectCostSpendEntry, (state, action) => {
    const modifiedDirectCost: ISidebarDirectCost = DeepCopyService.deepCopy(
      state.sideBarDirectCost,
    );
    modifiedDirectCost.commitment_items[action.index] = {
      ...modifiedDirectCost.commitment_items[action.index],
      ...action.commitmentItem,
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: modifiedDirectCost,
    };
  }),
  on(commitmentsActions.updateContractSpendEntryCost, (state, action) => {
    const items: ISidebarCommitmentItem[] = DeepCopyService.deepCopy(
      state.sideBarContract.commitment_items,
    );
    const commitmentItem: ISidebarCommitmentItem = items?.[action.spendEntryIndex];

    if (action.cost && commitmentItem?.costs?.[action.costIndex]) {
      commitmentItem.costs[action.costIndex] = action.cost;
    } else {
      console.warn('could not update cost');
    }

    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: {
        ...state.sideBarContract,
        commitment_items: items,
      },
    };
  }),
  on(commitmentsActions.updateDirectCostSpendEntryDescription, (state, action) => {
    const items: ISidebarCommitmentItem[] = DeepCopyService.deepCopy(
      state.sideBarDirectCost.commitment_items,
    );
    const commitmentItem: ISidebarCommitmentItem = items?.[action.spendEntryIndex];

    if (action.cost && commitmentItem?.costs?.[action.costIndex]) {
      commitmentItem.costs[action.costIndex] = action.cost;
    } else {
      console.warn('could not update cost');
    }

    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: {
        ...state.sideBarDirectCost,
        commitment_items: items,
      },
    };
  }),
  on(commitmentsActions.updateChangeOrderSpendEntry, (state, action) => {
    const modifiedChangeOrder: ISidebarChangeOrder = DeepCopyService.deepCopy(
      state.sideBarChangeOrder,
    );
    modifiedChangeOrder.commitment_items[action.index] = {
      ...modifiedChangeOrder.commitment_items[action.index],
      ...action.commitmentItem,
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: modifiedChangeOrder,
    };
  }),
  on(commitmentsActions.updateChangeOrderSpendEntryCost, (state, action) => {
    const items: ISidebarCommitmentItem[] = DeepCopyService.deepCopy(
      state.sideBarChangeOrder.commitment_items,
    );
    const commitmentItem: ISidebarCommitmentItem = items?.[action.spendEntryIndex];
    const existingCost = commitmentItem?.costs?.[action.costIndex];
    if (action.cost && existingCost) {
      commitmentItem.costs[action.costIndex] = {
        ...existingCost,
        name: action.cost.name,
        value: action.cost.value,
      };
    } else {
      console.warn('could not update cost');
    }

    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...state.sideBarChangeOrder,
        commitment_items: items,
      },
    };
  }),
  on(commitmentsActions.selectChangeOrderSpendEntryCost, (state, action) => {
    const items: ISidebarCommitmentItem[] = DeepCopyService.deepCopy(
      state.sideBarChangeOrder.commitment_items,
    );
    const commitmentItem: ISidebarCommitmentItem = items?.[action.spendEntryIndex];
    const existingCost = commitmentItem?.costs?.[action.costIndex];
    console.log(existingCost, action.cost);
    if (action.cost && existingCost) {
      commitmentItem.costs[action.costIndex] = {
        ...existingCost,
        id: existingCost.id,
        parent_id: action.cost?.is_dropdown ? action.cost.parent_id ?? action.cost.id : null,
        name: action.cost.name,
        is_dropdown: action.cost.is_dropdown,
      };
    } else {
      console.warn('could not update cost');
    }

    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...state.sideBarChangeOrder,
        commitment_items: items,
      },
    };
  }),
  on(commitmentsActions.selectInvoiceCost, (state, action) => {
    const modifiedInvoice: ISidebarInvoice = DeepCopyService.deepCopy(state.sideBarInvoice);
    console.log('selected cost', action.cost);
    modifiedInvoice.costs[action.costIndex] = {
      ...modifiedInvoice.costs[action.costIndex],
      budget_tag: action.cost.budget_tag ?? { name: '', id: null },
      item: action.cost.item,
      parent_id: action.cost.parent_id ?? action.cost.id,
      name: action.cost.name,
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarInvoice: modifiedInvoice,
    };
  }),
  on(commitmentsActions.updateInvoiceCostValue, (state, action) => {
    const modifiedInvoice: ISidebarInvoice = DeepCopyService.deepCopy(state.sideBarInvoice);
    modifiedInvoice.costs[action.costIndex] = {
      ...modifiedInvoice.costs[action.costIndex],
      value: action.cost.value,
    };
    return {
      ...state,
      sideBarChanged: true,
      sideBarInvoice: modifiedInvoice,
    };
  }),
  on(commitmentsActions.commitmentItemsLoaded, (state, action) => {
    return { ...state, budgetLineItems: action.items };
  }),
  on(commitmentsActions.addCostToContractCommitmentItem, (state, action) => {
    const newState: CommitmentsState = DeepCopyService.deepCopy(state);
    // insert one new cost at the specified index
    newState.sideBarContract.commitment_items[action.spendEntryIndex].costs.splice(
      action.newCostIndex,
      0,
      { ...defaultCost },
    );
    return { ...newState, sideBarChanged: true };
  }),
  on(commitmentsActions.addCostToDirectCostCommitmentItem, (state, action) => {
    const newState: CommitmentsState = DeepCopyService.deepCopy(state);
    newState.sideBarDirectCost.commitment_items[action.spendEntryIndex].costs.splice(
      action.newCostIndex,
      0,
      {
        ...defaultCost,
      },
    );
    return { ...newState, sideBarChanged: true };
  }),
  on(commitmentsActions.addCostToInvoice, (state, action) => {
    const canAddMoreCost =
      state.sideBarInvoice.costs.length <
      state.sidebarSelectedContractSummary.contract.commitment_items.reduce(
        (acc, item) => (acc += item.costs.length),
        0,
      );

    if (!canAddMoreCost) {
      return { ...state };
    }

    return {
      ...state,
      sideBarChanged: true,
      sideBarInvoice: {
        ...state.sideBarInvoice,
        costs: [...state.sideBarInvoice.costs, { ...defaultInvoiceCost }],
      },
    };
  }),
  on(commitmentsActions.addCostToChangeOrderCommitmentItem, (state, action) => {
    const newState: CommitmentsState = DeepCopyService.deepCopy(state);

    newState.sideBarChangeOrder.commitment_items[action.spendEntryIndex].costs.splice(
      action.newCostIndex,
      0,
      {
        ...defaultCost,
        is_dropdown: true,
      },
    );
    return { ...newState, sideBarChanged: true };
  }),
  on(commitmentsActions.removeContractSpendEntry, (state, action) => {
    let modifiedSidebarContract = DeepCopyService.deepCopy(state.sideBarContract);
    modifiedSidebarContract = deleteCommitmentLineItem(modifiedSidebarContract, action.index);

    return { ...state, sideBarChanged: true, sideBarContract: { ...modifiedSidebarContract } };
  }),
  on(commitmentsActions.removeContractSpendEntryCost, (state, action) => {
    const modifiedSidebarContract: ISidebarContract = DeepCopyService.deepCopy(
      state.sideBarContract,
    );

    const lineItem = modifiedSidebarContract?.commitment_items?.[action.spendEntryIndex];
    modifiedSidebarContract.commitment_items[action.spendEntryIndex] = deleteLineItemCosts(
      lineItem,
      action.costIndex,
    );
    return { ...state, sideBarChanged: true, sideBarContract: { ...modifiedSidebarContract } };
  }),
  on(commitmentsActions.removeInvoiceCost, (state, action) => {
    const modifiedSidebarInvoice: ISidebarInvoice = DeepCopyService.deepCopy(state.sideBarInvoice);
    if (modifiedSidebarInvoice?.costs?.[action.costIndex]?.id) {
      modifiedSidebarInvoice.costs[action.costIndex].deleted = true;
      modifiedSidebarInvoice.deleted_costs = [
        ...(modifiedSidebarInvoice.deleted_costs ?? []),
        modifiedSidebarInvoice.costs[action.costIndex],
      ];
    }
    modifiedSidebarInvoice.costs.splice(action.costIndex, 1);
    return { ...state, sideBarInvoice: { ...modifiedSidebarInvoice } };
  }),
  on(commitmentsActions.removeChangeOrderSpendEntry, (state, action) => {
    let modifiedSidebarChangeOrders = DeepCopyService.deepCopy(state.sideBarChangeOrder);

    modifiedSidebarChangeOrders = deleteCommitmentLineItem(
      modifiedSidebarChangeOrders,
      action.index,
    );

    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...modifiedSidebarChangeOrders,
      },
    };
  }),
  on(commitmentsActions.removeChangeOrderSpendEntryCost, (state, action) => {
    const modifiedSidebarChangeOrder: ISidebarChangeOrder = DeepCopyService.deepCopy(
      state.sideBarChangeOrder,
    );

    const lineItem = modifiedSidebarChangeOrder?.commitment_items?.[action.spendEntryIndex];
    modifiedSidebarChangeOrder.commitment_items[action.spendEntryIndex] = deleteLineItemCosts(
      lineItem,
      action.costIndex,
    );

    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: { ...modifiedSidebarChangeOrder },
    };
  }),
  on(commitmentsActions.removeDirectCostSpendEntry, (state, action) => {
    let modifiedSidebarDirectCost = DeepCopyService.deepCopy(state.sideBarDirectCost);
    modifiedSidebarDirectCost = deleteCommitmentLineItem(modifiedSidebarDirectCost, action.index);
    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: {
        ...modifiedSidebarDirectCost,
      },
    };
  }),
  on(commitmentsActions.removeDirectCostEntryDescription, (state, action) => {
    const modifiedSidebarDirectCost: ISidebarDirectCost = DeepCopyService.deepCopy(
      state.sideBarDirectCost,
    );

    const lineItem = modifiedSidebarDirectCost?.commitment_items?.[action.spendEntryIndex];
    modifiedSidebarDirectCost.commitment_items[action.spendEntryIndex] = deleteLineItemCosts(
      lineItem,
      action.costIndex,
    );

    return { ...state, sideBarChanged: true, sideBarDirectCost: { ...modifiedSidebarDirectCost } };
  }),
  on(commitmentsActions.clearCommitments, (_) => {
    return {
      ...initialState,
    };
  }),
  on(commitmentsActions.clearSidebar, (state) => {
    return {
      ...state,
      sidebarPage: COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE,
      sideBarChanged: false,
      sideBarContract: {
        ...defaultContract,
        project_id: state.sideBarContract.project_id,
      },
      sideBarChangeOrder: {
        ...defaultChangeOrder,
        project_id: state.sideBarChangeOrder.project_id,
      },
      sideBarInvoice: {
        ...defaultInvoice,
        project_id: state.sideBarInvoice.project_id,
      },
      sideBarDirectCost: {
        ...defaultDirectCost,
        project_id: state.sideBarDirectCost.project_id,
      },
    };
  }),
  on(commitmentsActions.setCommitmentProjectData, (state, action) => {
    return {
      ...state,
      projectData: action.projectData,
    };
  }),
  on(commitmentsActions.resetContractEntryDates, (state, action) => {
    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: {
        ...state.sideBarContract,
        commitment_items: state.sideBarContract.commitment_items.map((item) => ({
          ...item,
          start_date: null,
        })),
      },
    };
  }),
  on(commitmentsActions.sidebarSelectedContractSummaryLoaded, (state, action) => {
    return {
      ...state,
      sidebarSelectedContractSummary: action.contractDetails,
    };
  }),
  on(commitmentsActions.contractFilesChanged, (state, action) => {
    return {
      ...state,
      sideBarChanged: true,
      sideBarContract: {
        ...state.sideBarContract,
        files: action.files,
        removeFileIds: action.removeFileIds,
      },
    };
  }),
  on(commitmentsActions.changeOrderFilesChanged, (state, action) => {
    return {
      ...state,
      sideBarChanged: true,
      sideBarChangeOrder: {
        ...state.sideBarChangeOrder,
        files: action.files,
        removeFileIds: action.removeFileIds,
      },
    };
  }),
  on(commitmentsActions.invoiceFilesChanged, (state, action) => {
    return {
      ...state,
      sideBarChanged: true,
      sideBarInvoice: {
        ...state.sideBarInvoice,
        files: action.files,
        removeFileIds: action.removeFileIds,
      },
    };
  }),
  on(commitmentsActions.directCostFilesChanged, (state, action) => {
    return {
      ...state,
      sideBarChanged: true,
      sideBarDirectCost: {
        ...state.sideBarDirectCost,
        files: action.files,
        removeFileIds: action.removeFileIds,
      },
    };
  }),
);

const deleteLineItemCosts = (
  lineItem: Partial<ISidebarCommitmentItem>,
  costIndex: number,
): ISidebarCommitmentItem => {
  const modifiedLineItem = DeepCopyService.deepCopy(lineItem);
  if (modifiedLineItem.costs[costIndex].id) {
    modifiedLineItem.costs[costIndex].deleted = true;
    modifiedLineItem.deleted_costs = [
      ...(modifiedLineItem.deleted_costs ?? []),
      modifiedLineItem.costs[costIndex],
    ];
  }

  modifiedLineItem.costs.splice(costIndex, 1);

  return modifiedLineItem;
};

const deleteCommitmentLineItem = (
  commitment: ISidebarContract | ISidebarChangeOrder | ISidebarDirectCost,
  index: number,
) => {
  const modifiedCommitment = DeepCopyService.deepCopy(commitment);
  if (modifiedCommitment.commitment_items[index].id) {
    modifiedCommitment.commitment_items[index].deleted = true;
    modifiedCommitment.deleted_commitment_items = [
      ...(modifiedCommitment.deleted_commitment_items ?? []),
      modifiedCommitment.commitment_items[index],
    ];
  }

  modifiedCommitment.commitment_items.splice(index, 1);

  return modifiedCommitment;
};
