<app-custom-input-overlay-dropdown
  #checkListOverlay
  *ngIf="!isNewChecklistInput"
  [(model)]="model.name"
  [autoComplete]="false"
  [iconClass]="{
    position: 'p-input-icon-right',
    icon: checkListOverlay.isDropdownShown ? '-mt-1 mr-1 dropdown-icon opened' : ' -mt-1 mr-1 dropdown-icon closed'
  }"
  [required]="required"
  [readonly]="true"
  class="cursor-pointer"
  label="Checklist"
  name="checklist"
  placeholder="Checklist"
>
  <app-checklist-dropdown
    [showNoChecklistOption]="false"
    (selectedOption)="checkListOptionSelected($event); checkListOverlay.hideDropdown()"
    [checkLists]="getAllCheckList$ | async"
    [width]="checkListOverlay?.floatingInputComponent?.inputElement?.nativeElement?.clientWidth"
  >
    <ng-template #projectedContent>
      <div class="text-color_red_light icon-minus-box cursor-pointer"></div>
    </ng-template>
  </app-checklist-dropdown>
</app-custom-input-overlay-dropdown>

<app-floating-input
  (iconClick)="checkListOptionSelected({ action: SELECTED_OPTION.DESELECT })"
  *ngIf="isNewChecklistInput"
  [iconClass]="{
    position: 'p-input-icon-right',
    icon: 'icon-refresh mr-1 cursor-pointer text-color_secondary hover:text-color_reject'
  }"
  [(model)]="model.name"
  label="New Checklist Name"
  name="checklistInput"
  placeholder="New Checklist Name"
  required
></app-floating-input>

<ng-container *ngFor="let item of model?.items; let index = index; trackBy: trackByIndex">
  <div class="flex gap-2.5 items-center align-baseline">
    <div class="grid grid-cols-2 gap-2.5">
      <app-floating-input
        required
        [(model)]="item.name"
        [label]="'Checklist Item ' + (index + 1 | number)"
        [name]="'checklistItem_' + ((index | number) + 1)"
        [placeholder]="'Checklist Item ' + (index + 1 | number)"
      ></app-floating-input>
      <app-input-calendar
        required
        placeholder="Due Date"
        [date]="model?.items[index]?.end_date"
        [name]="'checklist_item_due_date_' + ((index | number) + 1)"
        [iconClass]="{
          iconPosition: 'p-input-icon-right',
          icon: 'icon-calendar-box text-color_secondary'
        }"
        (dateChanged)="updateModelDate($event, index)"
        [minStartDate]="minStartDate"
        [maxDate]="maxDate"
      ></app-input-calendar>
    </div>
    <div (click)="deleteItem(index)" class="text-color_secondary icon-minus-box cursor-pointer pt-5 pb-0.5"></div>
    <div (click)="insertItem(index)" class="text-color_red_light icon-plus-box cursor-pointer pt-5 pb-0.5"></div>
  </div>
</ng-container>
