export enum INTERACTION_BAR_STATES {
  NONE = 'none',
  RE_SCHEDULE = 're-schedule',
  RE_SCHEDULE_BY_CONTRACTOR = 're-schedule-contracotr',
  RE_SCHEDULE_BY_PROJECT = 're-schedule-by-porject',
  SCHEDULE = 'schedule',
  ADD_INVOICE = 'add-invoice',
  MESSAGES = 'messages',
  // CREATE_DISCUSSION = 'create-discussion',
  // DISCUSSION_VIEW = 'discussion-view',
  // DISCUSSION_THREAD_VIEW = 'discussion-thread-view',
  SELECT_PROJECT = 'select-project',
  CLOSE = 'close',
  SUCCESS_CLOSED = 'success-closed',
  EDIT_INVOICE = 'edit-invoice',
  MEETINGS = 'meetings',
  TASKS_EDIT_CREATE_SIDEBAR = 'tasks-edit-create-sidebar',
  TASK_DETAILS = 'task-details',
  LINE_ITEMS_SPEND_SUMMARY = 'line-items-spend-summary',
  REPORTING = 'reporting',
  REPORTING_INVOICE_PANEL = 'reporting-invoice-panel',
  ADD_PROPERTY = 'add-property',
  EDIT_PROPERTY = 'edit-property',
  ADD_PROPERTY_SHARE = 'add-property-share',
  EDIT_PROPERTY_SHARE = 'edit-property-share',
  NOTIFICATIONS = 'notif',
  FILE_SHARE = 'file-share',
  DOCUMENTS_LINK_SHARE = 'documents-link-share',
  COMMITMENTS_INTERACTION_BAR = 'commitments-interaction-bar',
  SIDEBAR_ADD_CONTRACT = 'sidebar-contract',
  SIDEBAR_CHANGE_ORDER = 'sidebar-change-order',
  SIDEBAR_INVOICE = 'sidebar-invoice',
  SIDEBAR_MISC_COST = 'sidebar-misc-cost',
  BUDGET_ADJUSTMENT = 'budget-adjustment',
  BUDGET_ADJUSTMENT_LOG = 'budget-adjustment-log',
  ANTICIPATED_COSTS = 'anticipated-costs',
  TEAM_MANAGEMENT = 'team-management',
  TEAM_PROP_PROJ_LIST = 'team-prop-proj-list',
  ROLLUP_INTERACTION_BAR = 'rollup-interaction-bar',
  PROJECT_PROGRESS = 'project-progress-tracking',
  ACTIVITY_SIDEBAR = 'activities-sidebar',
  MANAGE_PROJECT = 'manage-project',
  SETUP_PROJECT = 'project-setup',
}

export enum COMMITMENTS_INTERACTION_BAR_TYPE {
  ADD_CONTRACT = 1,
  CHANGE_ORDER,
  DIRECT_COST,
  INVOICES,
}

export enum COMMITMENTS_TYPE {
  CONTRACTS = 'contracts',
  CHANGE_ORDER = 'change_orders',
  DIRECT_COST = 'direct_costs',
  INVOICES = 'invoices',
}

// width: 825px;
export const widerPages = new Set<string>([
  INTERACTION_BAR_STATES.MESSAGES,
  INTERACTION_BAR_STATES.TASK_DETAILS,
  INTERACTION_BAR_STATES.ROLLUP_INTERACTION_BAR,
  INTERACTION_BAR_STATES.LINE_ITEMS_SPEND_SUMMARY,
]);

// width: 500px;
export const slightlyWiderPages = new Set<string>([
  INTERACTION_BAR_STATES.PROJECT_PROGRESS,
  INTERACTION_BAR_STATES.ACTIVITY_SIDEBAR,
  INTERACTION_BAR_STATES.TEAM_MANAGEMENT,
  INTERACTION_BAR_STATES.TEAM_PROP_PROJ_LIST,
  INTERACTION_BAR_STATES.MANAGE_PROJECT,
  INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT_LOG,
  INTERACTION_BAR_STATES.ANTICIPATED_COSTS,
  INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT,
  INTERACTION_BAR_STATES.SIDEBAR_ADD_CONTRACT,
  INTERACTION_BAR_STATES.SIDEBAR_CHANGE_ORDER,
  INTERACTION_BAR_STATES.SIDEBAR_INVOICE,
  INTERACTION_BAR_STATES.SIDEBAR_MISC_COST,
  INTERACTION_BAR_STATES.SETUP_PROJECT,
  INTERACTION_BAR_STATES.REPORTING,
  INTERACTION_BAR_STATES.REPORTING_INVOICE_PANEL,
]);

// NOTE: this crappy interaction bar implementation uses all kind of data in an unpredictable manner
// kept the additional object keys here for legacy, they should be moved into data
export type InteractionBarState = {
  action: INTERACTION_BAR_STATES;
  data?: any;
  oldState?: any;
  contractorId?: number; // todo remove and move into data
  title?: string; // todo remove and move into data
  person?: any; // todo remove and move into data
  project?: any; // todo remove and move into data
};
