import { ProjectsInterface } from './projects.interface';

export class ProjectsContractor implements ProjectsInterface {
  tabOneLabel = 'Bidding';
  tabTwoLabel = 'Invitations';
  tabThreeLabel = 'Planned';
  moneyIndicatorTwoTitle = 'Pending Bids ($)';
  moneyIndicatorOneTitle = 'Awarded Bids ($)';
  tableFields = ['title', 'email', 'company', 'planned', 'status'];
}
