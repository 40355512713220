<div class="team-management-wrapper px-8 pt-8 pb-2">
  <span class="flex justify-between pb-2 mr-5 gap-3">
    <div class="color-primary text-2xl font-semibold whitespace-nowrap">Team Members</div>
    <span class="color-secondary flex gap-5 items-center">
      <app-search-bar-minimalist (searchText)="searchMembers($event)"></app-search-bar-minimalist>
      <div class="inline border-l border-solid border-shade_5 h-full"></div>
      <span (click)="openAddTeamMemberSidebar()" class="flex gap-1 items-center pointer-cursor">
        <div class="icon-add-round btn-small btn"></div>
        <div class="text-sm font-semibold">Add Team Member</div>
      </span>
    </span>
  </span>
  <div class="grid-row header-row">
    <div>Name</div>
    <div>Email</div>
    <div>Homebase</div>
    <div class="flex gap-1">
      <span>Position</span>
      <span>/</span>
      <span>Role</span>
    </div>
    <div class="flex gap-1 justify-center">
      <span>Properties</span>
      <span>/</span>
      <span>Projects</span>
    </div>
    <div>Authority</div>
  </div>
  <div *ngIf="areTeamsFetching$ | async">
    <mat-progress-bar class="custom-loading-progress-bar" mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-scrollbar visibility="always" class="std-scrollbar" track="vertical" appearance="standard">
    <div *ngFor="let team of teams$ | async">
      <div *ngFor="let member of team.team_members">
        <div class="pt-2"></div>
        <div class="grid-row">
          <div class="row-item justify-between flex">
            <span class="flex gap-1.5 items-center">
              <span class="w-8 h-8 pr-2">
                <span class="w-inherit h-inherit rounded-full block">
                  <img
                    *ngIf="getProfilePicture(member.profile_picture_id) | async"
                    [src]="getProfilePicture(member.profile_picture_id) | async"
                    class="w-inherit h-inherit rounded-full block"
                  />
                  <span
                    *ngIf="!member.profile_picture_id && !(areProfilePicturesLoading$ | async)"
                    class="icon-logo-box-hex w-inherit h-inherit text-xl rounded-full block text-color_secondary"
                  ></span>
                  <span
                    *ngIf="!(getProfilePicture(member.profile_picture_id) | async) && member.profile_picture_id"
                    class="icon-refresh animate-spin pic-loading text-xl"
                  ></span>
                </span>
              </span>
              <app-faded-text displayGrid="true">
                <span class="color-secondary font-semibold flex-1">{{ member.first_name }} {{ member.last_name }}</span>
              </app-faded-text>
            </span>

            <app-un-styled-options-list
              [listItems]="member.permissions | optionPermissions"
              (selectedOption)="registerOption($event, member)"
              #unStyledOptionsList
            >
              <ng-template #projectedContent>
                <span
                  [ngClass]="{
                    'p-disabled invisible': !Object.keys(member?.permissions).length
                  }"
                  class="icon-options text-base pr-7 cursor-pointer text-color_secondary"
                  (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                ></span>
              </ng-template>
            </app-un-styled-options-list>
          </div>
          <div class="row-item font-semibold">
            <app-faded-text class="min-w-0">{{ member.email }}</app-faded-text>
          </div>
          <div class="row-item">{{ member?.home_base?.name }}</div>
          <div class="row-item">{{ member.position }}</div>
          <div class="row-item color-secondary font-semibold justify-center">
            {{ member.properties_count }} / {{ member.projects_count }}
          </div>
          <div class="row-item">{{ member.role | titlecase }}</div>
        </div>
        <div class="pb-2 border-b border-solid border-shade_5"></div>
      </div>
    </div>
    <div
      class="pt-5 text-shade_3 font-bold flex justify-center align center w-full"
      *ngIf="!totalTeamMembers && !(areTeamsFetching$ | async)"
    >
      No team members found
    </div>
  </ng-scrollbar>
</div>
