<mat-drawer-container (backdropClick)="backDropClicked()" class="interaction-bar-container" fullscreen>
  <mat-drawer
    [disableClose]="disableClose"
    #drawer
    (closedStart)="clearActiveBtn()"
    class="interaction-bar"
    [ngClass]="{
      wider: widerPages.has(drawerState),
      slightlyWider: slightlyWiderPages.has(drawerState)
    }"
    mode="over"
    [position]="drawerState | handleInteractionBarPosition"
  >
    <app-meetings
      *ngIf="user.data$ | async"
      #meetings
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.MEETINGS }"
      [reload]="drawerState == INTERACTION_BAR_STATE.MEETINGS && drawer.opened"
    ></app-meetings>
    <app-tasks-interaction-bar
      *ngIf="(user.data$ | async) && drawerState == INTERACTION_BAR_STATE.TASKS_EDIT_CREATE_SIDEBAR && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.TASKS_EDIT_CREATE_SIDEBAR }"
    >
    </app-tasks-interaction-bar>

    <app-rollups-interaction-bar
      *ngIf="(user.data$ | async) && drawerState == INTERACTION_BAR_STATE.ROLLUP_INTERACTION_BAR && drawer.opened"
    ></app-rollups-interaction-bar>

    <app-task-details
      *ngIf="(user.data$ | async) && drawerState == INTERACTION_BAR_STATE.TASK_DETAILS && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.TASK_DETAILS }"
    >
    </app-task-details>

    <app-line-item-spend-summary
      *ngIf="drawerState == INTERACTION_BAR_STATE.LINE_ITEMS_SPEND_SUMMARY && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.LINE_ITEMS_SPEND_SUMMARY }"
    >
    </app-line-item-spend-summary>

    <app-messages-wrapper
      [drawerState]="drawerState"
      [data]="drawerData"
      *ngIf="(user.data$ | async) && drawerState === INTERACTION_BAR_STATE.MESSAGES"
    ></app-messages-wrapper>
    <app-notifications
      *ngIf="user.data$ | async"
      #notif
      [ngClass]="{ 'disp-none': drawerState != 'notif' }"
    ></app-notifications>
    <app-re-schedule
      *ngIf="
        drawerState == INTERACTION_BAR_STATE.SCHEDULE ||
        drawerState == INTERACTION_BAR_STATE.RE_SCHEDULE ||
        drawerState == INTERACTION_BAR_STATE.RE_SCHEDULE_BY_PROJECT ||
        drawerState == INTERACTION_BAR_STATE.RE_SCHEDULE_BY_CONTRACTOR
      "
      [data]="drawerData"
      [type]="drawerState"
    ></app-re-schedule>

    <app-manage-team-members-sidebar
      *ngIf="drawerState === INTERACTION_BAR_STATE.TEAM_MANAGEMENT"
    ></app-manage-team-members-sidebar>

    <app-shared-team-prop-proj-sidebar
      [data]="drawerData"
      *ngIf="drawerState === INTERACTION_BAR_STATE.TEAM_PROP_PROJ_LIST"
    ></app-shared-team-prop-proj-sidebar>

    <!--    <app-add-invoice *ngIf="drawerState == 'add-invoice' || drawerState == 'edit-invoice'" [data]="drawerData">-->
    <!--    </app-add-invoice>-->

    <!--    <app-commitments-interaction-bar *ngIf="drawerState == 'commitments-interaction-bar'" [data]="drawerData">-->
    <!--    </app-commitments-interaction-bar>-->

    <app-contract-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.SIDEBAR_ADD_CONTRACT && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.SIDEBAR_ADD_CONTRACT }"
    ></app-contract-sidebar>
    <app-change-order-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.SIDEBAR_CHANGE_ORDER && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.SIDEBAR_CHANGE_ORDER }"
    ></app-change-order-sidebar>

    <app-invoice-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.SIDEBAR_INVOICE && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.SIDEBAR_INVOICE }"
    ></app-invoice-sidebar>

    <app-misc-cost-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.SIDEBAR_MISC_COST && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.SIDEBAR_MISC_COST }"
    >
    </app-misc-cost-sidebar>

    <app-track-item-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.PROJECT_PROGRESS && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.PROJECT_PROGRESS }"
    ></app-track-item-sidebar>

    <app-activity-sidebar
      *ngIf="drawerState == INTERACTION_BAR_STATE.ACTIVITY_SIDEBAR && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.ACTIVITY_SIDEBAR }"
    ></app-activity-sidebar>

    <app-project-setup
      *ngIf="drawerState == INTERACTION_BAR_STATE.SETUP_PROJECT && drawer.opened"
      [ngClass]="{ 'disp-none': drawerState != INTERACTION_BAR_STATE.SETUP_PROJECT }"
    >
    </app-project-setup>

    <app-select-project #projectInvite *ngIf="drawerState == 'select-project'"></app-select-project>

    <app-bar-reporting *ngIf="drawerState == INTERACTION_BAR_STATE.REPORTING" [data]="drawerData"> </app-bar-reporting>

    <app-invoice-report-panel *ngIf="drawerState == INTERACTION_BAR_STATE.REPORTING_INVOICE_PANEL" [data]="drawerData">
    </app-invoice-report-panel>

    <app-add-property
      *ngIf="drawerState == INTERACTION_BAR_STATE.ADD_PROPERTY || drawerState === INTERACTION_BAR_STATE.EDIT_PROPERTY"
      [data]="drawerData"
    >
    </app-add-property>

    <app-budget-adjustment-2 *ngIf="drawerState === INTERACTION_BAR_STATE.BUDGET_ADJUSTMENT"></app-budget-adjustment-2>

    <app-budget-adjustment-log
      *ngIf="drawerState === INTERACTION_BAR_STATE.BUDGET_ADJUSTMENT_LOG"
      [projectSpends]="drawerData"
    >
    </app-budget-adjustment-log>

    <app-anticipated-costs *ngIf="drawerState === INTERACTION_BAR_STATE.ANTICIPATED_COSTS"></app-anticipated-costs>

    <app-share-document *ngIf="drawerState == INTERACTION_BAR_STATE.FILE_SHARE" [data]="drawerData">
    </app-share-document>

    <app-share-document-link *ngIf="drawerState == INTERACTION_BAR_STATE.DOCUMENTS_LINK_SHARE" [data]="drawerData">
    </app-share-document-link>

    <app-manage-project *ngIf="drawerState === INTERACTION_BAR_STATE.MANAGE_PROJECT"></app-manage-project>
  </mat-drawer>

  <ng-content></ng-content>
</mat-drawer-container>
