import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getMessagingView } from '../store/messages/messages.selectors';

@Injectable({
  providedIn: 'root',
})
export class MessagingFooterService implements OnDestroy {
  save = new EventEmitter();
  discard = new EventEmitter();
  private isDestroyed$ = new Subject<void>();
  selectedView$ = this.store.select(getMessagingView);

  constructor(private store: Store) {}

  emitSave() {
    this.save.next(true);
  }

  emitDiscard() {
    this.discard.next(true);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
