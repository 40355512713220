import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from '../services/notifications.service';

@Injectable()
export class RestRequestIntercept implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private notif: NotificationsService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let duplicate;
    // this is in consideration because there are requests that need no Authorization in order to work well
    if (req.headers.get('Authorization') !== 'none') {
      duplicate = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.storage.getToken()),
      });
    } else {
      duplicate = req.clone({ headers: req.headers.delete('Authorization') });
    }

    // add timezone to header
    duplicate = req.clone({
      headers: duplicate.headers.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone),
    });

    return next.handle(duplicate).pipe(
      catchError((error) => {
        if (error?.status === 402) {
          this.notif.showError(
            `There is a problem with your subscription: ${error?.error?.message || 'please contact the administrator.'}`,
          );
        }
        throw error;
      }),
    );

    // .pipe(tap(evt => {
    // if (evt instanceof HttpResponse) {
    //   console.log('---&gt; status:', evt.status);
    //   console.log('---&gt; filter:', req.params.get('filter'));
    // }
    // }));
  }
}
