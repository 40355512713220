<div
  (click)="discussionItemClicked()"
  class="discussion-item-wrapper items-baseline py-4 border-b border-solid text-size_l cursor-pointer"
>
  <app-messaging-bubble
    class="cursor-pointer"
    [isFilled]="!group.is_read"
    [type]="getBubbleType(group)"
  ></app-messaging-bubble>
  <div class="flex items-baseline">
    <div class="discussion-title font-semibold text-base ml-6 mr-4">{{ group.name }}</div>
    <div class="discussion-type text-xs uppercase">
      {{ group.type }}
      <!--      {{ discussionTypeToLiteral[group.type] }}-->
    </div>
  </div>
  <div class="discussion-participants text-xs uppercase text-left">
    <app-faded-text>
      <ng-container *ngIf="group?.members?.length - 1 > 1; else nonGroup">
        {{ group.members.length }} PEOPLE
      </ng-container>
      <ng-template #nonGroup>{{ (group.members | removeLoggedInUser)?.[0]?.name }}</ng-template>
    </app-faded-text>
  </div>
  <div class="discussion-date font-semibold text-xs uppercase text-right">
    {{ group.last_comment_date | datepipe: DATE_FORMAT }}
  </div>
  <span
    *ngIf="group.status !== 'archived'"
    (click)="archiveGroup(); $event.stopPropagation()"
    class="icon btn icon-delete ms-3 text-xl text-center w-full"
  ></span>
</div>
