import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OPTIONS } from '../../../../../framework/constants/options-list.constants';
import { ContractorsApiService } from '../../../../../services/contractors-api.service';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { BID_STATUS } from '../../../../../framework/constants/project.constants';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { ContractorInterface } from './contractor.interface';
import { ContractorManager } from './contractor.manager';
import { ContractorContractor } from './contractor.contractor';
import { ProjectApiService } from '../../../../../services/project-api.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import {
  NO_TIMEZONE,
  NO_TIMEZONE_DATE,
  NO_TIMEZONE_TIME,
  UTC_DATE,
} from 'src/app/pipes/framework/date-custom.pipe';
import moment from 'moment';
import { AppState } from '../../../../../store/app-state';
import { Store } from '@ngrx/store';
import { DriveService } from '../../../../../services/drive.service';
import { MessagesStateService } from '../../../../../services/messages-state.service';
import { PhonePipe } from '../../../../../pipes/framework/phone.pipe';
import { MoneyPipe } from '../../../../../pipes/framework/money-short.pipe';
import { NgIf, NgClass } from '@angular/common';
import { UnStyledOptionsListComponent } from '../../../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { FadedTextComponent } from '../../../../../framework/faded-text/faded-text.component';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.scss'],
  standalone: true,
  imports: [FadedTextComponent, UnStyledOptionsListComponent, NgIf, NgClass, MoneyPipe, PhonePipe],
})
export class ContractorComponent implements OnInit {
  @Input() title;
  @Input() isShort;
  @Input() showVisitDate;
  BID_STATUS = BID_STATUS;
  @Output() changedOptions = new EventEmitter();
  optionsShortFormat: Array<OPTIONS>;
  disabledActionList = [];
  showInfo = false;
  contractor: any = { license: {} };
  date = '';
  hour = '';
  viewModel: ContractorInterface;
  NO_TIMEZONE = NO_TIMEZONE;
  NO_TIMEZONE_DATE = NO_TIMEZONE_DATE;
  NO_TIMEZONE_TIME = NO_TIMEZONE_TIME;
  UTC_DATE = UTC_DATE;

  constructor(
    private contractorApi: ContractorsApiService,
    private interactionBarState: InteractionBarStateService,
    public user: CurrentUserService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private driveService: DriveService,
    private store: Store<AppState>,
    private messagesStateService: MessagesStateService,
  ) {
    if (this.user.isManager) {
      this.viewModel = new ContractorManager();
    } else {
      this.viewModel = new ContractorContractor();
    }
  }

  @Input() set data(value) {
    if (value) {
      if (this.user.isManager) {
        this.setStateManager(value);
      } else {
        this.setStateContractor(value);
      }
    }
  }

  setStateManager(value) {
    this.contractor = value;
    this.contractor.internStatus = 'invited';

    if (value.schedule_visits) {
      if (value.schedule_visits.length > 0) {
        this.contractor.internStatus = 'scheduled';
      }
    }

    if (value.bid) {
      this.contractor.internStatus = value?.bid?.status?.id;
    }
  }

  setStateContractor(value) {
    this.contractor = value;
    this.contractor.internStatus = 'invited';

    if (value.schedule_visits) {
      if (
        value.schedule_visits.length > 0 &&
        value.schedule_visits[0].contractor_id === this.user.data.contractor.id
      ) {
        this.contractor.internStatus = 'scheduled';
      }
    }

    if (value.bid) {
      if (value.bid.contractor_id === this.user.data.contractor.id) {
        this.contractor.internStatus = value.bid.status.id;
      }
    }
  }

  ngOnInit() {
    if (this.contractor.user.type.name === 'contractor') {
      this.contractor.user.type.name = 'general contractor';
    }
    switch (this.contractor.internStatus) {
      case 'invited':
        this.optionsShortFormat = this.viewModel.optionsAutoInvited;
        // todo change has_commitment to whatever comes from backend
        // this.disabledActionList = this.contractor?.has_commitments ? [OPTIONS.DISMISS] : []; // todo: check if important, should be revoke
        this.setDate(new Date(this.contractor.invite.created_at));
        break;
      case 'scheduled':
        if (this.contractor.schedule_visits) {
          if (this.contractor.visit) {
            this.setVisitTime(this.contractor.visit);
          } else if (this.contractor.schedule_visits.length > 0) {
            this.contractor.schedule_visits.sort((a, b) => {
              return (
                new Date(a.visit.start_date_time).valueOf() -
                new Date(b.visit.start_date_time).valueOf()
              );
            });
            this.setVisitTime(this.contractor.schedule_visits[0].visit);
          }
        }
        this.optionsShortFormat = this.viewModel.optionsVisitScheduled;
        break;
      case BID_STATUS.PENDING:
        this.setBidDate();
        this.optionsShortFormat = this.viewModel.optionsBidSubmitted;
        break;
      case BID_STATUS.ACCEPTED:
        this.setBidDate();
        this.optionsShortFormat = this.viewModel.optionsAwarded;
        break;
      case BID_STATUS.DECLINED:
        this.setBidDate();
        this.optionsShortFormat = this.viewModel.optionsBidSubmitted;
        break;
      default:
        break;
    }

    if (this.showVisitDate) {
      if (this.contractor.schedule_visits) {
        if (this.contractor.visit) {
          this.setVisitTime(this.contractor.visit);
        } else if (this.contractor.schedule_visits.length > 0) {
          this.contractor.schedule_visits.sort((a, b) => {
            return (
              new Date(a.visit.start_date_time).valueOf() -
              new Date(b.visit.start_date_time).valueOf()
            );
          });
          this.setVisitTime(this.contractor.schedule_visits[0]);
        }
      }
    }
  }

  setBidDate() {
    this.setDate(new Date(this.contractor.bid.updated_at));
  }

  setDate(date) {
    const formatedHout = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
      .toTimeString()
      .split(':');
    this.hour = formatedHout[0] + ':' + formatedHout[1];
    this.date = date; //.toDateString()
  }

  registerOption(option: any, contractor) {
    switch (option) {
      case OPTIONS.INFO:
        this.showInfo = !this.showInfo;
        break;
      case OPTIONS.ACCEPT_BID:
        this.changedOptions.emit({
          action: OPTIONS.ACCEPT_BID,
          data: contractor.bid.id,
        });
        break;
      case OPTIONS.SCHEDULE:
        if (this.user.isManager) {
          this.interactionBarState.openReScheduleByContractor(contractor.id);
        } else {
          this.interactionBarState.openReScheduleByProject(contractor.id);
        }
        break;
      case OPTIONS.DOWNLOAD:
        this.notif.showLoading();
        this.driveService.downloadSelectedDocuments(
          [contractor.bid.user_drive_file],
          contractor?.bid?.user_drive_file?.name ?? '',
        );
        break;
      case OPTIONS.MESSAGE:
        this.messagesStateService.newMessageTo(contractor.user);
        break;

      case OPTIONS.RESCHEDULE:
        if (this.user.isManager) {
          if (contractor.project_id) {
            this.interactionBarState.openReScheduleByProjectAndContracotr(
              contractor.id,
              contractor.project_id,
            );
          } else {
            this.interactionBarState.openReScheduleByContractor(contractor.id);
          }
        } else {
          this.interactionBarState.openReScheduleByProject(contractor.id);
        }
        break;
      case OPTIONS.DISMISS:
      case OPTIONS.CANCEL:
        this.notif.showPopup('Not implemented!');
        return;
        this.notif.showPopup('Dismiss service provider?').then((resp) => {
          if (!resp) {
            return;
          }
          const invite = contractor.invite;
          if (invite) {
            // this.projectApi.deleteInvite(invite.id).then(
            //   (data) => {
            //     this.changedOptions.emit({ action: 'refresh', data: null });
            //   },
            //   (err) => {
            //     this.notif.showError(err);
            //   },
            // );
          } else {
            this.notif.showError('Invite not found!');
          }
        });
        break;
      case OPTIONS.REVOKE: {
        this.changedOptions.emit({
          action: OPTIONS.REVOKE,
          data: contractor.bid.id,
        });
        break;
      }
    }
  }

  toggleFavorite(contractor) {
    this.contractorApi.setFavorite(contractor.id, !contractor.is_favorite).then(
      (data) => {
        this.changedOptions.emit({ action: 'refresh', data: null });
      },
      (err) => {
        this.notif.showError(err);
      },
    );
  }

  private setVisitTime(visit) {
    // used to be interval and day of visit obj
    this.date = moment.utc(visit.start_date_time).local().format('MM/DD/YYYY');
    this.hour = moment.utc(visit.start_date_time).local().format('hh:mm A');
  }
}
