import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_OVERLAY_VIEWS, CustomOverlayService } from '../../services/custom-overlay.service';
import { emptyAreaOptions } from '../constants/documents.constants';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { OPTIONS } from '../constants/options-list.constants';

@Component({
  selector: 'app-drive-empty-area',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './drive-empty-area.component.html',
  styleUrls: ['./drive-empty-area.component.scss'],
})
export class DriveEmptyAreaComponent implements AfterViewInit, OnDestroy {
  @Output() registerEmptyAreaOption = new EventEmitter<OPTIONS>();

  private isDestroyed$ = new Subject<void>();
  constructor(private overlayService: CustomOverlayService) {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  openEmptyOverlay($event: MouseEvent) {
    if (this.overlayService.isOpened()) {
      this.overlayService.closeOverlay();
    }
    this.overlayService.openOverlay(
      { position: 'relative', x: $event.x + 70, y: $event.y },
      { listItems: emptyAreaOptions },
      CUSTOM_OVERLAY_VIEWS.STYLED_OPTIONS_LIST,
    );

    this.overlayService.outputData$.pipe(first()).subscribe((selectedOption: OPTIONS) => {
      this.registerEmptyAreaOption.emit(selectedOption);
    });
  }
}
