import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-checklist-item',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule],
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss'],
})
export class ChecklistItemComponent {
  @Input() checked: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() disabled = false;
}
