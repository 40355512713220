import { Component, OnInit } from '@angular/core';
import { ForecastAdjustmentsViewComponent } from '../../forecast-adjustments-view/forecast-adjustments-view.component';

/**
 * @deprecated delete this
 */
@Component({
  selector: 'app-forecast-adjustments',
  templateUrl: './forecast-adjustments.component.html',
  styleUrls: ['./forecast-adjustments.component.scss'],
  standalone: true,
  imports: [ForecastAdjustmentsViewComponent],
})
export class ForecastAdjustmentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
