import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { DefaultOptionsListComponent } from '../../default-options-list/default-options-list.component';
import { IChecklist } from '../../../store/tasks/tasks.interfaces';
import { SELECTED_OPTION } from '../../overlays/option-list.constants';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { IItemTrackingChecklist } from '../../../store/item-tracking/item-tracking.reducer';

@Component({
  selector: 'app-checklist-dropdown',
  templateUrl: './checklist-dropdown.component.html',
  styleUrls: ['./checklist-dropdown.component.scss'],
  standalone: true,
  imports: [NgScrollbar, NgForOf, NgIf, CdkOverlayOrigin, NgTemplateOutlet, NgStyle],
})
export class ChecklistDropdownComponent extends DefaultOptionsListComponent implements OnInit {
  @ContentChild('projectedContent') projectedContentTemplate: TemplateRef<any>;
  @Input() checkLists: IChecklist[] | IItemTrackingChecklist[] = [];
  @Input() showNoChecklistOption = true;
  @Input() width: any;
  SELECTED_OPTION = SELECTED_OPTION;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
