import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-create-link',
  templateUrl: './create-link.component.html',
  styleUrls: ['./create-link.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CreateLinkComponent implements OnInit {
  input = new UntypedFormControl('', [Validators.required]);
  constructor(
    public dialogRef: MatDialogRef<CreateLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { href: string } | null,
  ) {
    if (data?.href) {
      this.input.setValue(data.href);
    }
  }

  ngOnInit(): void {}

  save(): void {
    if (this.input.valid) {
      this.dialogRef.close(this.withHttp(this.input.value));
    } else {
      this.input.markAsTouched();
    }
  }

  withHttp = (url) =>
    url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
      schemma ? match : `http://${nonSchemmaUrl}`,
    );
}
