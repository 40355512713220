import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    let nr = Number.parseInt(value, 10);
    let valueUnit = 'B';

    const KB = 1000; // note: we need kilobytes not kibibytes (1000 vs 1024)

    if (nr / KB > 1) {
      nr = Math.trunc(nr / KB);
      valueUnit = 'KB';
    }

    if (nr / KB > 1) {
      nr = Math.trunc(nr / KB);
      valueUnit = 'MB';
    }

    if (nr / KB > 1) {
      nr = Math.trunc(nr / KB);
      valueUnit = 'GB';
    }

    return nr + ' ' + valueUnit;
  }
}
