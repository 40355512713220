import { createFeatureSelector, createSelector } from '@ngrx/store';
import { settingsFeatureKey } from './settings.reducer';
import { ISettings } from './settings.interface';

export const settingsFeatureSelector = createFeatureSelector<ISettings>(settingsFeatureKey);

export const getBudgetTemplates = createSelector(settingsFeatureSelector, (state) =>
  state.budgetTemplates.filter((template) => !template?.is_deleted),
);

export const getBudgetTemplateItemsNonEmpty = createSelector(settingsFeatureSelector, (state) =>
  state.budgetTemplates.filter(
    (template) => template.template_items.length && !template?.is_deleted,
  ),
);

export const getSelectedBudgetTemplate = createSelector(settingsFeatureSelector, (state) => {
  const selectedTemplate = state.budgetTemplates.find(
    (template) => template.id === state.selectedBudgetTemplateId,
  );
  return {
    ...selectedTemplate,
    template_items: selectedTemplate?.template_items?.filter((item) => !item?.is_deleted),
  };
});

export const getIsLoading = createSelector(settingsFeatureSelector, (state) => state.isLoading);

export const getSelectedBudgetTagTemplate = createSelector(settingsFeatureSelector, (state) =>
  state.budgetTagTemplates.find((template) => template.id === state.selectedBudgetTagTemplateId),
);

export const getGlTemplates = createSelector(settingsFeatureSelector, (state) =>
  state.glTemplates.filter((template) => !template?.is_deleted),
);
export const getBudgetTagTemplates = createSelector(settingsFeatureSelector, (state) =>
  state.budgetTagTemplates.filter((template) => !template?.is_deleted),
);

export const getSelectedGlTemplate = createSelector(settingsFeatureSelector, (state) =>
  state.glTemplates.find((template) => template.id === state.selectedGlTemplateId),
);
