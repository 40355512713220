<div class="webapp-page-container">
  <div class="text-wrapper">
    <div class="default-cursor title-page">Service Providers</div>

    <div class="options-bar">
      <div (click)="setActiveFilter(0)" class="option active">All</div>
      <div (click)="setActiveFilter(1)" class="option">Preferred</div>
      <div (click)="setActiveFilter(2)" class="option">Invited</div>
      <div (click)="setActiveFilter(3)" class="option">Scheduled</div>

      <div class="search-bar">
        <app-search-bar-minimalist (searchText)="registerSearch($event)"></app-search-bar-minimalist>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'disp-none': isEmptyData }" class="content">
    <div
      class="header"
      [ngClass]="{
        'visible-all': !(state === CONTRACTOR_SELECTOR.INVITED || state === CONTRACTOR_SELECTOR.SCHEDULED)
      }"
    >
      <div *ngIf="!(this.state == 'invited' || this.state == 'scheduled')" class="column checkbox">
        <!--        <mat-checkbox-->
        <!--          (change)="$event ? masterToggle() : null"-->
        <!--          [checked]="isAllSelected()"-->
        <!--          [indeterminate]="!isOneSelected() && !isAllSelected()">-->
        <!--        </mat-checkbox>-->
      </div>
      <div class="column">
        Company
        <!--        <div-->
        <!--          *ngIf="columnSort.company.sort != SORT_DIR.DISABLED"-->
        <!--          [ngClass]="{-->
        <!--            show: columnSort.company.sort != SORT_DIR.NONE,-->
        <!--            invert: columnSort.company.sort == SORT_DIR.DESC-->
        <!--          }"-->
        <!--          class="sort-arrow"-->
        <!--        >-->
        <!--          <span-->
        <!--            (click)="sortByColumn('company')"-->
        <!--            class="icon-arrow-right"-->
        <!--          ></span>-->
        <!--        </div>-->
      </div>
      <div class="column">
        Address
        <!--        <div-->
        <!--          *ngIf="columnSort.addr.sort != SORT_DIR.DISABLED"-->
        <!--          [ngClass]="{-->
        <!--            show: columnSort.addr.sort != SORT_DIR.NONE,-->
        <!--            invert: columnSort.addr.sort == SORT_DIR.DESC-->
        <!--          }"-->
        <!--          class="sort-arrow"-->
        <!--        >-->
        <!--          <span (click)="sortByColumn('addr')" class="icon-arrow-right"></span>-->
        <!--        </div>-->
      </div>
      <div class="column">
        Contact
        <!--        <div-->
        <!--          *ngIf="columnSort.contact.sort != SORT_DIR.DISABLED"-->
        <!--          [ngClass]="{-->
        <!--            show: columnSort.contact.sort != SORT_DIR.NONE,-->
        <!--            invert: columnSort.contact.sort == SORT_DIR.DESC-->
        <!--          }"-->
        <!--          class="sort-arrow"-->
        <!--        >-->
        <!--          <span-->
        <!--            (click)="sortByColumn('contact')"-->
        <!--            class="icon-arrow-right"-->
        <!--          ></span>-->
        <!--        </div>-->
      </div>
      <div class="column license-type">
        Keywords
        <!--        & Classes-->
        <!--        <div-->
        <!--          *ngIf="columnSort.license.sort != SORT_DIR.DISABLED"-->
        <!--          [ngClass]="{-->
        <!--            show: columnSort.license.sort != SORT_DIR.NONE,-->
        <!--            invert: columnSort.license.sort == SORT_DIR.DESC-->
        <!--          }"-->
        <!--          class="sort-arrow"-->
        <!--        >-->
        <!--          <span-->
        <!--            (click)="sortByColumn('license')"-->
        <!--            class="icon-arrow-right"-->
        <!--          ></span>-->
        <!--        </div>-->
      </div>
      <div [ngClass]="{ 'meeting-date-show': this.state == 'scheduled' }" class="column meeting-date">
        Meeting
        <!--        <div-->
        <!--          *ngIf="columnSort.meeting.sort != SORT_DIR.DISABLED"-->
        <!--          [ngClass]="{-->
        <!--            show: columnSort.meeting.sort != SORT_DIR.NONE,-->
        <!--            invert: columnSort.meeting.sort == SORT_DIR.DESC-->
        <!--          }"-->
        <!--          class="sort-arrow"-->
        <!--        >-->
        <!--          <span-->
        <!--            (click)="sortByColumn('meeting')"-->
        <!--            class="icon-arrow-right"-->
        <!--          ></span>-->
        <!--        </div>-->
      </div>
      <div class="column-badges">
        Labor Structure
        <!--          <span class="icon-prevailing-wage active"-->
        <!--                matTooltip="Prevailing Wage"-->
        <!--                ></span>-->
        <!--          <span class="icon-union-contractor active"-->
        <!--                matTooltip="Union Contractor"-->
        <!--                ></span>-->
        <!--          <span class="icon-oshpd active"-->
        <!--                matTooltip="Healthcare / OSHPD"-->
        <!--                ></span>-->
      </div>
      <div class="column-favorite">Favorite</div>
      <!--        <div class="column labor-structure">-->
      <!--          Labor Structure-->
      <!--        </div>-->

      <div
        class="column-invite"
        *ngIf="this.state == 'invited' || this.state == 'scheduled' || this.contractosSelected.length === 0"
      >
        Send Invite
      </div>

      <div class="column-invite btn-small">
        <span
          (click)="inviteProjects()"
          *ngIf="!(this.state == 'invited' || this.state == 'scheduled') && this.contractosSelected.length > 0"
          class="icon-send-multiple-invite cursor-pointer"
          matTooltip="Invite to project"
        >
          <!--            && !isOneSelected()-->
        </span>
      </div>
    </div>

    <ng-scrollbar
      #scroll2
      [ngClass]="{
        'visibility-none': !(state === CONTRACTOR_SELECTOR.INVITED || state === CONTRACTOR_SELECTOR.SCHEDULED)
      }"
      class="std-scrollbar"
      appearance="compact"
    >
      <cdk-virtual-scroll-viewport
        class="table-wrapper"
        itemSize="60"
        maxBufferPx="1500"
        minBufferPx="1000"
        scrollViewport
        smoothScroll
      >
        <div *cdkVirtualFor="let item of data; let count = count" class="project-segment">
          <div *ngIf="item.id < 0" class="message-row">
            {{ item.message }}
          </div>

          <div [ngClass]="{ 'project-name-show': item.project_title }" class="project-name">
            <div class="title">
              {{ item.project_title }}
            </div>
          </div>

          <div *ngFor="let contractor of item.invited_contractors" class="row contractor-row contractor-row-show">
            <div class="column">
              <div class="image">
                <app-img-loading
                  [src]="contractor?.user?.profile_picture_url"
                  [imgWidth]="ICON_WIDTH"
                  [imgHeight]="ICON_HEIGHT"
                  class="contractor-logo"
                ></app-img-loading>
              </div>
              <div class="text-wrapper">
                <div class="line-one title">
                  {{ contractor.license.company_name }}
                </div>
                <div class="line-two">
                  <!--                Electrical-->
                </div>
              </div>
            </div>
            <div class="column">
              <div class="text-wrapper">
                <div class="line-one">
                  {{ contractor.license.street }}, {{ contractor.license.city }},
                  {{ contractor.license.state }}
                </div>
                <div class="line-two">{{ contractor.license.zip_code }}</div>
              </div>
            </div>
            <div class="column">
              <div *ngIf="contractor.user" class="text-wrapper">
                <div class="line-one">{{ contractor.user.phone | phone }}</div>
                <div class="line-two">{{ contractor.user.email }}</div>
              </div>
            </div>
            <div class="column license-type">
              <div class="text-wrapper">
                <div class="line-one" *ngIf="contractor.keywords">
                  <span class="contractor-keywords" [matTooltip]="getKeywords(contractor.keywords)">
                    {{ getKeywords(contractor.keywords) }}
                  </span>
                </div>
                <div class="line-two">
                  <!--                <span-->
                  <!--                  *ngFor="let cls of contractor.license.classifications"-->
                  <!--                  [matTooltip]="cls.classification.short_description"-->
                  <!--                  -->
                  <!--                  style="padding-right: 0.5rem"-->
                  <!--                >-->
                  <!--                  {{ cls.classification.key }}-->
                  <!--                </span>-->
                </div>
              </div>
            </div>

            <div
              *ngIf="contractor.schedule_visits"
              [ngClass]="{ 'meeting-date-show': this.state == 'scheduled' }"
              class="column meeting-date"
            >
              <div *ngIf="contractor.schedule_visits.length > 0" class="text-wrapper">
                <div class="line-one">
                  {{ contractor.schedule_visits[0].visit.day | datepipe: NO_TIMEZONE }}
                </div>
                <div class="line-two">
                  {{ contractor.schedule_visits[0].visit.interval }}
                </div>
              </div>
            </div>

            <div *ngIf="!contractor.schedule_visits"></div>

            <div class="column-badges">
              <span
                [ngClass]="{ active: contractor.prevailing_wage == true }"
                class="icon-prevailing-wage"
                matTooltip="Prevailing Wage"
              ></span>
              <span
                [ngClass]="{ active: contractor.union_contractor == true }"
                class="icon-union-contractor"
                matTooltip="Union Contractor"
              ></span>
              <span
                [ngClass]="{ active: contractor.oshpd_certified == true }"
                class="icon-oshpd"
                matTooltip="Healthcare / OSHPD"
              ></span>
            </div>
            <div
              (click)="toggleFavorite(contractor)"
              [ngClass]="{ 'star-accent': contractor.is_favorite }"
              class="star btn-small column-favorite cursor-pointer"
            >
              <span *ngIf="!contractor.is_favorite" class="icon-star-u"></span>
              <span *ngIf="contractor.is_favorite" class="icon-star"></span>
            </div>

            <div class="message column-invite cursor-pointer">
              <app-un-styled-options-list
                #unStyledOptionsList
                (selectedOption)="registerOption($event, contractor, item)"
                [listItems]="contractorOptions"
              >
                <ng-template #projectedContent>
                  <div class="message btn-small column-invite">
                    <span
                      (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                      *ngIf="this.state == 'invited' || this.state == 'scheduled'"
                      class="icon-options"
                    ></span>
                  </div>
                </ng-template>
              </app-un-styled-options-list>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-scrollbar>

    <ng-scrollbar
      #scroll
      [ngClass]="{
        'visibility-none': !(state === CONTRACTOR_SELECTOR.ALL || state === CONTRACTOR_SELECTOR.PREFERRED)
      }"
      appearance="compact"
      class="std-scrollbar visible-all"
    >
      <cdk-virtual-scroll-viewport
        class="table-wrapper"
        itemSize="60"
        maxBufferPx="1500"
        minBufferPx="1000"
        scrollViewport
        smoothScroll
      >
        <!--        *ngIf="-->
        <!--        state === CONTRACTOR_SELECTOR.ALL ||-->
        <!--        state === CONTRACTOR_SELECTOR.PREFERRED-->
        <!--      "-->
        <div *cdkVirtualFor="let contractor of data; trackBy: track">
          <div *ngIf="contractor.id < 0" class="message-row">
            {{ contractor.message }}
          </div>
          <div *ngIf="contractor.id >= 0" class="row contractor-row contractor-row-show">
            <div class="column checkbox">
              <mat-checkbox
                (change)="$event ? toggle(contractor) : null"
                (click)="$event.stopPropagation()"
                [checked]="contractor.selected"
              >
              </mat-checkbox>
            </div>
            <div class="column">
              <div class="image">
                <app-img-loading
                  [src]="contractor?.user?.profile_picture_url"
                  [imgWidth]="ICON_WIDTH"
                  [imgHeight]="ICON_HEIGHT"
                  class="contractor-logo"
                >
                </app-img-loading>
              </div>
              <div class="text-wrapper">
                <div class="line-one title">
                  {{ contractor.license.company_name }}
                </div>
                <div class="line-two">
                  <!--                Electrical-->
                </div>
              </div>
            </div>
            <div class="column">
              <div class="text-wrapper">
                <div class="line-one">
                  {{ contractor.license.street }}, {{ contractor.license.city }},
                  {{ contractor.license.state }}
                </div>
                <div class="line-two">{{ contractor.license.zip_code }}</div>
              </div>
            </div>
            <div class="column">
              <div *ngIf="contractor.user" class="text-wrapper">
                <div class="line-one">{{ contractor.user.phone | phone }}</div>
                <div class="line-two">{{ contractor.user.email }}</div>
              </div>
            </div>
            <div class="column license-type">
              <div class="text-wrapper">
                <div class="line-one" *ngIf="contractor.keywords">
                  <span class="contractor-keywords" [matTooltip]="getKeywords(contractor.keywords)">
                    {{ getKeywords(contractor.keywords) }}
                  </span>
                </div>
                <div class="line-two">
                  <!--                <span-->
                  <!--                  *ngFor="let cls of contractor.license.classifications"-->
                  <!--                  [matTooltip]="cls.classification.short_description"-->
                  <!--                  -->
                  <!--                  style="padding-right: 0.5rem"-->
                  <!--                >-->
                  <!--                  {{ cls.classification.key }}-->
                  <!--                </span>-->
                </div>
              </div>
            </div>

            <div></div>

            <div class="column-badges">
              <span
                [ngClass]="{ active: contractor.prevailing_wage == true }"
                class="icon-prevailing-wage"
                matTooltip="Prevailing Wage"
              ></span>
              <span
                [ngClass]="{ active: contractor.union_contractor == true }"
                class="icon-union-contractor"
                matTooltip="Union Contractor"
              ></span>
              <span
                [ngClass]="{ active: contractor.oshpd_certified == true }"
                class="icon-oshpd"
                matTooltip="Healthcare / OSHPD"
              ></span>
            </div>
            <div
              (click)="toggleFavorite(contractor)"
              [ngClass]="{ 'star-accent': contractor.is_favorite }"
              class="star btn-small column-favorite cursor-pointer"
            >
              <span *ngIf="!contractor.is_favorite" class="icon-star-u"></span>
              <span *ngIf="contractor.is_favorite" class="icon-star"></span>
            </div>

            <div class="message btn-small column-invite" [ngClass]="{ disabled: this.contractosSelected.length > 0 }">
              <span
                (click)="inviteProjects(contractor.id)"
                class="icon-send-invite cursor-pointer"
                matTooltip="Invite to project"
              ></span>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-scrollbar>
  </div>

  <div *ngIf="isEmptyData" class="content">
    <div class="notify-msg visibility-hidden default-cursor">No contractors</div>
  </div>
</div>
