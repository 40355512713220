<div class="wide-interaction-bar-wrapper">
  <app-messages-header></app-messages-header>
  <div class="wide-interaction-bar-content">
    <app-discussions-list *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_LIST"></app-discussions-list>
    <app-discussions-create
      *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_CREATE"
    ></app-discussions-create>
    <app-discussions-view *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_VIEW"></app-discussions-view>
    <app-discussion-thread-view
      *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW"
    ></app-discussion-thread-view>
  </div>
  <app-messages-footer *ngIf="(selectedView$ | async) !== MESSAGING_VIEWS.DISCUSSION_LIST"></app-messages-footer>
</div>
