import { Component } from '@angular/core';
import { AppState } from '../../../store/app-state';
import { settingsActions } from '../../../store/settings/settings.actions';
import { Store } from '@ngrx/store';
import { getIsLoading } from '../../../store/settings/settings.selectors';
import { NotificationsService } from '../../../services/notifications.service';
import { BudgetTagTemplatesComponent } from '../settings/templates/budget-tag-templates/budget-tag-templates.component';
import { BudgetTemplatesComponent } from '../settings/templates/budget-templates/budget-templates.component';
import { NgClass, NgIf } from '@angular/common';

enum TEMPLATE_TABS {
  ACCOUNT_TEMPLATE,
  PROJECT_TEMPLATES,
  BUDGET_TAG_TEMPLATES,
}
@Component({
  selector: 'app-spend-templates',
  templateUrl: './spend-templates.component.html',
  styleUrls: ['./spend-templates.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, BudgetTemplatesComponent, BudgetTagTemplatesComponent],
})
export class SpendTemplatesComponent {
  TEMPLATE_TABS = TEMPLATE_TABS;
  currentTab = TEMPLATE_TABS.PROJECT_TEMPLATES;
  isLoading = this.store.select(getIsLoading);

  selectTab(tab: TEMPLATE_TABS) {
    this.currentTab = tab;
  }

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
  ) {
    this.store.dispatch(settingsActions.loadBudgetTemplate());
    this.store.dispatch(settingsActions.loadBudgetTagTemplates());
    this.store.dispatch(settingsActions.loadGlTemplateViewInit());

    this.isLoading.subscribe((isLoading) => {
      if (isLoading) {
        this.notif.showLoading();
      } else {
        this.notif.close();
      }
    });
  }
}
