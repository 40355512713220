import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITask, TASK_KANBAN_COLUMN_TYPES } from '../../../store/tasks/tasks.interfaces';
import { Store } from '@ngrx/store';
import {
  getTasksByColumnType,
  isKanbanColumnFirstPageLoading,
  isKanbanColumnPaginationLoading,
} from '../../../store/tasks/tasks.selectors';
import { Observable, Subject, Subscription } from 'rxjs';
import { tasksActions } from '../../../store/tasks/tasks.actions';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { TasksService } from '../../../services/tasks.service';
import { TaskKanbanItemComponent } from '../task-small-view/task-kanban-item.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tasks-kanban-column',
  templateUrl: './tasks-kanban-column.component.html',
  styleUrls: ['./tasks-kanban-column.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressBar, NgScrollbar, NgFor, TaskKanbanItemComponent, AsyncPipe],
})
/**
 * Displays one column of the kanban board (based on `type` input parameter). It has infinite scrolling too.
 */
export class TasksKanbanColumnComponent implements OnInit, OnDestroy {
  @Input() type: TASK_KANBAN_COLUMN_TYPES;
  @ViewChild('scrollbar') scrollbar;
  TASK_KANBAN_COLUMN_TYPES = TASK_KANBAN_COLUMN_TYPES;
  getTasks$: Observable<ITask[]>;
  isLoading$: Observable<boolean>;
  isPaginationLoading$: Observable<boolean>;
  isDestroyed$ = new Subject();
  lastScrollSubscription: Subscription;
  lastScrollPosition: number;
  loadNextPage$ = new Subject<void>();

  constructor(
    private store: Store,
    private taskService: TasksService,
  ) {}

  ngOnInit(): void {
    // setup store selectors by view type
    this.isLoading$ = this.store.select(isKanbanColumnFirstPageLoading(this.type));
    this.isPaginationLoading$ = this.store.select(isKanbanColumnPaginationLoading(this.type));
    this.getTasks$ = this.store.select(getTasksByColumnType(this.type));

    this.handleInfiniteScroll();

    this.store.dispatch(
      tasksActions.loadNextPageFromKanban({ firstPage: true, columnType: this.type }),
    );

    // debounceTime here is important to not create multiple requests on the multiple scrolled events at a time
    this.loadNextPage$.pipe(debounceTime(500)).subscribe(() => {
      this.store.dispatch(tasksActions.loadNextPageFromKanban({ columnType: this.type }));
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  /**
   * After loading finished handle the infinite scrolling.
   * @private
   */
  private handleInfiniteScroll() {
    this.isLoading$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((loading) => !loading),
        take(1),
      )
      .subscribe(() => {
        this.handleScrollbarEvents();
      });
  }

  /**
   * This functions waits (with an interval) for the scrollbar to appear in the DOM.
   * After that it subscribes to its `scrolled` event to handle pagination.
   */
  handleScrollbarEvents() {
    // only one scroll subscription can exist at a time
    if (this.lastScrollSubscription) {
      this.lastScrollSubscription.unsubscribe();
    }
    const intervalId = setInterval(() => {
      if (!this.scrollbar) {
        return;
      }
      clearInterval(intervalId);
      this.lastScrollSubscription = this.scrollbar?.scrolled
        .pipe(takeUntil(this.isDestroyed$), debounceTime(10))
        .subscribe((event) => {
          const target = event.target;
          if (this.lastScrollPosition < target.scrollTop) {
            // if user scrolled downwards
            if (target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.8) {
              // if the user scrolled in the bottom 80%-100% of the scrollbar
              this.loadNextPage$.next(); // this is needed to apply debounceTime
            }
          }
          this.lastScrollPosition = target.scrollTop;
        });
    }, 50);
  }

  openTasksSidebar() {
    this.taskService.openCreateTask();
  }
}
