import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContractorsApiService } from '../../../services/contractors-api.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { CONTRACTOR_SELECTOR } from '../../constants/view.constants';
import * as moment from 'moment';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { CalendarComponent } from '../../inputs/calendar/calendar.component';
import { ContractorComponent } from '../../../pages/webapp/projects/view-project/contractor/contractor.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
  standalone: true,
  imports: [NgIf, CalendarComponent, NgScrollbar, NgFor, ContractorComponent],
})
export class MeetingsComponent implements OnInit {
  contractors = [];
  allContractors = [];
  isContractorLoading = true;
  todayCount = 0;
  isFirstOpen = true;

  @ViewChild('calendar') calendar: CalendarComponent;

  constructor(
    private barState: InteractionBarStateService,
    private projectApi: ProjectApiService,
    public user: CurrentUserService,
    private contractorApi: ContractorsApiService,
  ) {}

  @Input() set reload(value) {
    if (value === true) {
      this.isFirstOpen = false;
      this.init();
      if (this.calendar) {
        this.calendar.clean();
        this.calendar.refresh();
      }
    }
  }

  ngOnInit() {}

  init() {
    this.contractors = [];
    this.allContractors = [];
    this.isContractorLoading = true;
    if (this.user.isManager) {
      this.initManager();
    } else {
      this.initContractor();
    }
  }

  initManager() {
    this.contractorApi.getContractors(1, CONTRACTOR_SELECTOR.SCHEDULED).then(
      (data) => {
        this.contractors = [];
        this.allContractors = [];
        data.data.forEach((project) => {
          if (project.invited_contractors) {
            project.invited_contractors.forEach((contractor) => {
              if (contractor.schedule_visits.length > 0) {
                contractor.schedule_visits.forEach((visit) => {
                  const contractorCopy = { ...contractor };
                  contractorCopy.visit = visit.visit;
                  contractorCopy.project_id = project.project_id;
                  this.allContractors.push(contractorCopy);
                });
                this.allContractors.sort((a, b) => {
                  const dateA = moment(
                    a.visit.day + ', ' + a.visit.interval.split('-')[0],
                    'DD-MM-YYYY hh:mmA',
                  )
                    .toDate()
                    .getTime();
                  const dateB = moment(
                    b.visit.day + ', ' + b.visit.interval.split('-')[0],
                    'DD-MM-YYYY hh:mmA',
                  )
                    .toDate()
                    .getTime();
                  // console.log(new Date(dateA), new Date(dateB));
                  if (dateA < dateB) {
                    return -1;
                  } else if (dateA > dateB) {
                    return 1;
                  } else {
                    return 0;
                  }
                });

                // console.log(this.allContractors);
              }
            });
            // console.log(this.allContractors);
            this.contractors = this.allContractors;
            if (this.isFirstOpen) {
              this.todayCount = this.selectContractorsByDate(new Date()).length;
              this.barState.meetingCount.next(this.todayCount);
            }
          }
        });
        this.isContractorLoading = false;
        if (this.calendar) {
          this.calendar.onSelect(new Date());
        }
      },
      (err) => {
        this.isContractorLoading = false;
      },
    );
  }

  initContractor() {
    this.contractorApi.getVisits().then(
      (visits) => {
        this.projectApi.getProjects().then(
          (projects) => {
            this.contractors = [];
            this.allContractors = [];
            // console.log(projects, visits);

            let today: any = new Date();
            today = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000)
              .toISOString()
              .split('T')[0];
            visits.forEach((visit) => {
              projects.forEach((project) => {
                if (visit.project_id === project.id) {
                  project.license = {};
                  project.license.company_name = project.title;
                  project.schedule_visits = [visit];
                  project.bids = project.project_bids;
                  // console.log(project);
                  this.allContractors.push({ ...visit, ...project });
                }
              });
            });
            this.contractors = this.allContractors;
            // console.log(this.contractors);
            if (this.isFirstOpen) {
              this.todayCount = this.selectContractorsByDate(new Date()).length;
              this.barState.meetingCount.next(this.todayCount);
            }
            this.isContractorLoading = false;
            if (this.calendar) {
              this.calendar.onSelect(new Date());
            }
          },
          (err) => {
            this.isContractorLoading = false;
          },
        );
      },
      (err) => {
        this.isContractorLoading = false;
      },
    );
  }

  registerDateChange(date) {
    this.contractors = this.selectContractorsByDate(date);
  }

  private selectContractorsByDate(date) {
    // console.log(date, this.allContractors);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    const dateString = date.toISOString().split('T')[0];

    const contractors = [];

    this.allContractors.forEach((contractor) => {
      if (contractor.visit.day === dateString) {
        contractors.push(contractor);
      }
    });

    // console.log(contractors, date);

    return contractors;
  }
}
