<span
  [class]="iconClass.position + ' ' + classes.wrapper"
  [ngClass]="{
    'mt-6': !!label && verticalSpacing,
    'mt-2': !label && verticalSpacing,
    'mb-2': verticalSpacing,
    'p-float-label': !!label
  }"
  class="floating-input flex flex-col"
>
  <input
    #input="ngModel"
    #inputElement
    (blur)="onBlur()"
    (focus)="onFocus()"
    (ngModelChange)="onModelChange($event)"
    [(ngModel)]="model"
    [disabled]="isInputDisabled"
    [email]="isEmail"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [name]="name"
    [ngClass]="{
      'ng-invalid ng-dirty': !control?.valid && control?.touched,
      'negative-color': input.value < 0 && formatMoney
    }"
    [class]="classes.input"
    [ngModelOptions]="ngModelOptions"
    [pattern]="pattern"
    [placeholder]="placeholder"
    [readOnly]="readonly || (!autoComplete && innerReadOnly)"
    [required]="required"
    [type]="type"
    [value]="formattedModel"
    pInputText
    [appFormatInputMoney]="{ disable: !formatMoney }"
  />
  <i (click)="onIconClick()" *ngIf="iconClass.icon" [classList]="iconClass.icon"></i>

  <label *ngIf="!!label" [ngClass]="{ disabled: isInputDisabled }">{{ label }}</label>
  <small [ngClass]="{ hidden: control?.valid || control?.untouched }" class="p-error text-uppercase">
    <ng-container *ngIf="control">
      <!--      todo there might be a situation with multiple errors, this should be handled-->
      <ng-container *ngIf="control.hasError('required')"> Required </ng-container>
      <ng-container *ngIf="control.hasError('email')"> Invalid email </ng-container>
      <ng-container *ngIf="control.hasError('pattern')"> Invalid </ng-container>
      <ng-container *ngIf="control.hasError('notMatched')"> Does not match </ng-container>
      <ng-container *ngIf="control.hasError('minlength')"> Too short </ng-container>
      <ng-container *ngIf="control.hasError('maxlength')"> Too long </ng-container>
    </ng-container>
  </small>
</span>
