import { createAction, props } from '@ngrx/store';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
  IGlAccountLabel,
  IGlTemplate,
} from './settings.interface';

const cancel = createAction('[Settings] cancel');
const setIsLoading = createAction('[Settings] setIsLoading', props<{ isLoading: boolean }>());

/* ----------
  budget templates
---------- */

const loadBudgetTemplate = createAction('[Settings] Load budget templates');

const setBudgetTemplates = createAction(
  '[Settings] set budget templates',
  props<{ budgetTemplates: IBudgetTemplate[] }>(),
);

const addBudgetTemplate = createAction(
  '[Settings] add budget template',
  props<{ budgetTemplate: Partial<IBudgetTemplate> }>(),
);

const addBudgetTemplateItem = createAction(
  '[Settings] add budget template item',
  props<{ newItemName: string }>(),
);

const updateBudgetTemplateItem = createAction(
  '[Settings] update budget template item',
  props<{ newItem: IBudgetTemplateItem }>(),
);

const updateBudgetTemplate = createAction(
  '[Settings] update whole budget template',
  props<{ template: IBudgetTemplate }>(),
);

const setSelectedBudgetTemplate = createAction(
  '[Settings] set selected budget template',
  props<{ templateId: number }>(),
);

const setGLAccountLabelFromDropdown = createAction(
  '[Settings] [Budget templates] set GL Account Label From Dropdown',
  props<{ accountLabel: IGlAccountLabel; budgetTemplateItem: IBudgetTemplateItem }>(),
);

const unlinkBudgetTemplateItem = createAction(
  '[Settings] [Budget templates] unlink GL Account Label From Dropdown',
  props<{ budgetTemplateItem: IBudgetTemplateItem }>(),
);

const saveBudgetTemplates = createAction('[Settings] [Budget templates] save all budget templates');
const reloadBudgetTemplates = createAction('[Settings] [Budget templates] reload budget templates');
const deleteBudgetTemplate = createAction(
  '[Settings] [Budget templates] delete budget templates',
  props<{ templateId: number }>(),
);

/* ----------
  GL accounts templates
---------- */

const loadGlTemplateViewInit = createAction(
  '[Settings][budget template view init] Load GL templates',
);

const setGlTemplates = createAction(
  '[Settings] set GL account templates',
  props<{ glTemplates: IGlTemplate[] }>(),
);

const addGlTemplate = createAction(
  '[Settings] add GL account template',
  props<{ glTemplate: Partial<IGlTemplate> }>(),
);

const addGlTemplateItem = createAction(
  '[Settings] add GL account template item (locally)',
  props<{ newItemName: string }>(),
);

const updateGlTemplateItem = createAction(
  '[Settings] update GL account template item',
  props<{ newItem }>(),
);
const removeGlTemplateItem = createAction(
  '[Settings] remove GL account template item',
  props<{ itemId: number }>(),
);
const deleteGlTemplate = createAction(
  '[Settings] remove GL account',
  props<{ templateId: number }>(),
);

const updateGlTemplate = createAction(
  '[Settings] update whole GL account template',
  props<{ template: IGlTemplate }>(),
);

const setSelectedGlTemplate = createAction(
  '[Settings] set selected GL account template',
  props<{ templateId: number }>(),
);

const saveGlTemplate = createAction('[Settings] save all GL account templates');
const reloadGlAccounts = createAction('[Settings] reload GL accounts');

/* ----------
  budget tag templates
---------- */

const setBudgetTagTemplates = createAction(
  '[Settings] set budget tag templates',
  props<{ budgetTagTemplates: IBudgetTagTemplate[] }>(),
);

const addBudgetTagTemplate = createAction(
  '[Settings] add budget tag template',
  props<{ budgetTagTemplate: Partial<IBudgetTagTemplate> }>(),
);

const setSelectedBudgetTagTemplate = createAction(
  '[Settings] set selected budget tag template',
  props<{ templateId: number }>(),
);
const loadBudgetTagTemplates = createAction('[Settings] load budget tag templates');
const loadBudgetTagTemplatesFromCommitments = createAction(
  '[Settings] load budget tag templates from commitments',
  props<{ projectId: number }>(),
);

const saveBudgetTagTemplate = createAction('[Settings] save all budget tag templates');
const removeBudgetTagTemplateItem = createAction(
  '[Settings] remove budget tag template item',
  props<{ itemId: number }>(),
);
const updateBudgetTagTemplateItem = createAction(
  '[Settings] update budget tag template item',
  props<{ newItem }>(),
);

const addBudgetTagTemplateItem = createAction(
  '[Settings] add budget tag template item',
  props<{ newItemName: string }>(),
);

const reloadBudgetTagTemplates = createAction('[Settings] reload budget tag templates');
const deleteBudgetTagTemplate = createAction(
  '[Settings] delete budget tag templates',
  props<{ templateId: number }>(),
);

const updateBudgetTagTemplate = createAction(
  '[Settings] update whole budget tag template',
  props<{ template: IBudgetTagTemplate }>(),
);

const removeBudgetTemplateItem = createAction(
  '[Settings] [Budget templates] delete budget template item',
  props<{ itemId: number }>(),
);

const clearAll = createAction('[Settings] clear all');

export const settingsActions = {
  cancel,
  setIsLoading,
  // Project templates
  loadBudgetTemplate,
  setBudgetTemplates,
  addBudgetTemplate,
  addBudgetTemplateItem,
  updateBudgetTemplateItem,
  updateBudgetTemplate,
  setSelectedBudgetTemplate,
  setGLAccountLabelFromDropdown,
  unlinkBudgetTemplateItem,
  saveBudgetTemplates,
  reloadBudgetTemplates,
  deleteBudgetTemplate,
  removeBudgetTemplateItem,

  // GL accounts
  loadGlTemplateViewInit,
  setGlTemplates,
  addGlTemplate,
  addGlTemplateItem,
  setSelectedGlTemplate,
  saveGlTemplate,
  reloadGlAccounts,
  updateGlTemplateItem,
  removeGlTemplateItem,
  deleteGlTemplate,
  updateGlTemplate,

  // Budget Tags
  loadBudgetTagTemplates,
  loadBudgetTagTemplatesFromCommitments,
  setBudgetTagTemplates,
  setSelectedBudgetTagTemplate,
  addBudgetTagTemplate,
  updateBudgetTagTemplateItem,
  addBudgetTagTemplateItem,
  removeBudgetTagTemplateItem,
  saveBudgetTagTemplate,
  reloadBudgetTagTemplates,
  deleteBudgetTagTemplate,
  updateBudgetTagTemplate,
  // clear
  clearAll,
};
