@if (title) {
  <div class="title flex gap-2">
    @if (showBackButton) {
      <app-bordered-dropdown-icon
        (click)="onBack()"
        isClosed="false"
        [useAsBackButton]="true"
      ></app-bordered-dropdown-icon>
    }
    <span>
      {{ title }}
    </span>
  </div>
}

@if (subtitle) {
  <div class="subtitle">
    {{ subtitle }}
  </div>
}

@if (section) {
  <div [ngClass]="{ 'no-margin': !subtitle && !title }" class="subtitle">
    <ng-content select="[section]"></ng-content>
  </div>
}

<ng-content></ng-content>
