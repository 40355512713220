import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getGlTemplates } from '../../store/settings/settings.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-state';
import { IGlAccountLabel, IGlTemplate } from '../../store/settings/settings.interface';
import { IsValidUUIDPipe } from '../../pipes/framework/is-valid-uuid.pipe';
import { NgScrollbar } from 'ngx-scrollbar';
import { BackButtonBoxComponent } from '../back-button-box/back-button-box.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

export type AccountDropdownOptionSelected = { value: IGlAccountLabel };
@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss'],
  standalone: true,
  imports: [NgIf, BackButtonBoxComponent, NgScrollbar, NgFor, AsyncPipe, IsValidUUIDPipe],
})
export class AccountDropdownComponent implements OnInit {
  constructor(private store: Store<AppState>) {}
  @Input() hasTemplate = false;

  @Output() optionSelected: EventEmitter<AccountDropdownOptionSelected> =
    new EventEmitter<AccountDropdownOptionSelected>();

  @Output() unlinkTemplate: EventEmitter<void> = new EventEmitter();

  glTemplates$ = this.store.select(getGlTemplates);
  selectedGLAccount: IGlTemplate | null;

  ngOnInit(): void {}
  selectOption(value: IGlAccountLabel) {
    this.optionSelected.emit({ value });
  }

  selectTemplate(template: IGlTemplate) {
    this.selectedGLAccount = template;
  }

  unselectAccount() {
    this.selectedGLAccount = null;
  }

  unlinkAccount() {
    this.unlinkTemplate.emit();
  }
}
