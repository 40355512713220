<div class="graph-wrapper">
  <div class="stats">
    <app-cash-flow-stats></app-cash-flow-stats>
  </div>
  <div class="chart-container">
    <canvas
      baseChart
      type="bar"
      [data]="chartData"
      [options]="chartConfig"
      [ngClass]="{
        'animation-pulse': isLoading$ | async
      }"
    ></canvas>
  </div>
</div>
