import { EventEmitter, Injectable } from '@angular/core';
import { ProjectApiService } from './project-api.service';
import moment from 'moment';
import { spendActionTypes } from '../store/spend/spend.actions';
import { AppState } from '../store/app-state';
import { Store } from '@ngrx/store';
import { CurrentUserService } from './current-user.service';

/**
 * @deprecated - use the view project store instead. This service would behave silly.
 */

@Injectable({
  providedIn: 'root',
})
export class ProjectStateService {
  static updateContractorStats = new EventEmitter<void>();
  static goNextPage = new EventEmitter<boolean>();
  isEdit = false;
  isAddProject = false;
  id = null; // can be string or number, but it's mostly string :Laughing:
  manualInput = false;

  budget = null;
  spend = null;
  forecast = null;
  ready = false;
  isAddEstimate = false;
  estimateID = null;
  initialStartDate = null;

  constructor(
    private projectApi: ProjectApiService,
    private store: Store<AppState>,
    private userService: CurrentUserService,
  ) {}

  // tslint:disable-next-line:variable-name
  private _general;

  get general() {
    return this._general;
  }

  set general(generalData) {
    console.log('set general', generalData);
    this.store.dispatch(
      spendActionTypes.setProjectStartDate({
        projectStartDate: moment(generalData.start_date).format('YYYY-MM-DD'),
      }),
    );
    this._general = generalData;
  }

  createProject(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.projectApi.createProject(this.general).then(
        (data) => {
          console.log(data);
          this.id = data.id;
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  updateProject() {
    return new Promise<any>((res, rej) => {
      this.projectApi.updateProject(this.general).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  // todo: delete?
  fetchAndSetProject() {
    this.ready = false;
    return new Promise<any>((res, rej) => {
      this.projectApi.getProject(this.id).then(
        (data) => {
          if (Object.keys(data).length !== 0) {
            this.id = data.id;
            this._general = data;
            this._general.start_date = new Date(this._general.start_date).toISOString();
            this.budget = data.project_budget;
            this.spend = data.project_spent;
            this.forecast = data.project_forecast;
            this.ready = true;
            res(data);
          } else {
            rej('You do not have access to this project');
          }
        },
        (err) => {
          this.ready = true;

          rej(err);
        },
      );
    });
  }

  isReady(): Promise<any> {
    return new Promise<any>((res) => {
      if (this.ready) {
        res(this.ready);
      } else {
        setTimeout(() => {
          this.isReady().then((d) => res(d));
        }, 100);
      }
    });
  }

  getVisit(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.projectApi.getVisits(this.id).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  clearProject() {
    this.budget = null;
    this.forecast = null;
    this.spend = null;
    this.id = null;
    this._general = undefined;
  }

  clear() {
    this.isEdit = false;
    this.isAddProject = false;
    this.isEdit = false;
    this.manualInput = false;

    this.clearProject();
  }

  createVisit(hour: any, date) {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    const start = moment(hour.start, ['h:mm A']).utc().format('HH:mm');
    const end = moment(hour.end, ['h:mm A']).utc().format('HH:mm');
    const body = {
      project_id: this.id,
      intervals: [
        {
          start_date_time: moment(date).add(start, 'hours').toISOString(),
          end_date_time: moment(date).clone().add(end, 'hours').toISOString(),
        },
      ],
    };
    return new Promise<any>((res, rej) => {
      this.projectApi.createVisit(body).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  getScheduleVisits(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.projectApi.getScheduleVisits(this.id).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  createScheduleVisit(visitId, contractorID): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.projectApi.createScheduleVisit(this.id, contractorID, visitId).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  reviseScheduleVisit(visitID, body): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.projectApi.reviseScheduleVisit(visitID, body).then(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  getLineItemFiscalYear(startDate: string): number {
    const startDateMonth = moment(startDate).month() + 1;
    if (
      this.userService.data.fiscal_year_start > startDateMonth ||
      this.userService.data.fiscal_year_start === 1
    ) {
      return moment(startDate).year();
    }
    return moment(startDate).year() + 1;
  }
}
