<div class="task-users-dropdown-wrapper" [@fillIn]="'in'">
  <ng-scrollbar *ngIf="usersRelated.length" class="std-scrollbar min-h-[12.5rem]" appearance="standard">
    <ng-container *ngFor="let user of usersRelated">
      <span (click)="optionSelected({ action: 'select', user: user })" class="row p-1">
        <span class="text-shade_1 font-semibold text-sm cursor-pointer">
          {{ user.name }}
        </span>
        <span class="text-shade_2 font-normal text-sm cursor-pointer">
          {{ user.company_name }}
        </span>
      </span>
    </ng-container>
  </ng-scrollbar>
  <ng-container *ngIf="!usersRelated.length">
    <span class="text-shade_2 font-normal text-sm"> No users found </span>
  </ng-container>
</div>
