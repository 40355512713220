import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-general-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './general-header.component.html',
  styleUrl: './general-header.component.scss',
})
export class GeneralHeaderComponent {
  @Input() title: string;
  @Input() hideBorder = false;
}
