import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { documentActionTypes } from './documents.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import { PERMISSION_LITERALS, sortFuncDrive } from '../../framework/constants/documents.constants';
import { SharedWithUser } from '../../framework/interfaces/User.interface';

export interface IPermissions {
  [PERMISSION_LITERALS.CAN_DOWNLOAD]: number;
  [PERMISSION_LITERALS.CAN_COPY]: number;
  [PERMISSION_LITERALS.CAN_MOVE]: number;
  [PERMISSION_LITERALS.CAN_DUPLICATE]: number;
  [PERMISSION_LITERALS.CAN_RENAME]: number;
  [PERMISSION_LITERALS.CAN_SHARE]: number;
  [PERMISSION_LITERALS.CAN_EXTERNALLY_SHARE]: number;
  [PERMISSION_LITERALS.CAN_DELETE]: number;
  [PERMISSION_LITERALS.CAN_CANCEL_SHARE]: number;
  [PERMISSION_LITERALS.CAN_MODIFY_CONTENT]: number;
  [PERMISSION_LITERALS.CAN_EDIT]: number;
}

export interface DocumentItem {
  id: number;
  parent_id: number;
  owner: string;
  direct_link?: string | null;
  bundle_links?: Array<any>;
  shared_with: Array<SharedWithUser>;
  children: Array<DocumentItem>;
  files: Array<DocumentItem>;
  permissions?: IPermissions;
  name: string;
  updated_at?: string;
  created_at?: string;
  is_editable?: boolean;
  size?: string;
  project_id?: string;
  isLocalFolder?: boolean; // added by the frontend, it does not exist in reality (projects and shared files folder)
  type?: string;
  is_drive: 0 | 1;
  is_service_provider_allowed_to_view?: boolean;
  user_drive_folder_id?: number;
  service_provider_user_id?: number | null;
  shared_by: string;
  setFolderPath?: boolean; // added by the FE, checker for set folder path in drive
  files_count: number;
  folders_count: number;
  is_uploaded?: boolean;
}

export interface DocumentsState extends EntityState<DocumentItem> {
  docsLoaded: boolean;
  isProjectView: boolean;
  shared_by?: string;
  selectedFolderId?: number;
  selections: number[];
}

export const documentsAdapter: EntityAdapter<DocumentItem> = createEntityAdapter<DocumentItem>();

export const initialState = documentsAdapter.getInitialState({
  docsLoaded: false,
  isProjectView: undefined,
  shared_by: undefined,
  selectedFolderId: undefined,
  selections: [],
});

export const documentsReducer = createReducer(
  initialState,

  on(documentActionTypes.updateAll, (state, action) => {
    const newState = {
      ...state,
      docsLoaded: true,
      selections: [action.data.id],
      selectedFolderId: state.selectedFolderId ?? action.data.id, // todo: check this if folder does not appear
    };
    if (!action.data) {
      return documentsAdapter.setAll([], newState);
    }
    const actionData = DeepCopyService.deepCopy(action.data);

    if (!actionData?.files) {
      actionData.files = [];
    }

    actionData?.files?.sort(sortFuncDrive);
    actionData?.children?.sort(sortFuncDrive);
    actionData.setFolderPath = action.setFolderPath || false;
    if (action.data.shared_by) {
      newState.shared_by = action.data.shared_by;
    }
    return documentsAdapter.setAll([actionData], newState);
  }),

  on(documentActionTypes.backOneFolder, (state, action) => {
    const selections = DeepCopyService.deepCopy(state.selections);
    if (selections.length <= 1) {
      return state;
    }
    selections.pop();
    return {
      ...state,
      docsLoaded: true,
      selectedFolderId: selections[selections.length - 1],
      selections,
    };
  }),

  on(documentActionTypes.loadDriveFolder, (state, action) => {
    const newState = {
      ...DeepCopyService.deepCopy(state),
      selectedFolderId: action.folder.id,
      selections: [...state.selections, action.folder.id],
      docsLoaded: true,
    };

    const actionCopy = DeepCopyService.deepCopy(action);
    actionCopy.folder?.children?.sort(sortFuncDrive);
    actionCopy.folder?.files?.sort(sortFuncDrive);
    return documentsAdapter.setOne(actionCopy.folder, newState);
  }),
  on(documentActionTypes.updateDriveFolder, (state, action) => {
    const newState = {
      ...state,
      docsLoaded: true,
    };
    const actionCopy = DeepCopyService.deepCopy(action);
    const updatedEntities = [
      {
        id: actionCopy.folder.id,
        changes: {
          ...actionCopy.folder,
          children: actionCopy.folder.children.sort(sortFuncDrive),
          files: actionCopy.folder.files.sort(sortFuncDrive),
        },
      },
    ];
    // find and update parent folder of the currently accessed folder
    const parentId = state.selections[state.selections.length - 2];
    if (parentId) {
      const updatedChildren = state.entities[parentId].children.map((child) =>
        child.id === actionCopy.folder.id ? actionCopy.folder : child,
      );
      updatedChildren.sort(sortFuncDrive);
      updatedEntities.push({
        id: parentId,
        changes: {
          children: updatedChildren,
        },
      });
    }
    return documentsAdapter.updateMany(updatedEntities, newState);
  }),
  on(documentActionTypes.reload, documentActionTypes.reloadExternalDrive, (state, _) => {
    return {
      ...state,
      docsLoaded: false,
    };
  }),
  on(
    documentActionTypes.driveFolderClicked,
    documentActionTypes.driveExternalFolderClicked,
    (state, _) => {
      return {
        ...state,
        docsLoaded: false,
      };
    },
  ),
  on(documentActionTypes.reset, (state, action) => {
    return {
      ...state,
      docsLoaded: false,
    };
  }),
  on(documentActionTypes.clearAll, (state, action) => {
    const newState = documentsAdapter.removeAll(state);
    return {
      ...newState,
      ...initialState,
      docsLoaded: false,
    };
  }),
);

export const { selectAll } = documentsAdapter.getSelectors();
