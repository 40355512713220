import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IActivityProgressItem,
  itemTrackingFeatureKey,
  ItemTrackingState,
  selectAll,
  selectEntities,
} from './item-tracking.reducer';
import { calculateDifferenceBetweenDates } from '../activities/activities.constants';
import dayjs from 'dayjs';

export const activitiesFeatureSelector =
  createFeatureSelector<ItemTrackingState>(itemTrackingFeatureKey);

const getAllActivities = createSelector(activitiesFeatureSelector, selectAll);
const getAllActivityEntities = createSelector(activitiesFeatureSelector, selectEntities);
const allActivityProgressItems = createSelector(activitiesFeatureSelector, (state) => {
  return state.allProgressItems;
});

const allProgressItemsList = createSelector(allActivityProgressItems, (items) => {
  return items.map((item, index) => {
    const { percent: due_percentage, dayCount: due_days } = transformItemDateProgress({
      start_date: item.start_date,
      end_date: item.end_date,
      completed: item.work_percentage === 100,
    });

    const progressItem = {
      ...item,
      due_days: due_days,
      due_percentage: due_percentage,
      is_due: !!item?.is_due,
      end_date: dayjs(item.end_date).format('MM/DD/YYYY'),
      start_date: dayjs(item.start_date).format('MM/DD/YYYY'),
      total_files: item?.checklist?.items?.reduce((acc, item) => acc + item.files.length, 0) ?? 0,
      checklist: {
        ...item.checklist,
        items:
          item?.checklist?.items?.map((checkListItem, index) => {
            const { percent: due_percentage, dayCount: due_days } = transformItemDateProgress({
              start_date: item.start_date,
              end_date: checkListItem.end_date,
              completed: checkListItem.completed,
            });

            return {
              ...checkListItem,
              due_days: checkListItem.completed ? 0 : due_days,
              due_percentage: checkListItem.completed ? 100 : due_percentage,
              end_date: dayjs(checkListItem.end_date).format('MM/DD/YYYY'),
              note: checkListItem?.note ?? '',
              files: checkListItem?.files ?? [],
            };
          }) ?? [],
        completed: item.checklist.items.every((item) => item.completed),
      },
    };

    progressItem.is_due = progressItem.checklist.items.some((item) => item.due_days < 0);

    return progressItem;
  });
});

const getAllCheckList = createSelector(activitiesFeatureSelector, (state) => {
  let nonDeletedChecklists = [
    ...state.allChecklist.filter(
      (checklist) => !state.deleteChecklistIds.find((id) => id === checklist.id),
    ),
  ];
  nonDeletedChecklists = nonDeletedChecklists.map((checklist) => {
    checklist = {
      ...checklist,
      items: checklist.items
        .toSorted((a, b) => a?.order ?? 0 - b?.order ?? 0)
        .map((item, index) => ({ ...item, order: index })),
    };

    return checklist;
  });
  return nonDeletedChecklists;
});

const deletedChecklists = createSelector(activitiesFeatureSelector, (state) => {
  return state.deleteChecklistIds;
});

const isLoading = createSelector(activitiesFeatureSelector, (state) => state.isLoading);

const getTotals = createSelector(activitiesFeatureSelector, (state) => {
  const work_percentage =
    state.allProgressItems.reduce((acc, item: IActivityProgressItem) => {
      return acc + item.work_percentage;
    }, 0) / state?.allProgressItems?.length ?? 1;
  return {
    totalItems: state.allProgressItems.length,
    work_percentage: isNaN(work_percentage) ? 0 : Math.floor(work_percentage),
  };
});

const isAnyFilterActive = createSelector(activitiesFeatureSelector, (state) => {
  return Object.values(state.loadFilter).some((value) => value);
});

export const itemTrackingSelectors = {
  getAllActivities,
  getAllActivityEntities,
  getAllCheckList,
  deletedChecklists,
  allActivityProgressItems,
  allProgressItemsList,
  isLoading,
  isAnyFilterActive,
  getTotals,
};

const transformItemDateProgress = (activity: {
  start_date: string;
  end_date: string;
  completed?: boolean;
}) => {
  return calculateDifferenceBetweenDates(
    activity.start_date,
    activity.end_date,
    activity.completed,
  );
};
