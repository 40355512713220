import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TabAsSlide } from '../../../../../framework/interfaces/TabAsSlide';
import { NgScrollbar } from 'ngx-scrollbar';
import { CalendarComponent } from '../../../../../framework/inputs/calendar/calendar.component';
import { AddProjectBase } from '../AddProjectBase';
import { SelectionListComponent } from '../../../../../framework/selection-list/selection-list.component';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  imports: [CalendarComponent, NgScrollbar, SelectionListComponent],
})
export class ScheduleAddComponent extends AddProjectBase implements OnInit, TabAsSlide {
  calendarDate = null;
  calendarEvents = [];
  @ViewChild('schedule') schedule: CalendarComponent;
  @ViewChild('selectionScrollbar') selectionScrollbar: NgScrollbar;

  @Input('setActive') set checkIfActive(isActive) {
    if (isActive) {
      setTimeout(() => {
        console.log('upppppp');
        this.selectionScrollbar.update();
      }, 500);
      this.refreshCalendar();
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  isBack(): Promise<boolean> {
    return new Promise<boolean>((res) => res(false));
  }

  isValid(): Promise<boolean> {
    return new Promise<boolean>((res) => res(true));
  }

  registerNewDate(event: any) {
    this.calendarDate = event;
  }

  registerEvents(event: any) {
    if (event) {
      this.calendarEvents = event;
    }
  }

  refreshCalendar() {
    this.schedule.refresh();
  }
}
