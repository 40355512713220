<div class="projects-table-wrapper">
  <div class="grid-table">
    <div [ngStyle]="rowStyle" class="grid-row header-row">
      <ng-container *ngFor="let header of tableHeaders; let i = index">
        <ng-container [ngSwitch]="dataTypes?.[header.key]">
          <ng-container *ngSwitchDefault>
            <div
              matTooltipPosition="below"
              matTooltip="{{ header.tooltip }}"
              (click)="sortBy(header.key)"
              class="item header-{{ header.key }}"
            >
              <div class="inner-container">
                <span>
                  {{ header.name }}
                </span>
                <app-sort-arrow [hide]="lastSortedBy !== i" [isDesc]="!lastSortedAscending[i]"></app-sort-arrow>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-content select="[header-last-col]"></ng-content>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isLoading">
    <mat-progress-bar class="custom-loading-progress-bar" mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="tableData?.length === 0 && !isLoading" class="error-msg">No Data.</div>

  <ng-scrollbar
    #scrollbar
    [ngClass]="{ 'hide-totals': !totalsKeys?.length }"
    appearance="compact"
    class="std-scrollbar"
    (updated)="scrollbarUpdated()"
  >
    <!--    track="vertical"-->
    <div class="grid-table body" [ngClass]="[tableClass]">
      <ng-container *ngFor="let data of tableData; let last = last">
        <div *ngIf="!data?.template_row" [ngStyle]="rowStyle" class="grid-row project-row">
          <ng-container *ngFor="let key of dataKeys">
            <!--  SWITCH based on TYPE-->
            <!--  IF key is not a special one than render content based on types-->
            <ng-container [ngSwitch]="dataTypes?.[key]">
              <ng-container *ngSwitchCase="'money'">
                <div class="item" [ngClass]="{ negative: data?.[key] < 0 }">
                  <span
                    [matTooltipClass]="{ 'negative-tooltip': data?.[key] < 0 }"
                    [matTooltip]="data?.[key] | money: 'positiveComa'"
                  >
                    {{ data?.[key] | money: 'shortSimpleSpace' }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <div class="item">
                  {{ data?.[key] | datepipe: UTC_DATE }}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'date-MMM_DD_YYYY'">
                <div class="item">
                  {{ data?.[key] | datepipe: MMMDDYYYY }}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'empty_field'">
                <div class="item"></div>
              </ng-container>
              <ng-container *ngSwitchCase="'formatted_address'">
                <div class="item font-semibold">
                  <app-faded-text>
                    {{ data?.[key] }}
                  </app-faded-text>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'type_unit_value'">
                <div class="item font-bold">{{ data.type_unit_value }}</div>
              </ng-container>
              <ng-container *ngSwitchCase="'name_with_options'">
                <div class="item flex items-center">
                  <div class="item flex-1 text-color_shade_-1 font-bold">
                    <app-faded-text>
                      {{ data?.[key] }}
                    </app-faded-text>
                  </div>
                  <app-un-styled-options-list
                    #unStyledOptionsList
                    (selectedOption)="onOptionSelected($event, data)"
                    [listItems]="optionsList"
                  >
                    <ng-template #projectedContent>
                      <div class="options">
                        <span
                          (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                          class="cursor-pointer icon-options btn-small text-color_secondary px-5 m-auto"
                        ></span>
                      </div>
                    </ng-template>
                  </app-un-styled-options-list>
                </div>
              </ng-container>
              <ng-container
                [ngTemplateOutletContext]="data?.[key]?.context"
                [ngTemplateOutlet]="data?.[key]?.template"
                *ngSwitchCase="'template_1'"
              ></ng-container>
              <ng-container
                [ngTemplateOutletContext]="data?.[key]?.context"
                [ngTemplateOutlet]="data?.[key]?.template"
                *ngSwitchCase="'template_2'"
              ></ng-container>
              <ng-container *ngSwitchDefault>
                <!--   any type-->
                <div class="item">
                  {{ data?.[key] }}
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div *ngIf="!last || !totalsKeys?.length" [ngClass]="['grid border-bottom h-1 w-full', separatorClass]"></div>
      </ng-container>
    </div>
  </ng-scrollbar>
  <div *ngIf="totalsKeys?.length" class="grid-table totals">
    <div [ngStyle]="rowStyle" class="grid-row">
      <ng-container *ngFor="let key of totalsKeys" [ngSwitch]="key">
        <ng-container *ngSwitchDefault>
          <ng-container [ngSwitch]="isNumber(totalsData?.[key])">
            <ng-container *ngSwitchCase="true">
              <div class="item" [ngClass]="{ negative: totalsData?.[key] < 0 }">
                <span
                  [matTooltipClass]="{ 'negative-tooltip': totalsData?.[key] < 0 }"
                  [matTooltip]="totalsData?.[key] | money: 'positiveComa'"
                >
                  {{ totalsData?.[key] | money: 'shortSimpleSpace' }}
                </span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="item">
                {{ totalsData?.[key] }}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
