import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';

/**
 * @deprecated use the new SearchInputComponent which corresponds to the new design.
 * Displays an input bar with a search icon.
 * It does apply debouncing to the emitted search text.
 * Note that there is another search bar with a different design. This is more minimalistic.
 */
@Component({
  selector: 'app-search-bar-minimalist',
  templateUrl: './search-bar-minimalist.component.html',
  styleUrls: ['./search-bar-minimalist.component.scss'],
  standalone: true,
  imports: [MatFormField, MatInput],
})
export class SearchBarMinimalistComponent implements OnInit, OnDestroy {
  searchInput = '';
  @Input() placeholder = 'Search';
  @Input() tabIndex = -1;
  @Input() set clearValue(data) {
    this.searchInput = '';
  }
  @Output() searchText = new EventEmitter();
  inputChange = new Subject();
  constructor() {}

  ngOnInit(): void {
    this.inputChange.pipe(debounceTime(200)).subscribe((value) => {
      this.searchText.next(value);
    });
  }

  searchTextChanged(event) {
    this.inputChange.next(event.target.value);
    this.searchInput = event.target.value;
  }

  ngOnDestroy() {
    this.searchText.complete();
    this.inputChange.complete();
  }
}
