import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { dropdownAnimation } from '../../../../assets/styles/animations';
import { optionOverlayPositions, OptionsAction, OptionsActionList } from '../option-list.constants';

/**
 * A wrapper component to show any content in an overlay.
 */
@Component({
  selector: 'app-options-list-general',
  templateUrl: './options-list-general.component.html',
  styleUrls: ['./options-list-general.component.scss'],
  animations: [dropdownAnimation],
  standalone: true,
  imports: [CdkConnectedOverlay],
})
export class OptionsListGeneralComponent implements OnInit {
  @Input() trigger: CdkOverlayOrigin;
  @Input() cdkConnectedOverlayHasBackdrop = true;
  @Input() actions: OptionsActionList;
  @Input() show: boolean;
  @Input() overlayPositions: ConnectedPosition[] = optionOverlayPositions;
  @Input() withAnimation = true;
  @Output() showChange = new EventEmitter<boolean>();
  @Output() optionSelected = new EventEmitter<string>();
  showOptions: boolean;
  constructor() {}

  ngOnInit(): void {}

  toggleShow() {
    this.show = !this.show;
    this.showChange.emit(this.show);
  }

  onOptionSelected(item: OptionsAction) {
    this.optionSelected.emit(item.value);
  }
}
