import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ITask, ITaskCalendarItem } from '../../../store/tasks/tasks.interfaces';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { getCalendarTasksByDay } from '../../../store/tasks/tasks.selectors';
import moment from 'moment';
import { tasksApiDateFormat } from '../../../store/tasks/tasks.constants';
import { TasksService } from '../../../services/tasks.service';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ConnectedPosition, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgScrollbar } from 'ngx-scrollbar';
import { OptionsListGeneralComponent } from '../../overlays/options-list-general/options-list-general.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-tasks-calendar-item',
  templateUrl: './tasks-calendar-item.component.html',
  styleUrls: ['./tasks-calendar-item.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, CdkOverlayOrigin, OptionsListGeneralComponent, NgScrollbar],
})
export class TasksCalendarItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() day: ITaskCalendarItem;
  @ViewChild('content') contentContainer: ElementRef<HTMLElement>;
  @ViewChild('itemHeader') itemHeaderRef: ElementRef<HTMLElement>;
  @ViewChildren('taskItem') taskItemsRef: QueryList<ElementRef>;
  getTasks$: Observable<ITask[]>;
  allTasks: ITask[] = [];
  isDestroyed$ = new Subject();
  itemsToShow: ITask[] = [];
  itemsHidden: ITask[] = [];
  windowResized = new Subject();
  showMoreTasks = false;
  overlayPositions: ConnectedPosition[] = [
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top' },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom' },
  ];

  constructor(
    private store: Store,
    public taskService: TasksService,
    private elRef: ElementRef,
  ) {}

  /**
   * on every page resize recalculate how many tasks can fit in a calendar cell.
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowResized.next(true);
  }

  ngOnInit(): void {
    this.getTasks$ = this.store.select(
      getCalendarTasksByDay(moment(this.day.dateFormatted, tasksApiDateFormat)),
    );

    this.windowResized.pipe(debounceTime(500)).subscribe(() => {
      this.afterRenderSpliceHiddenTasks();
    });

    this.loadTasks();
  }

  private loadTasks() {
    this.getTasks$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((tasks) => tasks.length > 0),
      )
      .subscribe((tasks) => {
        this.allTasks = tasks;
        this.spliceTasks(2);
        this.afterRenderSpliceHiddenTasks();
      });
  }

  ngAfterViewInit(): void {}

  /**
   * waits for the first task item to appear in the DOM if there are tasks for this day
   * @private
   */
  private afterRenderSpliceHiddenTasks() {
    if (this.allTasks.length > 0) {
      const intervalId = setInterval(() => {
        if (this.taskItemsRef?.first?.nativeElement) {
          clearInterval(intervalId);
          this.calculateHiddenTasks();
        }
      }, 25);
    }
  }

  /**
   * Dynamically calculates how many tasks can be shown for a day in calendar.
   * It gets the height of the calendar day's header and the first task's height and
   * calculates how many of them can fit in a cell based on the cell's height.
   * @private
   */
  private calculateHiddenTasks() {
    const hostHeight = this.elRef.nativeElement.clientHeight;
    const headerHeight = this.itemHeaderRef.nativeElement.clientHeight;
    const oneTaskHeight = this.taskItemsRef.first.nativeElement.clientHeight;
    const itemsToShowCount = Math.floor((hostHeight - headerHeight) / oneTaskHeight) - 1;
    this.spliceTasks(itemsToShowCount);
  }

  /**
   * Cuts the allTasks array in two arrays. The first is shown always, the second is hidden.
   * @param itemsToShowCount
   * @private
   */
  private spliceTasks(itemsToShowCount: number) {
    this.itemsToShow = this.allTasks.slice(0, itemsToShowCount);
    this.itemsHidden = this.allTasks.slice(itemsToShowCount, this.allTasks.length);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.windowResized.complete();
  }
}
