import { createAction, props } from '@ngrx/store';

import {
  Commitment,
  ContractSummaryResponse,
  CostBackendInvoiceExtended,
  DirectCostsSummaryResponse,
  ICommitmentBudgetLineItem,
  ISidebarChangeOrder,
  ISidebarCommitmentItem,
  ISidebarContract,
  ISidebarCost,
  ISidebarDirectCost,
  ISidebarInvoice,
} from './commitments.types';

const cancel = createAction('[commitments] cancel');

const loadAllCommitments = createAction('[commitments] load all', props<{ projectId: number }>());
const allCommitmentsLoaded = createAction(
  '[commitments] loaded all',
  props<{ commitments: any }>(), // todo type
);
const loadAllCommitmentsSummary = createAction(
  '[commitments] load all summary',
  props<{ projectId: number }>(),
);
const allCommitmentsSummaryLoaded = createAction(
  '[commitments] loaded all summary',
  props<{ commitmentsSummary: any }>(), // todo type
);
const loadContractSummary = createAction('[commitments] load contract summary');
const contractSummaryLoaded = createAction('[commitments] loaded contract summary');
const loadDirectCostsSummary = createAction(
  '[commitments] load direct costs summary',
  props<{ projectId: number }>(),
);
const directCostsSummaryLoaded = createAction(
  '[commitments] loaded direct costs summary',
  props<{ directCostsSummary: DirectCostsSummaryResponse }>(),
);

const loadCommitmentById = createAction('[commitments] load by id', props<{ id: number }>());
const loadSidebarCommitmentById = createAction(
  '[commitments] load sidebar commitment by id',
  props<{ id: number; projectId?: number }>(),
);
const commitmentByIdLoaded = createAction(
  '[commitments] commitment loaded by id',
  props<{ commitment: Commitment }>(),
);

const loadContractById = createAction('[commitments] load by id', props<{ id: number }>());
const contractByIdLoaded = createAction(
  '[commitments] selected contract loaded by id',
  props<{ contract: ContractSummaryResponse }>(),
);
const selectedContractClosed = createAction('[commitments] selected contract closed');

const incrementPage = createAction('[commitments] increment page');
const decrementPage = createAction('[commitments] decrement page');

const updateSelectedContract = createAction(
  '[commitments] update selected contract',
  props<{ contract: Partial<ISidebarContract> }>(),
);

const updateSelectedChangeOrder = createAction(
  '[commitments] update selected change order',
  props<{ change_order: Partial<ISidebarChangeOrder> }>(),
);

const updateSelectedInvoice = createAction(
  '[commitments] update selected invoice',
  props<{ invoice: Partial<ISidebarInvoice> }>(),
);

const updateSelectedDirectCost = createAction(
  '[commitments] update selected direct cost',
  props<{ directCost: Partial<ISidebarDirectCost> }>(),
);

const updateContractSpendEntry = createAction(
  '[commitments] update contract spend entry',
  props<{ commitmentItem: ISidebarCommitmentItem; index: number }>(),
);

const updateContractSpendEntryCost = createAction(
  '[commitments] update contract spend entry cost',
  props<{ cost?: Partial<ISidebarCost>; spendEntryIndex: number; costIndex: number }>(),
);

const updateDirectCostSpendEntryDescription = createAction(
  '[commitments] update direct cost spend entry cost description',
  props<{ cost?: Partial<ISidebarCost>; spendEntryIndex: number; costIndex: number }>(),
);

const updateChangeOrderSpendEntry = createAction(
  '[commitments] update change order spend entry',
  props<{ commitmentItem: ISidebarCommitmentItem; index: number }>(),
);
const updateChangeOrderSpendEntryCost = createAction(
  '[commitments] update change order spend entry cost',
  props<{
    cost?: Partial<ISidebarCost>;
    spendEntryIndex: number;
    costIndex: number;
  }>(),
);

const selectChangeOrderSpendEntryCost = createAction(
  '[commitments] select change order spend entry cost',
  props<{
    cost?: Partial<ISidebarCost>;
    spendEntryIndex: number;
    costIndex: number;
  }>(),
);

const updateInvoiceCostValue = createAction(
  '[commitments] update invoice cost',
  props<{
    cost: CostBackendInvoiceExtended;
    costIndex: number;
  }>(),
);

const selectInvoiceCost = createAction(
  '[commitments] select invoice cost',
  props<{
    cost: CostBackendInvoiceExtended;
    costIndex: number;
  }>(),
);

const updateDirectCostSpendEntry = createAction(
  '[commitments] update direct cost spend entry',
  props<{ commitmentItem: ISidebarCommitmentItem; index: number }>(),
);

const addContractSpendEntry = createAction('[commitments] add contract spend entry');
const addChangeOrderSpendEntry = createAction('[commitments] add change order spend entry');
const addDirectCostSpendEntry = createAction('[commitments] add direct cost spend entry');
const loadCommitmentItems = createAction(
  '[commitments] load commitment items',
  props<{ projectId: number }>(),
);

const commitmentItemsLoaded = createAction(
  '[commitments] commitment items loaded',
  props<{ items: ICommitmentBudgetLineItem[] }>(),
);

const addCostToContractCommitmentItem = createAction(
  '[commitments] add cost to contract commitment item',
  props<{ spendEntryIndex: number; newCostIndex: number }>(),
);
const addCostToChangeOrderCommitmentItem = createAction(
  '[commitments] add cost to change order commitment item',
  props<{ spendEntryIndex: number; newCostIndex: number }>(),
);

const addCostToInvoice = createAction('[commitments] add cost to invoice');

const addCostToDirectCostCommitmentItem = createAction(
  '[commitments] add cost to direct cost commitment item',
  props<{ spendEntryIndex: number; newCostIndex: number }>(),
);

const removeCommitment = createAction(
  '[commitments] remove commitment by id',
  props<{ id: number; projectId: number }>(),
);
const commitmentRemoved = createAction(
  '[commitments] commitment by id removed',
  props<{ id: number; projectId: number }>(),
);

const removeContractSpendEntry = createAction(
  '[commitments] remove contract spend entry',
  props<{ index: number }>(),
);

const removeDirectCostSpendEntry = createAction(
  '[commitments] remove direct cost spend entry',
  props<{ index: number }>(),
);
const removeContractSpendEntryCost = createAction(
  '[commitments] remove contract spend entry cost',
  props<{ spendEntryIndex: number; costIndex: number }>(),
);

const removeDirectCostEntryDescription = createAction(
  '[commitments] remove direct cost spend entry cost description',
  props<{ spendEntryIndex: number; costIndex: number }>(),
);

const removeChangeOrderSpendEntry = createAction(
  '[commitments] remove change order spend entry',
  props<{ index: number }>(),
);

const removeChangeOrderSpendEntryCost = createAction(
  '[commitments] remove change order spend entry cost',
  props<{ spendEntryIndex: number; costIndex: number }>(),
);

const removeInvoiceCost = createAction(
  '[commitments] remove invoice cost',
  props<{ costIndex: number }>(),
);

const saveContract = createAction('[commitments] save contract');
const modifyContract = createAction('[commitments] modify contract');

const saveChangeOrder = createAction('[commitments] save change order');
const modifyChangeOrder = createAction('[commitments] modify change order');

const saveInvoice = createAction('[commitments] save invoice');
const modifyInvoice = createAction('[commitments] modify invoice');

const saveDirectCost = createAction('[commitments] save direct cost');
const modifyDirectCost = createAction('[commitments] modify direct cost');

const contractSuccessfullySaved = createAction(
  '[commitments] contract successfully saved',
  props<{ commitment: Commitment }>(),
);

const changeOrderSuccessfullySaved = createAction(
  '[commitments] change order successfully saved',
  props<{ commitment: Commitment }>(),
);

const directCostSuccessfullySaved = createAction(
  '[commitments] direct cost successfully saved',
  props<{ commitment: Commitment }>(),
);

const invoiceSuccessfullySaved = createAction(
  '[commitments] invoice successfully saved',
  props<{ commitment: Commitment }>(),
);
const sidebarCommitmentLoaded = createAction(
  '[commitments] loaded sidebar commitment',
  props<{ sidebarCommitment: any }>(), // todo type
);

const clearSidebar = createAction('[commitments] clear sidebar');

const setCommitmentProjectData = createAction(
  '[commitments] set commitment project data',
  props<{ projectData: { id: number; start_date: string } }>(),
);

const resetContractEntryDates = createAction('[commitments] reset contract entry dates');
const loadSidebarSelectedContractSummary = createAction(
  '[commitments] load sidebar selected contract summary',
  props<{ contractId: number }>(),
);

const sidebarSelectedContractSummaryLoaded = createAction(
  '[commitments] sidebar selected contract summary loaded',
  props<{ contractDetails: ContractSummaryResponse }>(),
);

const contractFilesChanged = createAction(
  '[commitments] sidebar files changed for contract',
  props<{ files: File[]; removeFileIds: number[] }>(),
);
const changeOrderFilesChanged = createAction(
  '[commitments] sidebar files changed for change order',
  props<{ files: File[]; removeFileIds: number[] }>(),
);

const invoiceFilesChanged = createAction(
  '[commitments] sidebar files changed for invoice',
  props<{ files: File[]; removeFileIds: number[] }>(),
);

const directCostFilesChanged = createAction(
  '[commitments] sidebar files changed for direct cost',
  props<{ files: File[]; removeFileIds: number[] }>(),
);

const sidebarCommitmentLoading = createAction(
  '[commitments] sidebar commitment loading',
  props<{ isLoading: boolean }>(),
);

const setIsSelectedCommitmentViewSummaryLoading = createAction(
  '[commitments] commitments contract view summary  loading',
  props<{ isLoading: boolean }>(),
);

const projectChanged = createAction(
  '[commitments] project refreshed',
  props<{ projectId: number }>(),
);

const clearCommitments = createAction('[commitments] commitments clear general');

export const commitmentsActions = {
  cancel,
  loadAllCommitments,
  loadCommitmentItems,
  allCommitmentsLoaded,
  loadAllCommitmentsSummary,
  allCommitmentsSummaryLoaded,
  loadContractSummary,
  contractSummaryLoaded,
  loadDirectCostsSummary,
  directCostsSummaryLoaded,
  loadCommitmentById,
  commitmentByIdLoaded,
  loadContractById,
  contractByIdLoaded,
  selectedContractClosed,
  incrementPage,
  decrementPage,
  addContractSpendEntry,
  addChangeOrderSpendEntry,
  addDirectCostSpendEntry,
  updateSelectedContract,
  updateSelectedChangeOrder,
  updateSelectedInvoice,
  updateSelectedDirectCost,
  updateContractSpendEntry,
  updateContractSpendEntryCost,
  updateChangeOrderSpendEntry,
  updateInvoiceCostValue,
  selectInvoiceCost,
  updateDirectCostSpendEntry,
  updateChangeOrderSpendEntryCost,
  selectChangeOrderSpendEntryCost,
  commitmentItemsLoaded,
  addCostToContractCommitmentItem,
  addCostToChangeOrderCommitmentItem,
  addCostToDirectCostCommitmentItem,
  removeCommitment,
  commitmentRemoved,
  removeContractSpendEntry,
  removeDirectCostSpendEntry,
  removeContractSpendEntryCost,
  removeChangeOrderSpendEntry,
  removeChangeOrderSpendEntryCost,
  removeInvoiceCost,
  saveContract,
  modifyContract,
  contractSuccessfullySaved,
  changeOrderSuccessfullySaved,
  directCostSuccessfullySaved,
  saveChangeOrder,
  modifyChangeOrder,
  saveInvoice,
  modifyInvoice,
  invoiceSuccessfullySaved,
  loadSidebarCommitment: loadSidebarCommitmentById,
  sidebarCommitmentLoaded,
  clearSidebar,
  setCommitmentProjectData,
  resetContractEntryDates,
  loadSidebarSelectedContractSummary,
  sidebarSelectedContractSummaryLoaded,
  contractFilesChanged,
  changeOrderFilesChanged,
  sidebarCommitmentLoading,
  saveDirectCost,
  modifyDirectCost,
  updateDirectCostSpendEntryDescription,
  removeDirectCostEntryDescription,
  invoiceFilesChanged,
  directCostFilesChanged,
  addCostToInvoice,
  setIsSelectedCommitmentViewSummaryLoading,
  clearCommitments,
  projectChanged,
};
