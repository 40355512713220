import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { getSpendLineItemSummary } from '../../store/spend/spend.selectors';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { spendActionTypes } from '../../store/spend/spend.actions';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelContent,
} from '@angular/material/expansion';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { ArrowButtonBoxComponent } from '../dropdown-button-box/arrow-button-box.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-line-item-spend-summary',
  templateUrl: './line-item-spend-summary.component.html',
  styleUrls: ['./line-item-spend-summary.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgScrollbar,
    NgClass,
    NgFor,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    ArrowButtonBoxComponent,
    MatExpansionPanelContent,
    AsyncPipe,
    MoneyPipe,
  ],
})
export class LineItemSpendSummaryComponent implements OnDestroy {
  spendLineItemSummary$ = this.store.select(getSpendLineItemSummary);
  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.store.dispatch(spendActionTypes.clearSpendLineItemSummary());
  }

  /**
   * Toggle expansion panel
   * First a close/open has to be called to set the state of the panel
   * Then a toggle has to be called to trigger the animation
   * @param panel - panel to be toggled
   */
  onExpansionPanelClick(panel: MatExpansionPanel) {
    panel.expanded ? panel.close() : panel.open();
    panel.toggle();
  }
}
