import { createReducer, on } from '@ngrx/store';
import { activitiesActions } from './activities.actions';
import {
  Activity,
  ACTIVITY_VIEW,
  ActivityCalendarDateWindow,
  ActivityFilters,
} from './activities.constants';

export type ActivitiesStore = {
  notStarted: Activity[];
  inProgress: Activity[];
  completed: Activity[];
  activityLogs: Activity[];
  calendarActivities: Activity[];
  filters: ActivityFilters;
  selectedView: ACTIVITY_VIEW;
  isKanbanLoading: {
    notStarted: boolean;
    inProgress: boolean;
    completed: boolean;
  };
  isLoadingGeneral: boolean;
  nextKanbanPage: {
    notStarted: number;
    inProgress: number;
    completed: number;
  };
  logPage: number;
  nextCalendarPage: number;
  // represents the date window [start_date, end_date] for which we have activities loaded into calendarActivities
  calendarDateWindow: ActivityCalendarDateWindow;
  // this indicates if there was any activity in the system
  // used to show "no match based on filters" or "no activity at all" message
  isThereAnyActivity: boolean;
};

const initialState: ActivitiesStore = {
  notStarted: [],
  inProgress: [],
  completed: [],
  activityLogs: [],
  calendarActivities: [],
  nextKanbanPage: {
    notStarted: 1,
    inProgress: 1,
    completed: 1,
  },
  nextCalendarPage: 1,
  isKanbanLoading: {
    notStarted: true,
    inProgress: true,
    completed: true,
  },
  filters: {
    'types[]': [],
    search: '',
    unassigned: 0,
    'assigneeIds[]': [],
    startDateGTE: null, // these represent only the first month which is going to be loaded
    startDateLT: null, // these represent only the first month which is going to be loaded
  },
  calendarDateWindow: {
    startDateGTE: null,
    startDateLT: null,
  },
  isLoadingGeneral: false,
  logPage: 1,
  selectedView: ACTIVITY_VIEW.ACTIVITIES,
  isThereAnyActivity: false,
};

export const activitiesReducer = createReducer(
  initialState,
  on(activitiesActions.successfullyLoadedActivities, (state, action) => {
    // todo: this reducer is too complicated, we should simplify it
    let notStarted = state.notStarted;
    let inProgress = state.inProgress;
    let completed = state.completed;

    if (action.isFirstPage) {
      notStarted = action.notStarted ?? [];
      inProgress = action.inProgress ?? [];
      completed = action.completed ?? [];
    }

    if (!action.isFirstPage && action.notStarted) {
      notStarted = [...state.notStarted, ...action.notStarted];
    }
    if (!action.isFirstPage && action.inProgress) {
      inProgress = [...state.inProgress, ...action.inProgress];
    }
    if (!action.isFirstPage && action.completed) {
      completed = [...state.completed, ...action.completed];
    }

    const isThereAnyActivity =
      notStarted.length > 0 || inProgress.length > 0 || completed.length > 0;
    return {
      ...state,
      notStarted,
      inProgress,
      completed,
      nextKanbanPage: {
        notStarted:
          action.notStarted?.length === 0
            ? null
            : action.nextPage?.notStarted ?? state.nextKanbanPage.notStarted,
        inProgress:
          action.inProgress?.length === 0
            ? null
            : action.nextPage?.inProgress ?? state.nextKanbanPage.inProgress,
        completed:
          action.completed?.length === 0
            ? null
            : action.nextPage?.completed ?? state.nextKanbanPage.completed,
      },
      isKanbanLoading: {
        // consider separating these
        notStarted: false,
        inProgress: false,
        completed: false,
      },
      isThereAnyActivity: state.isThereAnyActivity || isThereAnyActivity,
    };
  }),
  on(activitiesActions.successfullyLoadedActivityLog, (state, action) => {
    const activityLogs = action.resetLogs ? action.logs : [...state.activityLogs, ...action.logs];
    const isThereAnyActivity = activityLogs.length > 0;
    return {
      ...state,
      activityLogs,
      isThereAnyActivity: state.isThereAnyActivity || isThereAnyActivity,
      logPage: action.resetLogs ? 1 : action.logPage + 1,
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.successfullyLoadedActivitiesCalendar, (state, action) => {
    let calendarActivities: Activity[];
    if (action.isFirstLoad) {
      calendarActivities = [...action.activities];
    } else if (action.direction < 0) {
      calendarActivities = [...action.activities, ...state.calendarActivities];
    } else {
      calendarActivities = [...state.calendarActivities, ...action.activities];
    }
    calendarActivities.sort((a, b) => (a.start_date < b.start_date ? -1 : 1));
    // filter out duplicates...
    calendarActivities = calendarActivities.filter(
      (item, pos) => calendarActivities.findIndex((innerItem) => innerItem.id === item.id) === pos,
    );

    let dateWindow: ActivityCalendarDateWindow = state.calendarDateWindow;
    if (action.isFirstLoad) {
      dateWindow = {
        startDateGTE: action.startDateGTE,
        startDateLT: action.startDateLT,
      };
    }

    return {
      ...state,
      calendarActivities,
      isLoadingGeneral: false,
      calendarDateWindow: dateWindow,
    };
  }),
  on(activitiesActions.refreshCalendarDateWindow, (state, action) => {
    let dateWindow: ActivityCalendarDateWindow = state.calendarDateWindow;

    if (action.direction < 0) {
      dateWindow = {
        ...state.calendarDateWindow,
        startDateGTE: action.startDateGTE,
      };
    } else if (action.direction > 0) {
      dateWindow = {
        ...state.calendarDateWindow,
        startDateLT: action.startDateLT,
      };
    }
    return {
      ...state,
      calendarDateWindow: dateWindow,
    };
  }),
  on(activitiesActions.calendarActivitiesDestroyed, (state, activities) => {
    return {
      ...state,
      calendarActivities: [],
    };
  }),
  on(activitiesActions.selectedViewChanged, (state, action) => {
    return {
      ...state,
      selectedView: action.view,
    };
  }),
  on(activitiesActions.saveFilters, (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        'types[]': 'types[]' in action ? action['types[]'] : state.filters['types[]'],
        search: 'search' in action ? action.search : state.filters.search,
        'assigneeIds[]':
          'assigneeIds[]' in action ? action['assigneeIds[]'] : state.filters['assigneeIds[]'],
        'projectIds[]':
          'projectIds[]' in action ? action['projectIds[]'] : state.filters['projectIds[]'],
        unassigned: 'unassigned' in action ? action.unassigned : state.filters.unassigned,
        startDateLT: 'startDateLT' in action ? action.startDateLT : state.filters.startDateLT,
        startDateGTE: 'startDateGTE' in action ? action.startDateGTE : state.filters.startDateGTE,
        // endDateLT: 'endDateLT' in action ? action.endDateLT : state.filters.endDateLT,
        // endDateGTE: 'endDateGTE' in action ? action.endDateGTE : state.filters.endDateGTE,
      },
    };
  }),
  on(activitiesActions.successfullyAssignedTeammate, (state, action) => {
    const mapFn = (activity: Activity) => {
      // update the activity with the new assignee
      if (activity.id === action.activity.id) {
        return action.activity;
      }
      return activity;
    };
    const notStarted = state.notStarted.map(mapFn);
    const inProgress = state.inProgress.map(mapFn);
    const completed = state.completed.map(mapFn);
    const activityLogs = state.activityLogs.map(mapFn);
    const calendarActivities = state.calendarActivities.map(mapFn);

    return {
      ...state,
      notStarted,
      inProgress,
      completed,
      activityLogs,
      calendarActivities,
    };
  }),
  on(activitiesActions.setLoading, (state, action) => {
    return {
      ...state,
      isKanbanLoading: {
        notStarted: 'notStarted' in action ? action.notStarted : state.isKanbanLoading.notStarted,
        inProgress: 'inProgress' in action ? action.inProgress : state.isKanbanLoading.inProgress,
        completed: 'completed' in action ? action.completed : state.isKanbanLoading.completed,
      },
    };
  }),
  on(activitiesActions.setIsLoadingGeneral, (state, action) => {
    return {
      ...state,
      isLoadingGeneral: action.isLoading,
    };
  }),
  on(activitiesActions.resetActivityLogs, (state) => {
    return {
      ...state,
      activityLogs: [],
      logPage: 1,
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.cancel, (state) => {
    return {
      ...state,
      isKanbanLoading: {
        notStarted: false,
        inProgress: false,
        completed: false,
      },
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.clearActivities, (state) => {
    return {
      ...initialState,
    };
  }),
);
