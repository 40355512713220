import { environment } from '../../environments/environment';

export const REST_BASE = environment.apiUrl;
export const REST_AUTH_BASE = 'auth/';
export const REST_USER_BASE = 'users/';

export const REST_REGISTER = REST_BASE + REST_AUTH_BASE + 'register';
export const REST_LOGIN = REST_BASE + REST_AUTH_BASE + 'login';
export const REST_LOGOUT = REST_BASE + REST_AUTH_BASE + 'logout';
export const REST_TOKEN_RFRESH = REST_BASE + REST_AUTH_BASE + 'refresh';
export const REST_TOKEN_VALIDATE = REST_BASE + REST_AUTH_BASE + 'validate';
export const REST_PASS_RESET = REST_BASE + REST_AUTH_BASE + 'reset-password';
export const REST_PASS_CONFIRM = REST_BASE + REST_AUTH_BASE + 'confirm-password';

export const REST_USER = REST_BASE + REST_USER_BASE;
export const REST_CURRENT_USER = REST_BASE + REST_AUTH_BASE + 'user';

export const REST_PROJECTS = REST_BASE + 'projects';
export const REST_PROJECT_STATUS = REST_PROJECTS + '/status';
export const REST_PROJECT_STATUS_TYPES = REST_BASE + 'project-statuses';
export const REST_PROJECT_STATS = REST_BASE + 'stats';
export const REST_PROPERTY = REST_BASE + 'properties';
export const REST_PROPERTY_TYPES = REST_BASE + 'property-types';
export const REST_PROJECT_BUDGET = REST_BASE + 'budgets';
export const REST_PROJECT_INVITE = REST_BASE + 'invites';
export const REST_PROJECT_BID = REST_BASE + 'bids';
export const REST_VISITS = REST_BASE + 'visits';
export const REST_SCHEDULE_VISITS = REST_BASE + 'schedule-visits';
export const REST_PROJECT_ITEMS = REST_BASE + 'items';
export const REST_PROJECT_TAGS = REST_BASE + 'project-tags';

export const REST_CONTRACTOR = REST_BASE + 'contractors';
export const REST_CONTRACTOR_FAVORITE = REST_CONTRACTOR + '/favorite';
export const REST_CONTRACTOR_AVAILABLE_MANAGERS = REST_CONTRACTOR + '/accessible-managers';
export const TMP_SERVICE_PROVIDER = 'temporary-service-providers';

export const REST_DESCRIPTIONS = REST_BASE + 'project-descriptions';

export const REST_MESSAGES = REST_BASE + 'messages';
export const REST_MESSAGES_SENT = REST_BASE + 'messages-sent';
export const REST_MESSAGES_RECEIVED = REST_BASE + 'messages-received';
export const REST_MESSAGING = REST_BASE + 'messaging';
export const REST_MESSAGING_USERS = REST_MESSAGING + '/users';
export const REST_MESSAGING_GROUPS = REST_MESSAGING + '/groups';
export const REST_MESSAGING_MESSAGES = REST_MESSAGING + '/messages';

export const REST_NOTIFICATIONS = REST_BASE + 'notifications';
export const REST_NOTIFICATIONS_UNSEEN = REST_BASE + 'notifications-unseen';
export const REST_NOTIFICATIONS_SEEN = REST_BASE + 'notifications-seen';
export const REST_NOTIFICATIONS_SETTINGS = REST_BASE + 'notification-settings';
export const REST_UNSEEN_STATS = REST_NOTIFICATIONS + '/stats';

export const REST_REPORTS = REST_BASE + 'reports';

export const REST_USER_DRIVE = REST_BASE + 'user-drive/';

export const REST_DRIVE_FOLDERS = REST_BASE + 'user-drive-folders';
export const REST_DRIVE_FILES = REST_BASE + 'user-drive-files';
export const REST_DRIVE_FILES_VIEW = REST_BASE + 'user-drive-files/view';
export const REST_DRIVE_FILES_DOWNLOAD = REST_BASE + 'user-drive-files/download';
export const REST_PROJECTS_DRIVE = REST_BASE + 'user-drive-folders/projects/';
export const REST_DRIVE_ZIP = REST_DRIVE_FILES + '/zipped';
export const REST_DRIVE_FOLDER_SHARE = REST_DRIVE_FOLDERS + '/share';
export const REST_DRIVE_FILE_SHARE = REST_DRIVE_FILES + '/share';
export const REST_DRIVE_EXTERNAL_FILE_SHARE = REST_DRIVE_FILES + '/external-share';
export const REST_DRIVE_VIEW_EXTERNAL_FILE = REST_BASE + 'external-files/view';
export const REST_DRIVE_DOWNLOAD_EXTERNAL_FILE = REST_BASE + 'external-files';
export const REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_DOWNLOAD = REST_BASE + 'external-files/download';
export const REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_VIEW = REST_BASE + 'external-files/view';
export const REST_DRIVE_VIEW_EXTERNAL_DRIVE = REST_BASE + 'external-drive';
export const REST_DRIVE_CANCEL_SHARE_FOLDER = REST_DRIVE_FOLDERS + '/share/revoke';
export const REST_DRIVE_CANCEL_SHARE_FILE = REST_DRIVE_FILES + '/share/revoke';
export const REST_DRIVE_DIRECT_SHARE = REST_USER_DRIVE + 'direct-share';
export const REST_DRIVE_DIRECT_SHARE_BUNDLE = REST_DRIVE_DIRECT_SHARE + '/bundle';
export const REST_DRIVE_DIRECT_SHARE_FILES = REST_DRIVE_FILES + '/direct-share';
export const REST_DRIVE_DIRECT_SHARE_FOLDERS = REST_DRIVE_FOLDERS + '/direct-share';
export const REST_DRIVE_FOLDERS_MAKE_COPY = REST_USER_DRIVE + 'make-copy';
export const REST_DRIVE_FILES_PRESIGNED = REST_DRIVE_FILES + '/presigned';
export const REST_TASKS = REST_BASE + 'tasks';

export const REST_PUSHER_AUTH = REST_BASE + 'pusher/auth';

export const REST_COMMITMENTS = 'commitments';
export const REST_BUDGET_ADJUSTMENTS = REST_BASE + 'budget-adjustments';
export const FORECAST_MODIFICATIONS = REST_BASE + 'forecast-modifications';

export const REST_CASHFLOW = 'cash-flow';
export const REST_CASHFLOW_BASE = REST_BASE + REST_CASHFLOW;
export const REST_ALL_CASHFLOW = REST_PROJECTS + '/all/' + REST_CASHFLOW;

export const REST_LINE_ITEMS_BY_PROJECT = REST_PROJECT_ITEMS + '/projects';
export const REST_CALC_DISTRIBUTION = REST_BASE + 'distributions/calculate';

export const REST_ACCESSIBLE_USERS = REST_BASE + 'accessible-users';

export const REST_GENERAL_TASKS = REST_BASE + 'general-tasks';
export const REST_TASK_CHECKLIST = REST_BASE + 'checklist-items';

export const PROJECTS_SIMPLE = REST_PROJECTS + '/simple';

export const BUDGET_TEMPLATES = REST_BASE + 'budget/templates';
export const GL_TEMPLATES = REST_BASE + 'budget/glaccounts';
export const GL_TEMPLATES_LABEL = REST_BASE + 'budget/glaccount-labels';
export const TAG_TEMPLATES = REST_BASE + 'budget/tag-templates';
export const TAG_TEMPLATES_ITEM = REST_BASE + 'budget/tags';

export const REST_TEAM_MANAGEMENT = REST_BASE + 'teams';

export const REST_BILLING_PLANS = REST_BASE + 'billing-plans';

export const REST_PROGRESS_ITEMS = REST_BASE + 'progress-items';
export const REST_PROGRESS_ITEMS_CHECKLISTS = REST_BASE + 'progress-item-checklists';
export const REST_ACTIVITIES = REST_BASE + 'activities';
