export enum OPTIONS {
  RESCHEDULE = 'Reschedule',
  SHARE = 'Share',
  EXTERNAL_SHARE = 'Share link',
  CANCEL_SHARING = 'Remove shared',
  DUPLICATE = 'Duplicate',
  MOVE = 'Move',
  COPY = 'Copy & Paste',
  MESSAGE = 'Message',
  INFO = 'Info',
  SCHEDULE = 'Schedule',
  DISMISS = 'Dismiss',
  ACCEPT_BID = 'Accept Bid',
  DOWNLOAD = 'Download',
  DOWNLOAD_BID = 'Download Bid',
  OPEN = 'Open',
  DELETE = 'Delete',
  RENAME = 'Rename',
  EDIT = 'Edit',
  CANCEL = 'Cancel',
  VIEW = 'View',
  VIEW_CONTRACT = 'View Contract',
  REFUSE = 'Dismiss',
  RESTORE = 'Restore',
  REVOKE = 'Revoke',
  POST_TO_BID = 'Post To Bid',
  NEW_FOLDER = 'New Folder',
  UPLOAD_FILE = 'Upload File',
  NEW_SPREADSHEET = 'New Spreadsheet',
  NEW_WORD = 'New Document',
  TASK_PENDING = 'Pending',
  TASK_IN_PROGRESS = 'In Progress',
  TASK_COMPLETE = 'Complete',
  LEAVE_TEAM = 'Leave Team',
  REASSIGN = 'Reassign',
}

export const MANAGER_PROJECT_OPTIONS = {
  planned: [OPTIONS.EDIT, OPTIONS.DELETE, OPTIONS.POST_TO_BID],
  bidding: [OPTIONS.EDIT, OPTIONS.CANCEL],
  awarded: [OPTIONS.EDIT],
  in_progress: [OPTIONS.EDIT, OPTIONS.DELETE, OPTIONS.CANCEL],
  canceled: [OPTIONS.RESTORE, OPTIONS.DELETE],
  deleted: [OPTIONS.RESTORE],
  default: [OPTIONS.EDIT, OPTIONS.DELETE, OPTIONS.CANCEL],
};

export const CONTRACTOR_PROJECT_OPTIONS = {
  bidding: [OPTIONS.MESSAGE],
  awarded: [OPTIONS.MESSAGE],
  in_progress: [OPTIONS.MESSAGE, OPTIONS.DELETE],
  canceled: [OPTIONS.DELETE],
  deleted: [OPTIONS.RESTORE],
  default: [OPTIONS.MESSAGE, OPTIONS.DELETE],
  invited: [OPTIONS.MESSAGE, OPTIONS.DELETE],
};
