<app-bottom-notification class="drive-bottom-notif"></app-bottom-notification>
<div
  [ngClass]="{ 'is-project-view': isProjectView, 'is-drive-view': !isProjectView }"
  class="webapp-page-container drive-container"
>
  <div *ngIf="!isProjectView" class="text-wrapper">
    <div class="pointer-cursor title-page" (click)="goBack(-1)">Skillhop Storage Drive</div>
    <div class="flex items-center gap-2">
      <div *ngIf="isPublicLinkAccessed && (sharedBy$ | async)" class="shared-by font-weight-bold">
        <span class="color-primary">Share View By</span>
        <span class="color-1">{{ sharedBy$ | async }}</span>
      </div>
    </div>
  </div>

  <ng-scrollbar
    #scroll2
    class="std-scrollbar horizontal-scrollbar"
    appUploadDrop
    appearance="compact"
    track="horizontal"
    (fileDropped)="onFilesDropped($event)"
    [supportFolderUpload]="true"
  >
    <div class="documents">
      <div class="header" *ngIf="selectedView.value === DRIVE_VIEWS.DEFAULT">
        <div class="icon checkbox">
          <p-checkbox
            name="selectAllDocumentCheckBox"
            id="selectAllDocumentCheckBox"
            #selectAllDocumentCheckBox
            (click)="handleFilesSelection(selectAllDocumentCheckBox.checked())"
            [binary]="true"
            [(ngModel)]="allDocsCheckbox"
            [ngModelOptions]="{ standalone: true }"
          >
          </p-checkbox>
        </div>

        <app-floating-input
          #floatingInputComponent
          ngDefaultControl
          [disabled]="!currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]"
          (keyup.enter)="addFolder()"
          (iconClick)="addFolder()"
          (focusin)="clearPlaceholder()"
          (focusout)="addPlaceholder()"
          [iconClass]="{
            position: 'p-input-icon-right',
            icon: 'icon-add-block-square cursor-pointer text-color_secondary hover:brightness-110 top-[13px] text-[20px]'
          }"
          [formControl]="folderName"
          name="folderName"
          [placeholder]="folderNamePlaceholder"
        ></app-floating-input>

        <app-styled-options-list
          #allDriveOptionsList
          [listItems]="driveOptionsList"
          [disabledActionList]="
            driveOptionsList | uncommonPermissions: selectedFiles : isPublicLinkAccessed : currentFolder
          "
          [optionOverlayPositions]="driveHeaderOptionOverlayPositions"
          (selectedOption)="registerMultipleOptions($event)"
        >
          <ng-template #projectedContent>
            <div
              (click)="allDriveOptionsList.optionsListGeneralComponent.toggleShow()"
              class="options cursor-pointer font-semibold text-sm text-color_secondary flex gap-2.5 px-5"
            >
              <span class="select-none">Options</span>
              <span
                [ngClass]="{ 'rotate-180': allDriveOptionsList?.optionsListGeneralComponent?.show }"
                class="icon-rounded-triangle text-xs flex items-center justify-center"
              >
              </span>
            </div>
          </ng-template>
        </app-styled-options-list>

        <div class="separator-line"></div>

        <app-upload-window
          #uploadWindowComponent
          [disabled]="!currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]"
          (chosenFiles)="registerFileUpload($event)"
          [allowMultipleFiles]="true"
          [hideUploadIcon]="true"
        >
          <div
            pTooltip="Upload File or Document"
            tooltipPosition="bottom"
            [ngClass]="{
              'disabled color-cancel': !currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]
            }"
            (click)="uploadWindowComponent.openWindow()"
            class="cursor-pointer justify-center text-color_secondary btn icon icon-folded-file-upload"
          ></div>
        </app-upload-window>

        <div
          class="icon btn icon-folded-spreadsheet pointer-cursor"
          pTooltip="Create Spreadsheet"
          tooltipPosition="bottom"
          [ngClass]="{
            'disabled color-cancel': !currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]
          }"
          (click)="setDriveView(DRIVE_VIEWS.EXCEL)"
        ></div>

        <div
          class="icon btn icon-folded-word-doc pointer-cursor"
          pTooltip="Create Text Document"
          tooltipPosition="bottom"
          [ngClass]="{
            'disabled color-cancel': !currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]
          }"
          (click)="setDriveView(DRIVE_VIEWS.WORD)"
        ></div>

        <div class="separator-line"></div>

        <div *ngIf="selectedFolder | async" class="path">
          <span *ngIf="!isProjectView" (click)="goBack(-1)" class="pointer-cursor folder-name sh-drive-root">
            SH Drive
          </span>
          <span
            *ngFor="let path of driveService.folderPath; let i = index"
            (click)="goBack(i)"
            class="pointer-cursor folder-name"
          >
            <span *ngIf="i > 0 || !isProjectView" class="icon-rounded-triangle folder-arrow"></span>
            <app-faded-text displayGrid="true" class="folder-title">
              {{ path.name }}
            </app-faded-text>
          </span>
        </div>

        <!--        todo: add when backend is ready with modified grid-template-columns-->
        <!--        <div class="simple-button">-->
        <!--          <span>Archive</span>-->
        <!--          <span class="icon-folder-x"></span>-->
        <!--        </div>-->
      </div>

      <div class="content" *ngIf="selectedView.value === DRIVE_VIEWS.DEFAULT">
        <div class="table-header">
          <div class="column">Folder / File name</div>
          <div class="column"></div>
          <div class="column">Type</div>
          <div *ngIf="isPublicLinkAccessed" class="column">Last Modified</div>
          <div class="column">Content</div>
          <div class="column">Size</div>
          <div *ngIf="!isPublicLinkAccessed" class="column">Shared</div>
          <div *ngIf="!isPublicLinkAccessed" class="column">Owner</div>
          <!--        empty options column-->
        </div>

        <ng-scrollbar
          #scroll2
          class="std-scrollbar vertical-scrollbar rows-wrapper upload-drop-area"
          appUploadDrop
          appearance="compact"
          track="vertical"
          (fileDropped)="onFilesDropped($event)"
          [supportFolderUpload]="true"
        >
          <div *ngIf="selectedFolder | async as folder" class="project-segment" (contextmenu)="$event.preventDefault()">
            <!--            TODO: row code may be extracted to componeent + getArray needs to be sepparated because of ordering-->

            <!--            <div (click)="goBack()" *ngIf="!folder.is_drive" class="row">-->
            <!--              <div class="column checkbox">-->
            <!--              </div>-->
            <!--              ...-->
            <!--            </div>-->

            <div *ngFor="let item of getArray(folder.children, folder.files); let count = count; let index = index">
              <div class="row" (contextmenu)="selectOneDocument(documentsCheckboxes, item, $event)">
                <div class="column checkbox">
                  <p-checkbox
                    #documentsCheckboxes
                    (onChange)="selectFile(item)"
                    [value]="item.id"
                    [(ngModel)]="_selectedFilesId"
                    [inputId]="item.id | number"
                  ></p-checkbox>
                </div>

                @if (currentFolder.is_drive && item.permissions | permissionToText) {
                  <div
                    class="column no-border transparent-background pointer-cursor flex-col"
                    (click)="openDocument(item)"
                  >
                    <input
                      disabled
                      class="no-border transparent-background pointer-cursor file-name-input"
                      (focusin)="fileFocusHandler('focusin', item)"
                      (focusout)="fileFocusHandler('focusout', item)"
                      (change)="fileFocusHandler('change', item)"
                      [ngClass]="{
                        'folder-title': driveService.isFolder(item),
                        selected: documentsCheckboxes.checked
                      }"
                      [value]="driveService.isFolder(item) ? item.name : item.name.replace(regexExtension, '')"
                      [name]="'docInput_' + item.id"
                      #fileNameInput
                    />

                    <app-faded-text class="font-normal text-sm text-shade_1 italic pr-0.5 w-full">
                      {{ item.permissions | permissionToText }}</app-faded-text
                    >
                  </div>
                } @else {
                  <div
                    class="doc-input column transition-none transparent-background pointer-cursor"
                    (click)="openDocument(item)"
                  >
                    <input
                      disabled
                      [name]="'docInput_' + item.id"
                      class="no-border transparent-background file-name-input"
                      (focusin)="fileFocusHandler('focusin', item)"
                      (focusout)="fileFocusHandler('focusout', item)"
                      (change)="fileFocusHandler('change', item)"
                      [ngClass]="{
                        'folder-title': driveService.isFolder(item),
                        selected: documentsCheckboxes.checked
                      }"
                      [value]="driveService.isFolder(item) ? item.name : item.name.replace(regexExtension, '')"
                      #fileNameInput
                    />

                    <span (click)="$event.stopPropagation()" class="icon-approved text-color_secondary"></span>
                  </div>
                }

                <app-styled-options-list
                  #unStyledOptionsList
                  (selectedOption)="registerOption($event, item)"
                  [listItems]="item | overlayOptions: OVERLAY_TYPES.DRIVE : isPublicLinkAccessed : currentFolder"
                  [optionOverlayPositions]="optionOverlayPositions"
                >
                  <ng-template #projectedContent>
                    <div class="options">
                      <span
                        *ngIf="!isPublicLinkAccessed"
                        class="icon-options-inverted text-[22px] text-color_secondary cursor-pointer pl-1"
                        (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                      >
                      </span>
                    </div>
                  </ng-template>
                </app-styled-options-list>

                <div class="separator-line"></div>

                <div class="column">
                  {{ getType(item) }}
                </div>
                <div *ngIf="isPublicLinkAccessed" class="column">
                  <ng-container *ngIf="!item.isLocalFolder">
                    {{ item.updated_at | datepipe: UTC_DATE }}
                  </ng-container>
                </div>
                <div class="column">
                  <div *ngIf="driveService.isFolder(item)">
                    {{ item.files_count + item.folders_count }}
                    Files
                  </div>
                </div>
                <div [ngClass]="{ invisible: driveService.isFolder(item) }" class="column">
                  {{ item.size | bytes }}
                </div>
                <div *ngIf="!isPublicLinkAccessed" class="column shared-col">
                  <span *ngIf="item?.shared_with?.length || item.bundle_links?.length || item?.direct_link"> Yes </span>
                  <span *ngIf="item?.shared_with?.length + item.bundle_links?.length > 1">
                    ({{ (item?.shared_with?.length ?? 0) + (item?.bundle_links?.length ?? 0) }})
                  </span>
                  <span
                    class="empty"
                    *ngIf="!item?.shared_with?.length && !item.bundle_links?.length && !item?.direct_link"
                  >
                    No
                  </span>
                </div>
                <div *ngIf="!isPublicLinkAccessed" class="column owner-col">
                  <app-faded-text [displayGrid]="true" [ngClass]="{ empty: item.owner === 'Me' }" *ngIf="item.owner">{{
                    item.owner
                  }}</app-faded-text>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="!currentDocuments.length && !currentFolder?.files_count && !currentFolder?.folders_count"
            class="no-data pt-3"
          >
            No files available
          </div>

          <app-drive-empty-area (registerEmptyAreaOption)="registerEmptyAreaOption($event)"></app-drive-empty-area>
        </ng-scrollbar>
      </div>

      <app-document
        *ngIf="selectedView.value === DRIVE_VIEWS.WORD"
        class="document-component"
        #doc
        [wordFile]="documentFileData"
      ></app-document>

      <app-sheet
        *ngIf="selectedView.value === DRIVE_VIEWS.EXCEL"
        class="document-sheet"
        #sheet
        [excelFile]="documentFileData"
      ></app-sheet>

      <div *ngIf="selectedView.value !== DRIVE_VIEWS.DEFAULT" class="button-container">
        <div class="discard-button" (click)="discardChanges()">Discard</div>
        <div class="file-name-input-container">
          <form [formGroup]="filenameForm">
            <div class="form border-top file-name-input">
              <mat-form-field class="input">
                <input matInput placeholder="Input File Name" formControlName="fileName" />
              </mat-form-field>
            </div>
            <div class="save-button" (click)="saveDocument()">Save</div>
          </form>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
