<div #content class="calendar-item-content">
  <div [ngClass]="{ 'text-shade_3': !day.isSelectedMonth }" #itemHeader class="day-header">
    <span></span>
    <span class="text-uppercase text-size_s text-color_secondary font-bold">
      <ng-container *ngIf="day.isFirstRow">{{ day.dayName }}</ng-container>
    </span>
    <span class="text-size_m">{{ day.dayNumber }}</span>
  </div>
  <ng-container *ngFor="let task of itemsToShow; let i = index">
    <div
      #taskItem
      (click)="taskService.openDetails(task.id)"
      [ngClass]="{
        'task-daily': task.type === 'daily' && !task.is_spectating,
        'task-custom': task.type === 'custom' && !task.is_spectating,
        'task-spectating': task.is_spectating
      }"
      class="task-row"
    >
      <!--      <app-faded-text></app-faded-text>-->
      {{ task.title }}
    </div>
  </ng-container>
  <ng-container *ngIf="itemsHidden.length > 0">
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="show-hidden-tasks" (click)="showMoreTasks = true">
      {{ itemsHidden.length }} more tasks
    </div>
    <app-options-list-general
      [trigger]="trigger"
      [(show)]="showMoreTasks"
      [overlayPositions]="overlayPositions"
      [withAnimation]="false"
    >
      <div
        class="options-overlay-white more-tasks-overlay-container"
        [ngClass]="{ 'few-tasks': itemsHidden?.length <= 4 }"
      >
        <ng-scrollbar class="std-scrollbar" track="vertical">
          <div
            [ngClass]="{
              'task-daily': task.type === 'daily' && !task.is_spectating,
              'task-custom': task.type === 'custom' && !task.is_spectating,
              'task-spectating': task.is_spectating
            }"
            class="hidden-task"
            *ngFor="let task of itemsHidden"
            (click)="taskService.openDetails(task.id); showMoreTasks = false"
          >
            {{ task.title }}
          </div>
        </ng-scrollbar>
      </div>
    </app-options-list-general>
  </ng-container>
</div>
