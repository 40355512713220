import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IGlTemplate,
} from '../../../../../store/settings/settings.interface';
import {
  getBudgetTagTemplates,
  getBudgetTemplates,
  getGlTemplates,
  getSelectedBudgetTagTemplate,
  getSelectedBudgetTemplate,
  getSelectedGlTemplate,
} from '../../../../../store/settings/settings.selectors';
import { Store } from '@ngrx/store';
import { settingsActions } from '../../../../../store/settings/settings.actions';
import { NotificationsService } from '../../../../../services/notifications.service';
import { SelectorInputComponent } from './selector-input/selector-input.component';
import { NgFor, NgClass, AsyncPipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';

export type TemplateType = 'gl_accounts' | 'budget' | 'budget-tag';

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    FormsModule,
    NgScrollbar,
    NgFor,
    NgClass,
    SelectorInputComponent,
    AsyncPipe,
  ],
})
export class TemplateSelectorComponent implements OnInit {
  private _templateType: TemplateType;
  @ViewChild('editInput') editInputElement: HTMLInputElement;
  @Output() unselectTemplate = new EventEmitter();
  @Input() set type(templateType: TemplateType) {
    this._templateType = templateType;
    if (templateType === 'budget') {
      this.selectedTemplate = this.store.select(getSelectedBudgetTemplate);
      this.templates$ = this.store.select(getBudgetTemplates);
    }
    if (templateType === 'gl_accounts') {
      this.selectedTemplate = this.store.select(getSelectedGlTemplate);
      this.templates$ = this.store.select(getGlTemplates);
    }

    if (templateType === 'budget-tag') {
      this.selectedTemplate = this.store.select(getSelectedBudgetTagTemplate);
      this.templates$ = this.store.select(getBudgetTagTemplates);
    }
  }
  get templateType() {
    return this._templateType;
  }
  templateName = '';
  selectedTemplate: Observable<IBudgetTemplate | IGlTemplate | IBudgetTagTemplate>;
  templates$: Observable<IBudgetTemplate[] | IBudgetTagTemplate[] | IGlTemplate[]>;

  constructor(
    private notif: NotificationsService,
    private store: Store,
  ) {}

  ngOnInit(): void {}

  createTemplate() {
    if (!this.templateName.replace(/\s/g, '').length) {
      this.notif.showError('Please add template name!');
      return;
    }
    if (this.templateType === 'budget-tag') {
      this.store.dispatch(
        settingsActions.addBudgetTagTemplate({
          budgetTagTemplate: { name: this.templateName, tags: [] },
        }),
      );
    }

    if (this.templateType === 'budget') {
      this.store.dispatch(
        settingsActions.addBudgetTemplate({
          budgetTemplate: { name: this.templateName, template_items: [] },
        }),
      );
    }
    if (this.templateType === 'gl_accounts') {
      this.store.dispatch(
        settingsActions.addGlTemplate({
          glTemplate: { name: this.templateName, labels: [] },
        }),
      );
    }

    this.templateName = '';
  }

  selectTemplate(id: number) {
    if (this.templateType === 'budget') {
      this.store.dispatch(settingsActions.setSelectedBudgetTemplate({ templateId: id }));
    }
    if (this.templateType === 'gl_accounts') {
      this.store.dispatch(settingsActions.setSelectedGlTemplate({ templateId: id }));
    }
    if (this.templateType === 'budget-tag') {
      this.store.dispatch(settingsActions.setSelectedBudgetTagTemplate({ templateId: id }));
    }
  }
}
