import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root',
})
export class DeepCopyService {
  constructor() {}

  // TODO [plori 2023-08-3 11:17]: change to   static deepCopy<T>(original: T): T {
  // which means it returns the same type as the parameter
  static deepCopy(original) {
    return cloneDeep(original);
  }

  static deepCopyLegacy(original: any) {
    if (original === undefined || original === null) {
      return original;
    }
    if (typeof original !== 'object') {
      return original;
    }
    if (Array.isArray(original)) {
      const newArr = [];
      original.forEach((elem) => {
        newArr.push(this.deepCopy(elem));
      });
      return newArr;
    }

    const newObj = {};
    // tslint:disable-next-line:forin
    for (const key of Object.keys(original)) {
      newObj[key] = this.deepCopy(original[key]);
    }
    return newObj;
  }
}
