import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_TEAM_MANAGEMENT } from '../restApi/RestRoutes';
import { NotificationsService } from './notifications.service';
import { tap } from 'rxjs/operators';
import { teamManagementActions } from '../store/team-management/team-management.actions';
import { AppState } from '../store/app-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ITeamMemberExtended,
  TeamMemberSimple,
} from '../store/team-management/team-management.interfaces';
import { TEAM_VIEWS } from '../framework/constants/team.constants';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class TeamManagementService {
  constructor(
    private rest: RestRequestService,
    private notif: NotificationsService,
    private store: Store<AppState>,
    private userService: CurrentUserService,
  ) {}

  getAllTeams(view: TEAM_VIEWS = TEAM_VIEWS.MY_TEAMS) {
    return this.rest.getWithObservable(REST_TEAM_MANAGEMENT, {}, { view });
  }

  getTeamMembers(projectIds: number[] = null): Observable<TeamMemberSimple[]> {
    const params = projectIds ? { 'projectIds[]': projectIds } : {};
    return this.rest
      .getWithObservable(`${REST_TEAM_MANAGEMENT}/users`, {}, params)
      .pipe(tap((data) => console.log('get team members', data)));
  }

  getTeamMember(id: number): Observable<ITeamMemberExtended> {
    return this.rest.getWithObservable(`${REST_TEAM_MANAGEMENT}/${id}`);
  }

  // todo: body type
  saveAddMember(body) {
    return this.rest.postWithObservable(REST_TEAM_MANAGEMENT, body);
  }

  // todo: body type
  modifyMember(body) {
    return this.rest.putWithObservable(`${REST_TEAM_MANAGEMENT}/${body.id}`, body);
  }

  getAllTeamRoles(id: number) {
    return this.rest.getWithObservable(`${REST_TEAM_MANAGEMENT}/roles/${id}`);
  }
  getPropertyProjects() {
    return this.rest.getWithObservable(`${REST_TEAM_MANAGEMENT}/property-projects`);
  }

  deleteTeamMember(id: number, isCurrentUser = false) {
    const message = isCurrentUser
      ? 'Are you sure you want to leave the team?'
      : 'Are you sure you want to delete member?';
    const leaveMessage = isCurrentUser
      ? 'You have left the team successfully.'
      : 'Member deleted successfully.';
    this.notif.showPopup(message).then((res) => {
      if (res) {
        this.notif.showLoading();
        this.rest.delWithObservable(`${REST_TEAM_MANAGEMENT}/${id}`).subscribe({
          next: () => {
            this.notif.showSuccess(leaveMessage);
            this.store.dispatch(teamManagementActions.reloadTeamFromBackend({ team_id: id }));
            if (isCurrentUser) {
              this.userService.refreshUser();
            }
          },
          error: () => this.notif.showError('Error removing user from team'),
        });
      }
    });
  }

  async acceptInvite({ token, is_accepted }: { token?: string; is_accepted?: boolean }) {
    if (is_accepted) {
      // be aware that the server responds with error if invitation is already accepted (token won't be valid)
      return false;
    }
    const resp = await this.notif.showPopup('Are you sure you want to accept the invitation?');
    if (resp) {
      try {
        await this.rest.post(`${REST_TEAM_MANAGEMENT}/invitations/${token}`, {});
        this.notif.showSuccess('Invitation accepted successfully.');
        return true;
      } catch (ex) {
        this.notif.showError(`Error occurred while accepting invite: ${ex?.error?.message ?? ''}`);
      }
    }
    return false;
  }

  resendInvite(memberId: number) {
    return this.rest.postWithObservable(
      `${REST_TEAM_MANAGEMENT}/${memberId}/resend-invitation`,
      {},
    );
  }

  reassignTeamMember(oldMemberId: number, newMemberId: number) {
    return this.rest.patchWithObservable(`${REST_TEAM_MANAGEMENT}/${oldMemberId}/switch-lead`, {
      switch_to: newMemberId,
    });
  }
}
