import { PROJECT_STATUS_ID } from '../../../framework/constants/project.constants';
import { IRollupFilters } from '../../../store/rollups/rollups.interface';

export const defaultProjectStatusIds: PROJECT_STATUS_ID[] = [
  PROJECT_STATUS_ID.PLANNED,
  PROJECT_STATUS_ID.BIDDING,
  PROJECT_STATUS_ID.IN_PROGRESS,
  PROJECT_STATUS_ID.AWARDED,
];

export const defaultRollupFilters: IRollupFilters = {
  project_status: [...defaultProjectStatusIds],
  property_ids: [],
};
