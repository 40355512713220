import { AbstractControl } from '@angular/forms';
import {
  groupFilterType,
  IMessageFilters,
  IMessagingGroup,
  IMessagingGroupFilters,
} from '../../store/messages/messages.interfaces';

export enum DISCUSSION_FILTER_TYPE {
  ALL_DISCUSSIONS,
  GROUP_DISCUSSIONS,
  DIRECT_MESSAGES,
  TASKS,
  ARCHIVED,
}

export enum DISCUSSION_TYPES {
  GROUP_TYPE = 'group',
  DIRECT_TYPE = 'direct',
  TASK_TYPE = 'task',
  ARCHIVED = 'archived',
}

export const discussionTypeToLiteral = {
  [DISCUSSION_FILTER_TYPE.ALL_DISCUSSIONS]: 'All Discussions',
  [DISCUSSION_FILTER_TYPE.GROUP_DISCUSSIONS]: 'Group',
  [DISCUSSION_FILTER_TYPE.DIRECT_MESSAGES]: 'Direct',
  [DISCUSSION_FILTER_TYPE.TASKS]: 'Tasks',
  [DISCUSSION_FILTER_TYPE.ARCHIVED]: 'Archived',
};

export enum TASK_MESSAGES_BUBBLE_TYPES {
  MESSAGE_GROUP,
  MESSAGE_DIRECT,
  MESSAGE_ARCHIVED,
  TASK_DAILY,
  TASK_SPECTATING,
  TASK_CUSTOM,
}

export const messageTypeToBubbleType = {
  group: TASK_MESSAGES_BUBBLE_TYPES.MESSAGE_GROUP,
  direct: TASK_MESSAGES_BUBBLE_TYPES.MESSAGE_DIRECT,
  daily: TASK_MESSAGES_BUBBLE_TYPES.TASK_DAILY,
  custom: TASK_MESSAGES_BUBBLE_TYPES.TASK_CUSTOM,
};

export const discussionFilterTypeToDiscussionType: { [key: number]: groupFilterType } = {
  [DISCUSSION_FILTER_TYPE.DIRECT_MESSAGES]: DISCUSSION_TYPES.DIRECT_TYPE,
  [DISCUSSION_FILTER_TYPE.GROUP_DISCUSSIONS]: DISCUSSION_TYPES.GROUP_TYPE,
  [DISCUSSION_FILTER_TYPE.TASKS]: DISCUSSION_TYPES.TASK_TYPE,
};

// todo: change keys after they have changed
export const taskTypeToBubbleType = {
  daily: TASK_MESSAGES_BUBBLE_TYPES.TASK_DAILY,
  custom: TASK_MESSAGES_BUBBLE_TYPES.TASK_CUSTOM,
  spectating: TASK_MESSAGES_BUBBLE_TYPES.TASK_SPECTATING,
};

export const discussionTypeList = [
  {
    type: DISCUSSION_FILTER_TYPE.ALL_DISCUSSIONS,
    text: discussionTypeToLiteral[DISCUSSION_FILTER_TYPE.ALL_DISCUSSIONS],
  },
  {
    type: DISCUSSION_FILTER_TYPE.GROUP_DISCUSSIONS,
    text: discussionTypeToLiteral[DISCUSSION_FILTER_TYPE.GROUP_DISCUSSIONS],
  },
  {
    type: DISCUSSION_FILTER_TYPE.DIRECT_MESSAGES,
    text: discussionTypeToLiteral[DISCUSSION_FILTER_TYPE.DIRECT_MESSAGES],
  },
  {
    type: DISCUSSION_FILTER_TYPE.ARCHIVED,
    text: discussionTypeToLiteral[DISCUSSION_FILTER_TYPE.ARCHIVED],
  },
];

export function getErrorMessage(param: AbstractControl) {
  return param.hasError('required')
    ? 'Required'
    : param.hasError('email')
      ? 'Invalid email'
      : param.hasError('minlength')
        ? 'Too short'
        : '';
}

export const DEFAULT_IMAGE = 'default_image';

export const DEFAULT_ID = -1;

export const getBubbleType = (group: IMessagingGroup) => {
  if (group?.status && group.status === DISCUSSION_TYPES.ARCHIVED) {
    return TASK_MESSAGES_BUBBLE_TYPES.MESSAGE_ARCHIVED;
  }
  if (group?.type === DISCUSSION_TYPES.TASK_TYPE) {
    return taskTypeToBubbleType[group.task_type];
  }
  return messageTypeToBubbleType[group?.type];
};

export interface IMessagingHeaderFilters {
  search?: string;
  discussionType?: DISCUSSION_FILTER_TYPE;
}

// the first page should be bigger.
// otherwise on tall screens the scrollbar doesn't appear and the infinite scroll won't work
export const MESSAGES_FIRST_PAGE_LENGTH = 30;
export const MESSAGES_NOT_FIRST_PAGE_LENGTH = 20;

export const defaultMessageFilters: IMessageFilters = {
  search: null,
  perPage: MESSAGES_FIRST_PAGE_LENGTH, // the first page has 30 pages then it is set to 20
  offset: null, // the last loaded message id - replaces page parameter
};

export const defaultGroupFilters: IMessagingGroupFilters = {
  search: null,
  discussionType: DISCUSSION_FILTER_TYPE.ALL_DISCUSSIONS,
  page: 0, // by default, it's 0 which means that no pages are loaded yet, so the first page should be loaded (page=1)
  perPage: MESSAGES_FIRST_PAGE_LENGTH, // the first page has 30 pages then it is set to 20
  group_id: null,
};
