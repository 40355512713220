import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CurrentUserService } from '../../services/current-user.service';

export class UsersDataSource extends DataSource<any | undefined> {
  private cachedUsers = [{ id: -1, username: 'Loading...', company_name: '...' }];
  private dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedUsers);
  private subscription = new Subscription();
  private currentPage = 0;
  private PAGE_SIZE = 25;
  private currentSearch = '';

  constructor(
    private userService: CurrentUserService,
    private notif,
  ) {
    super();
  }

  connect(
    collectionViewer: CollectionViewer,
  ): Observable<(any | undefined)[] | ReadonlyArray<any | undefined>> {
    this.onScrollDown();
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        if (this.currentPage * this.PAGE_SIZE - 5 <= range.end) {
          this.onScrollDown();
        }
      }),
    );
    return this.dataStream;
  }

  onScrollDown() {
    this.currentPage++;

    this.userService.getAllUsers(this.currentPage, this.currentSearch).then(
      (data: any) => {
        this.cachedUsers.pop();
        this.cachedUsers.push(...data.data);

        if (data.current_page !== data.last_page) {
          this.cachedUsers.push({ id: -1, username: 'Loading...', company_name: '...' });
        }
        this.dataStream.next(this.cachedUsers);
      },
      (err) => {
        this.cachedUsers.pop();
        this.cachedUsers.push({ id: -1, username: 'Error', company_name: err });
        this.notif.showError(err);
      },
    );
  }

  searchPage(searchText) {
    this.currentSearch = searchText;
    this.cleanCache();
    this.onScrollDown();
  }

  cleanCache() {
    this.currentPage = 0;
    this.cachedUsers = [{ id: -1, username: 'Loading...', company_name: '...' }];
    this.dataStream.next(this.cachedUsers);
  }

  updateUser(id: number, data) {
    const userIndex = this.cachedUsers.findIndex((o) => o.id === id);
    this.cachedUsers[userIndex] = { ...this.cachedUsers[userIndex], ...data };
    this.dataStream.next(this.cachedUsers);
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }
}
