export enum PROJECT_STATUS_KEY {
  NONE = '',
  BIDDING = 'bidding',
  AWARDED = 'awarded',
  CANCELED = 'cancelled',
  PLANNED = 'planned',
  DELETED = 'deleted',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export enum PROJECT_STATUS_ID {
  NONE,
  BIDDING = 1,
  AWARDED = 3,
  CANCELED,
  PLANNED,
  DELETED,
  IN_PROGRESS,
  COMPLETED,
  ARCHIVED,
}

export enum PROJECT_STATUS_CONTRACTOR {
  NONE,
  INVITED = 'invited',
  BIDDING = 'bidding',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
}

export const contractorProjectStatusIdToString = {
  invited: 'Invited',
  bidding: 'Bidding',
  in_progress: 'In Progress',
  completed: 'Completed',
  deleted: 'Deleted',
  archived: 'Archived',
};

export const enum PROJECT_TYPE {
  NONE,
  BUDGET,
  BIDDING,
  MISCELL,
}

export enum BID_STATUS {
  NONE,
  PENDING,
  ACCEPTED,
  DECLINED,
}

export enum PROJECT_TYPE_CONTRACTOR {
  NONE,
  INVITED,
  BIDDING,
}

export const PROJECT_ATTRIBUTES = [
  { id: 'capex', name: 'CapEX' },
  { id: 'opex', name: 'OpEX' },
  { id: 'priority', name: 'Priority' },
  { id: 'approved', name: 'Approved' },
];

export const PROJECT_ATTRIBUTES_OBJ = {
  capex: 'capex',
  opex: 'opex',
  priority: 'priority',
  approved: 'approved',
};

export const PROJECT_STATUSES: ManagerProjectStatus[] = [
  { id: 1, name: 'Bidding', key: 'bidding' },
  { id: 3, name: 'Awarded', key: 'awarded' },
  { id: 4, name: 'Cancelled', key: 'cancelled' },
  { id: 5, name: 'Planned', key: 'planned' },
  { id: 6, name: 'Deleted', key: 'deleted' }, // is not in use anymore, kept for legacy
  { id: 7, name: 'In Progress', key: 'in_progress' },
  { id: 8, name: 'Completed', key: 'completed' },
  { id: 9, name: 'Archived', key: 'archived' },
];

export type ManagerProjectStatus = { id: number; name: string; key: ProjectStatusKey };

export type ProjectStatusKey =
  | 'bidding'
  | 'awarded'
  | 'cancelled'
  | 'planned'
  | 'deleted'
  | 'in_progress'
  | 'completed'
  | 'archived';

// contractors can have different statuses, missing key as this is who it works (id is key)
export const PROJECT_STATUSES_CONTRACTOR: ContractorProjectStatus[] = [
  { id: 'invited', name: 'Invited' },
  { id: 'bidding', name: 'Bidding' },
  { id: 'in_progress', name: 'In Progress' },
  { id: 'completed', name: 'Completed' },
  { id: 'deleted', name: 'Deleted' },
  { id: 'archived', name: 'Archived' },
];

export type ContractorProjectStatusId =
  | 'invited'
  | 'bidding'
  | 'in_progress'
  | 'completed'
  | 'deleted'
  | 'archived';

export type ContractorProjectStatus = { id: ContractorProjectStatusId; name: string };

export const tableStatusesManager = [
  PROJECT_STATUS_KEY.BIDDING,
  PROJECT_STATUS_KEY.AWARDED,
  PROJECT_STATUS_KEY.PLANNED,
  PROJECT_STATUS_KEY.IN_PROGRESS,
];
export const tableStatusesContractor = [
  PROJECT_STATUS_CONTRACTOR.INVITED,
  PROJECT_STATUS_CONTRACTOR.BIDDING,
  PROJECT_STATUS_CONTRACTOR.IN_PROGRESS,
];

export const PROJECTS_ALL_PROPERTIES_SELECTED = 0;

export const projectsTableHeaders = [
  { name: 'Project', tooltip: null, key: 'title' },
  { name: 'Company Name', tooltip: null, key: 'company_name' },
  {
    name: 'Start Date',
    tooltip: 'The date that the project expect to start.',
    key: 'start_date',
  },
  // todo: extend these:>
  { name: 'Approval', tooltip: null, key: 'approved' },
  { name: 'Proposal', tooltip: 'All submitted proposals', key: 'proposal' },
  { name: 'Contract', tooltip: 'All approved contracts', key: 'contract_sum' },
  { name: 'Invoiced', tooltip: 'All paid invoices', key: 'invoice_sum' },
  {
    name: 'Current Budget',
    tooltip: 'The most up-to-date project budget.',
    key: 'current_budget',
  },
  {
    name: 'Committed',
    tooltip: 'Total Committed = Contracts + Change Orders',
    key: 'total_committed',
  },
  { name: 'Invoiced', tooltip: 'All paid invoices', key: 'actuals' },
  {
    name: 'Fcst to Complete',
    tooltip: 'Fcst to Complete = Current Budget - Committed.',
    key: 'forecasts_to_complete',
  },
  // {
  //   name: 'Actuals',
  //   tooltip:
  //     'A total of all paid invoices and direct costs. Invoices and direct costs marked "not paid" are not included.',
  //   key: 'actuals',
  // },
  {
    name: '%Complete',
    tooltip: '%Complete = (Actuals / Committed) * 100',
    tooltipContractor: '%Complete = Paid Invoices / Total Committed',
    key: 'complete',
  },
  { name: 'Project Attributes', tooltip: null, key: 'attributes' },
  { name: 'Project Status', tooltip: null, key: 'statusId' },
  { name: 'Project Status', tooltip: null, key: 'status' },
];
