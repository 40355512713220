import { Pipe, PipeTransform } from '@angular/core';
import { RestRequestService } from '../../restApi/rest-request.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

// see: https://javascript.plainenglish.io/loading-images-with-authorization-8aab33663ba6
@Pipe({
  name: 'blobToBase64',
  standalone: true,
})
export class BlobToBase64Pipe implements PipeTransform {
  async transform(value: string, ...args: unknown[]) {
    try {
      const imageBlob = await this.rest.getBlob(value);
      // const imageBlob = await this.rest.get(value, { responseType: 'blob' }).toPromise();
      console.log(imageBlob);
      const reader = new FileReader();
      const url = URL.createObjectURL(imageBlob);
      return this.domSanitizer.bypassSecurityTrustUrl(url);
      // console.warn(url);
      // return url;
      // return new Promise((resolve, reject) => {
      //   reader.onloadend = () => resolve(reader.result as string);
      //   reader.readAsDataURL(imageBlob);
      // });
    } catch (ex) {
      console.log(ex);
      return 'fallback';
    }
  }
  constructor(
    private rest: RestRequestService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {}
}
