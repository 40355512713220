<div [ngClass]="{ 'border-bottom': showBorder }" class="header-wrapper page-header-wrapper">
  <div class="page-title">{{ title }}</div>
  <div class="inputs">
    <div *ngIf="showViewSelector" class="btn-small select-container">
      <span class="default-cursor select-placeholder">Data View:</span>
      <mat-form-field subscriptSizing="dynamic" class="input borderless">
        <mat-select [value]="ROLLUP_VIEW_TYPES.PROJECTS" (valueChange)="tableViewTypeChanged($event)">
          <mat-option [value]="ROLLUP_VIEW_TYPES.PROJECTS">{{ viewTitleMap?.[ROLLUP_VIEW_TYPES.PROJECTS] }}</mat-option>
          <mat-option [value]="ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS">{{
            viewTitleMap?.[ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS]
          }}</mat-option>
          <mat-option [value]="ROLLUP_VIEW_TYPES.ACCOUNTS">{{ viewTitleMap?.[ROLLUP_VIEW_TYPES.ACCOUNTS] }}</mat-option>
          <mat-option [value]="ROLLUP_VIEW_TYPES.TAGS">{{ viewTitleMap?.[ROLLUP_VIEW_TYPES.TAGS] }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="showViewSelector" class="border-left"></div>

    <ng-content select="[before-search]"></ng-content>

    <div *ngIf="showPreSearchBorder" class="border-left"></div>

    <app-search-bar-minimalist
      [placeholder]="searchPlaceholder"
      (searchText)="searchTextChanged($event)"
    ></app-search-bar-minimalist>

    <ng-content select="[after-search]"></ng-content>
  </div>
</div>
