import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { defaultItemTrackingSidebar } from './item-tracking.constants';
import { itemTrackingActions } from './item-tracking.actions';
import { Activity } from '../activities/activities.constants';
import { UserDriveFile } from '../../framework/constants/documents.constants';

export const itemTrackingFeatureKey = 'item-tracking';

export const activityAdapter: EntityAdapter<IItemTracking> = createEntityAdapter<IItemTracking>();

export const { selectAll, selectEntities } = activityAdapter.getSelectors();

export interface IItemTracking {}

// todo move these interfaces into item-tracking.interfaces.ts and please review names
export interface IItemTrackingSidebar {
  id?: number;
  title: string;
  start_date: string;
  end_date: string;
  checklist: IItemTrackingChecklist;
}

export interface IActivityProgressItem {
  id: number;
  title: string;
  project_id: number;
  start_date: string;
  end_date: string;
  note: string;
  is_due: boolean;
  total_files: number;
  checklist: IItemTrackingChecklist;
  due_percentage?: number;
  author?: Author;
  due_days: number;
  work_percentage: number;
  activity: Partial<Activity>;
}

export interface IItemTrackingFile extends File {
  id: number;
  name: string;
  size: number;
  type: string;
  deleted?: boolean;
}

// todo find a more specific name
export interface Author {
  id: number;
  username: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  company_name: string;
  type: Type;
  is_admin: boolean;
  is_parent_account: boolean;
  parent_account_id: any;
  is_approved: boolean;
  deleted: boolean;
  last_login: string;
  reset_token: any;
  fiscal_year_start: number;
  created_at: string;
  updated_at: string;
  user_drive_folder_id: number;
  deleted_at: any;
  email_verified_at: string;
  company_picture_id: number;
  profile_picture_id: number;
  stripe_id: string;
  pm_type: any;
  pm_last_four: any;
  trial_ends_at: any;
  profile_picture_url: string;
  company_picture_url: string;
  profile_picture: UserDriveFile;
  company_picture: UserDriveFile;
}

// todo find a more specific name (what kind of type is this?)
export interface Type {
  id: number;
  name: string;
}

export interface IItemTrackingChecklistItem {
  id?: number;
  name: string;
  end_date: string;
  completed: boolean;
  due_days?: number;
  due_percentage?: number;
  note: string;
  order?: number;
  files: IItemTrackingFile[];
  activity?: Partial<Activity>;
}

export interface IItemTrackingChecklist {
  id?: number;
  template_id?: number;
  name: string;
  deleted?: boolean;
  completed?: boolean;
  items: IItemTrackingChecklistItem[];
}

export interface ItemTrackingState extends EntityState<IItemTracking> {
  project_id: number;
  isLoading: boolean; // general isLoading
  isSidebarLoading: boolean;
  sidebarActivity: Partial<IItemTrackingSidebar>;
  allChecklist: IItemTrackingChecklist[];
  deleteChecklistIds: number[];
  allProgressItems: IActivityProgressItem[];
  loadFilter: { [key: string]: boolean };
}

export const activitiesInitialState: ItemTrackingState = activityAdapter.getInitialState({
  project_id: null,
  isLoading: false,
  isSidebarLoading: false,
  sidebarActivity: defaultItemTrackingSidebar,
  allChecklist: [
    {
      name: '',
      items: [],
    },
  ],
  deleteChecklistIds: [],
  allProgressItems: [],
  loadFilter: {},
});

export const itemTrackingReducer = createReducer(
  activitiesInitialState,
  on(itemTrackingActions.setIsLoading, (state, action): ItemTrackingState => {
    return { ...state, isLoading: action.isLoading };
  }),
  on(itemTrackingActions.loadAllProgressItems, (state): ItemTrackingState => {
    return { ...state, isLoading: true };
  }),
  on(itemTrackingActions.setAllProgressItems, (state, action): ItemTrackingState => {
    return { ...state, allProgressItems: action.progressItems, isLoading: false };
  }),
  on(itemTrackingActions.cancel, (state): ItemTrackingState => {
    return { ...state, isLoading: false };
  }),
  on(itemTrackingActions.setChecklist, (state, action): ItemTrackingState => {
    return { ...state, allChecklist: [...action.checklist] };
  }),
  on(itemTrackingActions.setActiveProject, (state, action): ItemTrackingState => {
    return {
      ...state,
      project_id: action.projectId,
    };
  }),
  on(itemTrackingActions.deleteChecklist, (state, action): ItemTrackingState => {
    return {
      ...state,
      deleteChecklistIds: [...state.deleteChecklistIds, action.id],
    };
  }),
  on(itemTrackingActions.clearDeletedChecklist, (state, action): ItemTrackingState => {
    return {
      ...state,
      deleteChecklistIds: [],
      isLoading: false,
    };
  }),
  on(itemTrackingActions.setProgressItemsLoadFilter, (state, action): ItemTrackingState => {
    return {
      ...state,
      loadFilter: { ...action.filter },
    };
  }),
  on(itemTrackingActions.resetProgressItemsState, (state, action): ItemTrackingState => {
    return {
      ...state,
      loadFilter: {},
    };
  }),
  on(itemTrackingActions.clearProgressItemsList, (state, action): ItemTrackingState => {
    return {
      ...state,
      allProgressItems: [],
    };
  }),
);
