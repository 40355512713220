import { EventEmitter, Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_BASE, REST_COMMITMENTS, REST_REPORTS } from '../restApi/RestRoutes';
import { IReportingData } from '../framework/reporting/reporting.constants';
import { ROLLUP_VIEW_TYPES } from '../store/rollups/rollups.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { PROJECT_VIEWS } from '../framework/constants/view-project.constants';
import { HttpParams } from '@angular/common/http';
import { IServiceProvider } from '../framework/interaction-bar/invoice-report-panel/invoice-report-panel.types';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  selectedProjectView = new EventEmitter<PROJECT_VIEWS>();
  selectedRollupView = new BehaviorSubject<ROLLUP_VIEW_TYPES>(null);
  private _selectedProperty: number[] | 'all' = null;
  private _selectedYear: number = null;
  private _selectedProjectId: number[] | 'all' = null;
  constructor(private rest: RestRequestService) {}
  generateReportOnServer(data: Partial<IReportingData>) {
    return this.rest.post(REST_REPORTS, data);
  }

  setSelectedProjectView(view: PROJECT_VIEWS) {
    this.selectedProjectView.next(view);
  }

  get selectedProperty() {
    return this._selectedProperty;
  }
  set selectedProperty(value: number[] | 'all') {
    this._selectedProperty = value;
  }

  get selectedYear(): number {
    return this._selectedYear;
  }

  set selectedYear(value: number) {
    this._selectedYear = value;
  }

  get selectedProjectId(): number[] | 'all' {
    return this._selectedProjectId;
  }

  set selectedProjectId(value: number[] | 'all') {
    this._selectedProjectId = value;
  }

  getServiceProvidersList(projectIds: number[]): Observable<IServiceProvider[]> {
    let queryParams = new HttpParams();
    projectIds.forEach((projectId, index) => {
      queryParams = queryParams.append(`project_ids[${index}]`, projectId);
    });
    return this.rest.getWithObservable(
      `${REST_BASE}${REST_COMMITMENTS}/service-providers?${queryParams.toString()}`,
    );
  }
}
