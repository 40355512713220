import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-simple-small-button',
  standalone: true,
  imports: [NgClass],
  styleUrl: './simple-small-button.component.scss',
  template: `
    <span [ngClass]="wrapperClass" class="simple-small-button">
      <ng-content> </ng-content>
    </span>
  `,
})
export class SimpleSmallButtonComponent {
  @Input() wrapperClass: { [p: string]: any } | string[] | string = [];
}
