<div class="webapp-page-container">
  <div class="icon-logo-box-hex"></div>
  <div class="prompt-container">
    <div class="title">Do you want to view or download the file?</div>
    <div class="button-wrapper">
      <button [disabled]="!!errorMsg" mat-button class="btn btn-view" (click)="viewFile()">View</button>
      <button [disabled]="!!errorMsg" mat-button class="btn btn-download" (click)="downloadFile()">Download</button>
    </div>
    <div class="error-msg">{{ errorMsg }}</div>
  </div>
</div>
