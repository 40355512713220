<div
  class="messaging-bubble"
  [ngClass]="{
    'is-filled': isFilled,
    'group-color': type === TYPES.MESSAGE_GROUP,
    'direct-message-color': type === TYPES.MESSAGE_DIRECT,
    'tasks-daily': type === TYPES.TASK_DAILY,
    'tasks-custom': type === TYPES.TASK_CUSTOM,
    'tasks-spectating': type === TYPES.TASK_SPECTATING,
    'archive-color': type === TYPES.MESSAGE_ARCHIVED
  }"
></div>
