import { ISpendLineItemSummary } from './spend.interfaces';

export const defaultSpendLineSummary: ISpendLineItemSummary = {
  name: '',
  spend_contracts: {
    contracts: [],
    committed: 0,
  },
  spend_direct_costs: {
    direct_costs: [],
    committed: 0,
  },
  committed: 0,
  isLoaded: false,
};
