<ng-template
  #overlay
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="show"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayHasBackdrop]="cdkConnectedOverlayHasBackdrop"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="toggleShow()"
>
  <div [@dropdownAnimation] [@.disabled]="!withAnimation">
    <ng-content></ng-content>
  </div>
</ng-template>
