<div class="auth-wrapper" [ngClass]="{ register: isRegister }">
  <div class="content">
    <app-login *ngIf="currentPage === ROUTE_AUTH.LOGIN"></app-login>
    <app-reset-password *ngIf="currentPage === ROUTE_AUTH.RESET_PASSWORD"></app-reset-password>
    <app-register *ngIf="currentPage === ROUTE_AUTH.REGISTER"></app-register>
    <app-thank-you [@fadeIn] *ngIf="currentPage === ROUTE_AUTH.THANK_YOU"></app-thank-you>
  </div>

  <div class="sliding-bar" [ngClass]="{ 'sliding-bar-register': isRegister }">
    <div class="logo">
      <img routerLink="/landing" class="cursor-pointer" src="assets/images/logo_auth.svg" alt="company logo" />
    </div>

    <div *ngIf="!isRegister" class="bar-content">
      <div class="title">New Account</div>
      <p class="text">First time here? Let's get you an account!</p>
      <div class="mt-14 flex justify-center">
        <button
          class="landing-btn cubic btn-big outline-primary-btn"
          mat-button
          routerLink="/auth/register"
          (click)="toggleIsRegister()"
        >
          REGISTER
        </button>
      </div>
    </div>

    <div *ngIf="isRegister" class="bar-content">
      <p class="title">My Account</p>
      <p class="text">
        Let's see the latest activity <br />
        regarding your bids and projects.
      </p>
      <div class="mt-14 flex justify-center">
        <button
          class="landing-btn cubic btn-big outline-primary-btn"
          mat-button
          routerLink="/auth/login"
          (click)="toggleIsRegister()"
        >
          LOGIN
        </button>
      </div>
    </div>

    <div class="triangles">
      <div class="blue-box"></div>
      <div class="yellow-box"></div>
    </div>
  </div>
</div>
