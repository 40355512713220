import { createAction, props } from '@ngrx/store';

export const load = createAction('[Documents Service] Load');

export const updateAll = createAction(
  '[Documents Service] Update All',
  props<{ data: any; setFolderPath?: boolean }>(),
);

export const updateOne = createAction('[Documents Service] Update One', props<{ data: any }>());

export const back = createAction('[Documents Service] Back');

export const reset = createAction('[Documents Service] Reset');

export const cancel = createAction('[Documents Service] Cancel');

export const reload = createAction('[Documents Service] Reload');

export const clearAll = createAction('[Documents Service] Clear');

export const loadExternalDrive = createAction(
  '[Documents Service] Load External Drive',
  props<{ uuid: string }>(),
);

export const reloadExternalDrive = createAction(
  '[Documents Service] Reload External Drive',
  props<{ uuid: string }>(),
);

export const loadDriveFolder = createAction(
  '[Documents Service] Load Drive Folder',
  props<{ folder: any }>(),
);

export const updateDriveFolder = createAction(
  '[Documents Service] Update Drive Folder',
  props<{ folder: any }>(),
);
export const driveFolderClicked = createAction(
  '[Documents Service] Drive Folder Clicked',
  props<{ id: number }>(),
);

export const driveExternalFolderClicked = createAction(
  '[Documents Service] Drive External Folder Clicked',
  props<{ id: number; uuid: string }>(),
);

export const reloadDriveFolder = createAction(
  '[Documents Service] Reload Drive Folder By Id',
  props<{ id: number }>(),
);

export const backOneFolder = createAction('[Documents Service] Go Back One Folder');

export const documentActionTypes = {
  load,
  reload,
  updateAll,
  updateOne,
  back,
  reset,
  cancel,
  clearAll,
  loadExternalDrive,
  reloadExternalDrive,
  loadDriveFolder,
  updateDriveFolder,
  driveFolderClicked,
  driveExternalFolderClicked,
  reloadDriveFolder,
  backOneFolder, // todo: this needs a better name
};
