import { TASK_ORDER_BY } from '../../../../store/tasks/tasks.interfaces';

export const taskTableHeader: TaskTableHeader[] = [
  { name: 'Task Name', type: TASK_ORDER_BY.TITLE },
  {
    name: 'Due Date',
    type: TASK_ORDER_BY.DUE_DATE,
  },
  {
    name: 'Status',
    type: TASK_ORDER_BY.STATUS,
  },
  {
    name: 'Checklist',
    type: TASK_ORDER_BY.CHECKLIST,
  },
  {
    name: '',
    type: TASK_ORDER_BY.NONE,
  },
  {
    name: 'Author',
    type: TASK_ORDER_BY.AUTHOR,
  },
  {
    name: 'Assigned',
    type: TASK_ORDER_BY.ASSIGNED,
  },
  {
    name: 'Project',
    type: TASK_ORDER_BY.PROJECT,
  },
  {
    name: 'Attachments',
    type: TASK_ORDER_BY.ATTACHMENTS,
  },
];

export type TaskTableHeader = { name: string; type: TASK_ORDER_BY };
