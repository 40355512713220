<div class="search">
  <div class="title" (click)="showInput()">
    <span class="icon-search" [ngClass]="{ 'add-margin': !showSearchbarProj && alignRight }"></span>
    <span class="key" [ngClass]="{ 'hide-font aling-left': showSearchbarProj }">{{ placeholder }}</span>
  </div>
  <div class="form title" *ngIf="showSearchbarProj" [@fadeIn]="'in'">
    <mat-form-field subscriptSizing="dynamic" class="search-input">
      <input
        matInput
        [formControl]="input"
        (focusout)="checkSearchValue()"
        (keyup.enter)="checkSearchValue()"
        #inputField
      />
    </mat-form-field>
  </div>
</div>
