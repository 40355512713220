import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setIsLoading, spendActionTypes } from './spend.actions';
import { catchError, debounceTime, map, repeat, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectSpendService } from '../../services/project-spend.service';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import {
  getAllLineItemData,
  getLineItemById,
  getNextOrder,
  getProjectStartDate,
  spendFeatureSelector,
} from './spend.selectors';
import { forkJoin, of, throwError } from 'rxjs';
import {
  defaultLineItem,
  defaultMonthlyData,
  DISTRIBUTION_TYPES,
  spendTypeKeys,
} from '../../framework/constants/spend.constants';
import moment from 'moment';
import {
  DistributionField,
  IBudget,
  IDistributionResponse,
  ILineItem,
  ISpendDistribution,
  SPEND_ERROR_TYPES,
} from './spend.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { NotificationsService } from '../../services/notifications.service';
import { ProjectStateService } from '../../services/project-state.service';
import { SpendState } from './spend.reducer';
import { SPEND_TYPES } from '../../framework/constants/budget.constants';
import lodash from 'lodash';
import { FiscalService } from '../../services/fiscal.service';

@Injectable()
export class SpendEffects {
  actionsWithHttpRequest = [
    spendActionTypes.loadSpends,
    spendActionTypes.saveToBackend,
    spendActionTypes.updateDistribution,
  ];

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private spendService: ProjectSpendService,
    private notif: NotificationsService,
    private projectStateService: ProjectStateService,
    private fiscalService: FiscalService,
  ) {}

  addDefaultLineItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.addDefaultLineItem),
      withLatestFrom(this.store.select(getProjectStartDate), this.store.select(getNextOrder)),
      map(([action, projectStartDate, order]) => {
        const lineItem = { ...defaultLineItem };
        lineItem.row_number = order;
        lineItem.start_date = projectStartDate ?? moment().format('YYYY-MM-DD');
        lineItem.forecast_start_date = projectStartDate ?? moment().format('YYYY-MM-DD');
        lineItem.budget = [];
        const year = this.projectStateService.getLineItemFiscalYear(lineItem.start_date);
        const budget: IBudget = {
          year,
          monthly_budget: { ...defaultMonthlyData },
          monthly_actuals: { ...defaultMonthlyData },
          monthly_forecast: { ...defaultMonthlyData },
        };
        lineItem.budget.push(budget);

        return spendActionTypes.addLineItem({ lineItem });
      }),
    );
  });

  addLineItemWithName = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.addLineItemWithName),
      withLatestFrom(this.store.select(getProjectStartDate), this.store.select(getNextOrder)),
      map(([action, projectStartDate, order]) => {
        const lineItem = { ...defaultLineItem };
        lineItem.row_number = order;
        lineItem.start_date = projectStartDate ?? moment().format('YYYY-MM-DD');
        lineItem.forecast_start_date = projectStartDate ?? moment().format('YYYY-MM-DD');
        lineItem.budget = [];
        lineItem.name = action.name;
        const year = this.projectStateService.getLineItemFiscalYear(lineItem.start_date);
        const budget: IBudget = {
          year,
          monthly_budget: { ...defaultMonthlyData },
          monthly_actuals: { ...defaultMonthlyData },
          monthly_forecast: { ...defaultMonthlyData },
        };
        lineItem.budget.push(budget);

        return spendActionTypes.addLineItem({ lineItem });
      }),
    );
  });

  loadSpends = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.loadSpends),
      switchMap((action) => {
        return of(action).pipe(
          debounceTime(300),
          switchMap((actionInner) =>
            this.spendService.getLineItemsByProjectId(actionInner.projectId),
          ),
          withLatestFrom(of(action)),
        );
      }),
      map(([lineItems, action]) => {
        lineItems.forEach((item) => {
          item.budget.forEach((bud) => {
            spendTypeKeys.forEach((spendType) => {
              delete bud[spendType].NaN; // backend error, there is sometimes a NaN month
            });
          });
        });

        // lineItems.sort((a, b) => a.row_number - b.row_number);
        lineItems = lineItems.map((item, index) => {
          return {
            ...item,
            row_number: index,
          };
        });
        return spendActionTypes.setLineItems({
          lineItems,
          projectId: action.projectId,
        });
      }),
    );
  });

  updateDistribution = createEffect(() => {
    // could not find simpler solution, sorry
    return this.actions$.pipe(
      ofType(spendActionTypes.updateDistribution),
      withLatestFrom(this.store.select(spendFeatureSelector)),
      // transform the action observable to also contain distribution from backend and lineItem from store
      // if the user selects a new value before response arrived, the first value is cancelled
      switchMap(([action, state]) =>
        of(action).pipe(
          switchMap((actionInner) => {
            if (this.isUpdateRequestNeeded(actionInner, state)) {
              return this.spendService
                .calculateDistribution(
                  actionInner.distribution,
                  state.newLineItems.has(action.lineId) ? null : action.lineId,
                )
                .catch((err) => {
                  throw err;
                });
            }
            return throwError(actionInner.distribution);
          }),
          catchError((err) => {
            setTimeout(() => {
              if (err.duration === null) {
                this.notif.showError('Please provide a distribution duration');
              } else {
                if (err.distribution !== 'manual') {
                  this.notif.showError('Distribution update error.');
                }
              }
            });
            return of(undefined);
          }),
          withLatestFrom(
            of(action),
            this.store.select(getLineItemById(action.lineId)),
            this.store.select(spendFeatureSelector),
          ),
        ),
      ),
      catchError((err) => {
        console.warn(err);
        throwError(err);
        return of(undefined);
      }),
      map(
        ([dist, action, lineItemStore, state]: [
          IDistributionResponse,
          { distribution: ISpendDistribution },
          ILineItem,
          SpendState,
        ]) => {
          let lineItem: ILineItem = cloneDeep(lineItemStore);
          lineItem = this.updateDistributionBasicValues(lineItem, action);
          const updateNewForecastItem =
            state.newLineItems.has(lineItem.id) && state.selectedSpendType === SPEND_TYPES.FORECAST;
          if (dist && !updateNewForecastItem) {
            // if dist is truthy that means there was an http request
            lineItem = this.updateDistWithReq(lineItem, dist, action);
          } else if (
            action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL ||
            updateNewForecastItem
          ) {
            // undefined dist means there wasn't any request to the backend
            lineItem = this.updateDistManual(lineItem, action);
          }
          return spendActionTypes.updateLineItem({ lineItem });
        },
      ),
      catchError((err) => {
        console.warn(err);
        return of(undefined);
      }),
    );
  });

  deleteAllLineItems = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.addProjectBudgetTemplateChange),
      withLatestFrom(
        this.store.select(getAllLineItemData),
        this.store.select(spendFeatureSelector),
      ),
      map(([action, items, state]) => {
        items.forEach((item) => {
          // if (!state.newLineItems.has(item.id)) {
          this.store.dispatch(spendActionTypes.deleteLineItem({ id: item.id }));
          // }
        });
        return spendActionTypes.cancel();
      }),
    );
  });

  saveToBackend = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.saveToBackend),
      withLatestFrom(this.store.select(spendFeatureSelector)),
      map(([_, state]) => {
        const saveObservables = []; // network requests are collected here and are executed in parallel
        // error handling on every observable is needed because the forkJoin used later.
        // otherwise if an error happens it cancels out other requests too.
        const errorHandling = catchError((err) => {
          setTimeout(() => {
            if (err?.error?.message) {
              this.notif.showError(err?.error?.message);
              return;
            }

            if (err?.error.start_date?.[0] === 'validation.after_or_equal') {
              this.notif.showError("Start date can't be before project start date!");
              return;
            }

            if (err?.error.forecast_start_date?.[0] === 'validation.after_or_equal') {
              this.notif.showError("Forecast start date can't be before project start date!");
              return;
            }

            this.notif.showError('An error occurred during saving.');
          });

          return throwError(err);
        });

        for (const id of state.newLineItems) {
          const lineItem = { ...state.entities[id] };
          delete lineItem.id;
          lineItem.project_id = state.selectedProjectId;
          saveObservables.push(
            this.spendService.createLineItem(lineItem, state.spendDistributionState).pipe(
              errorHandling,
              catchError((err) => {
                return of({
                  id,
                  errorType: SPEND_ERROR_TYPES.NEW,
                  error: true,
                });
              }),
            ),
          );
        }

        for (const id of state.deletedLineItems) {
          saveObservables.push(
            this.spendService.deleteLineItem(id).pipe(
              errorHandling,
              catchError((err) => {
                return of({
                  id,
                  errorType: SPEND_ERROR_TYPES.DELETED,
                  error: true,
                });
              }),
            ),
          );
        }

        for (const id of state.modifiedLineItems) {
          if (state.entities[id]) {
            saveObservables.push(
              this.spendService.updateLineItem(state.entities[id]).pipe(
                errorHandling,
                catchError((err) => {
                  return of({
                    id,
                    errorType: SPEND_ERROR_TYPES.MODIFIED,
                    error: true,
                  });
                }),
              ),
            );
          }
        }
        return saveObservables;
      }),
      switchMap((observables) => (observables?.length > 0 ? forkJoin([...observables]) : of([]))),
      map((responses: any[]) => {
        // items saved not successfully can be resaved
        const errors = responses.filter((response) => response?.error);
        return spendActionTypes.clearAfterSave({ errors });
      }),
    );
  });

  loadSpendItemSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.loadSpendLineItemSummary),
      switchMap((action) => {
        return this.spendService.getSpendLineItemSummary(action.lineItemId).pipe(
          map((summary) => {
            return spendActionTypes.spendLineItemSummaryLoaded({ summary });
          }),
        );
      }),
      catchError((_) => {
        this.notif.showError('An error occurred.');
        return of(spendActionTypes.cancel());
      }),
      repeat(),
    );
  });

  setIsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(...this.actionsWithHttpRequest),
      map((action) => {
        if (action.type === spendActionTypes.updateDistribution.type) {
          if (action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL) {
            // this way won't overwrite current loading state
            return spendActionTypes.setIsLoading({ isLoading: undefined });
          }
        }
        return spendActionTypes.setIsLoading({ isLoading: true });
      }),
    ),
  );

  isUpdateRequestNeeded = (action, state) => {
    let isNeeded = true;
    if (action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL) {
      isNeeded = false;
    }
    if (action.distribution.duration <= 0 || action.distribution.budget < 0) {
      isNeeded = false;
    }

    if (state.newLineItems.has(action.lineId) && state.selectedSpendType === SPEND_TYPES.FORECAST) {
      isNeeded = false;
    }

    if (!isNeeded) {
      this.store.dispatch(setIsLoading({ isLoading: false }));
    }
    return isNeeded;
  };

  updateDistributionBasicValues(lineItem: ILineItem, action: { distribution: ISpendDistribution }) {
    if (action.distribution.field === DistributionField.monthly_forecast) {
      lineItem.forecast_distribution = action.distribution.distribution;
      lineItem.forecast_start_date = action.distribution.start_date;
      lineItem.forecast_duration = action.distribution.duration;
    } else if (action.distribution.field === DistributionField.monthly_budget) {
      lineItem.budget_distribution = action.distribution.distribution;
      lineItem.start_date = action.distribution.start_date;
      lineItem.duration = action.distribution.duration;
    }
    return lineItem;
  }

  updateDistWithReq(
    lineItem: ILineItem,
    dist: IDistributionResponse,
    action: { distribution: ISpendDistribution },
  ) {
    // all new years from request will be saved in budgetYears
    const budgetYears = dist.budget.map((bud) => bud.year);
    // delete not needed years but keep years with non default values (non zero defaults)
    lineItem.budget = lineItem.budget.filter((budget) => {
      return budgetYears.includes(budget.year) || this.hasNonZeroMonth(action, budget);
    });

    // old monthly_data values need to be zero for selected distribution
    lineItem.budget.forEach((bud) => {
      bud[action.distribution.field] = { ...defaultMonthlyData };
    });
    // then add/update the years
    budgetYears.forEach((year) => {
      const newBudgetPerYear: IBudget = cloneDeep(
        dist.budget.find((newBud) => newBud.year === year),
      ) as IBudget;
      const oldBudgetIndex = lineItem.budget.findIndex((bud) => bud.year === year);
      // add years from distribution years
      if (oldBudgetIndex < 0) {
        spendTypeKeys
          .filter((key) => key !== action.distribution.field)
          .forEach((key) => {
            newBudgetPerYear[key] = { ...defaultMonthlyData };
          });
        lineItem.budget.push(newBudgetPerYear);
      } else {
        const budgetTypeKey = action.distribution.field;
        lineItem.budget[oldBudgetIndex][budgetTypeKey] = newBudgetPerYear[budgetTypeKey];
      }
    });

    // return line item without losing previous years with values but also new distribution and years from request
    return lineItem;
  }

  hasNonZeroMonth(action: { distribution: ISpendDistribution }, budget: IBudget): boolean {
    let hasValues = false;
    spendTypeKeys
      .filter((key): boolean => key !== action.distribution.field)
      .forEach((key): void => {
        if (!lodash.isEqual(budget[key], defaultMonthlyData) && !hasValues) {
          hasValues = true;
        }
      });
    return hasValues;
  }

  updateDistManual(lineItem: ILineItem, action: { distribution: ISpendDistribution }) {
    // because we had no request we need to add/remove years from budget if start_date/duration changed
    const FYStart = this.fiscalService.fiscalYearStart - 1;
    const forecastStart = moment(lineItem.forecast_start_date);
    const budgetStart = moment(lineItem.start_date);
    const startDate = budgetStart.isBefore(forecastStart)
      ? budgetStart.clone()
      : forecastStart.clone();

    const budgetStartFY = budgetStart.clone();
    const forecastStartFY = forecastStart.clone();
    if (FYStart) {
      budgetStartFY.add(12 - FYStart, 'months');
      forecastStartFY.add(12 - FYStart, 'months');
    }
    const budgetEndFY = budgetStartFY.clone().add(lineItem.duration - 1, 'months');
    const forecastEndFY = forecastStartFY.clone().add(lineItem.forecast_duration - 1, 'months');

    // const duration = action.distribution.duration;
    const startFY = budgetStartFY.isBefore(forecastStartFY)
      ? budgetStartFY.clone()
      : forecastStartFY.clone();
    const endFY = budgetEndFY.isAfter(forecastEndFY) ? budgetEndFY.clone() : forecastEndFY.clone();

    // remove years not in duration
    // lineItem.budget = lineItem.budget.filter(
    //   (bud) => bud.year >= startFY.year() && bud.year <= endFY.year()
    // );

    // add years in duration if not present yet
    for (let year = startFY.year(); year <= endFY.year(); year++) {
      if (!lineItem.budget.find((bud) => bud.year === year)) {
        const newYearlyBudget = {
          year,
          monthly_budget: { ...defaultMonthlyData },
          monthly_actuals: { ...defaultMonthlyData },
          monthly_forecast: { ...defaultMonthlyData },
        };
        lineItem.budget.push(newYearlyBudget);
      }
    }
    return lineItem;
  }
}
