import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Project } from '../../../pages/webapp/projects/projects.interface';
import { PROJECT_STATUS_ID } from '../../constants/project.constants';
import { FiscalService } from '../../../services/fiscal.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { IProperty } from '../../../store/properties/properties.interfaces';
import { ReportingService } from '../../../services/reporting.service';
import { IReportingData, REPORTING_ALL_STATUSES } from '../../reporting/reporting.constants';
import { NotificationsService } from '../../../services/notifications.service';
import { MatButton } from '@angular/material/button';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { ChecklistItemComponent } from '../../inputs/checklist-item/checklist-item.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

export type FormType = {
  start_year?: FormControl<number>;
  end_year?: FormControl<number>;
  invoice_log_start_date?: FormControl<string>;
  invoice_log_end_date?: FormControl<string>;
  project_status_ids: FormControl<string[]>;
  include_cash_flow: FormControl<boolean>;
  include_property_summary: FormControl<boolean>;
  include_project_summary: FormControl<boolean>;
  include_project_spend_detail: FormControl<boolean>;
  include_project_schedule: FormControl<boolean>;
  include_project_commitments: FormControl<boolean>;
  include_contract_detail: FormControl<boolean>;
  include_change_order_detail: FormControl<boolean>;
  include_invoice_detail: FormControl<boolean>;
  include_direct_cost_detail: FormControl<boolean>;
  include_project_updates: FormControl<boolean>;
  include_invoice_log: FormControl<boolean>;
  project_ids: FormControl<string[]>;
  property_ids: FormControl<string[]>;
  tmp_sp_ids?: FormControl<number[]>;
  sp_ids?: FormControl<number[]>;
};

@Component({
  selector: 'app-bar-reporting',
  templateUrl: './reporting-bar.component.html',
  styleUrls: ['./reporting-bar.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    NgIf,
    BackButtonBoxComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    NgScrollbar,
    ChecklistItemComponent,
    FloatingInputComponent,
    NgFor,
    MatButton,
  ],
})
export class ReportingBarComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly PROPERTY_PAGE: number = 1;
  readonly PROJECTS_PAGE: number = 2;
  readonly MAX_PAGE: number = 2;
  readonly ALL = 'all';
  PROJECT_STATUS_ID = PROJECT_STATUS_ID;
  allStatuses = [...REPORTING_ALL_STATUSES];

  reportFields = new FormGroup<FormType>({
    start_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    end_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    project_status_ids: new FormControl<string[]>(
      [this.ALL, ...this.allStatuses],
      [Validators.required],
    ),

    include_property_summary: new FormControl<boolean>(true),
    include_project_summary: new FormControl<boolean>(true),
    include_project_spend_detail: new FormControl<boolean>(true),
    include_cash_flow: new FormControl<boolean>(false),
    include_project_schedule: new FormControl<boolean>({ value: false, disabled: true }),
    include_project_commitments: new FormControl<boolean>(false),
    include_contract_detail: new FormControl<boolean>(false),
    include_change_order_detail: new FormControl<boolean>(false),
    include_invoice_detail: new FormControl<boolean>(false),
    include_direct_cost_detail: new FormControl<boolean>(false),
    include_project_updates: new FormControl<boolean>(false),
    include_invoice_log: new FormControl<boolean>(false),

    project_ids: new FormControl<string[]>([], [Validators.required]),
    property_ids: new FormControl<string[]>([], [Validators.required]),
  });

  years: Array<{ key: number; value: number }> = [];
  projects: Project[] = [];
  projectSearch: string;
  properties: IProperty[] = [];
  propertiesToList: IProperty[] = [];
  propertySearch: string;

  submitDisabled = false;
  errors: string[] = [null, null, null];
  destroyed$ = new Subject();

  isLoading: boolean = false;
  currentPage = 0;

  /**
   * filter projects after property, year and search term
   */
  get filteredProjects(): Project[] {
    const startYear = this.reportFields.get('start_year').value;
    const endYear = this.reportFields.get('end_year').value;
    const propertyIds = this.reportFields.get('property_ids').value;
    const statuses = this.reportFields.get('project_status_ids').value;

    const filtered = this.projects
      .filter((project) => {
        // filter by property selection
        return propertyIds.includes(this.ALL) || propertyIds.includes(project.property_id + '');
      })
      .filter((project) => {
        // filter by status selection
        return statuses.includes(project.project_status.id + '');
      })
      .filter(
        // filter by search term
        (project) =>
          !this.projectSearch ||
          project?.title?.toLowerCase()?.includes(this.projectSearch?.toLowerCase()) ||
          project?.project_property?.formatted_address
            ?.toLowerCase()
            ?.includes(this.projectSearch?.toLowerCase()),
      )
      .filter((project) => {
        // filter by year selection
        const years = project?.budget_years;
        for (const year of years) {
          if (year >= startYear && year <= endYear) {
            // if there is one year that's in the selected period, show the project
            return true;
          }
        }
        return false;
      });
    return filtered;
  }

  get nextDisabled() {
    return !!this.errors?.[this.currentPage] ?? true;
  }

  get errorMessage() {
    return this.errors?.[this.currentPage] ?? '';
  }
  @Input() set data(value: { properties: any; projects: Project[] }) {
    if (value) {
      if (value.properties) {
        this.properties = this.sortProperties(Array(...value.properties));
        this.propertiesToList = [...this.properties];
      }
      if (value.projects) {
        const projects = value.projects.filter(
          (proj) =>
            proj.status !== PROJECT_STATUS_ID.DELETED && proj.status !== PROJECT_STATUS_ID.ARCHIVED,
        );
        this.setYears(projects);
        this.projects = this.sortProjects([...projects]);
      }
    }
  }

  protected fiscalService = inject(FiscalService);
  protected interactionBar = inject(InteractionBarStateService);
  protected reportingService = inject(ReportingService);
  protected notif = inject(NotificationsService);

  constructor() {}

  ngOnInit(): void {
    this.fiscalService.fiscalYear$.pipe(takeUntil(this.destroyed$)).subscribe((fy) => {
      this.reportFields.get('start_year').setValue(fy);
      this.reportFields.get('end_year').setValue(fy);
    });
  }

  ngAfterViewInit(): void {
    this.reportFields.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.checkOverallValidation();
    });
  }

  checkStatusPageValidation() {
    this.errors[0] = null;
    const propertySummary = this.reportFields.get('include_property_summary').value;
    const projectSummary = this.reportFields.get('include_project_summary').value;
    const projectSpend = this.reportFields.get('include_project_spend_detail').value;
    const cashflow = this.reportFields.get('include_cash_flow').value;
    const schedule = this.reportFields.get('include_project_schedule').value;
    const commitments = this.reportFields.get('include_project_commitments').value;
    if (
      !(propertySummary || projectSummary || projectSpend || cashflow || schedule || commitments)
    ) {
      this.errors[0] = 'At least one report type is required.';
    }

    const statuses = this.reportFields.get('project_status_ids').value;
    if (statuses.length === 0) {
      this.errors[0] = 'At least one project status is required.';
    }

    return !this.errors[0];
  }

  checkPropertyValidation() {
    this.errors[this.PROPERTY_PAGE] = null;
    const propertyIds = this.reportFields.get('property_ids').value;
    if (propertyIds.length === 0) {
      this.errors[this.PROPERTY_PAGE] = 'At least one property is required.';
    }
    return !this.errors[this.PROPERTY_PAGE];
  }

  checkProjectValidation() {
    this.errors[this.PROJECTS_PAGE] = null;
    const projectIds = this.reportFields.get('project_ids').value;
    if (projectIds.length === 0) {
      this.errors[this.PROJECTS_PAGE] = 'At least one project is required.';
    }
    return !this.errors[this.PROJECTS_PAGE];
  }

  checkOverallValidation() {
    let isValid = true; // if any call returns false, this will be false
    isValid = isValid && this.checkStatusPageValidation();
    isValid = isValid && this.checkPropertyValidation();
    isValid = isValid && this.checkProjectValidation();
    this.submitDisabled = !isValid;
  }

  sortProperties = (properties: any[]) => {
    return properties.sort((a, b) => {
      return a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase());
    });
  };

  sortProjects = (projects: any[]) => {
    return projects.sort((a, b) => {
      return a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase());
    });
  };

  decrementPage() {
    if (this.currentPage === 0) {
      return;
    }
    this.currentPage--;
  }
  incrementPage() {
    if (this.currentPage === this.MAX_PAGE) {
      return;
    }

    let canGoTrough = false;
    if (this.currentPage === 0) {
      canGoTrough = this.checkStatusPageValidation();
    } else if (this.currentPage === 1) {
      canGoTrough = this.checkPropertyValidation();
    } else if (this.currentPage === 2) {
      canGoTrough = this.checkProjectValidation();
    }

    if (canGoTrough) {
      this.currentPage++;
      this.checkOverallValidation();
    }

    if (this.currentPage === this.PROJECTS_PAGE) {
      this.checkAllProjectsSelected();
    }
  }

  async submit() {
    if (this.reportFields.valid && !this.submitDisabled) {
      const data = this.convertFormToPostData();

      try {
        const response = await this.reportingService.generateReportOnServer(data);
        this.notif.showSuccess(
          response?.message ||
            "The report is being generated. You will get notified when it's ready.",
        );
        this.interactionBar.close();
      } catch (err) {
        console.warn('reporting error', err);
        this.notif.showError(err?.error?.message ?? 'An error happened.');
      }
    }
  }

  convertFormToPostData(): IReportingData {
    const stringArrayToNumberArray = (ids: string[]) => ids.map((id) => parseInt(id, 10));
    const formData = this.reportFields.getRawValue();
    const data: IReportingData = {
      ...formData,
      property_ids: [], // will be filled below
      project_ids: [], // will be filled below
      project_status_ids: [], // will be filled below
      include_project_cash_flow: formData.include_cash_flow,
      include_property_cash_flow: formData.include_cash_flow,
      include_property_projects: formData.include_project_summary,
      include_property_budget_lines: formData.include_project_summary,
      include_property_budget_tags: formData.include_project_summary,
      include_contract_detail: formData.include_project_commitments,
      include_change_order_detail: formData.include_project_commitments,
      include_invoice_log: formData.include_invoice_log,
    };

    if (formData.property_ids.includes(this.ALL)) {
      data.property_ids = this.ALL;
    } else {
      data.property_ids = stringArrayToNumberArray(formData.property_ids);
    }

    if (formData.project_ids.includes(this.ALL)) {
      data.project_ids = this.ALL;
    } else {
      data.project_ids = stringArrayToNumberArray(formData.project_ids);
    }

    if (formData.project_status_ids.includes(this.ALL)) {
      data.project_status_ids = stringArrayToNumberArray(this.allStatuses);
    } else {
      data.project_status_ids = stringArrayToNumberArray(formData.project_status_ids);
    }
    return data;
  }

  setYears(projects: Project[]) {
    const years = new Set();
    projects.forEach((project) => {
      if (project.budget_years) {
        project.budget_years.forEach((year) => {
          years.add(year);
        });
      }
    });

    const yearArray: number[] = Array.from(years).sort((a, b) => (a < b ? -1 : 1)) as number[];
    this.years = yearArray.map((year) => ({ key: year, value: year }));
    const currentValueIncluded = this.years
      .map((entry) => entry.key)
      .includes(this.reportFields.get('start_year').value);
    if (this.years.length > 0 && !currentValueIncluded) {
      // if the default value is not in the list, set the first year as default
      this.reportFields
        .get('start_year')
        .setValue(this.years?.[0]?.key ?? new Date().getFullYear());
      this.reportFields.get('end_year').setValue(this.years?.[0]?.key ?? new Date().getFullYear());
    }
  }

  yearsChanged(fieldChanged: string) {
    const endYear = this.reportFields.get('end_year').value;
    const startYear = this.reportFields.get('start_year').value;

    if (endYear < startYear) {
      // end year must be greater than start year
      // so change the opposite field
      if (fieldChanged === 'start_year') {
        this.reportFields.get('end_year').setValue(startYear);
      } else if (fieldChanged === 'end_year') {
        this.reportFields.get('start_year').setValue(endYear);
      }
    }
  }

  areAllCommitmentsToggled() {
    return !!(
      this.reportFields.get('include_contract_detail')?.value &&
      this.reportFields.get('include_change_order_detail')?.value &&
      this.reportFields.get('include_invoice_detail')?.value &&
      this.reportFields.get('include_direct_cost_detail')?.value
    );
  }

  /**
   * Toggle a field in the report form
   * NOTE: DO NOT USE WITH: include_project_commitments, it is a special field, it toggles all commitment fields
   * @param fieldName - the name of the field
   *
   */
  toggleField(fieldName: string) {
    if (!this.reportFields.get(fieldName) || fieldName === 'include_project_commitments') {
      return;
    }
    const currentValue = this.reportFields.get(fieldName)?.value;
    this.reportFields.get(fieldName).setValue(!currentValue);

    // set main field in function of other commitment related fields
    this.reportFields.get('include_project_commitments').setValue(this.areAllCommitmentsToggled());
  }

  /**
   * Set specific field by given value
   */
  toggleFieldByValue(fieldName: string, value: boolean) {
    this.reportFields.get(fieldName).setValue(value);
  }

  /**
   * Toggle all fields related to commitments based on the include_project_commitments field
   * Set all subfields to the same value as the main field
   */
  toggleAllCommitments() {
    const currentValue = this.reportFields.get('include_project_commitments').value;
    this.reportFields.get('include_project_commitments').setValue(!currentValue);
    this.toggleFieldByValue('include_contract_detail', !currentValue);
    this.toggleFieldByValue('include_change_order_detail', !currentValue);
    this.toggleFieldByValue('include_invoice_detail', !currentValue);
    this.toggleFieldByValue('include_direct_cost_detail', !currentValue);
  }

  /**
   * Toggles one of the report's field. It handles the 'all' option too.
   * It is a general method to prevent code duplication.
   *
   * @param fieldName - the name of the field in the form to toggle
   * @param value - the value (id) to add/remove, it can be ALL too
   * @param allValues - the list of all possible values for the field
   * @private
   */
  private toggleFieldWithAll(fieldName: string, value: string, allValues: string[]) {
    if (!this.reportFields.get(fieldName)) {
      return;
    }

    const currentValue = this.reportFields.get(fieldName).value;
    if (value === this.ALL) {
      if (currentValue.includes(this.ALL)) {
        this.reportFields.get(fieldName).setValue([]);
      } else {
        this.reportFields.get(fieldName).setValue([...allValues, this.ALL]);
      }
      return;
    }

    if (currentValue.includes(value)) {
      const newValue = currentValue.filter((current) => current !== value && current !== this.ALL);
      this.reportFields.get(fieldName).setValue(newValue);
    } else {
      const newValue = [...currentValue, value];
      if (newValue.length === allValues.length) {
        newValue.push(this.ALL);
      }
      this.reportFields.get(fieldName).setValue(newValue);
    }
  }

  toggleStatus(statusValue: string) {
    this.toggleFieldWithAll('project_status_ids', statusValue, this.allStatuses);
  }

  toggleProperty(propertyId: string) {
    if (propertyId === this.ALL) {
      this.propertiesToList = [...this.properties];
      this.propertySearch = '';
    }

    this.toggleFieldWithAll(
      'property_ids',
      propertyId,
      this.properties.map((prop) => prop.id + ''),
    );
  }

  toggleProject(projectId: string) {
    if (projectId === this.ALL) {
      this.projectSearch = '';
    }
    this.toggleFieldWithAll(
      'project_ids',
      projectId,
      this.filteredProjects.map((project) => project.id + ''),
    );
  }
  propertySearchChanged(searchTerm: string) {
    if (!searchTerm) {
      this.propertiesToList = [...this.properties];
    }
    this.propertiesToList = this.properties.filter(
      (prop) =>
        prop?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        prop?.formatted_address?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    );
  }

  /**
   * check if the ALL option should be selected or not
   * this is needed if user selects all projects, goes back, changes the filter and then goes forward again
   */
  checkAllProjectsSelected() {
    const selectedProjects = this.reportFields.get('project_ids').value;
    const allProjectIds = this.filteredProjects.map((project) => project.id + '');
    if (selectedProjects.length === allProjectIds.length && allProjectIds.length > 0) {
      // add ALL option if all projects are selected
      this.reportFields.get('project_ids').setValue([...allProjectIds, this.ALL]);
    } else {
      // add ALL otherwise
      this.reportFields
        .get('project_ids')
        .setValue(selectedProjects.filter((id) => id !== this.ALL));
    }
  }

  onPropertyIconClick() {
    if (this.propertySearch) {
      this.propertySearch = '';
      this.propertySearchChanged(this.propertySearch);
    }
  }

  onProjectIconClick() {
    if (this.projectSearch) {
      this.projectSearch = '';
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
