import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrowButtonBoxComponent } from '../../../dropdown-button-box/arrow-button-box.component';
import { GeneralSidebarHeaderComponent } from '../../../general-sidebar-header/general-sidebar-header.component';
import { NgClass, NgIf } from '@angular/common';
import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { BorderedDropdownIconComponent } from '../../../bordered-dropdown-icon/bordered-dropdown-icon.component';

@Component({
  selector: 'app-commitment-sidebar-title',
  templateUrl: './commitment-sidebar-title.component.html',
  styleUrls: ['./commitment-sidebar-title.component.scss'],
  standalone: true,
  imports: [
    ArrowButtonBoxComponent,
    GeneralSidebarHeaderComponent,
    NgIf,
    CommitmentEntryTextComponent,
    NgClass,
    BorderedDropdownIconComponent,
  ],
})
export class CommitmentSidebarTitleComponent {
  @Input() title: string;
  @Input() showBackButton = false;
  @Input() showHelpButton = false;
  @Output() goBack = new EventEmitter<void>();
  @Output() needHelpClicked = new EventEmitter<boolean>();
  isHelpShown = false;

  onBack() {
    this.goBack.emit();
  }

  onNeedHelpClick() {
    this.isHelpShown = !this.isHelpShown;
    this.needHelpClicked.emit(this.isHelpShown);
  }
}
