import { PROJECT_STATUS_KEY } from '../../../framework/constants/project.constants';
import { User } from '../../../framework/constants/user.constants';
import { UserDriveFile } from '../../../framework/constants/documents.constants';

export class ProjectsInterface {
  tabOneLabel: string;
  tabTwoLabel: string;
  moneyIndicatorOneTitle: string;
  moneyIndicatorTwoTitle: string;
  tabThreeLabel: string;
  tableFields: Array<any>;
}

export interface Type {
  id: number;
  name: string;
}

export type ProjectStatusKey =
  | PROJECT_STATUS_KEY.BIDDING
  | PROJECT_STATUS_KEY.AWARDED
  | PROJECT_STATUS_KEY.CANCELED
  | PROJECT_STATUS_KEY.PLANNED
  | PROJECT_STATUS_KEY.DELETED
  | PROJECT_STATUS_KEY.IN_PROGRESS
  | PROJECT_STATUS_KEY.COMPLETED
  | PROJECT_STATUS_KEY.ARCHIVED;

export interface ProjectStatus {
  id: number | string; // backend 'logic': it is string for contractors, it is number for managers
  name: string;
  key?: ProjectStatusKey; // note: only manager has key
}

export interface ProjectType {
  id: number;
  name: string;
}

export interface ProjectProperty {
  id: number;
  user_id: number;
  type_id: number;
  type_unit_value: number;
  name: string;
  tax_id?: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  status: number;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  formatted_address: string;
}

export interface ProjectVisit {
  id: number;
  project_id: number;
  day: string;
  interval: string;
  start_date_time: string;
  end_date_time: string;
}

export interface ProjectBid {
  id: number;
  user_id: number;
  project_id: number;
  user_drive_file_id: number;
  manager_id: number;
  contractor_id: number;
  value: number;
  description: string;
  message: string;
  enable: number;
  created_at: string;
  updated_at: string;
  status_message: string;
  user_drive_file?: UserDriveFile;
  status?: Status;
}

export interface Status {
  id: number;
  name: string;
}

export interface ProjectInvites {
  id: number;
  user_id: number;
  project_id: number;
  contractor_id: number;
  created_at: string;
  updated_at: string;
}

export interface ProjectDescription {
  description: string;
  id: number;
  project_id: number;
  updated_at: string;
  created_at: string;
  name: string;
  short_description: string;
}

export interface ContractorCommitment {
  added_at: string;
  approval_status: 'approved' | 'pending' | null;
  id: number;
  paid_date: string;
  sum: number;
  title: string;
  type: 'invoices' | 'change_orders' | 'contracts';
}

export interface ISimpleProject {
  id: number;
  title: string;
  start_date?: string;
  property_id?: number;
}

export interface IProjectFilters {
  status_filter: ProjectStatusKey[];
}

export interface Project {
  id: number;
  user_id: number;
  title: string;
  start_date: string;
  property_id: number;
  estimate_id?: any;
  budget_id: number;
  expense_id?: any;
  priority: boolean;
  spend_category: number;
  status: number | string; // project.status field can be different based on user type.
  type: number;
  approved: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  bids: number;
  bids_total: number;
  commitments: ContractorCommitment[];
  user: User;
  project_description: ProjectDescription;
  project_status: ProjectStatus;
  project_type: ProjectType;
  project_property: ProjectProperty;
  project_visit: ProjectVisit[];
  project_visit_schedule?: any;
  project_bids: ProjectBid[];
  project_invites: ProjectInvites;
  budget_years: number[];
  budget_template_id?: number;
  can_edit_template: boolean;
  budget_template?: {
    created_at: string;
    deleted_at: string | null;
    id: number;
    name: string;
    team_id: number;
    updated_at: string;
    user_id: number;
  };
  team_id: number;
  tags: string[];
}

export interface ProjectTableDataContractor extends Project {
  contracts_approved: boolean;
  company_name: string;
  statusId: number;
  proposal: number;
  contract_sum: number;
  invoice_sum: number;
  complete: number;
}

export enum PROJECTS_TABLE_VIEW {
  MANAGER,
  CONTRACTOR,
}

export interface GeneralFormModel {
  status?: number;
  title?: string;
  start_date?: string;
  property_id?: number;
  tags?: any[];
}
