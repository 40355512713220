@if (isLoading$ | async) {
  <app-page-loading></app-page-loading>
} @else {
  <div class="title">
    <app-bordered-dropdown-icon
      (click)="switchViewToManageProject()"
      isClosed="false"
      [useAsBackButton]="true"
    ></app-bordered-dropdown-icon>
    <span class="flex-1">Anticipated Cost</span>
    <span (click)="clearAllCosts()" class="text-color_secondary cursor-pointer">Clear All Costs</span>
  </div>

  <ng-scrollbar track="vertical" appearance="compact" class="std-scrollbar">
    <form name="anticipatedCostsForm" role="form" #anticipatedCostsForm="ngForm" class="h-full flex flex-col">
      @for (forecast_modification of forecast_modifications; track forecast_modification.item_id; let index = $index) {
        <app-general-sidebar-header section="true">
          <div class="flex" section>
            <div class="flex-1 uppercase text-base font-bold">Cost Group {{ index + 1 }}</div>
            <span
              (click)="removeBudgetLine(index)"
              class="flex text-color_red_negative text-2xl pr-0.5 icon-trash-x cursor-pointer"
            ></span>
          </div>
        </app-general-sidebar-header>

        <div class="py-2 px-7 mx-0.5">
          <app-dropdown
            optionLabel="name"
            optionValue="id"
            [name]="'BudgetLine_' + forecast_modification.item_id"
            label="Budget Line"
            placeholder="Budget Line"
            [options]="lineItemOptions"
            [(model)]="forecast_modification.item_id"
            required
          >
            <ng-template #selectedContent let-option>
              <div class="dropdown-item">
                {{ option.name | titlecase }}
              </div>
            </ng-template>
            <ng-template #optionContent let-option>
              <div
                class="dropdown-item"
                [ngClass]="{ 'disabled color-disabled pointer-events-none': allIds.includes(option.id) }"
              >
                {{ option.name | titlecase }}
              </div>
            </ng-template>
          </app-dropdown>

          @for (item of forecast_modification.modifications; track item.id; let itemIndex = $index) {
            <div class="flex gap-2.5 pr-2.5 items-center align-baseline">
              <div class="grid grid-cols-2 gap-2.5 w-full">
                <app-floating-input
                  [name]="'note_' + item.id"
                  [(model)]="item.note"
                  [label]="'Cost Description ' + (itemIndex + 1 | number)"
                  [placeholder]="'Cost Description ' + (itemIndex + 1 | number)"
                  required
                ></app-floating-input>

                <app-floating-input
                  [name]="'value_' + item.id"
                  [(model)]="item.value"
                  label="Dollar Amount"
                  placeholder="Dollar Amount"
                  [formatMoney]="true"
                  [ngClass]="{ 'negative-color': item.value < 0 }"
                  required
                ></app-floating-input>
              </div>
              <div
                [ngClass]="{
                  'p-disabled': forecast_modification?.modifications?.length === 1
                }"
                (click)="deleteItem(index, itemIndex)"
                class="text-color_red_light icon-minus-box cursor-pointer pt-5 pb-0.5"
              ></div>
              <div
                (click)="insertItem(index, itemIndex)"
                class="text-color_secondary icon-plus-box cursor-pointer pt-5 pb-0.5"
              ></div>
            </div>
          }
        </div>
      }

      @if (!forecast_modifications?.length) {
        <div class="flex flex-col items-center justify-center h-full py-2">
          <span class="text-shade_2 text-size_sm">No anticipated costs</span>
        </div>
      }

      <div class="px-7 mx-0.5">
        <app-simple-button
          size="large"
          [wrapperClass]="{
            'w-full bg-white border rounded border-solid border-color_secondary text-color_secondary text-sm font-semibold': true
          }"
          class="flex mt-2.5"
          (click)="addBudgetLine()"
        >
          Add Cost Group
        </app-simple-button>
      </div>
    </form>
  </ng-scrollbar>

  <div class="flex items-center py-4 px-8 gap-10">
    <div class="text-shade_1 font-bold flex justify-between w-full">
      <span> Total </span>
      <span [ngClass]="{ negative: totals < 0 }">{{ totals | money: 'positiveComa' }}</span>
    </div>
    <app-simple-button
      [wrapperClass]="{
        'rounded text-sm font-semibold bg-color_accent': true
      }"
      class="flex ml-auto"
      (click)="save()"
    >
      Save
    </app-simple-button>
  </div>
}
