import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { NgScrollbar } from 'ngx-scrollbar';

// todo: check if used otherwise consider removing it (might be useful..)
/**
 * A multi-select dropdown component.
 * Any types are used as it should be generic.
 * To be extended when needed. (Show/Hide all checkbox, etc.)
 */
@Component({
  selector: 'app-multi-dropdown-select',
  templateUrl: './multi-dropdown-select.component.html',
  styleUrls: ['./multi-dropdown-select.component.scss'],
  standalone: true,
  imports: [NgScrollbar, CheckboxModule, FormsModule, NgFor],
})
export class MultiDropdownSelectComponent {
  // key to display in the dropdown from options input
  @Input() key = 'name';
  // list data of the dropdown to be displayed
  private _options: { name: string; id: number }[] | any[];
  @Input() set options(data: ({ name: string; id: number } | any)[]) {
    this._options = data;
    this._selectedIds = this._options.map((option: any) => option.id);
    this._selectedData = this._options.map((option: any) => option);
  }
  get options() {
    return this._options;
  }

  // cached whole selected data from options list
  @Output() selectedOptions = new EventEmitter<any>();
  // cached selected ids
  @Output() selectedIds = new EventEmitter<number[]>();

  _selectedData: any[] = [];
  _selectedIds: number[] = [];
  areAllSelected = true;
  trackByFn = (_: number, item: any) => item.id;

  /**
   * Update the selected ids and data.
   * Filter _selectedData based on selected ids (checked checkboxes).
   * Trigger onChange() to emit the selected options.
   */

  async onChange() {
    this._selectedData = this.options.filter((option) =>
      this._selectedIds.find((id) => id === option.id),
    );
    this.areAllSelected = this._selectedIds.length === this.options.length;
    this.emitSelectedOptions();
  }

  /**
   * Emit the selected options as whole data and ids.
   */
  emitSelectedOptions() {
    this.selectedOptions.emit(this._selectedData);
    this.selectedIds.emit(this._selectedIds);
  }

  /**
   * Check all or uncheck all depending on all selected checkbox.
   * Trigger onChange() to emit the selected options.
   */
  async checkAll() {
    this.areAllSelected = !this.areAllSelected;
    if (this.areAllSelected) {
      this._selectedIds = this.options.map((option) => option.id);
    } else {
      this._selectedIds = [];
    }
    await this.onChange();
  }
}
