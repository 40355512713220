import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import { NotificationsService } from '../../services/notifications.service';
import { ISpreadLineItem, SpreadSheetInputData } from '../../store/spend/spend.interfaces';
import { getSelectableYears } from '../../store/spend/spend.selectors';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { Spreadsheet, SpreadsheetModule } from '@syncfusion/ej2-angular-spreadsheet';
import { take } from 'rxjs/operators';
import { SHEET_SAVE_URL } from '../constants/syncfusion.constants';
import { updateLineItem } from '../../store/spend/spend.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { saveAs } from 'file-saver';
import { SPEND_TYPES } from '../constants/budget.constants';

@Component({
  selector: 'app-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss'],
  standalone: true,
  imports: [SpreadsheetModule, NgClass, NgIf, NgForOf],
})
export class SpreadsheetComponent {
  @ViewChild('spreadsheet') spreadsheet: Spreadsheet;
  // spreadsheet: dhx.Spreadsheet;
  _months: string[] = [];
  @Input() set months(value) {
    this._months = value.map((monthName) => monthName.toUpperCase());
  }
  get months() {
    return this._months;
  }

  @Input() selectedBudgetType: SPEND_TYPES = SPEND_TYPES.BUDGET;

  @Input() set data(data: SpreadSheetInputData) {
    console.log(data);
    if (!data) {
      return;
    }
    this.loading = false;
    this.items = data.items as unknown as ISpreadLineItem[];
    console.log(this.items);
    this.isEditable = data.isEditable;
    this.selectedYear = data.selectedYear;
    this.possibleYears$.pipe(take(1)).subscribe((possibleYears) => {
      this.possibleYears = possibleYears;
      const sheetIndex = this.possibleYears?.findIndex((year) => year === this.selectedYear);
      this.activeSheetIndex = sheetIndex < 0 ? 0 : sheetIndex;
      this.calculateFormulas();
      this.isSheetDataLoaded = true;
      this.cd.detectChanges();
    });
  }

  // insert empty rows after line items and before totals
  emptyRowsBeforeTotal = new Array(3).fill({});
  totalCellFormulas = [];

  // 15 columns are in use (12 months + 1 for names + 2 for totals
  // this array contains the column names (15 letters starting from 'A'): ['A', 'B', 'C', ...]
  columnNames = new Array(15).fill(0).map((val, ind) => String.fromCharCode(65 + ind));

  items: ISpreadLineItem[] = [];
  isEditable: any[];
  selectedYear: number;
  headerOffset = 2; // this value should be subtracted from spreadsheet line index to get the lineItem index
  isSheetDataLoaded = false;

  editable;
  loading = true;
  isOpen = false;

  possibleYears$ = this.store.select(getSelectableYears);
  possibleYears;
  activeSheetIndex = 0; // the sheet(year) is selected (it is set based on selectedYear)

  SHEET_SAVE_URL = SHEET_SAVE_URL; // spreadsheet needs this url for save
  constructor(
    public user: CurrentUserService,
    protected notif: NotificationsService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {}

  /**
   * this function determines the sum formulas for year total and budget total fields
   * budget total references multiple sheets (every year)
   * todo: it contains hardcoded values (like D column) - these should be removed
   */
  calculateFormulas() {
    this.items.forEach((item, index) => {
      item.yearTotalFormula = `=SUM(D${index + this.headerOffset}:O${index + this.headerOffset})`;
      let formula = '';
      this.possibleYears.forEach((year, yearIndex) => {
        formula += `'${year}'!C${index + this.headerOffset}`;
        if (yearIndex < this.possibleYears.length - 1) {
          formula += ',';
        }
      });
      item.budgetTotalFormula = `=SUM(${formula})`;
    });
    this.setTotals();
  }

  afterSheetCreated() {
    this.setFormatting();
    this.setLocks();
    this.protectAllSheets();
  }

  setFormatting() {
    this.possibleYears.forEach((year) => {
      const lineCount = this.items.length + this.headerOffset + 4;
      this.spreadsheet.cellFormat(
        {
          fontFamily: 'Roboto',
          color: '#091D51',
          fontWeight: 'normal',
          verticalAlign: 'middle',
        },
        `${year}!A1:O${lineCount}`,
      );
      this.spreadsheet.cellFormat({ textAlign: 'center' }, `${year}!B1:O${lineCount}`);
      // header styling
      this.spreadsheet.cellFormat(
        { backgroundColor: '#F6F8FF', fontWeight: 'bold' },
        `${year}!A1:O${1}`,
      );
      // other cells styling than header
      this.spreadsheet.cellFormat({ backgroundColor: '#F6F8FF' }, `${year}!A2:O${lineCount}`);
      this.spreadsheet.cellFormat(
        { backgroundColor: '#FFFFFF' },
        `${year}!D2:O${this.items.length + this.headerOffset - 1}`,
      );
    });
  }

  protectAllSheets() {
    this.possibleYears.forEach((year) => {
      this.spreadsheet.protectSheet(year + '');
    });
  }

  unprotectAlSheets() {
    this.possibleYears.forEach((year) => {
      this.spreadsheet.unprotectSheet(year + '');
    });
  }

  /**
   * set cells which cannot be edited
   * Note: will not work without e-sheet [protectSettings]="{ selectCells: true }
   */
  setLocks() {
    this.isEditable.forEach((years, yearIndex) => {
      Object.entries(years).forEach(([year, value]: [string, boolean[]]) => {
        value.forEach((isMonthLocked, monthIndex) => {
          const monthStartColumnIndex = 3;
          const cell = this.columnNames[monthIndex + monthStartColumnIndex];
          const cellIndex = yearIndex + this.headerOffset;
          if (!isMonthLocked) {
            this.spreadsheet.cellFormat(
              { backgroundColor: '#F6F8FF' },
              `${year}!${cell}${cellIndex}`,
            );
          }
          // todo: it should be visible which cells are "manual" distribution to be edited and other cells like in the inputs app view
          // todo: currently isEditable contains only editable fields without the "only manual fields can be modified" change
          // this.spreadsheet.lockCells(`${year}!${cell}${cellIndex}`, !isMonthLocked);
          // lock all cells for now
          this.spreadsheet.lockCells(`${year}!${cell}${cellIndex}`, true);
        });
      });
    });
  }

  /**
   * determine the total formulas for total row for each column
   */
  setTotals() {
    // 12 months columns  + 2 total columns
    const currencyColumns = new Array(12 + 2).fill(0);
    const colOffset = 1; // in the first column we don't calculate totals
    currencyColumns.forEach((col, columnIndex) => {
      const colName = this.columnNames[columnIndex + colOffset];
      this.totalCellFormulas.push(
        `=SUM(${colName}${this.headerOffset}:${colName}${
          this.items.length - 1 + this.headerOffset
        })`,
      );
    });
  }

  getBudgetValue(lineItem: ISpreadLineItem, year, monthIndex) {
    switch (this.selectedBudgetType) {
      case SPEND_TYPES.BUDGET:
        return lineItem.budget.find((bud) => bud.year === year)?.monthly_budget[monthIndex + 1];
      case SPEND_TYPES.ACTUALS:
        return lineItem.budget.find((bud) => bud.year === year)?.monthly_actuals[monthIndex + 1];
      case SPEND_TYPES.FORECAST:
        return lineItem.budget.find((bud) => bud.year === year)?.monthly_forecast[monthIndex + 1];
    }
  }

  /**
   * on every cell change update the store with new budget values.
   * @param event spreadsheet event
   */
  onValueChange(event) {
    const address = event.address as string; // in the format: 2021!D4
    const year = Number.parseInt(address.split('!')[0], 10);
    const cell = address.split('!')[1];
    const line = cell.replace(/[a-zA-Z]/, '');
    const column = cell.replace(/[0-9]/, '');

    const lineItemIndex = Number.parseInt(line, 10) - 2;
    const monthIndex = this.columnNames.findIndex((colName) => colName === column) - 3;
    const newValue = Number.parseFloat(event.value);

    if (lineItemIndex >= 0 && monthIndex >= 0 && !isNaN(newValue) && !isNaN(year)) {
      const lineItem = DeepCopyService.deepCopy(this.items[lineItemIndex]);
      lineItem.budget.find((bud) => bud.year === year).monthly_budget[monthIndex + 1] = newValue;
      this.store.dispatch(updateLineItem({ lineItem }));
    } else {
      console.warn('not going to update spend, some values are not valid.');
    }
  }

  onBeforeSave(args) {
    this.unprotectAlSheets();
    args.isFullPost = false;
    this.notif.showLoading('Converting file...');
  }

  onSaveComplete(data) {
    this.protectAllSheets();
    if (data.status === 'Failure') {
      this.notif.showError('Failed to save');
      return;
    }

    if (data.blobData) {
      saveAs(data.blobData, `${data.fileName}.${data.saveType.toLowerCase()}`);
    }

    this.notif.close();
  }
}
