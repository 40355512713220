import { Component, Input, OnInit } from '@angular/core';
import { DefaultOptionsListComponent } from '../default-options-list/default-options-list.component';
import { ITaskMember } from '../../store/tasks/tasks.interfaces';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-task-users-dropdown',
  templateUrl: './task-users-dropdown.component.html',
  styleUrls: ['./task-users-dropdown.component.scss'],
  standalone: true,
  imports: [NgScrollbar, CommonModule],
})
export class TaskUsersDropdownComponent extends DefaultOptionsListComponent implements OnInit {
  @Input() usersRelated: ITaskMember[];
  @Input() selectedMembers: ITaskMember[];
  // @Output() closeDropdown = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  /**
   * Handling close and open events is done by its associated directive.
   * Component only detects if the user interaction is inside the component or not.
   */
  // @HostListener('document:click')
  // onClick(ev) {
  //   this.closeDropdown.emit(true);
  // }
}
