<div class="taskForm">
  <form #taskForm="ngForm" name="taskForm" role="form">
    <app-custom-input-overlay-dropdown
      #overlay
      (modelChange)="modelHasChanged()"
      [(model)]="CustomTaskTypeToDisplay[model.type]"
      [autoComplete]="false"
      [iconClass]="iconDropdown(overlay.isDropdownShown)"
      [placeholder]="'Custom Field ' + groupIndex"
      [readonly]="true"
      [required]="true"
      class="cursor-pointer"
      name="type"
    >
      <app-custom-tasks-group-dropdown
        (optionSelected)="optionSelected($event); overlay.hideDropdown()"
      ></app-custom-tasks-group-dropdown>
    </app-custom-input-overlay-dropdown>

    <div class="label-value-row">
      <app-floating-input
        (focusout)="modelHasChanged()"
        [(model)]="model.label"
        [required]="true"
        label="Label"
        name="label"
        placeholder="Label"
      ></app-floating-input>

      <ng-container *ngIf="model.type === CUSTOM_TASK_FIELD_TYPES.TEXT">
        <app-floating-input
          (focusout)="modelHasChanged()"
          [(model)]="model.value"
          [required]="true"
          label="Value"
          name="value"
          placeholder="Value"
        ></app-floating-input>
      </ng-container>
      <ng-container *ngIf="model.type === CUSTOM_TASK_FIELD_TYPES.DATE">
        <app-input-calendar
          (dateChanged)="dateChanged($event)"
          [date]="model.value"
          [required]="true"
          class="cursor-pointer"
          name="date"
          placeholder="Date"
        ></app-input-calendar>
      </ng-container>
      <ng-container *ngIf="model.type === CUSTOM_TASK_FIELD_TYPES.QUESTION">
        <app-dropdown
          (focusout)="modelHasChanged()"
          [(model)]="model.value"
          [options]="allCustomTaskQuestionFieldTypes"
          appendTo="body"
          label="Value"
          name="question"
        >
        </app-dropdown>
      </ng-container>
    </div>
  </form>
</div>
