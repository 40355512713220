import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  IProjectFilters,
  ISimpleProject,
  Project,
  ProjectBid,
  ProjectStatus,
} from '../pages/webapp/projects/projects.interface';
import {
  BUDGET_TEMPLATES,
  PROJECTS_SIMPLE,
  REST_ALL_CASHFLOW,
  REST_PROJECT_BID,
  REST_PROJECT_BUDGET,
  REST_PROJECT_INVITE,
  REST_PROJECT_STATS,
  REST_PROJECT_STATUS,
  REST_PROJECT_STATUS_TYPES,
  REST_PROJECT_TAGS,
  REST_PROJECTS,
  REST_PROPERTY,
  REST_PROPERTY_TYPES,
  REST_REPORTS,
  REST_SCHEDULE_VISITS,
  REST_VISITS,
} from '../restApi/RestRoutes';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { RestRequestService } from '../restApi/rest-request.service';
import { IProperty } from '../store/properties/properties.interfaces';
import { ROLLUP_VIEW_TYPES } from '../store/rollups/rollups.interface';
import { CurrentUserService } from './current-user.service';
import { InvitedContractor } from '../framework/constants/user.constants';
import { specialCharReplaceRegexp } from '../framework/constants/spend.constants';
import { PROJECT_STATUS_ID } from '../framework/constants/project.constants';
import { IBudgetTemplate } from '../store/settings/settings.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService extends ErrorHandlerRest {
  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
  ) {
    super(user, router);
  }

  getRollup(
    view: ROLLUP_VIEW_TYPES,
    search: string = '',
    propertyIds: number[] = [],
    tags: string[] = [],
    projectStatusIds: number[] = [],
    projectIds: number[] = [],
    showZeroDollarLines: boolean = true,
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('view', view);
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('showZeroDollarLines', showZeroDollarLines ? '1' : '0');
    propertyIds.forEach((propertyId, index) => {
      queryParams = queryParams.append(`propertyIds[${index}]`, propertyId);
    });
    projectStatusIds.forEach((projectStatusId, index) => {
      queryParams = queryParams.append(`projectStatusIds[${index}]`, projectStatusId);
    });
    projectIds.forEach((projectId, index) => {
      queryParams = queryParams.append(`projectIds[${index}]`, projectId);
    });
    queryParams = this.getTagsQueryParams(queryParams, tags);
    return this.rest.getWithObservable(REST_PROJECTS + `/rollup?${queryParams.toString()}`);
  }

  getTagsQueryParams = (queryParams: HttpParams, tags: string[]) => {
    if (!tags?.length) {
      return queryParams;
    }

    tags.forEach((tag, index) => {
      queryParams = queryParams.append(`tags[${index}]`, tag);
    });

    return queryParams;
  };

  getProjects(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECTS + '?fiscal_view=1').then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getSimpleProjects(filter: IProjectFilters): Observable<ISimpleProject[]> {
    const statusFilter = { ...filter };
    statusFilter['status_filter[]'] = statusFilter.status_filter;
    delete statusFilter.status_filter;
    return this.rest.getWithObservable(PROJECTS_SIMPLE, {}, statusFilter).pipe(
      map((projects: ISimpleProject[]) => {
        if (!projects || !Array.isArray(projects)) {
          return [];
        }
        return projects.toSorted((a, b) => {
          const aField = a.title.replace(specialCharReplaceRegexp, '');
          const bField = b.title.replace(specialCharReplaceRegexp, '');
          return aField.localeCompare(bField);
        });
      }),
    );
  }

  getAllCashflowItems$(includeBudgetAdjustments = null) {
    let params = {};
    if (includeBudgetAdjustments) {
      params = {
        with_budget_adjustment: 1,
      };
    }
    return this.rest.getWithObservable(REST_ALL_CASHFLOW, {}, params);
  }

  getProject(id): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECTS + '/' + id + '?fiscal_view=1').then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getProjectById$(id: number) {
    return this.rest.getWithObservable(REST_PROJECTS + '/' + id + '?fiscal_view=1');
  }

  getProjectWithObservable(id: number): Observable<Project> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${id}`);
  }

  getBudgetTemplates(propertyId: number): Observable<IBudgetTemplate[]> {
    return this.rest.getWithObservable(BUDGET_TEMPLATES, {}, { property_id: propertyId });
  }

  createProject$(project: Partial<Project>) {
    return this.rest.postWithObservable(REST_PROJECTS, project);
  }

  createProject(proejct): Promise<any> {
    const body = proejct;
    if (!Number(body.budget_template_id)) {
      body.budget_template_id = null;
    }

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROJECTS, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateProject(project): Promise<any> {
    const body = project;

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECTS + '/' + project.id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateProject$(project: Partial<Project>) {
    return this.rest.putWithObservable(`${REST_PROJECTS}/${project.id}`, project);
  }

  patchProject(id: number, project: { work_percentage: number }): Observable<any> {
    return this.rest.patchWithObservable(`${REST_PROJECTS}/${id}/patch`, project);
  }

  updateProjectStatus(id: number, status) {
    const body = {
      status,
    };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_STATUS + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteProject$(id: number) {
    return this.rest.putWithObservable(REST_PROJECT_STATUS + '/' + id, {
      status: PROJECT_STATUS_ID.DELETED,
    });
  }

  getProjectPossibleStatuses(): Promise<ProjectStatus[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECT_STATUS_TYPES).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getPossibleStatuses$() {
    return this.rest.getWithObservable(REST_PROJECT_STATUS_TYPES);
  }

  getStats() {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECT_STATS).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createProperty(property): Promise<any> {
    const body = property;

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROPERTY, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getProperties(): Promise<IProperty[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROPERTY).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateProperty(id, body): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROPERTY + '/' + id, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteProperty$(id) {
    return this.rest.delWithObservable(REST_PROPERTY + '/' + id).pipe(catchError(this.handleError));
  }

  getPropertyTypes(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROPERTY_TYPES).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  // visit: a manager can set date time intervals when a sp can come visit
  // schedule visit: the actual scheduled visit for a sp on a specific project
  createVisit(schedule): Promise<any> {
    const body = schedule;

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_VISITS, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getVisits(projectID): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_VISITS + '/project/' + projectID).then(
        (dataRes) => {
          dataRes.forEach((data) => {
            data.day = moment.utc(data.start_date_time).local().format('YYYY-MM-DD');
            const start = moment.utc(data.start_date_time).local().format('hh:mmA');
            const end = moment.utc(data.end_date_time).local().format('hh:mmA');
            data.interval = start + '-' + end;
          });
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteVisit(scheduleId) {
    return new Promise<any>((res, rej) => {
      this.rest.del(REST_VISITS + '/' + scheduleId).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  // visit: a manager can set date time intervals when a sp can come visit
  // schedule visit: the actual scheduled visit for a sp on a specific project
  createScheduleVisit(projectId, contractorId, visitId): Promise<any> {
    const body = {
      project_id: projectId,
      contractor_id: contractorId,
      visit_id: visitId,
    };

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_SCHEDULE_VISITS, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  // modify or reschedule schedule visit
  reviseScheduleVisit(visitID, body) {
    return new Promise<any>((res, rej) => {
      this.rest.put(`${REST_SCHEDULE_VISITS}/${visitID}`, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getScheduleVisits(projectID): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_SCHEDULE_VISITS + '?project_id=' + projectID).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getScheduleVisitsUser(userId): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_SCHEDULE_VISITS + '?user_id=' + userId).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteScheduleVisit(id: any) {
    return new Promise<any>((res, rej) => {
      this.rest.del(REST_SCHEDULE_VISITS + '/' + id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  createInvite(projectId: number, contractorIds: number[]): Promise<any> {
    const body = {
      project_ids: [projectId],
      contractor_ids: contractorIds,
    };

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROJECT_INVITE, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getInvitedContractors(projectId: number): Observable<
    {
      invited_contractors: InvitedContractor[];
      project_id: number;
      project_title: string;
    }[]
  > {
    return this.rest.getWithObservable(
      REST_PROJECT_INVITE + '/contractors?',
      {},
      { project_id: projectId },
    );
  }

  deleteInviteByContractor(proejctId) {
    return new Promise<any>((res, rej) => {
      this.rest.del(REST_PROJECT_INVITE + '/reject/' + proejctId).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  deleteInvite(id: number) {
    return this.rest.delWithObservable(REST_PROJECT_INVITE + '/' + id);
  }

  createBid(projectId, value) {
    value.project_id = projectId;
    const body = value;

    return new Promise<any>((res, rej) => {
      this.rest.post(REST_PROJECT_BID, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateBid(bidId: any, values: any) {
    const body = values;

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_BID + '/' + bidId, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateBidStatus(bidID, status) {
    const body = {
      status,
    };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_PROJECT_BID + '/status/' + bidID, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getBidByProject(projectID): Promise<ProjectBid[]> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_PROJECT_BID + '?project_id=' + projectID).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getReport(data): Promise<any> {
    return new Promise<any>((res, rej) => {
      let url = REST_REPORTS + '?start_year=' + data.start_year + '&end_year=' + data.end_year;

      if (Array.isArray(data.project_ids)) {
        data.project_ids.forEach((id) => {
          url += '&project_ids[]=' + id;
        });
      } else {
        url += '&project_ids=' + data.project_ids;
      }

      if (Array.isArray(data.property_ids)) {
        data.property_ids.forEach((id) => {
          url += '&property_ids[]=' + id;
        });
      } else {
        url += '&property_ids=' + data.property_ids;
      }

      this.rest.get(url).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  // currently it's not used and do not use it if not needed
  // we can leave it as is for now, just in case we need to put toggle lock on backend
  setBudgetState(id, isLocked): Promise<boolean> {
    console.warn('Budget lock request used. Should not be used');
    const url = `${REST_PROJECT_BUDGET}/${id}/toggle_lock`;
    const body = {
      is_locked: isLocked,
    };
    return new Promise<boolean>((res, rej) => {
      this.rest.patch(url, body).then(
        (data) => res(data.is_locked),
        (err) => rej(this.handleError(err)),
      );
    });
  }

  getAllProjectTags(): Promise<string[]> {
    return this.rest.get(REST_PROJECT_TAGS);
  }
}
