import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserService } from '../../../services/current-user.service';
import { hasActiveSubscription } from '../../../framework/constants/subscription.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../services/notifications.service';

/**
 * This component's only purpose is to handle the redirections from Stripe.<br/>
 * It will refresh the user data and redirect the user to the webapp if the user has an active subscription. <br/>
 * Otherwise, it will show an error message and log the user out.
 */
@Component({
  selector: 'app-billing-success',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="flex flex-col items-center justify-center">
      <h1 *ngIf="showSuccess" class="text-color_primary font-semibold">Successfully Subscribed</h1>
      <h1 *ngIf="showFail" class="text-color_primary font-semibold">
        An error occurred during payment.
      </h1>

      <h2 class="text-color_primary">We will redirect you in a second...</h2>
    </div>
  `,
  styleUrls: ['./billing-redirect.component.scss'],
})
export class BillingRedirectComponent implements OnInit, AfterViewInit {
  showSuccess = false;
  showFail = false;

  constructor(
    private user: CurrentUserService,
    private router: Router,
    private notif: NotificationsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const subPath = this.route.snapshot?.url?.[0]?.path;
    if (subPath?.includes('success')) {
      this.showSuccess = true;
    }
    if (subPath?.includes('fail')) {
      this.showFail = true;
    }
  }

  async ngAfterViewInit() {
    try {
      await this.user.refreshUser();
    } catch (ex) {
      console.error('error refreshing user', ex);
    }
    const userData = this.user.data;

    if (hasActiveSubscription(userData)) {
      this.router.navigate(['webapp']);
    } else {
      this.notif.showError(
        "An error occurred: we couldn't find an active subscription for your account.",
      );
      // this.user.logout();
      this.router.navigate(['webapp', 'subscribe']);
    }
  }
}
