<div class="sheet-wrapper" [ngClass]="{ 'opacity-barely': loading }">
  <ng-container *ngIf="isSheetDataLoaded">
    <ejs-spreadsheet
      #spreadsheet
      (created)="afterSheetCreated()"
      [activeSheetIndex]="activeSheetIndex"
      [allowSave]="true"
      [allowOpen]="false"
      [saveUrl]="SHEET_SAVE_URL"
      (beforeSave)="onBeforeSave($event)"
      (saveComplete)="onSaveComplete($event)"
    >
      <e-sheets>
        <!--          [protectSettings]="{ selectCells: true }"-->
        <!--        [isProtected]="true"-->
        <e-sheet *ngFor="let year of possibleYears" [name]="year + ''">
          <e-rows>
            <e-row [height]="30">
              <e-cells>
                <e-cell value="LINE ITEM"></e-cell>
                <e-cell value="BUDGET"></e-cell>
                <e-cell value="YEAR TOTAL"></e-cell>
                <e-cell *ngFor="let month of months" [value]="month"></e-cell>
              </e-cells>
            </e-row>
            <e-row *ngFor="let lineItem of items" [height]="30">
              <e-cells>
                <e-cell [value]="lineItem.name"></e-cell>
                <e-cell format="$#,##0.00" [formula]="lineItem.budgetTotalFormula"></e-cell>
                <e-cell format="$#,##0.00" [formula]="lineItem.yearTotalFormula"></e-cell>
                <e-cell
                  format="$#,##0.00"
                  [validation]="{ type: 'Decimal', operator: 'NotEqualTo', value1: '' }"
                  *ngFor="let month of months; let monthIndex = index"
                  [value]="getBudgetValue(lineItem, year, monthIndex)"
                ></e-cell>
              </e-cells>
            </e-row>
            <e-row *ngFor="let row of emptyRowsBeforeTotal" [height]="30"> </e-row>
            <e-row>
              <e-cells>
                <e-cell [value]="'Total'"></e-cell>
                <e-cell format="$#,##0.00" *ngFor="let formula of totalCellFormulas" [formula]="formula"></e-cell>
              </e-cells>
            </e-row>
          </e-rows>

          <e-columns>
            <e-column [width]="130"></e-column>
            <e-column [width]="130"></e-column>
            <e-column [width]="130"></e-column>
            <e-column *ngFor="let month of months" [width]="110"></e-column>
            <!--            <e-column [width]=120></e-column>-->
          </e-columns>
        </e-sheet>
      </e-sheets>
    </ejs-spreadsheet>
  </ng-container>
</div>

<div class="content" *ngIf="loading">
  <div class="loading">Loading...</div>
</div>
