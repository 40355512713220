import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

/**
 * This directive formats money inputs in Spend Distribution Component (at the moment).
 * It is meant to be used with a contentEditable HTML element, not usual inputs (for inputs, see FormatInputMoneyDirective).
 * We changed to contentEditable because we had huge performance issues with traditional inputs - bear this in mind.
 * It formats on focusout, not on the fly. When editing the input, it shows values as regular numbers.
 * It handles decimals, negative values, paste and enter events.
 */
@Directive({
  selector: '[appFormatInputMoneyContentEditable]',
  standalone: true,
})
export class FormatInputMoneyContentEditableDirective implements AfterViewInit {
  // numberValue and displayValue should represent the same thing
  // numberValue stores the actual number value
  // but displayValue is a string formatted like ($ 123) when the input isn't focused
  // and shows the numberValue when the input is focused (being edited)
  untouchedNumberInput = 0; // is it set only on @Input(), don't emit change if equals to new value
  @Input() set numberInput(value: number) {
    this._numberValue = value;
    this.untouchedNumberInput = value;
    this.formatInput();
  }
  private _numberValue = 0;
  set numberValue(value: number) {
    this._numberValue = value;
  }
  get numberValue() {
    return this._numberValue;
  }

  set displayValue(displayValue: string) {
    this.el.nativeElement.innerHTML = displayValue;
  }
  get displayValue() {
    return this.el.nativeElement.innerHTML;
  }

  @Input() set allowNegatives(allow: boolean) {
    this.regExp = allow ? this.negativeRegExp : this.positiveRegExp;
    this.notDigitRegExp = allow ? this.negativeNotDigitRegexp : this.positiveNotDigitRegexp;
  }

  @Output() valueChange = new EventEmitter<number>();

  negativeRegExp = new RegExp(/^-?([0-9]{1,9})?(\.)?(\.[\d]{1,2})?$/); // allows negative numbers and decimals
  positiveRegExp = new RegExp(/^([0-9]{1,9})?(\.)?(\.[\d]{1,2})?$/); // allows only positive numbers and decimals
  positiveNotDigitRegexp = new RegExp(/[^\d.]/g);
  negativeNotDigitRegexp = new RegExp(/[^\d.-]/g);
  notDigitRegExp = this.negativeNotDigitRegexp;
  regExp = this.negativeRegExp;
  // maybe strings/leading zeroes inputs can be avoided as and be included in a single regex, to be checked:
  // https://stackoverflow.com/questions/18495621/regular-expression-for-less-or-more-than-9-digits-repeating-digits-for-first-5-o
  zeroStartRegex = new RegExp(/^-?0+\d+(.\d+|.)?$/); // numbers that start with 0 ex: 01; 000; 001; 021.; 03.1;

  constructor(private el: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit() {
    this.formatInput();
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: InputEvent) {
    // enter is like leaving the field with focusout
    this.onFocusOut();
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    // mostly input validation
    if (event?.data?.match(this.notDigitRegExp)) {
      // if inputted character is not a valid character, replace it
      this.displayValue = this.displayValue.replace(this.notDigitRegExp, '');
      this.setCaretPositionToEnd();
      return;
    }
    const displayValue = this.displayValue;

    if (this.zeroStartRegex.test(displayValue)) {
      // if it starts incorrectly with zero, like 00012
      this.handleIncorrectValue();
      return;
    }

    if (this.regExp.test(displayValue)) {
      // test if input is a correct number
      this.numberValue = this.parseNumber(displayValue);
    } else {
      this.handleIncorrectValue();
    }
    // this.hasBeenPasted = false;
  }

  @HostListener('focusin')
  onFocusIn() {
    // remove formatting and show the number values
    const displayValue = String(this.numberValue);
    this.checkHideZero(displayValue);

    this.setCaretPositionToEnd();
  }

  @HostListener('focusout')
  onFocusOut() {
    // there is no 'change' event for contentEditable divs, so focousout acts as the change event
    // emit changes and format - it is like change event for inputs
    this.el.nativeElement.blur();

    this.checkShowZero();

    if (this.untouchedNumberInput !== this.numberValue) {
      // if the value hasn't changed, don't emit a change event
      this.valueChange.emit(this.numberValue);
    }
    this.formatInput();
  }

  formatInput() {
    try {
      this.displayValue =
        this.numberValue < 0
          ? '($ ' + this.formatWithCommas(this.numberValue * -1) + ')'
          : '$ ' + this.formatWithCommas(this.numberValue);
    } catch (e) {
      console.warn(e);
    }
  }

  formatWithCommas(input: number) {
    // round to 2 decimals: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    input = Math.round((input + Number.EPSILON) * 100) / 100;
    const parts = input.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  transformToNumber(value: string) {
    if (value === '' || !value) {
      return 0;
    }

    let sign = 1;
    if (value.includes('(')) {
      sign = -1;
    }

    const displayValue = value.replace(this.notDigitRegExp, '');
    return sign * this.parseNumber(displayValue);
  }

  private checkHideZero(displayValue: string) {
    // if number value is 0 then delete it
    if (displayValue === '0') {
      this.displayValue = '';
    } else {
      this.displayValue = displayValue;
    }
  }

  private checkShowZero() {
    // on focus out show zero if input is empty
    if (this.displayValue === '') {
      this.numberValue = 0;
    }
  }

  private setCaretPositionToEnd() {
    if (this.displayValue === '') {
      return;
    }
    setTimeout(() => {
      // setTimeout needed to wait for DOM update
      try {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(this.el.nativeElement.childNodes[0], this.displayValue.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      } catch (ex) {
        console.warn('set caret(cursor) error', ex);
      }
    });
  }

  private handleIncorrectValue() {
    // set displayValue to the previous (correct) numberValue when wrong character inserted
    this.displayValue = String(this.numberValue);
    this.setCaretPositionToEnd();
  }

  private parseNumber(displayValue: string) {
    const parsedNumber = Number(displayValue);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  }
}
