<div class="pricing-card basic" [ngClass]="{ 'bg-shade_1': showBasicFeatures, 'is-loading': isLoading }">
  <ng-container *ngIf="!showBasicFeatures">
    <div class="bg-box-1"></div>
    <div class="bg-box-2"></div>
    <h3 class="text-shade_1 z-10">Basic</h3>
    <div class="description">Best for single users & residential projects.</div>
    <div class="flex-1 flex flex-col">
      <div class="flex items-baseline justify-center">
        <h2 [ngClass]="{ 'opacity-0': isLoading }" class="price">${{ price }}</h2>
        <!--        <span class="text-shade_1 text-size_title_s lg:text-size_title_m font-semibold">user</span>-->
      </div>
      <div [ngClass]="{ 'opacity-0': isLoading }" class="billing-info text-shade_1">
        <ng-container *ngIf="!isYearly">billed each month</ng-container>
        <ng-container *ngIf="isYearly">billed each year</ng-container>
      </div>
    </div>
    <div
      (click)="showBasicFeatures = true"
      class="text-color_secondary font-semibold cursor-pointer hover:text-color_primary text-center my-5"
    >
      See Plan Features
    </div>
    <button
      [ngClass]="{ 'opacity-0': isLoading }"
      (click)="onButtonClick.emit()"
      class="landing-btn btn-big-full light-blue-btn uppercase transition-opacity"
    >
      {{ buttonText }}
    </button>
  </ng-container>
  <ng-container *ngIf="showBasicFeatures">
    <span (click)="showBasicFeatures = false" class="icon-remove-circle-x text-size_icon"></span>
    <h3 class="text-white z-10">Basic</h3>
    <div class="description text-white">Best for single users & residential projects.</div>
    <div class="flex-1 my-2">
      <div class="pricing-table-row"><span>Users</span> <span>1 User</span></div>
      <div class="pricing-table-row"><span>Storage</span> <span>1GB</span></div>
      <div class="pricing-table-row"><span>Projects</span> <span>5 / Year</span></div>
      <div class="pricing-table-row"><span>Properties</span> <span>1</span></div>
      <div class="pricing-table-row"><span>Reporting</span> <span>Summary Only</span></div>
      <!--      <div class="pricing-table-row"><span>Contracts</span> <span>1/Project</span></div>-->
      <!--      <div class="pricing-table-row"><span>Cashflow</span> <span>12 Months</span></div>-->
    </div>
    <button
      [ngClass]="{ 'opacity-0': isLoading }"
      (click)="onButtonClick.emit()"
      class="landing-btn btn-big-full outline-white-btn uppercase transition-opacity"
    >
      {{ buttonTextBackside }}
    </button>
  </ng-container>
</div>
