<ng-container *ngFor="let modelGroup of model.custom_fields; let index = index">
  <!--  https://angular.io/guide/two-way-binding-->
  <!--  using modelGroup will not work as it is read only and output of the child component will modify only original variable -->
  <app-custom-task-group
    #customTaskGroupComponent
    (updateModelState)="onModelChange()"
    (removeGroup)="removeGroup($event)"
    [groupIndex]="index + 1"
    [(model)]="model.custom_fields[index]"
  ></app-custom-task-group>
</ng-container>

<span (click)="addCustomFieldGroup()" class="pointer-cursor text-color_secondary">Add Item</span>
