<div class="forecast-view-wrapper">
  <div class="forecast-scroll-wrapper">
    <ng-scrollbar track="vertical" appearance="compact" class="std-scrollbar">
      <div class="forecast-modifications" *ngFor="let modification of forecastModifications; let lineNr = index">
        <div class="modification">
          <div class="header">
            <span class="title">{{ modification.name }}</span>
            <div class="flex-1"></div>
            <span (click)="addLine(lineNr, modification)" class="button">Add Modification</span>
          </div>
          <div
            class="flex justify-between gap-2 no-wrap"
            *ngFor="let formGroup of modification.forecastForms; let formGroupIndex = index"
            [formGroup]="formGroup"
          >
            <app-floating-input
              [name]="modification.name + '_' + formGroupIndex"
              class="flex-1"
              formControlName="value"
              [formControl]="formGroup.get('value')"
              ngDefaultControl
              [placeholder]="getAmount(formGroupIndex)"
              [label]="getAmount(formGroupIndex)"
              [iconClass]="{ position: 'p-input-icon-right', icon: 'icon-remove' }"
              (iconClick)="removeModification(lineNr, formGroupIndex)"
              [ngClass]="{ 'negative-color': formGroup.get('value').value < 0 }"
              [formatMoney]="true"
            >
            </app-floating-input>

            <app-floating-input
              class="flex-1"
              [name]="modification.name + '_note_' + formGroupIndex"
              formControlName="note"
              ngDefaultControl
              [formControl]="formGroup.get('note')"
              placeholder="Notes"
              label="Notes"
            ></app-floating-input>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div class="options">
    <button (click)="save()" [disabled]="isLoading" class="save-btn" mat-button>
      <span *ngIf="!isLoading">Submit</span>
      <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
    </button>
  </div>
</div>
