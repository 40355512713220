import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NotificationsService } from '../../../../../services/notifications.service';
import {
  IBudgetTemplate,
  IBudgetTemplateItem,
} from '../../../../../store/settings/settings.interface';
import {
  getBudgetTemplates,
  getSelectedBudgetTemplate,
} from '../../../../../store/settings/settings.selectors';
import { settingsActions } from '../../../../../store/settings/settings.actions';
import { AppState } from '../../../../../store/app-state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { AccountDropdownOptionSelected } from '../../../../../framework/account-dropdown/account-dropdown.component';
import { take } from 'rxjs/operators';
import { defaultDropdownOverlayPositions } from '../../../../../framework/overlays/option-list.constants';
import { TemplatesHelpComponent } from '../templates-help/templates-help.component';
import { FormsModule } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { TemplateSelectorComponent } from '../template-selector/template-selector.component';

@Component({
  selector: 'app-budget-templates',
  templateUrl: './budget-templates.component.html',
  styleUrls: ['./budget-templates.component.scss'],
  standalone: true,
  imports: [
    TemplateSelectorComponent,
    NgIf,
    NgScrollbar,
    NgFor,
    NgClass,
    FormsModule,
    TemplatesHelpComponent,
    AsyncPipe,
  ],
})
export class BudgetTemplatesComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
  ) {}
  @ViewChildren('trigger') trigger: QueryList<CdkOverlayOrigin>;
  @Output() gotToTagTemplates = new EventEmitter();
  selectedTemplate$: Observable<IBudgetTemplate> = this.store.select(getSelectedBudgetTemplate);
  templates: Observable<IBudgetTemplate[]> = this.store.select(getBudgetTemplates);
  newItemValue = '';

  showOptions: { [key: number]: boolean } = {};
  defaultDropdownOverlayPositions: ConnectedPosition[] = defaultDropdownOverlayPositions;

  showBudgetLineGuide = false;
  showAccountsGuide = false;

  ngOnInit(): void {}

  addTemplateItem() {
    if (!this.newItemValue) {
      this.notif.showError('Please add budget line item name!');
      return;
    }

    this.store.dispatch(
      settingsActions.addBudgetTemplateItem({
        newItemName: this.newItemValue,
      }),
    );
    this.newItemValue = '';
  }

  onInputChange(item: IBudgetTemplateItem, event: Event) {
    const element = event.target as HTMLInputElement;
    const newItem: IBudgetTemplateItem = { ...item, name: element.value };
    this.store.dispatch(settingsActions.updateBudgetTemplateItem({ newItem }));
  }

  onPasteEvent(fromIndex: number, event: ClipboardEvent) {
    const textToPaste = event.clipboardData.getData('text');
    const itemNames = textToPaste.split('\n');
    if (itemNames.length <= 1) {
      return;
    }
    // if more than 1 row is going to be inserted, overwrite the old values with the new ones
    event.preventDefault();
    if (!fromIndex && fromIndex !== 0) {
      // if items are inserted to the end of list (empty input), just create them
      for (const name of itemNames) {
        this.store.dispatch(settingsActions.addBudgetTemplateItem({ newItemName: name }));
      }
    } else {
      // if items are inserted in the middle of the items,
      // overwrite from `fromIndex` to the end of existing items
      // and then insert new ones if there is any
      let index = fromIndex;
      for (const name of itemNames) {
        this.selectedTemplate$.pipe(take(1)).subscribe((selectedTemplate) => {
          const id = selectedTemplate.template_items?.[index]?.id;
          index++;
          if (id) {
            const newItem: IBudgetTemplateItem = {
              id: selectedTemplate.template_items?.[index]?.id,
              g_l_account_label:
                selectedTemplate.template_items?.[index]?.g_l_account_label ?? null,
              name,
            };
            this.store.dispatch(settingsActions.updateBudgetTemplateItem({ newItem }));
          } else {
            this.store.dispatch(settingsActions.addBudgetTemplateItem({ newItemName: name }));
          }
        });
      }
    }
  }
  removeItem(item: IBudgetTemplateItem) {
    this.store.dispatch(settingsActions.removeBudgetTemplateItem({ itemId: item.id }));
  }

  getTrigger(index: number) {
    if (!this.trigger) {
      return;
    }

    const list = this.trigger.toArray();
    return list[index];
  }

  optionSelected(event: AccountDropdownOptionSelected, item: IBudgetTemplateItem, index: number) {
    console.log(event);
    this.showOptions[index] = false;
    this.store.dispatch(
      settingsActions.setGLAccountLabelFromDropdown({
        accountLabel: event.value,
        budgetTemplateItem: item,
      }),
    );
  }

  unlinkTemplate(item: IBudgetTemplateItem, index: number) {
    this.showOptions[index] = false;
    this.store.dispatch(settingsActions.unlinkBudgetTemplateItem({ budgetTemplateItem: item }));
  }

  onDiscard() {
    history.back();
  }

  onSave() {
    this.store.dispatch(settingsActions.saveBudgetTemplates());
  }

  saveAndGoToTags() {
    this.store.dispatch(settingsActions.saveBudgetTemplates());
    this.gotToTagTemplates.emit();
  }

  openAccountSelector(item: IBudgetTemplateItem, index: number) {
    if (item?.permissions?.can_edit) {
      this.showOptions[index] = true;
    }
  }
}
