import {
  AfterViewInit,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommitmentSidebarComponent } from '../commitment-sidebar/commitment-sidebar.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app-state';
import { NotificationsService } from '../../../../services/notifications.service';
import { StorageService } from '../../../../services/storage.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { take, takeUntil } from 'rxjs/operators';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { FormsModule, NgForm } from '@angular/forms';
import { SpendEntryComponent } from '../spend-entry/spend-entry.component';
import {
  commitmentCombinedSelectors,
  commitmentsSelectors,
} from '../../../../store/commitments/commitments.selectors';
import { defaultDirectCost } from '../../../../store/commitments/commitments.constants';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { fadeInGeneral } from '../../../../../assets/styles/animations';
import {
  ISidebarCommitmentItem,
  ISidebarDirectCost,
} from '../../../../store/commitments/commitments.types';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { MatButton } from '@angular/material/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { InputTextModule } from 'primeng/inputtext';
import { UploadCommitmentsSidebarComponent } from '../upload-commitments-sidebar/upload-commitments-sidebar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { CommitmentSidebarTitleComponent } from '../commitment-sidebar-title/commitment-sidebar-title.component';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingTextareaComponent } from '../../../inputs/floating-textarea/floating-textarea.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';

@Component({
  selector: 'app-misc-cost-sidebar',
  templateUrl: './misc-cost-sidebar.component.html',
  styleUrls: [
    './misc-cost-sidebar.component.scss',
    '../commitment-sidebar/commitment-sidebar.component.scss',
  ],
  animations: [fadeInGeneral],
  standalone: true,
  imports: [
    NgIf,
    CommitmentSidebarTitleComponent,
    CommitmentEntryTextComponent,
    NgScrollbar,
    FormsModule,
    UploadCommitmentsSidebarComponent,
    InputTextModule,
    NgClass,
    InputCalendarComponent,
    DropdownModule,
    InputTextareaModule,
    NgFor,
    SpendEntryComponent,
    MatButton,
    AsyncPipe,
    DecimalPipe,
    MoneyPipe,
    FloatingInputComponent,
    DropdownComponent,
    FloatingTextareaComponent,
    UpperCasePipe,
    SimpleButtonComponent,
  ],
})
export class MiscCostSidebarComponent
  extends CommitmentSidebarComponent
  implements AfterViewInit, OnDestroy
{
  constructor(
    protected store: Store<AppState>,
    protected notif: NotificationsService,
    protected storage: StorageService,
    protected user: CurrentUserService,
    protected interactionBar: InteractionBarStateService,
  ) {
    super(store, notif, storage, user, interactionBar);
    this.spendEntries$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.spendEntriesData = DeepCopyService.deepCopy(data);
    });
  }

  @ViewChild('firstPageForm') formFirstPage: NgForm;
  @ViewChildren('spendEntries') spendEntryComponents: QueryList<SpendEntryComponent>;
  @ViewChild('picker') picker;

  directCost$ = this.store.select(commitmentsSelectors.getSidebarDirectCost);
  spendEntries$ = this.store.select(commitmentsSelectors.getDirectCostSpendEntries);
  totalDirectCost$ = this.store.select(commitmentsSelectors.totalDirectCostsDescriptionTotals);
  canAddDirectCostEntries$ = this.store.select(
    commitmentCombinedSelectors.canAddDirectCostSpendEntries,
  );

  spendEntriesData: ISidebarCommitmentItem[] = [];
  isDropdownShown = {
    approval_status: false,
    paid_status: false,
  };
  model: Partial<ISidebarDirectCost> = {
    ...defaultDirectCost,
  };

  commitmentType = COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST;

  ngAfterViewInit() {
    this.spendEntries$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.spendEntriesData = DeepCopyService.deepCopy(data);
    });

    this.directCost$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      if (data) {
        this.model = DeepCopyService.deepCopy(data);
        console.log('this.model', this.model, 'data', data);
        this.isEdit = !!data.id;
      }
    });
  }

  addSpendEntry() {
    this.canAddDirectCostEntries$.pipe(take(1)).subscribe((canAdd) => {
      if (canAdd) {
        this.store.dispatch(commitmentsActions.addDirectCostSpendEntry());
      }
    });
  }

  saveOrModify() {
    if (this.model.id) {
      this.notif.showLoading('Updating direct cost...');
      this.store.dispatch(commitmentsActions.modifyDirectCost());
      return;
    }
    this.notif.showLoading('Saving direct cost...');
    this.store.dispatch(commitmentsActions.saveDirectCost());
  }

  updateDirectCost() {
    this.store.dispatch(
      commitmentsActions.updateSelectedDirectCost({
        directCost: this.model as Partial<ISidebarDirectCost>,
      }),
    );
  }

  updateAddedDate(date: string) {
    this.store.dispatch(
      commitmentsActions.updateSelectedDirectCost({
        directCost: {
          ...this.model,
          added_at: date,
        },
      }),
    );
  }

  updatePaidDate(date: string) {
    this.store.dispatch(
      commitmentsActions.updateSelectedDirectCost({
        directCost: {
          ...this.model,
          paid_date: date,
        },
      }),
    );
  }
}
