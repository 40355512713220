<div class="add-contracts-tab-wrapper">
  <button class="add-new-button btn-small" mat-button disableRipple (click)="openAddContractsAgreement()">
    <span class="flex items-center">
      <span class="icon-add-round"></span>
      <span class="btn-text">Add Contract</span>
    </span>
  </button>

  <button
    class="add-new-button btn-small"
    mat-button
    disableRipple
    [disabled]="!availableContracts"
    [ngClass]="{ 'color-disabled': !availableContracts }"
    (click)="openAddChangeOrder()"
  >
    <span class="flex items-center">
      <span class="icon-add-round"></span>
      <span class="btn-text">Add Change Order</span>
    </span>
  </button>

  <button
    class="add-new-button btn-small"
    mat-button
    disableRipple
    (click)="openAddInvoices()"
    [ngClass]="{ 'color-disabled': !availableContracts }"
    [disabled]="!availableContracts"
  >
    <span class="flex items-center">
      <span class="icon-add-round"></span>
      <span class="btn-text">Add Invoice</span>
    </span>
  </button>

  <!--  <app-invoice-list *ngIf="!user.isManager" [data]="commitments"></app-invoice-list>-->

  <button
    *ngIf="user.isManager"
    class="add-new-button btn-small"
    mat-button
    disableRipple
    (click)="openAddDirectCosts()"
  >
    <span class="flex items-center">
      <span class="icon-add-round"></span>
      <span class="btn-text">Add Direct Cost</span>
    </span>
  </button>
</div>

<div class="header">
  <!--      <app-search-bar></app-search-bar>-->
</div>
