import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  BUDGET_TEMPLATES,
  GL_TEMPLATES,
  GL_TEMPLATES_LABEL,
  REST_NOTIFICATIONS_SETTINGS,
  TAG_TEMPLATES,
  TAG_TEMPLATES_ITEM,
} from '../restApi/RestRoutes';
import { Observable } from 'rxjs';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
  IGLAccountLabel,
  IGlTemplate,
} from '../store/settings/settings.interface';
import * as uuid from 'uuid';
import { DeepCopyService } from './deep-copy.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private rest: RestRequestService) {}

  getBudgetTemplates(): Observable<IBudgetTemplate[]> {
    return this.rest.getWithObservable(BUDGET_TEMPLATES);
  }

  addBudgetTemplate(template: Partial<IBudgetTemplate>): Observable<IBudgetTemplate> {
    return this.rest.postWithObservable(BUDGET_TEMPLATES, this.getClearBudgetTemplate(template));
  }
  modifyBudgetTemplate(template: IBudgetTemplate): Observable<IBudgetTemplate> {
    const url = `${BUDGET_TEMPLATES}/${template.id}`;
    return this.rest.patchWithObservable(url, this.getClearBudgetTemplate(template));
  }

  // todo: see later, maybe not needed..
  // removeBudgetTemplateItem(template: IBudgetTemplate): Observable<IBudgetTemplate> {
  //   const url = `${BUDGET_TEMPLATES}/${template.id}`;
  //   return this.rest.delWithObservable(url, this.getClearBudgetTemplate(template));
  // }

  removeBudgetTemplate(template: IBudgetTemplate): Observable<IBudgetTemplate> {
    return this.rest.delWithObservable(`${BUDGET_TEMPLATES}/${template.id}`);
  }

  removeTagTemplateItem(item: Partial<IBudgetTemplateItem>): Observable<IBudgetTemplateItem> {
    return this.rest.delWithObservable(`${TAG_TEMPLATES_ITEM}/${item.id}`);
  }
  removeTagTemplate(item: Partial<IBudgetTemplate>): Observable<IBudgetTemplate> {
    return this.rest.delWithObservable(`${TAG_TEMPLATES}/${item.id}`);
  }

  addBudgetTagTemplate(template: Partial<IBudgetTagTemplate>): Observable<IBudgetTagTemplate> {
    return this.rest.postWithObservable(TAG_TEMPLATES, this.getClearTagsTemplate(template));
  }
  modifyBudgetTagTemplate(template: IBudgetTagTemplate): Observable<IBudgetTagTemplate> {
    const url = `${TAG_TEMPLATES}/${template.id}`;
    return this.rest.patchWithObservable(url, this.getClearTagsTemplate(template));
  }

  getGlTemplates(): Observable<IGlTemplate[]> {
    return this.rest.getWithObservable(GL_TEMPLATES);
  }

  getBudgetTagTemplates(projectId?: number): Observable<IBudgetTagTemplate[]> {
    if (!projectId) {
      return this.rest.getWithObservable(TAG_TEMPLATES);
    }

    return this.rest.getWithObservable(TAG_TEMPLATES, {}, { project_id: projectId });
  }

  addGlTemplate(template: Partial<IGlTemplate>): Observable<IGlTemplate> {
    return this.rest.postWithObservable(GL_TEMPLATES, this.getClearGLTemplate(template));
  }
  removeGlTemplate(template: Partial<IGlTemplate>): Observable<IGlTemplate> {
    return this.rest.delWithObservable(`${GL_TEMPLATES}/${template.id}`);
  }

  removeGlTemplateLabel(label: Partial<IGLAccountLabel>): Observable<IGLAccountLabel> {
    return this.rest.delWithObservable(`${GL_TEMPLATES_LABEL}/${label.id}`);
  }

  modifyGlTemplate(template: IGlTemplate): Observable<IGlTemplate> {
    const url = `${GL_TEMPLATES}/${template.id}`;
    return this.rest.patchWithObservable(url, this.getClearGLTemplate(template));
  }

  getNotificationSettings(): Observable<any> {
    return this.rest.getWithObservable(REST_NOTIFICATIONS_SETTINGS);
  }

  updateNotificationSettings(settings: any): Observable<any> {
    return this.rest.putWithObservable(REST_NOTIFICATIONS_SETTINGS, settings);
  }

  /**
   * Remove permissions and uuids from GL account template before sending to backend
   * @param template
   */
  getClearGLTemplate(template: Partial<IGlTemplate>): Partial<IGlTemplate> {
    template = DeepCopyService.deepCopy(template);
    delete template?.permissions;
    template.labels = template.labels.filter(
      (label) => !label?.is_deleted && label.name.replace(/\s/g, '').length,
    );
    template.labels.forEach((label) => {
      delete label?.permissions;
      // delete locally added uuids as they are not valid ids for backend
      if (uuid.validate(label.id)) {
        delete label?.id;
      }
    });
    return template;
  }

  /**
   * Remove permissions and uuids from budget tag templates before sending to backend
   * @param template
   */
  getClearTagsTemplate(template: Partial<IBudgetTagTemplate>): Partial<IBudgetTagTemplate> {
    template = DeepCopyService.deepCopy(template);
    delete template?.permissions;
    template.tags = template.tags.filter(
      (tag) => !tag?.is_deleted && tag.name.replace(/\s/g, '').length,
    );
    template.tags.forEach((tag) => {
      delete tag?.permissions;
      // delete locally added uuids as they are not valid ids for backend
      if (uuid.validate(tag.id)) {
        delete tag?.id;
      }
    });
    return template;
  }

  /**
   * Remove permissions and uuids from budget templates before sending to backend
   * @param template
   */
  private getClearBudgetTemplate(template: Partial<IBudgetTemplate>) {
    template = DeepCopyService.deepCopy(template);
    delete template?.permissions;
    template.template_items = template.template_items.filter(
      (it) => it?.name?.replace(/\s/g, '')?.length,
    );
    template.template_items.forEach((item) => {
      delete item?.permissions;
      item.g_l_account_label_id = item?.g_l_account_label?.id ? item.g_l_account_label.id : null;
      delete item?.g_l_account_label;
      // delete locally added uuids as they are not valid ids for backend
      if (uuid.validate(item.id)) {
        delete item?.id;
      }
    });
    return template;
  }
}
