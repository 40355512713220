import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propertiesAdapter, propertiesFeatureKey, PropertiesState } from './properties.reducer';

const { selectAll, selectIds, selectEntities } = propertiesAdapter.getSelectors();

const propertiesFeatureSelector = createFeatureSelector<PropertiesState>(propertiesFeatureKey);

const getAllProperties = createSelector(propertiesFeatureSelector, selectAll);
const getPropertiesByType = createSelector(propertiesFeatureSelector, (state) => {
  const properties =
    state.selectedPropertyType === 'peer' ? state.peerProperties : state.ownProperties;
  if (state.searchedProperty) {
    return properties.filter((property) =>
      property.name?.toLowerCase().includes(state.searchedProperty?.toLowerCase()),
    );
  }
  return properties;
});

const getSelectedView = createSelector(
  propertiesFeatureSelector,
  (state) => state.selectedPropertyType,
);

const isFetching = createSelector(propertiesFeatureSelector, (state) => state.isFetching);
export const propertiesSelectors = {
  propertiesFeatureSelector,
  getAllProperties,
  getPropertiesByType,
  isFetching,
  getSelectedView,
};
