<div class="budget-adjustment-wrapper">
  <div class="flex flex-col" [formGroup]="budgetAdjustmentForm">
    <app-floating-input
      formControlName="value"
      ngDefaultControl
      placeholder="Amount ($):"
      label="Amount ($):"
      [formatMoney]="true"
      [ngClass]="{
        'negative-color': budgetAdjustmentForm.get('value').value < 0
      }"
    ></app-floating-input>

    <app-dropdown
      required
      formControlName="from_item_id"
      ngDefaultControl
      [options]="fromItems"
      [optionValue]="'id'"
      [optionLabel]="'name'"
      label="From"
      placeholder="From"
    >
    </app-dropdown>

    <app-dropdown
      required
      formControlName="to_item_id"
      ngDefaultControl
      [options]="toItems"
      [optionValue]="'id'"
      [optionLabel]="'name'"
      (modelChange)="onToItemChange($event)"
      *ngIf="!isNewItemInput"
      [showLabel]="true"
      label="To"
      placeholder="To"
    >
      <ng-template #selectedContent #optionContent let-option>
        <ng-container *ngIf="option.id"
          ><div>{{ option.name }}</div></ng-container
        >
        <ng-container *ngIf="!option.id">
          <span
            [ngClass]="{ 'disabled color-disabled pointer-events-none': hasBudgetTemplate }"
            matRipple
            class="custom-mat-option"
          >
            <span [ngClass]="{ 'color-disabled': hasBudgetTemplate }" class="icon-add-round"></span>
            <span [ngClass]="{ 'color-disabled': hasBudgetTemplate }">Add New Budget Line Item</span>
          </span>
        </ng-container>
      </ng-template>
    </app-dropdown>

    <app-floating-input
      *ngIf="isNewItemInput"
      formControlName="to_item_id"
      ngDefaultControl
      placeholder="New Line Item"
      label="New Line Item"
      [ngClass]="{ disabled: hasBudgetTemplate }"
      [iconClass]="{
        position: 'p-input-icon-right',
        icon: 'icon icon-remove'
      }"
      (iconClick)="switchInput()"
    ></app-floating-input>

    <app-floating-textarea formControlName="note" ngDefaultControl label="Notes" placeholder="Notes">
    </app-floating-textarea>
  </div>
  <div class="flex-fill"></div>
  <div class="options">
    <button (click)="save()" [disabled]="isLoading" class="save-btn" mat-button>
      <span *ngIf="!isLoading">Submit</span>
      <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
    </button>
  </div>
</div>
