import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeneralSidebarHeaderComponent } from '../general-sidebar-header/general-sidebar-header.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { InputCalendarComponent } from '../inputs/input-calendar/input-calendar.component';
import { ChecklistInputComponent } from '../inputs/checklist-input/checklist-input.component';
import { DropdownComponent } from '../inputs/dropdown/dropdown.component';
import { AsyncPipe, NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { ISimpleProject } from '../../pages/webapp/projects/projects.interface';
import { Observable, Subject } from 'rxjs';
import {
  areSimpleProjectsLoading,
  getSimpleProjects,
} from '../../store/projects/projects.selectors';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { projectActions } from '../../store/projects/projects.actions';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { DeepCopyService } from '../../services/deep-copy.service';
import { activitiesActions } from '../../store/activities/activities.actions';
import { ISidebarActivity, NO_SIMPLE_PROJECT } from '../../store/activities/activities.constants';
import { Actions, ofType } from '@ngrx/effects';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { NotificationsService } from '../../services/notifications.service';
import { FloatingTextareaComponent } from '../inputs/floating-textarea/floating-textarea.component';

@Component({
  selector: 'app-activity-sidebar',
  standalone: true,
  imports: [
    GeneralSidebarHeaderComponent,
    NgScrollbar,
    FloatingInputComponent,
    FormsModule,
    InputCalendarComponent,
    ChecklistInputComponent,
    DropdownComponent,
    TitleCasePipe,
    NgClass,
    NgIf,
    AsyncPipe,
    FloatingTextareaComponent,
  ],
  templateUrl: './activity-sidebar.component.html',
  styleUrl: './activity-sidebar.component.scss',
})
export class ActivitySidebarComponent implements OnInit, OnDestroy {
  @ViewChild('form') activityForm: NgForm;

  noRelatedProject: ISimpleProject = {
    ...NO_SIMPLE_PROJECT,
  };

  selectedProject: ISimpleProject = this.noRelatedProject;
  private _model: ISidebarActivity = {
    start_date: '',
    title: '',
    end_date: '',
    project_id: this.noRelatedProject.id,
    description: '',
  };
  get model(): ISidebarActivity {
    return this._model;
  }

  set model(value: ISidebarActivity) {
    this._model = value;
    this.selectedProject = this.possibleProjects.find((p) => p.id === value.project_id);
  }

  possibleProjects$: Observable<ISimpleProject[]> = this.store.select(getSimpleProjects);
  possibleProjects: ISimpleProject[] = [this.noRelatedProject];
  areSimpleProjectsLoading$: Observable<boolean> = this.store.select(areSimpleProjectsLoading);
  isDestroyed$: Subject<boolean> = new Subject();
  disableCompletion = false;

  constructor(
    protected store: Store<AppState>,
    private actions: Actions,
    private interactionBar: InteractionBarStateService,
    private notif: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(projectActions.loadProjectsSimpleTaskSidebar({}));
    // todo: move this into a simple project service and eventually get data from a behavior subject
    // todo: test if project gets deelted what happens to the activity
    this.possibleProjects$.pipe(takeUntil(this.isDestroyed$)).subscribe((projects) => {
      this.possibleProjects = [this.noRelatedProject, ...DeepCopyService.deepCopy(projects)];
      // if (this.model.project_id) {
      //   const projectExists = this.possibleProjects.find((p) => p.id === this.model.project.id);
      //   if (!projectExists) {
      //     this.possibleProjects.push(this.model.project_id);
      //   }
      // }
    });

    this.handleUpdates();
    this.loadEditActivity();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  saveActivity() {
    this.store.dispatch(
      activitiesActions.addDailyActivity({ activity: DeepCopyService.deepCopy(this.model) }),
    );
  }

  updateActivity() {
    this.activityForm.form.markAllAsTouched();
    if (this.activityForm.form.invalid) {
      this.notif.showError(
        'Please fill out all required fields and make sure the dates are valid.',
      );
      return;
    }

    this.store.dispatch(
      activitiesActions.updateDailyActivity({ activity: DeepCopyService.deepCopy(this.model) }),
    );
  }

  private handleUpdates() {
    this.actions
      .pipe(
        ofType(
          activitiesActions.successfullyAddedDailyActivity,
          activitiesActions.successfullyUpdatedDailyActivity,
        ),
        takeUntil(this.isDestroyed$),
        tap(() => this.notif.showSuccess('Daily Activity saved successfully.')),
        delay(500),
      )
      .subscribe(() => {
        this.store.dispatch(activitiesActions.loadDataByView());
        this.interactionBar.close();
      });
  }

  toggleCompletion() {
    this.disableCompletion = true;
    setTimeout(() => {
      this.model.completed = !this?.model?.completed;
      this.disableCompletion = false;
    }, 500);
  }

  deleteActivity() {
    this.notif.showPopup('Are you sure you want to delete this activity?').then((result) => {
      if (result) {
        this.interactionBar.close();
        this.store.dispatch(activitiesActions.deleteDailyActivity({ id: this.model.id }));
      }
    });
  }

  private loadEditActivity() {
    this.actions
      .pipe(ofType(activitiesActions.selectDailyActivitySidebar), takeUntil(this.isDestroyed$))
      .subscribe(($event) => {
        this.model = DeepCopyService.deepCopy($event.activity);
      });
  }

  submit() {
    this.activityForm.form.markAllAsTouched();
    if (this.activityForm.form.invalid) {
      this.notif.showError(
        'Please fill out all required fields and make sure the dates are valid.',
      );
      return;
    }

    if (this.model.id) {
      this.updateActivity();
      return;
    }

    this.saveActivity();
  }

  onProjectChange(projectId: number) {
    this.model.project_id = projectId;
    this.selectedProject = this.possibleProjects.find((p) => p.id === projectId);
  }
}
