<div class="tasks-header page-header-wrapper" [ngClass]="{ 'with-border': page === KANBAN }">
  <div class="header-container leading-none">
    <div class="navigation">
      <span class="page-title">My Tasks</span>
      <span [ngClass]="{ active: page === KANBAN }" [routerLink]="'/webapp/' + KANBAN" class="nav-item"
        >Active Tasks</span
      >
      <span [ngClass]="{ active: page === TABLE }" [routerLink]="'/webapp/' + TABLE" class="nav-item">All Tasks</span>
      <span [ngClass]="{ active: page === CALENDAR }" [routerLink]="'/webapp/' + CALENDAR" class="nav-item"
        >Calendar View</span
      >
    </div>
    <div class="filters">
      <mat-form-field class="input no-preview borderless">
        <mat-select multiple placeholder="Task Filters" (selectionChange)="taskTypeSelected($event)">
          <mat-option class="disp-none" disabled value="placeholder">Task Filters</mat-option>
          <mat-option [value]="OPTION_ALL">All</mat-option>
          <mat-option [value]="TASK_TYPE.DAILY">Daily</mat-option>
          <mat-option [value]="TASK_TYPE.CUSTOM">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input no-preview borderless">
        <mat-select multiple placeholder="Status Filters" (selectionChange)="statusSelected($event)">
          <mat-option class="disp-none" disabled value="placeholder">Status Filters</mat-option>
          <mat-option [value]="OPTION_ALL">All</mat-option>
          <mat-option [value]="TASK_STATUS_VALUE.PENDING">Pending</mat-option>
          <mat-option [value]="TASK_STATUS_VALUE.IN_PROGRESS">In progress</mat-option>
          <mat-option [value]="TASK_STATUS_VALUE.COMPLETED">Completed</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input no-preview borderless">
        <mat-select multiple placeholder="Project Filters" (selectionChange)="projectSelected($event)">
          <mat-option class="disp-none" disabled value="placeholder">Project Filters</mat-option>
          <mat-option [value]="OPTION_ALL">All</mat-option>
          <mat-option *ngFor="let project of projects$ | async" [value]="project.id">{{ project.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
