import { createAction, props } from '@ngrx/store';
import {
  Activity,
  ACTIVITY_VIEW,
  ActivityFilterKeys,
  ActivityViewTypes,
  ISidebarActivity,
} from './activities.constants';

const loadDataByView = createAction('[Activities] Load Activities By View');

const loadActivitiesKanbanFirstPage = createAction('[Activities] Load Activities By Category');
const loadActivitiesKanbanNextPage = createAction(
  '[Activities] Load Activities Page',
  props<{ activityType: ActivityViewTypes }>(),
);
const loadActivitiesLogNextPage = createAction('[Activities] Load Activity Log');
const loadActivitiesLogFirstPage = createAction('[Activities] Load Activity Log With New Filters');

const loadActivitiesCalendarFirstPage = createAction('[Activities] Load Activities Calendar');
const loadActivitiesCalendarNextPage = createAction(
  '[Activities] Load Activities Calendar Next Page',
);
const loadActivitiesCalendarMonthInBackground = createAction(
  '[Activities] Load Activities Calendar Next Month',
  props<{ direction: -1 | 1; months: number }>(),
);

const successfullyLoadedActivities = createAction(
  '[Activities] Successfully Loaded All Activities Categorized',
  props<{
    isFirstPage: boolean;
    notStarted?: Activity[];
    inProgress?: Activity[];
    completed?: Activity[];
    nextPage: {
      notStarted?: number;
      inProgress?: number;
      completed?: number;
    };
  }>(),
);

const successfullyLoadedActivityLog = createAction(
  '[Activities] Successfully Loaded Activity Log',
  props<{
    logPage: number;
    logs: Activity[];
    resetLogs: boolean;
  }>(),
);

const successfullyLoadedActivitiesCalendar = createAction(
  '[Activities] Successfully Loaded Activities Calendar',
  props<{
    activities: Activity[];
    isFirstLoad: boolean;
    direction?: number;
    startDateGTE?: string;
    startDateLT?: string;
  }>(),
);

const refreshCalendarDateWindow = createAction(
  '[Activities] Refresh Calendar Date Window',
  props<{ direction: -1 | 1; startDateGTE: string; startDateLT: string }>(),
);

const calendarActivitiesDestroyed = createAction('[Activities] Activities Calendar Destroyed');

const selectedViewChanged = createAction(
  '[Activities] Selected View Changed',
  props<{ view: ACTIVITY_VIEW }>(),
);

const filtersChanged = createAction(
  '[Activities] Filters Changed',
  props<{ [key in ActivityFilterKeys]?: any }>(),
);

const saveFilters = createAction(
  '[Activities] Save Filters',
  props<{ [key in ActivityFilterKeys]?: any }>(),
);

const setLoading = createAction(
  '[Activities] Set Loading',
  props<{ notStarted?: boolean; inProgress?: boolean; completed?: boolean }>(),
);

const setIsLoadingGeneral = createAction(
  '[Activities] Set Is Loading',
  props<{ isLoading: boolean }>(),
);

const resetActivityLogs = createAction('[Activities] Reset Activity Logs');

const assignTeammate = createAction(
  '[Activities] Assign Teammate',
  props<{ activityId: number; teammateId: number }>(),
);

const successfullyAssignedTeammate = createAction(
  '[Activities] Successfully Assigned Teammate',
  props<{ activity: Activity }>(),
);

const successfullyAddedDailyActivity = createAction(
  '[Activities] Successfully Added Daily Activity',
);

const successfullyUpdatedDailyActivity = createAction(
  '[Activities] Successfully Updated Daily Activity',
);

const addDailyActivity = createAction(
  '[Activities] Add Daily Activity fr fr',
  props<{ activity: ISidebarActivity }>(),
);

const loadActivityDailyLog = createAction(
  '[Activities] Load Activity Daily Log',
  props<{ activity: ISidebarActivity }>(),
);

const selectDailyActivitySidebar = createAction(
  '[Activities] Select Daily Activity Sidebar',
  props<{ activity: ISidebarActivity }>(),
);

const updateDailyActivity = createAction(
  '[Activities] Update Daily Activity',
  props<{ activity: ISidebarActivity }>(),
);

const activitySelected = createAction(
  // called when the user clicks on an activity - custom handling is done for every type
  '[Activities] Activity Selected',
  props<{ activity: Activity }>(),
);

const activitySelectedFromNotification = createAction(
  // called when the user clicks on a notification related to an activity - custom handling is done for every type
  '[Activities] Activity Selected From Notification',
  props<{ activityId: number }>(),
);

const deleteDailyActivity = createAction(
  '[Activities] Delete Daily Activity',
  props<{ id: number }>(),
);

const clearActivities = createAction('[Activities] Clear Activities');

export const activitiesActions = {
  loadDataByView,
  loadActivitiesKanbanFirstPage,
  loadActivitiesKanbanNextPage,
  loadActivitiesLogNextPage,
  loadActivitiesLogFirstPage,
  loadActivitiesCalendarFirstPage,
  loadActivitiesCalendarNextPage,
  loadActivitiesCalendarMonthInBackground,
  successfullyLoadedActivities,
  successfullyLoadedActivityLog,
  successfullyLoadedActivitiesCalendar,
  refreshCalendarDateWindow,
  calendarActivitiesDestroyed,
  selectedViewChanged,
  filtersChanged,
  saveFilters,
  setLoading,
  setIsLoadingGeneral,
  assignTeammate,
  successfullyAssignedTeammate,
  resetActivityLogs,
  addDailyActivity,
  successfullyAddedDailyActivity,
  loadActivityDailyLog,
  selectDailyActivitySidebar,
  updateDailyActivity,
  successfullyUpdatedDailyActivity,
  activitySelected,
  activitySelectedFromNotification,
  deleteDailyActivity,
  cancel: createAction('[Activities] Cancel'),
  clearActivities,
};
