import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuRouteService {
  events = new EventEmitter();

  constructor() {}

  setActive(page) {
    this.events.next(page);
  }
}
