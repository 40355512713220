import {
  IChecklist,
  ITaskFilters,
  ITaskSideBar,
  TASK_KANBAN_COLUMN_TYPES,
  TASK_STATUS_VALUE,
  TaskVisualizationFilter,
} from './tasks.interfaces';
import { withUser } from '../messages/messages.interfaces';
import { IPaginationInfoKanban } from './tasks.reducer';
import { SELECTED_OPTION } from '../../framework/overlays/option-list.constants';

export const NO_CHECKLIST = -1;
export const NO_PROJECT = -1;
export const FIRST_PAGE = 1;
export const SECOND_PAGE = 2;
export const THIRD_PAGE = 3;

export const noCheckList: IChecklist = {
  id: NO_CHECKLIST,
  name: 'No Checklist',
  items: [],
};
export const defaultItem = { name: '', value: false };
export const emptyCheckList: IChecklist = { name: '', items: [defaultItem] };

export enum CUSTOM_TASK_FIELD_TYPES {
  DATE = 'date',
  TEXT = 'text',
  QUESTION = 'question',
}

export const defaultCustomFieldGroup: CustomTaskFieldGroup = {
  type: CUSTOM_TASK_FIELD_TYPES.TEXT,
  label: '',
  value: '',
};

export const CustomTaskTypeToDisplay = {
  [CUSTOM_TASK_FIELD_TYPES.TEXT]: 'Input Field',
  [CUSTOM_TASK_FIELD_TYPES.DATE]: 'Date Field',
  [CUSTOM_TASK_FIELD_TYPES.QUESTION]: 'Question Field',
};

export type CustomTaskFieldType =
  | CUSTOM_TASK_FIELD_TYPES.DATE
  | CUSTOM_TASK_FIELD_TYPES.TEXT
  | CUSTOM_TASK_FIELD_TYPES.QUESTION;

export const allCustomTaskFieldTypes: CustomTaskFieldType[] = [
  CUSTOM_TASK_FIELD_TYPES.DATE,
  CUSTOM_TASK_FIELD_TYPES.TEXT,
  CUSTOM_TASK_FIELD_TYPES.QUESTION,
];

export enum CUSTOM_TASK_QUESTION_TYPES {
  YES = 'Yes',
  NO = 'No',
}

export interface ICustomTaskQuestionType {
  label: CUSTOM_TASK_QUESTION_TYPES;
  value: boolean;
}

export const CustomTaskQuestionToValue = {
  [CUSTOM_TASK_QUESTION_TYPES.YES]: true,
  [CUSTOM_TASK_QUESTION_TYPES.NO]: false,
};

export const allCustomTaskQuestionFieldTypes: ICustomTaskQuestionType[] = [
  { label: CUSTOM_TASK_QUESTION_TYPES.YES, value: true },
  { label: CUSTOM_TASK_QUESTION_TYPES.NO, value: false },
];

export const defaultFilters: ITaskFilters = {
  visualization: 'all',
  requires_response: undefined,
  project_filter: [],
  type_filter: undefined,
  page: 0,
  per_page: 100,
  with_pagination: 0,
  start_date: undefined,
  end_date: null,
  status_filter: undefined,
  order_direction: 'asc',
  order_by: 'due_date',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const tasksFirstPageLength = 100;
export const tasksDefaultPageLength = 100;

export const defaultTasksSideBar: Partial<ITaskSideBar> = {
  assigned_members: [],
  spectator_members: [],
  end_date: null,
  start_date: null,
  project_id: NO_PROJECT,
  files: [],
  checklist: noCheckList,
  currentPage: FIRST_PAGE,
  updateTaskId: null,
  custom_fields: [defaultCustomFieldGroup],
  isSideBarLoading: true,
  deletedFiles: [],
};

export const defaultPaginationFilteredTasks: IPaginationInfoKanban = {
  due: {
    page: 0,
    isLoading: false,
    isAllLoaded: false,
    isFirstPageLoading: false,
  },
  upcoming: {
    page: 0,
    isLoading: false,
    isAllLoaded: false,
    isFirstPageLoading: false,
  },
  spectating: {
    page: 0,
    isLoading: false,
    isAllLoaded: false,
    isFirstPageLoading: false,
  },
};

export type CustomTaskQuestionType = CUSTOM_TASK_QUESTION_TYPES.YES | CUSTOM_TASK_QUESTION_TYPES.NO;

export type CustomTaskFieldGroup = {
  type: CustomTaskFieldType;
  label: string;
  value: string | boolean;
};

export type TaskDropdownOptionSelected = { type: SELECTED_OPTION; taskType?: CustomTaskFieldType };
export type userSearchData = { search: string; getWithUser: withUser; project_id: number };

export const getVisualizationByColumnType = (
  columnType: TASK_KANBAN_COLUMN_TYPES,
): TaskVisualizationFilter => {
  if (columnType === TASK_KANBAN_COLUMN_TYPES.DUE_THIS_WEEK) {
    return 'due';
  } else if (columnType === TASK_KANBAN_COLUMN_TYPES.UPCOMING) {
    return 'upcoming';
  } else if (columnType === TASK_KANBAN_COLUMN_TYPES.SPECTATING) {
    return 'spectating';
  }
  return undefined;
};

export const columnTypeToVisualization: { [key: string]: 'due' | 'upcoming' | 'spectating' } = {
  [TASK_KANBAN_COLUMN_TYPES.DUE_THIS_WEEK]: 'due',
  [TASK_KANBAN_COLUMN_TYPES.UPCOMING]: 'upcoming',
  [TASK_KANBAN_COLUMN_TYPES.SPECTATING]: 'spectating',
};

// task status updates are circular: pending -> in progress -> completed -> pending -> ...
export const nextTaskStatus = {
  [TASK_STATUS_VALUE.PENDING]: TASK_STATUS_VALUE.IN_PROGRESS,
  [TASK_STATUS_VALUE.IN_PROGRESS]: TASK_STATUS_VALUE.COMPLETED,
  [TASK_STATUS_VALUE.COMPLETED]: TASK_STATUS_VALUE.PENDING,
};

export const taskStatusToText = {
  [TASK_STATUS_VALUE.PENDING]: 'Pending',
  [TASK_STATUS_VALUE.IN_PROGRESS]: 'In Progess',
  [TASK_STATUS_VALUE.COMPLETED]: 'Completed',
};

export const tasksApiDateFormat = 'YYYY-MM-DD';
