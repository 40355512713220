<div [ngStyle]="{ width: width + 'px' }" class="checklist-dropdown-wrapper">
  <ng-scrollbar class="std-scrollbar min-h-[12.5rem]" appearance="standard">
    <span
      (click)="optionSelected({ action: SELECTED_OPTION.ADD })"
      class="row text-color_secondary text-sm font-semibold cursor-pointer"
      >New Checklist</span
    >
    <span *ngIf="showNoChecklistOption" class="row">
      <span
        (click)="optionSelected({ action: SELECTED_OPTION.DESELECT })"
        class="text-shade_1 font-semibold text-sm cursor-pointer"
      >
        No Checklist
      </span>
    </span>
    <ng-container *ngFor="let checklist of checkLists; let index = index">
      <span class="row">
        <span
          (click)="optionSelected({ action: SELECTED_OPTION.SELECT, checklist })"
          class="text-shade_1 font-semibold text-sm cursor-pointer"
        >
          {{ checklist.name }}
        </span>
        <span (click)="optionSelected({ action: SELECTED_OPTION.REMOVE, checklist, index }); $event.stopPropagation()">
          <ng-container *ngIf="projectedContentTemplate; else defaultContent">
            <ng-container *ngTemplateOutlet="projectedContentTemplate"></ng-container>
          </ng-container>

          <ng-template #defaultContent>
            <span class="text-color_secondary text-sm font-semibold text-center cursor-pointer mr-1"> remove</span>
          </ng-template>
        </span>
      </span>
    </ng-container>
  </ng-scrollbar>
</div>
