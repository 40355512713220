import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'containsValue',
  standalone: true,
})
export class ContainsValuePipe implements PipeTransform {
  transform(value: unknown, ...args: any[]): unknown {
    if (isArray(args[0])) {
      return args[0].find((val) => val === value);
    }
    if (typeof value === 'string') {
      return value.includes(String(args[0]));
    }
    return false;
  }
}
