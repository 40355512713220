import { Component, OnDestroy, OnInit } from '@angular/core';
import { IGlAccountLabel, IGlTemplate } from '../../../../../store/settings/settings.interface';
import { Store } from '@ngrx/store';
import { getSelectedGlTemplate } from '../../../../../store/settings/settings.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { settingsActions } from '../../../../../store/settings/settings.actions';
import { TemplatesHelpComponent } from '../templates-help/templates-help.component';
import { FormsModule } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgIf, NgFor } from '@angular/common';
import { TemplateSelectorComponent } from '../template-selector/template-selector.component';

@Component({
  selector: 'app-glaccounts',
  templateUrl: './glaccounts.component.html',
  styleUrls: ['./glaccounts.component.scss'],
  standalone: true,
  imports: [
    TemplateSelectorComponent,
    NgIf,
    NgScrollbar,
    NgFor,
    FormsModule,
    TemplatesHelpComponent,
  ],
})
export class GLAccountsComponent implements OnInit, OnDestroy {
  isDestroyed$ = new Subject<void>();
  selectedTemplate: IGlTemplate;
  showHelp = false;
  newItemValue = '';
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(getSelectedGlTemplate)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((template) => {
        if (template) {
          this.selectedTemplate = {
            ...template,
            labels: template?.labels.filter((label) => !label.is_deleted),
          };
        }
      });
  }

  onInputChange(item: IGlAccountLabel, index: number, event) {
    const element = event.target as HTMLInputElement;
    const newItem: IGlAccountLabel = { ...item, name: element.value };
    this.store.dispatch(settingsActions.updateGlTemplateItem({ newItem }));
  }

  onNewItemChange() {
    this.store.dispatch(settingsActions.addGlTemplateItem({ newItemName: this.newItemValue }));
    this.newItemValue = '';
  }

  removeItem(item: IGlAccountLabel) {
    this.store.dispatch(settingsActions.removeGlTemplateItem({ itemId: item.id }));
  }

  onPasteEvent(fromIndex: number, event: ClipboardEvent) {
    const textToPaste = event.clipboardData.getData('text');
    const itemNames = textToPaste.split('\n');
    if (itemNames.length <= 1) {
      return;
    }
    // if more than 1 row is going to be inserted, overwrite the old values with the new ones
    event.preventDefault();
    if (!fromIndex && fromIndex !== 0) {
      // if items are inserted to the end of list (empty input), just create them
      for (const name of itemNames) {
        this.store.dispatch(settingsActions.addGlTemplateItem({ newItemName: name }));
      }
    } else {
      // if items are inserted in the middle of the items,
      // overwrite from `fromIndex` to the end of existing items
      // and then insert new ones if there is any
      let index = fromIndex;
      for (const name of itemNames) {
        const id = this.selectedTemplate.labels?.[index]?.id;
        index++;
        if (id) {
          const newItem: IGlAccountLabel = { id, name };
          this.store.dispatch(settingsActions.updateGlTemplateItem({ newItem }));
        } else {
          this.store.dispatch(settingsActions.addGlTemplateItem({ newItemName: name }));
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  showHelpGuideline(showHelp: boolean) {
    this.showHelp = showHelp;
  }

  onDiscard() {
    history.back();
  }

  onSave() {
    this.store.dispatch(settingsActions.saveGlTemplate());
  }

  unselectTemplate() {
    this.selectedTemplate = null;
  }
}
