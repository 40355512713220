import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CurrentUserService } from '../../../services/current-user.service';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { fadeIn } from '../../../../assets/styles/animations';
import { USER_MANAGER } from '../../../framework/constants/user.constants';
import { NotificationsService } from '../../../services/notifications.service';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FloatingInputComponent } from '../../../framework/inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../framework/inputs/dropdown/dropdown.component';
import { serviceProviderTypes } from '../auth.constants';
import { ROUTE_AUTH } from '../../../framework/constants/route.auth.constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SubscriptionService } from '../../../services/subscription.service';
import { ISubscriptionPlan } from '../../../framework/constants/subscription.constants';

/**
 * Check if password and re typed password does match.
 * https://stackoverflow.com/questions/67231873/angular-11-how-to-validate-confirmpassword-is-same-as-password-using-reactive-fo
 */
const passwordMatch: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const password_confirmation = control.get('password_confirmation');
  return password?.value === password_confirmation?.value ? null : { notMatched: true };
};

type FormType = {
  company_name: FormControl;
  first_name: FormControl;
  last_name: FormControl;
  email: FormControl;
  phone: FormControl;
  password: FormControl;
  password_confirmation: FormControl;
  type: FormControl;
  license?: FormControl;
};

type RegistrationData = {
  company_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  type: string;
  license?: string;
  invitation_token?: string;
  billing_plan_id?: number;
  subscription_recurring_interval?: 'month' | 'year';
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [fadeIn],
  standalone: true,
  providers: [NgForm],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    FloatingInputComponent,
    DropdownComponent,
    RouterModule,
    MatTooltipModule,
    NgScrollbarModule,
  ],
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  registration = new FormGroup<FormType>(
    {
      company_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      first_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      last_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password_confirmation: new FormControl('', [Validators.required, Validators.minLength(8)]),
      type: new FormControl(USER_MANAGER, [Validators.required]), // hidden for manager
      // license: new FormControl('', Validators.required), // hidden for manager
    },
    { validators: passwordMatch },
  );
  isDestroyed$ = new Subject();

  isManager = true;
  loading = false;
  invitationToken: string;

  readonly serviceProviderTypes = serviceProviderTypes;
  selectedPlan: 'basic' | 'premium';
  plans: ISubscriptionPlan[];

  get isServiceProviderDisabled() {
    return this.invitationToken || this.selectedPlan;
  }

  constructor(
    protected currentUserService: CurrentUserService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private notif: NotificationsService,
    private cdr: ChangeDetectorRef,
    private subscriptionService: SubscriptionService,
  ) {}

  ngOnInit() {
    this.registration.valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe((value) => {
      if (this.registration.hasError('notMatched')) {
        this.registration.get('password_confirmation').setErrors({ notMatched: true });
      } else {
        this.registration.get('password_confirmation').setErrors(null);
      }
    });
    this.subscriptionService.getAllPlans().subscribe((plans) => {
      this.plans = plans;
    });
  }

  ngAfterViewInit() {
    // delay is needed to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.isDestroyed$), delay(20))
      .subscribe(this.parseQueryParams.bind(this));
  }

  private parseQueryParams(params: Params) {
    if (params?.invitation_token) {
      this.invitationToken = params.invitation_token;
      this.cdr.detectChanges();
    }
    if (params?.email) {
      this.registration.get('email').setValue(params.email);
    }
    if (params?.first_name) {
      this.registration.get('first_name').setValue(params.first_name);
    }
    if (params?.last_name) {
      this.registration.get('last_name').setValue(params.last_name);
    }
    if (params?.selected_plan) {
      this.selectedPlan = params.selected_plan;
    }
  }
  async register() {
    this.loading = true;
    this.notif.showLoading();
    const registrationData: RegistrationData = { ...this.registration.getRawValue() };

    if (this.invitationToken) {
      registrationData.invitation_token = this.invitationToken;
    }

    if (this.selectedPlan) {
      // send the selected plan's id to create a subscription on backend without navigating to stripe checkout
      const plan = this.plans.find((plan) => plan.name.toLowerCase().includes(this.selectedPlan));
      if (plan) {
        registrationData.billing_plan_id = plan.id;
        // this is hardcoded for now, but we might want to add a way to select it in the future
        registrationData.subscription_recurring_interval = 'month';
      } else {
        console.warn('Plan not found', this.selectedPlan);
      }
    }

    try {
      await this.currentUserService.register(registrationData);
      this.notif.showSuccess('Registration completed.');

      await this.router.navigate(['auth', ROUTE_AUTH.THANK_YOU], {
        queryParams: { invitation: !!this.invitationToken },
      });
    } catch (error) {
      const errorMessage = error.error[Object.keys(error.error)[0]];
      let isString = false;
      if (typeof errorMessage === 'string' || errorMessage instanceof String) {
        isString = true;
      }
      this.notif.showError(
        `An error occurred during register${isString ? ': ' + errorMessage : '.'} `,
      );
    } finally {
      this.loading = false;
    }
  }

  toggleIsManager(isManager: boolean) {
    if (!isManager) {
      this.registration.get('type').setValue(null);
      this.registration.addControl('license', new FormControl('', [Validators.required]));
    } else {
      this.registration.get('type').setValue(USER_MANAGER);
      this.registration.removeControl('license');
    }
    this.isManager = isManager;
    this.registration.markAsUntouched();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
