import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  BudgetHistoryData,
  ProjectSpendService,
} from '../../../../../../services/project-spend.service';
import { InteractionBarStateService } from '../../../../../../services/interaction-bar-state.service';
import { DeepCopyService } from '../../../../../../services/deep-copy.service';
import { viewProjectActions } from '../../../../../../store/view-project/view-project.actions';
import { Store } from '@ngrx/store';
import { PROJECT_VIEWS } from '../../../../../../framework/constants/view-project.constants';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import { SELECTED_OPTION } from '../../../../../../framework/overlays/option-list.constants';
import { MoneyPipe } from '../../../../../../pipes/framework/money-short.pipe';
import { SpendCustomInputComponent } from '../../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { FloatingInputComponent } from '../../../../../../framework/inputs/floating-input/floating-input.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { SimpleButtonComponent } from '../../../../../../framework/buttons/simple-medium-button/simple-button.component';

const leaveAnimationTiming = 500;

@Component({
  selector: 'app-project-budget-history',
  templateUrl: './project-budget-history.component.html',
  styleUrls: ['./project-budget-history.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(100vw)', opacity: 0 }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(
          `${leaveAnimationTiming}ms ease-in-out`,
          style({ transform: 'translateX(100vw)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    SimpleButtonComponent,
    NgClass,
    NgScrollbar,
    NgTemplateOutlet,
    FloatingInputComponent,
    SpendCustomInputComponent,
    MoneyPipe,
  ],
})
export class ProjectBudgetHistoryComponent implements OnInit {
  @Input({ required: true }) projectId: number;
  isLoading = true;
  hidden = false;
  spends = [];
  lineItems: BudgetHistoryData[] = []; // formatted
  dates = [];

  totals: {
    original_budget: number;
    current_budget: number;
    adjustments: any[];
  } = { original_budget: 0, current_budget: 0, adjustments: [] };

  constructor(
    private spendSvc: ProjectSpendService,
    private interactionBar: InteractionBarStateService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.spendSvc.getAll(this.projectId).then((spends) => {
      this.isLoading = false;
      this.spends = DeepCopyService.deepCopy(spends);
      const { lineItems, dates, totals } = this.spendSvc.getBudgetAdjustments(
        this.projectId,
        spends,
      );
      this.lineItems = lineItems;
      this.dates = dates;
      this.totals = totals;
    });
  }

  closeView() {
    this.store.dispatch(viewProjectActions.viewChanged({ view: PROJECT_VIEWS.DEFAULT }));
  }

  openBudgetLog() {
    this.interactionBar.openBudgetAdjustmentLog(this.spends);
  }

  protected readonly SPEND_TYPES = SPEND_TYPES;
  protected readonly SELECTED_OPTION = SELECTED_OPTION;
  protected readonly Math = Math;
}
