<div class="spend-templates-wrapper px-8 pt-7 pb-3">
  <div class="text-color_primary text-2xl font-semibold text-capitalize mb-6">Spend Templates</div>
  <div class="templates-header flex gap-7 pt-1 pb-4 border-b solid border-shade_5">
    <!--    <span-->
    <!--      [ngClass]="{ 'text-shade_2': currentTab === TEMPLATE_TABS.ACCOUNT_TEMPLATE }"-->
    <!--      class="text-color_secondary font-semibold text-sm cursor-pointer"-->
    <!--      (click)="selectTab(TEMPLATE_TABS.ACCOUNT_TEMPLATE)"-->
    <!--    >-->
    <!--      Account Templates-->
    <!--    </span>-->
    <span
      [ngClass]="{ 'text-shade_2': currentTab === TEMPLATE_TABS.PROJECT_TEMPLATES }"
      class="text-color_secondary font-semibold text-sm cursor-pointer"
      (click)="selectTab(TEMPLATE_TABS.PROJECT_TEMPLATES)"
    >
      Project Templates
    </span>
    <span
      [ngClass]="{ 'text-shade_2': currentTab === TEMPLATE_TABS.BUDGET_TAG_TEMPLATES }"
      class="text-color_secondary font-semibold text-sm cursor-pointer"
      (click)="selectTab(TEMPLATE_TABS.BUDGET_TAG_TEMPLATES)"
    >
      Budget Tag Templates
    </span>
  </div>
  <!--  <ng-container *ngIf="currentTab === TEMPLATE_TABS.ACCOUNT_TEMPLATE">-->
  <!--    <app-glaccounts></app-glaccounts>-->
  <!--  </ng-container>-->
  <ng-container *ngIf="currentTab === TEMPLATE_TABS.PROJECT_TEMPLATES">
    <app-budget-templates (gotToTagTemplates)="selectTab(TEMPLATE_TABS.BUDGET_TAG_TEMPLATES)"></app-budget-templates>
  </ng-container>
  <ng-container *ngIf="currentTab === TEMPLATE_TABS.BUDGET_TAG_TEMPLATES">
    <app-budget-tag-templates></app-budget-tag-templates>
  </ng-container>
</div>
