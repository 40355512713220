import packageInfo from '../../package.json';

export const environment = {
  apiUrl: 'https://staging-api.skillhop.com/v1/',
  production: true,
  sentryProduction: false,
  enableServiceWorker: true,
  version: packageInfo.version,
  pusher_key: 'a9e0b1106d1d31fd4958',
  hotjar_key: 3009977,
  sentry_dsn: 'https://22b452a4745046f89ca47328bf253762@o945779.ingest.sentry.io/6482597',
  syncfusion: 'ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdENjWntddX1XRGdd',
  test_user_m: 'test.front.manager2@steepsoft.com',
  test_user_m2: 'test.front.manager3@steepsoft.com',
  test_user_c: 'test.front.contractor3@steepsoft.com',
  test_pass: '1234qwer',
};

console.log(packageInfo.version);
console.log('environment: staging');
