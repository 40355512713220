<ng-scrollbar class="std-scrollbar">
  <div class="register-wrapper">
    <h1 class="title">Welcome To Skillhop!</h1>
    <div *ngIf="selectedPlan" class="mt-2 mb-0 text-shade_2 text-size_3xl">
      Your selected plan is: <span class="font-semibold">{{ selectedPlan | titlecase }}.</span>
    </div>
    <div class="mb-0 text-shade_2 text-size_3xl">
      <ng-container *ngIf="!isServiceProviderDisabled"> Select your account category </ng-container>
      <ng-container> You cannot register as a Service Provider. </ng-container>
    </div>
    <div class="btn-container">
      <button
        (click)="toggleIsManager(true)"
        class="landing-btn cubic btn-height-big w-100"
        [ngClass]="{
          'outline-btn': isManager,
          'btn-disabled': !isManager
        }"
      >
        Managers
      </button>
      <button
        (click)="toggleIsManager(false)"
        class="landing-btn cubic btn-height-big w-100 ml-4"
        [ngClass]="{
          'outline-btn': !isManager,
          'btn-disabled': isManager,
          disabled: isServiceProviderDisabled
        }"
      >
        Service Providers
      </button>
    </div>
    <div class="form-wrapper" (keyup.enter)="register()">
      <form *ngIf="isManager" class="form-container" [formGroup]="registration">
        <app-floating-input
          [autoComplete]="false"
          label="Company"
          placeholder="Company"
          formControlName="company_name"
          ngDefaultControl
        >
        </app-floating-input>
        <app-floating-input
          [disabled]="!!invitationToken"
          [autoComplete]="false"
          label="Email"
          placeholder="Email"
          formControlName="email"
          ngDefaultControl
        >
        </app-floating-input>

        <app-floating-input
          [autoComplete]="false"
          label="First Name"
          placeholder="First Name"
          formControlName="first_name"
          ngDefaultControl
        >
        </app-floating-input>
        <app-floating-input
          [autoComplete]="false"
          label="Phone"
          placeholder="Phone"
          formControlName="phone"
          type="tel"
          ngDefaultControl
        >
        </app-floating-input>

        <app-floating-input
          name="wasd"
          [autoComplete]="false"
          label="Last Name"
          placeholder="Last Name"
          formControlName="last_name"
          ngDefaultControl
        >
        </app-floating-input>
        <div class="flex gap-4">
          <app-floating-input
            [autoComplete]="false"
            class="min-w-0 flex-1"
            type="password"
            label="Password"
            placeholder="Password"
            formControlName="password"
            ngDefaultControl
          >
          </app-floating-input>
          <app-floating-input
            [autoComplete]="false"
            class="min-w-0 flex-1"
            type="password"
            label="Re-Enter"
            placeholder="Re-Enter"
            formControlName="password_confirmation"
            ngDefaultControl
          >
          </app-floating-input>
        </div>
      </form>

      <div *ngIf="!isManager" class="form-container" [formGroup]="registration">
        <app-dropdown
          [options]="serviceProviderTypes"
          label="Service Provider Type"
          formControlName="type"
          ngDefaultControl
        ></app-dropdown>
        <app-floating-input
          [autoComplete]="false"
          label="Email"
          placeholder="Email"
          formControlName="email"
          ngDefaultControl
        >
        </app-floating-input>

        <app-floating-input
          [autoComplete]="false"
          label="Company"
          placeholder="Company"
          formControlName="company_name"
          ngDefaultControl
        >
        </app-floating-input>
        <app-floating-input
          [autoComplete]="false"
          label="First Name"
          placeholder="First Name"
          formControlName="first_name"
          ngDefaultControl
        >
        </app-floating-input>

        <app-floating-input
          [autoComplete]="false"
          label="License Number"
          placeholder="License Number"
          formControlName="license"
          ngDefaultControl
        >
        </app-floating-input>
        <app-floating-input
          [autoComplete]="false"
          label="Last Name"
          placeholder="Last Name"
          formControlName="last_name"
          ngDefaultControl
        >
        </app-floating-input>

        <app-floating-input
          [autoComplete]="false"
          label="Phone"
          placeholder="Phone"
          formControlName="phone"
          type="tel"
          ngDefaultControl
        >
        </app-floating-input>
        <div class="flex gap-4">
          <app-floating-input
            [autoComplete]="false"
            class="min-w-0 flex-1"
            type="password"
            label="Password"
            placeholder="Password"
            formControlName="password"
            ngDefaultControl
          >
          </app-floating-input>
          <app-floating-input
            class="min-w-0 flex-1"
            type="password"
            label="Re-Enter"
            placeholder="Re-Enter"
            formControlName="password_confirmation"
            ngDefaultControl
          >
          </app-floating-input>
        </div>
      </div>

      <div class="form-container gap-y-8 grid-">
        <div class="login-instead order-3 sm:order-1">
          <button class="landing-btn outline-btn btn-big w-100 cubic" mat-button routerLink="/auth/login">
            LOGIN INSTEAD
          </button>
        </div>
        <div class="order-2">
          <button
            class="landing-btn yellow-btn btn-big w-100 cubic"
            [ngClass]="{ 'btn-disabled': registration.invalid }"
            mat-button
            [matTooltip]="registration.invalid ? 'Please enter valid data' : ''"
            (click)="registration.valid && register()"
          >
            REGISTER
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-scrollbar>
