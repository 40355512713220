<div *ngIf="isLoading" [ngClass]="{ 'notification-container-show': isOpened }" class="loading-container">
  <div class="loading-text">
    <span class="loading-message">{{ message }}</span>
    <div class="loading-rotate">
      <span class="icon-refresh"></span>
    </div>
  </div>
</div>

<div
  (click)="close()"
  *ngIf="isNotification && !isLoading"
  [ngClass]="{
    'notification-container-show': isOpened,
    'notify-success': isResolved
  }"
  class="loading-container error-notification"
>
  <div class="loading-text">
    <span class="loading-message">{{ message }}</span>
    <div *ngIf="!isResolved" class="loading-error">
      <span class="icon-add"></span>
    </div>
    <div *ngIf="isResolved" class="loading-success">
      <span class="icon-double-checkmark"></span>
    </div>
  </div>
</div>

<div
  *ngIf="!isNotification && !isLoading"
  [ngClass]="{
    'notification-container-show': isOpened
  }"
  class="loading-container"
>
  <div class="loading-text">
    <span class="loading-message">{{ message }}</span>
    <div (click)="acceptPopup()" class="loading-success padding-popup">
      <span class="btn-small icon-double-checkmark"></span>
    </div>
    <div (click)="rejectPopup()" class="loading-error">
      <span class="btn-small icon-add"></span>
    </div>
  </div>
</div>
