import { EventEmitter, Injectable } from '@angular/core';
import {
  INTERACTION_BAR_STATES,
  InteractionBarState,
} from '../framework/constants/interaction-bar.constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-state';
import { loadGroupFromMention } from '../store/messages/messages.actions';
import { ProjectSpend } from './project-spend.service';
import { spendActionTypes } from '../store/spend/spend.actions';

@Injectable({
  providedIn: 'root',
})
export class InteractionBarStateService {
  public static isBarCloseable = new EventEmitter();
  public static state = INTERACTION_BAR_STATES.NONE;
  public static lastActiveState = INTERACTION_BAR_STATES.NONE;
  public static clickedEvents = new EventEmitter();
  public static announceStateStatic = new BehaviorSubject<InteractionBarState>({
    action: INTERACTION_BAR_STATES.NONE,
  });
  // todo refactor with just static announceStateStatic, remove announceState
  public announceState = InteractionBarStateService.announceStateStatic;
  public leftBarState = new EventEmitter();

  meetingCount = new Subject();
  msgCount = new Subject();
  notifCount = new Subject<number>();

  constructor(private store: Store<AppState>) {}

  setDisableClose(value): void {
    InteractionBarStateService.isBarCloseable.emit(value);
  }

  openReSchedule(currentSchedule) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.RE_SCHEDULE;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.RE_SCHEDULE,
      data: currentSchedule,
    });
  }

  openReScheduleByContractor(contractorId) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR,
      data: contractorId,
    });
  }

  openReScheduleByProjectAndContracotr(contractorId, projectId) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.RE_SCHEDULE_BY_PROJECT,
      data: { projectId, contractorId },
    });
  }

  openReScheduleByProject(projectId) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.RE_SCHEDULE_BY_PROJECT;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.RE_SCHEDULE_BY_PROJECT,
      data: { projectId },
    });
  }

  openCommitments(commitmentType: INTERACTION_BAR_STATES) {
    InteractionBarStateService.lastActiveState = commitmentType;
    InteractionBarStateService.state = commitmentType;
    this.announceState.next({
      action: commitmentType,
    });
  }

  openRollupSidebar() {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.ROLLUP_INTERACTION_BAR;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.ROLLUP_INTERACTION_BAR;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.ROLLUP_INTERACTION_BAR,
    });
  }

  openAddInvoice(project, commitments = null, file = null) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.ADD_INVOICE;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.ADD_INVOICE;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.ADD_INVOICE,
      data: { project, file, commitments },
    });
  }

  openBudgetAdjustment(data: { projectId; lineItems }) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT,
      data,
    });
  }

  openAnticipatedCosts() {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.ANTICIPATED_COSTS;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.ANTICIPATED_COSTS;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.ANTICIPATED_COSTS,
      data: {},
    });
  }

  openBudgetAdjustmentLog(data) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT_LOG;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT_LOG;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.BUDGET_ADJUSTMENT_LOG,
      data: [...data],
    });
  }

  openMessagePerson(user, project = null) {
    user = { ...user };
    user.queryed_user = user.id;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.MESSAGES;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.MESSAGES,
      person: user,
      project,
    });
  }

  openMessageGroupFromNotification(notification) {
    setTimeout(() => {
      this.announceState.next({
        action: INTERACTION_BAR_STATES.MESSAGES,
      });
      this.store.dispatch(loadGroupFromMention({ groupId: notification.trigger_object_id }));
    }, 100);
  }

  close() {
    const oldState = InteractionBarStateService.state;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.NONE;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.CLOSE,
      oldState,
    });
  }

  openSchedule() {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.SCHEDULE;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.SCHEDULE,
    });
  }

  openSelectProject() {
    this.announceState.next({
      action: INTERACTION_BAR_STATES.SELECT_PROJECT,
    });
  }

  openInviteProject(contractorId, title) {
    this.announceState.next({
      action: INTERACTION_BAR_STATES.SELECT_PROJECT,
      contractorId,
      title,
    });
  }

  successClose() {
    const oldState = InteractionBarStateService.state;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.SUCCESS_CLOSED;

    this.announceState.next({ action: INTERACTION_BAR_STATES.SUCCESS_CLOSED, oldState });
  }

  openEditInvoice(project, invoice) {
    const data = { project, invoice };
    InteractionBarStateService.state = INTERACTION_BAR_STATES.EDIT_INVOICE;
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.EDIT_INVOICE;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.EDIT_INVOICE,
      data,
    });
  }

  openMeetings() {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.MEETINGS;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.MEETINGS,
    });
  }

  openReporting(data: { properties; projects }) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.REPORTING;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.REPORTING,
      data,
    });
  }

  openInvoiceReportPanel(data: { properties; projects }) {
    InteractionBarStateService.state = INTERACTION_BAR_STATES.REPORTING_INVOICE_PANEL;

    this.announceState.next({
      action: INTERACTION_BAR_STATES.REPORTING_INVOICE_PANEL,
      data,
    });
  }

  editProperties(property, file = null) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.EDIT_PROPERTY;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.EDIT_PROPERTY;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.EDIT_PROPERTY,
      data: { property, file },
    });
  }

  openProperties(property, file = null) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.ADD_PROPERTY;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.ADD_PROPERTY;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.ADD_PROPERTY,
      data: { property, file },
    });
  }

  openShareFile(itemData) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.FILE_SHARE;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.FILE_SHARE;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.FILE_SHARE,
      data: itemData,
    });
  }

  openShareLink(itemData) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.DOCUMENTS_LINK_SHARE;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.DOCUMENTS_LINK_SHARE;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.DOCUMENTS_LINK_SHARE,
      data: itemData,
    });
  }

  openSpendLineItemSummary(spend: ProjectSpend) {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.LINE_ITEMS_SPEND_SUMMARY;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.LINE_ITEMS_SPEND_SUMMARY;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.LINE_ITEMS_SPEND_SUMMARY,
    });
    this.store.dispatch(spendActionTypes.loadSpendLineItemSummary({ lineItemId: spend.id }));
  }

  openInteractionBar(actionState: INTERACTION_BAR_STATES, data?: any) {
    InteractionBarStateService.lastActiveState = actionState;
    InteractionBarStateService.state = actionState;
    this.announceState.next({
      action: actionState,
      data,
    });
  }

  openProjectSetup() {
    InteractionBarStateService.lastActiveState = INTERACTION_BAR_STATES.SETUP_PROJECT;
    InteractionBarStateService.state = INTERACTION_BAR_STATES.SETUP_PROJECT;
    this.announceState.next({
      action: INTERACTION_BAR_STATES.SETUP_PROJECT,
      data: {},
    });
  }
}
