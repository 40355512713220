import { Pipe, PipeTransform } from '@angular/core';
import * as uuid from 'uuid';

@Pipe({
  name: 'isValidUUID',
  standalone: true,
})
export class IsValidUUIDPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return !!uuid.validate(value);
  }
}
