<div class="interaction-bar-wrapper">
  <div class="interaction-bar-title default-cursor">
    Meetings
    <span class="counter">({{ contractors.length }})</span>
  </div>

  <div class="interaction-bar-content" *ngIf="user.isManager">
    <app-calendar (dateChange)="registerDateChange($event)" [eventType]="'by-user'" #calendar></app-calendar>
    <div class="no-meetings default-cursor" *ngIf="contractors.length == 0 && !isContractorLoading">No Meetings</div>
    <div class="no-meetings default-cursor" *ngIf="contractors.length == 0 && isContractorLoading">
      <span class="icon-refresh animate-spin"></span>
    </div>
    <div class="contractors-list">
      <ng-scrollbar class="std-scrollbar">
        <app-contractor
          *ngFor="let contractor of contractors"
          [data]="contractor"
          [showVisitDate]="true"
        ></app-contractor>
      </ng-scrollbar>
    </div>
  </div>

  <div class="interaction-bar-content" *ngIf="!user.isManager">
    <app-calendar (dateChange)="registerDateChange($event)" [eventType]="'by-schedule'"></app-calendar>
    <div class="no-meetings" *ngIf="contractors.length == 0 && !isContractorLoading">No Meetings</div>
    <div class="no-meetings" *ngIf="contractors.length == 0 && isContractorLoading">
      <span class="icon-refresh animate-spin"></span>
    </div>
    <div class="contractors-list">
      <ng-scrollbar class="std-scrollbar">
        <app-contractor
          *ngFor="let contractor of contractors"
          [data]="contractor"
          [showVisitDate]="true"
        ></app-contractor>
      </ng-scrollbar>
    </div>
  </div>
</div>
