import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { MessagingFooterService } from '../../../services/messaging-footer.service';

@Component({
  selector: 'app-messages-footer',
  templateUrl: './messages-footer.component.html',
  styleUrls: ['./messages-footer.component.scss'],
  standalone: true,
})
export class MessagesFooterComponent implements OnInit, OnDestroy {
  isDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private footerService: MessagingFooterService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
  }

  onSave() {
    this.footerService.emitSave();
  }

  onDiscard() {
    this.footerService.emitDiscard();
  }
}
