<div class="messages-header-wrapper flex justify-between items-center">
  <div *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_LIST" class="left-container flex items-center">
    <span class="icon icon-new-message-plus-chat" (click)="openCreateDiscussion()"></span>
    <span class="separator"></span>
    <div class="filter-wrapper">
      <mat-form-field subscriptSizing="dynamic" class="borderless">
        <mat-select class="mat-select" [value]="defaultDiscussionType">
          <mat-option
            class="mat-option"
            (onSelectionChange)="onTypeFilterChange($event)"
            [value]="discussionType.type"
            *ngFor="let discussionType of discussionTypeList"
          >
            {{ discussionType.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div
    (click)="goBack()"
    class="flex items-center cursor-pointer"
    *ngIf="
      (selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_VIEW ||
      (selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_CREATE
    "
  >
    <app-back-button-box class="text-shade_2 font-semibold">Discussions</app-back-button-box>
  </div>

  <div
    (click)="goBack(MESSAGING_VIEWS.DISCUSSION_VIEW)"
    class="flex items-center cursor-pointer"
    *ngIf="(selectedView$ | async) === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW"
  >
    <app-back-button-box class="text-shade_2 font-semibold">{{ (selectedGroup$ | async)?.name }}</app-back-button-box>
  </div>
  <div class="right-container">
    <!--    <app-search-bar [iconToRight]="true" class='search-bar'></app-search-bar>-->
    <app-search-bar-minimalist
      [clearValue]="selectedView$ | async"
      (searchText)="onTextFilterChange($event)"
    ></app-search-bar-minimalist>
  </div>
</div>
