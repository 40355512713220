import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { DateCustomPipe } from '../../../../pipes/framework/date-custom.pipe';
import {
  ITask,
  ITaskSideBar,
  ITaskStatusType,
  TASK_TYPE,
  taskStatuses,
} from '../../../../store/tasks/tasks.interfaces';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import {
  decrementPage,
  incrementPage,
  loadAllCheckList,
  saveTaskToBackend,
  updateSidebarTask,
} from '../../../../store/tasks/tasks.actions';
import { Observable, Subject } from 'rxjs';
import { getErrorMessage } from 'src/app/framework/constants/messages.constants';
import {
  FIRST_PAGE,
  NO_PROJECT,
  SECOND_PAGE,
  THIRD_PAGE,
} from '../../../../store/tasks/tasks.constants';
import { REST_DRIVE_FILES } from 'src/app/restApi/RestRoutes';
import { getCurrentPage, getUpdateTaskID } from '../../../../store/tasks/tasks.selectors';
import { NgForm } from '@angular/forms';
import { TasksInteractionBarThirdPageComponent } from '../tasks-interaction-bar-third-page/tasks-interaction-bar-third-page.component';
import { TasksInteractionBarSecondPageComponent } from '../tasks-interaction-bar-second-page/tasks-interaction-bar-second-page.component';
import { TasksInteractionBarFirstPageComponent } from '../tasks-interaction-bar-first-page/tasks-interaction-bar-first-page.component';
import { SELECTED_OPTION } from '../../../overlays/option-list.constants';

@Component({
  selector: 'app-tasks-interaction-bar-common',
  templateUrl: './tasks-interaction-bar-common.component.html',
  styleUrls: ['./tasks-interaction-bar-common.component.scss'],
  standalone: true,
})
export class TasksInteractionBarCommonComponent implements OnInit, OnDestroy {
  @Input() model: Partial<ITaskSideBar>;
  @ViewChild('taskForm') taskForm: NgForm;
  @ViewChild('firstPageComponent') firstPageComponent: TasksInteractionBarFirstPageComponent;
  @ViewChild('secondPageComponent') secondPageComponent: TasksInteractionBarSecondPageComponent;
  @ViewChild('thirdPageComponent') thirdPageComponent: TasksInteractionBarThirdPageComponent;
  @ViewChild('pageComponent') pageComponent:
    | TasksInteractionBarFirstPageComponent
    | TasksInteractionBarSecondPageComponent
    | TasksInteractionBarThirdPageComponent;
  getErrorMessage = getErrorMessage;
  getUpdateTaskID$: Observable<number> = this.store.select(getUpdateTaskID);
  getCurrentPage$: Observable<number> = this.store.select(getCurrentPage);
  isDestroyed$ = new Subject();
  NO_PROJECT = NO_PROJECT;
  FIRST_PAGE = FIRST_PAGE;
  SECOND_PAGE = SECOND_PAGE;
  THIRD_PAGE = THIRD_PAGE;
  REST_DRIVE_FILES = REST_DRIVE_FILES;
  taskStatuses: ITaskStatusType[] = taskStatuses;
  SELECTED_OPTION = SELECTED_OPTION;
  taskTypes: TASK_TYPE[] = [TASK_TYPE.DAILY, TASK_TYPE.CUSTOM];
  itemOptions = [''];

  constructor(
    protected store: Store<AppState>,
    protected interactionBarStateService: InteractionBarStateService,
    protected timeZonePipe: DateCustomPipe,
  ) {}

  get deepCopyModel(): Partial<ITask> {
    return DeepCopyService.deepCopy(this.model);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {}

  updateSidebarTask(value?: string | any) {
    this.store.dispatch(updateSidebarTask({ task: this.deepCopyModel }));
  }

  onSubmit() {
    if (this.pageComponent.isFormValid()) {
      this.store.dispatch(saveTaskToBackend());
    }
  }

  onNextPage() {
    if (this.pageComponent.isFormValid()) {
      this.store.dispatch(incrementPage());
    }
  }

  goBack() {
    this.store.dispatch(decrementPage());
  }

  close() {
    this.interactionBarStateService.close();
  }

  loadCheckList() {
    this.store.dispatch(loadAllCheckList());
  }

  isFormValid() {
    this.taskForm.form.markAllAsTouched();
    return this.taskForm.form.valid;
  }
}
