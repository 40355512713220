<div class="tab-container">
  <div class="spreadsheet-container" *ngIf="(spendState$ | async) === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET">
    <app-spreadsheet
      class="tab-content-wrapper"
      [data]="spreadsheetData"
      [selectedBudgetType]="selectedBudgetType$ | async"
      [months]="MONTHS"
      #spreadsheet
    ></app-spreadsheet>
  </div>

  <ng-template [ngIf]="(spendState$ | async) !== SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET" #spendDistribution>
    <div class="tab-content-wrapper spend-distribution-wrapper">
      <div *ngIf="(spendState$ | async) === SPEND_DISTRIBUTION_STATE.LOADING" class="loading-screen">
        <span class="icon-refresh animate-spin"></span>
      </div>

      <div class="header-wrapper form">
        <div class="column titles-column left head-row">
          <div class="titles-rows">
            <!--            budget type selector-->
            <!--            <app-dropdown-->
            <!--              (modelChange)="setBudgetType($event)"-->
            <!--              [disabled]="-->
            <!--                (spendState$ | async) === SPEND_DISTRIBUTION_STATE.EDIT_PROJECT ||-->
            <!--                (spendState$ | async) === SPEND_DISTRIBUTION_STATE.ADD_PROJECT-->
            <!--              "-->
            <!--              [model]="selectedBudgetType$ | async"-->
            <!--              name="budget_dropdown"-->
            <!--              appendTo="body"-->
            <!--              class="spend-app-dropdown"-->
            <!--              optionValue="name"-->
            <!--              optionLabel="name"-->
            <!--              [options]="spendBudgetTypes"-->
            <!--              [showLabel]="false"-->
            <!--              [showError]="false"-->
            <!--            >-->
            <!--              <ng-template #selectedContent let-option>-->
            <!--                <div class="font-semibold text-sm text-color_primary">{{ option?.name }}</div>-->
            <!--              </ng-template>-->
            <!--            </app-dropdown>-->

            <app-dropdown
              [(model)]="templateId"
              (modelChange)="setTemplate($event)"
              [disabled]="
                (hasCommitments$ | async) ||
                (selectedBudgetType$ | async) !== SPEND_TYPES.BUDGET ||
                (areTemplatesLoading$ | async)
              "
              name="template_dropdown"
              appendTo="body"
              class="spend-app-dropdown mod-spend-bg"
              optionValue="id"
              optionLabel="name"
              [options]="templates"
              [showLabel]="false"
              [showError]="false"
            >
              <ng-template #optionContent let-option>
                <div class="font-semibold text-sm text-color_primary dropdown-item">
                  {{ option?.name }}
                </div>
              </ng-template>
              <ng-template #selectedContent let-option>
                <div class="font-semibold text-sm text-color_primary dropdown-item selected">
                  @if (areTemplatesLoading$ | async) {
                    Loading... <span class="icon-refresh animate-spin"></span>
                  } @else if (templateId === DEFAULT_TEMPLATE_ID) {
                    {{ DEFAULT_TEMPLATE_NAME }}
                  } @else {
                    {{ option?.name }}
                  }
                </div>
              </ng-template>
            </app-dropdown>
          </div>
          <div class="budget-dollars">
            @switch (selectedBudgetType$ | async) {
              @case (SPEND_TYPES.BUDGET) {
                Budget
              }
              @case (SPEND_TYPES.FORECAST) {
                Committed
              }
              @case (SPEND_TYPES.ACTUALS) {
                Invoiced
              }
            }
          </div>
        </div>
        <div
          [ngClass]="{
            'show-one': selectedBudgetType === SPEND_TYPES.FORECAST,
            'hide-first': selectedBudgetType === SPEND_TYPES.ACTUALS
          }"
          class="form column head-row selections"
        >
          <div [ngClass]="{ hidden: selectedBudgetType === SPEND_TYPES.ACTUALS }" class="distribution-levers">
            {{ selectedBudgetType === SPEND_TYPES.BUDGET ? 'Cashflow Distribution Levers' : 'Allocation Lever' }}
          </div>

          <app-dropdown
            (modelChange)="setSelectedYear($event)"
            [model]="selectedYear$ | async"
            [options]="possibleYearsOptions"
            [showError]="false"
            [showLabel]="false"
            appendTo="body"
            class="spend-app-dropdown pl-4"
            name="year-column fiscal_year_dropdown"
            optionValue="value"
            optionLabel="key"
          >
            <ng-template #selectedContent let-option>
              <div class="font-semibold text-sm text-color_primary dropdown-item">
                <!--                <span class="pr-2"> Fiscal Year: </span>-->
                <span> FY{{ option?.key }} </span>
              </div>
            </ng-template>
            <ng-template #optionContent let-option>
              <div
                class="dropdown-item selected"
                [ngClass]="option?.value === ADD_YEAR ? 'text-color_secondary' : 'text-color_primary'"
              >
                {{ option?.key }}
              </div>
            </ng-template>
          </app-dropdown>

          <div></div>
          <!--          <div-->
          <!--            [ngClass]="{ disabled: selectedBudgetType === SPEND_TYPES.ACTUALS }"-->
          <!--            (click)="clearMonths()"-->
          <!--            class="btn btn-small clear"-->
          <!--          >-->
          <!--            Clear Months-->
          <!--          </div>-->

          <app-simple-button
            size="large"
            [wrapperClass]="{
              'bg-white border rounded border-solid border-color_secondary text-color_secondary text-sm font-semibold': true
            }"
            class="ml-auto flex items-center justify-end pr-2.5 min-w-max"
            (click)="openSpreadsheet()"
            >Spreadsheet View</app-simple-button
          >
        </div>
      </div>

      <ng-scrollbar visibility="always" appearance="compact" #scrollbar track="all" class="std-scrollbar outside">
        <div
          scrollViewport
          cdkDropListOrientation="vertical"
          cdkDropListLockAxis="y"
          cdkDropList
          class="data-wrapper"
          [ngClass]="{
            'cursor-grab': isDragging
          }"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="form lines-wrapper header-titles">
            <div #leftSide class="left">
              <div class="form column titles-column box-border pl-6 ml-px">Budget Lines</div>
              <div class="budget-area column column-header">
                <ng-container *ngIf="selectedBudgetType === SPEND_TYPES.BUDGET">Budget</ng-container>
                <ng-container *ngIf="selectedBudgetType === SPEND_TYPES.FORECAST">Committed</ng-container>
                <ng-container *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS">Invoiced</ng-container>
              </div>
            </div>
            <div
              [ngClass]="{
                right: selectedBudgetType !== SPEND_TYPES.ACTUALS,
                'show-one': selectedBudgetType === SPEND_TYPES.FORECAST
              }"
            >
              <div
                class="distribution-type-area"
                [ngClass]="{
                  'disp-none': selectedBudgetType === SPEND_TYPES.ACTUALS,
                  'show-one': selectedBudgetType !== SPEND_TYPES.BUDGET
                }"
              >
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column">
                  Start Date
                </div>
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column">
                  Duration
                </div>
                <div class="column">Distribution</div>
              </div>
              <div class="months months-header">
                <div>year total</div>
                <div *ngFor="let month of MONTHS; trackBy: trackByIndex">{{ month }}</div>
              </div>
            </div>
          </div>
          <div
            cdkDrag
            (cdkDragStarted)="isDragging = true"
            (cdkDragEnded)="isDragging = false"
            class="form column lines-wrapper"
            *ngFor="let item of items; let lineIndex = index; trackBy: trackByItemId"
          >
            <div class="left">
              <div class="form column titles-column">
                <div class="pr-[15px] -mt-1 box-border flex gap-1">
                  <div
                    cdkDragHandle
                    class="icon-draggable flex items-center justify-center text-color_secondary cursor-grab"
                  ></div>
                  <app-floating-input
                    class="mod-spend-bg w-full"
                    [name]="'title_' + item.id"
                    (change)="registerChangeName($event.target.value, item)"
                    (iconClick)="deleteLineFromStore(item.id)"
                    (focusin)="clearName(item)"
                    (focusout)="showName(item)"
                    [(model)]="item.name"
                    [disabled]="disableOnTemplate$ | async"
                    [iconClass]="{
                      position: 'p-input-icon-right',
                      icon:
                        (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET &&
                        items.length > 1 &&
                        !(disableOnTemplate$ | async)
                          ? 'icon icon-remove pt-1'
                          : ''
                    }"
                  >
                  </app-floating-input>
                </div>
              </div>
              <div class="form column budget-area">
                <div class="input total-year">
                  <app-spend-custom-input
                    [disabled]="
                      selectedBudgetType === SPEND_TYPES.FORECAST ||
                      selectedBudgetType === SPEND_TYPES.ACTUALS ||
                      item[typeToDistProp[selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL
                    "
                    [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"
                    (valueChange)="setItemTotal($event, item); calcDistribution(item)"
                    class="app-custom-input"
                    [value]="item.project_total"
                    [ngClass]="{
                      'totals-match-error':
                        item.project_total !== item[initialProjectTotalProp[this.selectedBudgetType]] &&
                        !!item.commitment_start_date &&
                        item[typeToDistProp[this.selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL
                    }"
                    [pTooltip]="
                      item.project_total !== item[initialProjectTotalProp[this.selectedBudgetType]] &&
                      !!item.commitment_start_date &&
                      item[typeToDistProp[this.selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL
                        ? 'For manual distribution with contract, the total must be equal to the initial total which is ' +
                          (item[initialProjectTotalProp[this.selectedBudgetType]] > 0
                            ? '$' + item[initialProjectTotalProp[this.selectedBudgetType]]
                            : '($' + item.initial_forecast_line_item_total * -1 + ')')
                        : ''
                    "
                    tooltipPosition="bottom"
                  >
                  </app-spend-custom-input>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                right: selectedBudgetType !== SPEND_TYPES.ACTUALS,
                'show-one': selectedBudgetType === SPEND_TYPES.FORECAST
              }"
            >
              <div
                class="distribution-type-area"
                [ngClass]="{
                  'disp-none': selectedBudgetType === SPEND_TYPES.ACTUALS,
                  'show-one': selectedBudgetType === SPEND_TYPES.FORECAST
                }"
              >
                <div
                  [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }"
                  *ngIf="selectedBudgetType !== SPEND_TYPES.ACTUALS"
                  class="input column"
                >
                  <app-input-calendar
                    [minStartDate]="minDates[lineIndex]"
                    [date]="item[typeToStartDateProp[selectedBudgetType]]"
                    (dateChanged)="setStartDate($event, item); calcDistribution(item)"
                    [showLabel]="false"
                    [name]="'start_date_' + item.id"
                    topPosition="top-2"
                    class="prime-calendar-spend"
                    readonly
                  ></app-input-calendar>
                </div>

                <!--                <div *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS" class="input column disabled">-->
                <!--                  <input class="input color-disabled" disabled value="Unavailable" />-->
                <!--                </div>-->
                <div
                  [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }"
                  *ngIf="selectedBudgetType !== SPEND_TYPES.ACTUALS"
                  class="input select-with-border"
                >
                  <app-dropdown
                    (modelChange)="setItemDuration($event, item); calcDistribution(item)"
                    [disabled]="item[typeToDistProp[selectedBudgetType]] === DISTRIBUTION_TYPES.MANUAL"
                    [model]="item[typeToDurationProp[selectedBudgetType]]"
                    [name]="'duration' + item.id"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="durationMonths"
                    [showLabel]="false"
                    [showError]="false"
                    appendTo="body"
                    class="spend-app-dropdown"
                  ></app-dropdown>
                </div>
                <div *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS" class="input disabled">
                  <!--                  <input class="input color-disabled" disabled value="Unavailable" />-->
                </div>

                <div *ngIf="selectedBudgetType !== SPEND_TYPES.ACTUALS" class="input select-with-border">
                  <app-dropdown
                    optionValue="key"
                    optionLabel="name"
                    (modelChange)="setItemDistribution($event, item); calcDistribution(item)"
                    [model]="item[typeToDistProp[selectedBudgetType]]"
                    [name]="'distribution_type_' + item.id"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="distributionTypes"
                    [showLabel]="false"
                    [showError]="false"
                    appendTo="body"
                    class="spend-app-dropdown"
                  ></app-dropdown>
                </div>
                <div *ngIf="selectedBudgetType === SPEND_TYPES.ACTUALS" class="input disabled">
                  <input class="input type color-disabled" disabled autocomplete="off" value="Unavailable" />
                </div>
              </div>

              <div class="months">
                <div class="input-wrapper month-input column">
                  <div class="input">
                    <app-spend-custom-input
                      [disabled]="true"
                      [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"
                      class="app-custom-input"
                      [value]="item.year_total"
                    >
                    </app-spend-custom-input>
                  </div>
                </div>
                <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
                  <div class="input">
                    <app-spend-custom-input
                      [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"
                      (valueChange)="registerChange($event, item, month)"
                      class="app-custom-input"
                      [ngClass]="{
                        'project-activity':
                          !item.monthly_disable[month] &&
                          item[typeToDistProp[selectedBudgetType]] !== DISTRIBUTION_TYPES.MANUAL
                      }"
                      [disabled]="
                        item.monthly_disable[month] ||
                        item[typeToDistProp[selectedBudgetType]] !== DISTRIBUTION_TYPES.MANUAL
                      "
                      [value]="item.monthly_data[month]"
                      [pTooltip]="
                        (!item.monthly_disable[month] &&
                          item[typeToDistProp[selectedBudgetType]] !== DISTRIBUTION_TYPES.MANUAL) ||
                        item.monthly_disable[month]
                          ? tooltipContent
                          : ''
                      "
                      tooltipPosition="bottom"
                    >
                    </app-spend-custom-input>
                    <ng-template #tooltipContent>
                      @if (
                        !item.monthly_disable[month] &&
                        item[typeToDistProp[selectedBudgetType]] !== DISTRIBUTION_TYPES.MANUAL
                      ) {
                        Input field cannot be edited, as it is being distributed based on project activity budget.
                      } @else if (item.monthly_disable[month]) {
                        Input field cannot be edited outside project activity.
                      }
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              hidden: (areTemplatesLoading$ | async) || !items.length
            }"
            class="form lines-wrapper -mt-2"
          >
            <div class="left">
              <div class="add-line column form" [ngClass]="{ 'p-disabled disabled': disableOnTemplate$ | async }">
                @if (!templateId) {
                  <span
                    [ngClass]="{
                      'invisible pointer-events-none': (selectedBudgetType$ | async) !== SPEND_TYPES.BUDGET
                    }"
                    class="cursor-pointer pl-1"
                    (click)="addLineToStore()"
                  >
                    Add Budget Line
                  </span>
                }
              </div>

              <div class="budget-area column column-header total-row">
                <div class="input total-year">
                  <app-spend-custom-input
                    [disabled]="true"
                    class="app-custom-input bg-[transparent]"
                    [value]="totals.project_total"
                  >
                  </app-spend-custom-input>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                right: selectedBudgetType !== SPEND_TYPES.ACTUALS,
                'show-one': selectedBudgetType === SPEND_TYPES.FORECAST
              }"
            >
              <div
                class="distribution-type-area total-row"
                [ngClass]="{
                  'disp-none': selectedBudgetType === SPEND_TYPES.ACTUALS,
                  'show-one': selectedBudgetType !== SPEND_TYPES.BUDGET
                }"
              >
                <div class="column input"></div>
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column input"></div>
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column input"></div>
              </div>
              <div class="months total-row">
                <div class="input-wrapper input total-year column">
                  <app-spend-custom-input
                    [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"
                    [disabled]="true"
                    class="app-custom-input bg-[transparent]"
                    [value]="totals.year_total"
                  >
                  </app-spend-custom-input>
                </div>

                <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
                  <div class="input">
                    <app-spend-custom-input
                      [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"
                      [disabled]="true"
                      class="app-custom-input bg-[transparent]"
                      [value]="totals.monthly_data[month]"
                    >
                    </app-spend-custom-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form lines-wrapper total-row">
            <div class="left">
              <div class="form column titles-column">
                <div class="input h-txt-overflow">
                  <!--                  <input disabled value="Totals" />-->
                </div>
              </div>
              <div class="form column budget-area">
                <div class="input total-year">
                  <!--                  <app-spend-custom-input class="app-custom-input disabled" [value]="totals.project_total">-->
                  <!--                  </app-spend-custom-input>-->
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                right: selectedBudgetType !== SPEND_TYPES.ACTUALS,
                'show-one': selectedBudgetType === SPEND_TYPES.FORECAST
              }"
            >
              <div
                class="distribution-type-area"
                [ngClass]="{
                  'disp-none': selectedBudgetType === SPEND_TYPES.ACTUALS,
                  'show-one': selectedBudgetType !== SPEND_TYPES.BUDGET
                }"
              >
                <div class="column input"></div>
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column input"></div>
                <div [ngClass]="{ 'disp-none': selectedBudgetType !== SPEND_TYPES.BUDGET }" class="column input"></div>
              </div>
              <div class="months">
                <div class="input-wrapper input total-year column">
                  <!--                                <app-spend-custom-input-->
                  <!--                                  [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"-->
                  <!--                                  [ngClass]="{ disabled: true }"-->
                  <!--                                  class="app-custom-input"-->
                  <!--                                  [value]="totals.year_total"-->
                  <!--                                >-->
                  <!--                                </app-spend-custom-input>-->
                </div>

                <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
                  <div class="input">
                    <!--                                  <app-spend-custom-input-->
                    <!--                                    [allowNegatives]="selectedBudgetType === SPEND_TYPES.FORECAST"-->
                    <!--                                    [ngClass]="{ disabled: true }"-->
                    <!--                                    class="app-custom-input"-->
                    <!--                                    [value]="totals.monthly_data[month]"-->
                    <!--                                  >-->
                    <!--                                  </app-spend-custom-input>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </ng-template>
</div>
