import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgIf, NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'app-img-loading',
  templateUrl: './img-loading.component.html',
  styleUrls: ['./img-loading.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgStyle],
})
export class ImgLoadingComponent implements OnInit {
  _src;

  get src() {
    return this._src;
  }

  @Input() set src(value) {
    this._src = value;
    this.loading = true;
    if (this.src === null || this.src === undefined) {
      this.showDefaultImg = true;
      this.change.detectChanges();
    } else {
      this.showDefaultImg = false;
      this.change.detectChanges();
    }
  }

  @Input() imgWidth;
  @Input() imgHeight;

  loading = true;
  showDefaultImg = false;

  constructor(private change: ChangeDetectorRef) {}

  ngOnInit() {}

  onLoad() {
    this.loading = false;
  }
}
