import { NotificationsService } from '../../services/notifications.service';

export abstract class NotificationsHandler {
  currentHash = null;

  constructor(protected notif: NotificationsService) {}

  openLoading(msg = null) {
    if (msg) {
      this.currentHash = this.notif.showLoading(msg);
    } else {
      this.currentHash = this.notif.showLoading();
    }
  }

  closeLoading() {
    this.notif.close(this.currentHash);
  }
}
