<div class="bud-template-row-wrapper">
  <div class="bud-template-wrapper">
    <div class="">
      <app-template-selector [type]="'budget'"></app-template-selector>
    </div>

    <div *ngIf="!!(selectedTemplate$ | async)?.id" class="double-column-wrapper">
      <div class="item-container-wrapper">
        <div class="items-container header-container">
          <span class="item header-item uppercase col-span-2 border-r border-solid border-shade_5"
            >Budget Line Items</span
          >
        </div>
        <!--        <div class="items-container header-container border-r solid border-shade_5">-->
        <!--                  <span class="item header-item uppercase col-span-2">Accounts</span>-->
        <!--        </div>-->
      </div>

      <ng-scrollbar class="std-scrollbar h-full" track="vertical" appearance="standard">
        <div>
          <div class="double-column h-full">
            <div class="border-r border-solid border-shade_5" *ngIf="!showBudgetLineGuide">
              <div (click)="showBudgetLineGuide = true" class="items-container header-container">
                <span class="header-container item help-item bg-shade_7 text-color_secondary capitalize col-span-2"
                  >Need help with Budget Lines?</span
                >
              </div>
              <div
                class="items-container"
                *ngFor="let item of (selectedTemplate$ | async)?.template_items; let index = index"
              >
                <span [ngClass]="{ 'edit-disabled': !item?.permissions?.can_edit }" class="item index">{{
                  index + 1
                }}</span>
                <span [ngClass]="{ 'edit-disabled': !item?.permissions?.can_edit }" class="item">
                  <input
                    [readOnly]="!item?.permissions?.can_edit"
                    (change)="onInputChange(item, $event)"
                    (paste)="onPasteEvent(index, $event)"
                    class="template-input"
                    [value]="item.name"
                  />
                </span>
                <span class="item justify-center !pointer-none">
                  <!--                todo decide if we want to remove an item or not-->
                  <!-- it removes an item, the add icon is rotated to be an X-->
                  <span *ngIf="item?.permissions?.can_delete" (click)="removeItem(item)" class="icon-add"></span>
                </span>
              </div>
              <div
                *ngIf="!!(selectedTemplate$ | async)?.id && !!(selectedTemplate$ | async)?.permissions?.can_edit"
                class="items-container"
              >
                <span class="item index"></span>
                <span class="item col-span-2">
                  <input
                    [(ngModel)]="newItemValue"
                    (change)="addTemplateItem()"
                    (paste)="onPasteEvent(null, $event)"
                    class="template-input"
                  />
                </span>
              </div>
            </div>

            <app-templates-help
              [showHelp]="showBudgetLineGuide"
              (closeHelpGuideLine)="showBudgetLineGuide = false"
              type="budget"
              *ngIf="showBudgetLineGuide"
            ></app-templates-help>

            <!--            <app-templates-help-->
            <!--              [showHelp]="showAccountsGuide"-->
            <!--              (closeHelpGuideLine)="showAccountsGuide = false"-->
            <!--              type="account-relations"-->
            <!--              *ngIf="showAccountsGuide"-->
            <!--            ></app-templates-help>-->

            <!--            <div *ngIf="!showAccountsGuide" class="bud-template-column">-->
            <!--              <div class="items-container header-container">-->
            <!--                <span-->
            <!--                  (click)="showAccountsGuide = true"-->
            <!--                  class="item help-item bg-shade_7 text-color_secondary capitalize col-span-2"-->
            <!--                  >More about this section</span-->
            <!--                >-->
            <!--              </div>-->
            <!--              <div-->
            <!--                *ngFor="let item of (selectedTemplate$ | async)?.template_items; let index = index"-->
            <!--                [ngClass]="{ 'edit-disabled': !item?.permissions?.can_edit }"-->
            <!--                class="item gl-template-selector px-2.5"-->
            <!--              >-->
            <!--                <div-->
            <!--                  class="w-full flex"-->
            <!--                  cdkOverlayOrigin-->
            <!--                  #trigger="cdkOverlayOrigin"-->
            <!--                  (click)="openAccountSelector(item, index)"-->
            <!--                >-->
            <!--                  <span class="flex-1">-->
            <!--                    {{ item?.g_l_account_label?.name ? item?.g_l_account_label?.name : 'No Account Template Selected' }}-->
            <!--                  </span>-->
            <!--                  <span-->
            <!--                    [ngClass]="{-->
            <!--                      'opacity-0': !item?.permissions?.can_edit-->
            <!--                    }"-->
            <!--                    class="icon-arrow-right-light rotate-90"-->
            <!--                  ></span>-->
            <!--                </div>-->
            <!--                <app-options-list-general-->
            <!--                  [(show)]="showOptions[index]"-->
            <!--                  [trigger]="getTrigger(index)"-->
            <!--                  [overlayPositions]="defaultDropdownOverlayPositions"-->
            <!--                  [withAnimation]="false"-->
            <!--                >-->
            <!--                  <app-account-dropdown-->
            <!--                    *ngIf="showOptions[index]"-->
            <!--                    (optionSelected)="optionSelected($event, item, index)"-->
            <!--                    (unlinkTemplate)="unlinkTemplate(item, index)"-->
            <!--                    [hasTemplate]="!!item?.g_l_account_label?.id"-->
            <!--                  >-->
            <!--                  </app-account-dropdown>-->
            <!--                </app-options-list-general>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
  <div class="buttons-wrapper -ml-px">
    <div></div>
    <div class="buttons-group">
      <span class="discard-btn btn flex flex-1 p-1.5 justify-center pointer-cursor" (click)="onDiscard()">Discard</span>
      <span class="buttons-sub-group">
        <span class="save-filled-blue btn flex p-1.5 justify-center pointer-cursor" (click)="saveAndGoToTags()"
          >Save & Go to Tags</span
        >
        <span class="send-btn btn flex flex-1 p-1.5 justify-center pointer-cursor" (click)="onSave()">Save</span>
      </span>
    </div>
  </div>
</div>
