<div class="py-4 relative h-100 flex flex-col justify-between box-border">
  <app-page-loading *ngIf="isLoading"></app-page-loading>

  @if (!isLoading) {
    <form #bidForm="ngForm" *ngIf="!showSummary" role="form">
      <app-input-upload
        (onFilesChange)="onFilesChange($event)"
        (onUploadFinished)="setupForm()"
        [files]="model.files"
        [hideRemoveFileIcon]="true"
        [metaData]="uploadMetaData"
        [uploadFiles$]="uploadFiles"
      ></app-input-upload>

      <app-floating-input
        [(model)]="model.description"
        label="Description"
        name="description"
        placeholder="Description"
        required
      ></app-floating-input>
      <app-floating-input
        [(model)]="model.value"
        label="Value"
        name="value"
        placeholder="Value"
        required
        type="number"
      ></app-floating-input>
      <app-floating-input
        [(model)]="model.message"
        label="Message"
        name="message"
        placeholder="Message"
        required
      ></app-floating-input>
      <div class="flex justify-end gap-3 w-full">
        <app-simple-button (click)="reviseProposal()" *ngIf="model?.id">Cancel</app-simple-button>
        <app-simple-button
          [wrapperClass]="{
            'bg-color_accent': true
          }"
          (click)="submit(bidForm)"
          >Submit Proposal</app-simple-button
        >
      </div>
    </form>

    <div
      *ngIf="showSummary"
      class="mt-8 pb-8 text-shade_1 font-bold flex flex-col items-center justify-center gap-6 border-b border-shade_4"
    >
      <span class="icon-paid text-5xl text-color_accept"></span>
      <span class="font-bold text-size_title_s"> {{ isAwarded ? 'Project Awarded' : 'Proposal Submitted' }}</span>
      <span class="font-bold text-4xl">${{ model.value | money }}</span>
    </div>

    <div class="flex justify-end gap-3">
      <app-simple-button
        (click)="downloadProposal()"
        [wrapperClass]="{
          'bg-transparent border border-color_secondary text-color_secondary hover:bg-color_secondary hover:text-white': true
        }"
      >
        Download</app-simple-button
      >
      <app-simple-button (click)="reviseProposal()">Revise Proposal</app-simple-button>
    </div>
  }
</div>
