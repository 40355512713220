export const SHEET_SAVE_URL =
  'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save';
export const SHEET_OPEN_URL =
  'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open';

export const DOCUMENT_OPEN_URL =
  'https://services.syncfusion.com/angular/production/api/documenteditor/Import/';

export const DOCUMENT_SERVICE_URL =
  'https://services.syncfusion.com/angular/production/api/documenteditor/';
