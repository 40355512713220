<div>
  <span [ngClass]="wrapperClass" class="p-float-label p-input-icon-right flex flex-col">
    <input
      #input="ngModel"
      (ngModelChange)="onModelChange($event)"
      [(ngModel)]="inputModel"
      [name]="name"
      [ngClass]="{ 'ng-invalid ng-dirty': !input.valid && input.touched }"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [placeholder]="label"
      [required]="required"
      pInputText
      (keyup.enter)="enterPressed($event)"
    />
    <i [matTooltip]="toolTip" class="icon-info text-color_secondary"></i>

    <label>{{ label }}</label>
    <small [ngClass]="{ hidden: input.valid }" class="p-error text-uppercase">
      <ng-container *ngIf="input.control?.errors?.tag_not_unique">Tags should be unique.</ng-container>
    </small>
  </span>
  <div [ngClass]="{ hidden: !model?.length }" class="tags-container">
    <span class="tag" *ngFor="let tag of model; let index = index">
      <span class="tag-name">{{ tag }}</span>
      <span (click)="removeTag(index)" class="icon-remove-circle-x"></span>
    </span>
  </div>
</div>
