import { ContractorInterface } from './contractor.interface';
import { OPTIONS } from '../../../../../framework/constants/options-list.constants';

export class ContractorManager implements ContractorInterface {
  statusAcceptedTxt = 'Awarded';
  statusDeclinedTxt = 'Bid Declined';
  statusPendingTxt = 'Bid Received';
  statusScheduled = 'Visit Scheduleld';
  statusNotScheduled = 'Visit Not Scheduled';

  optionsVisitScheduled = [OPTIONS.RESCHEDULE, OPTIONS.SHARE, OPTIONS.MESSAGE, OPTIONS.INFO];
  optionsContractor = [OPTIONS.SCHEDULE, OPTIONS.MESSAGE, OPTIONS.DISMISS];
  optionsBidSubmitted = [
    OPTIONS.ACCEPT_BID,
    OPTIONS.DOWNLOAD,
    OPTIONS.SCHEDULE,
    OPTIONS.MESSAGE,
    OPTIONS.INFO,
  ];
  optionsAwarded = [
    OPTIONS.DOWNLOAD,
    OPTIONS.REVOKE,
    OPTIONS.SCHEDULE,
    OPTIONS.SHARE,
    OPTIONS.MESSAGE,
    OPTIONS.INFO,
  ];
  optionsAutoInvited = [OPTIONS.SCHEDULE, OPTIONS.MESSAGE, OPTIONS.INFO, OPTIONS.DISMISS];
}
