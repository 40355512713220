import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  REST_DRIVE_CANCEL_SHARE_FILE,
  REST_DRIVE_CANCEL_SHARE_FOLDER,
  REST_DRIVE_DIRECT_SHARE,
  REST_DRIVE_DIRECT_SHARE_BUNDLE,
  REST_DRIVE_DIRECT_SHARE_FILES,
  REST_DRIVE_DIRECT_SHARE_FOLDERS,
  REST_DRIVE_DOWNLOAD_EXTERNAL_FILE,
  REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_DOWNLOAD,
  REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_VIEW,
  REST_DRIVE_EXTERNAL_FILE_SHARE,
  REST_DRIVE_FILE_SHARE,
  REST_DRIVE_FILES,
  REST_DRIVE_FILES_DOWNLOAD,
  REST_DRIVE_FILES_VIEW,
  REST_DRIVE_FOLDER_SHARE,
  REST_DRIVE_FOLDERS,
  REST_DRIVE_FOLDERS_MAKE_COPY,
  REST_DRIVE_VIEW_EXTERNAL_DRIVE,
  REST_DRIVE_VIEW_EXTERNAL_FILE,
  REST_DRIVE_ZIP,
  REST_PROJECTS_DRIVE,
} from '../restApi/RestRoutes';
import { HttpClient } from '@angular/common/http';
import { RestRequestService } from '../restApi/rest-request.service';
import { IMakeCopyStructure, IZipStructure } from './drive.service';
import { SHARE_PERMISSIONS } from '../framework/constants/documents.constants';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DriveApiService {
  constructor(
    private http: HttpClient,
    private restRequest: RestRequestService,
  ) {}

  getDriveAsync(): Observable<any> {
    return this.http.get(REST_DRIVE_FOLDERS);
  }

  getDriveFolder(id: number, retrieveDisabled = false) {
    const url = retrieveDisabled
      ? `${REST_DRIVE_FOLDERS}/${id}?retrieveDisabled=1`
      : `${REST_DRIVE_FOLDERS}/${id}`;
    return this.http.get(url);
  }

  getExternalDriveFolder(id: number, uuid: string) {
    return this.http.get(`${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/${id}`);
  }

  getProjectDriveAsync(projectId: number, retrieveDisabled = false): Observable<any> {
    const url = retrieveDisabled
      ? `${REST_PROJECTS_DRIVE}${projectId}?retrieveDisabled=1`
      : `${REST_PROJECTS_DRIVE}${projectId}`;
    return this.http.get(url);
  }

  createFolder(driveId, body: { name: string; parent_id: number }, uuid = null) {
    if (uuid) {
      return this.restRequest.post(
        `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-folders`,
        body,
      );
    }
    return this.restRequest.post(`${REST_DRIVE_FOLDERS}`, body);
  }

  deleteFolder(driveId, uuid = null) {
    if (uuid) {
      return this.restRequest.del(
        `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-folders/${driveId}`,
      );
    }
    return this.restRequest.del(`${REST_DRIVE_FOLDERS}/${driveId}`);
  }

  getFileArrayBuffer(fileId: number, uuid = null): Observable<ArrayBuffer> {
    const restUrl = uuid
      ? `${REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_DOWNLOAD}/${uuid}/${fileId}`
      : `${REST_DRIVE_FILES_DOWNLOAD}/${fileId}`;

    // first call the rest api to get the s3 url and then call the s3 url to get the file
    return this.http.get(restUrl).pipe(
      switchMap((s3Url: string) => {
        return this.restRequest.getArrayBuffer(s3Url);
      }),
    );
  }

  /**
   * Content disposition is set to attachment from backend
   * @param id
   * @param uuid
   */
  getFileDownloadURL(id, uuid: string | null = null) {
    const restUrl = uuid
      ? `${REST_DRIVE_DOWNLOAD_EXTERNAL_FILE}/${uuid}/${id}`
      : `${REST_DRIVE_FILES_DOWNLOAD}/${id}`;
    return this.restRequest.get(restUrl);
  }

  /**
   * Content disposition is set to inline from backend
   * @param id
   * @param uuid
   */
  getFileViewURL(id, uuid: string | null = null) {
    const restUrl = uuid
      ? `${REST_DRIVE_DOWNLOAD_EXTERNAL_FILE_VIEW}/${uuid}/${id}`
      : `${REST_DRIVE_FILES_VIEW}/${id}`;
    return this.restRequest.get(restUrl);
  }

  deleteFile(fileId, uuid = null) {
    if (uuid) {
      return this.restRequest.del(
        `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-files/${fileId}`,
      );
    }
    return this.restRequest.del(`${REST_DRIVE_FILES}/${fileId}`);
  }

  shareFolder(body: {
    user_drive_folder_id: number;
    emails: string[];
    permission: string;
    user_ids: number[];
  }) {
    return this.restRequest.post(REST_DRIVE_FOLDER_SHARE, body);
  }

  shareFile(body: {
    user_drive_file_id: number;
    emails: string[];
    permission: string;
    user_ids: number[];
  }) {
    return this.restRequest.post(REST_DRIVE_FILE_SHARE, body);
  }

  shareFileWithAllSP(fileId: number, privacy: boolean) {
    return this.restRequest.put(`${REST_DRIVE_FILES}/${fileId}`, {
      is_service_provider_allowed_to_view: privacy,
    });
  }

  deleteFolderShare(body: { folder_id: number; email: string }) {
    return this.restRequest.del(
      `${REST_DRIVE_CANCEL_SHARE_FOLDER}/${body.folder_id}/${body.email}`,
    );
  }

  deleteFileShare(body: { file_id: number; email: string }) {
    return this.restRequest.del(`${REST_DRIVE_CANCEL_SHARE_FILE}/${body.file_id}/${body.email}`);
  }

  getAllShares() {
    return this.restRequest.get(REST_DRIVE_FOLDER_SHARE);
  }

  renameFile(name: string, id, uuid: string = null) {
    const body = {
      name,
    };

    if (uuid) {
      return this.restRequest.put(
        `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-files/${id}`,
        body,
      );
    }

    return this.restRequest.put(REST_DRIVE_FILES + '/' + id, body);
  }

  renameFolder(name: string, id, uuid = null) {
    const body = {
      name,
    };
    if (uuid) {
      return this.restRequest.patch(
        `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-folders/${id}`,
        body,
      );
    }
    return this.restRequest.patch(REST_DRIVE_FOLDERS + '/' + id, body);
  }

  downloadZip(zipStructure: IZipStructure, uuid = null) {
    return new Promise<any>((res, rej) => {
      const restUrl = uuid ? `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/zipped` : REST_DRIVE_ZIP;
      this.restRequest.getZip(restUrl, zipStructure).then((dataRes) => {
        res(dataRes);
      });
    });
  }

  shareExternally(fileId) {
    return this.restRequest.post(REST_DRIVE_EXTERNAL_FILE_SHARE, {
      user_drive_file_id: fileId,
    });
  }

  cancelExternalShare(fileId) {
    return this.restRequest.del(`${REST_DRIVE_EXTERNAL_FILE_SHARE}/${fileId}`);
  }

  /**
   * cancel external share of a folder, endpoint named direct share
   * @param folderId id of a folder
   */
  cancelDirectShareFolder(folderId) {
    return this.restRequest.del(`${REST_DRIVE_DIRECT_SHARE_FOLDERS}/${folderId}`);
  }

  /**
   * cancel external share of a file, endpoint named direct share
   * @param fileId id of a file
   */
  cancelDirectShareFile(fileId) {
    return this.restRequest.del(`${REST_DRIVE_DIRECT_SHARE_FILES}/${fileId}`);
  }

  directShare(documentId, isFolder, permission) {
    const type = isFolder ? 'user_drive_folder_id' : 'user_drive_file_id';
    const data: { [k: string]: number } = {
      [type]: documentId,
      permission,
    };
    return this.restRequest.post(`${REST_DRIVE_DIRECT_SHARE}`, data);
  }

  directShareMultiple(body: {
    user_drive_folder_ids: number[];
    user_drive_file_ids: number[];
    permission: SHARE_PERMISSIONS;
  }) {
    return this.restRequest.post(`${REST_DRIVE_DIRECT_SHARE_BUNDLE}`, body);
  }

  getExternalDrive(uuid) {
    return this.http.get(`${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}`);
  }

  getViewLink(uuid: string) {
    return this.http
      .get(`${REST_DRIVE_VIEW_EXTERNAL_FILE}/${uuid}`, {
        responseType: 'text',
      })
      .toPromise();
  }

  /**
   *  Copy/Duplicate provided drive documents to a folder by providing its id
   * @param body.parent_id where the folder will be copied
   * @param body.children structure of files and children required for backend to copy see IZipStructure
   */
  copyDriveDocument(body: IMakeCopyStructure) {
    return this.restRequest.post(`${REST_DRIVE_FOLDERS_MAKE_COPY}`, body);
  }

  /**
   *
   * @param body.user_drive_folder_id  - what to move
   * @param body.parent_id - where to move
   */
  moveDriveFolder(body: { parent_id: number; user_drive_folder_id: number }) {
    return this.restRequest.patch(`${REST_DRIVE_FOLDERS}/${body.user_drive_folder_id}`, {
      parent_id: body.parent_id,
    });
  }

  /**
   *
   * @param body.user_drive_file_id - what to move
   * @param body.user_drive_folder_id - where to move
   */
  moveDriveFile(body: { user_drive_file_id: number; user_drive_folder_id: number }) {
    return this.restRequest.put(`${REST_DRIVE_FILES}/${body.user_drive_file_id}`, {
      user_drive_folder_id: body.user_drive_folder_id,
    });
  }

  /**
   * Download file by id as BLOB, careful, backend doesn't return blobs anymore
   * @param id
   */
  downloadFile(id) {
    return this.restRequest.getBlob(REST_DRIVE_FILES_DOWNLOAD + '/' + id, {}, {}, false);
  }
}
