import { Pipe } from '@angular/core';
import { DocumentItem, IPermissions } from '../../store/documents/documents.reducer';
import { BasePermissionsPipe } from './base-permissions.pipe';
import { IStyledOption } from '../../framework/constants/documents.constants';

export enum OVERLAY_TYPES {
  DRIVE,
  DRIVE_DISABLED,
}

@Pipe({
  name: 'overlayOptions',
  standalone: true,
})
export class OverlayOptionsPipe extends BasePermissionsPipe {
  // args[0] is publicLinkUsed boolean is page's drive's case
  // args[1] is drive current folder id
  transform(
    item: DocumentItem | Partial<IPermissions>,
    type: OVERLAY_TYPES,
    ...args: unknown[]
  ): Array<IStyledOption> {
    const isPublicLinkUsed = args[0];
    const currentFolder = args[1] as DocumentItem;
    switch (type) {
      case OVERLAY_TYPES.DRIVE: {
        const allPermissions = [];
        const permissions = (item as DocumentItem)?.permissions;
        this.permissionLiteralToOptions.forEach((permissionLiteralOption) => {
          const option = this.findOption(permissionLiteralOption.value);
          const isPermissionAllowed = permissions[permissionLiteralOption.permission]; // will find specific permission 1 or 0
          if (isPermissionAllowed && option) {
            allPermissions.push(option);
          }
        });
        return allPermissions;
      }

      case OVERLAY_TYPES.DRIVE_DISABLED: {
        return [];
      }

      default:
        return [];
    }
  }

  constructor() {
    super();
  }
}
