import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ArrayToComaSeparatedPipe } from '../../pipes/framework/array-to-list.pipe';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';

import { CommonModule, NgForOf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { ProgressBarModule } from 'primeng/progressbar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  IRollupLineItemTable,
  IRollupProjectTable,
  IRollupTagTable,
} from '../../store/rollups/rollups.interface';
import { TableSortingService } from '../../services/table-sorting.service';
import { PageLoadingComponent } from '../page-loading/page-loading.component';
import { IPropertyBase } from '../../store/properties/properties.interfaces';

export type ProjectedDynamicHeaderTypes = IRollupProjectTable &
  IRollupLineItemTable &
  IRollupTagTable &
  IPropertyBase;

/**
 * From parent scss file use grid-table-dynamic-table mixin to style the table from outside
 * Add grid row and grid cell to style the projected header and projected rows
 * You can project the following:
 * 1. Projected header
 * 2. Projected rows
 * 3. Projected separator
 * 4. Projected footer, for example for totals

 * HTML Structure for the parent component:
 * <div tableHeader class="grid-table">
 *    <div class="grid-row header-row">
 *        <div class="item">
 ***           your first header item here
 *        </div>
 *    </div>
 *  </div>
 *
 *
 * <div projectedTable class="grid-table">
 *   <div *ngFor="let data of someArrayOfData" class="grid-row">
 ***     <div class="item">here goes your data for the first item: {{ data.someValue }}</div>
 *   </div>
 * </div>
 *
 *
 * <div tableFooter class="grid-table">
 *    <div class="grid-row header-row">
 *        <div class="item">
 * **           your first header item here
 *        </div>
 *    </div>
 *  </div>
 */
@Component({
  selector: 'app-dynamic-projected-table',
  standalone: true,
  imports: [
    ArrayToComaSeparatedPipe,
    MatFormField,
    MatOption,
    MatProgressBar,
    MatSelect,
    MatTooltip,
    NgForOf,
    NgScrollbar,
    CommonModule,
    ProgressBarModule,
    PageLoadingComponent,
  ],
  templateUrl: './dynamic-projected-table.component.html',
  styleUrl: './dynamic-projected-table.component.scss',
})
export class DynamicProjectedTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('ngScrollbar') scrollbar: NgScrollbar;

  @Output() scrolledToBottom = new EventEmitter<void>();

  @Input() isLoading: boolean;
  @Input({ required: true }) isEmpty: boolean;
  @Input() wasDataLoadedOnce: boolean;
  @Input() emptyTableMessage = 'No Data.';
  @Input() hideFooter: boolean = false;
  @Input() horizontalContainerClass: { [p: string]: any } | string[] | string = [];

  isDestroyed$ = new Subject<void>();

  lastScrollPosition = 0;
  sortOrder: 1 | -1 = 1;
  lastSortedOrder: Partial<ProjectedDynamicHeaderTypes> = {};
  lastSortedKey = '';

  constructor(private sortService: TableSortingService) {}

  ngAfterViewInit() {
    this.scrollbar.scrolled
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((event) => this.onScroll(event));
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;

    if (this.lastScrollPosition < target.scrollTop) {
      // if user scrolled downwards
      if (target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.8) {
        // if user is in the bottom part (80%-100%) of the scrollbar track
        this.scrolledToBottom.next();
      }
    }
    this.lastScrollPosition = target.scrollTop;
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  sortTable<T>(data: T[], field: string = this.lastSortedKey, keepSorting = false): T[] {
    if (!keepSorting) {
      this.setSortingProperties(field);
    }
    return this.sortService.sort(data, field, this.sortOrder);
  }

  setSortingProperties(field: string) {
    if (this.lastSortedOrder[field]) {
      this.sortOrder = (this.lastSortedOrder[field] * -1) as 1 | -1;
    }

    this.lastSortedOrder[field] = this.sortOrder;

    this.lastSortedKey = field;
  }

  resetSorting() {
    this.sortOrder = 1;
    this.lastSortedOrder = {};
    this.lastSortedKey = '';
  }
}
