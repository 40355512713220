import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormatInputMoney]',
  standalone: true,
})
export class FormatInputMoneyDirective implements AfterViewInit {
  // this is a workaround, the directive is not being used in the FloatingInputComponent always
  // but this directive is disabled by default
  // this is needed because you cannot apply a directive conditionally.
  disable = false;
  @Input() set appFormatInputMoney({ disable }: { disable: boolean }) {
    this.disable = disable;
  }
  previousValue = undefined;
  hasBeenChanged = false;
  hasBeenPasted = false;
  enterHasBeenPressed = false;
  lastTypedCharPosition = null;
  negativeRegExp = new RegExp(/^-?([0-9]{1,9})?(\.)?(\.[\d]{1,2})?$/); // allows negative numbers and decimals
  positiveRegExp = new RegExp(/^([0-9]{1,9})?(\.)?(\.[\d]{1,2})?$/); // allows only positive numbers and decimals
  regExp = this.negativeRegExp;
  // maybe strings/leading zeroes inputs can be avoided as and be included in a single regex, to be checked:
  // https://stackoverflow.com/questions/18495621/regular-expression-for-less-or-more-than-9-digits-repeating-digits-for-first-5-o
  zeroStartRegex = new RegExp(/^-?0+\d+(.\d+|.)?$/g); // numbers that start with 0 ex: 01; 000; 001; 021.; 03.1;

  constructor(private model: NgControl) {}

  ngAfterViewInit() {
    if (this.disable) {
      return;
    }
    this.formatInput();

    // WARNING !!! - AWFUL CODE COMING
    // this is awful AF, but it's the only way I've found to make the input show the formatted value at first
    setTimeout(() => {
      this.onFocusIn();
      setTimeout(() => {
        this.onFocusOut();
      }, 10);
    }, 10);
    // AWFUL CODE ENDS
  }

  @Input() set allowNegatives(value) {
    this.regExp = value ? this.negativeRegExp : this.positiveRegExp;
  }

  formatWithCommas(input) {
    const parts = input.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  setControl(actualValue, displayValue = actualValue) {
    this.model.control.setValue(actualValue);
    this.model.valueAccessor.writeValue(displayValue);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.hasBeenPasted = true;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: ClipboardEvent) {
    this.enterHasBeenPressed = true;
  }

  @HostListener('input', ['$event'])
  onInput(event) {
    if (this.disable) {
      return;
    }

    this.lastTypedCharPosition = event.target.selectionStart;
    this.model.value.match(this.zeroStartRegex);
    if (this.zeroStartRegex.test(this.model.value)) {
      this.handleIncorrectValue();
    }
    this.model.value.match(this.regExp);
    this.regExp.test(this.model.value)
      ? this.setControl(this.model.value)
      : this.handleIncorrectValue();
    this.hasBeenPasted = false;
  }

  @HostListener('focusin')
  onFocusIn() {
    if (this.disable) {
      return;
    }

    if (this.model.value === '') {
      return;
    }
    this.previousValue = this.model.value;
    this.setControl(this.model.value);
  }

  @HostListener('focusout')
  onFocusOut() {
    if (this.disable) {
      return;
    }

    !this.hasBeenChanged && this.previousValue
      ? this.formatInput(this.previousValue)
      : this.formatInput();
    this.hasBeenChanged = false;
  }

  @HostListener('change', ['$event'])
  inputChange(event) {
    if (this.disable) {
      return;
    }

    this.hasBeenChanged = true;
    if (this.enterHasBeenPressed) {
      this.enterHasBeenPressed = false;
      event.target.blur();
    }
    this.formatInput();
  }

  formatInput(formatValue?) {
    let displayValue = formatValue ? formatValue : this.model.value;
    if (!this.validInput(displayValue)) {
      return;
    }
    try {
      const actualValue = this.model.value;
      displayValue =
        actualValue < 0
          ? '($ ' + this.formatWithCommas(displayValue * -1) + ')'
          : '$ ' + this.formatWithCommas(displayValue);
      this.setControl(actualValue, displayValue);
    } catch (e) {
      console.log(e);
    }
  }

  removeLastTyped() {
    if (this.lastTypedCharPosition) {
      const value =
        this.model.value.slice(0, this.lastTypedCharPosition - 1) +
        this.model.value.slice(this.lastTypedCharPosition);
      this.setControl(value);
    }
  }

  private validInput(displayValue) {
    if (displayValue === null) {
      return false;
    }

    if (displayValue === '-' || displayValue === '.' || displayValue === '') {
      this.setControl('');
      this.previousValue = undefined;
      return false;
    }
    if (String(displayValue).slice(-1) === '.') {
      this.setControl(this.model.value.slice(0, -1));
    }
    if (String(displayValue).charAt(0) === '.') {
      this.setControl(Number(this.model.value));
    }
    if (String(displayValue) === '-0' || String(displayValue) === '-0.') {
      this.setControl(0);
    }

    return true;
  }

  private handleIncorrectValue() {
    this.hasBeenPasted ? this.setControl(this.previousValue) : this.removeLastTyped();
  }
}
