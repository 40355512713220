import { ProjectBid } from '../../pages/webapp/projects/projects.interface';

export const USER_MANAGER = 'manager';
export const USER_CONTRACTOR = 'contractor';
export const USER_ARCHITECT = 'architect';
export const NEXT_SLIDE = 'next';
export const PREVIOUS_SLIDE = 'prev';
export const FINAL_SLIDE = 'final';
export const BUDGET_TYPE_MANUAL = 'manual';
export const BUDGET_TYPE_ESTIMATE = 'estimate';

export enum USER_AUTHORIZATION {
  NONE,
  DOES_HAVE_PROPERTY,
  APPROVED,
}

// export enum CONTRACTOR_TYPE {
//   CONTRACTOR= 1,
//   ARCHITECT= 3,
//   INSPECTOR= 5,
//   ENGINEER,
//   MATERIAL_SUPPLIER,
//   SUBCONTRACTOR = 8,
//   CONSULTANT = 9
// }

export enum CONTRACTOR_TYPE {
  CONTRACTOR = 1,
  ARCHITECT = 3,
  SUBCONTRACTOR,
  INSPECTOR = 5,
  ENGINEER,
  MATERIAL_SUPPLIER,
  CONSULTANT = 8,
}

export interface License {
  id: number;
  user_id: number;
  license_number: string;
  company_name: string;
  state: string;
  city: string;
  street: string;
  zip_code: string;
  phone_number: string;
  status: number;
  expiration: any;
  created_at: string;
  updated_at: string;
  formatted_address: string;
  classifications: any[];
}

export interface Contractor {
  id: number;
  user_id: number;
  license_id: number;
  oshpd_certified: boolean;
  union_contractor: boolean;
  prevailing_wage: boolean;
  keywords: string[];
  locations: string[];
  created_at: string;
  updated_at: string;
  deleted_at: any;
  is_favorite: number;
  status: any;
  license: License;
}

export interface InvitedContractor extends Contractor {
  user: User;
  schedule_visits: any[];
  total_committed: number;
  has_commitments: boolean;
  bid: ProjectBid;
  invite: Invite;
}

export interface Invite {
  id: number;
  user_id: number;
  project_id: number;
  contractor_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

// todo extend if needed
export type ReportingFeature =
  | 'property_summary'
  | 'project_projects'
  | 'property_budget_lines'
  | 'property_budget_tags'
  | 'property_cash_flow'
  | 'project_spend_detail'
  | 'project_cash_flow'
  | 'project_schedule'
  | 'project_commitments';

export interface IPaymentMethod {
  last_four: string;
  type: string;
}

export interface ISubscription {
  billing_plan_id: number;
  name: string;
  trial_days: number;
  team_limit: number;
  property_limit: number;
  project_limit: number;
  drive_storage_limit: number;
  reporting_features: ReportingFeature[];
  trial_ends_at: string;
  ends_at: string;
  next_payment_due: string;
  monthly_price: number;
  yearly_price: number;
  recurring_interval: 'month' | 'year';
  payment_method: IPaymentMethod;
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'; // these statuses are from Stripe docs.
  isTeamSubscription?: boolean; // added by frontend
}

export interface User {
  id: number;
  username: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  company_name: string;
  type: UserType;
  is_admin: boolean;
  is_parent_account: boolean;
  profile_picture_id: number;
  company_picture_id: number;
  parent_account_id: number;
  email_verified_at: string;
  is_approved: boolean;
  deleted: boolean;
  last_login: string;
  reset_token: string;
  fiscal_year_start: number;
  created_at: string;
  updated_at: string;
  user_drive_folder_id: number;
  deleted_at: string;
  profile_picture_url: string;
  company_picture_url: string;
  contractor: Contractor | null;
  is_property_owner: boolean;
  is_team_member: boolean;
  subscription: ISubscription | null;
  team_subscription: ISubscription[] | null;
  isManager?: boolean; // added by frontend
}

export interface UserType {
  id: number;
  name: string;
}
