<div class="teams-sidebar-wrapper">
  <ng-container *ngIf="isSelectedMemberFetching$ | async">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(isSelectedMemberFetching$ | async)">
    <span (click)="currentPage = currentPage - 1" class="pb-3 flex">
      @if (currentPage > TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE) {
        <app-arrow-button>
          <div class="text-shade_2 font-semibold text-sm">{{ sidebarTitle$ | async | titlecase }}</div>
        </app-arrow-button>
      } @else {
        <div class="text-shade_2 font-semibold text-sm">{{ sidebarTitle$ | async | titlecase }}</div>
      }
    </span>
    <div class="teamsForm">
      <!--    todo, eventually each form could be a different component-->
      <form
        name="firstPageForm"
        role="form"
        class="form w-100"
        #firstPageForm="ngForm"
        [ngClass]="{ hidden: currentPage !== TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE }"
      >
        <div class="page-container" *ngIf="currentPage === TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE">
          <ng-scrollbar
            #firstPageScroll
            class="std-scrollbar"
            appearance="standard"
            [ngClass]="{ expanded: firstPageScroll.state.isVerticallyScrollable }"
          >
            <app-floating-input
              label="First Name"
              name="FirstName"
              [(model)]="model.first_name"
              [required]="true"
              [disabled]="isCurrentUserEdited || !!(getSelectedMember$ | async)?.user_id"
            ></app-floating-input>

            <app-floating-input
              label="Last Name"
              name="LastName"
              [disabled]="isCurrentUserEdited || !!(getSelectedMember$ | async)?.user_id"
              [(model)]="model.last_name"
              [required]="true"
            ></app-floating-input>

            <app-floating-input
              label="Position Title"
              name="position"
              [(model)]="model.position"
              [required]="true"
            ></app-floating-input>

            <app-floating-input
              label="Email"
              name="email"
              [isEmail]="true"
              type="email"
              [(model)]="model.email"
              [disabled]="isCurrentUserEdited || viewType === TEAM_MANAGEMENT_SIDEBAR_VIEW.EDIT_TEAM_MEMBER"
              required
            ></app-floating-input>

            <app-dropdown
              [(model)]="model.home_base_id"
              (modelChange)="propertyChanged($event)"
              [options]="properties$ | async"
              [required]="true"
              name="homeBase"
              placeholder="Home Base"
              label="Home Base"
              optionLabel="name"
              optionValue="id"
            >
            </app-dropdown>

            <app-dropdown
              [required]="true"
              [options]="roles$ | async"
              [(model)]="model.role"
              (modelChange)="handleTemplatesLimit()"
              [disabled]="isCurrentUserEdited || !(roles$ | async).length"
              class="capitalize"
              name="role"
              placeholder="Authority"
              label="Authority"
            >
            </app-dropdown>

            <div [ngClass]="{ hidden: isCurrentUserEdited }" class="flex items-center gap-2 my-6">
              <p-checkbox
                name="limit_to_templates"
                class="small"
                [binary]="true"
                [(ngModel)]="model.limit_to_templates"
                [disabled]="model.role === ROLE_TYPE.SPECTATOR"
                [ngClass]="{ 'p-disabled': model.role === ROLE_TYPE.SPECTATOR }"
              ></p-checkbox>

              <span class="text-[13px] text-color_primary font-semibold"
                >Team member cannot use their own templates</span
              >
              <span
                pTooltip="If the box is checked, the user will be restricted to using only the templates you have created on your projects, and not any templates from their account."
                tooltipPosition="bottom"
                tooltipStyleClass="team-template-tooltip"
                class="question-icon"
              ></span>
            </div>
          </ng-scrollbar>
        </div>
      </form>
      <form
        name="secondPageForm"
        role="form"
        class="form w-100"
        #secondPageForm="ngForm"
        [ngClass]="{ hidden: currentPage !== TEAM_MANAGEMENT_SIDEBAR_PAGES.SECOND_PAGE }"
      >
        <div class="page-container" *ngIf="currentPage === TEAM_MANAGEMENT_SIDEBAR_PAGES.SECOND_PAGE">
          <app-search-input class="block" (searchText)="filterProperties($event)"></app-search-input>
          <div (click)="allPropCheckbox.updateModel(!allPropCheckbox.checked())" class="team-management-item">
            <p-checkbox
              #allPropCheckbox
              (click)="$event.stopPropagation()"
              (onChange)="allPropertiesCheckboxChanged($event.checked)"
              class="text-xs"
              [binary]="true"
              [ngModel]="allPropertyChecked"
              [ngModelOptions]="{ standalone: true }"
            >
            </p-checkbox>
            <span class="text-size_xl text-color_primary font-semibold">Select All Properties</span>
          </div>

          <ng-scrollbar
            #propertyScroll
            class="std-scrollbar"
            appearance="standard"
            [ngClass]="{ expanded: propertyScroll.state.isVerticallyScrollable }"
          >
            @for (property of filteredProperties$ | async; track property.id) {
              <div (click)="propertyCheckbox.updateModel(!propertyCheckbox.checked())" class="team-management-item">
                <p-checkbox
                  #propertyCheckbox
                  (click)="$event.stopPropagation()"
                  (onChange)="propertyCheckboxChanged($event.checked, property)"
                  [binary]="true"
                  [ngModel]="getCheckedProperties(property.id) | async"
                  [ngModelOptions]="{ standalone: true }"
                >
                </p-checkbox>
                <div class="flex flex-col">
                  <span class="text-size_xl text-color_primary font-semibold">{{ property.name }}</span>
                  <span class="text-size_l text-shade_1 font-normal">{{ property.address }}</span>
                </div>
              </div>
            }
          </ng-scrollbar>
        </div>
      </form>
      <form
        name="thirdPageForm"
        role="form"
        class="form w-100"
        #thirdPageForm="ngForm"
        [ngClass]="{
          hidden: currentPage !== TEAM_MANAGEMENT_SIDEBAR_PAGES.LAST_PAGE
        }"
      >
        <div class="page-container" *ngIf="currentPage === TEAM_MANAGEMENT_SIDEBAR_PAGES.LAST_PAGE">
          <app-search-input class="block" (searchText)="filterProjects($event)"></app-search-input>

          <div (click)="allProjectsCheckbox.updateModel(!allProjectsCheckbox.checked())" class="team-management-item">
            <p-checkbox
              #allProjectsCheckbox
              (click)="$event.stopPropagation()"
              (onChange)="allProjectsCheckboxChanged(allProjectsCheckbox.checked())"
              class="text-xs"
              [binary]="true"
              [ngModel]="areAllProjectCheckboxesSelected$ | async"
              [ngModelOptions]="{ standalone: true }"
            >
            </p-checkbox>
            <span class="text-size_xl text-color_primary font-semibold">Select All Projects</span>
          </div>

          <ng-scrollbar
            #projectScroll
            class="std-scrollbar"
            appearance="standard"
            [ngClass]="{ expanded: projectScroll.state.isVerticallyScrollable }"
          >
            @for (property of selectedProperties$ | async; track property.id) {
              @for (project of property.projects; track project.id) {
                <div (click)="checkboxProj.updateModel(!checkboxProj.checked())" class="team-management-item">
                  <p-checkbox
                    #checkboxProj
                    (click)="$event.stopPropagation()"
                    (onChange)="projectCheckboxChanged(checkboxProj.checked(), project)"
                    class="text-xs"
                    [binary]="true"
                    [ngModel]="getCheckedProjects(project.id) | async"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </p-checkbox>
                  <div class="flex flex-col">
                    <span class="text-size_xl text-color_primary font-semibold">{{ project.title }}</span>
                    <span class="text-size_m text-shade_1 font-normal">{{ property.name }}</span>
                  </div>
                </div>
              }
            }
          </ng-scrollbar>
          <div class="team-management-item border-0">
            <p-checkbox [binary]="true" [(ngModel)]="model.share_future_projects"> </p-checkbox>
            <span class="text-size_l text-color_primary font-semibold">Allow Access Future Projects</span>
            <span
              pTooltip="If the box is checked, any future project added to the property will be automatically shared with this user."
              tooltipPosition="bottom"
              tooltipStyleClass="team-template-tooltip"
              class="question-icon"
            ></span>
          </div>
        </div>
      </form>
    </div>

    <div class="flex justify-end">
      @if (currentPage !== TEAM_MANAGEMENT_SIDEBAR_PAGES.LAST_PAGE && !isCurrentUserEdited) {
        <app-simple-button (click)="incrementPage()">Next </app-simple-button>
      } @else {
        <app-simple-button [wrapperClass]="{ 'bg-color_accent': true }" (click)="handleSave()">
          {{ viewType === TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER ? 'Add Teammate' : 'Save' }}
        </app-simple-button>
      }
    </div>
  </ng-container>
</div>
