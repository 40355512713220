import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { teamManagementActions } from '../../../store/team-management/team-management.actions';
import { INTERACTION_BAR_STATES } from '../../../framework/constants/interaction-bar.constants';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import {
  ITeamMember,
  TEAM_MANAGEMENT_SIDEBAR_VIEW,
} from '../../../store/team-management/team-management.interfaces';
import {
  areTeamsFetching,
  getAreProfilePicturesLoading,
  getProfilePicture,
  getTeamMembersByView,
} from '../../../store/team-management/team-management.selectors';
import { OPTIONS } from '../../../framework/constants/options-list.constants';
import { DeepCopyService } from '../../../services/deep-copy.service';
import { TeamManagementService } from '../../../services/team-management.service';
import { OVERLAY_TYPES } from '../../../pipes/framework/overlay-options.pipe';
import { takeUntil } from 'rxjs/operators';
import { OptionsAction } from '../../../framework/overlays/option-list.constants';
import { OptionsPermissionsPipe } from '../../../pipes/framework/options-permissions.pipe';
import { UnStyledOptionsListComponent } from '../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { FadedTextComponent } from '../../../framework/faded-text/faded-text.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { SearchBarMinimalistComponent } from '../../../framework/search-bar-minimalist/search-bar-minimalist.component';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss'],
  standalone: true,
  imports: [
    SearchBarMinimalistComponent,
    NgIf,
    MatProgressBar,
    NgScrollbar,
    NgFor,
    FadedTextComponent,
    UnStyledOptionsListComponent,
    NgClass,
    AsyncPipe,
    TitleCasePipe,
    OptionsPermissionsPipe,
  ],
})
export class TeamManagementComponent implements OnInit, OnDestroy {
  teams$ = this.store.select(getTeamMembersByView);
  isDestroyed$ = new EventEmitter<boolean>();
  areProfilePicturesLoading$ = this.store.select(getAreProfilePicturesLoading);
  areTeamsFetching$ = this.store.select(areTeamsFetching);

  OPTIONS = OPTIONS;
  actionList: Array<OptionsAction> = [OPTIONS.EDIT, OPTIONS.DELETE];
  OVERLAY_TYPES = OVERLAY_TYPES;
  totalTeamMembers = 0;
  protected readonly Object = Object;

  constructor(
    private store: Store<AppState>,
    private interactionBarService: InteractionBarStateService,
    private teamService: TeamManagementService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(teamManagementActions.loadTeamMembersFromBackend({}));
    this.teams$.pipe(takeUntil(this.isDestroyed$)).subscribe((teams) => {
      this.totalTeamMembers = teams.reduce((acc, team) => acc + team.team_members.length, 0);
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  openAddTeamMemberSidebar() {
    // this.store.dispatch(teamManagementActions.resetPage());
    this.store.dispatch(teamManagementActions.clearSelectedTeamMember());
    this.store.dispatch(
      teamManagementActions.setViewType({ viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER }),
    );
    this.interactionBarService.announceState.next({
      action: INTERACTION_BAR_STATES.TEAM_MANAGEMENT,
    });
  }

  openEditTeamMemberSidebar(member: ITeamMember) {
    // this.store.dispatch(teamManagementActions.resetPage());
    this.store.dispatch(
      teamManagementActions.loadTeamRolesFromBackend({ team_id: member.team_id }),
    );
    this.store.dispatch(
      teamManagementActions.setViewType({
        viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW.EDIT_TEAM_MEMBER,
      }),
    );

    this.store.dispatch(
      teamManagementActions.editTeamMemberOptionClicked({
        member: {
          ...DeepCopyService.deepCopy(member),
          home_base_id: member?.home_base?.id ?? null,
        },
      }),
    );
    this.interactionBarService.announceState.next({
      action: INTERACTION_BAR_STATES.TEAM_MANAGEMENT,
    });
  }

  getProfilePicture = (id: number) => this.store.select(getProfilePicture(id));

  registerOption(event: OPTIONS, member: ITeamMember) {
    switch (event) {
      case OPTIONS.EDIT:
        this.openEditTeamMemberSidebar(member);
        break;
      case OPTIONS.DELETE:
        this.teamService.deleteTeamMember(member.id);
        break;
      default:
        break;
    }
  }

  searchMembers(searchQuery: string) {
    this.store.dispatch(teamManagementActions.setTeamSearchText({ searchQuery }));
  }
}
