<div class="interaction-bar-wrapper">
  <div class="interaction-bar-title">Budget Adjustment</div>

  <div class="interaction-bar-content">
    <app-budget-adjustment-view
      [hasBudgetTemplate]="hasBudgetTemplate"
      [projectId]="projectId"
      [lineItems]="lineItems"
    ></app-budget-adjustment-view>
  </div>
</div>
