import { createAction, props } from '@ngrx/store';
import {
  IChecklist,
  IChecklistItem,
  IFile,
  ITask,
  ITaskFilters,
  ITaskMember,
  TASK_KANBAN_COLUMN_TYPES,
  TASK_VIEWS,
  TaskOrderBy,
  TaskOrderDirection,
  TaskVisualizationFilter,
} from './tasks.interfaces';

export const cancel = createAction('[Tasks] cancel action');
export const loadTasks = createAction(
  '[Tasks] load all tasks',
  props<{ filters?: Partial<ITaskFilters> }>(),
);
export const setTasks = createAction('[Tasks] set all tasks', props<{ tasks: ITask[] }>());
export const setTasksAfterSort = createAction(
  '[Tasks][Effect] set tasks after sorting',
  props<{ tasks: ITask[] }>(),
);
export const createTask = createAction('[Tasks] create task', props<{ task: ITask }>());
export const taskSelected = createAction('[Tasks] task got selected', props<{ taskId: number }>());
export const taskViewLoadedSuccessfully = createAction(
  '[Tasks] task view loaded successfully',
  props<{ task: ITask }>(),
);
export const taskUpdatedSuccessfully = createAction(
  '[Tasks] task updated successfully',
  props<{ task: ITask }>(),
);

export const taskViewLoadStarted = createAction('[Tasks] task view loading started');
export const editTaskSidebarLoadStarted = createAction('[Tasks] edit task sidebar loading started');

export const loadUsers = createAction(
  '[Tasks] load users for assign and spectate',
  props<{ search: string }>(),
);
export const setUsers = createAction('[Tasks] set loaded users', props<{ users: ITaskMember[] }>());
export const setUsersLoading = createAction(
  '[Tasks] set users loading',
  props<{ isLoading: boolean }>(),
);

export const setAssignedUsers = createAction(
  '[Tasks] set assigned users',
  props<{ users: ITaskMember[] }>(),
);

export const setSpectatorUsers = createAction(
  '[Tasks] set spectator users',
  props<{ users: ITaskMember[] }>(),
);

export const addSpectatorUser = createAction(
  '[Tasks] add spectator users',
  props<{ user: ITaskMember }>(),
);

export const removeSpectatorUser = createAction(
  '[Tasks] remove spectator users',
  props<{ user: ITaskMember }>(),
);

export const addFiles = createAction('[Tasks] add one file to upload', props<{ files: IFile[] }>());

export const removeFile = createAction(
  '[Tasks] remove one file to upload',
  props<{ index: number }>(),
);

export const clearFiles = createAction('[Tasks] clean all file to upload');
export const loadAllCheckList = createAction('[Tasks] load all checklist');
export const setAllCheckList = createAction(
  '[Tasks] set all checklist',
  props<{ checklist: IChecklist[] }>(),
);

export const removeCheckList = createAction(
  '[Tasks] remove checklist',
  props<{ checklist: IChecklist; index: number }>(),
);

export const updateCheckList = createAction(
  '[Tasks] update checklist',
  props<{ checklist: IChecklist }>(),
);

// todo: rename to nextPageClicked
export const incrementPage = createAction('[Tasks] increment current page number');
export const saveTaskToBackend = createAction('[Tasks] save current sidebar task to backend');
export const setUpdateTaskID = createAction(
  '[Tasks] set last updated task id',
  props<{ id: number }>(),
);

export const clearTaskSideBar = createAction('[Tasks] clear whole task sidebar');

export const decrementPage = createAction('[Tasks] decrement current page number');

export const updateSidebarTask = createAction(
  '[Tasks] update sidebar task field',
  props<{ task: Partial<ITask> }>(),
);

export const setIsLoading = createAction('[Tasks] set is loading', props<{ isLoading: boolean }>());

export const refreshSelectedTask = createAction('[Tasks] refresh selected task');

export const statusUpdateOnTaskDetails = createAction(
  '[Tasks][details] update the selected tasks` status incrementally',
);
export const statusUpdateFromOptions = createAction(
  '[Tasks][options list] update the tasks` status incrementally',
  props<{ task: ITask }>(),
);
export const statusUpdateInEffects = createAction(
  '[Tasks][effects] update the task status incrementally',
  props<{ task: ITask }>(),
);

export const createChecklist = createAction(
  '[Tasks] create or update checklist',
  props<{ checklist: IChecklist }>(),
);

export const removedMarkedAsDeleteChecklist = createAction(
  '[Tasks] remove checklists that were flagged as delete',
);

export const editSideBarTaskClicked = createAction(
  '[Tasks] edit a task in sidebar',
  props<{ taskId: number }>(),
);

export const loadSidebarTaskEdit = createAction(
  '[Tasks] load a task in sidebar for edit',
  props<{ task: ITask }>(),
);

export const taskFiltersChangedInComponent = createAction(
  '[Tasks][header] filters changed in tasks header',
  props<{ filters: Partial<ITaskFilters> }>(),
);

export const taskFiltersChangedInEffect = createAction(
  '[Tasks][header] filters changed in tasks` effects',
  props<{ filters: ITaskFilters }>(),
);

export const tasksLoadingStarted = createAction(
  '[Tasks][Effects] tasks loading started',
  props<{ view: TaskVisualizationFilter }>(),
);

const deleteFromOptions = createAction('[Tasks][options] delete one', props<{ task: ITask }>());
const deleteOnDetails = createAction('[Tasks][details] delete one', props<{ task: ITask }>());
const deletedSuccessfully = createAction(
  '[Tasks][effect] deleted successfully',
  props<{ task: ITask }>(),
);

const checklistItemToggledOnDetails = createAction(
  '[Tasks][details] toggled checklist item',
  props<{ checklistItem: IChecklistItem }>(),
);

const setSidebarLoadingFromTaskUpload = createAction(
  '[Tasks][upload] sidebar loading set from upload',
  props<{ isLoading: boolean }>(),
);

const setSidebarLoadingFromMenuComp = createAction(
  '[Tasks][upload] sidebar loading set from menu component',
  props<{ isLoading: boolean }>(),
);

const messageSentOnDetails = createAction('[Tasks][details] a message got sent');

const loadNextPageFromTable = createAction(
  '[Tasks][table] load next page',
  props<{ firstPage?: boolean }>(),
);
const sortTasksFromTaskTable = createAction(
  '[Tasks][table] sort tasks',
  props<{ order_direction: TaskOrderDirection; order_by: TaskOrderBy }>(),
);
const resetPaginationFiltersFromTable = createAction('[Tasks][table] reset pagination filters');
const nextPageLoadingStarted = createAction(
  '[Tasks][effects] next page loading started',
  props<{ isFirstPage: boolean }>(),
);
const sortingTasksLoadingFromEffect = createAction(
  '[Tasks][effects] loading task sort from backend',
  props<{ isLoading: boolean }>(),
);
const nextPageLoaded = createAction(
  '[Tasks][effects] next page of tasks loaded successfully',
  props<{ tasks: ITask[]; isFirstPage: boolean }>(),
);

const loadNextPageFromKanban = createAction(
  '[Tasks][kanban] load next page by view',
  props<{ firstPage?: boolean; columnType: TASK_KANBAN_COLUMN_TYPES }>(),
);
const nextPageLoadingStartedOnKanban = createAction(
  '[Tasks][effects] next page loading started for kanban column',
  props<{ visualization: TaskVisualizationFilter; isFirstPage: boolean }>(),
);
const nextPageFromKanbanLoaded = createAction(
  '[Tasks][effects] next page for kanban column loaded',
  props<{
    tasks: ITask[];
    visualization: TaskVisualizationFilter;
    isFirstPage: boolean;
    pageLoaded: number;
  }>(),
);
const taskViewChanged = createAction(
  '[Tasks][wrapperComponent] Task View Changed',
  props<{ view: TASK_VIEWS }>(),
);

const emptyTasksState = createAction('[Tasks][logout] empty state');

// if hardReload it should load the tasks from the backend anytime
// otherwise it won't load it if there is data for that month
// if no year it will load the selected month's data
const loadMonthFromCalendar = createAction(
  '[Tasks][calendar] load next page',
  props<{ year?: number; month?: number; hardReload?: boolean }>(),
);
const monthLoadingStartedOnCalendar = createAction(
  '[Tasks][effect] next page loading started on calendar',
);
const monthFromCalendarLoaded = createAction(
  '[Tasks][effect] loaded next page from calendar',
  props<{ tasks: ITask[]; year: number; month: number; hardReload: boolean }>(),
);

const setDefaultMonth = createAction(
  '[Tasks][effect] set selected month to current month',
  props<{ hardReload?: boolean }>(),
);
const selectedMonthChangedCalendar = createAction(
  '[Tasks][calendar] selected month changed in calendar view',
  props<{ year: number; month: number }>(),
);
const selectedMonthChangedEffect = createAction(
  '[Tasks][effect] set selected month for calendar view',
  props<{ year: number; month: number }>(),
);

export const tasksActions = {
  loadTasks,
  setTasks,
  createTask,
  taskSelected,
  loadUsers,
  setUsersLoading,
  setUsers,
  setIsLoading,
  cancel,
  setAssignedUsers,
  setSpectatorUsers,
  addSpectatorUser,
  removeSpectatorUser,
  addFiles,
  removeFile,
  clearFiles,
  loadAllCheckList,
  setAllCheckList,
  removeCheckList,
  updateCheckList,
  updateSidebarTask,
  incrementPage,
  decrementPage,
  saveTaskToBackend,
  taskViewLoadedSuccessfully,
  taskUpdatedSuccessfully,
  taskViewLoadStarted,
  refreshSelectedTask,
  statusUpdateOnTaskDetails,
  statusUpdateFromOptions,
  statusUpdateInEffects,
  setUpdateTaskID,
  clearTaskSideBar,
  taskFiltersChangedInComponent,
  taskFiltersChangedInEffect,
  tasksLoadingStarted,
  createChecklist,
  editSideBarTaskClicked,
  loadSidebarTaskEdit,
  editTaskSidebarLoadStarted,
  deleteFromOptions,
  deleteOnDetails,
  deletedSuccessfully,
  checklistItemToggledOnDetails,
  removedMarkedAsDeleteChecklist,
  setSidebarLoadingFromTaskUpload,
  setSidebarLoadingFromMenuComp,
  messageSentOnDetails,
  loadNextPageFromTable,
  resetPaginationFiltersFromTable,
  nextPageLoadingStarted,
  nextPageLoaded,
  sortTasksFromTaskTable,
  sortingTasksLoadingFromEffect,
  setTasksAfterSort,
  loadNextPageFromKanban,
  nextPageLoadingStartedOnKanban,
  nextPageFromKanbanLoaded,
  taskViewChanged,
  loadMonthFromCalendar,
  monthLoadingStartedOnCalendar,
  monthFromCalendarLoaded,
  emptyTasksState,
  setDefaultMonth,
  selectedMonthChangedCalendar,
  selectedMonthChangedEffect,
};
