<div [ngClass]="{ 'p-float-label mb-2 mt-6': showLabel }">
  <p-dropdown
    #pDropdown
    *ngIf="!multiple"
    [required]="required"
    [placeholder]="placeholder"
    [name]="name"
    [optionValue]="optionValue"
    [optionLabel]="optionLabel"
    [disabled]="disabled"
    [appendTo]="appendTo"
    #dropdown="ngModel"
    [(ngModel)]="model"
    [options]="options"
    (onShow)="isDropdownShown = true; setOverlayWidth(pDropdown)"
    (onHide)="isDropdownShown = false"
    (onBlur)="onBlur()"
    [autoDisplayFirst]="autoDisplayFirst"
    [dropdownIcon]="isDropdownShown ? 'dropdown-icon opened' : 'dropdown-icon closed'"
    [ngClass]="{
      'ng-invalid ng-dirty': !!control?.invalid && !!control?.touched && !isDropdownShown,
      opened: isDropdownShown,
      closed: !isDropdownShown,
      'options-centered': dropdownClass.includes('options-centered'),
      borderless: dropdownClass.includes('borderless'),
      transparent: dropdownClass.includes('transparent'),
      'view-selector': dropdownClass.includes('view-selector'),
      'has-value': !!model
    }"
    (ngModelChange)="onModelChange($event)"
    inputId="float-label"
  >
    <ng-template let-outerContext pTemplate="item">
      <ng-container *ngIf="outerContext">
        <ng-container *ngTemplateOutlet="optionContent; context: { $implicit: outerContext }">
          <!--      show provided template content-->
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!optionContent">
        <!--      show default option if no template given-->
        <div class="dropdown-item">{{ outerContext?.[optionLabel] }}</div>
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="selectedItem">
      <ng-container *ngIf="selectedContent && outerContext">
        <ng-container *ngTemplateOutlet="selectedContent; context: { $implicit: outerContext }">
          <!--      show provided template content-->
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!selectedContent">
        <!--      show default option if no template given-->
        <div class="dropdown-item selected">{{ outerContext?.[optionLabel] }}</div>
      </ng-container>
    </ng-template>
  </p-dropdown>

  <p-multiSelect
    #pMultiSelect
    *ngIf="multiple"
    [filter]="false"
    [showHeader]="false"
    [maxSelectedLabels]="1"
    [required]="required"
    [placeholder]="placeholder"
    [name]="name"
    [optionValue]="optionValue"
    [optionLabel]="optionLabel"
    [disabled]="disabled"
    [appendTo]="appendTo"
    #dropdown="ngModel"
    [(ngModel)]="model"
    [options]="options"
    (onPanelShow)="isDropdownShown = true; setOverlayWidth(pMultiSelect)"
    (onPanelHide)="isDropdownShown = false"
    (onBlur)="onBlur()"
    [dropdownIcon]="isDropdownShown ? 'dropdown-icon opened' : 'dropdown-icon closed'"
    [ngClass]="{
      'ng-invalid ng-dirty': !!control?.invalid && !!control?.touched && !isDropdownShown,
      opened: isDropdownShown,
      closed: !isDropdownShown,
      'options-centered': dropdownClass.includes('options-centered'),
      borderless: dropdownClass.includes('borderless')
    }"
    (ngModelChange)="onModelChange($event)"
    inputId="float-label"
    [selectedItemsLabel]="selectedItemsLabel"
    emptyMessage="No items found"
    emptyFilterMessage="No results found"
  >
    <ng-template let-outerContext pTemplate="item">
      <ng-container *ngIf="outerContext">
        <ng-container *ngTemplateOutlet="optionContent; context: { $implicit: outerContext }">
          <!--      show provided template content-->
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!optionContent">
        <!--      show default option if no template given-->
        <div class="dropdown-item">{{ outerContext?.[optionLabel] }}</div>
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="selectedItem">
      <ng-container *ngIf="selectedContent && outerContext">
        <ng-container *ngTemplateOutlet="selectedContent; context: { $implicit: outerContext }">
          <!--      show provided template content-->
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!selectedContent">
        <!--      show default option if no template given-->
        <div class="dropdown-item selected">{{ outerContext?.[optionLabel] }}</div>
      </ng-container>
    </ng-template>
  </p-multiSelect>

  <label *ngIf="showLabel" for="float-label"> {{ label }}</label>
  <small
    *ngIf="showError"
    [ngClass]="{
      'visibility-hidden': !!control?.valid || !!control?.disabled || !control?.touched || isDropdownShown
    }"
    class="p-error text-uppercase"
    >REQUIRED
  </small>
</div>
