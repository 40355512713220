import { Component, Input } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

export const PROGRESS_BAR_COLOR_CLASSES = {
  SHADE_0: 'bg-color_accent_shade_2',
  ACCENT_SHADE_2: 'bg-shade_1',
};
/**
 * @description A progress bar component that can project content after the progress bar.
 * Note: When isNegative is given(true), the progress bar will be red not taking into consideration the allow background color change
 * isNegative by default is disabled, can be activation by setting it from outside
 */
@Component({
  selector: 'app-progress-bar-projectable',
  standalone: true,
  imports: [NgIf, TooltipModule, NgClass, NgStyle],
  templateUrl: './progress-bar-projectable.component.html',
  styleUrl: './progress-bar-projectable.component.scss',
})
export class ProgressBarProjectableComponent {
  protected readonly Math = Math;

  @Input({ required: true }) percent = 0;
  @Input() maxWidth = 100;
  @Input() minWidth = 0;
  @Input() completePercentage = 100; // percentage when done
  @Input() allowBgColorChange: boolean = true; // if false it will not change colors on done or overflow
  @Input() text: string;
  @Input() textColor: string; // tailwind class for text before progress bar
  @Input() fillClass: string; // tailwind class
  @Input() heightClass: string = 'h-2'; // tailwind class
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' | undefined = undefined;
  @Input() percentWidth: 'default' | 'big' = 'default';
  @Input() isNegative: boolean = false;
  @Input() biggerPercentText = false;

  get width() {
    return this.percent >= this.maxWidth
      ? this.maxWidth
      : this.percent <= this.minWidth
        ? this.minWidth
        : this.percent;
  }
}
