import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DISCUSSION_FILTER_TYPE,
  discussionTypeList,
  IMessagingHeaderFilters,
} from '../../constants/messages.constants';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  discardMessagingGroupCache,
  filterMessages,
  loadFirstPageOfGroups,
  setMessagingView,
} from '../../../store/messages/messages.actions';
import { IMessagingGroup, MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { getMessagingView, getSelectedGroup } from '../../../store/messages/messages.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchBarMinimalistComponent } from '../../search-bar-minimalist/search-bar-minimalist.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-messages-header',
  templateUrl: './messages-header.component.html',
  styleUrls: ['./messages-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormField,
    MatSelect,
    NgFor,
    MatOption,
    BackButtonBoxComponent,
    SearchBarMinimalistComponent,
    AsyncPipe,
  ],
})
export class MessagesHeaderComponent implements OnInit, OnDestroy {
  defaultDiscussionType: DISCUSSION_FILTER_TYPE = DISCUSSION_FILTER_TYPE.ALL_DISCUSSIONS;
  discussionTypeList: { type: DISCUSSION_FILTER_TYPE; text: string }[] = discussionTypeList;
  MESSAGING_VIEWS = MESSAGING_VIEWS;
  selectedView$ = this.store.select(getMessagingView);
  selectedGroup$: Observable<IMessagingGroup> = this.store.select(getSelectedGroup);
  defaultHeaderFilter = { search: undefined, discussionType: undefined };
  filter: IMessagingHeaderFilters = this.defaultHeaderFilter;
  isDestroyed$ = new Subject<void>();
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.selectedView$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      this.filter = { ...this.defaultHeaderFilter };
    });
  }

  openCreateDiscussion() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_CREATE }));
  }

  goBack(view = MESSAGING_VIEWS.DISCUSSION_LIST) {
    this.store.dispatch(setMessagingView({ view }));
    if (view === MESSAGING_VIEWS.DISCUSSION_LIST) {
      this.store.dispatch(loadFirstPageOfGroups({}));
    }

    if (view !== MESSAGING_VIEWS.DISCUSSION_VIEW) {
      this.store.dispatch(discardMessagingGroupCache());
    }
  }

  onTypeFilterChange(selectionChange) {
    if (selectionChange.isUserInput) {
      this.filter.discussionType = selectionChange.source.value;
      this.store.dispatch(filterMessages({ filters: { ...this.filter } }));
    }
  }

  onTextFilterChange(text) {
    this.filter.search = text;
    this.store.dispatch(filterMessages({ filters: { ...this.filter } }));
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
