import { Component, OnInit } from '@angular/core';
import { CUSTOM_OVERLAY_VIEWS, CustomOverlayService } from '../../services/custom-overlay.service';
import { ImgPreviewComponent } from '../img-preview/img-preview.component';
import { OptionsListStyledComponent } from '../overlays/options-list-styled/options-list-styled.component';
import { MessagingTagProjectOverlayComponent } from '../messages/messaging-tag-project-overlay/messaging-tag-project-overlay.component';
import { MessagingTagUserOverlayComponent } from '../messages/messaging-tag-user-overlay/messaging-tag-user-overlay.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-custom-overlay',
  templateUrl: './custom-overlay.component.html',
  styleUrls: ['./custom-overlay.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MessagingTagUserOverlayComponent,
    MessagingTagProjectOverlayComponent,
    OptionsListStyledComponent,
    ImgPreviewComponent,
  ],
})
export class CustomOverlayComponent implements OnInit {
  data: any;
  selectedView: CUSTOM_OVERLAY_VIEWS;
  VIEWS = CUSTOM_OVERLAY_VIEWS;

  constructor(private customOverlayService: CustomOverlayService) {}

  ngOnInit(): void {
    this.customOverlayService.data$.subscribe((data) => {
      this.data = data;
    });
    this.customOverlayService.selectedView$.subscribe((view) => {
      this.selectedView = view;
    });
  }

  registerSelectedOption($event: any) {
    this.customOverlayService.closeOverlay();
    this.customOverlayService.emitOutputData($event);
  }
}
