import { Component, OnDestroy, OnInit } from '@angular/core';
import { MoneyPipe, SHOW_DECIMALS } from '../../../pipes/framework/money-short.pipe';
import { combineLatest, Subject } from 'rxjs';
import { FiscalService } from '../../../services/fiscal.service';
import { takeUntil } from 'rxjs/operators';
import { CashFlowService } from '../../../services/cash-flow.service';
import { BaseChartDirective } from 'ng2-charts';
import { CashFlowStatsComponent } from '../cash-flow-stats/cash-flow-stats.component';
import { Store } from '@ngrx/store';
import { cashflowSelectors } from '../../../store/cashflow/cashflow.selectors';
import { CashflowTotals } from '../../constants/cashflow.constants';
import { ChartConfiguration, ChartData } from 'chart.js';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-cash-flow-graph',
  templateUrl: './cash-flow-graph.component.html',
  styleUrls: ['./cash-flow-graph.component.scss'],
  standalone: true,
  imports: [BaseChartDirective, CashFlowStatsComponent, AsyncPipe, NgClass],
})
export class CashFlowGraphComponent implements OnInit, OnDestroy {
  RED_NEGATIVE_COLOR = '#F14B43';
  WHITE_COLOR = '#FFFFFF';
  PRIMARY_COLOR = '#071743';
  ACCENT_COLOR = '#FFBA49';
  SHADE_1_COLOR = '#2c427e';
  SHADE_2_COLOR = '#667DBC';
  SHADE_3_COLOR = '#A5B4DE';
  SHADE_4_COLOR = '#cbd7f7';
  SHADE_5_COLOR = '#E2E7F5';
  SHADE_1_FADED_COLOR = 'rgba(44,66,126,0.5)';
  SHADE_5_FADED_COLOR = 'rgba(226,231,245,0.5)';
  ACCENT_FADED_COLOR = 'rgba(255,186,73,0.5)';
  SECONDARY_FADED_COLOR = 'rgba(67, 146, 241, 0.5)';
  DEFAULT_MAX_TICK = 1000;

  isDestroyed$ = new Subject<boolean>();
  chartConfig: ChartConfiguration<'bar', any, any>['options'] = this.getChartConfig();
  chartData: ChartData<'bar'>;

  totals$ = this.store.select(cashflowSelectors.getTotals);
  firstForecastMonth$ = this.store.select(cashflowSelectors.firstForecastMonth);
  isLoading$ = this.store.select(cashflowSelectors.isLoading);

  constructor(
    private toShortMoney: MoneyPipe,
    private fiscalService: FiscalService,
    private cashflowService: CashFlowService,
    private store: Store,
  ) {}

  ngOnInit() {
    combineLatest([this.totals$, this.firstForecastMonth$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([totals, firstForecastMonth]) => {
        if (totals != null && firstForecastMonth != null) {
          this.chartData = this.getChartData(totals, firstForecastMonth);
          this.chartConfig = this.getChartConfig();
        }
      });
  }

  /**
   * Returns the chart configuration object.
   * Should be called whenever the data changes as it is dependent on the data (e.g. max tick value, min values).
   */
  getChartConfig(): ChartConfiguration<'bar', any, any>['options'] {
    const config: ChartConfiguration<'bar', any, any>['options'] = {
      responsive: true,
      maintainAspectRatio: false,
      // @ts-ignore - chart.js types are dumb AF
      barPercentage: 0.8,
      categoryPercentage: 0.85,
      borderRadius: 4,
      devicePixelRatio: 2, // to get a sharper graph, may be rethought
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        y: {
          display: true,
          stacked: false,
          max: this.getMaxTick(),
          beginAtZero: true,
          ticks: {
            padding: 10,
            stepSize: this.calculateStepSize(),
            color: this.PRIMARY_COLOR,
            callback: (value, index, values) => {
              return (
                '$' +
                this.toShortMoney.transformToShortSimplified(value, SHOW_DECIMALS.ABOVE_MILLION)
              );
            },
          },
          border: {
            display: false,
          },
          grid: {
            color: this.SHADE_4_COLOR,
          },
          afterFit: (axis) => {
            // IDK why isn't there a better place to configure this
            axis.paddingTop = 20;
            return axis;
          },
        },
        x: {
          display: false,
          stacked: true,
          suggestedMin: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return config;
  }

  /**
   * Returns the chart data with all of the datasets and their colors.
   */
  private getChartData(totals: CashflowTotals, firstForecastMonth: number): ChartData<'bar'> {
    const chartData: ChartData<'bar'> = {
      labels: Array(12).fill(''),
      datasets: [
        { data: [], label: '', stack: 'budget', backgroundColor: this.SHADE_5_COLOR },
        {
          data: [],
          label: '',
          stack: 'actuals_forecast',
          order: 2,
          backgroundColor: Array(12).fill(this.SHADE_1_COLOR),
        },
        {
          data: Array(12).fill(0),
          label: '',
          stack: 'actuals_forecast',
          order: 1,
          backgroundColor: this.ACCENT_COLOR,
        },
      ],
    };

    // negative values are not shown, so we need to map them to 0
    chartData.datasets[0].data = this.mapNegative(totals.monthly_budget);
    chartData.datasets[1].data = this.mapNegative(totals.monthly_actuals_or_forecast);

    // until current month, we want to show the actuals
    for (let i = 0; i < firstForecastMonth - 1; i++) {
      chartData.datasets[1].backgroundColor[i] = this.ACCENT_COLOR;
    }

    if (firstForecastMonth > 0 && firstForecastMonth < 13 && totals.current_month_actuals > 0) {
      // the total column height is equal to forecast, but is stacked
      // we want to show the remaining actuals to forecast
      // total = actuals + (forecast - actuals)
      // chartData.datasets[1].data[firstForecastMonth - 1] =
      //   totals.monthly_actuals_or_forecast[firstForecastMonth - 1] - totals.current_month_actuals;
      chartData.datasets[2].data[firstForecastMonth - 1] =
        totals.current_month_actuals > 0 ? totals.current_month_actuals : 0;
    }

    return chartData;
  }

  private calculateStepSize() {
    const maxTick = this.getMaxTick();
    // const log = Math.floor(Math.log10(maxTick));
    // return Math.ceil(maxTick / Math.pow(10, log));
    return Math.round(maxTick / 4);
  }

  /**
   * Based on the maximum of all the data, calculates the maximum tick value = highest line on the graph. <br/>
   * If there is no data, returns a default value (= DEFAULT_MAX_TICK).
   */
  private getMaxTick(chartData: ChartData<'bar'> = this.chartData) {
    if (!chartData?.datasets) {
      return this.DEFAULT_MAX_TICK;
    }
    const maxValue = Math.max(
      this.maxOfArray(chartData.datasets[0].data as number[]),
      this.maxOfArray(chartData.datasets[1].data as number[]),
      this.maxOfArray(chartData.datasets[2].data as number[]),
    );
    if (maxValue === 0) {
      return this.DEFAULT_MAX_TICK;
    }
    return maxValue * 1.17;
    // using below solution it always returns even values like 40Mil, 50Mil, 60Mil...
    // const logValue = Math.pow(10, Math.ceil(Math.log10(maxValue)));
    // return (Math.ceil((maxValue * 11) / logValue) / 10) * logValue;
  }

  private maxOfArray(array: number[]) {
    return array.reduce((acc, curr) => {
      return acc > curr ? acc : curr;
    }, 0 - Infinity);
  }

  /**
   * Maps negative values to 0
   */
  private mapNegative(array: number[]) {
    return array.map((value) => (value > 0 ? value : 0));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
