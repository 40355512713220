<div class="task-interaction-bar-wrapper">
  <div
    *ngIf="!model.isSideBarLoading"
    class="task-header"
    [ngClass]="{ 'pl-0': (getCurrentPage$ | async) === FIRST_PAGE }"
  >
    <app-back-button-box *ngIf="(getCurrentPage$ | async) !== FIRST_PAGE" (clicked)="goBack()"></app-back-button-box>
    <span>{{ (isTaskSidebarEdit$ | async) ? 'Edit' : 'Add' }} Task</span>
  </div>
  <ng-scrollbar
    *ngIf="!model.isSideBarLoading"
    appearance="standard"
    class="std-scrollbar"
    #ngScrollbar
    smoothScroll
    [autoHeightDisabled]="true"
  >
    <div *ngIf="(getCurrentPage$ | async) === FIRST_PAGE">
      <app-tasks-interaction-bar-first-page #pageComponent [model]="model"></app-tasks-interaction-bar-first-page>
    </div>
    <div *ngIf="(getCurrentPage$ | async) === SECOND_PAGE">
      <app-tasks-interaction-bar-second-page #pageComponent [model]="model"></app-tasks-interaction-bar-second-page>
    </div>

    <div *ngIf="(getCurrentPage$ | async) === THIRD_PAGE">
      <app-tasks-interaction-bar-third-page #pageComponent [model]="model"></app-tasks-interaction-bar-third-page>
    </div>
  </ng-scrollbar>
  <div *ngIf="model.isSideBarLoading">
    <div class="h-100 flex justify-center items-start mt-5">
      <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
    </div>
  </div>
</div>
<div *ngIf="!model.isSideBarLoading" class="button-wrapper">
  <button type="submit" *ngIf="!(getIsSummit$ | async)" (click)="onNextPage()" class="btn next-btn" mat-button>
    <span>Next</span>
  </button>

  <button *ngIf="getIsSummit$ | async" (click)="onSubmit()" class="btn" mat-button>
    <span>Submit</span>
  </button>
</div>
