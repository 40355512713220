import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsApiService } from './notifications-api.service';
import * as moment from 'moment';
import {
  INotification,
  NOTIFICATION_CATEGORIES,
  NotificationsGrouped,
  NotificationsMap,
} from '../framework/constants/notification.constants';
import { InteractionBarStateService } from './interaction-bar-state.service';
import { Subject } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { filter, throttle } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsStateService implements OnDestroy {
  notificationsGrouped: NotificationsGrouped = { seen: new Map(), unseen: new Map() };
  loadedState = new Subject<boolean>(); // emits at the start and the end of fetching notifications
  unseenNotifications = 0;

  nextPageToLoad = 1;
  lastPageEmpty = false;
  PAGE_SIZE = 20;

  selectedCategory = NOTIFICATION_CATEGORIES.ALL;
  private shouldRetrieveNextPage = new Subject<void>();

  private _notifications: INotification[] = [];
  get notifications(): INotification[] {
    return this._notifications;
  }

  set notifications(value: INotification[]) {
    this._notifications = value;
    this.notificationsGrouped = this.getNotificationsGrouped();
    this.unseenNotifications = this.notifications.filter((n) => !n.is_seen).length;
    this.interactionBarState.notifCount.next(this.unseenNotifications);
    this.loadedState.next(true);
  }

  constructor(
    private notificationsApi: NotificationsApiService,
    private interactionBarState: InteractionBarStateService,
    private notif: NotificationsService,
  ) {
    // does not start loading a new page until the previous one is loaded
    // throttle does emit the first value when subscribed,
    // but prevents subsequent emissions while the loadedState does not emit a true value
    this.shouldRetrieveNextPage
      .pipe(throttle(() => this.loadedState.pipe(filter((loaded) => loaded))))
      .subscribe(() => {
        this.retrieveNextPage();
      });
  }

  /**
   * This method can be called multiple times in a row (e.g. in the scroll event handler)
   * because it's throttled and will call retrieveNextPage only when the previous page is loaded.
   */
  retrieveThrottled() {
    this.shouldRetrieveNextPage.next();
  }

  async retrieveNextPage(isFirstPage = false): Promise<void> {
    let pageCount = this.PAGE_SIZE;
    if (isFirstPage) {
      this.lastPageEmpty = false;
      this.nextPageToLoad = 1;
      this.notifications = [];
      // load more on first page, to make sure we have a scrollbar,
      // otherwise the user won't be able to load more as he cannot scroll to trigger the load
      pageCount = this.PAGE_SIZE * 2;
    }

    if (this.lastPageEmpty) {
      // if we loaded all the data, don't try to load more
      return;
    }

    let category = this.selectedCategory;
    if (category === NOTIFICATION_CATEGORIES.ALL) {
      category = undefined;
    }

    try {
      this.loadedState.next(false);
      const notifications = await this.notificationsApi.getPaginated(
        this.nextPageToLoad,
        pageCount,
        category,
      );
      if (!notifications.length) {
        this.lastPageEmpty = true;
        this.loadedState.next(true);
        return;
      }
      const notificationsWithLocalData = notifications.map(this.fillNotificationWithLocalData);
      this.notifications = [...this.notifications, ...notificationsWithLocalData];
    } catch (ex) {
      this.notif.showError('Could not load notifications.');
    } finally {
      if (isFirstPage) {
        this.nextPageToLoad += 2; // first page loads 2 pages data
      } else {
        this.nextPageToLoad++;
      }
    }
  }

  gotNewNotificationOnSocket(newNotif: INotification) {
    if (!newNotif) {
      console.warn('got an invalid notification on socket:', newNotif);
      return;
    }

    if (newNotif.notification_type === 'report_failed') {
      this.notif.showError('Report generation failed please try again later or reach out.');
    }

    const notif = this.fillNotificationWithLocalData(newNotif);
    this.notifications = [notif, ...this.notifications];
    this.unseenNotifications += 1;
    this.interactionBarState.notifCount.next(this.unseenNotifications);
  }

  async setNotificationsAsSeen(notification: INotification) {
    if (!notification.is_seen) {
      try {
        await this.notificationsApi.updateStatus(notification.id);
        await this.retrieveNextPage(true); // refresh all so sorting is correct
      } catch (ex) {
        this.notif.showError('Could not mark notification as seen.');
      }
    }
  }

  setCategory(category: NOTIFICATION_CATEGORIES) {
    this.selectedCategory = category;
    this.retrieveNextPage(true);
  }

  private fillNotificationWithLocalData = (notif: INotification): INotification => {
    notif.message = this.getMessage(notif);
    notif.title = this.getTitle(notif);
    notif.subtitle = this.getSubtitle(notif);
    return notif;
  };

  private getSubtitle(notif: INotification): string {
    let subtitle = '';
    if (notif.project) {
      subtitle += `${notif.project.title} | `;
    }
    const currentDate = moment.utc().local();
    const notifDate = moment.utc(notif.created_at).local();

    if (currentDate.diff(notifDate, 'days') > 1) {
      subtitle += notifDate.format('MM/DD/YYYY hh:mm A');
      return subtitle;
    }

    const minutes = currentDate.diff(notifDate, 'minutes');
    if (minutes >= 60) {
      subtitle += currentDate.diff(notifDate, 'hours') + 'h ago';
    } else if (minutes >= 1) {
      subtitle += minutes + 'm ago';
    } else {
      subtitle += 'Just now';
    }

    return subtitle;
  }

  private getTitle(notif: INotification): string {
    let title = '';
    if (notif.from_user?.name) {
      title += notif.from_user.name;
    }
    if (notif.from_user?.company_name) {
      title += ' (' + notif.from_user.company_name + ')';
    }
    return title;
  }

  private getMessage(notif: INotification): string {
    switch (notif?.notification_type) {
      case 'message':
        return 'Sent a message';
      case 'schedule_visit':
        return 'Scheduled a visit';
      case 'reschedule_visit':
        return 'Rescheduled a visit';
      case 'scheduled_visit':
        return 'Scheduled a visit';
      case 'rescheduled_visit':
        return 'Rescheduled a visit';
      case 'cancel_visit':
        return 'Cancelled a visit';
      case 'bid_placed':
        return 'Placed a bid';
      case 'bid_accepted':
        return 'Accepted a bid';
      case 'bid_declined':
        return 'Declined a bid';
      case 'invoice_uploaded':
        return 'Submitted an invoice';
      case 'invoice_updated':
        return 'Updated an invoice';
      case 'invoice_deleted':
        return 'Deleted an invoice';
      case 'project_invite':
        return 'Project invite';
      case 'project_invite_rejected':
        return 'Invitation rejected';
      case 'message_tag':
        return 'You have been mentioned';
      case 'bid_revised':
        return 'Bid revised';
      case 'new_task':
        return `New task: <b>${notif?.extra_data?.title ?? ''}</b>.`;
      case 'task_updated': {
        if (notif.extra_data?.status === 'in_progress') {
          return `<b>${notif.extra_data?.title}</b> is now in progress.`;
        } else if (notif.extra_data?.status === 'pending') {
          return `<b>${notif.extra_data?.title}</b> is now pending.`;
        } else if (notif.extra_data?.status === 'completed') {
          return `<b>${notif.extra_data?.title}</b> has been completed.`;
        } else if (notif.extra_data?.title) {
          return `There is an update on <b>${notif.extra_data?.title}</b>.`;
        }
        return 'Task updated';
      }
      case 'task_file_uploaded': {
        return `A new file is uploaded to <b>${notif.extra_data?.title ?? ''}</b>.`;
      }
      case 'task_daily_reminder': {
        return `Task <b>${notif.extra_data?.title ?? ''}</b> is due tomorrow.`;
      }
      case 'task_weekly_reminder': {
        return `You have <b>${
          notif.extra_data?.upcoming_tasks_count ?? ''
        }</b> upcoming tasks this week, of which <b>${
          notif.extra_data?.overdue_tasks_count ?? ''
        }</b> overdue.`;
      }
      case 'task_spectating_weekly_reminder': {
        return `You have <b>${
          notif.extra_data?.upcoming_tasks_count ?? ''
        }</b> upcoming tasks that you are spectating this week, of which <b>${
          notif.extra_data?.overdue_tasks_count ?? ''
        }</b> overdue.`;
      }
      case 'team_invite': {
        if (notif.extra_data?.is_accepted) {
          return `Invitation accepted, click here to go to your team.`;
        }
        return `Click on this notification to accept the invitation.`;
      }
      case 'report_generated': {
        return 'A new report is ready to be downloaded from the <b>Reports</b> folder.';
      }
      case 'report_failed': {
        return '<b>Report</b> generation failed please try again later.';
      }
      case 'activity_assigned': {
        const projectTitle = notif.extra_data?.project?.title ?? '';
        return `<b>${projectTitle ? '[' + projectTitle + '] ' : ''}${
          notif.extra_data?.title ?? ''
        }</b> has been assigned to you.`;
      }
      case 'activity_unassigned': {
        const projectTitle = notif.extra_data?.project?.title ?? '';
        return `<b>${projectTitle ? '[' + projectTitle + '] ' : ''}${
          notif.extra_data?.title ?? ''
        }</b> has been unassigned from you.`;
      }
      default:
        console.warn('Unhandled notification: ', notif.notification_type);
        return '';
    }
  }

  private getNotificationsGrouped(): NotificationsGrouped {
    // sorting is done on the backend, no need to sort here

    const seen = new Map<string, INotification[]>();
    const unseen = new Map<string, INotification[]>();

    if (!this.notifications?.length) {
      return { seen, unseen };
    }

    const checkAndAddTo = (map: NotificationsMap, notification: INotification) => {
      // interpret date as utc, then convert to local
      const notifDate = moment.utc(notification.created_at).local().format('YYYY-MM-DD');
      if (map.has(notifDate)) {
        map.get(notifDate).push(notification);
      } else {
        map.set(notifDate, [notification]);
      }
    };

    this.notifications.forEach((notification) => {
      if (notification.is_seen) {
        checkAndAddTo(seen, notification);
      } else {
        checkAndAddTo(unseen, notification);
      }
    });

    return { seen, unseen };
  }

  ngOnDestroy(): void {
    this.loadedState.complete();
    this.shouldRetrieveNextPage.complete();
  }
}
