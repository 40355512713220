import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getAllTasks,
  getPaginationFirstPageLoading,
  isTableProgressBarLoading,
} from '../../../../store/tasks/tasks.selectors';
import {
  IChecklist,
  TASK_ORDER_BY,
  TASK_STATUS_VALUE,
  TASK_TYPE,
  TaskOrderBy,
} from '../../../../store/tasks/tasks.interfaces';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { CurrentUserService } from '../../../../services/current-user.service';
import { tasksActions } from '../../../../store/tasks/tasks.actions';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import { TasksService } from '../../../../services/tasks.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { taskTableHeader, TaskTableHeader } from './task-table.constants';
import {
  NO_TIMEZONE,
  UTC_DATE,
  DateCustomPipe,
} from '../../../../pipes/framework/date-custom.pipe';
import { GetTasksTableActionsPipe } from '../../../../pipes/framework/disable-action.pipe';
import { OptionsListSimpleComponent } from '../../../../framework/overlays/options-list-simple/options-list-simple.component';
import { ChecklistPopupComponent } from '../../../../framework/tasks/checklist-popup/checklist-popup.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SortArrowComponent } from '../../../../framework/sort-arrow/sort-arrow.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    SortArrowComponent,
    MatProgressBar,
    NgScrollbar,
    MatTooltip,
    CdkOverlayOrigin,
    ChecklistPopupComponent,
    OptionsListSimpleComponent,
    AsyncPipe,
    GetTasksTableActionsPipe,
    DateCustomPipe,
  ],
})
/**
 * Shows all tasks in a table. It supports infinite scrolling.
 */
export class TasksTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollbar') scrollbarRef: NgScrollbar;
  tasks$ = this.store.select(getAllTasks);
  TASK_STATUS_VALUE = TASK_STATUS_VALUE;
  showChecklistItem: Array<boolean> = []; // shows if a task's checklist items should be shown on hover
  showOptionsList: Array<boolean> = []; // shows if a task's options list (three dot) menu should be shown
  TASK_TYPE = TASK_TYPE;
  isLoading$ = this.store.select(getPaginationFirstPageLoading);
  isTableProgressBarLoading$ = this.store.select(isTableProgressBarLoading);
  lastScrollSubscription: Subscription;
  taskTableHeader = taskTableHeader;
  TASK_ORDER_BY = TASK_ORDER_BY;

  lastSortedBy = -1;
  lastSortedAscending = Array(this.taskTableHeader.length).fill(false);
  protected readonly NO_TIMEZONE = NO_TIMEZONE;
  protected readonly UTC_DATE = UTC_DATE;
  private isDestroyed$ = new Subject<void>();
  private lastScrollPosition;

  constructor(
    private barState: InteractionBarStateService,
    private store: Store,
    public taskService: TasksService,
    private notif: NotificationsService,
    public userService: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(tasksActions.loadNextPageFromTable({ firstPage: true }));
    this.tasks$.pipe(takeUntil(this.isDestroyed$)).subscribe((tasks) => {
      this.showChecklistItem = new Array(tasks.length).fill(false);
      this.showOptionsList = new Array(tasks.length).fill(false);
    });
  }

  ngAfterViewInit(): void {
    this.isLoading$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => !value),
      )
      .subscribe((event) => {
        // on every loading state the scrollbar is destroyed, and a new one gets created after loading
        // that's why we have to subscribe after every loading
        this.handleScrollbarEvents();
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
    this.store.dispatch(tasksActions.resetPaginationFiltersFromTable());
  }

  /**
   * This functions waits (with an interval) for the scrollbar to appear in the DOM.
   * After that it subscribes to its scrolled event to handle pagination.
   */
  handleScrollbarEvents() {
    if (this.lastScrollSubscription) {
      this.lastScrollSubscription.unsubscribe();
    }
    const intervalId = setInterval(() => {
      if (!this.scrollbarRef) {
        return;
      }
      clearInterval(intervalId);
      this.lastScrollSubscription = this.scrollbarRef?.scrolled
        .pipe(takeUntil(this.isDestroyed$), debounceTime(10))
        .subscribe((event) => {
          const target = event.target as HTMLElement;
          if (this.lastScrollPosition < target.scrollTop) {
            // if user scrolled downwards
            if (target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.8) {
              // if user is in the bottom part (80%-100%) of the scrollbar track
              this.store.dispatch(tasksActions.loadNextPageFromTable({}));
            }
          }
          this.lastScrollPosition = target.scrollTop;
        });
    }, 50);
  }

  sortBy(index: number, tableHeader: TaskTableHeader) {
    if (tableHeader.type === TASK_ORDER_BY.NONE) {
      return;
    }

    this.lastSortedBy = index;
    this.lastSortedAscending[index] = !this.lastSortedAscending[index];
    this.store.dispatch(
      tasksActions.sortTasksFromTaskTable({
        order_direction: this.lastSortedAscending[index] ? 'asc' : 'desc',
        order_by: tableHeader.type as TaskOrderBy,
      }),
    );
  }

  areAllItemsChecked(checklist: IChecklist) {
    for (const item of checklist.items) {
      if (!item.value) {
        return false;
      }
    }
    return true;
  }
}
