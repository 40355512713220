import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { FINAL_SLIDE, NEXT_SLIDE, PREVIOUS_SLIDE } from '../framework/constants/user.constants';
import { ProjectApiService } from './project-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddProjectService {
  private _onTabChange = new Subject();
  pageState = new EventEmitter();
  totalCost = 0;
  title = null;
  address = '';

  constructor(
    private el: ElementRef,
    private projectApi: ProjectApiService,
  ) {}

  emitTabChange(tabIndex) {
    this._onTabChange.next(tabIndex);
  }

  get onTabChange() {
    return this._onTabChange.asObservable();
  }

  announceNext() {
    this.pageState.next(NEXT_SLIDE);
  }

  announcePrev() {
    this.pageState.next(PREVIOUS_SLIDE);
  }

  announceFinalStep() {
    this.pageState.next(FINAL_SLIDE);
  }

  announceFinalStepCost(cost) {
    this.totalCost = cost;
    this.pageState.next({ action: FINAL_SLIDE, data: cost });
  }

  selectCheckbox() {
    const boxes = this.el.nativeElement.querySelectorAll('.checkbox-item');
    boxes.forEach((box) => {
      box.classList.remove('checked');
    });

    const checkboxes = this.el.nativeElement.querySelectorAll('.mat-pseudo-checkbox-checked');
    checkboxes.forEach((checkbox) => {
      checkbox.parentNode.parentNode.classList.add('checked');
    });
  }

  retrieveBudget(number: number) {
    // this.projectApi.getBudget
  }
}
