<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="show"
  [cdkConnectedOverlayPositions]="positions"
>
  <div class="options-overlay-white checklist-items-hover">
    <div *ngFor="let item of checklistItems" class="flex items-center">
      <span
        [ngClass]="item.value ? 'text-color_accept' : 'text-shade_4'"
        class="icon-approved text-size_icon mr-2"
      ></span>
      <span class="text-size_m font-weight-600 text-shade_1">{{ item.name }}</span>
    </div>
  </div>
</ng-template>
