import { EventEmitter, Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_BASE,
  REST_COMMITMENTS,
  REST_LINE_ITEMS_BY_PROJECT,
  REST_PROJECTS,
  TMP_SERVICE_PROVIDER,
} from '../restApi/RestRoutes';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { CurrentUserService } from './current-user.service';
import { Router } from '@angular/router';
import { COMMITMENTS_TYPE } from '../framework/constants/interaction-bar.constants';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { AppState } from '../store/app-state';
import { Store } from '@ngrx/store';
import { DeepCopyService } from './deep-copy.service';

import {
  AllCommitmentsResponse,
  AllCommitmentsSummaryResponse,
  Commitment,
  COMMITMENT_APPROVAL_STATUS,
  ContractSummaryResponse,
  Cost,
  DirectCostsSummaryResponse,
  FilteredCommitmentData,
  ICommitmentBudgetLineItem,
  ISidebarChangeOrder,
  ISidebarChangeOrderPayload,
  ISidebarCommitmentItem,
  ISidebarCommitmentItemsDTO,
  ISidebarContract,
  ISidebarContractPayload,
  ISidebarCost,
  ISidebarDirectCost,
  ISidebarDirectCostPayload,
  ISidebarInvoice,
  ISidebarInvoicePayload,
} from '../store/commitments/commitments.types';

@Injectable({
  providedIn: 'root',
})
export class CommitmentsService extends ErrorHandlerRest {
  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
    private notif: NotificationsService,
    private store: Store<AppState>,
  ) {
    super(user, router);
  }
  updateData = new EventEmitter();
  private approvedString = 'approved';
  public dataReady = new BehaviorSubject<boolean>(false);
  private _filteredData: FilteredCommitmentData;
  set filteredData(value) {
    if (value) {
      this._filteredData = value;
    }
  }

  get filteredData() {
    return this._filteredData;
  }

  // todo: remove 'Simple' in name and add return type
  async getAllCommitmentsSimple(projectId: number): Promise<AllCommitmentsResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/${REST_COMMITMENTS}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getCommitmentItems(projectId: number): Promise<ICommitmentBudgetLineItem[]> {
    try {
      return this.rest.get(
        `${REST_LINE_ITEMS_BY_PROJECT}/${projectId}?fiscal_view=1`,
        {},
        {
          view: 'summary',
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getAllCommitmentsSummary(projectId: number): Promise<AllCommitmentsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/${REST_COMMITMENTS}/summary`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getDirectCostsSummary(projectId: number): Promise<DirectCostsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/direct-costs/summary`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getContractSummary(contractId: number): Promise<DirectCostsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${REST_COMMITMENTS}/contracts/${contractId}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getCommitmentById(id: number): Promise<Commitment> {
    try {
      return this.rest.get(`${REST_BASE}${REST_COMMITMENTS}/${id}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getContractById(id: number): Promise<ContractSummaryResponse> {
    try {
      return this.rest.get(`${REST_BASE}${REST_COMMITMENTS}/contracts/${id}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  // this should be deprecated and deleted later
  // todo: [molnarszabi: 2023-08-23] deprecate remove this method, implement with new endpoint
  /**
   * @deprecated since version 2.3.100
   * @param projectId
   */
  getAllCommitments(projectId: number) {
    this.dataReady.next(false);
    return new Promise<any>((res, rej) => {
      this.rest.get(`${REST_PROJECTS}/${projectId}/${REST_COMMITMENTS}`).then(
        (data) => {
          this.addTemporaryContractors(data).then((modifiedData) => {
            data = modifiedData;
            this.filteredData = this.filterCommitmentsDataByUser(data);
            this.filteredData = this.calculateTotalsByStatus(this.filteredData);
            this.filteredData = this.setEditStatus(this.filteredData);
            this.filteredData = this.addContractCommitments(this.filteredData);
            this.filteredData = this.addChangeOrderCostsToContract(this.filteredData);
            this.filteredData = this.calculateTotals(this.filteredData);
            this.dataReady.next(true);
            res(this.filteredData);
          });
        },
        (error) => {
          rej(this.handleError(error));
        },
      );
    });
  }

  transformToChangeOrderDTO(changeOrderData: ISidebarChangeOrder) {
    const body: ISidebarChangeOrderPayload = {
      project_id: changeOrderData.project_id,
      type: changeOrderData.type,
      title: changeOrderData.title,
      description: changeOrderData.description,
      added_at: changeOrderData.added_at,
      approval_status: changeOrderData.approval_status,
      contract_id: changeOrderData.contract_id,
      commitment_items: [
        ...this.transformCommitmentItems(changeOrderData.commitment_items),
        ...this.transformCommitmentItems(changeOrderData.deleted_commitment_items),
      ],
    };

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(changeOrderData),
    };
  }

  /**
   * Add common fields to DTO, which are used in all commitment types
   * @param commitmentData
   */
  commonDTOFields(
    commitmentData: ISidebarContract | ISidebarChangeOrder | ISidebarInvoice | ISidebarDirectCost,
  ) {
    const body:
      | Partial<ISidebarContractPayload>
      | Partial<ISidebarChangeOrderPayload>
      | Partial<ISidebarInvoicePayload> = {};
    if (commitmentData.id) {
      body.id = commitmentData.id;
    }
    if (commitmentData.service_provider?.temporary_service_provider_id) {
      body.temporary_service_provider_id =
        commitmentData.service_provider.temporary_service_provider_id;
    }
    if (commitmentData.service_provider?.service_provider_user_id) {
      body.service_provider_user_id = commitmentData.service_provider.service_provider_user_id;
    }
    if (
      commitmentData.service_provider?.name &&
      !commitmentData.service_provider?.service_provider_user_id &&
      !commitmentData.service_provider?.temporary_service_provider_id
    ) {
      body.tmp_sp_name = commitmentData.service_provider.name;
    }

    return body;
  }

  transformToInvoiceDTO(invoiceData: ISidebarInvoice) {
    const body: ISidebarInvoicePayload = {
      project_id: invoiceData.project_id,
      type: invoiceData.type,
      invoice_number: invoiceData?.invoice_number ?? invoiceData?.title,
      description: invoiceData.description,
      added_at: invoiceData.added_at,
      paid_date: invoiceData.paid_date,
      paid_status: invoiceData.paid_status,
      contract_id: invoiceData.contract_id,
      costs: [
        ...this.transformCommitmentCosts(invoiceData.costs),
        ...this.transformCommitmentCosts(invoiceData.deleted_costs),
      ],
    };

    if (!body.paid_status) {
      delete body.paid_date;
    }

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(invoiceData),
    };
  }

  transformToDirectCostDTO(directCost: ISidebarDirectCost) {
    const body: ISidebarDirectCostPayload = {
      project_id: directCost.project_id,
      vendor: directCost.vendor,
      type: directCost.type,
      title: directCost.title,
      description: directCost.description,
      added_at: directCost.added_at,
      paid_date: directCost.paid_date,
      paid_status: directCost.paid_status,
      commitment_items: [
        ...this.transformCommitmentItems(directCost.commitment_items),
        ...this.transformCommitmentItems(directCost.deleted_commitment_items),
      ],
    };

    if (!body.paid_status) {
      delete body.paid_date;
    }

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(directCost),
    };
  }

  transformCommitmentItems(commitmentItems: Partial<ISidebarCommitmentItem>[]) {
    if (!commitmentItems?.length) {
      return [];
    }
    return commitmentItems.map((commitmentItem: ISidebarCommitmentItem) => {
      const transformed: ISidebarCommitmentItemsDTO = {
        item_id: commitmentItem.item.id,
        start_date: commitmentItem?.start_date ?? null,
        duration: commitmentItem.duration ?? null,
        budget_tag_id: commitmentItem.budget_tag.id,
        budget_tag_name: commitmentItem.budget_tag.name,
        costs: [...commitmentItem.costs, ...(commitmentItem?.deleted_costs ?? [])],
        deleted: !!commitmentItem?.deleted,
      };

      if (!commitmentItem.start_date) {
        delete transformed.start_date;
      }

      if (commitmentItem.duration === null) {
        delete transformed.duration;
      }

      if (commitmentItem.id) {
        transformed.id = commitmentItem.id;
      }
      return transformed;
    });
  }

  transformCommitmentCosts = (costs: ISidebarCost[]) => {
    const transformedCosts: ISidebarCost[] = DeepCopyService.deepCopy(costs);
    if (!transformedCosts?.length) {
      return [];
    }
    return transformedCosts.map((cost) => {
      const transformed: ISidebarCost = {
        id: cost.id ?? null,
        name: cost.name,
        value: cost.value,
        parent_id: cost.parent_id,
        deleted: cost?.deleted ?? false,
      };
      if (!cost.id) {
        delete transformed.id;
      }
      return transformed;
    });
  };

  transformToContractDTO(contractForm: ISidebarContract) {
    const body: ISidebarContractPayload = {
      project_id: contractForm.project_id,
      type: contractForm.type,
      title: contractForm.title,
      description: contractForm.description,
      added_at: contractForm.added_at,
      approval_status: contractForm.approval_status,
      commitment_items: [
        ...this.transformCommitmentItems(contractForm.commitment_items),
        ...this.transformCommitmentItems(contractForm?.deleted_commitment_items),
      ],
    };

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(contractForm),
    };
  }

  addNewContract(contractForm) {
    const body: Partial<ISidebarContractPayload> = this.transformToContractDTO(contractForm);
    return this.addNewCommitment(body);
  }

  addNewChangeOrder(changeOrderData: ISidebarChangeOrder) {
    const body: Partial<ISidebarContractPayload> = this.transformToChangeOrderDTO(changeOrderData);
    return this.addNewCommitment(body);
  }

  addNewInvoice(invoiceData: ISidebarInvoice) {
    const body: Partial<ISidebarInvoicePayload> = this.transformToInvoiceDTO(invoiceData);
    return this.addNewCommitment(body);
  }

  addNewDirectCost(directCostForm) {
    const body: Partial<ISidebarContractPayload> = this.transformToDirectCostDTO(directCostForm);
    return this.addNewCommitment(body);
  }

  addNewCommitment(body: Partial<ISidebarContractPayload> | Partial<ISidebarChangeOrderPayload>) {
    return new Promise<any>((res, rej) => {
      this.rest.post(`${REST_BASE}${REST_COMMITMENTS}`, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  modifyCommitment(body: Partial<ISidebarContractPayload> | Partial<ISidebarChangeOrderPayload>) {
    return new Promise<any>((res, rej) => {
      this.rest.put(`${REST_BASE}${REST_COMMITMENTS}/${body.id}`, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  modifyExistingInvoice(invoiceForm: ISidebarInvoice) {
    const body: Partial<ISidebarInvoicePayload> = this.transformToInvoiceDTO(invoiceForm);

    return this.modifyCommitment(body);
  }

  modifyExistingDirectCost(directCost: ISidebarDirectCost) {
    const body: Partial<ISidebarDirectCostPayload> = this.transformToDirectCostDTO(directCost);

    return this.modifyCommitment(body);
  }

  modifyExistingChangeOrder(changeOrderData: ISidebarChangeOrder) {
    const body: Partial<ISidebarContractPayload> = this.transformToContractDTO(changeOrderData);
    return this.modifyCommitment(body);
  }

  modifyExistingContract(contractForm: ISidebarContract) {
    const body: Partial<ISidebarContractPayload> = this.transformToContractDTO(contractForm);

    return this.modifyCommitment(body);
  }

  deleteCommitment(id) {
    return new Promise<any>((res, rej) => {
      this.rest.del(`${REST_BASE}${REST_COMMITMENTS}/${id}`).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  // TODO: check where is this function used and mostly delete it
  private calculateTotals(data: FilteredCommitmentData) {
    data.contractors.forEach((contractor) => {
      let changeOrdersPaidSum = 0;
      let contractsPaidSum = 0;

      contractor.commitments.contracts.forEach((contract) => {
        const contractCosts = contract.commitment_items.flatMap((item) => item.costs);
        contract.total_contract_costs = this.calculateTotal(contractCosts);
        contract.total_contract_costs_approved = this.calculateTotalCostsApproved(contract);
        contract.total_contract_paid_sum = this.calculateTotalPaidCosts(contractCosts);
        contract.total_change_order_costs = this.calculateTotalCostsCommitment(
          contract.change_orders,
        );
        contract.total_change_order_paid_sum = this.calculateTotalPaidCostsCommitment(
          contract.change_orders,
        );
        contract.total_invoices_costs_paid = this.calculateTotalCostsCommitment(contract.invoices);
        contract.total_invoices_costs = this.calculateTotalCommitment(contract.invoices);
        contract.total_committed_approved =
          contract.total_contract_costs_approved + contract.total_change_order_costs;
        contract.percentageSummaryCompleted = this.calculatePercentage(
          contract.total_invoices_costs_paid,
          contract.total_committed_approved,
        );
        contract.percentageSummaryCompletedBar = this.calculatePercentage(
          contract.total_invoices_costs_paid,
          contract.total_committed_approved,
          true,
        );
        contract.percentageCompletedBar = this.calculatePercentage(
          contract.total_contract_paid_sum,
          contract.total_contract_costs,
          true,
        );
        contract.percentageCompleted = this.calculatePercentage(
          contract.total_contract_paid_sum,
          contract.total_committed_approved,
        );
        contract.commitment_items.forEach((contractItem) => {
          contractItem.costs.forEach((contractCost) => {
            // let totalChangeOrderCost = 1;
            if (!contractCost.total_change_order_costs) {
              contractCost.total_change_order_costs = 0;
            }
            contract.change_orders.forEach((changeOrder) => {
              changeOrder.commitment_items.forEach((changeOrderItem) => {
                changeOrderItem.costs.forEach((changeOrderItemCost) => {
                  if (
                    changeOrderItemCost.parent_id === contractCost.id &&
                    changeOrder.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED
                  ) {
                    contractCost.total_change_order_costs += changeOrderItemCost.value;
                  }
                });
              });
            });
            // contractCost.total_change_order_costs = totalChangeOrderCost;
            contractCost.total_committed_approved =
              contractCost.total_change_order_costs + contractCost.value;
            contractCost.percentageCompletedBar = this.calculatePercentage(
              contractCost.paid_sum,
              contractCost.is_change_order_cost
                ? contractCost.total_change_order_costs
                : contractCost.total_committed_approved,
              true,
            );
            contractCost.percentageCompleted = this.calculatePercentage(
              contractCost.paid_sum,
              contractCost.is_change_order_cost
                ? contractCost.total_change_order_costs
                : contractCost.total_committed_approved,
            );
          });
        });

        contract.percentageCompletedBarChangeOrder = this.calculatePercentage(
          contract.total_change_order_paid_sum,
          contract.total_change_order_costs,
          true,
        );
        contract.percentageCompletedChangeOrder = this.calculatePercentage(
          contract.total_change_order_paid_sum,
          contract.total_change_order_costs,
        );

        contract.change_orders.forEach((changeOrder) => {
          const changeOrdCosts = changeOrder.commitment_items.flatMap((item) => item.costs);
          changeOrder.total_costs = this.calculateTotal(changeOrdCosts);
          changeOrder.total_paid_costs = this.calculateTotalPaidCosts(changeOrdCosts);
          changeOrder.percentageCompleted = this.calculatePercentage(
            changeOrder.total_paid_costs,
            changeOrder.total_costs,
          );
          changeOrder.percentageCompletedBar = this.calculatePercentage(
            changeOrder.total_paid_costs,
            changeOrder.total_costs,
            true,
          );
          changeOrder.commitment_items.forEach((changeOrderCommitmentItem) => {
            changeOrderCommitmentItem.costs.forEach((cost) => {
              cost.percentageCompleted = this.calculatePercentage(cost.paid_sum, cost.value);
              cost.percentageCompletedBar = this.calculatePercentage(
                cost.paid_sum,
                cost.value,
                true,
              );

              // add total committed approved from contract cost to change order cost
              contractCosts.forEach((contractCost) => {
                if (contractCost.id === cost.id) {
                  cost.total_committed_approved = contractCost.total_committed_approved;
                }
              });
            });
          });
        });

        contract.invoices.forEach((invoice) => {
          const invoiceCosts = invoice.commitment_items.flatMap((item) => item.costs);
          invoice.total_costs = this.calculateTotal(invoiceCosts);
        });
      });

      contractor.commitments.change_orders.forEach((changeOrd) => {
        if (changeOrd.approval_status === 'approved') {
          changeOrd.commitment_items.forEach((item) => {
            item.costs.forEach((cost) => {
              changeOrdersPaidSum += cost.paid_sum;
            });
          });
        }
      });
      contractor.commitments.contracts.forEach((contract) => {
        if (contract.approval_status === 'approved') {
          contract.commitment_items.forEach((item) => {
            item.costs.map((cost) => {
              contractsPaidSum += cost.paid_sum;
            });
          });
        }
      });
      contractor.change_orders_approved_paid_sum = changeOrdersPaidSum;
      contractor.contracts_paid_sum = contractsPaidSum;
      contractor.total_paid_sum = contractsPaidSum + changeOrdersPaidSum;
      contractor.percentageCompletedBar = this.calculatePercentage(
        contractor.total_paid_sum,
        contractor.total_committed,
        true,
      );
      contractor.percentageCompleted = this.calculatePercentage(
        contractor.total_paid_sum,
        contractor.total_committed,
      );
    });
    return data;
  }

  private addContractCommitments(data: FilteredCommitmentData) {
    data.contractors.forEach((contractor) => {
      contractor.commitments.contracts.forEach((contract) => {
        const invoices = contractor.commitments.invoices.filter(
          (invoice) => invoice.contract_id === contract.id,
        );
        const changeOrders = contractor.commitments.change_orders.filter(
          (changeOrd) => changeOrd.contract_id === contract.id,
        );
        contract.invoices = invoices;
        contract.change_orders = changeOrders;
      });
    });
    return data;
  }

  /**
   * Define if a commitment has invoices, change orders and it's costs are connected
   * @param data
   * @private
   */
  private setEditStatus(data: FilteredCommitmentData) {
    data.contractors.forEach((contractor) => {
      contractor.commitments.contracts.forEach((contract) => {
        contract.has_invoice = !!contractor.commitments.invoices.find(
          (invoice) => invoice.contract_id === contract.id,
        );
        contract.has_change_order = !!contractor.commitments.change_orders.find(
          (changeOrder) => changeOrder.contract_id === contract.id,
        );

        const contractInvoices = contractor.commitments.invoices.filter(
          (invoice) => invoice.contract_id === contract.id,
        );

        const contractChangeOrders = contractor.commitments.change_orders.filter(
          (changeOrd) => changeOrd.contract_id === contract.id,
        );
        contract.commitment_items.forEach((contrCommitmentItem) => {
          contrCommitmentItem.costs.forEach((contractCost) => {
            let hasAttachments = false;
            contractInvoices.forEach((invoices) => {
              invoices.commitment_items.forEach((invoiceCommitmentItem) => {
                hasAttachments =
                  !!invoiceCommitmentItem.costs.find(
                    (cost) => cost.parent_id === contractCost.id,
                  ) || hasAttachments;
              });
            });
            contractChangeOrders.forEach((changeOrders) => {
              changeOrders.commitment_items.forEach((chaneOrdCommitmentItem) => {
                hasAttachments =
                  !!chaneOrdCommitmentItem.costs.find(
                    (cost) => cost.parent_id === contractCost.id,
                  ) || hasAttachments;
              });
            });
            contractCost.has_attachment = hasAttachments;
            contrCommitmentItem.has_cost_attachment =
              hasAttachments || !!contrCommitmentItem.has_cost_attachment;
          });
        });
      });

      contractor.commitments.change_orders.forEach((changeOrder) => {
        changeOrder.commitment_items.forEach((changeOrdItem) => {
          changeOrdItem.has_cost_attachment = false;
          changeOrdItem.costs.forEach((changeOrdCost) => {
            changeOrdCost.has_attachment = false;
            contractor.commitments.invoices.forEach((invoice) => {
              invoice.commitment_items.forEach((invoiceCommItem) => {
                const index = invoiceCommItem.costs.findIndex(
                  (cost) => cost.parent_id === changeOrdCost.id && changeOrdCost.parent_id === null,
                );
                if (index >= 0) {
                  changeOrder.has_invoice = true;
                  changeOrdCost.has_attachment = true;
                  changeOrdItem.has_cost_attachment = true;
                }
              });
            });
          });
        });
      });
    });
    return data;
  }

  private calculateApproved = (acc, commitment) => {
    if (commitment.approval_status === this.approvedString) {
      commitment.commitment_items.forEach((item) => {
        if (Array.isArray(item.costs)) {
          acc += item.costs.reduce((totalCost, cost) => totalCost + cost.value, 0);
        }
      });
    }
    return acc;
  };

  private calculateTotalsByStatus(data: FilteredCommitmentData) {
    data.contractors.forEach((contractor) => {
      contractor.contracts_approved_sum =
        contractor.commitments?.contracts?.reduce(this.calculateApproved, 0) ?? 0;
      contractor.change_orders_approved_sum =
        contractor.commitments?.change_orders?.reduce(this.calculateApproved, 0) ?? 0;
      contractor.total_committed =
        contractor.contracts_approved_sum + contractor.change_orders_approved_sum;
      if (contractor.total_committed === 0) {
        contractor.complete = 0;
      } else if (contractor.invoices_paid_sum > contractor.total_committed) {
        contractor.complete = 100;
      } else {
        contractor.complete = Math.round(
          (contractor.invoices_paid_sum * 100) / contractor.total_committed,
        );
      }
    });
    return data;
  }

  private filterCommitmentsDataByUser(data: any) {
    const filteredData = {
      contractors: [],
      direct_costs: data.direct_costs,
    };

    data.contractors?.forEach((contractor, index) => {
      const properties = (({ commitments, ...otherProps }) => otherProps)(contractor);
      filteredData.contractors.push(properties);
      filteredData.contractors[index].commitments = {
        contracts: [],
        change_orders: [],
        invoices: [],
      };
      contractor.commitments.forEach((commitment) => {
        switch (commitment.type) {
          case COMMITMENTS_TYPE.CONTRACTS: {
            filteredData.contractors[index].commitments.contracts.push(commitment);
            break;
          }
          case COMMITMENTS_TYPE.CHANGE_ORDER: {
            filteredData.contractors[index]?.commitments.change_orders.push(commitment);
            break;
          }
          case COMMITMENTS_TYPE.INVOICES: {
            filteredData.contractors[index]?.commitments.invoices.push(commitment);
            break;
          }
        }
      });
    });
    return filteredData;
  }

  refreshData() {
    setTimeout(() => {
      this.updateData.emit(true);
    }, 500);
  }

  formatString(value) {
    const regex = /[^\d.-]/g;
    return typeof value === 'string' ? value.replace(regex, '') : value;
  }

  private addTemporaryContractors(data: any): Promise<any> {
    return new Promise<any>((res) => {
      this.user.isManagerF().then((isManager) => {
        if (isManager) {
          data.contractors.map((contr) => (contr.is_temporary = false));
          data.temporary_contractors.forEach((provider) => {
            // mark all temporary serv. prov. with 'T' in id
            provider.is_temporary = true;
            provider.id = 'T' + provider.id;
            provider.commitments.forEach(
              (comm) =>
                (comm.temporary_service_provider_id = 'T' + comm.temporary_service_provider_id),
            );
            data.contractors.push(provider);
          });
        }
        res(data);
      });
    });
  }

  /**
   * Remove a temporary service provider request
   * @param id
   */
  removeTemporaryServiceProvider(id: any): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.del(`${REST_BASE}${TMP_SERVICE_PROVIDER}/${id}`).then(
        (data) => res(data),
        (err) => rej(this.handleError(err)),
      );
    });
  }

  calculateTotalCommitment(commitments: Commitment[]) {
    let total = 0;
    commitments.map((commitment) => {
      const costs = commitment.commitment_items.flatMap((item) => item.costs);
      total += this.calculateTotal(costs);
    });
    return total;
  }

  // can be called for contract, change orders or invoices
  calculateTotalCostsCommitment(commitments: Commitment[]) {
    let total = 0;
    if (!commitments.length) {
      return total;
    }
    switch (commitments[0].type) {
      case COMMITMENTS_TYPE.INVOICES: {
        commitments.map((commitment) => {
          if (commitment.paid_status) {
            const costs = commitment.commitment_items.flatMap((item) => item.costs);
            total += this.calculateTotal(costs);
          }
        });
        break;
      }
      default: {
        commitments.map((commitment) => {
          if (commitment.approval_status === 'approved') {
            const costs = commitment.commitment_items.flatMap((item) => item.costs);
            total += this.calculateTotal(costs);
          }
        });
        break;
      }
    }

    return total;
  }

  calculateTotal(costs: Cost[]) {
    return costs?.reduce((acc, current) => acc + current.value, 0);
  }

  calculateTotalCostsApproved(commitment: Commitment) {
    if (commitment.approval_status === 'approved') {
      const costs = commitment.commitment_items.flatMap((item) => item.costs);
      return this.calculateTotal(costs);
    }
    return 0;
  }

  // can be called for contract or change orders
  calculateTotalPaidCostsCommitment(commitments: Commitment[]) {
    let total = 0;
    commitments.map((commitment) => {
      if (commitment.approval_status === 'approved') {
        const costs = commitment.commitment_items.flatMap((item) => item.costs);
        total += this.calculateTotalPaidCosts(costs);
      }
    });
    return total;
  }

  calculateTotalPaidCosts(costs: Cost[]) {
    return costs?.reduce((acc, current) => acc + current.paid_sum, 0);
  }

  calculatePercentage(value, total, isPercentageBar = false) {
    const complete = 100;
    const min = 0;
    const percentage = Math.round((value * complete) / total);
    if (isNaN(percentage)) {
      return 0;
    }
    if (!isPercentageBar) {
      return percentage;
    }
    if (percentage < min) {
      return min;
    }
    if (percentage > complete) {
      return complete;
    }
    return percentage;
  }

  private addChangeOrderCostsToContract(data: FilteredCommitmentData) {
    data.contractors.forEach((contractor) => {
      contractor.commitments.contracts.forEach((contract) => {
        contract.change_orders.forEach((changeOrder) => {
          changeOrder.commitment_items.forEach((changeOrderCommitmentItem) => {
            changeOrderCommitmentItem.costs.forEach((cost) => {
              if (cost.parent_id === null) {
                const contractCommitmentItem = contract.commitment_items.find(
                  (commitmentItems) =>
                    commitmentItems.item.id === changeOrderCommitmentItem.item.id,
                );
                if (contractCommitmentItem) {
                  const contractCost = DeepCopyService.deepCopy(cost);
                  contractCost.is_change_order_cost = true;
                  contractCost.total_change_order_costs = contractCost.value;
                  contractCost.value = 0;
                  contractCommitmentItem.costs.push(contractCost);
                } else {
                  const changeOrderCommitmentItemUnassigned =
                    DeepCopyService.deepCopy(changeOrderCommitmentItem);
                  changeOrderCommitmentItemUnassigned.is_change_order_line_item = true;
                  changeOrderCommitmentItemUnassigned.costs =
                    changeOrderCommitmentItemUnassigned.costs.filter(
                      (c) => c.parent_id === null && c.id === cost.id,
                    );
                  changeOrderCommitmentItemUnassigned.costs[0].is_change_order_cost = true;
                  changeOrderCommitmentItemUnassigned.costs[0].total_change_order_costs =
                    changeOrderCommitmentItemUnassigned.costs[0].value;
                  changeOrderCommitmentItemUnassigned.costs[0].value = 0;
                  contract.commitment_items.push(changeOrderCommitmentItemUnassigned);
                }
              }
            });
          });
        });
      });
    });
    return data;
  }
}
