import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pricing-premium-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pricing-premium-card.component.html',
  styleUrls: ['./pricing-premium-card.component.scss', '../pricing-cards.scss'],
})
export class PricingPremiumCardComponent {
  private _price = 99 * 5;
  @Input() get price(): number {
    // divide by 5 to get the price for one property - this is a temporary solution
    // it should be divided by the number of properties in the future
    return this._price ? this._price / 5 : null;
  }

  set price(value: number) {
    if (value) {
      this._price = value;
    }
  }
  @Input() buttonText = 'Free 30 day trial';
  @Input() isYearly: boolean;
  @Output() onButtonClick = new EventEmitter<void>();
  @Input() isLoading = false;
}
