<div class="img-wrapper">
  <span *ngIf="loading && !showDefaultImg" class="icon-refresh animate-spin"></span>

  <span *ngIf="showDefaultImg" class="icon-logo-box-hex"></span>

  <img
    (load)="onLoad()"
    [ngClass]="{ 'hide-onloading': loading || showDefaultImg }"
    [ngStyle]="{ width: imgWidth, height: imgHeight }"
    [src]="src"
  />
</div>
