import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
  IGlTemplate,
  ISettings,
} from './settings.interface';
import { createReducer, on } from '@ngrx/store';
import { settingsActions } from './settings.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import * as uuid from 'uuid';
import { IPermissions } from '../documents/documents.reducer';
import { PERMISSION_LITERALS } from '../../framework/constants/documents.constants';

// TODO[molnarszabi: 2023.08.23]: this should be renamed to spend templates
export const settingsFeatureKey = 'settings';

export const settingsInitialState: ISettings = {
  budgetTemplates: [],
  selectedBudgetTemplateId: null,
  selectedGlTemplateId: null,
  selectedBudgetTagTemplateId: null,
  glTemplates: [],
  budgetTagTemplates: [],
  isLoading: false, // general loading indicator
};

const defaultPermissions: Partial<IPermissions> = {
  [PERMISSION_LITERALS.CAN_EDIT]: 1,
  [PERMISSION_LITERALS.CAN_DELETE]: 1,
};

export const settingsReducer = createReducer(
  settingsInitialState,
  on(settingsActions.setIsLoading, (state, action) => {
    return { ...state, isLoading: action.isLoading };
  }),
  on(settingsActions.setBudgetTemplates, (state, action) => {
    return { ...state, budgetTemplates: action.budgetTemplates, isLoading: false };
  }),
  on(settingsActions.setBudgetTagTemplates, (state, action) => {
    return { ...state, budgetTagTemplates: action.budgetTagTemplates, isLoading: false };
  }),
  on(settingsActions.setGlTemplates, (state, action) => {
    return { ...state, glTemplates: action.glTemplates, isLoading: false };
  }),
  on(settingsActions.setSelectedBudgetTemplate, (state, action) => {
    return { ...state, selectedBudgetTemplateId: action.templateId };
  }),
  on(settingsActions.setSelectedGlTemplate, (state, action) => {
    return { ...state, selectedGlTemplateId: action.templateId };
  }),
  on(settingsActions.setSelectedBudgetTagTemplate, (state, action) => {
    return { ...state, selectedBudgetTagTemplateId: action.templateId };
  }),
  on(settingsActions.addGlTemplate, (state, action) => {
    const newTemplate: IGlTemplate = {
      ...(action.glTemplate as IGlTemplate),
      id: uuid.v4(),
      permissions: { ...defaultPermissions },
    };
    return { ...state, glTemplates: [...state.glTemplates, newTemplate] };
  }),
  on(settingsActions.addBudgetTemplate, (state, action) => {
    const newTemplate: IBudgetTemplate = {
      ...(action.budgetTemplate as IBudgetTemplate),
      id: uuid.v4(),
      permissions: { ...defaultPermissions },
    };
    return { ...state, budgetTemplates: [...state.budgetTemplates, newTemplate] };
  }),
  on(settingsActions.addBudgetTagTemplate, (state, action) => {
    const newTemplate: IBudgetTagTemplate = {
      ...(action.budgetTagTemplate as IBudgetTagTemplate),
      id: uuid.v4(),
      permissions: { ...defaultPermissions },
    };
    return { ...state, budgetTagTemplates: [...state.budgetTagTemplates, newTemplate] };
  }),
  on(settingsActions.addBudgetTemplateItem, (state, action) => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const newTemplateItem: IBudgetTemplateItem = {
      name: action.newItemName,
      id: uuid.v4(),
      g_l_account_label: null,
      permissions: { ...defaultPermissions },
    };
    const budgetTemplates = DeepCopyService.deepCopy(state.budgetTemplates);
    const index = budgetTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    budgetTemplates[index].template_items.push(newTemplateItem);
    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),
  on(settingsActions.setGLAccountLabelFromDropdown, (state, action) => {
    const budgetTemplates = DeepCopyService.deepCopy(state.budgetTemplates);
    const selectedTemplateIndex = budgetTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    const templateItems = budgetTemplates[selectedTemplateIndex].template_items;
    const itemIndex = templateItems.findIndex((item) => item.id === action.budgetTemplateItem.id);
    templateItems[itemIndex].g_l_account_label = action.accountLabel;

    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),
  on(settingsActions.unlinkBudgetTemplateItem, (state, action) => {
    const budgetTemplates = DeepCopyService.deepCopy(state.budgetTemplates);
    const selectedTemplateIndex = budgetTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    const templateItems = budgetTemplates[selectedTemplateIndex].template_items;
    const itemIndex = templateItems.findIndex((item) => item.id === action.budgetTemplateItem.id);
    templateItems[itemIndex].g_l_account_label = null;

    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),
  on(settingsActions.addGlTemplateItem, (state, action) => {
    const glTemplates: IGlTemplate[] = DeepCopyService.deepCopy([...state.glTemplates]);
    const selectedIndex = glTemplates.findIndex(
      (template) => template.id === state.selectedGlTemplateId,
    );

    glTemplates[selectedIndex]?.labels.push({
      id: uuid.v4(),
      name: action.newItemName,
      permissions: { ...defaultPermissions },
    });

    return { ...state, glTemplates };
  }),
  on(settingsActions.addBudgetTagTemplateItem, (state, action) => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedIndex = budgetTagTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTagTemplateId,
    );

    budgetTagTemplates[selectedIndex].tags.push({
      id: uuid.v4(),
      name: action.newItemName,
      permissions: { ...defaultPermissions },
    });

    return { ...state, budgetTagTemplates };
  }),
  on(settingsActions.updateGlTemplateItem, (state, action) => {
    const glTemplates: IGlTemplate[] = DeepCopyService.deepCopy([...state.glTemplates]);
    const selectedTemplate = glTemplates.find(
      (template) => template.id === state.selectedGlTemplateId,
    );

    const itemIndex = selectedTemplate.labels.findIndex((item) => item.id === action.newItem.id);
    selectedTemplate.labels[itemIndex] = action.newItem;

    return { ...state, glTemplates };
  }),
  on(settingsActions.updateBudgetTemplateItem, (state, action) => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );

    const itemIndex = selectedTemplate.template_items.findIndex(
      (item) => item.id === action.newItem.id,
    );
    selectedTemplate.template_items[itemIndex] = action.newItem;

    return { ...state, budgetTemplates };
  }),
  on(settingsActions.updateBudgetTagTemplateItem, (state, action) => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedTemplate = budgetTagTemplates.find(
      (template) => template.id === state.selectedBudgetTagTemplateId,
    );

    const itemIndex = selectedTemplate.tags.findIndex((item) => item.id === action.newItem.id);
    selectedTemplate.tags[itemIndex] = action.newItem;

    return { ...state, budgetTagTemplates };
  }),
  on(settingsActions.removeGlTemplateItem, (state, action) => {
    const glTemplates: IGlTemplate[] = DeepCopyService.deepCopy([...state.glTemplates]);
    const selectedTemplate = glTemplates.find(
      (template) => template.id === state.selectedGlTemplateId,
    );
    selectedTemplate.labels = selectedTemplate.labels.map((item) => {
      if (item.id !== action.itemId) {
        return item;
      }
      return { ...item, is_deleted: true };
    });
    return { ...state, glTemplates };
  }),
  on(settingsActions.removeBudgetTagTemplateItem, (state, action) => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedTemplate = budgetTagTemplates.find(
      (template) => template.id === state.selectedBudgetTagTemplateId,
    );
    selectedTemplate.tags = selectedTemplate.tags.map((item) => {
      if (item.id !== action.itemId) {
        return item;
      }
      return { ...item, is_deleted: true };
    });
    return { ...state, budgetTagTemplates };
  }),
  on(settingsActions.updateBudgetTemplate, (state, action) => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplateIndex = budgetTemplates.findIndex(
      (template) => template.id === action.template.id,
    );
    budgetTemplates[selectedTemplateIndex] = { ...action.template };
    return { ...state, budgetTemplates };
  }),
  on(settingsActions.updateGlTemplate, (state, action) => {
    const glTemplates: IGlTemplate[] = DeepCopyService.deepCopy([...state.glTemplates]);
    const selectedTemplateIndex = glTemplates.findIndex(
      (template) => template.id === action.template.id,
    );
    glTemplates[selectedTemplateIndex] = { ...action.template };
    return { ...state, glTemplates };
  }),
  on(settingsActions.updateBudgetTagTemplate, (state, action) => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedTemplateIndex = budgetTagTemplates.findIndex(
      (template) => template.id === action.template.id,
    );
    budgetTagTemplates[selectedTemplateIndex] = { ...action.template };
    return { ...state, budgetTagTemplates };
  }),
  on(settingsActions.removeBudgetTemplateItem, (state, action) => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    selectedTemplate.template_items = selectedTemplate.template_items.map((item) => {
      if (item.id !== action.itemId) {
        return item;
      }
      return { ...item, is_deleted: true };
    });
    return { ...state, budgetTemplates };
  }),
  on(settingsActions.deleteGlTemplate, (state, action) => {
    const glTemplates: IGlTemplate[] = DeepCopyService.deepCopy([...state.glTemplates]);
    const selectedTemplate = glTemplates.find((template) => template.id === action.templateId);
    selectedTemplate.is_deleted = true;
    return { ...state, glTemplates, selectedGlTemplateId: null };
  }),
  on(settingsActions.deleteBudgetTemplate, (state, action) => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find((template) => template.id === action.templateId);
    selectedTemplate.is_deleted = true;
    return { ...state, budgetTemplates, selectedBudgetTemplateId: null };
  }),
  on(settingsActions.deleteBudgetTagTemplate, (state, action) => {
    const tagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedTemplate = tagTemplates.find((template) => template.id === action.templateId);
    selectedTemplate.is_deleted = true;
    return { ...state, budgetTagTemplates: tagTemplates, selectedBudgetTagTemplateId: null };
  }),
  on(settingsActions.clearAll, (_) => {
    return {
      ...settingsInitialState,
    };
  }),
);
