import { Component } from '@angular/core';
import { OptionsListGeneralComponent } from '../options-list-general/options-list-general.component';
import { NgFor, NgClass } from '@angular/common';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-options-list-simple',
  templateUrl: './options-list-simple.component.html',
  styleUrls: ['./options-list-simple.component.scss'],
  standalone: true,
  imports: [CdkConnectedOverlay, NgFor, NgClass],
})
export class OptionsListSimpleComponent extends OptionsListGeneralComponent {
  constructor() {
    super();
  }
}
