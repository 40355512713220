import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';

/**
 * This component has two states: editable and non-editable reflected in _showInput variable.
 * It is used to switch between an input and a simple text element on command
 */
@Component({
  selector: 'app-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf, MatInput, FormsModule],
})
export class EditableInputComponent {
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  @Input() set text(value) {
    this.inputModel = value;
  }
  @Output() textChange = new EventEmitter<string>();
  private _showInput = false;
  get showInput() {
    return this._showInput;
  }
  @Input() set showInput(value) {
    this._showInput = value;
    if (value) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 100);
    }
  }
  inputModel = '';

  updateTemplateName(newTemplateName) {
    console.log(newTemplateName.target.value);
    this.textChange.emit(newTemplateName.target.value);
  }
}
