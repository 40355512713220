<ng-container *ngIf="showHelp">
  <div class="p-5 bg-shade_7 h-full">
    <span class="flex pb-5 text-sm">
      <span *ngIf="type === 'gl_accounts'" class="flex-1 text-shade_1 font-bold">About accounts</span>
      <span *ngIf="type === 'budget-tag'" class="flex-1 text-shade_1 font-bold">About budget tags</span>
      <span *ngIf="type === 'budget'" class="flex-1 text-shade_1 font-bold">About budget templates</span>
      <span *ngIf="type === 'account-relations'" class="flex-1 text-shade_1 font-bold">About account relations</span>
      <span class="text-shade_2 font-medium text-[13px] pointer-cursor" (click)="hideGuideline(false)">Close help</span>
    </span>
    <div class="text-shade_1 text-sm leading-6 font-normal">
      <ng-container *ngIf="type === 'gl_accounts'">
        Here you can input lines items that represent the divisions, departments, or GL accounts within your company.
        <br />
        <br />
        This area is <span class="underline">optional</span>. However, if you are looking to track project spend detail
        at the accounting level, you will want to add your accounts here.
        <br />
        <br />
        This area is especially important if your company is requesting to integrate the accounting system with
        Skillhop.
        <br />
        <br />
        If you have more questions related to this page or around integrations, please reach out to us via email at
        <a href="mailto:contact@skillhop.com" class="email">contact&#64;skillhop.com</a>
      </ng-container>
      <ng-container *ngIf="type === 'budget-tag'">
        Here you can input lines items that represent the divisions, departments, or GL accounts within your company.
        <br />
        <br />

        This area is optional. However, if you are looking to track project spend detail at the accounting level, you
        will want to add your accounts here.
        <br />
        <br />
        This area is especially important if your company is requesting to integrate the accounting system with
        Skillhop.
        <br />
        <br />
        If you have more questions related to this page or around integrations, please reach out to us via email at
        <a href="mailto:contact@skillhop.com" class="email">contact&#64;skillhop.com</a>
      </ng-container>
      <ng-container *ngIf="type === 'budget'">
        Here you can input lines items that represent the divisions, departments, or GL accounts within your company.
        <br />
        <br />
        This area is <span class="underline">optional</span>. However, if you are looking to track project spend detail
        at the accounting level, you will want to add your accounts here.
        <br />
        <br />
        This area is especially important if your company is requesting to integrate the accounting system with
        Skillhop.
        <br />
        <br />
        If you have more questions related to this page or around integrations, please reach out to us via email at
        <a href="mailto:contact@skillhop.com" class="email">contact&#64;skillhop.com</a>
      </ng-container>
      <ng-container *ngIf="type === 'account-relations'">
        Here you can input lines items that represent the divisions, departments, or GL accounts within your company.

        <br />
        <br />

        This area is <span class="underline">optional</span>. However, if you are looking to track project spend detail
        at the accounting level, you will want to add your accounts here.
        <br />
        <br />
        This area is especially important if your company is requesting to integrate the accounting system with
        Skillhop.
        <br />
        <br />
        If you have more questions related to this page or around integrations, please reach out to us via email at
        <a href="mailto:contact@skillhop.com" class="email">contact&#64;skillhop.com</a>
      </ng-container>
    </div>
  </div>
</ng-container>
