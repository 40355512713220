import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-simple-button',
  standalone: true,
  imports: [NgClass],
  styleUrl: './simple-button.component.scss',
  template: `
    <button [disabled]="disabled" [ngClass]="customClass" class="simple-medium-button">
      <ng-content> </ng-content>
    </button>
  `,
})
export class SimpleButtonComponent {
  @Input() wrapperClass: { [p: string]: any } = {};
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() disabled = false;

  get customClass() {
    return {
      medium: this.size === 'medium',
      large: this.size === 'large',
      ...this.wrapperClass,
    };
  }
}
