<div class="custom-overlay-wrapper">
  <app-messaging-tag-user-overlay
    *ngIf="selectedView === VIEWS.MESSAGING_TAG_USERS"
    [data]="data"
  ></app-messaging-tag-user-overlay>
  <app-messaging-tag-project-overlay
    *ngIf="selectedView === VIEWS.MESSAGING_TAG_PROJECTS"
    [data]="data"
  ></app-messaging-tag-project-overlay>
  <app-options-list-styled
    *ngIf="selectedView === VIEWS.STYLED_OPTIONS_LIST"
    (selectedOption)="registerSelectedOption($event)"
    [listItems]="data?.listItems ?? []"
    [disabledList]="data?.disabledList ?? []"
  ></app-options-list-styled>
  <app-img-preview *ngIf="selectedView === VIEWS.CROPPER" [data]="data"></app-img-preview>
</div>
