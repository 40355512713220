<span class="subtitle">
  <div class="text-color_primary uppercase text-bold text-sm">
    @switch (entryType) {
      @case ('GeneralInfo') {
        <span> General Information </span>
      }
      @case ('General') {
        <div class="text-color_primary uppercase text-bold text-sm">
          {{ text }} {{ index !== null ? index + 1 : '' }}
        </div>
      }
      @case ('Invoice') {
        <span> Invoice Breakdown </span>
      }
    }
  </div>
  <span
    [ngClass]="{ hidden: entryType !== 'General' || index === null, 'p-disabled': disableRemovedEntry }"
    (click)="removeEntry()"
    class="flex text-color_red_negative text-2xl pr-0.5 icon-trash-x cursor-pointer"
  ></span>
</span>
