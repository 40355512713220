import { OPTIONS } from './options-list.constants';
import { NOTIFICATION_TYPE } from './notification.constants';

export const DRIVE_EXTERNAL_VIEW_ID = -1;

export const DRIVE_VIEW_OR_DOWNLOAD_EXTERNALLY_SHARED_FILE = 'webapp/documents';

export const sortFuncDrive = (a, b) => {
  const aNumber = Number.parseInt(a.name, 10);
  const bNumber = Number.parseInt(b.name, 10);
  if (!isNaN(aNumber) && !isNaN(bNumber)) {
    return aNumber - bNumber;
  }
  return a.name.localeCompare(b.name);
};

export const emptyAreaOptions: Array<IStyledOption> = [
  // needs to be added options accordingly
  {
    value: OPTIONS.NEW_FOLDER,
    class: '',
    icon: 'icon-add-round',
  },
  {
    value: OPTIONS.UPLOAD_FILE,
    class: '',
    icon: 'icon-folded-file-upload',
  },
  {
    value: OPTIONS.NEW_SPREADSHEET,
    class: '',
    icon: 'icon-folded-spreadsheet',
  },
  {
    value: OPTIONS.NEW_WORD,
    class: '',
    icon: 'icon-folded-word-doc',
  },
];

export enum PERMISSION_LITERALS {
  CAN_DOWNLOAD = 'can_download',
  CAN_COPY = 'can_copy',
  CAN_MOVE = 'can_move',
  CAN_DUPLICATE = 'can_duplicate',
  CAN_RENAME = 'can_rename',
  CAN_SHARE = 'can_share',
  CAN_EXTERNALLY_SHARE = 'can_externally_share',
  CAN_DELETE = 'can_delete',
  CAN_CANCEL_SHARE = 'can_cancel_share',
  CAN_MODIFY_CONTENT = 'can_modify_content',
  CAN_EDIT = 'can_edit',
  CAN_CREATE_PROJECT = 'can_create_project',
  LIMIT_TO_TEMPLATES = 'limit_to_templates',
}

/**
 * used to convert a permission string that is 0 or 1 to an option
 */
export const permissionLiteralToOptions: { permission: string; value: OPTIONS }[] = [
  {
    permission: PERMISSION_LITERALS.CAN_DOWNLOAD,
    value: OPTIONS.DOWNLOAD,
  },
  {
    permission: PERMISSION_LITERALS.CAN_MODIFY_CONTENT,
    value: OPTIONS.UPLOAD_FILE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_COPY,
    value: OPTIONS.COPY,
  },
  {
    permission: PERMISSION_LITERALS.CAN_MOVE,
    value: OPTIONS.MOVE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_DUPLICATE,
    value: OPTIONS.DUPLICATE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_RENAME,
    value: OPTIONS.RENAME,
  },
  {
    permission: PERMISSION_LITERALS.CAN_SHARE,
    value: OPTIONS.SHARE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_EXTERNALLY_SHARE,
    value: OPTIONS.EXTERNAL_SHARE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_EDIT,
    value: OPTIONS.EDIT,
  },
  {
    permission: PERMISSION_LITERALS.CAN_DELETE,
    value: OPTIONS.DELETE,
  },
  {
    permission: PERMISSION_LITERALS.CAN_CANCEL_SHARE,
    value: OPTIONS.CANCEL_SHARING,
  },
  {
    value: OPTIONS.NEW_SPREADSHEET,
    permission: PERMISSION_LITERALS.CAN_MODIFY_CONTENT,
  },
  {
    value: OPTIONS.NEW_WORD,
    permission: PERMISSION_LITERALS.CAN_MODIFY_CONTENT,
  },
];

export const allItemOptions: Array<IStyledOption> = [
  {
    value: OPTIONS.DOWNLOAD,
    class: '',
    icon: 'icon-folded-file-download',
  },
  {
    value: OPTIONS.EDIT,
    class: '',
    icon: 'icon-edit',
  },
  {
    value: OPTIONS.COPY,
    class: '',
    icon: 'icon-copy-block',
  },
  {
    value: OPTIONS.MOVE,
    class: '',
    icon: 'icon-move-block',
  },
  {
    value: OPTIONS.DUPLICATE,
    class: '',
    icon: 'icon-duplicate',
  },
  {
    value: OPTIONS.RENAME,
    class: '',
    icon: 'icon-pencil-block',
  },
  {
    value: OPTIONS.SHARE,
    class: '',
    icon: 'icon-share-filled-block',
  },
  {
    value: OPTIONS.EXTERNAL_SHARE,
    class: '',
    icon: 'icon-share-unfilled-block',
  },
  {
    value: OPTIONS.CANCEL_SHARING,
    class: '',
    icon: 'icon-remove-circle-x',
  },
  {
    value: OPTIONS.DELETE,
    class: '',
    icon: 'icon-file-x text-color_red_negative',
  },
];

export interface DriveNotification {
  type: NOTIFICATION_TYPE;
  message: string;
}

export const EXCELL_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const WORD_MIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export interface IStyledOption {
  class: string;
  value: OPTIONS;
  icon: string;
}

export enum SHARE_PERMISSIONS {
  READ_ONLY = 'view',
  READ_UPLOAD = 'edit',
}

export enum DRIVE_ITEM_TYPES {
  FILE = 'file',
  FOLDER = 'folder',
  DOCUMENTS = 'documents',
}

export enum DRIVE_VIEWS {
  DEFAULT,
  WORD,
  EXCEL,
}

export interface UserDriveFile {
  id: number;
  user_drive_folder_id: number;
  name: string;
  size: string;
  type: string;
  s3_path: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  service_provider_user_id: number;
  owner_id: number;
  extra_data: any;
  is_uploaded: boolean;
}
