import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedTask } from '../../../store/tasks/tasks.selectors';
import { IChecklistItem, ITask } from '../../../store/tasks/tasks.interfaces';
import {
  createOrUpdateMessage,
  messagesActions,
  setMessagingView,
  setSelectedGroupId,
} from '../../../store/messages/messages.actions';
import { MMMMDOYYYY, NO_TIMEZONE, DateCustomPipe } from '../../../pipes/framework/date-custom.pipe';
import { getMessagingView } from '../../../store/messages/messages.selectors';
import { IMessagingMember, MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrentUserService } from '../../../services/current-user.service';
import { MessagingEffects } from '../../../store/messages/messages.effects';
import { DriveService } from '../../../services/drive.service';
import { TasksService } from '../../../services/tasks.service';
import { tasksActions } from '../../../store/tasks/tasks.actions';
import { CUSTOM_TASK_FIELD_TYPES } from 'src/app/store/tasks/tasks.constants';
import { MessagesStateService } from '../../../services/messages-state.service';
import { MessagesFooterComponent } from '../../messages/messages-footer/messages-footer.component';
import { DiscussionThreadViewComponent } from '../../messages/discussion-thread-view/discussion-thread-view.component';
import { DiscussionsViewComponent } from '../../messages/discussions-view/discussions-view.component';
import { ArrowButtonBoxComponent } from '../../dropdown-button-box/arrow-button-box.component';
import { FileThumbnailComponent } from './file-thumbnail/file-thumbnail.component';
import { RouterLink } from '@angular/router';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelContent,
} from '@angular/material/expansion';
import { TaskDetailsHeaderComponent } from '../../tasks/task-details-header/task-details-header.component';
import { NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TaskDetailsHeaderComponent,
    NgClass,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    RouterLink,
    MatExpansionPanelContent,
    NgFor,
    FileThumbnailComponent,
    NgSwitch,
    NgSwitchCase,
    ArrowButtonBoxComponent,
    DiscussionsViewComponent,
    DiscussionThreadViewComponent,
    MessagesFooterComponent,
    AsyncPipe,
    DateCustomPipe,
  ],
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
  selectedTask$ = this.store.select(getSelectedTask);
  selectedMessagingView$ = this.store.select(getMessagingView);
  DATE_FORMAT = MMMMDOYYYY;
  isSummaryCollapsed = true;
  isMoreDetailsOpen = false;
  MESSAGING_VIEWS = MESSAGING_VIEWS;
  isDestroyed$ = new Subject();
  CUSTOM_TASK_FIELD_TYPES = CUSTOM_TASK_FIELD_TYPES;
  protected readonly NO_TIMEZONE = NO_TIMEZONE;
  private taskHasMessaging: boolean;

  constructor(
    private store: Store,
    private footerService: MessagingFooterService,
    private interactionBarService: InteractionBarStateService,
    public userService: CurrentUserService,
    private messagingEffect: MessagingEffects,
    private driveService: DriveService,
    private taskService: TasksService,
    private messageService: MessagesStateService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_VIEW }));
    this.handleSaveAction();
    this.handleMessageLoading();
    this.footerService.discard.pipe(takeUntil(this.isDestroyed$)).subscribe((_) => {
      if (this.taskService.isTaskDetailsOpenedFromMessages) {
        this.taskService.goBackToMessagesFromTaskDetails();
        return;
      }
      this.closeInteractionBar();
    });
  }

  getCheckedItems(items: IChecklistItem[]) {
    return items.reduce((acc, curr) => (curr.value ? acc + 1 : acc), 0);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
  }

  toggleCheckListItem(item: IChecklistItem) {
    this.store.dispatch(tasksActions.checklistItemToggledOnDetails({ checklistItem: item }));
  }

  closeInteractionBar() {
    this.interactionBarService.close();
  }

  private handleMessageLoading() {
    this.selectedTask$.pipe(takeUntil(this.isDestroyed$)).subscribe((task) => {
      if (!this.taskHasMessaging) {
        // init process which is done when a group is created or the task is loaded first time
        this.setupMessaging(task);
      }
    });
  }

  private setupMessaging(task: ITask) {
    if (task && task.messaging) {
      this.taskHasMessaging = true;
      this.store.dispatch(
        messagesActions.setSelectedGroupId({ groupId: task.messaging.group?.id }),
      );
      const messagesAndDrafts = {
        messages: task.messaging.messages,
        drafts: task.messaging.drafts,
      };
      this.store.dispatch(messagesActions.setGroups({ groups: [task.messaging.group] }));
      this.store.dispatch(
        messagesActions.setMessages({ groupMessagesAndDrafts: messagesAndDrafts }),
      );
      this.messagingEffect.loadProfilePictures(messagesAndDrafts);
      this.store.dispatch(
        messagesActions.setDiscussionMembers({ members: task.messaging.group.members }),
      );
    } else {
      this.taskHasMessaging = false;
      this.store.dispatch(setSelectedGroupId({ groupId: null }));
    }

    // in view task details if messaging does not exist, add members to be able to mention members
    if (
      task &&
      !task.messaging &&
      (task.spectator_members.length || task.assigned_members?.length)
    ) {
      const members: IMessagingMember[] = [];
      task.spectator_members.forEach((member) => {
        console.warn(String(member.user_id));
        members.push({
          company_name: member.company_name,
          user_id: String(member.user_id),
          name: member.name,
        });
      });
      task.assigned_members.forEach((member) => {
        console.warn(String(member.user_id));
        members.push({
          company_name: member.company_name,
          user_id: String(member.user_id),
          name: member.name,
        });
      });
      const filteredMembers = members.filter(
        (member) => String(member.user_id) !== String(this.userService.data.id),
      );
      this.store.dispatch(messagesActions.setDiscussionMembers({ members: filteredMembers }));
    }
  }

  private handleSaveAction() {
    this.footerService.save
      .pipe(takeUntil(this.isDestroyed$), withLatestFrom(this.selectedMessagingView$))
      .subscribe(([_, view]) => {
        this.isMoreDetailsOpen = false;
        switch (view) {
          case MESSAGING_VIEWS.DISCUSSION_VIEW: {
            this.store.dispatch(tasksActions.messageSentOnDetails());
            if (this.taskHasMessaging) {
              this.store.dispatch(createOrUpdateMessage({}));
            } else {
              this.store.dispatch(messagesActions.createGroupTasks());
            }
            break;
          }

          case MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW: {
            this.store.dispatch(createOrUpdateMessage({}));
            break;
          }
          default:
            break;
        }
      });
  }
}
