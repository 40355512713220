import { Injectable } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TableSortingService {
  constructor() {}

  sort<T>(tableData: T[], key: string, order: 1 | -1): T[] {
    let sortFunc: (a, b) => number = this.sortFunc(tableData, key, order);
    return tableData?.toSorted(sortFunc);
  }

  sortFunc<T>(tableData: T[], key: string, order: 1 | -1): (a: any, b: any) => number {
    let sortFunc: (a, b) => number;
    if (key.startsWith('date')) {
      sortFunc = (a, b) => {
        if (moment(_.get(a, key)).isBefore(moment(_.get(b, key)))) {
          return -1 * order;
        }
        return +1 * order;
      };
    } else if (typeof _.get(tableData, '[0].' + key) === 'string') {
      sortFunc = (a, b) => {
        const aValue = _.get(a, key);
        const bValue = _.get(b, key);
        if (!aValue) {
          return -1;
        }
        if (!bValue) {
          return 1;
        }

        // converting to string because we can get errors if data is malformed
        // and some values are strings but some are not
        return String(aValue)?.localeCompare(bValue) * order;
      };
    } else {
      // defaulting to number, but comparing with '<' may work for other types as well
      sortFunc = (a, b) => {
        if (_.get(a, key) < _.get(b, key)) {
          return -1 * order;
        }
        return +1 * order;
      };
    }
    return sortFunc;
  }
}
