<div class="sheet-wrapper" style="width: 100%; height: 100%" #widget>
  <ejs-spreadsheet
    #spreadsheet
    (created)="sheetCreated()"
    (beforeSave)="sheetBeforeSave($event)"
    (saveComplete)="sheetSaveComplete($event)"
    [saveUrl]="SHEET_SAVE_URL"
    [openUrl]="SHEET_OPEN_URL"
    [allowOpen]="true"
    [allowSave]="true"
  ></ejs-spreadsheet>
</div>
