import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { first, tap } from 'rxjs/operators';
import { AppState } from '../app-state';
import { areDocsLoaded } from './documents.selectors';
import { load, loadExternalDrive } from './documents.actions';
import {
  clearCache,
  loadProjectDocuments,
  setRetrieveDisabled,
} from '../projectDocuments/projectDocuments.actions';
import { DriveComponent } from '../../pages/webapp/drive/drive.component';

@Injectable()
export class DocumentsResolver {
  constructor(private store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.pipe(
      select(areDocsLoaded),
      tap((_) => {
        this.store.dispatch(clearCache());
        const id = this.getId(state.url);
        const uuid = route.params.uuid;
        if (uuid) {
          this.store.dispatch(loadExternalDrive({ uuid }));
          return;
        }
        state.url.includes('addID') // will be needed on page reload
          ? this.store.dispatch(setRetrieveDisabled({ retrieveDisabled: true }))
          : this.store.dispatch(setRetrieveDisabled({ retrieveDisabled: false }));
        if (state.url.includes('/webapp/projects/add') && !id) {
          return;
        }
        if (id) {
          DriveComponent.isProjectView.next(true);
          this.store.dispatch(loadProjectDocuments({ projectId: Number.parseInt(id, 10) }));
        } else {
          DriveComponent.isProjectView.next(false);
          // todo: consider re-adding the if statement
          // currently it is added because drive does not refresh if you navigate here from external drive
          this.store.dispatch(load());
        }
      }),
      first(),
    );
  }

  getId(url) {
    if (url.includes('editID')) {
      return url.match(/editID=(\d+)/)?.[1];
    }
    if (url.includes('addID')) {
      return url.match(/addID=(\d+)/)?.[1];
    }
    return url.match(/projects\/(\d+)/)?.[1];
  }
}
