import { Component, Input, OnInit } from '@angular/core';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { CustomOverlayService } from '../../services/custom-overlay.service';

/**
 * Component that opens the cropper when a file is received in the data input
 * Can be opened through the custom overlay service
 * Emits the cropped image as a blob through the custom overlay service
 */
@Component({
  selector: 'app-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss'],
  standalone: true,
  imports: [ImageCropperModule],
})
export class ImgPreviewComponent implements OnInit {
  IMG_RESIZE_TO = 300;
  croppedImage: any = '';

  @Input() data: File;

  constructor(private overlayService: CustomOverlayService) {}

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
    console.warn('Load failed');
  }

  saveImage() {
    this.overlayService.emitOutputData(this.croppedImage);
  }

  cancel() {
    this.overlayService.closeOverlay();
    this.overlayService.emitOutputData(null);
  }
}
