import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { documentActionTypes } from './documents.actions';
import { of, throwError } from 'rxjs';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { docsFeatureSelector } from './documents.selectors';
import { DriveApiService } from '../../services/drive-api.service';
import { DRIVE_EXTERNAL_VIEW_ID } from '../../framework/constants/documents.constants';
import { DocumentItem } from './documents.reducer';
import { Router } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class DocumentsEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private driveApiService: DriveApiService,
    private notif: NotificationsService,
    private router: Router,
  ) {}

  load = createEffect(() => {
    return this.actions.pipe(
      ofType(documentActionTypes.load),
      exhaustMap((_) => {
        return this.driveApiService.getDriveAsync().pipe(
          map((data) => {
            return documentActionTypes.updateAll({
              data: data.drive,
            });
          }),
          catchError(() => of(documentActionTypes.updateAll({ data: [] }))),
        );
      }),
    );
  });

  driveFolderClicked = createEffect(() => {
    return this.actions.pipe(
      ofType(documentActionTypes.driveFolderClicked, documentActionTypes.back),
      switchMap((action) => {
        if (action.type === documentActionTypes.back.type) {
          return of(documentActionTypes.cancel());
        }
        return this.driveApiService.getDriveFolder(action.id).pipe(
          map((folder) => {
            return documentActionTypes.loadDriveFolder({
              folder,
            });
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? err?.message);
            return of(documentActionTypes.cancel());
          }),
        );
      }),
    );
  });

  driveExternalFolderClicked = createEffect(() => {
    return this.actions.pipe(
      ofType(documentActionTypes.driveExternalFolderClicked, documentActionTypes.back),
      switchMap((action) => {
        if (action.type === documentActionTypes.back.type) {
          return of(documentActionTypes.cancel());
        }
        return this.driveApiService.getExternalDriveFolder(action.id, action.uuid).pipe(
          map((folder) => {
            return documentActionTypes.loadDriveFolder({
              folder,
            });
          }),
          catchError((err) => {
            this.notif.showError(err?.error?.message ?? err?.message);
            return of(documentActionTypes.cancel());
          }),
        );
      }),
    );
  });

  loadExternalDrive = createEffect(() => {
    return this.actions.pipe(
      ofType(documentActionTypes.loadExternalDrive),
      exhaustMap((action) => {
        return this.getExternalDrive(action.uuid).pipe(
          catchError((err) => {
            this.router.navigate(['not-found']);
            return throwError(err);
          }),
        );
      }),
    );
  });

  back = createEffect(() =>
    this.actions.pipe(
      ofType(documentActionTypes.back),
      switchMap((action) => {
        return of(documentActionTypes.backOneFolder());
      }),
      catchError(() => of(documentActionTypes.cancel())),
    ),
  );

  reload = createEffect(() =>
    this.actions.pipe(
      ofType(documentActionTypes.reload),
      withLatestFrom(this.store.select(docsFeatureSelector)),
      exhaustMap(([_, store]) => {
        if (!store.selectedFolderId) {
          return of(documentActionTypes.cancel());
        }
        return this.driveApiService.getDriveFolder(store.selectedFolderId).pipe(
          map((folder) => {
            return documentActionTypes.updateDriveFolder({ folder });
          }),
          catchError((error) => {
            console.warn(error);
            return of(documentActionTypes.updateAll({ data: [] }));
          }),
        );
      }),
    ),
  );

  reloadDriveFolder = createEffect(() =>
    this.actions.pipe(
      ofType(documentActionTypes.reloadDriveFolder),
      exhaustMap((action) => {
        return this.driveApiService.getDriveFolder(action.id).pipe(
          map((folder) => {
            return documentActionTypes.updateDriveFolder({ folder });
          }),
          catchError((error) => {
            console.warn(error);
            return of(documentActionTypes.updateAll({ data: [] }));
          }),
        );
      }),
    ),
  );

  reloadExternalDrive = createEffect(() =>
    this.actions.pipe(
      ofType(documentActionTypes.reloadExternalDrive),
      withLatestFrom(this.store.select(docsFeatureSelector)),
      exhaustMap(([action, store]) => {
        if (store.selectedFolderId >= 0) {
          return this.driveApiService
            .getExternalDriveFolder(store.selectedFolderId, action.uuid)
            .pipe(
              map((folder: DocumentItem) => {
                return documentActionTypes.updateDriveFolder({ folder });
              }),
              catchError((err) => {
                this.router.navigate(['not-found']);
                return throwError(err);
              }),
            );
        }
        // if no folder id or main folder, then reload the main drive
        return this.getExternalDrive(action.uuid).pipe(
          catchError((err) => {
            this.router.navigate(['not-found']);
            return throwError(err);
          }),
        );
      }),
    ),
  );

  getExternalFolder(data): DocumentItem {
    return {
      id: DRIVE_EXTERNAL_VIEW_ID,
      parent_id: null,
      name: data.name,
      size: data.size,
      files: [...data.files],
      children: [...data.children],
      isLocalFolder: true,
      is_drive: 1,
      shared_with: [],
      owner: null,
      shared_by: data.shared_by,
      files_count: 0,
      folders_count: 0,
    };
  }

  getExternalDrive(uuid: string) {
    return this.driveApiService.getExternalDrive(uuid).pipe(
      map((data: DocumentItem) => {
        data.id = DRIVE_EXTERNAL_VIEW_ID;
        console.log(data);
        const defaultDrive: DocumentItem = this.getExternalFolder(data);
        return documentActionTypes.updateAll({
          data: defaultDrive,
        });
      }),
      catchError((err) => throwError(err)),
    );
  }
}
