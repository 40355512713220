<div class="general-tab-container">
  <form #generalFormModel="ngForm">
    <div class="tab-content-wrapper">
      <div class="general-info">
        <ng-scrollbar appearance="standard" class="std-scrollbar">
          <div class="form-container">
            <div>
              <app-dropdown
                (modelChange)="statusChanged($event)"
                [(model)]="model.status"
                [options]="projectStatuses"
                label="Project Status"
                name="status"
                optionLabel="name"
                optionValue="id"
                required
              >
                <ng-template #optionContent #selectedContent let-status>
                  <div class="flex items-center gap-2">
                    <span
                      *ngIf="status?.name"
                      [ngClass]="{
                        'bg-accept':
                          status?.key == PROJECT_STATUS_KEY.AWARDED ||
                          status?.key == PROJECT_STATUS_KEY.IN_PROGRESS ||
                          status?.key == PROJECT_STATUS_KEY.COMPLETED,
                        'bg-accent': status?.key == PROJECT_STATUS_KEY.BIDDING,
                        'bg-cancel':
                          status?.key == PROJECT_STATUS_KEY.CANCELED || status?.key == PROJECT_STATUS_KEY.DELETED,
                        'bg-progress': status?.key === PROJECT_STATUS_KEY.IN_PROGRESS,
                        'bg-completed': status?.key === PROJECT_STATUS_KEY.COMPLETED
                      }"
                      class="circle"
                    ></span>
                    <span class="capitalize">{{ status?.name }}</span>
                  </div>
                </ng-template>
              </app-dropdown>
            </div>

            <app-floating-input
              [(model)]="model.title"
              label="Title"
              name="title"
              placeholder="Title"
              required
            ></app-floating-input>

            <app-input-calendar
              #added_at_calendar
              (dateChanged)="updateStartDate($event)"
              [date]="model.start_date"
              [isDisabled]="false"
              [maxDate]="maxDate"
              [minStartDate]="null"
              [required]="true"
              name="start_date"
              placeholder="Project Start Date"
            ></app-input-calendar>

            <app-dropdown
              (modelChange)="propertiesChanged($event)"
              [(model)]="model.property_id"
              [options]="properties"
              label="Property Name"
              name="property_id"
              optionLabel="name"
              optionValue="id"
              required
            >
            </app-dropdown>

            <app-tag-input
              [(model)]="model.tags"
              [toolTip]="TAGS_TOOLTIP"
              label="Project Tags"
              name="tags"
            ></app-tag-input>
          </div>
        </ng-scrollbar>
      </div>
    </div>
  </form>
</div>
