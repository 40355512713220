import { CustomTaskFieldGroup } from './tasks.constants';
import { IMessage, IMessagingGroup } from '../messages/messages.interfaces';
import {
  PAGE_TASKS_CALENDAR,
  PAGE_TASKS_KANBAN,
  PAGE_TASKS_TABLE,
} from '../../framework/constants/view.constants';

export interface ITask {
  id: number;
  title: string;
  type: ITaskType;
  summary: string;
  start_date: string;
  end_date: string;
  reminder: ITaskReminder;
  status: ITaskStatus;
  project_id: number;
  project: {
    id: number;
    title: string;
  };
  assigned_members: ITaskMember[];
  responsibility_members: ITaskMember[]; // is this necessary?
  spectator_members: ITaskMember[];
  author: ITaskMember;
  files: IFile[];
  checklist: IChecklist;
  messages_count?: number;
  messaging?: {
    group: IMessagingGroup;
    messages: IMessage[];
    drafts: IMessage[];
  };
  custom_fields: CustomTaskFieldGroup[];
  requires_response: boolean;
  visualization?: TaskVisualizationFilter; // added by frontend
  is_spectating: boolean;
}

export interface IFile extends File {
  id: number;
  name: string;
  size: number;
  type: string;
  deleted?: boolean;
}

export interface ITaskSideBar extends ITask {
  currentPage: number;
  updateTaskId: number;
  isSideBarLoading: boolean;
  deletedFiles: IFile[];
}

export interface IChecklist {
  id?: number;
  name: string;
  deleted?: boolean;
  items: IChecklistItem[];
}

export interface IChecklistItem {
  name: string;
  value?: boolean;
}

export interface ITaskStatusType {
  value: TASK_STATUS_VALUE;
  name: TASK_STATUS;
}

export interface ITaskMember {
  user_id: number;
  name: string;
  deleted?: boolean;
  company_name: string;
  type: 'contractor' | 'manager';
}

export enum TASK_KANBAN_COLUMN_TYPES {
  DUE_THIS_WEEK = 'Due This Week',
  UPCOMING = 'Upcoming',
  SPECTATING = 'Spectating',
}

export enum TASK_TYPE {
  DAILY = 'daily',
  CUSTOM = 'custom',
  SPECTATING = 'spectating', // not real type
}

export enum TASK_STATUS {
  PENDING = 'pending',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
}

export type taskStatus = 'pending' | 'in_progress' | 'completed';
export enum TASK_STATUS_VALUE {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export const taskStatuses: ITaskStatusType[] = [
  { value: TASK_STATUS_VALUE.PENDING, name: TASK_STATUS.PENDING },
  { value: TASK_STATUS_VALUE.IN_PROGRESS, name: TASK_STATUS.IN_PROGRESS },
  { value: TASK_STATUS_VALUE.COMPLETED, name: TASK_STATUS.COMPLETED },
];

export type ITaskType = TASK_TYPE.DAILY | TASK_TYPE.CUSTOM | TASK_TYPE.SPECTATING;
export type ITaskReminder = 'never' | 'to_extend';
export type ITaskStatus =
  | TASK_STATUS_VALUE.PENDING
  | TASK_STATUS_VALUE.IN_PROGRESS
  | TASK_STATUS_VALUE.COMPLETED;

export interface ITaskFilters {
  visualization: TaskVisualizationFilter;
  requires_response: 0 | 1;
  project_filter: number[];
  type_filter: 'daily' | 'custom';
  page: number;
  per_page: number;
  with_pagination: 0 | 1;
  start_date: string;
  end_date: string;
  status_filter: TASK_STATUS_VALUE;
  order_direction: TaskOrderDirection;
  order_by: TaskOrderBy;
  timezone: string;
}

export type TaskOrderBy =
  | 'title'
  | 'checklist'
  | 'due_date'
  | 'author'
  | 'project'
  | 'attachments'
  | 'assigned'
  | 'status';

export enum TASK_ORDER_BY {
  TITLE = 'title',
  CHECKLIST = 'checklist',
  DUE_DATE = 'due_date',
  AUTHOR = 'author',
  PROJECT = 'project',
  ATTACHMENTS = 'attachments',
  ASSIGNED = 'assigned',
  STATUS = 'status',
  NONE = '',
}

export type TaskOrderDirection = 'asc' | 'desc';
export type TaskVisualizationFilter = 'all' | 'due' | 'upcoming' | 'spectating';

export enum TASK_VIEWS {
  KANBAN,
  TABLE,
  CALENDAR,
}
export const taskPageUrlToView = {
  [PAGE_TASKS_KANBAN]: TASK_VIEWS.KANBAN,
  [PAGE_TASKS_TABLE]: TASK_VIEWS.TABLE,
  [PAGE_TASKS_CALENDAR]: TASK_VIEWS.CALENDAR,
};

export interface ITaskCalendarItem {
  isSelectedMonth: boolean;
  isFirstRow?: boolean;
  dayNumber: number;
  dayName: string;
  dateFormatted: string;
}
