import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
  AbstractControl,
  ControlContainer,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-floating-textarea',
  standalone: true,
  imports: [CommonModule, InputTextareaModule, FormsModule, InputTextModule],
  templateUrl: './floating-textarea.component.html',
  styleUrls: ['./floating-textarea.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloatingTextareaComponent),
    },
  ],
})
export class FloatingTextareaComponent implements OnInit, OnDestroy {
  @ViewChild('input') input: NgModel;
  @Input() model: any = '';
  @Input() required = false;
  @Input() name = 'input';
  @Output() modelChange = new EventEmitter<any>();
  @Input() label: string;
  @Input() ngModelOptions: { standalone: boolean } = { standalone: false };
  @Input() disabled = false;
  @Input() autoComplete = true;
  @Input() placeholder = '';
  @Input() set formControlName(value: string) {
    // just set the input's name
    if (value) {
      this.name = value;
    }
  }
  /**
   * validate input against a given pattern
   */
  @Input() pattern = null;
  @Input() patternErrorMessage: string = null;
  @Input() autoResize: boolean | undefined = false;
  @Input() rows: number = 5;
  @Input() cols: number = 30;

  formattedModel: any = '';
  isDestroyed$ = new Subject();

  ngControl: NgControl;
  readonly = true; // workaround against autocomplete
  get control(): AbstractControl {
    if (this.ngControl) {
      return this.ngControl.control;
    }
    return this.input?.control;
  }

  constructor(private injector: Injector) {}

  ngOnInit() {
    try {
      // no ngControl if used with ngModel so injector is needed,
      // otherwise we get a nullInjection error
      this.ngControl = this.injector.get(NgControl);
      setTimeout(() => {
        // otherwise it won't display the control's value
        this.model = this.ngControl?.control?.value ?? '';
        this.ngControl?.control?.valueChanges
          ?.pipe(takeUntil(this.isDestroyed$))
          .subscribe((value) => {
            this.model = value;
          });
      });
    } catch (error) {
      // this is NOT a real problem, we can just use ngModel's control
    }
  }

  onModelChange(event: any) {
    this.formattedModel = event;
    this.modelChange.emit(event);
  }

  onBlur() {
    this.control.markAsTouched();
  }

  onFocus() {
    // workaround against autocomplete
    // https://stackoverflow.com/a/32578659/6146963
    this.readonly = false;
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
