import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IGlTemplate,
} from '../../../../../../store/settings/settings.interface';
import { OVERLAY_TYPES } from '../../../../../../pipes/framework/overlay-options.pipe';
import { settingsActions } from '../../../../../../store/settings/settings.actions';
import { TemplateType } from '../template-selector.component';
import { AppState } from '../../../../../../store/app-state';
import { Store } from '@ngrx/store';
import { OptionsPermissionsPipe } from '../../../../../../pipes/framework/options-permissions.pipe';
import { NgIf } from '@angular/common';
import { UnStyledOptionsListComponent } from '../../../../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { EditableInputComponent } from '../../../../../../framework/inputs/editable-input/editable-input.component';

@Component({
  selector: 'app-selector-input',
  templateUrl: './selector-input.component.html',
  styleUrls: [],
  standalone: true,
  imports: [EditableInputComponent, UnStyledOptionsListComponent, NgIf, OptionsPermissionsPipe],
})
export class SelectorInputComponent {
  _template: IGlTemplate | IBudgetTemplate | IBudgetTagTemplate;
  @Input() set template(value) {
    this._template = value;
    this.hasPermissions = Object.values(value.permissions).some((permission) => !!permission);
  }
  get template() {
    return this._template;
  }
  @Input() templateType: TemplateType;
  @Output() unselectTemplate = new EventEmitter();
  OVERLAY_TYPES = OVERLAY_TYPES;
  showInput = false;

  hasPermissions = true; // true if the user has at least one permission
  constructor(private store: Store<AppState>) {}

  registerOption(event: string, template: IGlTemplate | IBudgetTemplate | IBudgetTagTemplate) {
    console.log(event, template);
    if (event === 'Delete') {
      this.deleteTemplate(template.id);
    }
    if (event === 'Edit') {
      this.showInput = true;
    }
  }

  deleteTemplate(id: number) {
    switch (this.templateType) {
      case 'budget':
        this.store.dispatch(settingsActions.deleteBudgetTemplate({ templateId: id }));
        break;
      case 'gl_accounts':
        this.store.dispatch(settingsActions.deleteGlTemplate({ templateId: id }));
        break;
      case 'budget-tag':
        this.store.dispatch(settingsActions.deleteBudgetTagTemplate({ templateId: id }));
        break;
      default:
        break;
    }
    this.unselectTemplate.emit();
  }

  updateTemplateName(newTemplateName: string) {
    console.log(newTemplateName);
    switch (this.templateType) {
      case 'budget':
        this.store.dispatch(
          settingsActions.updateBudgetTemplate({
            template: {
              ...(this.template as IBudgetTemplate),
              name: newTemplateName,
            },
          }),
        );
        break;
      case 'gl_accounts':
        this.store.dispatch(
          settingsActions.updateGlTemplate({
            template: {
              ...(this.template as IGlTemplate),
              name: newTemplateName,
            },
          }),
        );
        break;
      case 'budget-tag':
        this.store.dispatch(
          settingsActions.updateBudgetTagTemplate({
            template: {
              ...(this.template as IBudgetTagTemplate),
              name: newTemplateName,
            },
          }),
        );
        break;
      default:
        break;
    }
    this.showInput = false;
  }
}
