import { Component, Input, ViewChild } from '@angular/core';
import { FormType, ReportingBarComponent } from '../reporting/reporting-bar.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { ChecklistItemComponent } from '../../inputs/checklist-item/checklist-item.component';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { MatButton } from '@angular/material/button';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { IServiceProvider } from './invoice-report-panel.types';
import { Project } from '../../../pages/webapp/projects/projects.interface';
import { PROJECT_STATUS_ID } from '../../constants/project.constants';
import { IReportingData, REPORTING_ALL_STATUSES } from '../../reporting/reporting.constants';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import dayjs from 'dayjs';
import { PageLoadingComponent } from '../../page-loading/page-loading.component';
import { LoadingPulseComponent } from '../../loading-pulse/loading-pulse.component';

@Component({
  selector: 'app-invoice-report-panel',
  standalone: true,
  imports: [
    BackButtonBoxComponent,
    ChecklistItemComponent,
    DropdownComponent,
    FloatingInputComponent,
    MatButton,
    NgForOf,
    NgClass,
    NgIf,
    NgScrollbar,
    ReactiveFormsModule,
    InputCalendarComponent,
    FormsModule,
    PageLoadingComponent,
    LoadingPulseComponent,
  ],
  templateUrl: './invoice-report-panel.component.html',
  styleUrl: '../reporting/reporting-bar.component.scss',
})
export class InvoiceReportPanelComponent extends ReportingBarComponent {
  @ViewChild('dateRangesPageForm') dateRangesPageForm: NgForm;

  readonly PROPERTY_PAGE = 0;
  readonly PROJECTS_PAGE = 1;
  readonly SP_PAGE = 2;
  readonly DATES_PAGE = 3;
  readonly MAX_PAGE = 3;

  serviceProviderSearch: string = '';
  selected_sp_ids: string[] = [];
  serviceProvidersList: IServiceProvider[] = [];
  allStatuses = [...REPORTING_ALL_STATUSES];

  reportFields: FormGroup<FormType> = new FormGroup<FormType>({
    invoice_log_start_date: new FormControl<string>(null, [Validators.required]),
    invoice_log_end_date: new FormControl<string>(null, [Validators.required]),
    start_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    end_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    project_status_ids: new FormControl<string[]>(
      [this.ALL, ...this.allStatuses],
      [Validators.required],
    ),

    include_property_summary: new FormControl<boolean>(true),
    include_project_summary: new FormControl<boolean>(false),
    include_project_spend_detail: new FormControl<boolean>(false),
    include_cash_flow: new FormControl<boolean>(false),
    include_project_schedule: new FormControl<boolean>({ value: false, disabled: true }),
    include_project_commitments: new FormControl<boolean>(false),
    include_contract_detail: new FormControl<boolean>(false),
    include_change_order_detail: new FormControl<boolean>(false),
    include_invoice_detail: new FormControl<boolean>(false),
    include_direct_cost_detail: new FormControl<boolean>(false),
    include_project_updates: new FormControl<boolean>(false),
    include_invoice_log: new FormControl<boolean>(true),

    project_ids: new FormControl<string[]>([], [Validators.required]),
    property_ids: new FormControl<string[]>([], [Validators.required]),
    tmp_sp_ids: new FormControl<number[]>([]),
    sp_ids: new FormControl<number[]>([]),
  });
  dateRanges = {
    invoice_log_start_date: null,
    invoice_log_end_date: null,
  };

  @Input() set data(value: { properties: any; projects: Project[] }) {
    if (value) {
      if (value.properties) {
        this.properties = this.sortProperties(Array(...value.properties));
        this.propertiesToList = [...this.properties];
      }
      if (value.projects) {
        const projects = value.projects.filter(
          (proj) =>
            proj.status !== PROJECT_STATUS_ID.DELETED && proj.status !== PROJECT_STATUS_ID.ARCHIVED,
        );
        this.projects = this.sortProjects([...projects]);
      }
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.reportFields.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.checkOverallValidation();
    });
  }

  /**
   * Get filtered service providers list
   */
  get filteredServiceProvidersList(): IServiceProvider[] {
    return this.serviceProvidersList.filter(
      (sp: IServiceProvider) =>
        !this.serviceProviderSearch ||
        sp.name.toLowerCase().includes(this.serviceProviderSearch.toLowerCase()),
    );
  }

  /**
   * filter projects after property, year and search term
   */
  get filteredProjects(): Project[] {
    const propertyIds = this.reportFields.get('property_ids').value;
    const statuses = this.reportFields.get('project_status_ids').value;

    const filtered = this.projects
      .filter((project) => {
        // filter by property selection
        return propertyIds.includes(this.ALL) || propertyIds.includes(project.property_id + '');
      })
      .filter((project) => {
        // filter by status selection
        return statuses.includes(project.project_status.id + '');
      })
      .filter(
        // filter by search term
        (project) =>
          !this.projectSearch ||
          project?.title?.toLowerCase()?.includes(this.projectSearch?.toLowerCase()) ||
          project?.project_property?.formatted_address
            ?.toLowerCase()
            ?.includes(this.projectSearch?.toLowerCase()),
      );
    return filtered;
  }

  checkOverallValidation() {
    let isValid = true; // if any call returns false, this will be false
    isValid = isValid && this.checkPropertyValidation();
    isValid = isValid && this.checkProjectValidation();
    isValid = isValid && this.checkSPValidation();
    this.submitDisabled = !isValid;
  }

  checkSPValidation() {
    this.errors[this.SP_PAGE] = null;
    if (this.selected_sp_ids.length === 0) {
      this.errors[this.SP_PAGE] = 'At least one service provider is required.';
    }
    return !this.errors[this.SP_PAGE];
  }

  onCompanyIconClick() {
    this.serviceProviderSearch = '';
  }

  toggleAllSP() {
    if (this.selected_sp_ids.includes(this.ALL)) {
      this.selected_sp_ids = [];
    } else {
      const allValues = this.serviceProvidersList.map((company) => company.id.toString());
      this.selected_sp_ids = [...allValues, this.ALL];
    }
    this.checkSPValidation();
  }

  toggleSP(companyId: string) {
    if (this.selected_sp_ids.includes(companyId)) {
      this.selected_sp_ids = this.selected_sp_ids.filter(
        (id) => id !== companyId && id !== this.ALL,
      );
    } else {
      this.selected_sp_ids = [...this.selected_sp_ids, companyId];
    }

    if (this.selected_sp_ids.length === this.serviceProvidersList.length) {
      this.selected_sp_ids = [...this.selected_sp_ids, this.ALL];
    }
    this.checkSPValidation();
  }

  convertFormToPostData(): IReportingData {
    const stringArrayToNumberArray = (ids: string[]) => ids.map((id) => parseInt(id, 10));
    const formData = this.reportFields.getRawValue();
    const data: IReportingData = {
      ...formData,
      property_ids: [], // will be filled below
      project_ids: [], // will be filled below
      project_status_ids: [], // will be filled below
      include_project_cash_flow: formData.include_cash_flow,
      include_property_cash_flow: formData.include_cash_flow,
      include_property_projects: formData.include_project_summary,
      include_property_budget_lines: formData.include_project_summary,
      include_property_budget_tags: formData.include_project_summary,
      include_contract_detail: formData.include_project_commitments,
      include_change_order_detail: formData.include_project_commitments,
      include_invoice_log: formData.include_invoice_log,
      invoice_log_end_date: formData.invoice_log_end_date,
      invoice_log_start_date: formData.invoice_log_start_date,
      start_year: dayjs(formData.invoice_log_start_date).year(),
      end_year: dayjs(formData.invoice_log_end_date).year(),
    };

    if (formData.property_ids.includes(this.ALL)) {
      data.property_ids = this.ALL;
    } else {
      data.property_ids = stringArrayToNumberArray(formData.property_ids);
    }

    if (formData.project_ids.includes(this.ALL)) {
      data.project_ids = this.ALL;
    } else {
      data.project_ids = stringArrayToNumberArray(formData.project_ids);
    }

    if (formData.project_status_ids.includes(this.ALL)) {
      data.project_status_ids = stringArrayToNumberArray(this.allStatuses);
    } else {
      data.project_status_ids = stringArrayToNumberArray(formData.project_status_ids);
    }
    return data;
  }

  checkDatesValidation() {
    this.dateRangesPageForm.control.markAllAsTouched();
    this.errors[this.DATES_PAGE] = null;
    const startDate = this.dateRanges.invoice_log_start_date;
    const endDate = this.dateRanges.invoice_log_end_date;

    if (!startDate || !endDate) {
      this.errors[this.DATES_PAGE] = 'Start and end dates are required.';
    } else {
      this.errors[this.DATES_PAGE] = null;
    }

    return !this.errors[this.DATES_PAGE];
  }

  async fillFormAndSubmit() {
    if (this.checkDatesValidation()) {
      this.reportFields
        .get('invoice_log_start_date')
        .setValue(this.dateRanges.invoice_log_start_date);
      this.reportFields.get('invoice_log_end_date').setValue(this.dateRanges.invoice_log_end_date);
      this.reportFields
        .get('start_year')
        .setValue(dayjs(this.dateRanges.invoice_log_start_date).year());
      this.reportFields
        .get('end_year')
        .setValue(dayjs(this.dateRanges.invoice_log_end_date).year());
      const serviceProviders = this.serviceProvidersList.filter((sp) => sp.is_tmp === 0);
      const tempServiceProviders = this.serviceProvidersList.filter((sp) => sp.is_tmp === 1);
      const spIds = serviceProviders
        .filter((contractor) => this.selected_sp_ids.includes(String(contractor.id)))
        .map((sp) => sp.id);

      const tmpSpIds = tempServiceProviders
        .filter((contractor) => this.selected_sp_ids.includes(String(contractor.id)))
        .map((sp) => sp.id);
      this.reportFields.get('tmp_sp_ids').setValue(tmpSpIds);
      this.reportFields.get('sp_ids').setValue(spIds);
      await this.submit();
    }
  }

  incrementPage() {
    if (this.currentPage === this.MAX_PAGE) {
      return;
    }

    let canGoTrough = false;
    if (this.currentPage === this.PROPERTY_PAGE) {
      canGoTrough = this.checkPropertyValidation();
    } else if (this.currentPage === this.PROJECTS_PAGE) {
      canGoTrough = this.checkProjectValidation();
    } else if (this.currentPage === this.SP_PAGE) {
      canGoTrough = this.checkSPValidation();
    }

    if (canGoTrough) {
      this.currentPage++;
      this.checkOverallValidation();
    }

    if (this.currentPage === this.SP_PAGE) {
      this.loadCompanies();
    }

    if (this.currentPage === this.PROJECTS_PAGE) {
      this.checkAllProjectsSelected();
    }
  }

  loadCompanies() {
    this.isLoading = true;
    const projectIds = this.reportFields
      .get('project_ids')
      .value.filter((id) => id !== this.ALL)
      .map((id) => parseInt(id, 10));
    this.reportingService
      .getServiceProvidersList(projectIds)
      .pipe(take(1))
      .subscribe((spList) => {
        this.serviceProvidersList = spList;
        this.isLoading = false;
      });
  }
}
