<div class="budget-line-item-dropdown-container">
  <ng-scrollbar class="std-scrollbar rows-wrapper" track="vertical" appearance="standard">
    <div class="table-wrapper">
      <div class="row">
        <span class="row-title">Select Budget Line </span>
        <span
          [ngClass]="{ invisible: commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER }"
          class="row-title"
          >Contract Assigned</span
        >
        <span class="row-title">Budget</span>
        <span class="row-title">Commit</span>
        <span class="row-title">Balance</span>
      </div>
      <ng-container *ngFor="let budgetLineItem of budgetLineItems; index as i">
        <div class="row cursor-default">
          <span
            (click)="
              optionSelected.emit({ action: 'select', item: budgetLineItem, name: budgetLineItem.name, index: i })
            "
            class="pointer-cursor"
            [ngClass]="{ disabled: (budgetLineItem.id | containsValue: disabledLineItems) }"
          >
            {{ budgetLineItem.name }}</span
          >
          <span
            class="icon"
            [ngClass]="{
              'icon-approved': budgetLineItem.contractApproved,
              invisible: commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER
            }"
          ></span>
          <div [ngClass]="{ negative: budgetLineItem?.current_budget < 0 }">
            {{ budgetLineItem?.current_budget | money: 'shortWithParentheses' }}
          </div>
          <div [ngClass]="{ negative: budgetLineItem?.committed < 0 }">
            {{ budgetLineItem?.committed | money: 'shortWithParentheses' }}
          </div>
          <div [ngClass]="{ negative: budgetLineItem?.current_budget - budgetLineItem?.committed < 0 }">
            {{ budgetLineItem?.current_budget - budgetLineItem?.committed | money: 'shortWithParentheses' }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
  <div class="buttons-container">
    <div>
      <button
        mat-button
        disableRipple
        class="btn-small dropdown-button"
        (click)="optionSelected.emit({ action: 'delete' })"
      >
        Delete Budget Line Item
      </button>
    </div>
  </div>
</div>
