import { Component } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-add-button',
  standalone: true,
  imports: [NgClass],
  template: `
    <button class="btn btn-wrapper">
      <ng-content></ng-content>
      <div class="icon-plus-box text-size_title_s"></div>
    </button>
  `,
  styleUrl: './add-button.component.scss',
})
export class AddButtonComponent {}
