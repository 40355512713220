import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBudgetTagItem, IBudgetTagTemplate } from '../../../store/settings/settings.interface';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ContainsValuePipe } from '../../../pipes/framework/contains-value.pipe';
import { BorderedDropdownIconComponent } from '../../bordered-dropdown-icon/bordered-dropdown-icon.component';

@Component({
  selector: 'app-budget-tag-templates-dropdown',
  templateUrl: './budget-tag-templates-dropdown.component.html',
  styleUrls: ['./budget-tag-templates-dropdown.component.scss'],
  standalone: true,
  imports: [
    BackButtonBoxComponent,
    NgScrollbar,
    NgClass,
    NgIf,
    NgForOf,
    ContainsValuePipe,
    BorderedDropdownIconComponent,
  ],
})
export class BudgetTagTemplatesDropdownComponent {
  @Input() budgetTemplateTags: IBudgetTagTemplate[] = [];
  @Input() hasTemplate = false;
  @Input() disabledBudgetTagIds: number[] = [];
  @Input() showAllTags = false;
  @Output() optionSelected: EventEmitter<IBudgetTagItem> = new EventEmitter<IBudgetTagItem>();
  @Output() selectAllTagsLocalOption: EventEmitter<void> = new EventEmitter<void>();
  @Output() unlinkTemplate: EventEmitter<void> = new EventEmitter();
  selectedTemplate: IBudgetTagTemplate = null;

  selectTemplate(tag: IBudgetTagTemplate) {
    this.selectedTemplate = tag;
  }

  unselectTemplate() {
    this.selectedTemplate = null;
  }

  removeTemplate() {
    this.unlinkTemplate.emit();
  }

  selectTag(tag: IBudgetTagItem) {
    this.optionSelected.emit(tag);
  }
}
