import { Component, Input } from '@angular/core';
import { DriveService } from '../../../../services/drive.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IFile } from '../../../../store/tasks/tasks.interfaces';
import { DriveApiService } from '../../../../services/drive-api.service';
import { ConnectedPosition, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ShortenFileNamePipe } from '../../../../pipes/framework/shorten-file-name.pipe';
import { OptionsListGeneralComponent } from '../../../overlays/options-list-general/options-list-general.component';
import { NgClass, NgIf } from '@angular/common';

export type FileType = 'general' | 'image' | 'video' | 'audio' | 'ppt' | 'xls' | 'txt' | 'pdf';

@Component({
  selector: 'app-file-thumbnail',
  templateUrl: './file-thumbnail.component.html',
  styleUrls: ['./file-thumbnail.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, CdkOverlayOrigin, OptionsListGeneralComponent, ShortenFileNamePipe],
})
export class FileThumbnailComponent {
  type: FileType;
  url: SafeUrl;
  maxFileNameLength = 12;
  showFullName = false;
  overlayPositions: ConnectedPosition[] = [
    {
      originX: 'center',
      overlayX: 'center',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: -4,
      offsetX: 0,
    },
  ];

  constructor(
    private driveService: DriveService,
    private domSanitizer: DomSanitizer,
    private driveApi: DriveApiService,
  ) {}

  private _file: IFile;

  get file() {
    return this._file;
  }

  @Input() set file(file: IFile) {
    this._file = file;
    if (file) {
      this.setFileType();
    }
  }

  async setFileType() {
    if (this.file.type.includes('image')) {
      this.type = 'image';
      const fileURL = await this.driveApi.getFileViewURL(this.file.id);
      this.url = this.domSanitizer.bypassSecurityTrustUrl(fileURL);
      return;
    }
    if (this.file.type.includes('pdf')) {
      this.type = 'pdf';
      return;
    }

    this.type = 'general';
  }

  downloadFile() {
    if (this.file) {
      this.driveService.downloadSelectedDocuments([this.file]);
    }
  }
}
