import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  allCustomTaskQuestionFieldTypes,
  CUSTOM_TASK_FIELD_TYPES,
  CustomTaskFieldGroup,
  CustomTaskTypeToDisplay,
  TaskDropdownOptionSelected,
} from '../../../store/tasks/tasks.constants';
import { dropdownOverlayPositions, SELECTED_OPTION } from '../../overlays/option-list.constants';
import { getErrorMessage } from '../../constants/messages.constants';
import { iconDropdown } from '../../constants/general.constants';
import { SPEND_DISTRIBUTION_STATE } from '../../constants/spend.constants';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { NgIf } from '@angular/common';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { CustomTasksGroupDropdownComponent } from '../../custom-tasks-group-dropdown/custom-tasks-group-dropdown.component';
import { CustomInputOverlayDropdownComponent } from '../../custom-input-overlay-dropdown/custom-input-overlay-dropdown.component';

@Component({
  selector: 'app-custom-task-group',
  templateUrl: './custom-task-group.component.html',
  styleUrls: ['./custom-task-group.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CustomInputOverlayDropdownComponent,
    CustomTasksGroupDropdownComponent,
    FloatingInputComponent,
    NgIf,
    InputCalendarComponent,
    DropdownComponent,
  ],
})
export class CustomTaskGroupComponent implements OnInit {
  @Input() model: CustomTaskFieldGroup;
  @Output() modelChange = new EventEmitter<CustomTaskFieldGroup>();
  @Output() updateModelState = new EventEmitter();
  @Output() removeGroup = new EventEmitter<{ index: number }>();
  @Input() groupIndex: number;
  @ViewChild('taskForm') taskForm: NgForm;
  CustomTaskTypeToDisplay = CustomTaskTypeToDisplay;
  CUSTOM_TASK_FIELD_TYPES = CUSTOM_TASK_FIELD_TYPES;
  allCustomTaskQuestionFieldTypes = allCustomTaskQuestionFieldTypes;
  dropdownOverlayPositions: ConnectedPosition[] = dropdownOverlayPositions;
  showOptions = false;

  getErrorMessage = getErrorMessage;
  protected readonly iconDropdown = iconDropdown;
  protected readonly SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE;

  constructor() {}

  ngOnInit(): void {}

  modelHasChanged() {
    this.modelChange.emit(this.model);
    this.updateModelState.emit();
  }

  dateChanged(date: string) {
    this.model.value = date;
    // model.value = this.timeZonePipe.transform(model.value as string, CALENDAR);
    this.modelHasChanged();
  }

  removeCustomFieldGroup() {
    this.removeGroup.emit({ index: this.groupIndex - 1 });
  }

  clearValue() {
    this.model.value = '';
  }

  isFormValid() {
    this.taskForm.form.markAllAsTouched();
    return this.taskForm.form.valid;
  }

  optionSelected(option: TaskDropdownOptionSelected) {
    switch (option.type) {
      case SELECTED_OPTION.SELECT:
        this.model.type = option.taskType;
        this.clearValue();
        this.modelHasChanged();
        break;
      case SELECTED_OPTION.REMOVE:
        this.removeCustomFieldGroup();
        break;
      default:
        console.warn('Case unhandled.');
    }
    this.toggleShowOption();
  }

  toggleShowOption() {
    this.showOptions = !this.showOptions;
  }
}
