import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserService } from '../../../services/current-user.service';
import { SubscriptionService } from '../../../services/subscription.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../constants/user.constants';

@Component({
  selector: 'app-pricing-banner',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="isTrialing && !hasPaymentMethod" (click)="onBannerClick()" class="trial-end-banner">
      <span class="left-triangle"></span>
      <span class="right-triangle"></span>
      <span class="banner-text">
        <ng-container *ngIf="!trialEnded">
          Your free trial period ends in <span class="day-counter">{{ trial_ends }}</span
          >.
        </ng-container>
        <ng-container *ngIf="trialEnded"> Your free trial period has ended. </ng-container>

        Click <span class="text-color_accent">HERE</span> to add a credit card to your account and
        avoid losing your data.
      </span>
    </div>
  `,
  styleUrls: ['./pricing-banner.component.scss'],
})
export class PricingBannerComponent implements OnInit, OnDestroy {
  trial_ends = 'X Days'; // fill with default value
  trialEnded = false; // just to be sure - User won't be able to see this banner if the trial has ended and has no active subscription

  isDestroyed$ = new Subject();
  isTrialing = false;
  hasPaymentMethod = false;

  constructor(
    private userService: CurrentUserService,
    private subscriptionService: SubscriptionService,
  ) {}

  ngOnInit() {
    this.userService.data$.pipe(takeUntil(this.isDestroyed$)).subscribe((user) => {
      if (!user.subscription) {
        // user cannot be trialing if there is no subscription
        return;
      }
      this.isTrialing = user.subscription.status === 'trialing';
      this.hasPaymentMethod = !!user.subscription.payment_method?.type;
      this.calculateTrialEnds(user);
    });
  }

  calculateTrialEnds(user: User) {
    const trialEnds = moment.utc(user.subscription.trial_ends_at);
    const daysLeft = trialEnds.diff(moment(), 'days', true);
    if (isNaN(daysLeft) || daysLeft < 0) {
      this.trialEnded = true;
      return;
    }

    if (daysLeft < 1) {
      const minutesLeft = trialEnds.diff(moment(), 'minutes');
      if (minutesLeft > 60) {
        const hoursLeft = Math.ceil(minutesLeft / 60);
        this.trial_ends = hoursLeft === 1 ? '1 hour' : `${hoursLeft} hours`;
        return;
      }
      this.trial_ends = minutesLeft === 1 ? '1 minute' : `${minutesLeft} minutes`;
      return;
    }

    const roundedDays = Math.ceil(daysLeft);
    this.trial_ends = `${roundedDays} days`;
  }

  onBannerClick() {
    this.subscriptionService.openCustomerPortal();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
