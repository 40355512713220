import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../../services/current-user.service';
import { ProjectsManager } from '../projects.manager';
import { ProjectsContractor } from '../projects.contractor';
import { ProjectsInterface } from '../projects.interface';
import { ProjectStateService } from '../../../../services/project-state.service';
import { ProjectApiService } from '../../../../services/project-api.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { Subscription } from 'rxjs';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-project-info-contractor',
  templateUrl: './project-info-contractor.component.html',
  styleUrls: ['./project-info-contractor.component.scss'],
  standalone: true,
  imports: [NgClass, MoneyPipe],
})
export class ProjectInfoContractorComponent implements OnInit, OnDestroy {
  viewModel: ProjectsInterface;
  spent = 0;
  budget = 0;
  biddingNr = 0;
  biddingVisitsNr = 0;
  bidSubmitedNr = 0;
  biddingInvited = 0;
  currentProject = { title: '' };

  biddingAwarded = 0;
  biddingPending = 0;

  subs = new Subscription();

  constructor(
    public user: CurrentUserService,
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
  ) {
    if (user.isManager) {
      this.viewModel = new ProjectsManager();
    } else {
      this.viewModel = new ProjectsContractor();
    }
  }

  ngOnInit() {
    this.updateStats();
    const sub = ProjectStateService.updateContractorStats.subscribe((_) => {
      this.updateStats();
    });
    this.subs.add(sub);
  }

  updateStats() {
    this.projectApi.getStats().then(
      (data) => {
        this.biddingNr = data.bidding_projects;
        this.bidSubmitedNr = data.bids;
        this.biddingVisitsNr = data.schedule_visits;
        this.biddingAwarded = data.bidding_bids_awarded;
        this.biddingPending = data.bidding_bids_pending;
        this.biddingInvited = data.bidding_invitations;

        this.budget = data.bidding_budget_value;

        console.log(data);
      },
      (err) => {
        // this.notif.showError(err);
      },
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
