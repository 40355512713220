<ng-scrollbar
  *ngIf="(spendLineItemSummary$ | async)?.isLoaded"
  appearance="compact"
  class="std-commitments-scrollbar std-scrollbar"
  track="vertical"
>
  <div class="p-6 h-full box-border">
    <span class="text-shade_2 text-size_xl">
      <span class="font-bold">Project Spend: </span>
      <span class="font-normal">Budget Line Detail</span>
    </span>

    <br />

    <div class="row">
      <span></span>
      <span class="flex justify-start uppercase font-bold text-xs text-shade_3">committed</span>
    </div>
    <br />
    <div class="row">
      <span class="font-bold text-base text-color_primary"> {{ (spendLineItemSummary$ | async)?.name }}</span>
      <span
        [ngClass]="{
          negative: (spendLineItemSummary$ | async)?.committed < 0,
          'text-color_primary': (spendLineItemSummary$ | async)?.committed >= 0
        }"
        class="font-bold text-size_xl"
        >{{ (spendLineItemSummary$ | async)?.committed | money: 'positiveComa' }}</span
      >
    </div>
    <div class="pt-3 border-separator flex"></div>
    <ng-container *ngFor="let spendContact of (spendLineItemSummary$ | async)?.spend_contracts?.contracts">
      <div [ngClass]="{ 'flex-1': expansionPanel.expanded }" class="expansion-panel-wrapper flex">
        <mat-expansion-panel #expansionPanel class="pure-expansion-panel mat-elevation-z flex-1" hideToggle>
          <mat-expansion-panel-header class="!py-4">
            <div class="flex-1 row header self-end">
              <span class="flex items-center gap-3">
                <span class="text-color_primary text-size_xl font-semibold">{{ spendContact.title }}</span>
                <span class="text-shade_2 text-xs font-semibold leading-3">{{ spendContact.company_name }}</span>
              </span>
              <span
                [ngClass]="{
                  negative: spendContact?.committed < 0,
                  'text-color_primary': spendContact?.committed >= 0
                }"
                class="text-size_xl font-semibold"
                >{{ spendContact?.committed | money: 'positiveComa' }}</span
              >
            </div>
            <app-arrow-button-box
              (clicked)="onExpansionPanelClick(expansionPanel)"
              [direction]="expansionPanel.expanded ? 'up' : 'down'"
              [isFilled]="expansionPanel.expanded"
              class="pointer-cursor"
              thickness="light"
            ></app-arrow-button-box>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div class="expansion-panel-content">
              <div class="border-separator partial"></div>
              <ng-container *ngFor="let commitment of spendContact.commitments">
                <div class="row p-2.5">
                  <span>
                    {{ commitment.title }}
                    <!--                    {{ commitment.type === 'contracts' ? 'Contract' : 'Change Order' }}-->
                  </span>
                  <span
                    [ngClass]="{
                      negative: commitment?.committed < 0
                    }"
                    class="ml-[11px] font-sm font-normal"
                    >{{ commitment?.committed | money: 'positiveComa' }}</span
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="!spendContact?.commitments?.length">
                <div class="row p-2.5">
                  <span class="text-shade_2 font-bold">No Data</span>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </div>
      <div class="border-separator flex"></div>
    </ng-container>
    <div [ngClass]="{ 'flex-1': directCostExpansionPanel.expanded }" class="expansion-panel-wrapper flex">
      <mat-expansion-panel #directCostExpansionPanel class="pure-expansion-panel mat-elevation-z flex-1" hideToggle>
        <mat-expansion-panel-header class="!py-4">
          <div class="flex-1 row header self-end">
            <span class="flex items-center gap-3">
              <span class="text-color_primary text-size_xl font-semibold">Direct Cost</span>
            </span>
            <span
              [ngClass]="{
                negative: (spendLineItemSummary$ | async)?.spend_direct_costs.committed < 0,
                'text-color_primary': (spendLineItemSummary$ | async)?.spend_direct_costs.committed >= 0
              }"
              class="text-size_xl font-semibold"
              >{{ (spendLineItemSummary$ | async)?.spend_direct_costs.committed | money: 'positiveComa' }}</span
            >
          </div>
          <app-arrow-button-box
            (clicked)="onExpansionPanelClick(directCostExpansionPanel)"
            [direction]="directCostExpansionPanel.expanded ? 'up' : 'down'"
            [isFilled]="directCostExpansionPanel.expanded"
            class="pointer-cursor"
            thickness="light"
          ></app-arrow-button-box>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="expansion-panel-content">
            <div class="border-separator partial"></div>
            <ng-container
              *ngFor="
                let commitment of (spendLineItemSummary$ | async)?.spend_direct_costs.direct_costs;
                let index = index
              "
            >
              <div class="row p-2.5">
                <!--                <span> Direct Cost {{ index + 1 }} </span>-->
                <span> {{ commitment.title }}</span>
                <span
                  [ngClass]="{
                    negative: commitment?.committed < 0
                  }"
                  class="ml-[11px] font-sm font-normal"
                  >{{ commitment?.committed | money: 'positiveComa' }}</span
                >
              </div>
            </ng-container>
            <ng-container *ngIf="!(spendLineItemSummary$ | async)?.spend_direct_costs?.direct_costs?.length">
              <div class="row p-2.5">
                <span class="text-shade_2 font-bold">No Data</span>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <div class="border-separator flex"></div>
    <ng-container
      *ngIf="
        !(spendLineItemSummary$ | async)?.spend_contracts?.contracts?.length &&
        !(spendLineItemSummary$ | async)?.spend_direct_costs?.direct_costs?.length
      "
    >
      <div class="flex justify-center pt-10 p-2.5">
        <span class="text-shade_2 font-bold">No Data</span>
      </div>
    </ng-container>
  </div>
</ng-scrollbar>

<div *ngIf="!(spendLineItemSummary$ | async)?.isLoaded" class="flex w-full h-full items-center justify-center">
  <div class="notify-msg animate-pulsating-border">
    <div class="icon-logo-box-hex"></div>
  </div>
</div>
