<div class="flex flex-col">
  <div class="title flex justify-between items-center">
    <div class="flex gap-2 items-center h-6">
      @if (showBackButton) {
        <app-bordered-dropdown-icon
          (click)="onBack()"
          isClosed="false"
          [useAsBackButton]="true"
        ></app-bordered-dropdown-icon>
      }
      <span class="leading-normal">{{ title }}</span>
    </div>
    @if (showHelpButton) {
      <span (click)="onNeedHelpClick()" class="text-color_secondary text-sm cursor-pointer leading-normal">{{
        isHelpShown ? 'Close Help' : 'Need Help?'
      }}</span>
    }
  </div>
</div>
