import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-faded-text',
  templateUrl: './faded-text.component.html',
  styleUrls: ['./faded-text.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class FadedTextComponent implements OnInit {
  @Input() displayGrid = false;
  constructor() {}

  ngOnInit(): void {}
}
