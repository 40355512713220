import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { NotificationsService } from '../services/notifications.service';
import { StorageService } from '../services/storage.service';
import { hasActiveSubscription } from '../framework/constants/subscription.constants';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private user: CurrentUserService,
    private notif: NotificationsService,
    private storage: StorageService,
  ) {}

  /**
   * Verify if user can access logged in user content.
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res) => {
      this.checkTokenStatus().then((isAuth) => {
        if (this.user.isLoggedIn()) {
          if (hasActiveSubscription(this.user?.data)) {
            res(true);
            return;
          }

          // if user has no subscription and is going to subscription page, allow access
          // this is important to break the loop of redirecting to subscription page
          if (state.url.includes('/webapp/subscribe')) {
            res(true);
            return;
          }
          // if user has no active subscription, redirect to subscription page
          this.router.navigate(['webapp', 'subscribe']);
          res(false);
          return;
        } else {
          const segments: UrlSegment[] | undefined =
            this.router.getCurrentNavigation()?.finalUrl?.root?.children?.primary?.segments;
          const externalShare = segments?.[2]?.path !== 'documents' && segments?.[2]?.path;
          if (segments?.[1]?.path === 'documents' && !!externalShare) {
            res(true);
          } else {
            this.router.navigate(['/auth/login']);
            res(false);
          }
        }
      });
    });
  }

  /**
   * If user is already logged in redirect to landing page if he wants to access AUTH pages.
   */
  canLoad(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.user.isLoggedIn() && this.user.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/landing']);
      return false;
    }
  }

  /**
   * If user is logged in redirect to webapp.
   */
  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res) => {
      if (this.user.isLoggedIn()) {
        // this.user.isManagerF().then((isManager) => {
        if (next.queryParams.invitation_token) {
          this.storage.setInviteToken(next.queryParams.invitation_token);
          res(true);
          return;
        }

        this.router.navigate(['/webapp']);
        res(true);
        // if (!isManager) {
        //   res(false);
        // } else {
        //   res(true);
        // }
        // });
      } else {
        res(true);
      }
    });
  }

  checkTokenStatus(): Promise<any> {
    const tokenTime = new Date(this.storage.getTokenAquireTime()).getTime();
    const currentTime = new Date().getTime();
    const compUsrTime = 86400000 * 7; // set for 1 * 7 day in milliseconds
    const compTknTime = this.storage.getTokenDurration();

    return new Promise<any>((res) => {
      // check timespan that one user can be logged in
      if (currentTime - tokenTime > compUsrTime) {
        this.user.logout();
        res(true);
      }

      // refresh token
      else if (currentTime - tokenTime > compTknTime) {
        this.user.retrieveNewToken().then(
          (data) => {
            res(true);
          },
          (err) => {
            this.user.logout();
            res(false);
          },
        );
      } else {
        res(true);
      }
    });
  }
}
