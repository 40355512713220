import { Component, OnDestroy, OnInit } from '@angular/core';
import { IFile, ITaskSideBar } from '../../../store/tasks/tasks.interfaces';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  clearFiles,
  clearTaskSideBar,
  loadTasks,
  tasksActions,
} from '../../../store/tasks/tasks.actions';
import {
  getIsSubmit,
  getSidebarModel,
  getTaskSidebarFilesToDelete,
  getTaskSidebarFilesToUpload,
  isTaskSidebarEdit,
} from '../../../store/tasks/tasks.selectors';
import { Observable } from 'rxjs';
import { DateCustomPipe } from '../../../pipes/framework/date-custom.pipe';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { TasksInteractionBarCommonComponent } from './tasks-interaction-bar-common/tasks-interaction-bar-common.component';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { DriveApiService } from '../../../services/drive-api.service';
import { DeepCopyService } from '../../../services/deep-copy.service';
import {
  IMetaData,
  PresignedFileUploadService,
} from '../../../services/presigned-file-upload.service';
import { NotificationsService } from '../../../services/notifications.service';
import { MatButton } from '@angular/material/button';
import { TasksInteractionBarThirdPageComponent } from './tasks-interaction-bar-third-page/tasks-interaction-bar-third-page.component';
import { TasksInteractionBarSecondPageComponent } from './tasks-interaction-bar-second-page/tasks-interaction-bar-second-page.component';
import { TasksInteractionBarFirstPageComponent } from './tasks-interaction-bar-first-page/tasks-interaction-bar-first-page.component';
import { SmoothScroll } from 'ngx-scrollbar/smooth-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-tasks-interaction-bar',
  templateUrl: './tasks-interaction-bar.component.html',
  styleUrls: ['./tasks-interaction-bar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    BackButtonBoxComponent,
    NgScrollbar,
    SmoothScroll,
    TasksInteractionBarFirstPageComponent,
    TasksInteractionBarSecondPageComponent,
    TasksInteractionBarThirdPageComponent,
    MatButton,
    AsyncPipe,
  ],
})
export class TasksInteractionBarComponent
  extends TasksInteractionBarCommonComponent
  implements OnInit, OnDestroy
{
  uploadMetaData: Partial<IMetaData> = { task_id: null };
  model: Partial<ITaskSideBar> = { isSideBarLoading: true };
  getIsSummit$: Observable<boolean> = this.store.select(getIsSubmit);
  getSidebarModel$ = this.store.select(getSidebarModel);
  isTaskSidebarEdit$: Observable<boolean> = this.store.select(isTaskSidebarEdit);
  getTaskSidebarFilesToUpload$: Observable<IFile[]> = this.store.select(
    getTaskSidebarFilesToUpload,
  );
  getTaskSidebarFilesToDelete$: Observable<IFile[]> = this.store.select(
    getTaskSidebarFilesToDelete,
  );

  constructor(
    protected store: Store<AppState>,
    protected timeZonePipe: DateCustomPipe,
    protected interactionBarStateService: InteractionBarStateService,
    protected driveApi: DriveApiService,
    private presignedFileUploadService: PresignedFileUploadService,
    private notif: NotificationsService,
  ) {
    super(store, interactionBarStateService, timeZonePipe);
  }

  ngOnInit(): void {
    this.loadCheckList();
    this.handleSidebarModel();
    this.handleAfterTaskSaved();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.store.dispatch(clearTaskSideBar());
  }

  handleAfterTaskSaved() {
    this.getUpdateTaskID$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((id) => id !== null),
      )
      .subscribe((id) => {
        this.uploadMetaData = { task_id: id };
        this.deleteFlaggedFiles();
        this.uploadFiles();
      });
  }

  uploadFiles() {
    this.getTaskSidebarFilesToUpload$
      .pipe(
        take(1),
        map((files) => files.filter((file) => !file?.id)),
      )
      .subscribe((files) => {
        if (files.length) {
          this.presignedFileUploadService
            .uploadMultipleFilesToS3(
              files,
              files.map((_) => this.uploadMetaData),
            )
            .subscribe(
              (data) => {
                this.store.dispatch(loadTasks({}));
                data.filter((d) => d === null).length > 0
                  ? this.notif.showSuccess('Error occurred. Upload partially.')
                  : this.notif.showSuccess('Uploaded successfully');
                this.close();
              },
              (error) => {
                console.warn(error);
              },
            );
          this.store.dispatch(tasksActions.setSidebarLoadingFromTaskUpload({ isLoading: true }));
          this.store.dispatch(clearFiles());
        } else {
          this.close();
        }
      });
  }

  deleteFlaggedFiles() {
    this.getTaskSidebarFilesToDelete$.pipe(take(1)).subscribe((files) => {
      files.forEach((file) => this.driveApi.deleteFile(file.id));
    });
  }

  /**
   * handle sidebar values from store sidebar
   * overwrite component model only when page has changed, and it's not loading (important)
   * in case model is in loading state current page should not be overwritten because
   * if page is overwritten it will not enter the second time for edit
   */
  handleSidebarModel() {
    this.getSidebarModel$.pipe(takeUntil(this.isDestroyed$)).subscribe((newModel) => {
      // this.model.files = DeepCopyService.deepCopy(newModel.files);
      if (newModel.currentPage) {
        // necessary in case loading changes
        this.model.isSideBarLoading = DeepCopyService.deepCopy(newModel.isSideBarLoading);
      }
      if (this.model.currentPage !== newModel.currentPage && !newModel.isSideBarLoading) {
        // otherwise model will update on every input character change and focus out of input
        this.model = DeepCopyService.deepCopy(newModel);
      }
    });
  }
}
