import { Component, Input, OnInit } from '@angular/core';
import { IMessagingGroup } from '../../../../store/messages/messages.interfaces';
import {
  DISCUSSION_FILTER_TYPE,
  discussionTypeToLiteral,
  TASK_MESSAGES_BUBBLE_TYPES,
} from '../../../constants/messages.constants';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-messaging-bubble',
  templateUrl: './messaging-bubble.component.html',
  styleUrls: ['./messaging-bubble.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class MessagingBubbleComponent implements OnInit {
  @Input() isFilled = false;
  @Input() type: TASK_MESSAGES_BUBBLE_TYPES;
  DISCUSSION_TYPE = DISCUSSION_FILTER_TYPE;
  discussionTypeToLiteral = discussionTypeToLiteral;
  TYPES = TASK_MESSAGES_BUBBLE_TYPES;
  constructor() {}

  ngOnInit(): void {}
}
