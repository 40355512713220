import { Pipe, PipeTransform } from '@angular/core';
import { DocumentItem } from '../../store/documents/documents.reducer';
import { IStyledOption, PERMISSION_LITERALS } from '../../framework/constants/documents.constants';
import { OPTIONS } from '../../framework/constants/options-list.constants';
import { CommonPermissionsPipe } from './common-permissions.pipe';
import { OverlayOptionsPipe } from './overlay-options.pipe';

@Pipe({
  name: 'uncommonPermissions',
  standalone: true,
})
export class UncommonPermissionsPipe implements PipeTransform {
  constructor(private optionsPipe: OverlayOptionsPipe) {}

  commonPermissionsPipe = new CommonPermissionsPipe(this.optionsPipe);

  transform(
    allPermissions: IStyledOption[],
    selectedItems: DocumentItem[],
    isPublicLinkUsed: boolean,
    currentFolder: DocumentItem,
  ): OPTIONS[] {
    // get all permissions that are common for all selected items
    const commonPermissions = this.commonPermissionsPipe.transform(
      selectedItems,
      isPublicLinkUsed,
      currentFolder,
    );

    // get all permissions that are not common between all available items and common permissions
    const disabledPermissions = allPermissions.filter(
      (permission) =>
        !commonPermissions.some((commonPermission) => commonPermission.value === permission.value),
    );

    let disabledOptions = disabledPermissions.map((permission) => permission.value);

    // if current folder has permission to modify content
    // allow the following: upload, create new spread sheet and create new word document
    if (currentFolder?.permissions?.[PERMISSION_LITERALS.CAN_MODIFY_CONTENT]) {
      disabledOptions = disabledOptions.filter(
        (option) =>
          option !== OPTIONS.UPLOAD_FILE &&
          option !== OPTIONS.NEW_SPREADSHEET &&
          option !== OPTIONS.NEW_WORD,
      );
    }

    // remove options that we don't have feature for multiple items
    if (selectedItems.length > 1) {
      disabledOptions.push(...[OPTIONS.RENAME, OPTIONS.SHARE]);
    }

    return disabledOptions;
  }
}
