import { Component, Input } from '@angular/core';
import _ from 'lodash';
import { NgIf, NgClass, NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [MatTooltip, NgIf, NgClass, NgStyle],
})
export class ProgressBarComponent {
  private _width = 0;
  private _percent = 0;
  private _totalPercent: number = undefined; // used for 'Contract Credit'
  private maxWidth = 100;
  private minWidth = 0;

  set width(value) {
    this._width =
      value >= this.maxWidth ? this.maxWidth : value <= this.minWidth ? this.minWidth : value;
  }

  get width() {
    return this._width;
  }

  @Input() set percent(value: number) {
    this._percent = value;
    value = _.isNumber(value) ? value : parseInt(String(value), 10);
    this.width = value;
  }

  get percent() {
    return this._percent;
  }

  get finitePercent() {
    return Number.isFinite(this._percent) ? this._percent : 100;
  }

  // used for 'Contract Credit', ex. total % complete of contract is negative and % complete of cost under it are negative
  // when percent and totalPercent are both negative 'Contract Credit' tooltip will show up
  @Input() set totalPercent(value: number) {
    this._totalPercent = value;
  }

  get totalPercent() {
    return this._totalPercent ? this._totalPercent : this._percent;
  }

  constructor() {}

  protected readonly Number = Number;
}
