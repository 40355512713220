<div [ngStyle]="{ 'min-height': listItems.length * 40 + 'px' }" class="options-list-container">
  <div #wrapper class="options-list-animation-wrapper">
    <div class="options-list">
      <div
        (click)="optionSelected(item.value)"
        [ngClass]="{ 'sk-disabled': isDisabled(item) }"
        *ngFor="let item of listItems"
        class="btn-option btn-small"
      >
        <div [class]="[item.class, 'text-wrapper']">
          <span [class]="item.icon"></span>
          <span class="text-value">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
