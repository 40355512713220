<div #beforeContent>
  <ng-content select="[before-content]"></ng-content>
</div>

@if (text && !beforeContent.children.length) {
  <div class="no-leading font-bold w-7 text-size_xs" [ngClass]="[textColor ? textColor : 'text-shade_1']">
    {{ text }}
  </div>
}

<div [pTooltip]="tooltipContent" [tooltipPosition]="tooltipPosition" class="progress-bar" [class]="heightClass">
  <div
    [ngClass]="{
      'allow-bg-color-change': allowBgColorChange,
      done: percent === completePercentage && !isNegative,
      overflow: percent > completePercentage && !isNegative,
      underflow: isNegative
    }"
    [class]="fillClass"
    [ngStyle]="{
      width: width + '%'
    }"
    class="fill"
  ></div>
</div>

<div class="contents" #afterContent>
  <ng-content select="[after-content]"></ng-content>
</div>

@if (!afterContent.children.length) {
  <div
    [ngClass]="{
      'min-w-6': percentWidth === 'default',
      'min-w-11': percentWidth === 'big',
      '!text-color_red_negative': percent > completePercentage,
      negative: percent < 0,
      'text-[12px]': biggerPercentText,
      'text-[8px]': !biggerPercentText
    }"
    class="no-leading font-bold text-shade_1 flex gap-0"
  >
    {{ Math.abs(Math.floor(percent) ?? 0) + '%' }}
  </div>
}

<ng-template #tooltipContent>
  <ng-content select="[tooltip-content]"></ng-content>
</ng-template>
