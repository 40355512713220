<div *ngIf="(isLoading$ | async) && (tasks$ | async).length === 0; else contents" class="m-auto">
  <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
</div>
<ng-template #contents>
  <div class="table-wrapper tasks-table pb-4">
    <div class="grid-table">
      <div class="grid-row header-row">
        <ng-container *ngFor="let tableHeader of taskTableHeader; let index = index">
          <div
            (click)="sortBy(index, tableHeader)"
            [ngClass]="{ 'cursor-pointer': tableHeader.type !== TASK_ORDER_BY.NONE }"
            class="item"
          >
            <span>{{ tableHeader.name }}</span>
            <app-sort-arrow
              *ngIf="tableHeader.type !== TASK_ORDER_BY.NONE"
              [hide]="lastSortedBy !== index"
              [isDesc]="!lastSortedAscending[index]"
            ></app-sort-arrow>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="scrollbar-wrapper">
      <mat-progress-bar
        *ngIf="isTableProgressBarLoading$ | async"
        class="progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
      <ng-scrollbar #scrollbar appearance="compact" class="std-scrollbar" track="vertical">
        <div class="grid-table data">
          <div *ngFor="let task of tasks$ | async; let taskIndex = index" class="grid-row data-row">
            <div
              (click)="taskService.openDetails(task.id)"
              [ngClass]="{ custom: task.type === TASK_TYPE.CUSTOM, daily: task.type === TASK_TYPE.DAILY }"
              class="item title"
            >
              {{ task.title }}
            </div>
            <div class="item">{{ task.end_date ? (task.end_date | datepipe: UTC_DATE) : 'None' }}</div>
            <div class="item">
              <span
                *ngIf="task.requires_response"
                class="icon-warning text-size_icon"
                matTooltip="Response Required"
              ></span>
              <ng-container *ngIf="!task.requires_response">
                <ng-container *ngIf="task.is_spectating">
                  <span class="icon-binoculars text-size_icon text-shade_4" matTooltip="Spectating"></span>
                </ng-container>
                <ng-container *ngIf="!task.is_spectating">
                  <span
                    *ngIf="task.status === TASK_STATUS_VALUE.PENDING"
                    class="icon-lightning-filled text-size_icon text-shade_4"
                    matTooltip="Pending"
                  ></span>
                  <span
                    *ngIf="task.status === TASK_STATUS_VALUE.IN_PROGRESS"
                    class="icon-lightning-filled text-size_icon text-shade_1"
                    matTooltip="In Progress"
                  ></span>
                  <span
                    *ngIf="task.status === TASK_STATUS_VALUE.COMPLETED"
                    class="icon-approved text-size_icon text-color_accept"
                    matTooltip="Completed"
                  ></span>
                </ng-container>
              </ng-container>
            </div>
            <div class="item">
              <ng-container *ngIf="task.checklist">
                <span
                  #trigger="cdkOverlayOrigin"
                  (mouseenter)="showChecklistItem[taskIndex] = true"
                  (mouseleave)="showChecklistItem[taskIndex] = false"
                  [ngClass]="areAllItemsChecked(task.checklist) ? 'text-color_accept' : 'text-shade_4'"
                  cdkOverlayOrigin
                  class="text-size_icon icon-info-filled"
                ></span>
                <app-checklist-popup
                  [(show)]="showChecklistItem[taskIndex]"
                  [checklistItems]="task.checklist.items"
                  [trigger]="trigger"
                ></app-checklist-popup>
              </ng-container>
            </div>
            <div class="item">
              <span
                #trigger="cdkOverlayOrigin"
                (click)="showOptionsList[taskIndex] = true"
                cdk-overlay-origin
                class="icon-options pointer-cursor text-size_icon text-color_secondary"
              ></span>
              <!-- if there are no files then disable the download action-->
              <app-options-list-simple
                (optionSelected)="taskService.optionSelected(task, $event)"
                [(show)]="showOptionsList[taskIndex]"
                [actions]="task | getTasksTableActions"
                [trigger]="trigger"
              ></app-options-list-simple>
            </div>
            <div class="item">{{ task.author?.name }}</div>
            <div class="item">
              <ng-container *ngIf="task.assigned_members?.[0]; else noAssignedMember">
                {{ task.assigned_members[0].user_id === userService.data.id ? 'Me' : task.assigned_members[0].name }}
              </ng-container>
              <ng-template #noAssignedMember>None</ng-template>
            </div>
            <div class="item">{{ task.project ? task.project.title : 'None' }}</div>
            <div class="item">{{ task.files?.length }}</div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>
