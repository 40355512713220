<div class="bubble-wrapper" [ngClass]="{ center: center, 'full-width': !center }">
  <div
    [ngClass]="{
      'bg-accept':
        project?.status == PROJECT_STATUS_ID.AWARDED ||
        project?.status == PROJECT_STATUS_ID.COMPLETED ||
        project?.status == PROJECT_STATUS_ID.IN_PROGRESS,
      'bg-accent': project?.status == PROJECT_STATUS_ID.BIDDING || project?.status == STATUS_CONTRACTOR.BIDDING,
      'bg-cancel':
        project?.status == PROJECT_STATUS_ID.CANCELED ||
        project?.status == PROJECT_STATUS_ID.DELETED ||
        project?.status == PROJECT_STATUS_ID.ARCHIVED ||
        project?.status == STATUS_CONTRACTOR.DELETED ||
        project?.status == STATUS_CONTRACTOR.ARCHIVED,
      'bg-progress':
        project?.status === PROJECT_STATUS_ID.IN_PROGRESS || project?.status == STATUS_CONTRACTOR.IN_PROGRESS,
      'bg-completed': project?.status === PROJECT_STATUS_ID.COMPLETED || project?.status == STATUS_CONTRACTOR.COMPLETED
    }"
    class="round-mark"
  ></div>
  <span *ngIf="!isDropDown && selectedStatus" class="project-status">
    {{ selectedStatus?.name }}
  </span>
  <span *ngIf="!selectedStatus" class="project?-status">-</span>

  <div class="mat-form-field-container" *ngIf="isDropDown && selectedStatus">
    <mat-form-field subscriptSizing="dynamic" class="borderless">
      <mat-select [value]="selectedStatus.id" (valueChange)="changeProjectStatus($event)">
        <mat-option *ngFor="let status of availableStatuses" [value]="status.id">
          <div class="general-selection-text">{{ status.name }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
