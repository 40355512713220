import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { getErrorMessage } from '../../constants/messages.constants';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  addFiles,
  cleanFiles,
  discardMessagingGroupCache,
  loadSearchedMessagingUsers,
  removeFile,
  removeNewGroupDiscussionMember,
  setMessagingView,
  updateNewGroupData,
} from '../../../store/messages/messages.actions';
import { combineLatest, Subject, Subscription } from 'rxjs';
import {
  getFilesToUpload,
  getLastNonDraftSentMessageId,
  getNewDiscussionMembers,
} from '../../../store/messages/messages.selectors';
import { MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { QuickToolbarSettingsModel } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/toolbar-settings-model';
import { IFilesToUpload } from '../discussions-view/discussions-view.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { REST_DRIVE_FILES } from 'src/app/restApi/RestRoutes';
import { NotificationsService } from '../../../services/notifications.service';
import { DiscussionViewCommonComponent } from '../discussion-view-common/discussion-view-common.component';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { MatInput } from '@angular/material/input';
import {
  RichTextEditorComponent,
  RTEConfig,
} from '../../inputs/rich-text-editor/rich-text-editor.component';
import { PresignedFileUploadService } from '../../../services/presigned-file-upload.service';
import { UploadDropDirective } from '../../../directives/upload-drop.directive';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgFor, NgIf } from '@angular/common';
import { MessagingUsersTableComponent } from '../messaging-users-table/messaging-users-table.component';
import { OptionsListGeneralComponent } from '../../overlays/options-list-general/options-list-general.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatFormField, MatError } from '@angular/material/form-field';

@Component({
  selector: 'app-discussions-create',
  templateUrl: './discussions-create.component.html',
  styleUrls: ['./discussions-create.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    CdkOverlayOrigin,
    OptionsListGeneralComponent,
    MessagingUsersTableComponent,
    NgFor,
    MatInput,
    NgIf,
    MatError,
    NgScrollbar,
    UploadDropDirective,
    RichTextEditorComponent,
  ],
})
export class DiscussionsCreateComponent
  extends DiscussionViewCommonComponent
  implements OnInit, OnDestroy
{
  // TODO: make use of inheritance

  @ViewChild('fileUploadSubstitute') fileUploadSubstitute: ElementRef;
  @ViewChild('richTextEditorComponent') editorComponent: RichTextEditorComponent;
  @ViewChild('subjectInput') set subjectInput(elementRef: ElementRef<MatInput>) {
    // here you get access only when element is rendered (or destroyed)
    elementRef?.nativeElement.focus();
    // cdr is needed to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }
  messageForm: UntypedFormGroup;
  getErrorMessage = getErrorMessage;
  editorSetting: RTEConfig = {
    richTextConfigItems: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      this.linkBtn,
      '|',
      this.tagBtn,
      '|',
      this.uploadBtn,
    ],
    enableToolbar: true,
    isReady: false,
    height: '99%',
  };

  filesToUpload$ = this.store.select(getFilesToUpload);
  lastNonDraftSentMessageId$ = this.store.select(getLastNonDraftSentMessageId);
  subscriptions = new Subscription();
  quickToolbarSettings: QuickToolbarSettingsModel = {
    link: ['Open', this.editLinkBtn, 'UnLink'],
  };
  REST_DRIVE_FILES = REST_DRIVE_FILES;
  uploadMetaData: { message_id: number };
  filesToUpload: IFilesToUpload[] = [];
  isDestroyed$: Subject<boolean> = new Subject<boolean>();

  discussionMembers$ = this.store.select(getNewDiscussionMembers);
  showRelatedUsers = true;
  selectedView = MESSAGING_VIEWS.DISCUSSION_CREATE;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notif: NotificationsService,
    protected overlayService: CustomOverlayService,
    protected footerService: MessagingFooterService,
    protected store: Store<AppState>,
    protected userService: CurrentUserService,
    protected domSanitizer: DomSanitizer,
    protected presignedFileUploadService: PresignedFileUploadService,
    private cdr: ChangeDetectorRef,
  ) {
    super(
      overlayService,
      footerService,
      store,
      userService,
      domSanitizer,
      presignedFileUploadService,
    );

    this.messageForm = this.formBuilder.group({
      subject: [''],
      participants: [''],
      message: [''],
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.subscriptions.unsubscribe();
    this.notif.close();
  }

  ngOnInit(): void {
    this.handleChatMembers();
    this.handleUpload();
  }

  registerOption(event: any) {
    if (event.action === 'select') {
      this.store.dispatch(updateNewGroupData({ member: event.user }));
      this.messageForm.get('participants').setValue('');
      return;
    }

    this.store.dispatch(removeNewGroupDiscussionMember({ member: event.user }));
  }

  handleChatMembers() {
    combineLatest([this.searchedMessagingMembers$, this.discussionMembers$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([usersRelated, discussionMembers]) => {
        this.itemOptions = usersRelated.map((user) => user.name);
        this.usersData = {
          usersRelated,
          discussionMembers,
        };
      });
  }

  loadUsers() {
    this.store.dispatch(
      loadSearchedMessagingUsers({ search: this.messageForm.get('participants').value }),
    );
  }

  updateMessagingGroup(event: string, field: string) {
    const body: { message?: string; member?: any; name?: string } = {
      [field]: event,
    };
    this.store.dispatch(updateNewGroupData(body));
  }

  getOpenPosition(dropDownOverlay: HTMLElement) {
    const pos = dropDownOverlay.getClientRects()[0];

    if (this.usersData.discussionMembers.length) {
      return { x: pos?.x, y: pos?.bottom - 10 };
    }
    return { x: pos?.x, y: pos?.y + 50 };
  }

  tagProject() {
    this.editorComponent.insertHashtag();
  }

  createLink() {
    this.editorComponent.createLink();
  }

  editLink() {
    this.editorComponent.editLink();
  }

  uploadFile() {
    this.fileUploadSubstitute.nativeElement.click();
  }

  registerFileAdded(event: any) {
    this.registerFileUpload(event.target.files);
  }

  registerFileUpload(event: File[]) {
    const files = Array.from(event);
    this.store.dispatch(
      addFiles({
        files: [
          ...files.map((file) => {
            return { file, url: this.getFileURL(file) };
          }),
        ],
      }),
    );
  }

  removeFile(file: IFilesToUpload, index: number) {
    this.store.dispatch(removeFile({ file, index }));
  }

  // in case images have to be displayed
  getFileURL(file): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  handleUpload() {
    this.lastNonDraftSentMessageId$.pipe(takeUntil(this.isDestroyed$)).subscribe((id) => {
      this.uploadMetaData = { message_id: id };
      if (this.filesToUpload.length) {
        setTimeout(() => {
          const files = this.filesToUpload.map((f) => f.file);
          this.presignedFileUploadService
            .uploadMultipleFilesToS3(
              files,
              files.map((_) => this.uploadMetaData),
            )
            .subscribe(
              () => {
                this.discard();
              },
              (error) => {
                console.warn(error);
              },
            );
          this.store.dispatch(cleanFiles());
        }, 0);
      }
    });

    this.filesToUpload$.pipe(takeUntil(this.isDestroyed$)).subscribe((files) => {
      this.filesToUpload = files;
    });
  }

  discard() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
    this.store.dispatch(discardMessagingGroupCache());
  }
}
