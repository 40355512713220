<div class="wide-interaction-bar-wrapper">
  <div *ngIf="!(selectedTask$ | async)" class="h-100 flex justify-center items-center">
    <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
  </div>
  <ng-container *ngIf="selectedTask$ | async as task">
    <div class="wide-interaction-bar-content">
      <app-task-details-header [task]="selectedTask$ | async"></app-task-details-header>
      <div
        [ngClass]="{ 'flex-1': isMoreDetailsOpen, 'border-separator': !isMoreDetailsOpen }"
        class="expansion-panel-wrapper flex"
      >
        <mat-expansion-panel
          [(expanded)]="isMoreDetailsOpen"
          class="pure-expansion-panel mat-elevation-z flex-1"
          hideToggle
        >
          <mat-expansion-panel-header>
            <div class="task-info-container task-info-grid flex-1 mt-4">
              <div class="task-info-row">
                <div class="font-semibold">Project</div>
                <ng-container *ngIf="task.project">
                  <div
                    (click)="closeInteractionBar()"
                    [routerLink]="['/webapp', 'projects', task.project.id]"
                    class="project-name"
                  >
                    {{ task.project.title }}
                  </div>
                </ng-container>
                <ng-container *ngIf="!task.project">
                  <div>None</div>
                </ng-container>
              </div>
              <div class="task-info-row">
                <div class="font-semibold">Assigned</div>
                <div>
                  {{ task.assigned_members?.length > 0 ? task.assigned_members[0]?.name : 'None' }}
                  {{ task?.assigned_members?.[0]?.user_id === userService.data?.id ? '(Me)' : '' }}
                </div>
              </div>
              <div class="task-info-row">
                <div class="font-semibold">Due Date</div>
                <div>{{ task.end_date ? (task.end_date | datepipe: DATE_FORMAT : NO_TIMEZONE) : 'None' }}</div>
              </div>
              <div class="task-info-row">
                <div class="font-semibold">Status</div>
                <div class="font-semibold text-capitalize">
                  <span class="text-shade_1">{{ task.status === 'in_progress' ? 'in progress' : task.status }}</span>
                  <span *ngIf="task.requires_response">
                    / <span class="response-required-color">Response Required</span></span
                  >
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div class="task-info-container task-info-grid expansion-panel-content">
              <div class="task-info-row">
                <div class="font-semibold">Spectators</div>
                <div>
                  <span *ngFor="let member of task.spectator_members" class="pr-3.5"
                    >{{ member.name }} {{ member.user_id === userService.data?.id ? '(Me)' : '' }}</span
                  >
                </div>
              </div>
              <div class="task-info-row">
                <div class="font-semibold">Attachments</div>
                <div class="flex gap-4 flex-wrap">
                  <app-file-thumbnail *ngFor="let file of task.files" [file]="file"></app-file-thumbnail>
                  <span *ngIf="!task.files?.length">No files</span>
                </div>
              </div>
              <div *ngIf="task.summary" class="task-info-row">
                <div class="font-semibold">Summary</div>
                <div class="summary-container">
                  <span class="summary-content">{{ task.summary }}</span>
                </div>
              </div>
              <div *ngFor="let customField of task.custom_fields" class="task-info-row">
                <div class="font-semibold">{{ customField.label }}</div>
                <div>
                  <ng-container [ngSwitch]="customField.type">
                    <ng-container *ngSwitchCase="CUSTOM_TASK_FIELD_TYPES.TEXT">
                      {{ customField.value }}
                    </ng-container>
                    <ng-container *ngSwitchCase="CUSTOM_TASK_FIELD_TYPES.DATE">
                      {{ customField.value | datepipe: DATE_FORMAT : NO_TIMEZONE }}
                    </ng-container>
                    <ng-container *ngSwitchCase="CUSTOM_TASK_FIELD_TYPES.QUESTION">
                      {{ customField.value ? 'Yes' : 'No' }}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="task.checklist" class="checklist-container pt-3">
              <div class="task-info-grid flex-1">
                <div class="task-info-row">
                  <div class="font-semibold">Checklist</div>
                  <div>{{ getCheckedItems(task.checklist.items) }} of {{ task.checklist.items.length }}</div>
                </div>
              </div>
              <div class="checklist-body pt-3 task-info-grid">
                <div
                  (click)="toggleCheckListItem(item)"
                  *ngFor="let item of task.checklist.items"
                  class="task-info-row checklist-item cursor-pointer"
                >
                  <span
                    [ngClass]="{ 'text-shade_4': !item.value, 'text-color_accept': item.value }"
                    class="icon-approved icon text-size_icon pl-4"
                  ></span>
                  <span class="text-shade_1 name"> {{ item.name }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <app-arrow-button-box
          (clicked)="isMoreDetailsOpen = !isMoreDetailsOpen"
          [direction]="isMoreDetailsOpen ? 'up' : 'down'"
          [isFilled]="isMoreDetailsOpen"
          class="pointer-cursor self-end leading-none"
          thickness="light"
        ></app-arrow-button-box>
      </div>
      <div [ngClass]="{ 'flex-1': !isMoreDetailsOpen }">
        <app-discussions-view
          *ngIf="(selectedMessagingView$ | async) === MESSAGING_VIEWS.DISCUSSION_VIEW"
          [hideSentMessages]="isMoreDetailsOpen"
          [isTaskView]="true"
        ></app-discussions-view>
        <app-discussion-thread-view
          *ngIf="(selectedMessagingView$ | async) === MESSAGING_VIEWS.DISCUSSION_THREAD_VIEW"
        ></app-discussion-thread-view>
      </div>
    </div>
    <app-messages-footer></app-messages-footer>
  </ng-container>
</div>
