import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-bordered-dropdown-icon',
  standalone: true,
  imports: [NgClass],
  template: `
    <span
      class="dropdown-icon"
      [ngClass]="{
        closed: isClosed || useAsBackButton,
        opened: !isClosed,
        'rotate-90 cursor-pointer hover:bg-color_secondary hover:after:text-white': useAsBackButton
      }"
    ></span>
  `,
  styleUrls: ['./bordered-dropdown-icon.component.scss'],
})
export class BorderedDropdownIconComponent {
  @Input({ required: true }) isClosed: boolean;
  @Input() fill: boolean = false;
  @Input() useAsBackButton: boolean = false;
}
