<!--*ngIf="!(isLoading$ | async) && (groups$ | async).length !== 0"-->
<div class="d-flex h-100 box-border pb-2" *ngIf="!(isLoading$ | async) || (groups$ | async)?.length !== 0">
  <mat-progress-bar
    class="custom-loading-progress-bar"
    mode="indeterminate"
    *ngIf="(isLoading$ | async) && (groups$ | async)?.length !== 0"
  ></mat-progress-bar>
  <ng-scrollbar #scrollbar class="std-scrollbar">
    <app-discussions-list-item *ngFor="let group of groups$ | async" [group]="group"></app-discussions-list-item>
  </ng-scrollbar>
  <div *ngIf="(groups$ | async)?.length === 0" class="mt-4 text-center color-secondary">No discussions.</div>
</div>
<div class="h-100 flex justify-center items-center" *ngIf="(isLoading$ | async) && (groups$ | async)?.length === 0">
  <span class="icon-refresh animate-spin pic-loading text-size_title_xl text-shade_2"></span>
</div>
