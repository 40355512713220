import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MessagesApiService } from './messages-api.service';
import * as moment from 'moment';
// please leave pusher imports as they are and don't combine them
import * as PusherTypes from 'pusher-js';
import Pusher from 'pusher-js';
import { REST_PUSHER_AUTH } from '../restApi/RestRoutes';
import { StorageService } from './storage.service';
import { CurrentUserService } from './current-user.service';
import { environment } from '../../environments/environment';
import { AppState } from '../store/app-state';
import { Store } from '@ngrx/store';
import { getSelectedGroupId } from '../store/messages/messages.selectors';
import {
  addGroupMessage,
  gotMessageOnSocketUpdateIt,
  loadFirstPageOfGroups,
  loadSingleProfilePicture,
  messagesActions,
  setMessagingView,
  updateNewGroupData,
} from '../store/messages/messages.actions';
import { Subject } from 'rxjs';
import {
  IMessage,
  IMessagingMember,
  IMessagingUser,
  MESSAGING_VIEWS,
} from '../store/messages/messages.interfaces';
import { takeUntil } from 'rxjs/operators';
import { InteractionBarStateService } from './interaction-bar-state.service';
import { INTERACTION_BAR_STATES } from '../framework/constants/interaction-bar.constants';
import { NotificationsStateService } from './notifications-state.service';
import { User } from '../framework/constants/user.constants';

const APP_CLUSTER = 'us3';

@Injectable({
  providedIn: 'root',
})
export class MessagesStateService implements OnDestroy {
  static connectUser$ = new Subject();
  channel: PusherTypes.Channel;

  messages = [];
  received = [];
  sent = [];

  $selectedGroupId = this.store.select(getSelectedGroupId);
  selectedGroupId: number;

  refresh = new EventEmitter();
  updateMessage$ = new Subject<IMessage>();
  addMessage$ = new Subject<{
    messages: Partial<IMessage>[];
    shouldScrollToBottom: boolean;
    messageFromOtherUser?: boolean;
  }>();
  filteredMessages = new Subject<{ messages: IMessage[]; filters: { search: string } }>();
  pusher: Pusher;
  isDestroyed$ = new Subject<void>();

  constructor(
    private messagesApi: MessagesApiService,
    private storage: StorageService,
    private user: CurrentUserService,
    private store: Store<AppState>,
    private barState: InteractionBarStateService,
    private notifService: NotificationsStateService,
  ) {
    if (this.channel) {
      return;
    }
    console.log('messaging service constructor');
    this.connectUser();
    this.$selectedGroupId
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((id) => (this.selectedGroupId = id));
    MessagesStateService.connectUser$.pipe(takeUntil(this.isDestroyed$)).subscribe((data) => {
      this.connectUser();
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  connectUser() {
    try {
      if (this.pusher) {
        this.pusher.disconnect();
        console.log('disconnect user');
      }
      console.log('connect user');
      this.pusher = new Pusher(environment.pusher_key, {
        cluster: APP_CLUSTER,
        authEndpoint: REST_PUSHER_AUTH,
        auth: {
          headers: {
            Authorization: `Bearer ${this.storage.getToken()}`,
          },
        },
      });

      this.channel = this.pusher.subscribe(`private-user.notifications.${this.user.data.id}`);

      this.channel.bind('notification.received', (data) => {
        this.notifService.gotNewNotificationOnSocket(data?.notification);
      });

      this.channel.bind('message.received', (data: { message: IMessage }) => {
        console.warn('msg received', data);
        if (this.selectedGroupId === data.message.group_id) {
          // update/add only the messages which are in the selected group
          this.store.dispatch(
            loadSingleProfilePicture({ user: data.message.user as IMessagingUser }),
          );
          if (data.message.user.id === this.user.data?.id && !data.message.is_announcement) {
            // if got back the message on socket that the current user sent and is not announcement, update the message
            this.updateMessage$.next(data.message);
            this.store.dispatch(gotMessageOnSocketUpdateIt({ message: data.message }));
          } else {
            this.addMessage$.next({
              messages: [data.message],
              shouldScrollToBottom: true,
              messageFromOtherUser: true,
            });
            this.store.dispatch(addGroupMessage({ message: data.message }));
          }
        }
        if (data.message.user.id !== this.user.data?.id) {
          // if got a message from another user
          this.store.dispatch(messagesActions.newMessageGotOnSocket({ message: data.message }));
        }
      });

      this.channel.bind('message.updated', (data) => {
        console.warn('update message', data);
        if (data.message) {
          this.updateMessage$.next(data.message);
          this.store.dispatch(
            gotMessageOnSocketUpdateIt({ message: data.message, isDraft: false }),
          );
        }
      });
    } catch (e) {
      this.user.data = this.storage.getUserData();
      if (!this.user.data) {
        return;
      }
      setTimeout(() => {
        this.connectUser();
      }, 500);
    }
  }

  retrieveMessages(): Promise<any> {
    return new Promise((res, rej) => {
      this.messagesApi.getMessagesSent().then(
        (sent) => {
          this.sent = sent;
          this.messagesApi.getMessagesReceived().then(
            (rec) => {
              this.received = rec;
              this.messages = [...sent, ...rec];
              res(this.messages);
            },
            (err) => {
              rej(err);
            },
          );
        },
        (err) => {
          rej(err);
        },
      );
    });
  }

  getUsers() {
    const userId = new Set<number>();
    const users = [];
    this.received.forEach((message) => {
      if (!userId.has(message.from_uid) && message?.from) {
        message.from.queryed_user = message.from_uid;
        users.push(message.from);
        userId.add(message.from_uid);
      }
    });

    this.sent.forEach((message) => {
      if (!userId.has(message.to_uid) && message?.to) {
        message.to.queryed_user = message.to_uid;
        users.push(message.to);
        userId.add(message.to_uid);
      }
    });

    users.forEach((user) => {
      const userMessages = this.getMessages(user.queryed_user);
      if (userMessages[0]) {
        user.lastMessage = userMessages[userMessages.length - 1].message;
        user.lastSeen = userMessages[userMessages.length - 1].status_message === 'seen';
        user.lastMessageDate = userMessages[userMessages.length - 1].created_at;
        user.lastReceivedSeen =
          (userMessages[userMessages.length - 1].status_message === 'seen' &&
            userMessages[userMessages.length - 1].isFrom === true) ||
          userMessages[userMessages.length - 1].isFrom === false;
      } else {
        user.lastMessage = userMessages;
        user.lastSeen = false;
      }
    });

    return users.sort(this.sortUsersByLastConversation);
  }

  getMessages(userId) {
    const messages = [];
    this.received.forEach((message) => {
      if (message.from_uid === userId) {
        message.isFrom = true;
        // does only contain the formatted message creation time
        message.createdDate = moment.utc(message.created_at).local().format('h:mm a');
        messages.push(message);
      }
    });

    this.sent.forEach((message) => {
      if (message.to_uid === userId) {
        message.isFrom = false;
        message.createdDate = moment.utc(message.created_at).local().format('h:mm a');
        // new Date(new Date(message.created_at).getTime() - new Date(message.created_at).getTimezoneOffset() * 60 * 1000).toLocaleString();
        messages.push(message);
      }
    });

    return messages.sort(this.sortByCreateDate);
  }

  sortByCreateDate(a, b) {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortUsersByLastConversation(user_a, user_b) {
    const dateA = new Date(user_a.lastMessageDate);
    const dateB = new Date(user_b.lastMessageDate);
    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  }

  optimisticAdd(message: any, to) {
    const optimisticMsg = {
      message,
      from: this.user.data,
      from_uid: this.user.data.id,
      status: 0,
      status_message: 'unseen',
      is_optimistic: true,
      to_uid: to,
      created_at: new Date().toISOString(),
    };

    this.sent.push(optimisticMsg);
  }

  optimisticRemove(message: any) {
    this.sent = this.sent.filter((o) => o.message !== message && o.is_optimistic);
  }

  /**
   * opens the messaging interaction bar and reloads groups
   * clearOldGroups should be set to true if called from task details
   * because when opening the task view it sets the messaging groups to
   * contain only the tasks' group
   * @param clearOldGroups
   */
  openMessagesInteractionBar(clearOldGroups?: boolean) {
    this.store.dispatch(loadFirstPageOfGroups({ clearOldGroups }));
    this.store.dispatch(messagesActions.setOverallMessagingUnreadStatus({ isUnread: false }));
    this.barState.announceState.next({ action: INTERACTION_BAR_STATES.MESSAGES });
  }

  /**
   * Opens the messaging interaction bar with the selected person as the addressee.
   * @param addressee
   */
  newMessageTo(addressee: User) {
    this.barState.announceState.next({ action: INTERACTION_BAR_STATES.MESSAGES });
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_CREATE }));
    const member = this.buildMessagingMember(addressee, this.user.data);
    this.store.dispatch(updateNewGroupData({ member }));
  }

  /**
   * Builds a messaging member object where the member is the addressee and the creator is the current user.
   * @param addressee
   * @param currentUser
   */
  buildMessagingMember(addressee: User, currentUser: User): IMessagingMember {
    return {
      user_id: addressee.id + '',
      name: `${addressee.first_name} ${addressee.last_name}`,
      company_name: addressee.company_name,
      is_team_member: false,
      email: addressee?.email,
      is_admin: true,
      added_by: {
        user_id: String(currentUser.id),
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        company_name: currentUser.company_name,
      },
    };
  }
}
