import { Pipe, PipeTransform } from '@angular/core';
import {
  allItemOptions,
  IStyledOption,
  permissionLiteralToOptions,
} from '../../framework/constants/documents.constants';
import { OPTIONS } from '../../framework/constants/options-list.constants';

@Pipe({
  name: 'basePermissions',
  standalone: true,
})
export class BasePermissionsPipe implements PipeTransform {
  allItemOptions = allItemOptions;
  permissionLiteralToOptions = permissionLiteralToOptions;
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  findOption(option: OPTIONS): IStyledOption {
    return this.allItemOptions.find((op) => op.value === option);
  }
}
