// todo: rename to team-management-sidebar
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MatCheckbox } from '@angular/material/checkbox';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { SimpleButtonComponent } from '../../buttons/simple-medium-button/simple-button.component';
import { SearchInputComponent } from '../../search-input/search-input.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { ArrowButtonComponent } from '../../buttons/arrow-button/arrow-button.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-state/index';
import { NotificationsService } from '../../../services/notifications.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { teamManagementActions } from '../../../store/team-management/team-management.actions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  areAllAvailablePropertiesCheckboxesSelected,
  areAllProjectCheckboxesSelected,
  getCheckedProjects,
  getCheckedProperties,
  getFilteredSelectedProperties,
  getIsProjectChecked,
  getIsPropertyChecked,
  getSelectedMember,
  getTeamManagementFilteredProperties,
  getTeamManagementProperties,
  getTeamManagementRoles,
  isOwnerEdited,
  isSelectedMemberFetching,
  teamManagementSidebarViewType,
} from '../../../store/team-management/team-management.selectors';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import {
  IProject,
  IProjectProperty,
  ITeamMember,
  ROLE_TYPE,
  TEAM_MANAGEMENT_SIDEBAR_PAGES,
  TEAM_MANAGEMENT_SIDEBAR_VIEW,
} from 'src/app/store/team-management/team-management.interfaces';
import { DeepCopyService } from '../../../services/deep-copy.service';

@Component({
  selector: 'app-manage-team-members-sidebar',
  templateUrl: './manage-team-members-sidebar.component.html',
  styleUrls: ['./manage-team-members-sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    TitleCasePipe,
    FormsModule,
    InputTextModule,
    MatCheckbox,
    DropdownComponent,
    BackButtonBoxComponent,
    FloatingInputComponent,
    CheckboxModule,
    TooltipModule,
    SimpleButtonComponent,
    SearchInputComponent,
    NgScrollbar,
    ArrowButtonComponent,
  ],
})
export class ManageTeamMembersSidebarComponent implements OnInit, OnDestroy {
  private viewType$: Observable<TEAM_MANAGEMENT_SIDEBAR_VIEW> = this.store.select(
    teamManagementSidebarViewType,
  );

  TEAM_MANAGEMENT_SIDEBAR_PAGES = TEAM_MANAGEMENT_SIDEBAR_PAGES;

  getSelectedMember$ = this.store.select(getSelectedMember);

  model: Partial<ITeamMember> = {
    limit_to_templates: true,
  };
  roles$ = this.store
    .select(getTeamManagementRoles)
    .pipe(map((roles) => roles.map((role) => ({ label: role, value: role }))));
  properties$ = this.store.select(getTeamManagementProperties);

  _currentPage = TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE;
  set currentPage(newPage: TEAM_MANAGEMENT_SIDEBAR_PAGES) {
    if (newPage < 0 || newPage > TEAM_MANAGEMENT_SIDEBAR_PAGES.LAST_PAGE) {
      return;
    }
    this.refreshTitle(newPage);
    this._currentPage = newPage;
  }
  get currentPage() {
    return this._currentPage;
  }

  sidebarTitle$ = new BehaviorSubject('');
  getIsOwnerEdited$ = this.store.select(isOwnerEdited); // currently not used, might be useful in the future

  isDropdownShown = {
    homeBase: false,
    role: false,
  };

  projectsSearch = '';
  // teams: Observable<ITeam[]> = this.store.select(getAllTeams);
  checkedProperties$ = this.store.select(getCheckedProperties);
  filteredProperties$ = this.store.select(getTeamManagementFilteredProperties);
  areAllProjectCheckboxesSelected$ = this.store.select(areAllProjectCheckboxesSelected);
  selectedProjects$ = this.store.select(getCheckedProjects);
  selectedProperties$ = this.store.select(getFilteredSelectedProperties);
  isSelectedMemberFetching$ = this.store.select(isSelectedMemberFetching);
  projectSearch$ = new Subject<string>();
  propertySearch$ = new Subject<string>();
  isCurrentUserEdited = false;
  viewType = TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER;

  @ViewChild('firstPageForm') formFirstPage: NgForm;
  @ViewChild('secondPageForm') formSecondPage: NgForm;
  @ViewChild('thirdPageForm') formThirdPage: NgForm;

  allPropertyChecked: boolean;
  protected readonly ROLE_TYPE = ROLE_TYPE;
  protected readonly TEAM_MANAGEMENT_SIDEBAR_VIEW = TEAM_MANAGEMENT_SIDEBAR_VIEW;

  private isDestroyed$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
    private currentUser: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.setupTeamMemberForm();
    this.store.dispatch(teamManagementActions.loadProjectPropertiesFromBackend());

    this.projectSearch$
      .pipe(debounceTime(300))
      .subscribe((searchText) =>
        this.store.dispatch(teamManagementActions.filterProjectsBySearch({ searchText })),
      );

    this.propertySearch$
      .pipe(debounceTime(300))
      .subscribe((searchText) =>
        this.store.dispatch(teamManagementActions.filterPropertiesBySearch({ searchText })),
      );

    this.store
      .select(areAllAvailablePropertiesCheckboxesSelected)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value) => {
        this.allPropertyChecked = value;
      });

    this.viewType$.pipe(takeUntil(this.isDestroyed$)).subscribe((viewType) => {
      this.viewType = viewType;
      this.refreshTitle(this.currentPage);
    });
  }
  getCheckedProperties = (id) => this.store.select(getIsPropertyChecked(id));
  getCheckedProjects = (id) => this.store.select(getIsProjectChecked(id));
  propertyCheckboxChanged(checked: boolean, property: IProjectProperty) {
    this.store.dispatch(teamManagementActions.checkProperty({ property, checked }));
  }

  projectCheckboxChanged(checked: boolean, project: IProject) {
    this.store.dispatch(teamManagementActions.checkProject({ project, checked }));
  }

  filterProperties(value: string) {
    this.propertySearch$.next(value);
  }

  filterProjects(value: string) {
    this.projectSearch$.next(value);
  }
  incrementPage() {
    // todo
    switch (this.currentPage) {
      case TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE:
        if (this.formFirstPage.valid) {
          this.currentPage += 1;
          break;
        }
        this.notif.showError('Please fill in all required fields');
        this.formFirstPage.form.markAllAsTouched();
        break;
      case TEAM_MANAGEMENT_SIDEBAR_PAGES.SECOND_PAGE:
        this.checkedProperties$.pipe(take(1)).subscribe((selectedProperties) => {
          if (!selectedProperties.length) {
            this.notif.showError('Please select at least one property');
            return;
          }
          if (this.formSecondPage.valid) {
            this.currentPage += 1;
            return;
          }
          this.formSecondPage.form.markAllAsTouched();
        });

        break;
      default:
        console.warn('Unhandled page page increment');
        break;
    }
  }

  allPropertiesCheckboxChanged(isChecked: boolean) {
    this.store.dispatch(teamManagementActions.checkAllProperties({ checked: isChecked }));
  }
  allProjectsCheckboxChanged(isChecked: boolean) {
    this.store.dispatch(teamManagementActions.checkAllProjects({ checked: isChecked }));
  }

  propertyChanged(event) {
    this.store.dispatch(
      teamManagementActions.loadTeamRolesFromPropertyChange({ property_id: event }),
    );
  }

  saveMember() {
    this.store.dispatch(
      teamManagementActions.addMemberToBackend({ member: DeepCopyService.deepCopy(this.model) }),
    );
  }

  editMember() {
    this.store.dispatch(
      teamManagementActions.modifyMemberOnBackend({ member: DeepCopyService.deepCopy(this.model) }),
    );
  }

  handleSave() {
    if (this.isCurrentUserEdited) {
      this.save();
      return;
    }

    if (!this.formThirdPage.valid) {
      this.formThirdPage.form.markAllAsTouched();
    }

    this.save();
  }

  save() {
    if (this.viewType === TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER) {
      this.saveMember();
    } else {
      this.editMember();
    }
  }

  private setupTeamMemberForm() {
    this.getSelectedMember$
      .pipe(
        filter((member) => !!member?.id),
        take(1),
      )
      .subscribe((member) => {
        if (!member) {
          return;
        }
        this.model = DeepCopyService.deepCopy(member);
        if (member?.home_base?.id) {
          this.model.home_base_id = member.home_base.id;
        }
        this.store.dispatch(
          teamManagementActions.setMemberProperties({
            propertyIds: member?.properties?.map((p) => p.id) ?? [],
          }),
        );
        this.store.dispatch(
          teamManagementActions.setMemberProjects({
            projectIds: member?.projects?.map((p) => p.id) ?? [],
          }),
        );
        this.isCurrentUserEdited =
          this.currentUser.data?.email === this?.model?.email &&
          this.viewType === TEAM_MANAGEMENT_SIDEBAR_VIEW.EDIT_TEAM_MEMBER;
      });
  }

  setIsDropdownShown(name: string, isShown: boolean) {
    this.isDropdownShown[name] = isShown;
  }

  handleTemplatesLimit() {
    if (this.model.role === ROLE_TYPE.SPECTATOR) {
      this.model.limit_to_templates = false;
    }
  }

  private refreshTitle(page: TEAM_MANAGEMENT_SIDEBAR_PAGES) {
    let title = '';
    switch (page) {
      case TEAM_MANAGEMENT_SIDEBAR_PAGES.FIRST_PAGE:
        title =
          this.viewType === TEAM_MANAGEMENT_SIDEBAR_VIEW.ADD_TEAM_MEMBER
            ? 'Add Team Member'
            : 'Edit Team Member';
        break;
      case TEAM_MANAGEMENT_SIDEBAR_PAGES.SECOND_PAGE:
        title = 'Assign Properties';
        break;
      case TEAM_MANAGEMENT_SIDEBAR_PAGES.LAST_PAGE:
        title = 'Assign Projects';
        break;
      default:
        return;
    }
    this.sidebarTitle$.next(title);
  }

  ngOnDestroy() {
    this.store.dispatch(teamManagementActions.clearSelectedTeamMember());
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
    this.propertySearch$.complete();
    this.projectSearch$.complete();
  }
}
