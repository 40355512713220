import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-days-count',
  standalone: true,
  imports: [NgClass],
  templateUrl: './days-count.component.html',
  styleUrl: './days-count.component.scss',
})
export class DaysCountComponent {
  @Input() dayCount: number;
  @Input() showText = true;
  @Input() size: 'default' | 'big' = 'default';
  protected readonly Math = Math;
}
