import { ContractorInterface } from './contractor.interface';
import { OPTIONS } from '../../../../../framework/constants/options-list.constants';

export class ContractorContractor implements ContractorInterface {
  statusAcceptedTxt = 'Proposal Awarded';
  statusDeclinedTxt = 'Proposal Dismissed';
  statusPendingTxt = 'Proposal Submitted';
  statusScheduled = 'Visit Scheduleld';
  statusNotScheduled = 'Visit Not Scheduled';

  optionsVisitScheduled = [OPTIONS.RESCHEDULE, OPTIONS.SHARE, OPTIONS.MESSAGE, OPTIONS.INFO];
  optionsContractor = [OPTIONS.SCHEDULE, OPTIONS.MESSAGE, OPTIONS.DISMISS];
  optionsBidSubmitted = [OPTIONS.SCHEDULE, OPTIONS.MESSAGE, OPTIONS.INFO];
  optionsAwarded = [OPTIONS.INFO];
  optionsAutoInvited = [OPTIONS.REFUSE];
}
