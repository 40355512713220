import { Pipe, PipeTransform } from '@angular/core';
import { DocumentItem } from '../../store/documents/documents.reducer';
import { OVERLAY_TYPES, OverlayOptionsPipe } from './overlay-options.pipe';
import { OPTIONS } from '../../framework/constants/options-list.constants';
import { IStyledOption } from '../../framework/constants/documents.constants';
import { isObject } from 'lodash';

export enum PERMISSION_TYPES {
  DRIVE,
}

@Pipe({
  name: 'drivePermissions',
  standalone: true,
})
export class DrivePermissionsPipe implements PipeTransform {
  constructor(private optionsPipe: OverlayOptionsPipe) {}

  transform(items: DocumentItem[], type: PERMISSION_TYPES, ...args: unknown[]): boolean {
    const option = args[0] as OPTIONS | IStyledOption;
    const isPublicLinkUsed = args[1];
    const currentFolder = args[2] as DocumentItem;
    if (!items.length) {
      return this.hasDriveActionPermission(new Set(), option as OPTIONS | IStyledOption);
    }

    switch (type) {
      case PERMISSION_TYPES.DRIVE: {
        let permissions = new Set<OPTIONS | IStyledOption>();
        const unfilteredPermissions = [];
        items.forEach((item) => {
          unfilteredPermissions.push(
            this.optionsPipe.transform(item, OVERLAY_TYPES.DRIVE, isPublicLinkUsed, currentFolder),
          );
        });
        let filteredArray = unfilteredPermissions[0];
        unfilteredPermissions.forEach((unfiltered) => {
          filteredArray = this.getCommon(filteredArray, unfiltered);
        });
        permissions = new Set<OPTIONS | IStyledOption>([...permissions, ...filteredArray]);
        return this.hasDriveActionPermission(permissions, option);
      }
      default:
        return this.hasDriveActionPermission(new Set(), option);
    }
  }

  private getCommon(array1, array2) {
    return array1.filter((value) => array2.includes(value));
  }

  private hasDriveActionPermission(
    options: Set<OPTIONS | IStyledOption | []>,
    action: OPTIONS | IStyledOption,
  ): boolean {
    const optionsArr = Array.from(options);
    return !!optionsArr.find((option) => {
      if (isObject(option)) {
        return (option as IStyledOption).value === action;
      }
      return option === action;
    });
  }
}
