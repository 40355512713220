import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ISimpleProject,
  Project,
  ProjectStatus,
} from '../../pages/webapp/projects/projects.interface';
import { projectActions } from './projects.actions';

export const projectsFeatureKey = 'projects';

export interface ProjectsState extends EntityState<Project> {
  isLoaded: boolean;
  areSimpleProjectsLoading: boolean;
  simpleProjects: ISimpleProject[];
  statuses: ProjectStatus[];
}

export const projectsAdapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export const initialState = projectsAdapter.getInitialState({
  isLoaded: false,
  areSimpleProjectsLoading: true,
  simpleProjects: [],
  statuses: [],
});
export const { selectAll, selectIds } = projectsAdapter.getSelectors();

export const projectsReducer = createReducer(
  initialState,
  on(projectActions.allProjectsLoadedSuccessfully, (state, action) => {
    return projectsAdapter.setAll(action.projects, { ...state, isLoaded: true });
  }),
  on(projectActions.simpleProjectsLoadedSuccessfully, (state, action) => {
    return { ...state, areSimpleProjectsLoading: false, simpleProjects: action.projects };
  }),
  on(projectActions.clearProjects, (state) => {
    return { ...initialState };
  }),
  on(projectActions.statusesLoadedSuccessfully, (state, action) => {
    return { ...state, statuses: action.statuses };
  }),
);
