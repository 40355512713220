import { createAction, props } from '@ngrx/store';
import {
  IActivityProgressItem,
  IItemTrackingChecklist,
  IItemTrackingSidebar,
} from './item-tracking.reducer';

const setActiveProject = createAction(
  '[activities] set activity project id',
  props<{ projectId: number }>(),
);

const deleteChecklist = createAction(
  '[activities] delete activity checklist',
  props<{ id: number }>(),
);

const deleteProgressItem = createAction(
  '[activities] delete progress item',
  props<{ id: number }>(),
);

const loadAllChecklist = createAction(
  '[activities] load activity checklist',
  props<{ withTrash: boolean }>(),
);

const setChecklist = createAction(
  '[activities] set all checklist',
  props<{ checklist: IItemTrackingChecklist[] }>(),
);

const cancel = createAction('[activities] cancel');
const deleteChecklistFromBackend = createAction('[activities] delete checklist from backend');
const clearDeletedChecklist = createAction('[activities] clear deleted checklist');

const saveTrackingItem = createAction(
  '[activities] save tracking item',
  props<{ trackingItem: IItemTrackingSidebar }>(),
);

const saveProgressItemFromList = createAction(
  '[activities] save tracking item list',
  props<{ trackingItem: IActivityProgressItem; reloadData?: boolean }>(),
);

const editTrackingItemSidebar = createAction(
  '[activities] edit tracking item sidebar',
  props<{ id: number }>(),
);

const setIsLoading = createAction('[activities] is loading', props<{ isLoading: boolean }>());

const selectTrackingItemToEdit = createAction(
  '[activities] select tracking item to edit',
  props<{ item: IItemTrackingSidebar }>(),
);

const successfullySavedTrackingItem = createAction('[activities] successfully saved tracking item');

const loadAllProgressItems = createAction('[activities] load all progress items');
const setAllProgressItems = createAction(
  '[activities] load progress items',
  props<{ progressItems: IActivityProgressItem[] }>(),
);

const setProgressItemsLoadFilter = createAction(
  '[activities] set progress items load filter',
  props<{ filter: { [p: string]: boolean } }>(),
);

const resetProgressItemsState = createAction('[activities] reset progress items');
const clearProgressItemsList = createAction('[activities] clear progress items list');

export const itemTrackingActions = {
  setActiveProject,
  setIsLoading,
  loadAllChecklist,
  setChecklist,
  deleteChecklist,
  deleteChecklistFromBackend,
  clearDeletedChecklist,
  cancel,
  saveTrackingItem,
  saveProgressItemFromList,
  successfullySavedTrackingItem,
  editTrackingItemSidebar,
  loadAllProgressItems,
  setAllProgressItems,
  selectTrackingItemToEdit,
  deleteProgressItem,
  setProgressItemsLoadFilter,
  resetProgressItemsState,
  clearProgressItemsList,
};
