<div class="selection-container">
  <!--  (mouseover)="hoverAction($event, i)"-->
  <div
    class="selection-item"
    *ngFor="let hour of availabilityHours; let i = index"
    [ngClass]="{
      'selection-item-with-schedule':
        userApi.isManager &&
        barState.type !== INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR &&
        hour.available &&
        hour.status === 'visit' &&
        !isIntervalPast(hour, 'end')
    }"
  >
    <!--  // 'disabled': curentDate == null || (!hour.status && hour.status != 0)-->

    <div class="wrapper" *ngIf="i + 1 < availabilityHours.length">
      <div
        class="hour"
        [ngClass]="{ visit: hour.available && hour.status === 'visit' && !isIntervalPast(hour, 'end') }"
      >
        {{ hour.start }} - {{ hour.end }} <br />
        <span
          class="contractor-name"
          *ngIf="userApi.isManager && hour.status == 'visit' && !isIntervalPast(hour, 'end')"
        >
          {{ hour.name }}
        </span>
      </div>

      <div
        class="cancel-wrapper"
        *ngIf="
          userApi.isManager &&
          barState.type !== INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR &&
          hour.available &&
          hour.status === 'visit'
        "
        (click)="registerCheckbox(hour, $event, true)"
      >
        <br />
        <span *ngIf="!isIntervalPast(hour, 'end')" class="btn-small cancel-button">Cancel</span>
      </div>

      <!--      MANAGER TOGGLE NONE TYPE-->
      <div class="status" *ngIf="userApi.isManager && barState.type === INTERACTION_BAR_STATES.NONE">
        <span *ngIf="!hour.available && hour.status !== 'visit' && hour.status !== 'loading' && !isIntervalPast(hour)"
          >Available</span
        >
        <span *ngIf="hour.available && hour.status === 'visit' && !isIntervalPast(hour, 'end')">Scheduled</span>
        <span *ngIf="hour.status == 'loading'" class="icon-refresh animate-spin"></span>
      </div>
      <mat-slide-toggle
        color="accent"
        *ngIf="userApi.isManager && barState.type === INTERACTION_BAR_STATES.NONE"
        [checked]="!hour.available && !isIntervalPast(hour)"
        (change)="registerCheckbox(hour, $event)"
        [disabled]="curentDate == null || hour.status == 'visit' || hour.disable == true || isIntervalPast(hour)"
      >
      </mat-slide-toggle>

      <!--      CONTRACTOR TOGGLE CONTRACTOR VIEW -->
      <div
        class="status"
        *ngIf="
          !userApi.isManager &&
          (barState.type === INTERACTION_BAR_STATES.SCHEDULE || barState.type === INTERACTION_BAR_STATES.RE_SCHEDULE)
        "
      >
        <span *ngIf="!hour.available && hour.status !== 'visit' && hour.status !== 'loading' && !isIntervalPast(hour)"
          >Available</span
        >
        <span *ngIf="hour.available && hour.status === 'visit' && !isIntervalPast(hour, 'end')">Scheduled</span>
        <span *ngIf="hour.status == 'loading'" class="icon-refresh animate-spin"></span>
      </div>
      <mat-slide-toggle
        color="accent"
        [ngClass]="hour.available === true ? 'trueClass' : 'falseClass'"
        *ngIf="
          !userApi.isManager &&
          (barState.type === INTERACTION_BAR_STATES.SCHEDULE || barState.type === INTERACTION_BAR_STATES.RE_SCHEDULE)
        "
        [checked]="isChecked(hour) && !isIntervalPast(hour, 'end')"
        (change)="registerCheckbox(hour, $event)"
        [disabled]="
          curentDate == null ||
          (!hour.status && hour.status != 0) ||
          hour.disable == true ||
          (hour.status === 'visit' && isIntervalPast(hour, 'end')) ||
          (hour.status !== 'visit' && isIntervalPast(hour, 'start'))
        "
      >
      </mat-slide-toggle>

      <!--      CONTRACTOR TOGGLE MANAGER VIEW -->
      <div
        class="status"
        *ngIf="userApi.isManager && barState.type === INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR"
      >
        <span *ngIf="hour.available && hour.status === 'visit' && !isIntervalPast(hour, 'end')">Scheduled</span>
        <span *ngIf="hour.status == 'loading'" class="icon-refresh animate-spin"></span>
      </div>
      <mat-slide-toggle
        color="accent"
        [ngClass]="hour.available === true ? 'trueClass' : 'falseClass'"
        *ngIf="userApi.isManager && barState.type === INTERACTION_BAR_STATES.RE_SCHEDULE_BY_CONTRACTOR"
        [checked]="isChecked(hour) && !isIntervalPast(hour, 'end')"
        (change)="registerCheckbox(hour, $event)"
        [disabled]="
          curentDate == null ||
          (hour.status === 'visit' && isIntervalPast(hour, 'end')) ||
          (hour.status !== 'visit' && isIntervalPast(hour))
        "
      >
      </mat-slide-toggle>
    </div>
  </div>
</div>
