import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { REST_DRIVE_DOWNLOAD_EXTERNAL_FILE } from '../../../../restApi/RestRoutes';
import { DriveApiService } from '../../../../services/drive-api.service';
import { NotificationsService } from '../../../../services/notifications.service';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-view-or-download-file',
  templateUrl: './view-or-download-file.component.html',
  styleUrls: ['./view-or-download-file.component.scss'],
  standalone: true,
  imports: [MatButton],
})
export class ViewOrDownloadFileComponent implements OnInit {
  uuid = '';
  errorMsg = null;
  readonly fileCancelled =
    'You can not access this file. The sharing may be cancelled or the link is wrong.';
  constructor(
    private activatedRoute: ActivatedRoute,
    private driveApi: DriveApiService,
    private notif: NotificationsService,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.pipe(first()).subscribe((params) => {
      this.uuid = params.get('uuid');
    });
  }

  async viewFile() {
    if (this.uuid) {
      try {
        window.location.href = await this.driveApi.getViewLink(this.uuid);
      } catch (e) {
        if (e.status >= 400 && e.status < 500) {
          this.notif.showError(this.fileCancelled);
          this.errorMsg = this.fileCancelled;
        } else {
          this.notif.showError('File not available.');
          this.errorMsg = 'File not available.';
        }
        console.log(e);
      }
    }
  }

  downloadFile() {
    if (this.uuid) {
      window.location.href = `${REST_DRIVE_DOWNLOAD_EXTERNAL_FILE}/${this.uuid}`;
    }
  }
}
