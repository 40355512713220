import { Project } from '../../pages/webapp/projects/projects.interface';
import { taskStatus } from '../../store/tasks/tasks.interfaces';

export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
  POPUP = 'popup',
  POPUP_RESPONSE = 'response',
  CLOSE = 'close',
}

export interface INotification {
  id: number;
  is_seen: boolean;
  notification_type: string;
  project: Partial<Project>;
  trigger_object_id: number;
  from_user: {
    id: number;
    name: string;
    company_name: string;
  };
  extra_data?: {
    // currently contains data only for the tasks
    // these are optional, some of them can be present at a notification
    title?: string;
    status?: taskStatus;
    upcoming_tasks_count?: number;
    overdue_tasks_count?: number;
    thread_id?: number; // messaging
    group_id?: number; // messaging
    token?: string; // team invite
    is_accepted?: boolean; // team invite
    project?: Project;
    id?: number;
  };
  created_at: string;

  message: string; // added by the frontend
  title: string; // added by the frontend
  subtitle: string; // added by the frontend
}

export interface IGroupedNotifications {
  date: string;
  notifications: INotification[];
}

export interface IUnseenStatus {
  has_unseen_notifications: boolean;
  has_unread_messages: boolean;
}

export type NotificationsMap = Map<string, INotification[]>;
export type NotificationsGrouped = { seen: NotificationsMap; unseen: NotificationsMap };

// note: please make sure name is the same as notification_type from backend
// it is not 'tasks', but 'task' please be careful
export enum NOTIFICATION_CATEGORIES {
  ALL = 'all',
  INVITES = 'project',
  SCHEDULE = 'visit',
  MESSAGES = 'message',
  PROPOSALS = 'bid',
  INVOICES = 'invoice',
  TEAM = 'team',
}
