import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { NOTIFICATION_TYPE } from '../constants/notification.constants';
import {
  INotification,
  BottomNotificationService,
} from '../../services/bottom-notification.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-bottom-notification',
  templateUrl: './bottom-notification.component.html',
  styleUrls: ['./bottom-notification.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class BottomNotificationComponent implements OnInit, AfterViewInit {
  public message: string;
  public notificationType: NOTIFICATION_TYPE;
  public NOTIFICATION_TYPE = NOTIFICATION_TYPE;

  constructor(
    private el: ElementRef,
    private botomNotif: BottomNotificationService,
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.botomNotif.notifications.subscribe((notification) => {
      this.notificationType = notification.type;
      switch (notification.type) {
        case NOTIFICATION_TYPE.POPUP: {
          this.showPopup(notification);
          break;
        }
      }
    });
  }

  listener = (ev: Event) => {
    const target = ev.target as HTMLElement;
    if (
      !target?.firstElementChild?.classList?.contains('folder-title') &&
      !target?.parentElement?.parentElement?.classList.contains('folder-title') &&
      !target?.classList.contains('folder-name')
    ) {
      this.rejectPopup();
    }
  };

  showPopup(data: INotification) {
    this.message = data.message;
    setTimeout(() => {
      document.addEventListener('click', this.listener);
    }, 100);
  }

  rejectPopup() {
    this.removeClickListener();
    this.botomNotif.notifications.emit({
      type: NOTIFICATION_TYPE.POPUP_RESPONSE,
      response: false,
    });
  }

  acceptPopup() {
    this.removeClickListener();
    this.botomNotif.notifications.emit({
      type: NOTIFICATION_TYPE.POPUP_RESPONSE,
      response: true,
    });
  }

  close() {
    NotificationsService.showEvent.emit({
      type: NOTIFICATION_TYPE.CLOSE,
      data: true,
    });
  }

  private removeClickListener() {
    document.removeEventListener('click', this.listener);
  }
}
