import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenFileName',
  standalone: true,
})
export class ShortenFileNamePipe implements PipeTransform {
  // it inserts dots (ellipsis character) in the middle of the string if it's longer than the maxLength
  transform(value: string, maxLength = 30): string {
    if (!value || typeof value !== 'string' || value.length < maxLength) {
      return value;
    }

    const halfLength = Math.round(maxLength / 2);

    let shortName = value.slice(0, halfLength);
    shortName += '…';
    shortName += value.slice(value.length - halfLength, value.length);
    return shortName;
  }
}
