import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service';
import { MONTHS } from '../../constants/view.constants';
import { Router } from '@angular/router';
import { ROUTE_WEBAPP } from '../../constants/route.webapp.constants';
import { FiscalService } from '../../../services/fiscal.service';
import { Subject } from 'rxjs';
import { DynamicProjectedTableComponent } from '../../dynamic-projected-table/dynamic-projected-table.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

import { Store } from '@ngrx/store';
import { cashflowSelectors } from '../../../store/cashflow/cashflow.selectors';
import { takeUntil } from 'rxjs/operators';
import { TooltipModule } from 'primeng/tooltip';
import { CashflowData } from '../../constants/cashflow.constants';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { cashflowActions } from '../../../store/cashflow/cashflow.actions';

interface FilteredBudgetData {
  values: number[];
  year: number;
}

interface FilteredLineItem {
  id: string;
  name: string;
  actuals: FilteredBudgetData;
  budget: FilteredBudgetData;
  forecast: FilteredBudgetData;
  actuals_or_forecast: FilteredBudgetData;
}

@Component({
  selector: 'app-cashflow-table',
  templateUrl: './cashflow-table.component.html',
  styleUrls: ['./cashflow-table.component.scss'],
  standalone: true,
  imports: [
    DynamicProjectedTableComponent,
    NgScrollbar,
    CommonModule,
    MatTooltip,
    TooltipModule,
    MoneyPipe,
    FadedTextComponent,
    InputSwitchModule,
    FormsModule,
  ],
})
export class CashflowTableComponent implements OnInit, OnDestroy {
  private _filteredData: FilteredLineItem[] = [];
  @Input() isViewProject = false;
  @Input() selectedFiscalYear: number;
  @Output() changeGraph = new EventEmitter();
  months = [];
  selectedLineItemIndex: any;

  data$ = this.store.select(cashflowSelectors.getTableData);
  notEmptyData$ = this.store.select(cashflowSelectors.getNotEmptyData);
  totals$ = this.store.select(cashflowSelectors.getTotals);
  firstForecastMonth$ = this.store.select(cashflowSelectors.firstForecastMonth);
  isLoading$ = this.store.select(cashflowSelectors.isLoading);

  isDestroyed$ = new Subject<boolean>();
  showZeroDollarLineItems = false;

  constructor(
    private user: CurrentUserService,
    private router: Router,
    private fiscalService: FiscalService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.firstForecastMonth$.pipe(takeUntil(this.isDestroyed$)).subscribe((firstForecastMonth) => {
      this.months = this.getMonthsHeader(firstForecastMonth);
      console.log('months', this.months);
    });
  }

  /**
   * build the array of header titles for the table.
   * if a month is forecast, then append a ' / F'.
   */
  getMonthsHeader(firstForecastMonth: number) {
    const months = [];
    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      let suffix = '';
      if (monthIndex >= firstForecastMonth - 1) {
        suffix = ' / F';
      }

      console.log(
        'monthindex',
        monthIndex,
        this.fiscalService.fiscalMonthIndexToCalendarMonthIndex(monthIndex),
      );
      months.push(
        MONTHS[this.fiscalService.fiscalMonthIndexToCalendarMonthIndex(monthIndex)] + suffix,
      );
    }
    return months;
  }

  openViewProject(data: CashflowData) {
    this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS, data?.project_id]);
  }

  selectLineItem(data, index) {
    this.selectedLineItemIndex = index;
    this.changeGraph.emit(data);
  }

  resetSelectedLineItem() {
    this.selectedLineItemIndex = null;
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  onZeroDollarChange(event: InputSwitchChangeEvent) {
    this.store.dispatch(
      cashflowActions.filtersChanged({
        filters: { show_zero_dollar_lines: event.checked ? 1 : 0 },
      }),
    );
  }
}
