import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, spendFeatureKey, SpendState } from './spend.reducer';
import {
  IBudget,
  IFilteredLineItem,
  ILastSpendStoreUpdate,
  ILineItem,
  ILineItemsTotal,
  IMonthlySpendData,
  SpendStoreUpdateTypes,
} from './spend.interfaces';
import { SPEND_TYPES } from '../../framework/constants/budget.constants';
import {
  defaultMonthlyData,
  defaultMonthlyDisable,
} from '../../framework/constants/spend.constants';
import { FiscalService } from '../../services/fiscal.service';

export const spendFeatureSelector = createFeatureSelector<SpendState>(spendFeatureKey);

export const getAllLineItemData = createSelector(spendFeatureSelector, selectAll);

export const getSelectedYear = createSelector(spendFeatureSelector, (state) => state.selectedYear);

export const getSpendDistributionState = createSelector(
  spendFeatureSelector,
  (state) => state.spendDistributionState,
);

export const getModifiedLineItems = createSelector(
  spendFeatureSelector,
  (state) =>
    ({
      modifiedLineItems: state.modifiedLineItems,
      newLineItems: state.newLineItems,
      deletedLineItems: state.deletedLineItems,
    }) as Partial<SpendState>,
);

export const getProjectStartDate = createSelector(
  spendFeatureSelector,
  (state) => state.projectStartDate,
);
export const getSelectedSpendType = createSelector(
  spendFeatureSelector,
  (state) => state.selectedSpendType,
);
export const getIsLoading = createSelector(spendFeatureSelector, (state) => state.isLoading);

export const getLineItemById = (id) =>
  createSelector(getAllLineItemData, (items) => {
    return items.find((item) => item.id === id);
  });
export const getFilteredLineItems = createSelector(
  getAllLineItemData,
  getSelectedSpendType,
  getSelectedYear,
  (lineItems, selectedType, selectedYear) => {
    const filteredLineItems: IFilteredLineItem[] = [];
    const mapToType = (bud: IBudget) => {
      switch (selectedType) {
        case SPEND_TYPES.BUDGET:
          return bud.monthly_budget;
        case SPEND_TYPES.FORECAST:
          return bud.monthly_forecast;
        case SPEND_TYPES.ACTUALS:
          return bud.monthly_actuals;
      }
    };
    lineItems.forEach((item) => {
      let monthlyData: IMonthlySpendData = item.budget
        ?.filter((bud) => bud.year === selectedYear)
        .map(mapToType)[0];
      if (!monthlyData) {
        monthlyData = { ...defaultMonthlyData };
      }
      const yearTotal = monthlyData
        ? Object.values(monthlyData).reduce((acc, curr) => acc + curr, 0)
        : 0;
      const projectTotal = item.budget
        ?.map(mapToType)
        ?.reduce(
          (total, monthlySpendData) =>
            total +
            Object.values(monthlySpendData).reduce(
              (monthlyTotal, value) => monthlyTotal + value,
              0,
            ),
          0,
        );
      const lineItem: IFilteredLineItem = {
        ...item,
        year_total: yearTotal,
        monthly_data: monthlyData,
        monthly_disable: { ...defaultMonthlyDisable },
        project_total: projectTotal,
      };

      if (selectedType === SPEND_TYPES.FORECAST) {
        lineItem.initial_forecast_line_item_total =
          lineItem?.initial_forecast_line_item_total ?? lineItem.project_total;
      } else {
        lineItem.initial_budget_line_item_total =
          lineItem?.initial_budget_line_item_total ?? lineItem.project_total;
      }

      filteredLineItems.push(lineItem);
    });
    return filteredLineItems;
  },
);

export const getLineItemTotals = createSelector(getFilteredLineItems, (lineItems) => {
  const total: ILineItemsTotal = {
    project_total: 0,
    monthly_data: { ...defaultMonthlyData },
    year_total: 0,
  };
  lineItems.forEach((item) => {
    total.project_total += item.project_total ? item.project_total : 0;
    total.year_total += item.year_total ? item.year_total : 0;
    Object.keys(total.monthly_data).forEach((monthKey) => {
      total.monthly_data[monthKey] += item.monthly_data?.[monthKey]
        ? item.monthly_data[monthKey]
        : 0;
    });
  });
  return total;
});

// return the newly added years which are not be present in line item budget data
export const getNewYears = createSelector(spendFeatureSelector, (state) => state.newYears);

// returns all the years that can be selected in the dropdown
export const getSelectableYears = createSelector(
  getAllLineItemData,
  getNewYears,
  (lineItems, newYears) => {
    return getAllPossibleYearsFunction(lineItems, newYears);
  },
);

const getLastStoreUpdate = createSelector(spendFeatureSelector, (state) => state.lastStoreUpdate);

export const getNextOrder = createSelector(getAllLineItemData, (lineItems): number => {
  if (!lineItems.length) {
    return 1;
  }

  let order = lineItems[0].row_number ?? 0;
  lineItems.forEach((item) => {
    if (item.row_number > order) {
      order = item.row_number;
    }
  });
  return order + 1;
});

export const getStoreUpdates = createSelector(
  getFilteredLineItems, // todo: it should not recalculate everything
  getLastStoreUpdate,
  (filteredLineItems, update): ILastSpendStoreUpdate => {
    let lineItems: IFilteredLineItem[] = [];
    if (
      update.type === SpendStoreUpdateTypes.SET_ALL ||
      update.type === SpendStoreUpdateTypes.FILTER_CHANGE
    ) {
      lineItems = filteredLineItems;
    } else if (
      update.type === SpendStoreUpdateTypes.DELETE ||
      update.type === SpendStoreUpdateTypes.NONE
    ) {
      lineItems = [];
    } else {
      const item = filteredLineItems.find((filtered) => filtered.id === update.lineId);
      lineItems.push(item);
    }

    return {
      lastStoreUpdate: update,
      lineItems,
    };
  },
);

// mixes together the years from line items and years added added by the user
// returns an ascending continuous array (no gaps between years)
export const getAllPossibleYearsFunction = (lineItems: ILineItem[], newYears: number[]) => {
  const possibleYears = getLineItemPossibleYearsFunction(lineItems);
  if (possibleYears?.[0] === undefined) {
    return [];
  }
  const allYears = possibleYears.concat(newYears);
  const minYear = allYears.reduce((acc, value) => (acc > value ? value : acc), allYears[0]);
  const maxYear = allYears.reduce((acc, value) => (acc < value ? value : acc), allYears[0]);
  return new Array(maxYear - minYear + 1).fill(0).map((__, index) => minYear + index);
};

// returns the years present in lineItem.budget
export const getLineItemPossibleYearsFunction = (lineItems: ILineItem[]) => {
  if (lineItems.length === 0) {
    return [FiscalService.fiscalYear];
  }
  const allYears = lineItems
    .map((item) => item.budget.map((budget) => budget.year))
    .reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);

  return [...new Set(allYears)].sort((a, b) => a - b);
};

export const getDisableBasedOnTemplate = createSelector(spendFeatureSelector, (state) => {
  return state.hasTemplates;
});

export const getSpendLineItemSummary = createSelector(spendFeatureSelector, (state) => {
  return state.spendLineItemSummary;
});

export const hasCommitments = createSelector(getAllLineItemData, (lineItems) => {
  return lineItems.some((lineItem) => !!lineItem.commitment_start_date);
});
