import { DocumentsState, selectAll } from './documents.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const docsFeatureSelector = createFeatureSelector<DocumentsState>('documents');

export const getAllDocs = createSelector(docsFeatureSelector, selectAll);

export const getSelectedFolder = createSelector(
  docsFeatureSelector,
  getAllDocs,
  (state, entities) => {
    const folder = entities.find((entity) => entity.id === state.selectedFolderId);
    return folder;
  },
);

export const areDocsLoaded = createSelector(docsFeatureSelector, (state) => state.docsLoaded);

export const getSharedBy = createSelector(docsFeatureSelector, (state) => {
  return state.shared_by;
});
