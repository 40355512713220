import { ISubscription, User } from './user.constants';
import moment from 'moment/moment';

export interface ISubscribeResponse {
  url: string;
}

export interface ISubscriptionPlan {
  id: number;
  name: string;
  stripe_product_id: string;
  is_active: boolean;
  stripe_monthly_price_id?: string;
  stripe_monthly_price?: number;
  stripe_yearly_price_id: string;
  stripe_yearly_price: number;
  trial_days: number;
  team_limit: number;
  property_limit: number;
  project_limit: number;
  drive_storage_limit: number;
  reporting_features: string[];
  deleted_at: any;
  created_at: string;
  updated_at: string;
}

const isSubscriptionActive = (subscription: ISubscription) => {
  const trialEnd = moment(subscription.trial_ends_at);
  const subscriptionEnd = moment(subscription.ends_at);

  if (subscription.status === 'trialing' && trialEnd.isBefore(moment())) {
    console.log('subscription not active: subscription is in trialing, but trialing ended');
    return false;
  }

  if (subscription.status === 'active' && subscriptionEnd.isBefore(moment())) {
    console.log('subscription not active: subscription is active, but subscription ended');
    return false;
  }

  return subscription.status === 'active' || subscription.status === 'trialing';
};

export const hasActiveSubscription = (user: User) => {
  if (!!user?.contractor?.id) {
    // if user is a contractor, they don't need a subscription
    return true;
  }

  const subscription = getUserSubscription(user);
  if (!subscription) {
    console.log('subscription not active: no subscription info for user');
    return false;
  }

  return true;
};

/**
 * returns if the user has an active subscription that they own, and is not a team subscription.
 */
export const hasActiveOwnSubscription = (user: User) => {
  if (!!user?.contractor?.id) {
    // if user is a contractor, they don't need a subscription
    return true;
  }

  const subscription = getUserSubscription(user, false);
  if (!subscription) {
    console.log('subscription not active: user has no own subscription');
    return false;
  }

  return true;
};

/**
 * returns the currently active subscription - it may be a user sub or a team sub.
 */
export const getUserSubscription = (user: User, includeTeamSub = true): ISubscription => {
  let subscription: ISubscription = user?.subscription;
  if (subscription && isSubscriptionActive(subscription)) {
    return {
      ...subscription,
      isTeamSubscription: false,
    };
  }

  if (!includeTeamSub) {
    return null;
  }

  // first try to find an active subscription from the team
  subscription = user.team_subscription.find((sub) => sub.status === 'active');
  if (subscription && isSubscriptionActive(subscription)) {
    return {
      ...subscription,
      isTeamSubscription: true,
    };
  }

  // then try to find a trialing subscription from the team
  subscription = user.team_subscription.find((sub) => sub.status === 'trialing');
  if (subscription && isSubscriptionActive(subscription)) {
    return {
      ...subscription,
      isTeamSubscription: true,
    };
  }

  return null;
};

export interface IInvoiceBackendResponse {
  current_page: number;
  data: IBillingInvoice[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface IBillingInvoice {
  id: string;
  created: string;
  total: number;
  subtotal: number;
  subtotal_excluding_tax: number;
  hosted_invoice_url: string;
  invoice_pdf: string;
  status: string;
  plan: ISubscriptionPlan;
}
