import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { NotificationsService } from '../../../services/notifications.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { PROJECT_STATUS_ID } from '../../constants/project.constants';
import { MatButton } from '@angular/material/button';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FadedTextComponent } from '../../faded-text/faded-text.component';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss'],
  standalone: true,
  imports: [
    FadedTextComponent,
    NgClass,
    NgScrollbar,
    MatSelectionList,
    NgFor,
    MatListOption,
    NgIf,
    MatButton,
  ],
})
export class SelectProjectComponent implements OnInit {
  @ViewChild('projectsSelector', { static: true })
  projectsSelector: MatSelectionList;
  contractorId: number = null;
  projects: Array<any> = [];
  title = 'Projects';
  loading = true;

  constructor(
    private projectsApi: ProjectApiService,
    private notif: NotificationsService,
    private barState: InteractionBarStateService,
  ) {}

  ngOnInit() {
    this.projectsApi.getProjects().then(
      (data) => {
        console.log(data);
        data = data.filter(
          (project) =>
            project.status !== PROJECT_STATUS_ID.DELETED &&
            project.status !== PROJECT_STATUS_ID.CANCELED &&
            project.status !== PROJECT_STATUS_ID.ARCHIVED,
        );
        this.projects = data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        console.error(err);
      },
    );
  }

  inviteToProject() {
    console.log(this.contractorId);
    const bar = new Promise((resolve, reject) => {
      // TODO: this for each should be omitted by providing an array to the request
      this.projectsSelector.selectedOptions.selected.forEach((item, index, array) => {
        console.log(item);
        this.projectsApi.createInvite(item.value, [this.contractorId]).then(
          (data) => {
            if (index === array.length - 1) {
              resolve(true);
            }
          },
          (err) => {
            if (index === array.length - 1) {
              reject(false);
            }
            // @ts-ignore
            this.notif.showError(err + ' [' + item._text.nativeElement.innerText + ']');
          },
        );
      });
    });

    bar.then(
      () => {
        this.barState.close();
        this.notif.showSuccess('Successfully invited!');
      },
      (err) => {},
    );
  }

  inviteAllToProject() {
    console.log(this.contractorId);
    const bar = new Promise((resolve, reject) => {
      this.projects.forEach((item, index, array) => {
        console.log(item);
        // TODO: this for each should be omitted by providing an array to the request
        this.projectsApi.createInvite(item.id, [this.contractorId]).then(
          (data) => {
            if (index === array.length - 1) {
              resolve(true);
            }
          },
          (err) => {
            if (index === array.length - 1) {
              reject(false);
            }
            this.notif.showError(err + ' [' + item._text.nativeElement.innerText + ']');
          },
        );
      });
    });

    bar.then(
      () => {
        this.barState.close();
        this.notif.showSuccess('Successfully invited to all projects!');
      },
      (err) => {},
    );
  }
}
