import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AddProjectService } from '../../../../services/add-project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectStateService } from '../../../../services/project-state.service';
import {
  PROJECT_STATUS_ID,
  PROJECT_STATUSES,
} from '../../../../framework/constants/project.constants';
import { ROUTE_WEBAPP } from '../../../../framework/constants/route.webapp.constants';
import { NotificationsService } from '../../../../services/notifications.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Store } from '@ngrx/store';
import {
  loadProjectDocuments,
  reset as ResetProjectDocuments,
  setRetrieveDisabled,
} from '../../../../store/projectDocuments/projectDocuments.actions';
import { DriveComponent } from '../../drive/drive.component';
import {
  areProjDocsLoaded,
  getAllProjDocs,
} from '../../../../store/projectDocuments/projectDocuments.selectors';
import { delay, filter, map, take, timeout } from 'rxjs/operators';
import { reload, reset as resetDocuments } from '../../../../store/documents/documents.actions';
import { AppState } from '../../../../store/app-state';
import {
  loadSpends,
  saveToBackend,
  setHasProjectTemplateFromAddEditProject,
  setProjectStartDate,
  setSelectedProjectIdFromAddEditProject,
  setSelectedSpendType,
  setSpendDistributionState,
} from '../../../../store/spend/spend.actions';
import { SPEND_DISTRIBUTION_STATE } from '../../../../framework/constants/spend.constants';
import { SPEND_TYPES } from '../../../../framework/constants/budget.constants';
import moment from 'moment';
import { SpendViewProjectStateService } from '../../../../services/spend-view-project-state.service';
import { getIsLoading, getStoreUpdates } from '../../../../store/spend/spend.selectors';
import { SpendStoreUpdateTypes } from '../../../../store/spend/spend.interfaces';
import { Subject, Subscription } from 'rxjs';
import { DriveService } from '../../../../services/drive.service';
import { DRIVE_VIEWS } from '../../../../framework/constants/documents.constants';
import { ProjectApiService } from '../../../../services/project-api.service';
import { AddProjectBase } from './AddProjectBase';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { MatButton } from '@angular/material/button';
import { ScheduleAddComponent } from './schedule/schedule.component';
import { SpendDistributionComponent } from './spend-distribution/spend-distribution.component';
import { GeneralComponent } from './general/general.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { settingsActions } from '../../../../store/settings/settings.actions';
import { viewProjectActions } from '../../../../store/view-project/view-project.actions';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  providers: [AddProjectService],
  standalone: true,
  imports: [
    NgIf,
    MatTabGroup,
    MatTab,
    NgClass,
    MatTabLabel,
    GeneralComponent,
    DriveComponent,
    SpendDistributionComponent,
    ScheduleAddComponent,
    MatButton,
    AsyncPipe,
  ],
})
export class AddProjectComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('driveComponent') driveComponent: DriveComponent;
  @ViewChild('generalComponent') generalComponent: GeneralComponent;
  @ViewChildren('optionTab') tabs: QueryList<AddProjectBase>;
  selectedTabIndex = 0;
  isEditMode = false;
  isScheduleActive = false;
  isBidding = false;
  budgetTabIndex = 2;
  isFirstLoad = true;
  initialPage: number;
  isBudgetLocked = true;
  areDocsLoaded$ = this.store.select(areProjDocsLoaded);
  areDocsLoaded = false;
  isBudgetSheetClosed$ = this.spendViewState.currentState.pipe(
    map((state) => state === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET),
  );
  shouldDeleteProjectOnDestroy = true; // project should be deleted on discard / navigation to another page
  lastStoreUpdate$ = this.store.select(getStoreUpdates);
  subscriptions = new Subscription();
  DRIVE_VIEWS = DRIVE_VIEWS;
  isDestroyed$ = new Subject();
  isGeneralFormValid: boolean;

  isValidating = false;

  constructor(
    private el: ElementRef,
    private state: AddProjectService,
    private route: ActivatedRoute,
    private projectState: ProjectStateService,
    private router: Router,
    private notif: NotificationsService,
    private changeRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private spendViewState: SpendViewProjectStateService,
    private driveService: DriveService,
    private projectApi: ProjectApiService,
  ) {}

  get tabsCount(): number {
    return this.tabs?.toArray()?.length ?? 3;
  }

  get currentTab() {
    return this.tabs?.toArray()[this.tabGroup.selectedIndex];
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const projectID = params.editID;
      this.initialPage = Number.parseInt(params.page, 10);
      this.projectState.isAddProject = !isNotNullOrUndefined(params.editID);
      if (projectID != null) {
        this.projectState.isEdit = true;
        this.projectState.id = params.editID;
        this.isEditMode = true;
      } else {
        if (this.isFirstLoad) {
          this.projectState.clearProject();
          this.isFirstLoad = false;
        }
        this.projectState.isEdit = false;

        if (params.addID) {
          this.projectState.isEdit = true;
          this.projectState.id = params.addID;
        }
      }
      this.isBudgetLocked = SpendViewProjectStateService.isBudgetLocked;

      if (params.estimateID != null) {
        this.projectState.isAddEstimate = true;
        this.projectState.estimateID = params.estimateID;
      } else {
        this.projectState.isAddEstimate = false;
      }

      if (Boolean(params.isBidding) === true) {
        this.isBidding = true;
      }
    });
    this.areDocsLoaded$
      .pipe(
        filter((areLoaded) => areLoaded),
        take(1),
      )
      .subscribe(() => {
        this.areDocsLoaded = true;
      });

    this.store.dispatch(ResetProjectDocuments());

    if (this.projectState.id) {
      this.loadSpends();
      this.store.dispatch(settingsActions.loadBudgetTemplate());
      this.store.dispatch(
        viewProjectActions.selectedProjectChanged({ projectId: Number(this.projectState.id) }),
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.isBudgetPage() && this.areDocsLoaded) {
      this.tabs?.toArray()?.[this.budgetTabIndex]?.update();
    }
  }

  ngAfterViewInit(): void {
    if (this.initialPage) {
      this.tabGroup.selectedIndex = this.initialPage - 1;
      this.selectedTabIndex = this.initialPage - 1;
      this.updateVisitedTabs();
      this.changeRef.detectChanges();
    }

    // this.tabGroup.selectedIndex = 3;
    this.initSubscriptions();
  }

  updateVisitedTabs() {
    for (let i = 0; i < this.selectedTabIndex; i++) {
      // I'm not proud of this, but we need it.
      const element = this.el.nativeElement.getElementsByClassName('mat-mdc-tab')[i];
      if (element) {
        element.classList.add('visited');
      }
    }

    for (let i = this.selectedTabIndex; i < this.tabsCount; i++) {
      // I'm not proud of this, but we need it.
      const element = this.el.nativeElement.getElementsByClassName('mat-mdc-tab')[i];
      if (element) {
        element.classList.remove('visited');
      }
    }
  }

  setPageUrl(isNext: boolean) {
    let pageUrl = window.location.href;
    const pageNr = isNext ? this.tabGroup.selectedIndex + 2 : this.tabGroup.selectedIndex;
    if (pageUrl.includes('page=')) {
      pageUrl = pageUrl.split('page=')[0] + `page=${pageNr}`;
    } else if (pageUrl.includes('?')) {
      pageUrl += `&page=${pageNr}`;
    } else {
      pageUrl += `?page=${pageNr}`;
    }

    if (!this.isEditMode && !pageUrl.includes('addID') && this.projectState.id) {
      pageUrl += `&addID=${this.projectState.id}`;
    }

    if (this?.projectState?.id) {
      this.store.dispatch(setSelectedProjectIdFromAddEditProject({ id: this.projectState.id }));
    }

    if (!this.isBudgetPage() && this.areDocsLoaded) {
      this.tabs?.toArray()?.[this.budgetTabIndex]?.update();
      this.loadSpends();
    }

    if (!this.areDocsLoaded && this.projectState.id) {
      this.store.dispatch(setRetrieveDisabled({ retrieveDisabled: true }));
      this.store.dispatch(
        loadProjectDocuments({
          projectId: Number.parseInt(this.projectState.id, 10),
        }),
      );
      this.store.dispatch(reload());
      DriveComponent.isProjectView.next(true);
    }

    if (pageNr === 1 && this.projectState.id) {
      this.generalComponent.loadData();
    }
    window.history.replaceState('pageSet', 'Skillhop', pageUrl);
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.lastStoreUpdate$.pipe(delay(100)).subscribe((update) => {
        if (update.lastStoreUpdate.type === SpendStoreUpdateTypes.SAVE_BACKEND) {
          this.store.dispatch(loadSpends({ projectId: this.projectState.id }));
        }

        this.store.dispatch(
          setSpendDistributionState({
            spendDistributionState: SPEND_DISTRIBUTION_STATE.ADD_PROJECT,
          }),
        );
      }),
    );
  }

  async isCurrentTabValid() {
    const currentTab = this.currentTab;
    return await currentTab.isValid();
  }

  async nextButtonClicked(): Promise<void> {
    this.isValidating = true;
    if (this.isBudgetPage() && this.isBudgetLocked) {
      this.notif.showError('Budget is locked, please unlock!');
      this.isValidating = false;
      return;
    }

    this.notif.showLoading();
    const isValid = await this.isCurrentTabValid();
    this.isValidating = false;

    if (isValid) {
      this.notif.close();
      this.goNextSlide();
    }
  }

  async backButtonClicked() {
    if (this.selectedTabIndex === 0) {
      return;
    }
    if (this.isBudgetPage() && this.isBudgetLocked) {
      this.notif.showError('Budget is locked, please unlock!');
      return;
    }

    const isValid = await this.isCurrentTabValid();
    if (!isValid) {
      return;
    }

    const currentTab = this.currentTab;
    const canGoBack = await currentTab.canGoBack();

    if (canGoBack) {
      this.goPreviousSlide();
    }
  }

  discard() {
    this.shouldDeleteProjectOnDestroy = true;
    this?.driveComponent?.goBack(-1);

    this.store
      .select(getAllProjDocs)
      .pipe(take(1))
      .subscribe((entities) => {
        // keep in mind how manny times history.push was used... and then navigate
        const backCount = entities.length > 1 ? entities.length * -1 : -1;
        window.history.go(backCount);
      });
  }

  onTabChange() {
    this.state.emitTabChange(this.tabGroup.selectedIndex);
    this.selectedTabIndex = this.tabGroup.selectedIndex;
    // todo use ngClass
    this.updateVisitedTabs();
  }

  registerProjectStatusChange(statusId: number) {
    console.log('registerProjectStatusChange');
    this.isScheduleActive = statusId === PROJECT_STATUS_ID.BIDDING;

    // this.updateTabLength();
  }

  closeSpreadsheet() {
    if (this.projectState.isAddProject) {
      this.spendViewState.closeSheet(SPEND_DISTRIBUTION_STATE.ADD_PROJECT);
    } else {
      this.spendViewState.closeSheet(SPEND_DISTRIBUTION_STATE.EDIT_PROJECT);
    }
  }

  changeBudgetLock() {
    this.isBudgetLocked = !this.isBudgetLocked;
    SpendViewProjectStateService.isBudgetLocked = this.isBudgetLocked;
  }

  // updateTabLength() {
  //   if (!this.tabs) {
  //     console.warn('Tabs not found');
  //     this.tabsCount = 1;
  //     return;
  //   }
  //
  //   if (this.areDocsLoaded) {
  //     this.tabsCount = this.tabs.toArray().length;
  //   } else {
  //     this.tabsCount = this.tabs.toArray().length + 1;
  //   }
  // }

  isBudgetPage() {
    const tabIndexesExist =
      this?.tabGroup?.selectedIndex !== undefined && this.budgetTabIndex !== undefined;
    return tabIndexesExist ? this.tabGroup.selectedIndex === this.budgetTabIndex : false;
  }

  async ngOnDestroy() {
    if (!this.isEditMode && this.shouldDeleteProjectOnDestroy) {
      // delete project if creation was discarded
      const deletedStatus = PROJECT_STATUSES.find((status) => status.key === 'deleted');
      if (!deletedStatus) {
        console.warn('Deleted status could not be set');
      } else {
        await this.projectApi.updateProjectStatus(this.projectState.id, deletedStatus.id);
      }
    }

    this.projectState.clear();
    this.areDocsLoaded = false;
    this.subscriptions.unsubscribe();
    this.store.dispatch(resetDocuments());
    this.store.dispatch(ResetProjectDocuments());
    this.isDestroyed$.next(true);
    this.driveService.folderPath = [];
  }

  private goPreviousSlide() {
    this.tabGroup.selectedIndex -= 1;
    this.setPageUrl(false);
  }

  private goNextSlide() {
    this.tabGroup.selectedIndex += 1;
    this.setPageUrl(true);
    if (this.tabGroup.selectedIndex === this.tabs.toArray().length - 1) {
      // if last page finish saving and navigate to project (view) page
      this.saveAndNavigate();
    }
  }

  private saveAndNavigate() {
    const navigateAndCloseNotif = () => {
      this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS, this.projectState.id], {
        replaceUrl: true,
      });
      this.notif.close();
    };

    this.notif.showLoading();

    this.shouldDeleteProjectOnDestroy = false;
    this.store.dispatch(saveToBackend());
    this.store
      .select(getIsLoading)
      .pipe(
        filter((isLoading) => !isLoading), // wait for loading to finish
        take(1),
        timeout(10000), // emits error if not completed in 10 seconds
      )
      .subscribe(navigateAndCloseNotif, navigateAndCloseNotif); // navigate if not loading or timeout error
  }

  private loadSpends() {
    if (!this.projectState.id) {
      return;
    }

    this.store.dispatch(setSelectedSpendType({ spendType: SPEND_TYPES.BUDGET }));
    const startDate = this.projectState?.general?.start_date;
    if (!startDate) {
      this.projectState.fetchAndSetProject().then((retrievedProject) => {
        this.store.dispatch(
          setHasProjectTemplateFromAddEditProject({
            hasTemplates: !!retrievedProject.budget_template_id,
          }),
        );
        this.setSpendState();
      });
      return;
    }
    this.setSpendState();
  }

  private setProjectStartDate() {
    this.store.dispatch(
      setProjectStartDate({
        projectStartDate: moment.utc(this.projectState.general.start_date).format('YYYY-MM-DD'),
      }),
    );
  }

  private setSpendState() {
    this.setProjectStartDate();
    this.store.dispatch(loadSpends({ projectId: this.projectState.id }));
  }

  protected readonly SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE;
}
