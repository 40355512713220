<div class="subscribe-wrapper">
  <div class="subscribe-content">
    <div class="content">
      <ng-container *ngIf="!isUserInfoLoading && (hasEndedTrial || subscriptionEnded)">
        <h2 class="text-center mt-10 mb-5">
          Please add a payment method <br />
          <span class="text-color_secondary">in order to continue using Skillhop</span>
        </h2>
        <p *ngIf="hasEndedTrial" class="text-size_xxl">
          Your trial has ended without any payment method added to your subscription.
        </p>
        <p *ngIf="subscriptionEnded" class="text-size_xxl">
          Your don't have any payment method added to your subscription.
        </p>
        <p class="text-size_xxl">Please click on the button below and add a payment method.</p>
        <div class="flex justify-center mt-12 mb-60">
          <button (click)="manageSubscription()" class="landing-btn light-blue-btn uppercase">
            Add payment method
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="!isUserInfoLoading && !(hasEndedTrial || subscriptionEnded)">
        <h2 class="text-center mt-10 mb-5">
          Please select a plan that <span class="text-color_secondary">suits your needs</span>
        </h2>
        <p class="text-size_xxl" *ngIf="!hasTeamSubscription">
          In order to continue using Skillhop, <span class="font-semibold">you need to select a paid plan.</span>
        </p>
        <p class="text-size_xxl">
          Basic and Premium plans include a one-time <span class="font-semibold">free 30-day trial.</span>
        </p>

        <div class="mt-6 flex justify-center items-center gap-4">
          <span class="w-24 text-size_title_s text-right" [ngClass]="{ 'font-bold': !isYearly }">Monthly</span>
          <p-inputSwitch class="light-blue-switch" [(ngModel)]="isYearly"> </p-inputSwitch>
          <span class="w-24 text-size_title_s" [ngClass]="{ 'font-bold': isYearly }">Yearly</span>
        </div>

        <div class="plans">
          <app-pricing-basic-card
            [isLoading]="isLoading || !basicPlan"
            [backSideButtonText]="'free 30 day trial'"
            [buttonText]="buttonText"
            [buttonTextBackside]="buttonText"
            (onButtonClick)="subscribeToPlan('basic')"
            [isYearly]="isYearly"
            [price]="isYearly ? basicPlan?.stripe_yearly_price : basicPlan?.stripe_monthly_price"
          ></app-pricing-basic-card>
          <app-pricing-premium-card
            [isLoading]="isLoading || !premiumPlan"
            (onButtonClick)="subscribeToPlan('premium')"
            [buttonText]="buttonText"
            [isYearly]="isYearly"
            [price]="isYearly ? premiumPlan?.stripe_yearly_price : premiumPlan?.stripe_monthly_price"
          ></app-pricing-premium-card>
          <app-pricing-enterprise-card
            [isLoading]="isLoading"
            (onButtonClick)="openIntercom()"
          ></app-pricing-enterprise-card>
        </div>
      </ng-container>

      <div *ngIf="!isUserInfoLoading" class="mt-10">
        <p class="text-center">If in doubt, please contact us and we will be happy to help.</p>
        <div class="button-container">
          <button (click)="openIntercom()" class="landing-btn btn-small outline-btn uppercase">contact us</button>
          @if (hasTeamSubscription) {
            <button (click)="goBack()" class="landing-btn btn-small light-blue-btn uppercase">
              Go back to Skillhop
            </button>
          } @else {
            <button (click)="logout()" class="landing-btn btn-small light-blue-btn uppercase">logout</button>
          }
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{ animate: triggerAnimation }" class="triangle left"></div>
  <div [ngClass]="{ animate: triggerAnimation }" class="triangle right"></div>
</div>
