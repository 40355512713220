<div class="calendar-wrapper">
  <div class="calendar-header-wrapper">
    <div class="calendar-header">
      <div class="flex text-size_xl text-uppercase font-bold gap-1">
        <span>{{ monthName }}</span>
        <span>{{ year }}</span>
      </div>
      <div class="flex nav-arrows text-size_xl text-color_secondary gap-5">
        <span (click)="goToPreviousMonth()" class="icon-arrow-right-light left"></span>
        <span (click)="goToNextMonth()" class="icon-arrow-right-light"></span>
      </div>
    </div>
    <mat-progress-bar
      mode="indeterminate"
      class="custom-loading-progress-bar"
      *ngIf="isLoading$ | async"
    ></mat-progress-bar>
  </div>

  <div class="calendar-container" [ngClass]="{ 'row-5-calendar': calendarItems.length <= 35 }">
    <div *ngFor="let day of calendarItems" class="day-container">
      <app-tasks-calendar-item [day]="day"></app-tasks-calendar-item>
    </div>
  </div>
</div>
