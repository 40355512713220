import { Component, Input, OnInit } from '@angular/core';
import { IMessagingMember } from '../../../store/messages/messages.interfaces';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-messaging-tag-user-overlay',
  templateUrl: './messaging-tag-user-overlay.component.html',
  styleUrls: ['./messaging-tag-user-overlay.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbar],
})
export class MessagingTagUserOverlayComponent implements OnInit {
  private _data;
  private _filterText: string;
  @Input() set data(value) {
    this._data = value;
    this.users = value.discussionMembers;
    this.usersToShow = value.discussionMembers;
    this.filterText = value.filterText;
  }

  get filterText() {
    return this._filterText;
  }
  set filterText(value: string) {
    this._filterText = value;
    if (!value) {
      this.usersToShow = this.users;
      return;
    }
    this.usersToShow = this.users.filter((user) =>
      user.name.toLowerCase().includes(value.toLowerCase()),
    );
  }
  users: IMessagingMember[];
  usersToShow: IMessagingMember[];

  constructor(private overlayService: CustomOverlayService) {}

  ngOnInit(): void {}

  selectUser(user: IMessagingMember) {
    this.overlayService.closeOverlay();
    this.overlayService.emitOutputData(user);
  }
}
