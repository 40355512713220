import { Pipe, PipeTransform } from '@angular/core';
import { IMessagingMember } from '../../store/messages/messages.interfaces';
import { CurrentUserService } from '../../services/current-user.service';

/**
 * pipe will remove current logged user in case of discussion/direct messages
 * where users number is exactly 2 to display the other user
 */
@Pipe({
  name: 'removeLoggedInUser',
  standalone: true,
})
export class RemoveLoggedInUserPipe implements PipeTransform {
  constructor(private currentUser: CurrentUserService) {}

  transform(users: IMessagingMember[], ...args: unknown[]): unknown {
    const directMessage = 2;
    if (users?.length === directMessage) {
      return users?.filter((user) => user.user_id !== String(this.currentUser.data.id));
    }
    return users;
  }
}
