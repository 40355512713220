import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  getRollupSelectedData,
  getRollupViewType,
} from '../../../../store/rollups/rollups.selectors';
import {
  IRollupAccountTable,
  IRollupLineItemTable,
  IRollupTagTable,
  ROLLUP_VIEW_TYPES,
} from '../../../../store/rollups/rollups.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormattedMoneyComponent } from '../../../../framework/formated-money/formatted-money.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgSwitch, NgSwitchCase, NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-rollups-interaction-bar',
  templateUrl: './rollups-interaction-bar.component.html',
  styleUrls: ['./rollups-interaction-bar.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgScrollbar, FormattedMoneyComponent, NgFor, NgIf, AsyncPipe],
})
export class RollupsInteractionBarComponent implements AfterViewInit, OnDestroy {
  viewType$ = this.store.select(getRollupViewType);
  selectedRollupData$ = this.store.select(getRollupSelectedData);
  selectedRollupData: IRollupLineItemTable | IRollupTagTable | IRollupAccountTable;
  viewType: ROLLUP_VIEW_TYPES;

  private isDestroyed$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  protected readonly ROLLUP_VIEW_TYPES = ROLLUP_VIEW_TYPES;

  get bliData() {
    return this.selectedRollupData as IRollupLineItemTable;
  }

  get tagsData() {
    return this.selectedRollupData as IRollupTagTable;
  }

  get accountsData() {
    return this.selectedRollupData as IRollupAccountTable;
  }

  ngAfterViewInit() {
    this.selectedRollupData$.pipe(takeUntil(this.isDestroyed$)).subscribe((selectedRollupData) => {
      this.selectedRollupData = selectedRollupData;
    });
    this.viewType$.pipe(takeUntil(this.isDestroyed$)).subscribe((viewType) => {
      this.viewType = viewType;
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
