import { Component, Input, OnInit } from '@angular/core';
import { IMessage } from '../../../store/messages/messages.interfaces';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-message-item-announcement',
  templateUrl: './message-item-announcement.component.html',
  styleUrls: ['./message-item-announcement.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class MessageItemAnnouncementComponent implements OnInit {
  @Input() message: IMessage;

  constructor() {}

  ngOnInit(): void {}
}
