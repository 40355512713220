import { createFeatureSelector, createSelector } from '@ngrx/store';
import { messagingFeatureKey, MessagingState, selectAll } from './messages.reducer';
import { IMessage, IMessageThread, IMessagingGroup } from './messages.interfaces';

export const messagingFeatureSelector = createFeatureSelector<MessagingState>(messagingFeatureKey);

const messagingGroupsInitial = createSelector(messagingFeatureSelector, selectAll);
export const getMessagingGroups = createSelector(
  messagingGroupsInitial,
  (groups): IMessagingGroup[] => {
    if (!groups || !Array.isArray(groups)) {
      return [];
    }
    // filter out task groups
    // todo: this can be removed once the backend is updated to not send task groups
    return groups.filter((group) => group.type !== 'task');
  },
);

// will return just the id of the selected group
export const getSelectedGroupId = createSelector(
  messagingFeatureSelector,
  (state) => state.selectedGroupId,
);

export const getSelectedGroup = createSelector(
  getMessagingGroups,
  getSelectedGroupId,
  (items, groupId) => {
    return items.find((item) => item.id === groupId);
  },
);

export const getMessagingView = createSelector(
  messagingFeatureSelector,
  (state) => state.selectedView,
);

export const getSearchedMessagingMembers = createSelector(messagingFeatureSelector, (state) =>
  Array.from(state.searchedMembers),
);

export const getDiscussionMembers = createSelector(messagingFeatureSelector, (state) =>
  Array.from(state.discussionMembers),
);

export const getNewDiscussionMembers = createSelector(messagingFeatureSelector, (state) =>
  Array.from(state.newGroupData.discussionMembers),
);

export const getAllMessages = createSelector(
  messagingFeatureSelector,
  getSelectedGroup,
  (state, group) => {
    return group?.messages ?? [];
  },
);

export const getThreadMessages = createSelector(
  messagingFeatureSelector,
  getSelectedGroup,
  (state, group): IMessageThread => {
    const mainMessage = group?.messages?.find((m) => m.id === state.thread_id) as IMessage;
    if (mainMessage) {
      return {
        mainMessage,
        replies: mainMessage.thread,
      };
    }
    return null;
  },
);

export const getCurrentMessage = createSelector(messagingFeatureSelector, (state) => state.message);

export const getTopLevelDraft = createSelector(messagingFeatureSelector, (state) => {
  // if (state.message) {
  //   return null;
  // }
  return state.drafts.find((draft) => draft.group_id === state.selectedGroupId && !draft.thread_id);
});

export const getThreadDraft = createSelector(messagingFeatureSelector, (state) => {
  // if (state.message) {
  //   return null;
  // }
  return state.drafts.find(
    (draft) => draft.group_id === state.selectedGroupId && draft.thread_id === state.thread_id,
  );
});

export const getProfilePicCache = createSelector(
  messagingFeatureSelector,
  (state) => state.profile_pic_cache,
);

export const getProfilePicUrl = (profilePicId) =>
  createSelector(messagingFeatureSelector, (state) => state.profile_pic_cache[profilePicId]);

export const getMessagingLoading = createSelector(
  messagingFeatureSelector,
  (state) => state.isLoading,
);

export const getMessagingLoadingPagination = createSelector(
  messagingFeatureSelector,
  (state) => state.isPaginationLoading,
);

export const getLastNonDraftSentMessageId = createSelector(
  messagingFeatureSelector,
  (state) => state.lastNonDraftSentMessageId,
);

export const getFilesToUpload = createSelector(messagingFeatureSelector, (state) => state.files);

export const getMessageFilters = createSelector(
  messagingFeatureSelector,
  (state) => state.messageFilters,
);
export const getGroupFilters = createSelector(
  messagingFeatureSelector,
  (state) => state.groupFilters,
);

export const getLastGroupPageIndex = createSelector(getGroupFilters, (filters) => filters.page);

export const getAreAllGroupsLoaded = createSelector(
  messagingFeatureSelector,
  (state) => state.areAllGroupsLoaded,
);

export const hasUnreadMessage = createSelector(
  messagingFeatureSelector,
  (state) => state.hasUnreadMessage,
);

export const messageSelectors = {
  getGroupFilters,
  getMessageFilters,
};
