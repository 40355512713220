<h1 class="dialog-header" mat-dialog-title>Add link</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Link</mat-label>
    <input (keyup.enter)="save()" matInput [formControl]="input" />
  </mat-form-field>
</div>
<div class="flex justify-end" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button class="btn bg-accent color-white" mat-button (click)="save()">Save</button>
</div>
