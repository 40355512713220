import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-add-remove-buttons',
  standalone: true,
  imports: [NgClass],
  templateUrl: './add-remove-buttons.component.html',
  styleUrl: './add-remove-buttons.component.scss',
})
export class AddRemoveButtonsComponent {
  @Input() disabledRemove = false;
  @Input() disabledAdd = false;

  @Output() addItem = new EventEmitter();
  @Output() removeItem = new EventEmitter();

  insertItem() {
    this.addItem.emit();
  }

  deleteItem() {
    this.removeItem.emit();
  }
}
