import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  exhaustMap,
  map,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { teamManagementActions } from './team-management.actions';
import { of } from 'rxjs';
import { TeamManagementService } from '../../services/team-management.service';
import { NotificationsService } from '../../services/notifications.service';
import { teamManagementFeatureSelector } from './team-management.selectors';
import { DeepCopyService } from '../../services/deep-copy.service';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { RestRequestService } from '../../restApi/rest-request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TEAM_VIEWS } from '../../framework/constants/team.constants';
import { ITeamMember, TEAM_MANAGEMENT_SIDEBAR_VIEW } from './team-management.interfaces';
import { INTERACTION_BAR_STATES } from '../../framework/constants/interaction-bar.constants';
import { DriveApiService } from '../../services/drive-api.service';

@Injectable()
export class TeamManagementEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private teamManagementService: TeamManagementService,
    private rest: RestRequestService,
    private notif: NotificationsService,
    private interactionBarStateService: InteractionBarStateService,
    private domSanitizer: DomSanitizer,
    private driveApi: DriveApiService,
  ) {}

  /**
   * Load all team members
   */
  loadTeamMembers$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        teamManagementActions.loadTeamMembersFromBackend,
        teamManagementActions.reloadTeamFromBackend,
      ),
      tap((_) => {
        this.store.dispatch(teamManagementActions.setAreTeamsFetching({ isLoading: true }));
      }),
      withLatestFrom(this.store.select(teamManagementFeatureSelector)),
      switchMap(([action, state]) => {
        return of(action).pipe(
          switchMap(() =>
            this.teamManagementService.getAllTeams((action as any).view ?? state.view),
          ),
          withLatestFrom(of(action), of(state)),
        );
      }),
      map(([teams, action, state]) => {
        if (action.type === teamManagementActions.reloadTeamFromBackend.type) {
          this.interactionBarStateService.close();
        }

        this.store.dispatch(teamManagementActions.loadProfilePictures({ teams }));

        return teamManagementActions.teamLoaded({
          view: state.view,
          teams,
        });
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  viewChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.viewChanged),
      withLatestFrom(this.store.select(teamManagementFeatureSelector)),
      map(([action, state]) => {
        // load teams' data if there is no data
        // todo: check if this is correct
        if (
          (action.view === TEAM_VIEWS.MY_TEAMS && state.myTeams.length === 0) ||
          (action.view === TEAM_VIEWS.PEER_TEAMS && state.peerTeams.length === 0)
        ) {
          return teamManagementActions.loadTeamMembersFromBackend({ view: action.view });
        }
        return teamManagementActions.cancelLoad();
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  loadTeamMemberClicked = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.editTeamMemberOptionClicked),
      tap((action) => {
        this.store.dispatch(teamManagementActions.isSelectedMemberFetching({ isLoading: true }));
        this.store.dispatch(
          teamManagementActions.loadTeamRolesFromBackend({ team_id: action.member?.team_id }),
        );
        this.store.dispatch(
          teamManagementActions.setViewType({
            viewType: TEAM_MANAGEMENT_SIDEBAR_VIEW.EDIT_TEAM_MEMBER,
          }),
        );
      }),
      exhaustMap((action) => {
        return this.teamManagementService.getTeamMember(action.member.id);
      }),
      map((member) => {
        this.interactionBarStateService.openInteractionBar(INTERACTION_BAR_STATES.TEAM_MANAGEMENT);
        return teamManagementActions.setLoadedTeamMember({
          member,
        });
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  loadProfilePicture = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.loadProfilePictures),
      tap((action) => {
        this.store.dispatch(teamManagementActions.setProfilePicturesLoading({ isLoading: true }));
      }),
      map((action) => {
        // todo: forkjoin with id and url array
        // const fileRequests = [];
        const loadProfilePic = (member: ITeamMember) => {
          if (member.profile_picture_id) {
            this.driveApi.getFileViewURL(member.profile_picture_id).then((url) => {
              const imageSrc = this.domSanitizer.bypassSecurityTrustUrl(url);
              this.store.dispatch(
                teamManagementActions.addProfilePictureToCache({
                  profilePictureId: member.profile_picture_id,
                  url: imageSrc,
                }),
              );
            });
            // fileRequests.push({request, of(member.profile_picture_id)});
          }
        };
        action.teams.forEach((team) => {
          loadProfilePic(team);
          team.team_members.forEach((member) => {
            loadProfilePic(member);
          });
        });

        return teamManagementActions.setProfilePicturesLoading({ isLoading: false });
      }),
      catchError((errorData) => {
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  addTeamMemberToBackend$ = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.addMemberToBackend),
      tap((action) => {
        this.store.dispatch(teamManagementActions.setAreTeamsFetching({ isLoading: true }));
      }),
      withLatestFrom(this.store.select(teamManagementFeatureSelector)),
      exhaustMap(([action, state]) => {
        const member = DeepCopyService.deepCopy(action.member);
        const body = {
          ...member,
          property_ids: state.checkedProperties,
          project_ids: state.checkedProjects,
          team_id: state.selectedTeamId,
        };
        return this.teamManagementService.saveAddMember(body);
      }),
      map((data) => {
        this.notif.showSuccess('An invite has been sent');
        this.store.dispatch(teamManagementActions.reloadTeamFromBackend({ team_id: data.team_id }));
        return teamManagementActions.cancelLoad();
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  setTeamSearchQuery$ = createEffect(() => {
    return this.actions.pipe(
      ofType(teamManagementActions.setTeamSearchText),
      map((teamGroups) => {
        return teamManagementActions.searchTeamTextChanged();
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    );
  });

  modifyTeamMemberToBackend$ = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.modifyMemberOnBackend),
      tap((action) => {
        this.store.dispatch(teamManagementActions.setAreTeamsFetching({ isLoading: true }));
      }),
      withLatestFrom(this.store.select(teamManagementFeatureSelector)),
      exhaustMap(([action, state]) => {
        const member = DeepCopyService.deepCopy(action.member);
        const body = {
          ...member,
          property_ids: state.checkedProperties,
          project_ids: state.checkedProjects,
          team_id: state.selectedTeamId,
        };
        return this.teamManagementService.modifyMember(body);
      }),
      map((data) => {
        this.notif.showSuccess('Successfully updated.');
        this.store.dispatch(teamManagementActions.reloadTeamFromBackend({ team_id: data.team_id }));
        return teamManagementActions.cancelLoad();
      }),
      catchError((errorData) => {
        this.notif.showError(errorData?.error?.message);
        return of(teamManagementActions.cancelLoad());
      }),
      repeat(),
    ),
  );

  getTeamRoles$ = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.loadTeamRolesFromBackend),
      exhaustMap((action) => this.teamManagementService.getAllTeamRoles(action.team_id)),
      map((roles) => {
        return teamManagementActions.setTeamRoles({ roles });
      }),
      catchError((err) => {
        console.warn('getTeamRoles$ err handled', err);
        return of(teamManagementActions.cancel());
      }),
      repeat(),
    ),
  );

  loadTeamRolesFromPropertyChange = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.loadTeamRolesFromPropertyChange),
      withLatestFrom(this.store.select(teamManagementFeatureSelector)),
      exhaustMap(([action, state]) => {
        const selectedProperty = state.projectProperties.find(
          (property) => property.id === action.property_id,
        );
        if (selectedProperty) {
          return of(action).pipe(
            switchMap(() => this.teamManagementService.getAllTeamRoles(selectedProperty.team_id)),
            withLatestFrom(of(selectedProperty.team_id)),
          );
        }
      }),
      map(([roles, teamId]) => {
        return teamManagementActions.setTeamRolesAndTeamId({ roles, team_id: teamId });
      }),
      catchError((err) => {
        console.warn('getTeamRoles$ err handled', err);
        return of(teamManagementActions.cancel());
      }),
      repeat(),
    ),
  );

  getPropertyProjects$ = createEffect(() =>
    this.actions.pipe(
      ofType(teamManagementActions.loadProjectPropertiesFromBackend),
      exhaustMap((action) => this.teamManagementService.getPropertyProjects()),
      map((properties) => {
        return teamManagementActions.setProjectProperties({ properties });
      }),
      catchError((err) => {
        console.warn('getPropertyProjects$ err handled', err);
        return of(teamManagementActions.cancel());
      }),
      repeat(),
    ),
  );
}
