import { EventEmitter, Injectable } from '@angular/core';
import { NOTIFICATION_TYPE } from '../framework/constants/notification.constants';

export interface INotification {
  type: NOTIFICATION_TYPE;
  message?: string;
  response?: boolean;
  position?: string;
  timeout?: number;
  width?: number;
  height?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BottomNotificationService {
  notifications = new EventEmitter<INotification>();

  constructor() {}

  showLoading(data: INotification) {
    this.notifications.emit(data);
  }

  showPopup(data: INotification): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.notifications.emit(data);

      const subscription = this.notifications.subscribe((notification) => {
        if (notification.type === NOTIFICATION_TYPE.POPUP_RESPONSE) {
          subscription.unsubscribe();
          this.close();
          resolve(notification.response);
        }
      });
    });
  }

  close() {
    this.notifications.emit({
      type: NOTIFICATION_TYPE.CLOSE,
    });
  }
}
