import { Pipe, PipeTransform } from '@angular/core';
import { OptionsActionList } from '../../framework/overlays/option-list.constants';
import { ITask, TASK_STATUS_VALUE } from '../../store/tasks/tasks.interfaces';
import { OPTIONS } from '../../framework/constants/options-list.constants';
import { TasksService } from '../../services/tasks.service';

@Pipe({
  name: 'getTasksTableActions',
  standalone: true,
})
export class GetTasksTableActionsPipe implements PipeTransform {
  baseActions: OptionsActionList = [
    {
      value: OPTIONS.VIEW,
    },
    {
      value: OPTIONS.EDIT,
    },
    {
      value: OPTIONS.DELETE,
    },
    {
      value: OPTIONS.DOWNLOAD,
    },
  ];
  pendingActions: OptionsActionList = [
    ...this.baseActions,
    { value: OPTIONS.TASK_IN_PROGRESS, class: 'border-top' },
  ];
  inProgressActions: OptionsActionList = [
    ...this.baseActions,
    { value: OPTIONS.TASK_COMPLETE, class: 'border-top' },
  ];
  completedActions: OptionsActionList = [
    ...this.baseActions,
    { value: OPTIONS.TASK_PENDING, class: 'border-top' },
  ];

  constructor(private taskService: TasksService) {}

  transform(task: ITask): OptionsActionList {
    let actions: OptionsActionList;
    if (task.status === TASK_STATUS_VALUE.PENDING) {
      actions = this.pendingActions;
    }
    if (task.status === TASK_STATUS_VALUE.IN_PROGRESS) {
      actions = this.inProgressActions;
    } else {
      actions = this.completedActions;
    }
    return this.taskService.disableTaskActions(actions, task);
  }
}
