<div class="projects-contractor-container">
  <div [ngClass]="{ 'disp-none': projects.length == 0 }" class="content" *ngIf="projects.length !== 0">
    <div class="default-cursor page-title">Projects</div>
    <div class="info-wrapper">
      <app-project-info-contractor></app-project-info-contractor>
    </div>

    <div class="table-wrapper">
      <app-projects-table
        [allProjects]="projects"
        [dataKeys]="dataKeys"
        [viewType]="VIEWS.CONTRACTOR"
      ></app-projects-table>
    </div>
  </div>

  <div class="content" *ngIf="projects.length === 0">
    <div class="notify-msg visibility-hidden">No projects</div>
  </div>
</div>
