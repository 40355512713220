<div
  class="loading-container"
  [ngClass]="{ 'notification-container-show': notificationType === NOTIFICATION_TYPE.POPUP }"
>
  <div class="loading-text">
    <span class="loading-message">{{ message }}</span>
    <div (click)="acceptPopup()" class="loading-success notif-btn">
      <span class="btn-small">PASTE</span>
    </div>
  </div>
</div>
