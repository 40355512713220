import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  IChecklist,
  ITask,
  ITaskFilters,
  ITaskMember,
  ITaskSideBar,
  TASK_TYPE,
  TASK_VIEWS,
} from './tasks.interfaces';
import { tasksActions } from './tasks.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import {
  defaultFilters,
  defaultPaginationFilteredTasks,
  defaultTasksSideBar,
  FIRST_PAGE,
  NO_PROJECT,
  noCheckList,
  SECOND_PAGE,
  THIRD_PAGE,
} from './tasks.constants';
import moment from 'moment';

export const tasksFeatureKey = 'tasks';

export const tasksAdapter: EntityAdapter<ITask> = createEntityAdapter<ITask>();

export const { selectAll, selectEntities } = tasksAdapter.getSelectors();

export interface IFilteredTasksByVisualization {
  due: ITask[];
  upcoming: ITask[];
  spectating: ITask[];
}
export interface IPaginationInfoKanban {
  due: {
    page: number;
    isLoading: boolean;
    isFirstPageLoading: boolean;
    isAllLoaded: boolean;
  };
  upcoming: {
    page: number;
    isLoading: boolean;
    isFirstPageLoading: boolean;
    isAllLoaded: boolean;
  };
  spectating: {
    page: number;
    isLoading: boolean;
    isFirstPageLoading: boolean;
    isAllLoaded: boolean;
  };
  // perPage: number;
}
export interface IMonthlyTasks {
  year: number;
  month: number;
  tasks: ITask[];
}

export interface TasksState extends EntityState<ITask> {
  selectedTask: ITask;
  users: ITaskMember[];
  isLoading: boolean; // general isLoading, I guess
  loading: {
    // table view - all tasks' loading state
    pagination: boolean;
    tableSorting: boolean;
    firstPage: boolean;
  };
  usersLoading: boolean;
  sidebarTask: Partial<ITaskSideBar>;
  allChecklist: IChecklist[];
  tasksFiltered: IFilteredTasksByVisualization;
  paginationInfoKanban: IPaginationInfoKanban;
  filters: ITaskFilters;
  areAllPagesLoaded: boolean;
  selectedView: TASK_VIEWS;
  tasksCalendar: IMonthlyTasks[];
  calendar: {
    isCalendarLoading: boolean;
    selectedYear: number;
    selectedMonth: number;
  };
}

export const tasksInitialState: TasksState = tasksAdapter.getInitialState({
  selectedTask: null,
  users: [],
  isLoading: false,
  loading: {
    pagination: false,
    tableSorting: false,
    firstPage: false,
  },
  usersLoading: false,
  sidebarTask: defaultTasksSideBar,
  allChecklist: [],
  tasksFiltered: {
    due: [],
    spectating: [],
    upcoming: [],
  },
  filters: defaultFilters,
  areAllPagesLoaded: false,
  paginationInfoKanban: defaultPaginationFilteredTasks,
  selectedView: null,
  tasksCalendar: [],
  calendar: {
    isCalendarLoading: false,
    selectedYear: moment().year(),
    selectedMonth: moment().month(),
  },
});

export const tasksReducer = createReducer(
  tasksInitialState,
  on(tasksActions.setTasks, (state, action): TasksState => {
    return tasksAdapter.setAll(action.tasks, { ...state, isLoading: false });
  }),
  on(tasksActions.setTasksAfterSort, (state, action): TasksState => {
    return tasksAdapter.setAll(action.tasks, {
      ...state,
      isLoading: false,
      loading: {
        ...state.loading,
        tableSorting: false,
      },
    });
  }),
  on(tasksActions.nextPageLoaded, (state, action): TasksState => {
    const areAllPagesLoaded = action.tasks.length === 0;
    const newState = {
      ...state,
      loading: { ...state.loading, pagination: false, firstPage: false },
      isLoading: false,
      areAllPagesLoaded,
    };
    if (action.isFirstPage) {
      return tasksAdapter.setAll(action.tasks, newState);
    }
    return tasksAdapter.addMany(action.tasks, newState);
  }),
  on(tasksActions.nextPageLoadingStarted, (state, action): TasksState => {
    return {
      ...state,
      loading: {
        ...state.loading,
        pagination: true,
        firstPage: action.isFirstPage,
      },
    };
  }),
  on(tasksActions.nextPageFromKanbanLoaded, (state: TasksState, action): TasksState => {
    const tasks = action.isFirstPage
      ? action.tasks
      : [...state.tasksFiltered[action.visualization], ...action.tasks];
    const tasksFiltered = { ...state.tasksFiltered };
    tasksFiltered[action.visualization] = tasks;
    const paginationFilteredTasks: IPaginationInfoKanban = DeepCopyService.deepCopy(
      state.paginationInfoKanban,
    );
    paginationFilteredTasks[action.visualization] = {
      page: action.pageLoaded,
      isAllLoaded: !action.tasks?.length,
      isLoading: false,
      isFirstPageLoading: false,
    };
    return {
      ...state,
      tasksFiltered,
      paginationInfoKanban: paginationFilteredTasks,
    };
  }),
  on(tasksActions.nextPageLoadingStartedOnKanban, (state, action): TasksState => {
    const paginationInfoKanban: IPaginationInfoKanban = DeepCopyService.deepCopy(
      state.paginationInfoKanban,
    );
    paginationInfoKanban[action.visualization].isLoading = true;
    paginationInfoKanban[action.visualization].isFirstPageLoading = action.isFirstPage;
    return {
      ...state,
      paginationInfoKanban,
    };
  }),
  on(tasksActions.monthLoadingStartedOnCalendar, (state, action): TasksState => {
    return {
      ...state,
      calendar: {
        ...state.calendar,
        isCalendarLoading: true,
      },
    };
  }),
  on(tasksActions.monthFromCalendarLoaded, (state: TasksState, action): TasksState => {
    let newData = []; // if it was a hard reload, empty all other data
    if (!action.hardReload) {
      newData = state.tasksCalendar.filter(
        (record) => record.year !== action.year || record.month !== action.month,
      );
    }

    newData.push({
      year: action.year,
      month: action.month,
      tasks: action.tasks,
    });
    return {
      ...state,
      tasksCalendar: newData,
      calendar: {
        ...state.calendar,
        isCalendarLoading: false,
      },
    };
  }),
  on(tasksActions.selectedMonthChangedEffect, (state, action) => {
    return {
      ...state,
      calendar: {
        ...state.calendar,
        selectedYear: action.year,
        selectedMonth: action.month,
      },
    };
  }),
  on(tasksActions.taskViewLoadStarted, (state, action): TasksState => {
    return { ...state, selectedTask: null };
  }),
  on(
    tasksActions.taskViewLoadedSuccessfully,
    tasksActions.taskUpdatedSuccessfully,
    (state, action): TasksState => {
      return { ...state, selectedTask: action.task };
    },
  ),
  on(tasksActions.setUsers, (state, action): TasksState => {
    return { ...state, usersLoading: false, users: action.users };
  }),
  on(tasksActions.setUsersLoading, (state, action): TasksState => {
    return { ...state, usersLoading: action.isLoading };
  }),
  on(tasksActions.setIsLoading, (state, action): TasksState => {
    return { ...state, isLoading: action.isLoading };
  }),
  on(tasksActions.loadTasks, (state, tasks): TasksState => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(tasksActions.setAssignedUsers, (state, action): TasksState => {
    const currentTask = { ...state.sidebarTask };
    currentTask.assigned_members = action.users;
    return { ...state, sidebarTask: currentTask };
  }),
  on(tasksActions.addSpectatorUser, (state, action): TasksState => {
    const currentTask = DeepCopyService.deepCopy(state.sidebarTask);
    if (
      !currentTask.spectator_members.find((spectator) => spectator.user_id === action.user.user_id)
    ) {
      currentTask.spectator_members.push(action.user);
    }
    return { ...state, sidebarTask: currentTask };
  }),
  on(tasksActions.removeSpectatorUser, (state, action): TasksState => {
    const currentTask = DeepCopyService.deepCopy(state.sidebarTask);
    const spectatorIndex = currentTask.spectator_members.findIndex(
      (spectator) => spectator.user_id === action.user.user_id,
    );
    if (spectatorIndex >= 0) {
      currentTask.spectator_members[spectatorIndex].deleted = true;
    }
    return { ...state, sidebarTask: currentTask };
  }),
  on(tasksActions.addFiles, (state, action): TasksState => {
    const files = [...state.sidebarTask.files, ...action.files];
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        files,
      },
    };
  }),
  on(tasksActions.removeFile, (state, action): TasksState => {
    const files = DeepCopyService.deepCopy(state.sidebarTask.files);
    const deletedFiles = files.splice(action.index, 1);
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        files,
        deletedFiles: [...state.sidebarTask.deletedFiles, ...deletedFiles],
      },
    };
  }),
  on(tasksActions.clearFiles, (state, action): TasksState => {
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        files: [],
      },
    };
  }),
  on(tasksActions.setAllCheckList, (state, action): TasksState => {
    return {
      ...state,
      allChecklist: action.checklist,
    };
  }),
  on(tasksActions.removeCheckList, (state, action): TasksState => {
    const allChecklist: IChecklist[] = DeepCopyService.deepCopy(state.allChecklist);
    const foundCheckList = allChecklist.find((checklist) => checklist.id === action.checklist.id);
    if (foundCheckList) {
      foundCheckList.deleted = true;
    }

    return {
      ...state,
      allChecklist,
    };
  }),
  on(tasksActions.updateCheckList, (state, action): TasksState => {
    const items = action.checklist?.items?.map((item) => {
      return { name: item.name, value: !item?.value ? false : item.value };
    });
    const checklist: IChecklist = {
      ...action.checklist,
      items: items ?? [],
    };

    const sidebarTask = {
      ...state.sidebarTask,
      checklist,
    };
    return {
      ...state,
      sidebarTask,
    };
  }),
  on(tasksActions.updateSidebarTask, (state, action): TasksState => {
    console.log(action.task);
    const sidebarTask = {
      ...state.sidebarTask,
      ...action.task,
    };
    return {
      ...state,
      sidebarTask,
    };
  }),
  on(tasksActions.incrementPage, (state, action): TasksState => {
    if (submitCondition(state.sidebarTask.currentPage, state.sidebarTask.type)) {
      return state;
    }
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        currentPage: state.sidebarTask.currentPage + 1,
      },
    };
  }),
  on(tasksActions.setUpdateTaskID, (state, action): TasksState => {
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        updateTaskId: action.id,
      },
    };
  }),
  on(tasksActions.clearTaskSideBar, (state, action): TasksState => {
    return { ...state, sidebarTask: defaultTasksSideBar };
  }),
  on(tasksActions.decrementPage, (state, action) => {
    if (state.sidebarTask.currentPage === FIRST_PAGE) {
      return state;
    }
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        currentPage: state.sidebarTask.currentPage - 1,
      },
    };
  }),
  on(tasksActions.taskFiltersChangedInEffect, (state, action): TasksState => {
    return {
      ...state,
      filters: {
        ...action.filters,
      },
    };
  }),
  on(tasksActions.resetPaginationFiltersFromTable, (state, action): TasksState => {
    return {
      ...state,
      filters: {
        ...state.filters,
        with_pagination: 0,
        page: 0,
      },
    };
  }),
  on(tasksActions.loadSidebarTaskEdit, (state, action): TasksState => {
    const task: Partial<ITaskSideBar> = {
      ...action.task,
    };
    if (!task?.checklist) {
      task.checklist = noCheckList;
    }
    if (task?.project === null) {
      task.project = { id: NO_PROJECT, title: '' };
      task.project_id = NO_PROJECT;
    }

    // @ts-ignore
    if (task?.custom_fields?.[0] === false || !task?.custom_fields) {
      task.custom_fields = [];
    }
    return {
      ...state,
      isLoading: false,
      sidebarTask: {
        ...defaultTasksSideBar,
        ...task,
        isSideBarLoading: false,
      },
    };
  }),
  on(tasksActions.editTaskSidebarLoadStarted, (state, action): TasksState => {
    return {
      ...state,
      sidebarTask: {
        ...state.sidebarTask,
        isSideBarLoading: true,
      },
    };
  }),
  on(
    tasksActions.setSidebarLoadingFromTaskUpload,
    tasksActions.setSidebarLoadingFromMenuComp,
    (state, action): TasksState => {
      return {
        ...state,
        sidebarTask: { ...state.sidebarTask, isSideBarLoading: action.isLoading },
      };
    },
  ),
  on(tasksActions.messageSentOnDetails, (state: TasksState, action): TasksState => {
    const selectedTask: ITask = { ...state.selectedTask };
    selectedTask.requires_response = false;
    return tasksAdapter.updateOne(
      { id: selectedTask.id, changes: { requires_response: false } },
      { ...state, selectedTask },
    );
  }),
  on(tasksActions.sortingTasksLoadingFromEffect, (state, action): TasksState => {
    return { ...state, loading: { ...state.loading, tableSorting: action.isLoading } };
  }),
  on(tasksActions.taskViewChanged, (state, action): TasksState => {
    return {
      ...state,
      selectedView: action.view,
      filters: defaultFilters,
      paginationInfoKanban: defaultPaginationFilteredTasks,
    };
  }),
  on(tasksActions.emptyTasksState, (state, action) => {
    return {
      ...tasksInitialState,
    };
  }),
);

export function submitCondition(currentPage, taskType) {
  return (
    (currentPage === SECOND_PAGE && taskType === TASK_TYPE.DAILY) || currentPage === THIRD_PAGE
  );
}
