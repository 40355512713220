import { createAction, props } from '@ngrx/store';
import {
  IProjectFilters,
  ISimpleProject,
  Project,
} from '../../pages/webapp/projects/projects.interface';
import { IProjectStatus } from '../rollups/rollups.interface';

const loadProjectsTasksKanban = createAction('[Projects][Tasks][Sidebar] load all projects');
const loadProjectsSimpleTaskSidebar = createAction(
  '[Projects][Tasks][Sidebar] load all projects as simple response as possible',
  props<{ filters?: IProjectFilters }>(),
);

const allProjectsLoadedSuccessfully = createAction(
  '[Projects][Effects] projects loaded',
  props<{ projects: Project[] }>(),
);

const simpleProjectsLoadedSuccessfully = createAction(
  '[Projects][Effects] simple projects loaded',
  props<{ projects: ISimpleProject[] }>(),
);

const simpleProjectLoadCancelled = createAction(
  '[Projects][Tasks][Effect] load simple projects cancelled',
);

const allProjectLoadCancelled = createAction(
  '[Projects][Tasks][Effect] load all projects cancelled',
);

const clearProjects = createAction('[Projects] clear projects');

const loadBackendStatuses = createAction('[Projects][Effects] load backend statuses');
const cancelStatusLoading = createAction('[Projects] cancel status loading');
const statusesLoadedSuccessfully = createAction(
  '[Projects] statuses loaded successfully',
  props<{ statuses: IProjectStatus[] }>(),
);

const projectCreationCancelled = createAction('[Projects] cancel project creation');
const createProject = createAction(
  '[Projects] create project',
  props<{ project: Partial<Project> }>(),
);

const projectCreatedSuccessfully = createAction(
  '[Projects] project created successfully',
  props<{ projectId: number }>(),
);

const editProjectInitiated = createAction(
  '[Projects] edit project initiated',
  props<{ id: number }>(),
);
const editProjectDataLoaded = createAction(
  '[Projects] edit project data loaded for edit',
  props<{ project: Project }>(),
);
const failedToInitiateProjectEdit = createAction('[Projects] failed to initiate project edit');
const failedToDeleteProject = createAction('[Projects] failed to delete project');
const deleteProject = createAction('[Projects] delete project', props<{ id: number }>());
const successfulProjectDeletion = createAction('[Projects] successful project deletion');

const updateProject = createAction(
  '[Projects] update project',
  props<{ project: Partial<Project> }>(),
);

const updateProjectSuccess = createAction('[Projects] update project success');

const updateProjectFailure = createAction('[Projects] update project failure');

export const projectActions = {
  loadProjectsTasksKanban,
  loadProjectsSimpleTaskSidebar,
  allProjectsLoadedSuccessfully,
  simpleProjectsLoadedSuccessfully,
  simpleProjectLoadCancelled,
  allProjectLoadCancelled,
  clearProjects,
  loadBackendStatuses,
  cancelStatusLoading,
  statusesLoadedSuccessfully,
  createProject,
  projectCreationCancelled,
  projectCreatedSuccessfully,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  editProjectInitiated,
  editProjectDataLoaded,
  failedToInitiateProjectEdit,
  deleteProject,
  successfulProjectDeletion,
  failedToDeleteProject,
};
