<div class="interaction-bar-wrapper reporting-bar">
  <div class="interaction-bar-title default-cursor">
    <app-back-button-box (clicked)="decrementPage()" *ngIf="currentPage > 0" class="text-initial"></app-back-button-box>
    <span [ngClass]="{ 'shift-left': currentPage > 0 }">Print Report:</span>
    <span class="font-normal ml-1" *ngIf="currentPage === 0">Select Data Views</span>
    <span class="font-normal ml-1" *ngIf="currentPage === 1">Select Properties</span>
    <span class="font-normal ml-1" *ngIf="currentPage === 2">Select Projects</span>
  </div>

  <div class="interaction-bar-content" [formGroup]="reportFields">
    <div *ngIf="currentPage === 0" class="page">
      <div class="flex gap-4 items-center">
        <app-dropdown
          class="flex-1"
          [options]="years"
          label="Begin Fiscal Year"
          formControlName="start_year"
          ngDefaultControl
          optionValue="key"
          optionLabel="value"
          (modelChange)="yearsChanged('start_year')"
        >
        </app-dropdown>

        <app-dropdown
          class="flex-1"
          [options]="years"
          label="End Fiscal Year"
          formControlName="end_year"
          ngDefaultControl
          optionValue="key"
          optionLabel="value"
          (modelChange)="yearsChanged('end_year')"
        >
        </app-dropdown>
      </div>

      <ng-scrollbar appearance="standard" track="vertical" class="std-scrollbar reporting-scroll">
        <div (click)="toggleField('include_property_summary')" class="checklist-item">
          <app-checklist-item
            [title]="'Property Summary'"
            [checked]="reportFields.get('include_property_summary').value"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_project_summary')" class="checklist-item">
          <app-checklist-item [title]="'Project Summary'" [checked]="reportFields.get('include_project_summary').value">
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_project_spend_detail')" class="checklist-item">
          <app-checklist-item
            [title]="'Project Spend Detail'"
            [checked]="reportFields.get('include_project_spend_detail').value"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_cash_flow')" class="checklist-item">
          <app-checklist-item [title]="'Cashflow'" [checked]="reportFields.get('include_cash_flow').value">
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_project_schedule')" class="checklist-item pointer-events-none">
          <app-checklist-item
            [disabled]="true"
            [title]="'Schedule'"
            [checked]="reportFields.get('include_project_schedule').value"
          >
          </app-checklist-item>
        </div>

        <!--        commitment fields-->
        <div (click)="toggleAllCommitments()" class="checklist-item">
          <app-checklist-item
            [title]="'Project Commitments'"
            [checked]="reportFields.get('include_project_commitments').value"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_contract_detail')" class="checklist-item">
          <app-checklist-item
            title="Include Contract Details"
            [checked]="reportFields.get('include_contract_detail').value"
            class="pl-8"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_change_order_detail')" class="checklist-item">
          <app-checklist-item
            title="Include Change Orders Details"
            [checked]="reportFields.get('include_change_order_detail').value"
            class="pl-8"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_invoice_detail')" class="checklist-item">
          <app-checklist-item
            title="Include Invoice Details"
            [checked]="reportFields.get('include_invoice_detail').value"
            class="pl-8"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleField('include_direct_cost_detail')" class="checklist-item">
          <app-checklist-item
            title="Include Miscellaneous Cost Details"
            [checked]="reportFields.get('include_direct_cost_detail').value"
            class="pl-8"
          >
          </app-checklist-item>
        </div>

        <div (click)="toggleField('include_project_updates')" class="checklist-item">
          <app-checklist-item title="Project Updates" [checked]="reportFields.get('include_project_updates').value">
          </app-checklist-item>
          <span class="text-shade_2 font-semibold text-sm w-full">Most Recent Available Only</span>
        </div>

        <div class="separator">Project Status</div>
        <div (click)="toggleStatus(ALL)" class="checklist-item">
          <app-checklist-item
            [title]="'All Project Status'"
            [checked]="reportFields.get('project_status_ids').value.includes(ALL)"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleStatus(PROJECT_STATUS_ID.COMPLETED + '')" class="checklist-item">
          <app-checklist-item
            title="Completed"
            [checked]="reportFields.get('project_status_ids').value.includes(PROJECT_STATUS_ID.COMPLETED + '')"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleStatus(PROJECT_STATUS_ID.IN_PROGRESS + '')" class="checklist-item">
          <app-checklist-item
            title="In-Progress"
            [checked]="reportFields.get('project_status_ids').value.includes(PROJECT_STATUS_ID.IN_PROGRESS + '')"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleStatus(PROJECT_STATUS_ID.PLANNED + '')" class="checklist-item">
          <app-checklist-item
            title="Planned"
            [checked]="reportFields.get('project_status_ids').value.includes(PROJECT_STATUS_ID.PLANNED + '')"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleStatus(PROJECT_STATUS_ID.BIDDING + '')" class="checklist-item">
          <app-checklist-item
            title="Bidding"
            [checked]="reportFields.get('project_status_ids').value.includes(PROJECT_STATUS_ID.BIDDING + '')"
          >
          </app-checklist-item>
        </div>
        <div (click)="toggleStatus(PROJECT_STATUS_ID.CANCELED + '')" class="checklist-item">
          <app-checklist-item
            title="Cancelled"
            [checked]="reportFields.get('project_status_ids').value.includes(PROJECT_STATUS_ID.CANCELED + '')"
          >
          </app-checklist-item>
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="currentPage === 1" class="page">
      <app-floating-input
        placeholder="Search"
        label="Search"
        name="property_search"
        [(model)]="propertySearch"
        (iconClick)="onPropertyIconClick()"
        (modelChange)="propertySearchChanged($event)"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: propertySearch
            ? 'icon-add rotate-45 text-shade_3 cursor-pointer hover:text-color_reject'
            : 'icon-search text-shade_3'
        }"
      ></app-floating-input>
      <ng-scrollbar appearance="standard" track="vertical" class="std-scrollbar reporting-scroll">
        <div class="text-shade_2 text-center px-2 py-5" *ngIf="propertiesToList.length === 0">
          There are no properties corresponding to the search.
        </div>
        <div
          *ngIf="!propertySearch && propertiesToList.length > 0"
          (click)="toggleProperty(ALL)"
          class="checklist-item"
        >
          <app-checklist-item
            title="All Properties"
            [checked]="reportFields.get('property_ids').value.includes(ALL)"
          ></app-checklist-item>
        </div>
        <div
          (click)="toggleProperty(property.id + '')"
          *ngFor="let property of propertiesToList"
          class="checklist-item"
        >
          <app-checklist-item
            [title]="property.name"
            [subtitle]="property.formatted_address"
            [checked]="reportFields.get('property_ids').value.includes(property.id + '')"
          ></app-checklist-item>
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="currentPage === 2" class="page">
      <app-floating-input
        placeholder="Search"
        label="Search"
        name="project_search"
        [(model)]="projectSearch"
        (iconClick)="onProjectIconClick()"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: projectSearch
            ? 'icon-add rotate-45 text-shade_3 cursor-pointer hover:text-color_reject'
            : 'icon-search text-shade_3'
        }"
      ></app-floating-input>
      <ng-scrollbar appearance="standard" track="vertical" class="std-scrollbar reporting-scroll">
        <div class="text-shade_2 text-center px-2 py-5" *ngIf="filteredProjects.length === 0">
          There are no projects corresponding to the selected years, statuses, properties or search.
        </div>
        <div *ngIf="!projectSearch && filteredProjects.length > 0" (click)="toggleProject(ALL)" class="checklist-item">
          <app-checklist-item
            title="All Projects"
            [checked]="reportFields.get('project_ids').value.includes(ALL)"
          ></app-checklist-item>
        </div>
        <div (click)="toggleProject(project.id + '')" *ngFor="let project of filteredProjects" class="checklist-item">
          <app-checklist-item
            [title]="project.title"
            [subtitle]="project?.project_property?.formatted_address ?? ''"
            [checked]="reportFields.get('project_ids').value.includes(project.id + '')"
          ></app-checklist-item>
        </div>
      </ng-scrollbar>
    </div>

    <div class="text-color_reject text-center my-2">
      {{ errorMessage }}
    </div>
    <button
      *ngIf="currentPage < 2; else saveButton"
      (click)="incrementPage()"
      [disabled]="nextDisabled"
      [ngClass]="{ disabled: nextDisabled }"
      class="btn next-btn"
      mat-button
    >
      <span *ngIf="!isLoading">Next</span>
      <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
    </button>
    <ng-template #saveButton>
      <button
        (click)="submit()"
        [ngClass]="{ disabled: submitDisabled }"
        [disabled]="submitDisabled"
        class="btn"
        mat-button
      >
        <span>Generate report</span>
      </button>
    </ng-template>
  </div>
</div>
