import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule, NgForm } from '@angular/forms';
import { TabAsSlide } from '../../../../../framework/interfaces/TabAsSlide';
import { ProjectApiService } from '../../../../../services/project-api.service';
import {
  PROJECT_STATUS_ID,
  PROJECT_STATUS_KEY,
} from '../../../../../framework/constants/project.constants';
import { ProjectStateService } from '../../../../../services/project-state.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { ActivatedRoute, Params } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { loadSpends } from '../../../../../store/spend/spend.actions';
import { AppState } from '../../../../../store/app-state';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { getStoreUpdates } from '../../../../../store/spend/spend.selectors';
import { ILineItem } from '../../../../../store/spend/spend.interfaces';
import { CommitmentsService } from '../../../../../services/commitments.service';
import {
  clearCacheForNewDrive,
  loadProjectDocuments,
  reloadProjDocs,
} from '../../../../../store/projectDocuments/projectDocuments.actions';
import { GeneralFormModel, ProjectStatus } from '../../projects.interface';
import { take, takeUntil } from 'rxjs/operators';
import { IProperty } from '../../../../../store/properties/properties.interfaces';
import { AddProjectBase } from '../AddProjectBase';
import { TagInputComponent } from '../../../../../framework/inputs/tag-input/tag-input.component';
import { InputCalendarComponent } from '../../../../../framework/inputs/input-calendar/input-calendar.component';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import { NgClass, NgIf } from '@angular/common';
import { DropdownComponent } from '../../../../../framework/inputs/dropdown/dropdown.component';
import { viewProjectActions } from '../../../../../store/view-project/view-project.actions';
import { settingsActions } from '../../../../../store/settings/settings.actions';

export const CAPEX_ID = 1;
export const OPEX_ID = 2;

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgScrollbar,
    DropdownComponent,
    NgIf,
    NgClass,
    FloatingInputComponent,
    InputCalendarComponent,
    TagInputComponent,
  ],
})
export class GeneralComponent extends AddProjectBase implements OnInit, OnDestroy, TabAsSlide {
  @Output() isFormValid = new EventEmitter<boolean>();
  @Output() projectStatusChange = new EventEmitter<number>();
  @ViewChild('summaryScroll', { static: true }) summaryScroll: NgScrollbar;
  @ViewChild('generalFormModel', { static: true }) generalFormModel: NgForm;

  PROJECT_STATUS_KEY = PROJECT_STATUS_KEY;
  projectStatuses: ProjectStatus[] = [];
  properties: IProperty[] = [];
  lastStoreUpdate$ = this.store.select(getStoreUpdates);
  maxDate: string | null;
  oldSelectedProperty: number = null;

  _model: GeneralFormModel = {
    status: null,
    title: '',
    start_date: null,
    property_id: null,
    tags: [],
  };

  set model(value: GeneralFormModel) {
    this._model = value;
  }

  get model(): GeneralFormModel {
    return this._model;
  }

  isDestroyed$ = new Subject();
  TAGS_TOOLTIP =
    'You can utilize this field to tag your project with different keyword which you can use to filter and group projects by different categories, ie. CapEx, OpEx, Maintenance...etc.';

  constructor(
    private projectApi: ProjectApiService,
    private notif: NotificationsService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private commitmentService: CommitmentsService,
    public projectState: ProjectStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadData();
    this.subscribeToSpendUpdates();
  }

  loadData() {
    this.notif.showLoading();
    this.route.queryParams.pipe(take(1)).subscribe(
      async (params) => {
        if (this.projectState.isEdit) {
          await this.fetchAndSetDataForEdit(params);
        } else {
          await this.fetchAndSetDataForCreate(params);
        }

        this.notif.close();
      },
      (err) => {
        this.notif.showError(err);
      },
    );
  }

  async fetchAndSetDataForCreate(params: Params) {
    const propertiesPromise = this.fetchAndSetProperties(params);
    const statusesPromise = this.fetchAndSetStatuses();
    await Promise.all([propertiesPromise, statusesPromise]);
  }

  async fetchAndSetDataForEdit(params: Params) {
    const propertiesPromise = this.fetchAndSetProperties(params);
    const projectPromise = this.projectState.fetchAndSetProject();
    const [_, projectData] = await Promise.all([propertiesPromise, projectPromise]);
    // fetching statuses needs to be done after project data is set
    await this.fetchAndSetStatuses();

    this.model = {
      ...projectData,
    };
    this.oldSelectedProperty = this?.model?.property_id ?? null;
    this.statusChanged(this.model.status);
    this.projectState.initialStartDate = projectData.start_date;
  }

  async fetchAndSetProperties(params: Params) {
    try {
      this.properties = await this.projectApi.getProperties();
    } catch (err) {
      this.notif.showError(err);
    }
  }

  async fetchAndSetStatuses() {
    try {
      const backendStatuses = await this.projectApi.getProjectPossibleStatuses();
      this.setAvailableProjectStatuses(backendStatuses);
    } catch (err) {
      this.notif.showError(err);
    }
  }

  subscribeToSpendUpdates() {
    this.lastStoreUpdate$.pipe(takeUntil(this.isDestroyed$)).subscribe((storeUpdate) => {
      if (!storeUpdate.lineItems[0]) {
        this.maxDate = null;
        return;
      }
      const initialDateProp = this.getDateProp(storeUpdate.lineItems[0]);
      const firstLineItemDate =
        storeUpdate.lineItems?.reduce((prev, item) => {
          const itemDateProp = this.getDateProp(item);
          const prevDateProp = this.getDateProp(prev);
          return moment(prev[prevDateProp]).isSameOrBefore(moment(item[itemDateProp]))
            ? prev
            : item;
        }, storeUpdate.lineItems[0])[initialDateProp] || null;
      this.maxDate = firstLineItemDate;
    });
  }

  getDateProp(item: ILineItem) {
    return moment(item.start_date).isSameOrBefore(moment(item.forecast_start_date))
      ? 'start_date'
      : 'forecast_start_date';
  }

  setAvailableProjectStatuses(statuses: ProjectStatus[]) {
    const hiddenStatuses: PROJECT_STATUS_KEY[] = [
      PROJECT_STATUS_KEY.ARCHIVED,
      PROJECT_STATUS_KEY.DELETED,
    ];
    if (this.projectState?.general?.status !== PROJECT_STATUS_ID.AWARDED) {
      // hide for project create
      // hide in edit the awarded status if project is not awarded already
      hiddenStatuses.push(PROJECT_STATUS_KEY.AWARDED);
    }

    const filteredStatuses: ProjectStatus[] = statuses.filter((status) => {
      return !hiddenStatuses.includes(status.key);
    });

    const sortOrderByKey = {
      // the order to display
      [PROJECT_STATUS_KEY.PLANNED]: 1,
      [PROJECT_STATUS_KEY.BIDDING]: 2,
      [PROJECT_STATUS_KEY.IN_PROGRESS]: 4,
      [PROJECT_STATUS_KEY.AWARDED]: 5,
      [PROJECT_STATUS_KEY.COMPLETED]: 6,
      [PROJECT_STATUS_KEY.CANCELED]: 7,
      [PROJECT_STATUS_KEY.DELETED]: 8,
      [PROJECT_STATUS_KEY.ARCHIVED]: 9,
    };
    filteredStatuses.sort((a, b) => {
      return sortOrderByKey[a.key] - sortOrderByKey[b.key];
    });

    this.projectStatuses = filteredStatuses;
  }

  /**
   * Validates and saves first page. <br>
   * Saving needs to be done here, if we have a backend error, the user cannot go further.
   */
  async isValid(): Promise<boolean> {
    this.generalFormModel.form.markAllAsTouched();

    if (this.generalFormModel.invalid) {
      this.notif.showError('Please fill in all required fields.');
      return false;
    }

    const startDateControl = (this.model.start_date as any)?.control; // todo better type for ngModel
    if (startDateControl?.hasError('matDatepickerMax')) {
      this.notif.showError(
        "Start date needs to be before or equal the line item's budget or forecast start date!",
      );
      return false;
    }

    this.projectState.general = {
      id: this.projectState.id,
      ...this.model,
    };

    if (this.projectState.isEdit) {
      try {
        const updateResponse = await this.projectState.updateProject();
        this.notif.showSuccess('Updated!');
        if (this.projectState.id && !this.projectState.isAddProject) {
          this.store.dispatch(loadSpends({ projectId: updateResponse.id }));
        }
        this.store.dispatch(viewProjectActions.refreshNeeded({})); // todo: this is a hotfix, ne need an another action
      } catch (err) {
        this.notif.showError(err);
        return false;
      }
    } else {
      try {
        const createResponse = await this.projectState.createProject();
        this.projectState.isEdit = true;
        this.projectState.general = createResponse;

        this.store.dispatch(clearCacheForNewDrive());
        this.store.dispatch(
          loadProjectDocuments({
            projectId: Number.parseInt(createResponse.id, 10),
          }),
        );
        this.store.dispatch(settingsActions.loadBudgetTemplate());
        // todo: to be checked if it does the job..
        this.store.dispatch(
          viewProjectActions.selectedProjectChanged({ projectId: Number(this.projectState.id) }),
        );
        this.store.dispatch(viewProjectActions.refreshNeeded({}));
        this.store.dispatch(reloadProjDocs());
      } catch (err) {
        this.notif.showError(err);
        return false;
      }
    }

    return true;
  }

  getErrorMessage(param: AbstractControl) {
    return param.hasError('required')
      ? 'Required'
      : param.hasError('email')
        ? 'Invalid email'
        : param.hasError('minlength')
          ? 'Too short'
          : param.hasError('matDatepickerMax')
            ? 'Invalid date'
            : param.hasError('passNoMatch')
              ? 'No match'
              : '';
  }

  /**
   * If properties changed, set default project template and if needed ask the
   * user if he/she wants to reset the budget.
   */
  propertiesChanged(newPropertyId: number) {
    const selectedProperty = this.properties.find((property) => property.id === newPropertyId);
    if (
      selectedProperty?.team_id === this.projectState?.general?.budget_template?.team_id ||
      !this.projectState?.general?.budget_template
    ) {
      this.changeProperty(newPropertyId);
    } else {
      this.notif
        .showPopup(
          'Selected property does not have the same team as the selected budget template. Template will reset. Continue?',
        )
        .then((resp) => {
          if (resp) {
            this.changeProperty(newPropertyId);
            this.projectState.general.budget_template_id = null;
            this.projectState.general.budget_template = null;
            // @ts-ignore
            this.model.budget_template_id = null;
            // @ts-ignore
            this.model.budget_template = null;
          } else {
            this.changeProperty(this.oldSelectedProperty);
          }
        });
    }
  }

  /**
   * Changes the property and filters and resets the budget template.
   */
  changeProperty(propertyId: number) {
    this.oldSelectedProperty = propertyId;
    this.model.property_id = propertyId;
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  updateStartDate(value: string) {
    this.model.start_date = value;
  }

  statusChanged(statusId: number) {
    this.projectStatusChange.emit(statusId);
  }
}
