import { inject, Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import { ProjectDescriptionPayload } from '../pages/webapp/projects/view-project/project-updates/project-updates.types';
import { REST_DESCRIPTIONS } from '../restApi/RestRoutes';

@Injectable({
  providedIn: 'root',
})
export class ProjectDescriptionsService {
  restRequestService = inject(RestRequestService);

  getProjectDescriptions(project_id: number) {
    return this.restRequestService.getWithObservable(
      `${REST_DESCRIPTIONS}`,
      {},
      {
        project_id: project_id,
      },
    );
  }

  deleteProjectDescription(
    description_id: number,
    description: Partial<ProjectDescriptionPayload>,
  ) {
    return this.restRequestService.delWithObservable(
      `${REST_DESCRIPTIONS}/${description_id}`,
      {},
      description,
    );
  }

  updateProjectDescription(description: Partial<ProjectDescriptionPayload>) {
    return this.restRequestService.patchWithObservable(
      `${REST_DESCRIPTIONS}/${description.id}`,
      description,
    );
  }

  createProjectDescription(description: ProjectDescriptionPayload) {
    return this.restRequestService.postWithObservable(`${REST_DESCRIPTIONS}`, description);
  }
}
