import { createAction, props } from '@ngrx/store';
import {
  ILineItem,
  ISpendDistribution,
  ISpendLineItemSummary,
  SpendError,
} from './spend.interfaces';
import { SPEND_TYPES } from '../../framework/constants/budget.constants';
import { SPEND_DISTRIBUTION_STATE } from '../../framework/constants/spend.constants';

export const loadSpends = createAction('[Spend] Load Spends', props<{ projectId: number }>());
export const setLineItems = createAction(
  '[Spend] Set Line Items',
  props<{ lineItems: ILineItem[]; projectId: number }>(),
);

export const addDefaultLineItem = createAction('[Spend] Add Default Line Item');
export const addLineItemWithName = createAction(
  '[Spend] Add Line Item with name',
  props<{ name: string }>(),
);

export const addLineItem = createAction('[Spend] Add Line Item', props<{ lineItem: ILineItem }>());
export const deleteLineItem = createAction('[Spend] Delete Line Item', props<{ id: number }>());
/**
 * add all items deleted items in store
 */
export const addProjectBudgetTemplateChange = createAction(
  '[Spend] Delete line items for budget template change',
);
export const updateLineItem = createAction(
  '[Spend] Update Line Item',
  props<{ lineItem: Partial<ILineItem> }>(),
);
export const updateDistribution = createAction(
  '[Spend] Update Distribution',
  props<{ lineId: number; distribution: ISpendDistribution }>(),
);
export const setSelectedSpendType = createAction(
  '[Spend] Set Selected Spend Type',
  props<{ spendType: SPEND_TYPES }>(),
);
export const setSelectedYear = createAction('[Spend] Set Selected Year', props<{ year: number }>());

export const setProjectStartDate = createAction(
  '[Spend] Set Project Start Date',
  props<{ projectStartDate: string }>(),
);

export const setSpendDistributionState = createAction(
  '[Spend] Set Project Spend Distribution State (EDIT/ADD/DEFAULT,ETC)',
  props<{ spendDistributionState: SPEND_DISTRIBUTION_STATE }>(),
);

export const setIsLoading = createAction('[Spend] Set Is Loading', props<{ isLoading: boolean }>());
export const clearMonths = createAction('[Spend] Clear Months');
export const saveToBackend = createAction('[Spend] Save To Backend');
export const clearAfterSave = createAction(
  '[Spend] Clear After Save',
  props<{ errors: SpendError[] }>(),
);

export const addNewYear = createAction('[Spend] Add New Year');
export const setSelectableYears = createAction(
  '[Spend] Set Selectable Years',
  props<{ selectableYears: number[] }>(),
);

export const cancel = createAction('[Spend] Cancel event');

export const setSelectedProjectIdFromAddEditProject = createAction(
  '[Spend] Set Selected Project Id from add/edit project',
  props<{ id: number }>(),
);

export const setHasProjectTemplateFromAddEditProject = createAction(
  '[Spend] set has project template from add/edit project',
  props<{ hasTemplates: boolean }>(),
);

export const setHasProjectTemplateFromViewProject = createAction(
  '[Spend] set has project template from view project',
  props<{ hasTemplates: boolean }>(),
);

export const loadSpendLineItemSummary = createAction(
  '[Spend] Load Spend Line Item Summary',
  props<{ lineItemId: number }>(),
);

export const spendLineItemSummaryLoaded = createAction(
  '[Spend] Load Spend Line Item Summary loaded',
  props<{ summary: ISpendLineItemSummary }>(),
);

export const clearSpendLineItemSummary = createAction('[Spend] Clear Spend Line Item Summary');

export const spendActionTypes = {
  loadSpends,
  setLineItems,
  addLineItem,
  addDefaultLineItem,
  addLineItemWithName,
  updateLineItem,
  deleteLineItem,
  updateDistribution,
  setSelectedSpendType,
  setSelectedYear,
  setProjectStartDate,
  setSpendDistributionState,
  setIsLoading,
  clearMonths,
  saveToBackend,
  clearAfterSave,
  addNewYear,
  setSelectableYears,
  addProjectBudgetTemplateChange,
  setSelectedProjectIdFromAddEditProject,
  setHasProjectTemplateFromViewProject,
  setHasProjectTemplateFromAddEditProject,
  cancel,
  loadSpendLineItemSummary,
  spendLineItemSummaryLoaded,
  clearSpendLineItemSummary,
};
